import {
    PLANOGRAM_METADATA_PROCESSING,
    PLANOGRAM_METADATA_INIT,
    PLANOGRAM_METADATA_GET_SUCCESS,
    UPDATE_PLANOGRAM_METADATA_LOADING,
    UPDATE_PLANOGRAM_METADATA_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    processing: false,
};

const updatePlanogramMetadata = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PLANOGRAM_METADATA_LOADING:
            return { ...state, loading: true };
        case UPDATE_PLANOGRAM_METADATA_LOADING_DONE:
            return { ...state, loading: false };
        case PLANOGRAM_METADATA_PROCESSING:
            return { ...state, processing: true };
        case PLANOGRAM_METADATA_GET_SUCCESS:
            return { ...state, processing: false, loading: false };
        case PLANOGRAM_METADATA_INIT:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default updatePlanogramMetadata;
