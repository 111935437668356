import { Backend } from './backend';
//import { checkFilters } from './utils';

class RolePermissionApi {
    constructor() {
        this.path = '/users/engagement/roles';
    }

    async createRole(fields) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/add`,
                body: fields,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { RolePermissionApi };
