import { TaskApi } from '../../../../services/task-service';

export const LAST_VISIT_DATE_WIDGET_LOADING = 'LAST_VISIT_DATE_WIDGET_LOADING';
export const LAST_VISIT_DATE_WIDGET_SUCCESS = 'LAST_VISIT_DATE_WIDGET_SUCCESS';
export const LAST_VISIT_DATE_WIDGET_UPDATE = 'LAST_VISIT_DATE_WIDGET_UPDATE';
export const LAST_VISIT_DATE_WIDGET_CLEAR = 'LAST_VISIT_DATE_WIDGET_CLEAR';

const taskApi = new TaskApi();

export const loadLastVisitDate = (retailerId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LAST_VISIT_DATE_WIDGET_LOADING, loading: true });

            const retailersLastCompletedTasks =
                await taskApi.getLastCompletedScheduledTask([retailerId]);

            const lastCompletedTaskDate =
                retailersLastCompletedTasks &&
                retailersLastCompletedTasks.length > 0
                    ? retailersLastCompletedTasks[0].completedDate
                    : null;

            dispatch({
                type: LAST_VISIT_DATE_WIDGET_SUCCESS,
                lastCompletedTaskDate,
            });
        } catch (e) {
            console.error(e);
            dispatch({ type: LAST_VISIT_DATE_WIDGET_LOADING, loading: false });
        }
    };
};

export const loadLastVisitDateLocally = (lastCompletedTaskDate) => ({
    type: LAST_VISIT_DATE_WIDGET_UPDATE,
    lastCompletedTaskDate,
});

export const clearLastVisitDate = () => ({
    type: LAST_VISIT_DATE_WIDGET_CLEAR,
});
