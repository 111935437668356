import { ReportsApi } from '../../../services/reports-service';
import reportNames from '../../../constants/reportNames';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const GENERATE_INVOICE_LOADING = 'GENERATE_INVOICE_LOADING';
export const GENERATE_INVOICE_LOADING_DONE = 'GENERATE_INVOICE_LOADING_DONE';

const reportsApi = new ReportsApi();

export const generateInvoice = (invoiceId) => {
    return async (dispatch) => {
        dispatch({ type: GENERATE_INVOICE_LOADING });
        try {
            await reportsApi.generateReportByName(
                reportNames.SINGLE_INVOICE_REPORT,
                { id: invoiceId }
            );
        } catch (err) {
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err) }));
        }
        dispatch({ type: GENERATE_INVOICE_LOADING_DONE });
    };
};
