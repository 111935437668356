import _ from 'lodash';
import moment from 'moment';

import { toggleFooter } from '../footer';

import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

import { TaskApi } from '../../../services/task-service';
import { ZonesApi } from '../../../services/zones-service';
import { _getSurveyForTasks } from '../weeklyScheduler';

export const DAILY_TASKS = 'DAILY_TASKS';
export const DAILY_TASKS_LOADING = 'DAILY_TASKS_LOADING';
export const OTHER_TASKS_FOR_DAY = 'OTHER_TASKS_FOR_DAY';
export const OTHER_TASKS_FOR_DAY_LOADING = 'OTHER_TASKS_FOR_DAY_LOADING';
export const OTHER_TASKS_FOR_DAY_COUNT = 'OTHER_TASKS_FOR_DAY_COUNT';
export const RETAILER_TASKS_FOR_DAY_COUNT = 'RETAILER_TASKS_FOR_DAY_COUNT';
export const SET_DAILY_TASK_TAB = 'SET_DAILY_TASK_TAB';
export const SET_IS_CURRENT_WEEK = 'SET_IS_CURRENT_WEEK';
export const DAILY_TASKS_SELECT_DATE = 'DAILY_TASKS_SELECT_DATE';
export const DAILY_TASKS_RESET_SELECTED_DATE =
    'DAILY_TASKS_RESET_SELECTED_DATE';
export const INIT_DAILY_TASKS = 'INIT_DAILY_TASKS';
export const UPDATE_MODAL_VALUES = 'UPDATE_MODAL_VALUES';

const taskApi = new TaskApi();
const zonesApi = new ZonesApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const initDailyTasks = () => ({
    type: INIT_DAILY_TASKS,
});

export const setCurrViewDate = (date) => ({
    type: DAILY_TASKS_SELECT_DATE,
    date,
});

export const resetDateSelection = () => ({
    type: DAILY_TASKS_RESET_SELECTED_DATE,
});

export const toggleDailyLoading = (toggle) => ({
    type: DAILY_TASKS_LOADING,
    toggle,
});

export const toggleOtherTaskLoading = (toggle) => ({
    type: OTHER_TASKS_FOR_DAY_LOADING,
    toggle,
});

export const fetchDailyTasks = ({ date, path }) => {
    return async (dispatch, getState) => {
        dispatch(toggleDailyLoading(true));

        const today = new Date();
        const currentWeek = moment(today).isoWeek();
        const selectedWeek = moment(date).isoWeek();
        const isCurrentWeek = currentWeek === selectedWeek;
        dispatch({
            type: SET_IS_CURRENT_WEEK,
            isCurrentWeek,
        });

        try {
            const {
                weeklyScheduler: { scheduled, unscheduled },
                auth,
            } = getState();

            //const content = _.sortBy(_.filter(scheduled, (retailer) => moment(date).isSame(moment(retailer.schedule), 'd')), ['priority']);
            const content = _.sortBy(
                _.filter(scheduled, (retailer) =>
                    moment(date).isSame(moment(retailer.schedule), 'd')
                ),
                ['visitOrder']
            );

            const retailerIds = _.map(content, 'id');
            const zoneList = _.isEmpty(retailerIds)
                ? []
                : await zonesApi.getZonesByRetailerIds(retailerIds);
            const zoneIds = _.map(zoneList, 'id');
            //init the call here so its in cache storage so when the card is called has past visit tasks avail
            await getPastVistTasks(content, auth.info.user_id);
            await getLastCompletedTasks(content);

            if (path) {
                if (unscheduled && unscheduled.length > 0) {
                    dispatch(
                        toggleFooter(
                            true,
                            `${unscheduled.length} Unscheduled visit(s) found for this week`,
                            path
                        )
                    );
                } else {
                    dispatch(toggleFooter(false, '', null));
                }
            }

            dispatch(calculateTotalRetailerTasks(content));
            dispatch({
                type: DAILY_TASKS,
                content,
                zoneIds,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
            dispatch(toggleDailyLoading(false));
        }
    };
};

export const updateRetailerDailyTaskList = (utr) => {
    return (dispatch, getState) => {
        const {
            dailyTasks: { content, otherContent, zoneIds },
        } = getState();
        try {
            const scheduledTasksSearchPredicate = {
                id: utr.retailerId,
                tasks: [{ id: utr.id }],
            };
            let retailerToUpdate = _.find(
                content,
                scheduledTasksSearchPredicate
            );

            //if not in scheduled tasks try in adhoc
            if (!retailerToUpdate) {
                const adhocTasksSearchPredicate = {
                    id: utr.retailerId,
                    adhocTasks: [{ id: utr.id }],
                };
                retailerToUpdate = _.find(content, adhocTasksSearchPredicate);
            }

            if (retailerToUpdate) {
                let taskToRemove = _.find(retailerToUpdate.tasks, {
                    id: utr.id,
                });
                if (taskToRemove) {
                    _.remove(retailerToUpdate.tasks, taskToRemove);
                    retailerToUpdate.tasks.push(utr);
                }

                // also update the adhoc task list
                taskToRemove = _.find(retailerToUpdate.adhocTasks, {
                    id: utr.id,
                });
                if (taskToRemove) {
                    _.remove(retailerToUpdate.adhocTasks, taskToRemove);
                    retailerToUpdate.adhocTasks.push(utr);
                }

                dispatch(calculateTotalRetailerTasks(content));
                dispatch({
                    type: DAILY_TASKS,
                    content,
                    zoneIds,
                });
            }

            // update other tasks list
            const taskToRemove = _.find(otherContent, { id: utr.id });
            if (taskToRemove) {
                _.remove(otherContent, taskToRemove);
                otherContent.push(utr);
                dispatch({
                    type: OTHER_TASKS_FOR_DAY,
                    content: otherContent,
                });
            }
        } catch (error) {
            dispatch(createErrorHandlerError(error));
        }
    };
};

export const fetchAllMyAdHocTasksDueToday = (args, userId) => {
    return async (dispatch) => {
        try {
            dispatch(toggleOtherTaskLoading(true));
            let list = await taskApi.getAdHocTasksDueToday(args, userId);

            list = await _getSurveyForTasks(list);

            dispatch(calculateTotalOtherTasks(list));
            dispatch({
                type: OTHER_TASKS_FOR_DAY,
                content: list,
            });
        } catch (error) {
            dispatch(createErrorHandlerError(error));
            dispatch(toggleOtherTaskLoading(false));
            //errorHandler(error);
        }
    };
};

export const setTabIndex = (tabIndex) => ({
    type: SET_DAILY_TASK_TAB,
    tabIndex,
});

export const updateModalResults = (args) => {
    const startId = args.startIsRetailer ? args.startLocation : null;
    const endId = args.endIsRetailer ? args.endLocation : null;

    return (dispatch) => {
        dispatch({
            type: UPDATE_MODAL_VALUES,
            retailerIdStart: startId,
            retailerIdEnd: endId,
            locationStart: args.startLocation,
            locationEnd: args.endLocation,
        });
    };
};

const calculateTotalOtherTasks = (otherContent) => {
    return (dispatch, getState) => {
        const {
            dailyTasks: { content },
        } = getState();

        const retailerVisits = _.compact(
            _.uniqBy(getRetailersForAdhocTasks(content, otherContent), 'id')
        );

        //count the personal tasks (no retailer)
        let count = _.sum(
            _.map(otherContent, (utr) => (!utr.retailerId ? 1 : 0))
        );

        //add in all the adhoc tasks (each visit may have 0 to multiple)
        count += _.sumBy(retailerVisits, 'adhocTasks.length');

        dispatch({
            type: OTHER_TASKS_FOR_DAY_COUNT,
            count,
        });
    };
};

const calculateTotalRetailerTasks = (content) => {
    return (dispatch) => {
        const count = _.sumBy(content, 'tasks.length');

        dispatch({
            type: RETAILER_TASKS_FOR_DAY_COUNT,
            count,
        });
    };
};

const getRetailersForAdhocTasks = (content, otherContent) => {
    const retailerTaskList = [];

    const adhocTaskWithRetailerId = _.filter(
        otherContent,
        (utr) => {
            return utr.retailerId;
        },
        ['retailerId']
    );

    if (adhocTaskWithRetailerId.length > 0) {
        _.each(adhocTaskWithRetailerId, (f) => {
            const visit = _.find(content, (r) => r.id === f.retailerId);
            if (visit) {
                retailerTaskList.push(visit);
            }
        });
    }

    return retailerTaskList;
};

const getPastVistTasks = async (retailers, userId) => {
    for (let i = 0; i < retailers.length; i++) {
        const retailer = retailers[i];

        const retailerScheduleDate = moment(
            retailer.schedule ? retailer.schedule : new Date()
        );
        const pastVisitsDateBefore = retailerScheduleDate
            .clone()
            .format('YYYY-MM-DD');
        const pastVisitsDateAfter = retailerScheduleDate
            .clone()
            .subtract(2, 'months')
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

        const filterArgs = {
            filters: [
                {
                    property: 'status',
                    data: ['COMPLETE', 'INCOMPLETE', 'STARTED', 'ASSIGNED'],
                },
                {
                    property: 'scheduleType',
                    data: ['ONE_TIME', 'ALL_VISITS', 'NEXT_VISIT'],
                },
                { property: 'userId', data: [userId] },
                { property: 'dueDateAfter', data: [pastVisitsDateAfter] },
                { property: 'dueDateBefore', data: [pastVisitsDateBefore] },
                { property: 'retailerId', data: [retailer.id] },
            ],
            page: 0,
            size: 8,
            sortColumn: 'dueDate',
            sortOrder: 'desc',
        };

        const idQueryStr = `?id=lastVisitFor-${retailer.id}`;

        await taskApi.getUTRsFilter(filterArgs, idQueryStr);
    }
};

const getLastCompletedTasks = async (retailers) => {
    const retailerIds = _.uniq(retailers.map((retailer) => retailer.id));
    const retailersLastCompletedTasks =
        await taskApi.getLastCompletedScheduledTask(retailerIds);
    retailersLastCompletedTasks.forEach((completedTask) => {
        const retailer = _.find(retailers, { id: completedTask.retailerId });
        if (retailer) {
            retailer.lastCompletedTaskDate = completedTask.completedDate;
        }
    });
};
