import moment from 'moment';

import {
    WEEKLY_SCHEDULE_INIT,
    WEEKLY_SCHEDULE_RETAILERS_LIST,
    WEEKLY_SCHEDULE_ADD_RETAILERS_LIST,
    WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
    WEEKLY_SCHEDULE_LOADING,
    WEEKLY_SCHEDULE_DONE,
    WEEKLY_SCHEDULE_CURRENT_WEEK,
    WEEKLY_SCHEDULE_CURRENT_WEEK_CLEAR,
} from '../actions/weeklyScheduler';

const initialDate = () => {
    const today = new Date();
    const start = moment(today).isoWeek();
    return moment(today)
        .isoWeek(start)
        .startOf('isoWeek')
        .clone()
        .format('YYYY-MM-DD');
};

const INITIAL_STATE = {
    scheduled: [],
    unscheduled: [],
    loading: false,
    firstDayOfWeek: initialDate(),
};

const weeklyScheduler = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WEEKLY_SCHEDULE_LOADING:
            return { ...state, loading: true };
        case WEEKLY_SCHEDULE_DONE:
            return { ...state, loading: false };
        case WEEKLY_SCHEDULE_RETAILERS_LIST:
            return {
                ...state,
                scheduled: [...action.scheduled],
                unscheduled: [...action.unscheduled],
            };
        case WEEKLY_SCHEDULE_ADD_RETAILERS_LIST:
            return {
                ...state,
                scheduled: [...action.scheduled],
                unscheduled: [...action.unscheduled],
            };
        case WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST:
            return {
                ...state,
                scheduled: action.scheduled,
                unscheduled: action.unscheduled,
            };
        case WEEKLY_SCHEDULE_INIT:
            return { ...INITIAL_STATE };
        case WEEKLY_SCHEDULE_CURRENT_WEEK:
            return { ...state, firstDayOfWeek: action.firstDayOfWeek };
        case WEEKLY_SCHEDULE_CURRENT_WEEK_CLEAR:
            return { ...state, firstDayOfWeek: initialDate() };
        default:
            return state;
    }
};
export default weeklyScheduler;
