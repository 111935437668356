import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SidebarItem from '../components/SidebarItem';
import Secured from '../components/security/Secured';
import {
    closeDrawer,
    setActiveMenu,
    toggleDrawer,
} from '../redux/actions/drawer';
import config from '../constants/config';
import {
    clearErrorHandler,
    clearNotification,
    clearReferrer,
    initActiveUser,
    initDailyTasks,
    initTarget,
    toggleFooter,
    setupOrganizationInfo,
    showConfirmationPopup,
    setIsFormDirty,
    setPath,
    setactiveName,
} from '../redux/actions';
import { resetFilters } from '../components/filter/actions';
import { logout } from '../redux/actions/auth';
import {
    clearAllTableSelection,
    clearSearch,
} from '../components/datatable/actions';
import { clearError } from '../services/error-handler';
import Localize from '../constants/i18n-utils';
import permissions from '../constants/permissions';

const {
    CONFIGURATION_MANAGEMENT,
    PERMISSIONS_CONFIGURATION_MANAGEMENT,
    USER_MANAGEMENT,
    FAQ_MANAGEMENT_PRIVATE,
    FAQ_MANAGEMENT_PUBLIC,
    CONTACT_US_CONFIGURATION,
    PRIVACY_POLICY_MANAGEMENT,
    NEW_RETAILER_POLICY,
    UPLOAD_MANAGEMENT,
    MESSAGE_MANAGEMENT,
    // VIRTUAL_TOUR_CONFIGURATION,
    DASHBOARD,
    GAMES_LIST,
    RETAILER_GROUP_LIST,
    COURIER_LIST,
    PLANOGRAM_LIST,
} = config.ROUTE_URLS;

const {
    DISPLAY_GAMES_LIST,
    UPDATE_SYS_CONFIGS,
    LIST_USERS,
    VIEW_PERMISSIONS,
    UPDATE_CONTACT_US,
    MANAGE_UPLOAD,
    DISPLAY_RETAILER_GROUP_LIST,
    ROLE_PLANOGRAM_LIST,
    ROLE_COURIER_LIST,
    DASHBOARD_PAGE,
} = permissions;

const MENU_ICON_SIZE = 22;

export class SideMenu extends Component {
    state = {
        name: null,
    };

    async componentDidMount() {
        await this.props.setupOrganizationInfo();
    }

    _setActive = (name) => {
        const { isFormDirty, setactiveName } = this.props;
        if (isFormDirty) {
            setactiveName(name);
            return;
        }
        this.props.onSetActiveMenu(name);
    };

    nav = (path, callback) => {
        //exit if path is not valid or if trying to nav to the same page
        if (!path || this.props.location.pathname === path) {
            return;
        }

        const { isFormDirty, showConfirmationPopup, isConfirmationPopup } =
            this.props;
        const nav = () => {
            if (isFormDirty) {
                this.props.setIsFormDirty(false);
                if (isConfirmationPopup) {
                    showConfirmationPopup(false);
                }
            }

            this._cleanup();
            this.props.history.push(path);
        };

        if (!isFormDirty) {
            nav();
        } else {
            const { setPath } = this.props;
            showConfirmationPopup(true);
            setPath(path);
        }

        // this._cleanup();
        // this.props.history.push(path);

        if (callback) {
            callback();
        }
    };

    _cleanup = () => {
        this.props.onToggleFooter();
        this.props.onCloseDrawer();
        this.props.onResetFilters();
        this.props.clearSearch();
        this.props.clearAllTableSelection();
        this.props.onClearErrors();
        this.props.onResetSelectedUser();
        this.props.clearErrorHandler();
        this.props.clearReferrer();
    };

    render() {
        const { activeItem } = this.props;

        return (
            <Menu
                className="sfa-menu"
                data-test="sidemenu"
                vertical
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    borderStyle: 'none',
                    height: 675,
                    overflowY: 'auto',
                }}
            >
                <Secured hasRole={DASHBOARD_PAGE}>
                    <SidebarItem
                        title="Dashboard"
                        leftIcon="icon-Dashboard"
                        leftIconSize={MENU_ICON_SIZE}
                        setActive={this._setActive}
                        activeItem={activeItem}
                        href={'#' + DASHBOARD}
                        navigateTo={() => this.nav(DASHBOARD)}
                    />
                </Secured>
                <Secured hasAnyRole={[UPDATE_SYS_CONFIGS, UPDATE_CONTACT_US]}>
                    <SidebarItem
                        title={Localize.text(
                            'Menu.administration',
                            'Administration'
                        )}
                        leftIcon="icon-Settings"
                        leftIconSize={MENU_ICON_SIZE}
                    >
                        <Secured hasRole={UPDATE_SYS_CONFIGS}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.configuration',
                                    'Configuration Management'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() =>
                                    this.nav(CONFIGURATION_MANAGEMENT)
                                }
                            />
                        </Secured>
                        <Secured hasRole={LIST_USERS}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.user.management',
                                    'User Management'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(USER_MANAGEMENT)}
                            />
                        </Secured>
                        <Secured hasRole={VIEW_PERMISSIONS}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.configuration.permissions',
                                    'User Roles & Permissions'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() =>
                                    this.nav(
                                        PERMISSIONS_CONFIGURATION_MANAGEMENT
                                    )
                                }
                            />
                        </Secured>

                        {/* LOTTO ADMIN NAV */}
                        <Secured hasRole={UPDATE_CONTACT_US}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.configuration.contactUs',
                                    'Contact Us Configuration'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() =>
                                    this.nav(CONTACT_US_CONFIGURATION)
                                }
                            />
                        </Secured>
                        <Secured hasRole={MANAGE_UPLOAD}>
                            <SidebarItem
                                rightIcon="icon-forward"
                                leftIconSize={MENU_ICON_SIZE}
                                subMenu
                                title={Localize.text(
                                    'Menu.uploadDocument',
                                    'Upload Files'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(UPLOAD_MANAGEMENT)}
                            />
                        </Secured>
                        <SidebarItem
                            title={Localize.text('Menu.faq', 'Edit Pages')}
                            leftIcon="icon-additional-info"
                            leftIconSize={MENU_ICON_SIZE}
                        >
                            <Secured hasRole="ROLE_RE_CREATE_FAQ">
                                <SidebarItem
                                    leftIconSize={MENU_ICON_SIZE}
                                    subMenu
                                    title={Localize.text(
                                        'Menu.faqPrivate',
                                        'FAQ - Private'
                                    )}
                                    setActive={this._setActive}
                                    activeItem={activeItem}
                                    navigateTo={() =>
                                        this.nav(FAQ_MANAGEMENT_PRIVATE)
                                    }
                                />
                            </Secured>
                            <Secured hasRole="ROLE_RE_CREATE_FAQ">
                                <SidebarItem
                                    rightIcon="icon-forward"
                                    leftIconSize={MENU_ICON_SIZE}
                                    subMenu
                                    title={Localize.text(
                                        'Menu.faqPublic',
                                        'FAQ - Public'
                                    )}
                                    setActive={this._setActive}
                                    activeItem={activeItem}
                                    navigateTo={() =>
                                        this.nav(FAQ_MANAGEMENT_PUBLIC)
                                    }
                                />
                            </Secured>
                            <Secured hasRole="ROLE_RE_CREATE_PRIVACY_POLICY">
                                <SidebarItem
                                    leftIconSize={MENU_ICON_SIZE}
                                    subMenu
                                    title={Localize.text(
                                        'Menu.privacyPolicyEdit',
                                        'Privacy Policy'
                                    )}
                                    setActive={this._setActive}
                                    activeItem={activeItem}
                                    navigateTo={() =>
                                        this.nav(PRIVACY_POLICY_MANAGEMENT)
                                    }
                                />
                            </Secured>

                            <Secured hasRole="ROLE_RE_CREATE_NEW_RETAILER_POLICY">
                                <SidebarItem
                                    leftIconSize={MENU_ICON_SIZE}
                                    subMenu
                                    title={Localize.text(
                                        'Menu.newRetailerEdit',
                                        'New Retailer'
                                    )}
                                    setActive={this._setActive}
                                    activeItem={activeItem}
                                    navigateTo={() =>
                                        this.nav(NEW_RETAILER_POLICY)
                                    }
                                />
                            </Secured>
                        </SidebarItem>
                    </SidebarItem>
                </Secured>
                <Secured hasAnyRole={[UPDATE_SYS_CONFIGS]}>
                    <SidebarItem
                        title={Localize.text(
                            'Menu.portaladministration',
                            'Portal Administration'
                        )}
                        leftIcon="icon-Settings"
                        leftIconSize={MENU_ICON_SIZE}
                    >
                        <Secured hasRole={UPDATE_SYS_CONFIGS}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.addEditMessages',
                                    'Add/ Edit Messages'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(MESSAGE_MANAGEMENT)}
                            />
                        </Secured>
                        <Secured hasRole={DISPLAY_GAMES_LIST}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.gamesList',
                                    'Games List'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(GAMES_LIST)}
                            />
                        </Secured>
                        <Secured hasRole={DISPLAY_RETAILER_GROUP_LIST}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.retailerGroups',
                                    'Retailer Groups'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(RETAILER_GROUP_LIST)}
                            />
                        </Secured>
                        <Secured hasRole={ROLE_PLANOGRAM_LIST}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.planogram',
                                    'Planograms'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(PLANOGRAM_LIST)}
                            />
                        </Secured>
                        <Secured hasRole={ROLE_COURIER_LIST}>
                            <SidebarItem
                                subMenu
                                title={Localize.text(
                                    'Menu.couriers',
                                    'Couriers'
                                )}
                                setActive={this._setActive}
                                activeItem={activeItem}
                                navigateTo={() => this.nav(COURIER_LIST)}
                            />
                        </Secured>
                        {
                            // TO BE DEVELOPED IN A FUTURE STORY
                            // <Secured hasRole={UPDATE_SYS_CONFIGS}>
                            //     <SidebarItem
                            //         subMenu
                            //         title={Localize.text(
                            //             'Menu.firstTimeTour',
                            //             'Virtual Tour Configuration'
                            //         )}
                            //         setActive={this._setActive}
                            //         activeItem={activeItem}
                            //         navigateTo={() =>
                            //             this.nav(VIRTUAL_TOUR_CONFIGURATION)
                            //         }
                            //     />
                            // </Secured>
                        }
                    </SidebarItem>
                </Secured>
            </Menu>
        );
    }
}

const mapStateToProps = ({ drawer, formsMetadata }) => ({
    isConfirmationPopup: drawer.showConfirmationPopup,
    activeItem: drawer.activeMenu,
    isFormDirty: formsMetadata.isFormDirty,
    path: drawer.path,
    name: drawer.name,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logout()),
        onToggleDrawer: (toggle) => dispatch(toggleDrawer(toggle)),
        onSetActiveMenu: (name) => dispatch(setActiveMenu(name)),
        onToggleFooter: () => dispatch(toggleFooter(false)),
        onCloseDrawer: () => dispatch(closeDrawer()),
        onResetFilters: () => dispatch(resetFilters()),
        initTarget: () => dispatch(initTarget()),
        onClearErrors: () => {
            dispatch(clearError());
            dispatch(clearNotification());
        },
        clearSearch: () => dispatch(clearSearch()),
        clearAllTableSelection: () => dispatch(clearAllTableSelection()),
        onResetSelectedUser: () => dispatch(initActiveUser()),
        clearErrorHandler: () => dispatch(clearErrorHandler()),
        clearReferrer: () => dispatch(clearReferrer()),
        initDailyTasks: () => dispatch(initDailyTasks()),
        setupOrganizationInfo: () => dispatch(setupOrganizationInfo()),
        showConfirmationPopup: (value) =>
            dispatch(showConfirmationPopup(value)),
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
        setPath: (path) => dispatch(setPath(path)),
        setactiveName: (name) => dispatch(setactiveName(name)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
