import { ReturnsApi } from '../../../services/returns-service';
import { RetailersApi } from '../../../services/retailers-service';
import { getErrorMessage } from '../../../constants/utils';
import _ from 'lodash';

const returnsApi = new ReturnsApi();
const retailersApi = new RetailersApi();

export const PENDING_RETURNS__MODAL_LOADING = 'PENDING_RETURNS__MODAL_LOADING';
export const PENDING_RETURNS__MODAL_LOADED = 'PENDING_RETURNS__MODAL_LOADED';
export const PENDING_RETURNS__DISPLAY_MODAL = 'PENDING_RETURNS__DISPLAY_MODAL';

export const PENDING_RETURNS__SET_PENDING_RETURN_ITEM =
    'PENDING_RETURNS__SET_PENDING_RETURN_ITEM';
export const PENDING_RETURNS__CREATE_RETURN_RESET =
    'PENDING_RETURNS__CREATE_RETURN_RESET';
export const PENDING_RETURNS__RETURN_REASONS_LIST =
    'PENDING_RETURNS__RETURN_REASONS_LIST';

export const RETAILER_INFO = 'RETAILER_INFO';

export const displayCreateReturnModal = (display) => ({
    type: PENDING_RETURNS__DISPLAY_MODAL,
    payload: display,
});

export const resetCreateReturnScreen = () => (dispatch) => {
    dispatch({ type: PENDING_RETURNS__CREATE_RETURN_RESET });
};

export const validateBook =
    (serial, retailerReference, confirming, pendingReturnId) =>
    async (dispatch) => {
        dispatch({ type: PENDING_RETURNS__MODAL_LOADING });
        let bookReturnValidation, result, retailer;

        try {
            retailer = await retailersApi.getRetailerIdByReference(
                retailerReference
            );
        } catch (e) {
            console.error(e);
            result = {
                status: 'ERROR',
                errorMessage:
                    'An unexpected error has occurred during processing. The error has been logged. Please contact Application Support.',
            };
        }
        if (retailer) {
            try {
                bookReturnValidation = await returnsApi.validateBook(
                    serial,
                    retailer.id,
                    confirming,
                    pendingReturnId
                );
                result = { status: 'SUCCESS', payload: bookReturnValidation };
            } catch (e) {
                result = { status: 'ERROR', errorMessage: getErrorMessage(e) };
            }
        }
        dispatch({ type: PENDING_RETURNS__MODAL_LOADED });
        return result;
    };

export const validatePosItem =
    (itemCode, retailerReference, itemQuantity) => async (dispatch) => {
        dispatch({ type: PENDING_RETURNS__MODAL_LOADING });

        let posItemValidation, result, retailer;

        try {
            retailer = await retailersApi.getRetailerIdByReference(
                retailerReference
            );
        } catch (e) {
            console.error(e);
            result = {
                status: 'ERROR',
                errorMessage:
                    'An unexpected error has occurred during processing. The error has been logged. Please contact Application Support.',
            };
        }

        if (retailer) {
            try {
                posItemValidation = await returnsApi.validatePosItem(
                    itemCode,
                    retailer.id
                );
                result = { status: 'SUCCESS', payload: posItemValidation };
            } catch (err) {
                result = {
                    status: 'FAILED',
                    errorMessage: getErrorMessage(err),
                };
            }
        }
        dispatch({ type: PENDING_RETURNS__MODAL_LOADED });
        return result;
    };

export const validateTicketRange =
    (
        startBarcode,
        endBarcode,
        retailerReference,
        confirming,
        pendingReturnId
    ) =>
    async (dispatch) => {
        dispatch({ type: PENDING_RETURNS__MODAL_LOADING });
        let ticketsReturnValidation, result, retailer;

        try {
            retailer = await retailersApi.getRetailerIdByReference(
                retailerReference
            );
        } catch (e) {
            console.error(e);
            result = {
                status: 'ERROR',
                errorMessage:
                    'An unexpected error has occurred during processing. The error has been logged. Please contact Application Support.',
            };
        }

        if (retailer) {
            try {
                ticketsReturnValidation = await returnsApi.validateTickets(
                    startBarcode,
                    endBarcode,
                    retailer.id,
                    confirming,
                    pendingReturnId
                );
                result = {
                    status: 'SUCCESS',
                    payload: ticketsReturnValidation,
                };
            } catch (e) {
                result = { status: 'FAILED', errorMessage: getErrorMessage(e) };
            }
        }
        dispatch({ type: PENDING_RETURNS__MODAL_LOADED });
        return result;
    };

export const isBookSplit = (barCode) => async () => {
    let response = {};
    try {
        response.result = await returnsApi.isBookSplit(barCode);
        response.status = 'SUCCESS';
    } catch (e) {
        response = { status: 'ERROR', errorMessage: getErrorMessage(e) };
    }
    return response;
};

export const checkIfOnlyOnePackIsAllowed = () => (dispatch, getState) => {
    const pendingReturnItems =
        getState().createReturn.pendingReturnDetails.pendingReturnItems;

    const scannedBookCount = _.filter(
        pendingReturnItems,
        (pendingReturnItem) => pendingReturnItem.itemType === 'book'
    )?.length;

    const allowOnlyOnePackPerReturn =
        _.find(getState().organization.configs, {
            name: 'allow-only-one-pack-per-return',
        })?.value.toLowerCase() === 'true';

    return !!allowOnlyOnePackPerReturn && scannedBookCount === 1;
};

export const checkBookDuplicate = (bookSerial) => (dispatch, getState) => {
    return !!_.find(
        getState().createReturn.pendingReturnDetails.pendingReturnItems,
        (pendingReturnItem) => {
            return (
                pendingReturnItem.itemType === 'book' &&
                pendingReturnItem.bookSerial === bookSerial
            );
        }
    );
};

export const checkTicketDuplicate =
    (bookSerial, tickets) => (dispatch, getState) => {
        return !!_.find(
            getState().createReturn.pendingReturnDetails.pendingReturnItems,
            (pendingReturnItem, key) => {
                if (pendingReturnItem.bookSerial === bookSerial) {
                    return !!_.size(
                        _.intersectionWith(
                            pendingReturnItem.tickets,
                            tickets,
                            (arrVal, otherVal) => {
                                return (
                                    arrVal.ticketNumber ===
                                    otherVal.ticketNumber
                                );
                            }
                        )
                    );
                }
                return false;
            }
        );
    };

export const addBookToPendingReturn =
    (bookSerial, bookId) => (dispatch, getState) => {
        let pendingReturnItems = _.cloneDeep(
            getState().createReturn.pendingReturnDetails.pendingReturnItems
        );
        pendingReturnItems.push({
            bookSerial,
            bookId: bookId,
            itemType: 'book',
        });
        dispatch(setPendingReturnItems(pendingReturnItems));
    };

export const setPendingReturnItems = (pendingReturnItems) => ({
    type: PENDING_RETURNS__SET_PENDING_RETURN_ITEM,
    payload: _.sortBy(pendingReturnItems, ['bookSerial']),
});

export const removeBookFromPendingReturn =
    (bookSerial) => (dispatch, getState) => {
        let pendingReturnItems = _.cloneDeep(
            getState().createReturn.pendingReturnDetails.pendingReturnItems
        );
        let newPendingReturnItems = _.filter(
            pendingReturnItems,
            (pendingReturnItem) => {
                return pendingReturnItem.bookSerial !== bookSerial;
            }
        );
        dispatch(setPendingReturnItems(newPendingReturnItems));
    };

export const addPosItemToPendingReturn =
    (itemCode, itemQuantity) => (dispatch, getState) => {
        let pendingReturnItems = _.cloneDeep(
            getState().createReturn.pendingReturnDetails.pendingReturnItems
        );

        if (!pendingReturnItems.some((item) => item.itemCode === itemCode)) {
            pendingReturnItems.push({
                bookSerial: `pos_${itemCode}`,
                itemCode,
                itemQuantity: ~~itemQuantity,
                itemType: 'ps',
            });
        } else {
            let posItem = pendingReturnItems.find((item) => {
                return item.itemCode === itemCode;
            });
            posItem.itemQuantity += ~~itemQuantity;
        }

        dispatch(setPendingReturnItems(pendingReturnItems));
    };

export const addTicketsToPendingReturns =
    (bookSerial, tickets) => (dispatch, getState) => {
        let pendingReturnItems = _.cloneDeep(
            getState().createReturn.pendingReturnDetails.pendingReturnItems
        );
        let pendingReturnBook = _.find(
            pendingReturnItems,
            (pendingReturnItem) => {
                return pendingReturnItem.bookSerial === bookSerial;
            }
        );
        let newTickets = _.map(tickets, (ticket) => {
            return { ticketNumber: ticket.ticketNumber, ticketId: ticket.id };
        });
        if (pendingReturnBook) {
            pendingReturnBook = {
                ...pendingReturnBook,
                tickets: [...pendingReturnBook.tickets, ...newTickets],
            };
            pendingReturnItems = _.pullAllWith(
                pendingReturnItems,
                [{ bookSerial }],
                (arrVal, othVal) => {
                    return arrVal.bookSerial === othVal.bookSerial;
                }
            );
        } else {
            pendingReturnBook = {
                bookSerial,
                tickets: newTickets,
                itemType: 'ticket',
            };
        }
        pendingReturnItems.push(pendingReturnBook);
        dispatch(setPendingReturnItems(pendingReturnItems));
    };

export const removeTicketFromPendingReturn =
    (bookSerial, ticketId) => (dispatch, getState) => {
        let pendingReturnItems = _.cloneDeep(
            getState().createReturn.pendingReturnDetails.pendingReturnItems
        );
        let pendingReturnBook = _.find(
            pendingReturnItems,
            (pendingReturnItem) => {
                return pendingReturnItem.bookSerial === bookSerial;
            }
        );
        let updatedPendingReturnBookTickets = _.pullAllWith(
            pendingReturnBook.tickets,
            [{ ticketId }],
            (arrVal, othVal) => {
                return arrVal.ticketId === othVal.ticketId;
            }
        );

        let newPendingReturnItems;
        if (!_.size(updatedPendingReturnBookTickets)) {
            dispatch(removeBookFromPendingReturn(bookSerial));
            newPendingReturnItems = _.cloneDeep(
                getState().createReturn.pendingReturnDetails.pendingReturnItems
            );
        } else {
            newPendingReturnItems = _.pullAllWith(
                pendingReturnItems,
                [{ bookSerial }],
                (arrVal, othVal) => {
                    return arrVal.bookSerial === othVal.bookSerial;
                }
            );
            newPendingReturnItems.push(pendingReturnBook);
        }
        dispatch(setPendingReturnItems(newPendingReturnItems));
    };

export const removePosItemFromReturn = (itemCode) => (dispatch, getState) => {
    let pendingReturnItems = _.cloneDeep(
        getState().createReturns.pendingReturnDetails.pendingReturnItems
    );

    let newPendingReturnItems = _.filter(
        pendingReturnItems,
        (pendingReturnItem) => {
            return pendingReturnItem.itemCode !== itemCode;
        }
    );
    dispatch(setPendingReturnItems(newPendingReturnItems));
};

export const savePendingReturn = (args) => async (dispatch) => {
    dispatch({ type: PENDING_RETURNS__MODAL_LOADING });
    let response;
    try {
        let returnResponse = await returnsApi.savePendingReturn(args);
        response = {
            status: 'SUCCESS',
            payload: {
                returnResponse,
            },
        };
    } catch (e) {
        response = { status: 'ERROR', errorMessage: getErrorMessage(e) };
    }
    dispatch({ type: PENDING_RETURNS__MODAL_LOADED });
    return response;
};
