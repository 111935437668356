import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchPastOrders } from '../../redux/actions';
import config from '../../constants/config';
import {
    formatMoney,
    formatToDisplayDate,
    userHasPermission,
} from '../../constants/utils';
import permissions from '../../constants/permissions';
import Localize from '../../constants/i18n-utils';
import './styles.css';
import { updateSortDetails } from '../datatable/actions';
import { generateOrderByExternalOrderNumber } from '../../redux/actions';
import FilterSection from '../../components/filter/FilterSection';
import { clearFilters } from '../../components/filter/actions';
import { selectItemsToFilter } from '../../constants/utils';
import { ResponsiveTable } from '../datatable/responsive-table/responsive-table';
import _ from 'lodash';

const PAST_ORDER_DEFAULTS = {
    size: 10,
    sortOrder: 'desc',
    sortColumn: 'orderDate',
};

const { ORDER } = config.ROUTE_URLS;

const { VIEW_ORDER } = permissions;

export class PastOrders extends Component {
    state = {
        defaultFilterItems: {
            status: ['D', 'C'],
            useDefault: true,
        },
    };

    componentDidMount() {
        this.init();
    }

    async init() {
        const { selectedItems, updateSortDetails, number } = this.props;
        let filters = selectItemsToFilter(selectedItems);
        updateSortDetails({
            sortColumnName: PAST_ORDER_DEFAULTS.sortColumn,
            ascending: false,
        });

        filters.push({
            property: 'isComplete',
            data: ['true'],
        });

        this.search({
            page: number,
            size: PAST_ORDER_DEFAULTS.size,
            sortOrder: PAST_ORDER_DEFAULTS.sortOrder,
            sortColumn: PAST_ORDER_DEFAULTS.sortColumn,
            filters: filters,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            this.init();
        }
    }

    componentWillUnmount() {
        this.props.clearFilters();
    }

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        const { selectedItems } = this.props;
        const filters = selectItemsToFilter(selectedItems);
        filters.push({
            property: 'isComplete',
            data: ['true'],
        });
        this.search({
            page: page <= 0 ? 0 : page,
            size,
            sortOrder,
            sortColumn: sortColumn || PAST_ORDER_DEFAULTS.sortColumn,
            query,
            filters,
        });
    };
    handleFilterApply = (selectedItems, query) => {
        let filters = selectItemsToFilter(selectedItems);
        this.setState({ defaultFilterItems: { useDefault: false } });
        filters.push({
            property: 'isComplete',
            data: ['true'],
        });
        this.search({
            page: 0,
            size: PAST_ORDER_DEFAULTS.size,
            filters,
            query,
        });
    };
    handleClearFilters = () => {
        this.search({
            page: 0,
            size: PAST_ORDER_DEFAULTS.size,
            filters: [
                {
                    property: 'isComplete',
                    data: ['true'],
                },
            ],
        });

        this.setState({ defaultFilterItems: { useDefault: false } });
    };

    handleMobileSearch = (query) => {
        const { selectedItems, sortOrder, sortColumn } = this.props;
        this.handleSearch(query, selectedItems, sortOrder, sortColumn);
    };

    handleSearch = (query, selectedItems, sortOrder, sortColumn) => {
        let filters = selectItemsToFilter(selectedItems);
        filters.push({
            property: 'isComplete',
            data: ['true'],
        });
        this.search({
            page: 0,
            size: PAST_ORDER_DEFAULTS.size,
            sortOrder,
            sortColumn: sortColumn || PAST_ORDER_DEFAULTS.sortColumn,
            query,
            filters,
        });
    };

    search = (args) => {
        const { activeRetailer } = this.props;
        this.props.search({
            ...args,
            sortOrder: args?.sortOrder || PAST_ORDER_DEFAULTS.sortOrder,
            sortColumn: args?.sortColumn || PAST_ORDER_DEFAULTS.sortColumn,
            filters: [
                ...args.filters,
                {
                    property: 'retailer',
                    data: [activeRetailer?.userRetailerId?.retailerReference],
                },
            ],
        });
    };
    gotoOrderDetail = (path) => this.props.history.push(path);

    render() {
        const { loading, content, totalPages, totalElements, size, number } =
            this.props;

        const columns = [
            {
                name: 'externalOrderNumber',
                title: `${Localize.text(
                    'pastOrders.headers.orderNo',
                    'Order No.'
                )}`,
                sortable: true,
                searchable: true,
                mobileViewOrder: 1,
            },
            {
                name: 'orderDate',
                title: `${Localize.text(
                    'pastOrders.headers.orderDate',
                    'Order Date'
                )}`,
                sortable: true,
                formatter: (value) => formatToDisplayDate(value),
                mobileViewOrder: 3,
            },
            {
                name: 'status',
                title: `${Localize.text(
                    'pastOrders.headers.status',
                    'Status'
                )}`,
                sortable: true,
                mobileViewOrder: 2,
            },
            {
                name: 'orderTotal',
                title: `${Localize.text(
                    'pastOrders.headers.totalQuantity',
                    'Total Quantity'
                )}`,
                sortable: true,
                formatter: (value) => value ?? 0,
                mobileViewOrder: 4,
                mobileOnly: true,
            },
            {
                name: 'totalCost',
                title: `${Localize.text(
                    'pastOrders.headers.totalQuantity',
                    'Total Quantity'
                )}`,
                sortable: true,
                formatter: (value) => formatMoney(value),
                mobileViewOrder: 4,
                mobileOnly: true,
            },
        ];

        let actions = [
            {
                text: `${Localize.text(
                    'pastOrders.ellipse.download',
                    'Download'
                )}`,
                handleClick: (id) => {
                    const orderData = _.find(content, (c) => c.id === id);
                    this.props.generateOrder(
                        orderData.sfaOrderNumber,
                        orderData.externalOrderNumber
                    );
                },
                handleClickParams: 'id',
            },
            {
                text: `${Localize.text(
                    'pastOrders.ellipse.viewDetails',
                    'View Details'
                )}`,
                handleClick: (id) => this.gotoOrderDetail(`${ORDER}/${id}`),
                handleClickParams: 'id',
                canShow: () => userHasPermission(VIEW_ORDER),
            },
        ];

        return (
            <div className="past-orders-content" data-test="pastOrdersContent">
                <FilterSection
                    filters={[
                        {
                            label: `${Localize.text(
                                'pastOrders.filter.status',
                                'Status'
                            )}:`,
                            name: 'status',
                            multiple: true,
                            toggleAll: true,
                            client: true,
                            searchable: true,
                            items: [
                                { text: 'Canceled', value: 'C' },
                                { text: 'Processed', value: 'D' },
                            ],
                            defaultSelected: this.state.defaultFilterItems
                                .useDefault
                                ? this.state.defaultFilterItems.status
                                : '',
                        },
                    ]}
                    onApplyFilter={this.handleFilterApply}
                    onClearFilters={this.handleClearFilters}
                />
                <ResponsiveTable
                    actionsMenu={actions}
                    loading={loading}
                    content={content}
                    contentKey="id"
                    totalPages={totalPages}
                    totalElements={totalElements}
                    size={size}
                    page={number}
                    selector={{
                        variables: ['id'],
                        type: 'allowwithactionsmenu',
                        path: ORDER,
                        callback: userHasPermission(VIEW_ORDER)
                            ? this.gotoOrderDetail
                            : null,
                    }}
                    columns={columns}
                    fetchData={this.handleSelectTablePage}
                    onSearch={this.handleSearch}
                    onMobileSearch={this.handleMobileSearch}
                    noDataMessage={Localize.text(
                        'pastOrders.noData',
                        'There are no Past Orders'
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = ({
    pastOrders,
    userRetailer: { activeRetailer },
    tableFilter: { selectedItems },
}) => ({
    ...pastOrders,
    activeRetailer,
    selectedItems,
});

const mapDispatchToProps = (dispatch) => ({
    search: (args) => dispatch(searchPastOrders(args)),
    updateSortDetails: (args) => dispatch(updateSortDetails(args)),
    generateOrder: (sfaOrderNumber, externalOrderNumber) =>
        dispatch(
            generateOrderByExternalOrderNumber(
                sfaOrderNumber,
                externalOrderNumber
            )
        ),
    clearFilters: () => dispatch(clearFilters()),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PastOrders)
);
