import {
    PLANOGRAM_PROCESSING,
    PLANOGRAM_CREATE_SUCCESS,
    PLANOGRAM_INIT,
    PLANOGRAM_GET_SUCCESS,
    UPLOAD_PLANOGRAM_LOADING,
    UPLOAD_PLANOGRAM_LOADING_DONE,
} from '../../actions';

const INIT_PLANOGRAM_CREATE = {
    file: '',
    name: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    planogramToUpload: { ...INIT_PLANOGRAM_CREATE },
    planogramGroupId: null,
};

const uploadPlanogram = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPLOAD_PLANOGRAM_LOADING:
            return { ...state, loading: true };
        case UPLOAD_PLANOGRAM_LOADING_DONE:
            return { ...state, loading: false };
        case PLANOGRAM_PROCESSING:
            return { ...state, processing: true };
        case PLANOGRAM_GET_SUCCESS:
            return {
                ...state,
                planogramToUpload: action.planogram,
                processing: false,
                loading: false,
            };
        case PLANOGRAM_CREATE_SUCCESS:
            return {
                ...state,
                planogramToUpload: { ...INIT_PLANOGRAM_CREATE },
                processing: false,
            };
        case PLANOGRAM_INIT:
            return {
                ...INITIAL_STATE,
                planogramGroupId: action.id,
                planogramToUpload: { ...INIT_PLANOGRAM_CREATE },
            };
        default:
            return state;
    }
};
export default uploadPlanogram;
