import { Backend } from './backend';

class OrganizationApi {
    constructor() {
        this.path = '/organization/engagement';
    }

    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `/organization/server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `/organization/version`,
        });
    }

    async getSetupDetails() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/retailer-portal-details`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getDefaultLanguage() {
        try {
            return await Backend.sendNoAuth({
                method: 'GET',
                url: `${this.path}/portal-default-language`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getLogoKey() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/portal-app-logo-key`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateConfigurationByConfigName(args) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/update-retailer-portal-details`,
                body: args,
            });
        } catch (e) {
            throw e;
        }
    }

    async logoUpload(logo) {
        try {
            let formData = new FormData();
            formData.append('file', logo.file);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/logo/portal-upload/${logo.key}`,
                body: formData,
                timeout: 120000,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    async portalFileUpload(upload) {
        try {
            let formData = new FormData();
            formData.append('file', upload.file);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/portal-file-upload`,
                body: formData,
                timeout: 120000,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    //gets the main Application logo from a public/open url
    async downloadAppLogo() {
        try {
            return await Backend.downloadInsecureImg({
                timeout: 120000,
                url: `${this.path}/portalAppLogo`,
            });
        } catch (e) {
            throw e;
        }
    }

    //gets the report logo from a public/open url
    async downloadReportLogo() {
        try {
            return await Backend.downloadInsecureImg({
                timeout: 120000,
                url: `${this.path}/portalReportLogo`,
            });
        } catch (e) {
            throw e;
        }
    }

    async downloadFaqFile() {
        try {
            return await Backend.download({
                method: 'GET',
                url: `${this.path}/download-portal-faq-file`,
            });
        } catch (e) {
            throw e;
        }
    }

    async downloadPrivacyFile() {
        try {
            return await Backend.download({
                method: 'GET',
                url: `${this.path}/download-portal-privacy-file`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPortalFrontendConfigurations() {
        try {
            return await Backend.sendNoAuth({
                method: 'GET',
                url: `${this.path}/portal-frontend-configuration`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getIncludeSampleGames() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/include-sample-games`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { OrganizationApi };
