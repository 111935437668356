import React, { Component } from 'react';
import { connect } from 'react-redux';
import constants from './widgetNames';
import Page from '../../layout/Page';
import { Grid, Loader, Button, Icon, Segment } from 'semantic-ui-react';
import YtdTotalCommissions from './widgets/YtdTotalCommissions/YtdTotalCommissions';
import TopGamesByPricePointWidget from './widgets/topGamesByPricePointWidget/TopGamesByPricePointWidget';
import TotalPurchaseYTDWidget from './widgets/totalPurchaseYTDWidget/TotalPurchaseYTDWidget';
import TotalPurchaseYTDWidgetByPP from './widgets/totalPurchaseYTDWidgetByPP/TotalPurchaseYTDWidgetByPP';
import TotalPurchaseYtdNonProductWidget from './widgets/totalPurchaseYtdNonProductWidget/TotalPurchaseYtdNonProductWidget';
import LastOrderSummaryWidget from './widgets/lastOrderSummaryWidget/LastOrderSummaryWidget';
import SystemTimeWidget from './widgets/systemTimeWidget/SystemTimeWidget';
import ServiceVersionWidget from './widgets/serviceVersionWidget/ServiceVersionWidget';
import {
    fetchDashboardSettings,
    getActiveMessageFilesForRetailerType,
    setMoreDetailLabel,
} from '../../redux/actions';
import { map, isEmpty, toLower, sortBy } from 'lodash';
import NoItems from '../../components/NoItems';
import { Dimmer } from 'semantic-ui-react';
import ImageCarousel from '../../containers/ImageCarousel/ImageCarousel';
import classnames from 'classnames';
import './Dashboard.css';
import { userHasPermission, isAdmin } from '../../constants/utils';
import permissions from '../../constants/permissions';
import Localize from '../../constants/i18n-utils';
import GettingStartedWidget from './widgets/gettingStartedWidget/GettingStartedWidget';
import PlanogramsWidget from './widgets/planogramsWidget/PlanogramsWidget';
import UpcomingGamesWidget from './widgets/upcomingGamesWidget/UpcomingGamesWidget';
import ClosingGamesWidget from './widgets/closingGamesWidget/ClosingGamesWidget';

const {
    COMMISSION_WIDGET,
    VIRTUAL_TOUR,
    TOP_SELLING_GAME_WIDGET,
    LAST_ORDER_SUMMARY_WIDGET,
    TOTAL_PURCHASE_YTD_WIDGET,
    TOTAL_PURCHASE_YTD_PP_WIDGET,
    SYSTEM_TIME_WIDGET,
    SERVICES_VERSIONS,
    PLANOGRAMS_WIDGET,
    UPCOMING_GAMES_WIDGET,
    CLOSING_GAMES_WIDGET,
    TOTAL_PURCHASE_YTD_NON_PRODUCTS_WIDGET,
} = permissions;

class Dashboard extends Component {
    state = {
        showImageCarousel: true,
        messageDataLoaded: false,
    };

    componentDidMount() {
        this.props.fetchDashboardSettings();
        this.props.fetchListOfMessageFiles();
        this.props.setMoreDetailLabel({
            label: Localize.text('More detail', 'More detail'),
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            this.props.fetchListOfMessageFiles();
        }
    }

    renderWidget = (widgetName, key, widgets, section) => {
        let columnClass = 'row-extra-large';

        if (widgets && widgets.length > 1) {
            columnClass = widgets.length === 2 ? 'row-large' : 'row-small';
        }

        let widgetJsx = null;

        switch (widgetName) {
            case constants.WIDGET_RE_TOUR:
                if (userHasPermission(VIRTUAL_TOUR)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <GettingStartedWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_COMMISSIONS:
                if (userHasPermission(COMMISSION_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <YtdTotalCommissions />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_TOP_SELLING_GAMES_PP:
                if (userHasPermission(TOP_SELLING_GAME_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <TopGamesByPricePointWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_LAST_ORDER_SUMMARY:
                if (userHasPermission(LAST_ORDER_SUMMARY_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <LastOrderSummaryWidget />
                        </Grid.Column>
                    );
                }
                break;

            case constants.WIDGET_RE_TOTAL_PURCHASE_YTD:
                if (userHasPermission(TOTAL_PURCHASE_YTD_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <TotalPurchaseYTDWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_TOTAL_PURCHASE_YTD_PP:
                if (userHasPermission(TOTAL_PURCHASE_YTD_PP_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <TotalPurchaseYTDWidgetByPP />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_TOTAL_PURCHASE_YTD_NON_PRODUCTS:
                if (userHasPermission(TOTAL_PURCHASE_YTD_NON_PRODUCTS_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <TotalPurchaseYtdNonProductWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_SERVICES_VERSIONS:
                if (userHasPermission(SERVICES_VERSIONS)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <ServiceVersionWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_SYSTEM_TIME:
                if (userHasPermission(SYSTEM_TIME_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <SystemTimeWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_PLANOGRAMS:
                if (userHasPermission(PLANOGRAMS_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <PlanogramsWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_UPCOMING_GAMES:
                if (userHasPermission(UPCOMING_GAMES_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <UpcomingGamesWidget />
                        </Grid.Column>
                    );
                }
                break;
            case constants.WIDGET_RE_CLOSING_GAMES:
                if (userHasPermission(CLOSING_GAMES_WIDGET)) {
                    widgetJsx = (
                        <Grid.Column
                            key={`${section.sectionId}-${widgetName}-${key}`}
                            className={`${columnClass} ${toLower(widgetName)}`}>
                            <ClosingGamesWidget />
                        </Grid.Column>
                    );
                }
                break;
            default:
                break;
        }
        return widgetJsx;
    };

    renderRow = (section) => {
        const { widgets } = section;

        return (
            <Grid.Row key={section.sectionId} stretched>
                {map(widgets, ({ name }, key) =>
                    name !== 'PLACEHOLDER'
                        ? this.renderWidget(name, key, widgets, section)
                        : ''
                )}
            </Grid.Row>
        );
    };

    toggleCarousel = () => {
        this.setState({ showImageCarousel: !this.state.showImageCarousel });
    };

    render() {
        const { dashboardData, dashboardLoading, files, loading } = this.props;
        const { showImageCarousel, messageDataLoaded } = this.state;

        if (!messageDataLoaded && !isEmpty(dashboardData)) {
            this.props.fetchListOfMessageFiles();
            this.setState({ messageDataLoaded: true });
        }

        let content;
        let imageContent;
        if (!loading && !isAdmin()) {
            if (dashboardData || !isEmpty(dashboardData)) {
                if (files && Object.keys(files).length > 0) {
                    const fileImage = [];
                    for (var key in files) {
                        fileImage.push(files[key].fileBase64);
                    }

                    imageContent = (
                        <ImageCarousel
                            fileData={files}
                            fileImage={fileImage}
                            showThumbs={false}
                            showArrows={true}
                            ImageHeight="300px"
                        />
                    );
                }
            }
        }
        if (!dashboardLoading) {
            if (!dashboardData || isEmpty(dashboardData)) {
                imageContent = '';
                content = (
                    <div className="no-data">
                        <NoItems
                            message="There are no widgets available for your profile"
                            hideNoItemsMessage
                        />
                    </div>
                );
            } else {
                content = (
                    <Grid
                        columns={2}
                        stackable
                        className="dashboard"
                        relaxed={false}
                        padded={false}>
                        {map(sortBy(dashboardData, ['position']), (section) =>
                            this.renderRow(section)
                        )}
                    </Grid>
                );
            }
        }
        return (
            <Page name="DashboardPage">
                {' '}
                {!dashboardLoading && loading && (
                    <Loader active inline="centered">
                        Loading
                    </Loader>
                )}
                <Dimmer active={dashboardLoading} page inverted>
                    <Loader />
                </Dimmer>
                {showImageCarousel && imageContent}
                {imageContent && (
                    <Segment className="show-hide-wrapper" basic>
                        <Button
                            className="show-hide-btn transparent-btn"
                            onClick={this.toggleCarousel}>
                            <Icon
                                className={classnames('eye', {
                                    slash: showImageCarousel,
                                })}
                            />
                            {showImageCarousel ? 'Hide' : 'Show'}
                        </Button>
                    </Segment>
                )}
                {content}
            </Page>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    dashboardSettings: { dashboardData, dashboardLoading },
    documentUpload: { files, loading },
}) => ({
    retailerInfo,
    dashboardData,
    dashboardLoading: dashboardLoading,
    loading,
    files,
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListOfMessageFiles: () =>
            dispatch(getActiveMessageFilesForRetailerType()),
        fetchDashboardSettings: () => dispatch(fetchDashboardSettings()),
        setMoreDetailLabel: (values) => dispatch(setMoreDetailLabel(values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
