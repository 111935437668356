import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Grid,
    Header,
    Icon,
    Button,
    Label,
    GridRow,
    GridColumn,
} from 'semantic-ui-react';
import { find } from 'lodash';
import { formatMoney } from '../../constants/utils';
import NumberIncrementor from '../numberIncrementor/NumberIncrementor';
import {
    incrementQuantity,
    decrementQuantity,
    initOrderQuantity,
    saveOrderQuantity,
    addToCart,
} from '../../redux/actions';
import './styles.css';

export class PosGameCatalogDetailModal extends Component {
    handleAddToCart = () => {
        const { addToCart, orderQty } = this.props;
        if (orderQty >= 0) {
            addToCart();
            this.handleClose();
        }
    };

    handleClose = () => {
        const { onClose, initOrderQuantity } = this.props;

        onClose();
        initOrderQuantity();
    };

    handleMount = () => {
        const { gameInCart, saveOrderQuantity } = this.props;
        if (!!gameInCart) {
            saveOrderQuantity(gameInCart);
        }
    };

    render() {
        const {
            game,
            gameInCart,
            isOpen,
            incrementQuantity,
            decrementQuantity,
            orderQty,
            localizePosGameDetail,
            gameInCartQty,
            readOnly,
        } = this.props;

        return (
            <Modal
                open={isOpen}
                onMount={this.handleMount}
                className="game-detail-modal"
            >
                <Modal.Content>
                    <span
                        className="icon-Close static-close-button"
                        onClick={this.handleClose}
                    />
                    <Grid className="game-detail-grid">
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <div className={'pos-container-modal p-3 pb-0'}>
                                {game.image?.props.src
                                    .toString()
                                    .includes('noImage') ? (
                                    <div className={'game-name-modal'}>
                                        <div className={'text-container-modal'}>
                                            Point of Sale : <br />
                                            <b>{game.name} </b>
                                        </div>
                                    </div>
                                ) : (
                                    game.image
                                )}
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Header className="title-text bold game-title mb-20">
                                {game.gameRefAndName}
                            </Header>
                            <Grid columns={2} className={'pb-2 mb-0'}>
                                <GridRow
                                    className={
                                        'game-info-inner-wrapper m-3 mt-7'
                                    }
                                >
                                    <GridColumn className={'text-align-s'}>
                                        <span>
                                            {`${localizePosGameDetail.reference}`}
                                        </span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold">
                                            {game.reference}
                                        </span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-s'}>
                                        <span>{`${localizePosGameDetail.name}`}</span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold">
                                            {game.name}
                                        </span>
                                    </GridColumn>

                                    <GridColumn className={'text-align-s'}>
                                        <span>{`${localizePosGameDetail.gameType}`}</span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold">POS</span>
                                    </GridColumn>
                                </GridRow>
                                <GridRow
                                    className={'game-info-inner-wrapper m-3 '}
                                >
                                    <GridColumn className={'text-align-s'}>
                                        <span>{`${localizePosGameDetail.pricePoint}`}</span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold  ">
                                            {formatMoney(game.ticketPrice)}
                                        </span>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            {!readOnly && (
                                <div>
                                    <div className="add-item-action-wrapper">
                                        <NumberIncrementor
                                            label={`${localizePosGameDetail.quantity}`}
                                            value={orderQty}
                                            onIncrease={incrementQuantity}
                                            onDecrease={decrementQuantity}
                                            disabled={orderQty < 1}
                                        />
                                        {!!gameInCart && (
                                            <Button
                                                positive
                                                className="update-cart-btn"
                                                content={`${localizePosGameDetail.update}`}
                                                onClick={this.handleAddToCart}
                                                disabled={
                                                    gameInCartQty === orderQty
                                                }
                                            />
                                        )}
                                        <div className={'w-100 pb-6 pt-2'}>
                                            Min:{' '}
                                            {game.minimumOrderQuantity
                                                ? game.minimumOrderQuantity
                                                : '0'}{' '}
                                            {game.maximumOrderQuantity &&
                                            game.maximumOrderQuantity > 0
                                                ? `,Max: ${game.maximumOrderQuantity}`
                                                : ''}
                                        </div>
                                    </div>
                                    <Grid columns={2}>
                                        <GridRow>
                                            {!!gameInCart ? (
                                                <GridColumn
                                                    className={'d-flex pt-2'}
                                                >
                                                    <div className="in-cart-label">
                                                        <Icon className="icon-Cart" />
                                                    </div>
                                                    <div>
                                                        <Label
                                                            circular
                                                            color="yellow"
                                                        >
                                                            {gameInCart}
                                                        </Label>{' '}
                                                        in Cart
                                                    </div>
                                                </GridColumn>
                                            ) : (
                                                <GridColumn className={'pr-0'}>
                                                    <Button
                                                        primary
                                                        icon={
                                                            <Icon className="icon-Cart" />
                                                        }
                                                        disabled={orderQty < 1}
                                                        content={`${localizePosGameDetail.cartButton}`}
                                                        className="add-to-cart-btn m-0"
                                                        onClick={
                                                            this.handleAddToCart
                                                        }
                                                    />
                                                </GridColumn>
                                            )}
                                        </GridRow>
                                    </Grid>
                                </div>
                            )}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (
    {
        orderQuantity: { qty },
        cart: { orderedItems },
        organization: { configs },
    },
    { gameInCartQty }
) => {
    return {
        orderQty: qty,
        gameInCart: gameInCartQty,
        isInstantTicket:
            find(configs, { name: 'instant-ticket-view' })?.value === 'true',
    };
};

const mapDispatchToProps = (dispatch, { game }) => {
    return {
        incrementQuantity: () => dispatch(incrementQuantity(game)),
        decrementQuantity: () => dispatch(decrementQuantity(game)),
        initOrderQuantity: () => dispatch(initOrderQuantity(game)),
        addToCart: () => dispatch(addToCart(game)),
        saveOrderQuantity: (qty) => dispatch(saveOrderQuantity(qty)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosGameCatalogDetailModal);
