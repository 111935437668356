import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilterSection from '../../../components/filter/FilterSection';
import { generateNoParameterReport } from '../../../redux/actions';

class ReportNoFilter extends Component {
    onGenerateReport = () => {
        const { userInfo, reportId } = this.props;

        this.props.generateNoParameterReport({
            id: reportId,
            userFullName: userInfo.full_name,
            userName: userInfo.user_name,
        });
    };

    render() {
        const today = moment();
        return (
            <FilterSection
                filters={[
                    {
                        label: 'Today',
                        name: 'today',
                        isDate: true,
                        maxDate: today,
                        minDate: today,
                        defaultSelected: today,
                    },
                ]}
                onApplyFilter={this.onGenerateReport}
                applyButtonText="Generate Report"
                resetButtonText="Reset Criteria"
                showClearFilter={false}
                showResetFilter={false}
                mustSelectAllFilters={true}
            />
        );
    }
}
const mapStateToProps = ({ auth }) => {
    return { userInfo: auth.info };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateNoParameterReport: (args) =>
            dispatch(generateNoParameterReport(args)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportNoFilter)
);
