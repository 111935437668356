import {
    LOGO_LOADING,
    LOGO_LOADING_DONE,
    LOGO_SETUP,
    REPORT_LOGO_SETUP,
} from '../actions/appLogo';

const INITIAL_STATE = {
    appLogoFile: null,
    reportLogoFile: null,
    loading: false,
};

const appLogo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGO_LOADING:
            return { ...state, loading: true };
        case LOGO_LOADING_DONE:
            return { ...state, loading: false };
        case LOGO_SETUP:
            return {
                ...state,
                appLogoFile: action.appLogoFile,
                loading: false,
            };
        case REPORT_LOGO_SETUP:
            return {
                ...state,
                reportLogoFile: action.reportLogoFile,
            };
        default:
            return state;
    }
};
export default appLogo;
