import React from 'react';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import './styles.css';
import _ from 'lodash';
import { replaceWhiteSpace } from '../../../constants/utils';

export const ConfirmationModal = ({
    isOpen = false,
    actions = [],
    loading = false,
    loadingText = '',
    processing = false,
    processingText = '',
    headerContent = '',
    modalMessage = '',
    bodyContent,
    size = 'tiny',
}) => (
    <Modal className="confirm-modal" open={isOpen} size={size}>
        <Modal.Header className="modal-header">{headerContent}</Modal.Header>
        <Modal.Content className="modal-content">
            <div className="content-message">{modalMessage}</div>
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading} inverted>
                    <Loader inverted size="medium">
                        <div className="loading-text">{loadingText}</div>
                    </Loader>
                </Dimmer>
                <div className="body-content">{bodyContent}</div>
            </Dimmer.Dimmable>
        </Modal.Content>
        <Modal.Actions className="modal-actions">
            <Dimmer.Dimmable dimmed={processing}>
                <Dimmer active={processing} inverted>
                    <Loader inverted size="medium">
                        <div className="processing-text">{processingText}</div>
                    </Loader>
                </Dimmer>
                {_.map(actions, (action) => (
                    <Button
                        className="neutral-action"
                        onClick={action.onClick}
                        disabled={!!action.disabled}
                        key={`mdl-act_${replaceWhiteSpace(action.text, '-')}`}
                    >
                        {action.text}
                    </Button>
                ))}
            </Dimmer.Dimmable>
        </Modal.Actions>
    </Modal>
);
