import {
    RETAILER_SELECT_LOADING,
    RETAILER_SELECT_LOADING_DONE,
    RETAILER_SELECT_INIT,
    RETAILER_SELECT_GET,
    RETAILER_UPDATE_SELECTED_VALUES,
    RETAILER_CLEAR_QUERY_DATA,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    content: [],
    page: {},
    selectedValues: [],
};

const retailerSelect = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RETAILER_SELECT_INIT:
            return { ...INITIAL_STATE };

        case RETAILER_SELECT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case RETAILER_SELECT_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case RETAILER_SELECT_GET:
            return {
                ...state,
                content:
                    payload.searchQuery === state.searchQuery
                        ? state.content.concat(payload.content)
                        : payload.content,
                page: payload,
                searchQuery: payload.searchQuery,
            };
        case RETAILER_UPDATE_SELECTED_VALUES:
            return { ...state, selectedValues: payload };
        case RETAILER_CLEAR_QUERY_DATA:
            return { ...state, content: [], page: {} };
        default:
            return state;
    }
};
export default retailerSelect;
