import React, { useEffect } from 'react';
import { getUserRetailerInfo } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export const RetailerInfo = ({ preference }) => {
    const dispatch = useDispatch();

    const activeRetailer = useSelector(
        (state) => state.userRetailer?.activeRetailer
    );
    const retailerInfo = useSelector((state) => state.userInfo?.retailerInfo);
    useEffect(() => {
        dispatch(
            getUserRetailerInfo(
                activeRetailer?.userRetailerId?.retailerReference
            )
        );
    }, [activeRetailer, dispatch]);

    if (!activeRetailer || !retailerInfo) {
        return <div></div>;
    }

    const { name, reference, addresses } = retailerInfo;

    let address;

    if (addresses.length > 0) {
        address = addresses.find(
            (address) => address?.addressType?.code === preference
        );
    }
    if (!address && addresses.length > 0) {
        address = addresses[0];
    } else if (!address) {
        address = {};
    }
    const {
        mailingAddressLine1,
        mailingAddressLine2,
        mailingAddressLine3,
        mailingAddressLine4,
        city,
        state,
        country,
        zipCode,
    } = address;

    return (
        <div className="caption-text" data-test="retailerinfo">
            <div className="retailer-nav-info">
                <span>{`${reference} - ${name}`}</span>
            </div>
            {mailingAddressLine1 && (
                <div>
                    <span>{mailingAddressLine1}</span>
                </div>
            )}
            {mailingAddressLine2 && (
                <div>
                    <span>{mailingAddressLine2}</span>
                </div>
            )}
            {mailingAddressLine3 && (
                <div>
                    <span>{mailingAddressLine3}</span>
                </div>
            )}
            {mailingAddressLine4 && (
                <div>
                    <span>{mailingAddressLine4}</span>
                </div>
            )}
            <div>
                <span>{`${city}, ${state} ${zipCode}, ${country}`}</span>
            </div>
        </div>
    );
};
