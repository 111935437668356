import { PlanogramApi } from '../../../services/planogram-group-service';
import { RetailersApi } from '../../../services/retailers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const PLANOGRAMGROUP_UPDATE_SUCCESS = 'PLANOGRAMGROUP_UPDATE_SUCCESS';
export const UPDATE_PLANOGRAMGROUP_INIT = 'UPDATE_PLANOGRAMGROUP_INIT';
export const UPDATE_PLANOGRAMGROUP_GET_SUCCESS =
    'UPDATE_PLANOGRAMGROUP_GET_SUCCESS';
export const UPDATE_PLANOGRAM_LOADING = 'UPDATE_PLANOGRAM_LOADING';
export const UPDATE_PLANOGRAM_LOADING_DONE = 'UPDATE_PLANOGRAM_LOADING_DONE';

const planogramApi = new PlanogramApi();
const retailersApi = new RetailersApi();

const updatePlanogramError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'UpdatePlanogramGroup',
            })
        );
    };
};

export const getPlanogramById = (id) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: UPDATE_PLANOGRAM_LOADING });
        try {
            const planogram = await planogramApi.getPlanogramGroupById(id);
            const retailerForPlanogram =
                await retailersApi.getRetailersByPlanogroupId(id);

            dispatch({
                type: UPDATE_PLANOGRAMGROUP_GET_SUCCESS,
                planogram: {
                    ...planogram,
                    retailers: retailerForPlanogram,
                    facings: planogram.facings.toString(), //need it as string else redux form doesnt consider it inputted
                },
            });
        } catch (e) {
            success = false;
            dispatch(updatePlanogramError(e));
        } finally {
            dispatch({ type: UPDATE_PLANOGRAM_LOADING_DONE });
        }

        return success;
    };
};

export const updatePlanogram = (planogram) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: UPDATE_PLANOGRAM_LOADING });
        try {
            const ret = await planogramApi.updatePlanogramGroup(planogram);
            await retailersApi.updateAllRetailersWithPlanogramGroup(
                planogram.retailerIds,
                planogram.id
            );
            const retailerForPlanogram =
                await retailersApi.getRetailersByPlanogroupId(planogram.id); //get the retailer info

            dispatch({
                type: PLANOGRAMGROUP_UPDATE_SUCCESS,
                planogram: {
                    ...ret,
                    retailers: retailerForPlanogram,
                    facings: ret.facings.toString(), //need it as string else redux form doesnt consider it inputted
                },
            });
        } catch (e) {
            success = false;
            dispatch(updatePlanogramError(e));
        } finally {
            dispatch({ type: UPDATE_PLANOGRAM_LOADING_DONE });
        }

        return success;
    };
};
