import { TaskApi } from '../../../services/task-service';
import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { _getSurveyForTasks } from '../weeklyScheduler';

export const PAST_VISITS_LIST = 'PAST_VISITS_LIST';
export const PAST_VISITS_LIST_LOADING = 'PAST_VISITS_LIST_LOADING';
export const PAST_VISITS_LIST_INIT = 'PAST_VISITS_LIST_INIT';

const taskApi = new TaskApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchRetailerPastVisitTasksAsync = (args, idQueryStr) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PAST_VISITS_LIST_LOADING });
            const list = await taskApi.getUTRsFilter(args, idQueryStr);
            list.content = await _getSurveyForTasks(list.content);

            dispatch({
                type: PAST_VISITS_LIST,
                list,
            });
        } catch (err) {
            dispatch(createErrorHandlerError(err));
            console.error(err);
        }
    };
};

export const initPastVisitTasks = () => ({
    type: PAST_VISITS_LIST_INIT,
});
