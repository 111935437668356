import { Backend } from './backend';
import { checkFilters } from './utils';

class RetailerShipmentOrderApi {
    constructor() {
        this.path = 'retailers/shipments';
    }

    async searchShipments({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getShipmentById(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async createShipmentOrder(order) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/create-shipment`,
                body: order,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { RetailerShipmentOrderApi };
