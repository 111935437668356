import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Progress } from 'semantic-ui-react';
import { formatDate, formatMoney } from '../../../../constants/utils';
import config from '../../../../constants/config';
import { map, truncate, sortBy, find, max, compact } from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import subMonths from 'date-fns/subMonths';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import Widget from '../Widget';
import {
    fetchYtdTotalCommissions,
    fetchReportList,
    pushReferrer,
    setActiveItem,
} from '../../../../redux/actions';
import NoItems from '../../../../components/NoItems';

import './styles.css';
import retailerNavMenuItemNames from '../../../../constants/retailerNav';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';

const { DASHBOARD } = config.ROUTE_URLS;

export class YtdTotalCommissionsWidget extends Component {
    state = {
        lastMonth: '',
        thisMonth: '',
    };

    async componentDidMount() {
        const { fetchReportList } = this.props;
        const startOfLastMonth = subMonths(
            moment(moment()).startOf('month').toDate(),
            1
        );
        const startOfMonthBeforeLast = subMonths(
            moment(startOfLastMonth).toDate(),
            1
        );

        this.setState({
            lastMonth: startOfMonthBeforeLast,
            thisMonth: startOfLastMonth,
        });

        await fetchReportList();
        await this.init();
    }

    init = async () => {
        const { lastMonth, thisMonth } = this.state;
        const { fetchYtdTotalCommissions } = this.props;
        const endOfMonthBeforeLast = subMonths(
            moment(moment()).endOf('month').toDate(),
            2
        );
        await fetchYtdTotalCommissions(
            this.props.retailerInfo?.reference,
            formatDate(thisMonth, config.DATE_FORMAT_FOR_SERVER),
            formatDate(lastMonth, config.DATE_FORMAT_FOR_SERVER),
            formatDate(endOfMonthBeforeLast, config.DATE_FORMAT_FOR_SERVER),
            true
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    getDisplayName = (name) => {
        const { thisMonth, lastMonth } = this.state;
        const DATE_FORMAT = 'MMM, YYYY';

        const today = moment();
        const currYear = today.format('YYYY');

        switch (name) {
            case 'lastMonthCommission':
                return formatDate(lastMonth, DATE_FORMAT);
            case 'thisMonthCommission':
                return formatDate(thisMonth, DATE_FORMAT);
            case 'ytdStart':
                return formatDate(moment(`${currYear}-01-01`), DATE_FORMAT);
            case 'today':
                return formatDate(today, DATE_FORMAT);
            case 'ytdEnd':
                return formatDate(moment(`${currYear}-12-31`), DATE_FORMAT);
            default:
                return 'Year To Date';
        }
    };

    renderProgressBar = (commission) => {
        if (commission && commission.key < 3) {
            const { commissions } = this.props;

            const lastMonthCommission = commissions.lastMonthCommission;
            const thisMonthCommission = commissions.thisMonthCommission;
            const maxCommission = max([
                lastMonthCommission,
                thisMonthCommission,
            ]);

            const percentCalc = (commission.total / maxCommission) * 100;
            const percent = !isNaN(percentCalc) ? percentCalc : 0;

            return (
                <Progress
                    size="medium"
                    percent={percent}
                    className={`${
                        commission.key < 3
                            ? 'ytd-white-bar'
                            : 'sfa-min-length ytd-yellow-bar bar ytd-trim'
                    }`}
                />
            );
        } else {
            //must be year to day bar
            const { thisMonth } = this.state;
            const currMonthValue = parseInt(moment(thisMonth).format('MM'), 10);
            const value = currMonthValue || 1;

            return (
                <Progress
                    size="medium"
                    value={value}
                    total={12}
                    className={`${
                        commission.key < 3
                            ? 'ytd-white-bar'
                            : 'sfa-min-length ytd-yellow-bar bar ytd-trim'
                    }`}
                />
            );
        }
    };

    gotoMoreDetails = () => {
        const { listReports, history, setReferrer, setNavActiveItem } =
            this.props;
        const reCommissionsReport = find(listReports.content, 'Sales Reports');
        const reCommissionsReportDetails = reCommissionsReport
            ? find(reCommissionsReport['Sales Reports'], {
                  name: 'ReCommissionsReport',
              })
            : null;
        if (reCommissionsReportDetails) {
            history.push(
                `reports/ReCommissionsReport/${reCommissionsReportDetails.id}`
            );
            setReferrer({ page: 'Dashboard', path: DASHBOARD });
        }
        setNavActiveItem(retailerNavMenuItemNames.REPORTS_NAME);
    };

    getDisplayKey = (name) => {
        switch (name) {
            case 'lastMonthCommission':
                return 1;
            case 'thisMonthCommission':
                return 2;
            case 'ytdCommission':
                return 3;
            default:
                return 0;
        }
    };

    render() {
        const { loading, commissions, errorResponse } = this.props;

        const fiscalDatePercentage =
            12 /
            Math.abs(
                differenceInCalendarMonths(
                    moment(this.props.organization.fiscalDate).toDate(),
                    moment()
                )
            );

        const maxTotal = commissions?.ytdCommission;
        const displayData = map(commissions, (props, i) => {
            if (!props) return;

            return {
                key: this.getDisplayKey(i),
                displayName: this.getDisplayName(i),
                total: props,
                label: formatMoney(props),
                max: maxTotal,
                ytdTotalPercent: !isNaN(fiscalDatePercentage)
                    ? fiscalDatePercentage
                    : 0,
            };
        });

        const sortedData = sortBy(compact(displayData), 'key');

        return (
            <Widget
                loading={loading}
                footer={
                    (sortedData && sortedData.length) > 0 ? (
                        <MoreDetail onClick={this.gotoMoreDetails} />
                    ) : null
                }
                data-test="ytdTotalCommissions"
            >
                <div className="bold widget-heading">
                    Commission
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!commissions || errorResponse ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : (sortedData && sortedData.length) > 0 ? (
                    <div
                        className="widget-content-vertically-centered"
                        data-test="withData"
                    >
                        {map(sortedData, (commission) => {
                            return (
                                <div
                                    key={`${commission.key}`}
                                    className="task-completion-bar ytd-trim"
                                >
                                    <div className="label-container">
                                        <div
                                            className="bold"
                                            style={{ paddingRight: 30 }}
                                        >
                                            {truncate(commission.displayName, {
                                                length: 30,
                                            })}
                                        </div>
                                        <div
                                            className={
                                                commission.key < 3
                                                    ? 'label-content-left'
                                                    : 'label-content-right'
                                            }
                                            style={{
                                                minWidth: '7%',
                                                marginLeft:
                                                    commission.key < 3 &&
                                                    `${
                                                        (commission.total /
                                                            commission.max) *
                                                        60
                                                    }%`,
                                            }}
                                        >
                                            {formatMoney(commission.total)}
                                        </div>
                                    </div>
                                    {this.renderProgressBar(commission)}
                                    {commission.key === 2 && <hr />}
                                    {commission.key === 3 && (
                                        <div className="ytd-trim ytd-opacity">
                                            <span>
                                                {this.getDisplayName(
                                                    'ytdStart'
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: `${fiscalDatePercentage}%`,
                                                    fontWeight: 'bold',
                                                    opacity: 1,
                                                }}
                                            >
                                                {this.getDisplayName('today')}
                                            </span>
                                            <span>
                                                {this.getDisplayName('ytdEnd')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '40px' }}
                        message="You have no commissions information!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    ytdTotalCommissions: { commissions, loading, errorResponse },
    userInfo: { retailerInfo },
    organization,
    listReports,
}) => {
    return {
        loading,
        retailerInfo,
        commissions,
        organization,
        listReports,
        errorResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
        fetchReportList: () => dispatch(fetchReportList()),
        fetchYtdTotalCommissions: (
            reference,
            startDate,
            endDate,
            previousEndDate,
            isWidget
        ) =>
            dispatch(
                fetchYtdTotalCommissions(
                    reference,
                    startDate,
                    endDate,
                    previousEndDate,
                    isWidget
                )
            ),
        setNavActiveItem: (activeItem) => dispatch(setActiveItem(activeItem)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(YtdTotalCommissionsWidget)
);
