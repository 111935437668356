import { Backend } from './backend';

class TelSellCallApi {
    constructor() {
        this.path = 'retailers/tel-sell-call';
    }

    async getTelSellCallDetailsByRetailerReference(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-retailer-reference/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { TelSellCallApi };
