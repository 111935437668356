import {
    PASTDUE_TASKS,
    PASTDUE_TASKS_LOADING,
} from '../../actions/tasks/pastDueTasks';

const INITIAL_STATE = {
    loading: false,
    pastDueTasksCount: 0,
    content: {},
};

const pastDueTasks = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PASTDUE_TASKS_LOADING:
            return { ...state, loading: true };
        case PASTDUE_TASKS:
            return {
                ...state,
                content: { ...action.content },
                pastDueTasksCount: action.pastDueTasksCount,
                loading: false,
            };
        default:
            return state;
    }
};
export default pastDueTasks;
