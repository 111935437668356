import { checkFilters, getMimeType } from './utils';
import { Backend } from './backend';
import config from '../constants/config';
class DocumentsApi {
    constructor() {
        this.path = '/media/engagement/documents';
    }

    async downloadDocument(mediaKey, fileName) {
        try {
            //mediaKey should always have the file extension
            const fileType = getMimeType(mediaKey);

            return await Backend.download(
                {
                    method: 'GET',
                    url: `${this.path}/?key=${mediaKey}`,
                },
                fileName,
                fileType
            );
        } catch (e) {
            throw e;
        }
    }
    async getPublicUrl(mediaKey) {
        try {
            return `${config.BASE_URL}/${this.path}/public?key=${mediaKey}`;
        } catch (e) {
            throw e;
        }
    }

    async getDownloadUrl(mediaKey, fileName) {
        try {
            //mediaKey should always have the file extension
            const fileType = getMimeType(mediaKey);

            return await Backend.getDownloadUrl(
                {
                    method: 'GET',
                    url: `${this.path}/?key=${mediaKey}`,
                },
                fileName,
                fileType
            );
        } catch (e) {
            throw e;
        }
    }

    async uploadPortalDocument(documentData) {
        try {
            const { file, description, documentType, category } = documentData;

            let formData = new FormData();
            formData.append('file', file);
            formData.append('name', file.name);
            formData.append('category', category);
            formData.append('description', description);
            formData.append('documentType', documentType);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}`,
                body: formData,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    async getDocumentCategories() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/categories`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateDocument(documentId, description, category, fileName) {
        try {
            let formData = new FormData();
            formData.append('description', description);
            formData.append('fileName', fileName);
            formData.append('category', category);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${documentId}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteDocument(documentId) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/${documentId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllDocuments(args) {
        const { page, size, sortOrder, sortColumn, filters, query } = args;
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size: size < 1 ? 10 : size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'modifiedDate',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }
}

export { DocumentsApi };
