import { RetailersApi } from '../../services/retailers-service';
import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

export const RETAILERS_LIST = 'RETAILERS_LIST';
export const RETAILERS_LOADING = 'RETAILERS_LOADING';

const retailersApi = new RetailersApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchRetailers = (args) => {
    return async (dispatch) => {
        dispatch({ type: RETAILERS_LOADING });
        try {
            let list = await retailersApi.getAllRetailers(args);

            dispatch({
                type: RETAILERS_LIST,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchRetailersAsync = (args) => {
    return async (dispatch) => {
        let ret = {};
        try {
            ret = await retailersApi.getAllRetailers(args);
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
        return ret;
    };
};
