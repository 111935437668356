import _ from 'lodash';
import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';

import {
    getSurveyTypes,
    getMarketingSurveyTypeFromRedux,
} from '../survey/survey';

export const MARKETING_CREATE_SUCCESS = 'MARKETING_CREATE_SUCCESS';
export const MARKETING_CREATE_INIT = 'MARKETING_CREATE_INIT';
export const MARKETING_CREATE_PAGE_LOADING = 'MARKETING_CREATE_PAGE_LOADING';
export const MARKETING_CREATE_PAGE_DONE = 'MARKETING_CREATE_PAGE_DONE';
export const MARKETING_ADD_QUESTION = 'MARKETING_ADD_QUESTION';
export const MARKETING_QUESTION_SORT = 'MARKETING_QUESTION_SORT';
export const MARKETING_UPDATE_QUESTIONS = 'MARKETING_UPDATE_QUESTIONS';
export const MARKETING_UPDATE_COMBO = 'MARKETING_UPDATE_COMBO';

const surveyApi = new SurveyApi();

const createMarketingError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'createMarketingSurvey',
            })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const createMarketingSurvey = (createMarketingSurvey) => {
    return async (dispatch, getState) => {
        let success = true;
        dispatch({ type: MARKETING_CREATE_PAGE_LOADING });
        try {
            const { survey } = getState();
            //get survey type id for compliance
            const marketingType = getMarketingSurveyTypeFromRedux(survey);

            //create compliance survey payload
            const marketingSurvey = {
                surveyType: marketingType.id,
                name: createMarketingSurvey.name.trim(),
                description: createMarketingSurvey.description,
                status: createMarketingSurvey.status,
            };

            const response = await surveyApi.createSurvey(marketingSurvey);
            await surveyApi.addQuestionsToSurvey(
                response.headers.location,
                createMarketingSurvey.marketingQuestions
            );

            dispatch({
                type: MARKETING_CREATE_SUCCESS,
            });
        } catch (e) {
            success = false;
            dispatch(createMarketingError(e));
        } finally {
            dispatch({ type: MARKETING_CREATE_PAGE_DONE });
        }

        return success;
    };
};

export const initMarketingSurvey = () => {
    return async (dispatch) => {
        dispatch({ type: MARKETING_CREATE_PAGE_LOADING });
        try {
            dispatch(getSurveyTypes());
            dispatch({ type: MARKETING_CREATE_INIT });
        } catch (e) {
            dispatch(createMarketingError(e));
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: MARKETING_CREATE_PAGE_DONE });
        }
    };
};

export const addQuestion = (question) => ({
    type: MARKETING_ADD_QUESTION,
    question,
});

export const updateSortOrder = (oldSortOrder, newSortOrder) => ({
    type: MARKETING_QUESTION_SORT,
    oldSortOrder,
    newSortOrder,
});

export const deleteQuestion = (questionToDelete) => {
    return (dispatch, getState) => {
        const { createMarketingSurvey } = getState();

        const newQuestions = _.filter(
            createMarketingSurvey.questions,
            (q) => q !== questionToDelete
        );

        dispatch({
            type: MARKETING_UPDATE_QUESTIONS,
            questions: newQuestions,
        });
    };
};

export const updateComboOptions = (newComboOptions, questionTypeFieldName) => {
    return (dispatch, getState) => {
        const { createMarketingSurvey } = getState();
        const { comboOptions } = createMarketingSurvey;
        let options = { ...comboOptions };
        options[questionTypeFieldName] = newComboOptions;

        dispatch({ type: MARKETING_UPDATE_COMBO, options: options });
    };
};
