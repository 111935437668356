import { DASHBOARD_TEMP_SETTINGS_UPDATE } from '../../actions/dashboard/settings';

const INITIAL_STATE = {
    settings: [],
};

const tempSettings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_TEMP_SETTINGS_UPDATE:
            return {
                ...state,
                settings: [...action.settings],
            };
        default:
            return state;
    }
};
export default tempSettings;
