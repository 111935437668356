import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import Page from '../../layout/Page';
import SfaBreadCrumb from '../SfaBreadCrumb';
import { getPublicFaqHtml, initPublicFaq } from '../../redux/actions';

export class PublicFaq extends Component {
    componentDidMount() {
        this.props.getFaq();
    }

    componentWillUnmount() {
        this.props.init();
    }

    render() {
        const { logoImage, hideFaq, loading, faqHtml } = this.props;
        return (
            <Page data-test="publicfaq">
                <Dimmer page inverted active={loading}>
                    <Loader />
                </Dimmer>
                <div className="faq-wrapper">
                    <div className="login-logo-wrapper">
                        <img src={logoImage.src} alt={logoImage.alt} />
                    </div>
                    <SfaBreadCrumb
                        onBreadCrumb={hideFaq}
                        breadCrumbText="sign-in"
                        animation="fade right"
                    />
                    <Header className="faq-title">
                        Frequently Asked Questions
                    </Header>
                    <div
                        className="faq-content"
                        dangerouslySetInnerHTML={{
                            __html: faqHtml,
                        }}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ publicFaq: { loading, faqHtml } }) => ({
    loading,
    faqHtml,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getFaq: () => dispatch(getPublicFaqHtml()),
        init: () => dispatch(initPublicFaq()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicFaq);
