import {
    RETAILER_INSTANT_INVENTORY_INIT,
    RETAILER_INSTANT_INVENTORY,
    RETAILER_INSTANT_INVENTORY_LOADING,
    RETAILER_INSTANT_INVENTORY_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    content: [],
    loading: false,
    totalElements: 0,
    lastUpdateDate: undefined,
};

const retailerInstantInventory = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_INSTANT_INVENTORY_INIT:
            return { ...INITIAL_STATE };
        case RETAILER_INSTANT_INVENTORY_LOADING:
            return { ...state, loading: true };
        case RETAILER_INSTANT_INVENTORY:
            return {
                ...state,
                content: [...action.list],
                totalElements: action.list.length,
                lastUpdateDate: action.lastUpdateDate,
            };
        case RETAILER_INSTANT_INVENTORY_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default retailerInstantInventory;
