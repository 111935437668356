import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import 'babel-polyfill';
import store from './redux/store';
import AppLayout from './layout/AppLayout';
import { initErrorHandler } from './services/error-handler';
import config from './constants/config';
import { setupCognito } from './utils/auth/cognito';
import './assets/hamburgers.min.css';

// TODO: upgrade react to 16.6+ to use the following code
// https://medium.com/@prawira/react-conditional-import-conditional-css-import-110cc58e0da6
// import CustomerStyling from './components/customerStyling/CustomerStyling';

initErrorHandler();

if (config.COGNITO_ENABLED) {
    setupCognito();
}

class App extends Component {
    componentDidMount() {
        if (config.BUILD === 'PROD') {
            const emptyFunction = () => null;

            console.log = emptyFunction;
            console.info = emptyFunction;
            console.warn = emptyFunction;
            console.debug = emptyFunction;
        }

        console.info(`******BUILD VERSION: ${config.PACKAGE_VERSION}******`);
    }

    render() {
        return (
            <Provider store={store.store}>
                <Router>
                    {/* <CustomerStyling> */}
                    <AppLayout />
                    {/* </CustomerStyling> */}
                </Router>
            </Provider>
        );
    }
}

export default App;
