import _ from 'lodash';

import { UserApi } from '../../../services/user-service';
import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_SETTINGS_PAGE_LOAD = 'DASHBOARD_SETTINGS_PAGE_LOAD';
export const DASHBOARD_SETTINGS_LOAD = 'DASHBOARD_SETTINGS_LOAD';
export const DASHBOARD_SETTINGS_SUCCESS = 'DASHBOARD_SETTINGS_SUCCESS';
export const DASHBOARD_TEMP_SETTINGS_UPDATE = 'DASHBOARD_TEMP_SETTINGS_UPDATE';
export const DASHBOARD_SETTINGS_CLEAR = 'DASHBOARD_SETTINGS_CLEAR';
export const DASHBOARD_SETTINGS_LABEL = 'DASHBOARD_SETTINGS_LABEL';

const createErrorHandlerError = errors => {
    return dispatch => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const clearSettings = () => ({ type: DASHBOARD_SETTINGS_CLEAR });

const userApi = new UserApi();

export const updateTempDashboardSettings = settings => ({
    type: DASHBOARD_TEMP_SETTINGS_UPDATE,
    settings: _.cloneDeep(settings || [])
});

export const fetchDashboardSettings = isSettingsPage => {
    return async (dispatch, getState) => {
        const {
            organization: { phoneFaxMask }
        } = getState();
        const loadingType = isSettingsPage
            ? DASHBOARD_SETTINGS_PAGE_LOAD
            : DASHBOARD_LOADING;

        try {
            dispatch({ type: loadingType, loading: true });

            const { dashboardSettings } = getState();

            if (
                !dashboardSettings ||
                _.isEmpty(dashboardSettings.dashboardData)
            ) {
                let data = await userApi.getUserDashboardSettings();

                data.dashboardData.forEach(data => {
                    data.widgets = data.widgets.filter(
                        widget => widget.name !== 'PLACEHOLDER'
                    );

                    data.availableWidgets = data.availableWidgets.filter(
                        widget => widget.name !== 'PLACEHOLDER'
                    );

                    data.availableWidgets.forEach(widget => {
                        if (!data.widgets.find(w => w.name === widget.name)) {
                            data.widgets.push({
                                id: null,
                                name: 'PLACEHOLDER',
                                displayName: 'Placeholder',
                                position:
                                    data.widgets.length === 0
                                        ? 1
                                        : data.widgets[data.widgets.length - 1]
                                              .position + 1
                            });
                        }
                    });
                });

                dispatch(updateTempDashboardSettings(data.dashboardData || []));

                dispatch({
                    type: DASHBOARD_SETTINGS_SUCCESS,
                    data,
                    phoneFaxMask
                });
            } else {
                // TODO find permanent fix
                //create a small delay because the default tab is not showing until click
                setTimeout(() => {
                    dispatch(
                        updateTempDashboardSettings(
                            dashboardSettings.dashboardData || []
                        )
                    );
                    dispatch({ type: loadingType, loading: false });
                }, 500);
            }
        } catch (e) {
            console.error(e);
            dispatch(createErrorHandlerError(e));
            dispatch({ type: loadingType, loading: false });
        }
    };
};

export const saveSettings = (settings, callback) => {
    return async dispatch => {
        try {
            dispatch({ type: DASHBOARD_SETTINGS_LOAD, loading: true });

            const args = _.map(
                settings,
                ({ id, sectionId, position, widgets }) => ({
                    id,
                    sectionId,
                    position,
                    widgets
                })
            );

            await userApi.saveDashboardSettings(args);
            const data = await userApi.getUserDashboardSettings();
            dispatch(updateTempDashboardSettings(data.dashboardData || []));

            dispatch({
                type: DASHBOARD_SETTINGS_SUCCESS,
                data
            });

            if (callback) {
                callback();
            }
        } catch (e) {
            console.error(e);
            dispatch(createErrorHandlerError(e));
            dispatch({ type: DASHBOARD_SETTINGS_LOAD, loading: false });
        }
    };
};

export const resetSettings = callback => {
    return async dispatch => {
        try {
            dispatch({ type: DASHBOARD_SETTINGS_LOAD, loading: true });

            await userApi.resetDashboardSettings();
            const data = await userApi.getUserDashboardSettings();
            dispatch(updateTempDashboardSettings(data.dashboardData || []));

            dispatch({
                type: DASHBOARD_SETTINGS_SUCCESS,
                data
            });

            if (callback) {
                callback();
            }
        } catch (e) {
            console.error(e);
            dispatch(createErrorHandlerError(e));
            dispatch({ type: DASHBOARD_SETTINGS_LOAD, loading: false });
        }
    };
};

export const setMoreDetailLabel = values => {
    return dispatch => {
        dispatch({
            type: DASHBOARD_SETTINGS_LABEL,
            label: values
        });
    };
};
