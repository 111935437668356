import {
    PLANOGRAMS_LIST,
    PLANOGRAMS_LIST_LOADING,
} from '../actions/planograms';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
};

const planograms = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLANOGRAMS_LIST_LOADING:
            return { ...state, loading: true };
        case PLANOGRAMS_LIST:
            return {
                ...state,
                ...action.list,
                loading: false,
            };
        default:
            return state;
    }
};
export default planograms;
