import React, { Component } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import { onlyAllowNumericAndLettersInput } from '../../../constants/keyPressEvents';

class SingleTicketScanForm extends Component {
    state = { returnTicketSerial: '' };

    constructor(props) {
        super(props);
        this.scannerField = React.createRef();
    }

    handleScanChange = (event, { value }) => {
        this.setState({ returnTicketSerial: value });
    };

    handleScanKeyDown = async (event) => {
        if (event.key === 'Enter') {
            const { onSubmit } = this.props;
            const { returnTicketSerial } = this.state;

            if (returnTicketSerial) {
                if (onSubmit) {
                    await onSubmit({ returnTicketSerial });
                    this.clearScannerField();
                }
            }
        }
    };

    handleClearSearchClick = () => {
        this.clearScannerField();
    };

    clearScannerField() {
        this.setState({ returnTicketSerial: '' });
        this.scannerField && this.scannerField.current.select();
    }

    render() {
        const { processing } = this.props;
        const { returnTicketSerial } = this.state;

        const searchIcon = (
            <Icon name="close" link onClick={this.handleClearSearchClick} />
        );

        return (
            <Form loading={processing}>
                <Form.Field>
                    <Input
                        name="returnTicketSerial"
                        fluid
                        placeholder="Ticket No."
                        className="bg-transparent pending-return-input"
                        onKeyPress={onlyAllowNumericAndLettersInput}
                        onKeyDown={(event) => this.handleScanKeyDown(event)}
                        onChange={this.handleScanChange}
                        icon={searchIcon}
                        ref={this.scannerField}
                        value={returnTicketSerial}
                        autoFocus
                        disabled={processing}
                    />
                </Form.Field>
            </Form>
        );
    }
}

export default SingleTicketScanForm;
