import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import config from '../../constants/config';

const { LOGIN_PAGE, UNAUTHORIZED } = config.ROUTE_URLS;

const isAuthorized = ({ hasRole, hasAnyRole, userInfo }) => {
    if (hasRole || hasAnyRole) {
        const roles = userInfo.authorities;

        if (hasRole) {
            if (
                typeof hasRole === 'string' &&
                (hasRole === '*' || _.find(roles, (r) => r === hasRole))
            ) {
                return true;
            } else if (_.isArray(hasRole)) {
                if (
                    _.some(hasRole, (role) => {
                        return _.find(roles, (r) => r === role);
                    })
                ) {
                    return true;
                }
            }

            return false;
        }

        if (
            hasAnyRole &&
            _.some(hasAnyRole, (role) => {
                return _.find(roles, (r) => r === role);
            })
        ) {
            return true;
        }
    }

    return false;
};

const ProtectedRoute = ({
    authenticated,
    hasRole,
    hasAnyRole,
    userInfo,
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!authenticated || !userInfo) {
                    return (
                        <Redirect
                            to={{
                                pathname: config.COGNITO_ENABLED
                                    ? '/'
                                    : LOGIN_PAGE,
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                if (!isAuthorized({ hasRole, hasAnyRole, userInfo })) {
                    return (
                        <Redirect
                            to={{
                                pathname: UNAUTHORIZED,
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default connect(({ auth }) => {
    return {
        userInfo: auth.info,
        authenticated: auth.isAuthenticated,
    };
})(ProtectedRoute);
