import { RETAILERS_LIST, RETAILERS_LOADING } from '../actions/retailers';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
};

const retailers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILERS_LOADING:
            return { ...state, loading: true };
        case RETAILERS_LIST:
            return {
                ...state,
                ...action.list,
                loading: false,
            };
        default:
            return state;
    }
};
export default retailers;
