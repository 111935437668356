import { Backend } from './backend';
import { checkFilters } from './utils';

class ReturnsApi {
    constructor() {
        this.path = 'retailers/returns';
    }

    async getAllReturns({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'externalReturnNumber',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async validateBook(serial, retailerId, confirming, pendingReturnId) {
        try {
            return await Backend.send({
                url: `${
                    this.path
                }/validate/book/${serial}?retailer=${retailerId}&confirming=${!!confirming}${
                    pendingReturnId ? '&pendingReturnId=' + pendingReturnId : ''
                }`,
                method: 'POST',
            });
        } catch (e) {
            throw e;
        }
    }

    async validateTickets(
        startSerial,
        endSerial,
        retailerId,
        confirming,
        pendingReturnId
    ) {
        try {
            return await Backend.send({
                url: `${
                    this.path
                }/validate/tickets?start=${startSerial}&end=${endSerial}&retailer=${retailerId}&confirming=${!!confirming}${
                    pendingReturnId ? '&pendingReturnId=' + pendingReturnId : ''
                }`,
                method: 'POST',
            });
        } catch (e) {
            throw e;
        }
    }

    async validatePosItem(itemCode, retailerId) {
        try {
            return await Backend.send({
                url: `${
                    this.path
                }/validate/posItem/${retailerId}?itemCode=${encodeURIComponent(
                    itemCode
                )}`,
                method: 'POST',
            });
        } catch (err) {
            throw err;
        }
    }

    async isBookSplit(barCode) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/book/is-split?barcode=${barCode}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getBarcodeOrSerialDetails(barcodeOrSerial) {
        try {
            return await Backend.send({
                url: `${this.path}/barcode-or-serial/${barcodeOrSerial}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllReturnReasons() {
        try {
            return await Backend.send({
                url: `${this.path}/return-reasons`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async savePendingReturn(args) {
        try {
            return await Backend.send({
                url: `${this.path}/api`,
                method: 'POST',
                body: args,
            });
        } catch (e) {
            throw e;
        }
    }

    async cancelReturn(returnOrder) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/cancel`,
                body: returnOrder,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPendingReturnById(id) {
        try {
            return await Backend.send({
                url: `${this.path}/${id}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }
}

export { ReturnsApi };
