import {
    TASK_SUMMARY_SALES_SUCCESS,
    TASK_SUMMARY_SALES_FAIL,
    TASK_SUMMARY_SALES_LOADING,
} from '../../actions/tasks/taskSummaryWidget';

const INITIAL_STATE = {
    loading: false,
    data: {},
    error: '',
};

const taskSummaryWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TASK_SUMMARY_SALES_LOADING:
            return { ...state, loading: true };
        case TASK_SUMMARY_SALES_FAIL:
            return { ...state, loading: false, error: action.msg };
        case TASK_SUMMARY_SALES_SUCCESS:
            return {
                ...state,
                data: { ...action.data },
                loading: false,
                error: '',
            };
        default:
            return state;
    }
};
export default taskSummaryWidget;
