import {
    POS_ITEM_LIST,
    POS_ITEM_LIST_LOADING,
    POS_ITEM_LIST_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    posItems: [],
};

const posItems = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POS_ITEM_LIST:
            return {
                ...state,
                ...action.list,
                loading: false,
            };
        case POS_ITEM_LIST_LOADING:
            return { ...state, loading: true };
        case POS_ITEM_LIST_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default posItems;
