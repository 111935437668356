import { ZonesApi } from '../../../services/zones-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const ZONE_CREATE_LOADING = 'ZONE_CREATE_LOADING';
export const ZONE_CREATE_LOADING_DONE = 'ZONE_CREATE_LOADING_DONE';

const zonesApi = new ZonesApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'CreateZones' })
        );
    };
};

export const createZone = (zoneToCreate) => {
    return async (dispatch, getState) => {
        dispatch({ type: ZONE_CREATE_LOADING });
        let success = true;

        try {
            const { auth } = getState();
            const fullName = `${auth.info.lastName}, ${auth.info.firstName}`;
            zoneToCreate.createdByFullName = fullName;

            await zonesApi.createZone(zoneToCreate);
        } catch (e) {
            success = false;
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: ZONE_CREATE_LOADING_DONE });
        }
        return success;
    };
};
