import React, { Component } from 'react';
import { Checkbox, Form, Menu, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import './styles.css';
import { displayRole, hideRole } from '../../redux/actions';

const MAX_ROLES_TO_DISPLAY = 3;
const MIN_ROLES_TO_DISPLAY = 1;

class RolesFilter extends Component {
    handleRoleCheckedChange = (e, { checked, id }) => {
        const { rolesList } = this.props;
        let rolesBeingDisplayed = _.filter(rolesList, ['display', true]);
        if (checked && _.size(rolesBeingDisplayed) < MAX_ROLES_TO_DISPLAY) {
            this.props.displayRole(id);
        } else if (
            !checked &&
            _.size(rolesBeingDisplayed) > MIN_ROLES_TO_DISPLAY
        ) {
            this.props.hideRole(id);
        }
    };

    render() {
        const { rolesList } = this.props;
        let optionsList = [];
        _.forEach(rolesList, (role) => {
            optionsList.push(
                <Menu.Item key={role.id}>
                    <Form.Field>
                        <Checkbox
                            key={role.id}
                            label={role.displayName}
                            id={role.id}
                            checked={role.display}
                            onChange={this.handleRoleCheckedChange}
                        />
                    </Form.Field>
                </Menu.Item>
            );
        });
        return (
            <div data-test="RolesFilter">
                <Popup
                    className="roles-filter-popup"
                    trigger={
                        <span>
                            Show Roles <span className="icon-Arrow-Down" />
                        </span>
                    }
                    on="click"
                    position="bottom right"
                >
                    <Popup.Header>
                        {' '}
                        You can select min 1 and max 3 roles
                    </Popup.Header>
                    <Popup.Content>
                        <Menu vertical className="roles-filter-menu shadow-2">
                            {optionsList}
                        </Menu>
                    </Popup.Content>
                </Popup>
            </div>
        );
    }
}

const mapStateToProps = ({ privileges }) => ({
    ...privileges,
});

const mapDispatchToProps = (dispatch) => {
    return {
        displayRole: (id) => dispatch(displayRole(id)),
        hideRole: (id) => dispatch(hideRole(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesFilter);
