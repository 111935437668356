import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';

import semanticFormField from './form/semanticFormField';
import UneditableLabel from './UneditableLabel';

const SfaInput = ({
    labelText,
    inputPlaceholder,
    inputValue,
    inputName,
    type,
    validations,
    style,
    ...otherProps
}) => {
    return (
        <Form.Field>
            <div className="readonly">
                <UneditableLabel text={labelText} />
            </div>

            <Field
                name={inputName}
                component={semanticFormField}
                as={Form.Input}
                type={type || 'text'}
                placeholder={inputPlaceholder}
                validate={validations}
                maxLength={255}
                value={inputValue}
                className="sfa-input-control"
                style={style}
                {...otherProps}
            />
        </Form.Field>
    );
};

export default SfaInput;
