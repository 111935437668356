import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../Widget';
import { getUpcomingGamesForRetailerReference } from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import NoItems from '../../../../components/NoItems';
import numeral from 'numeral';
import './styles.css';
import {
    formatToDisplayDate,
    getNoOfDaysForUpcomingGames,
} from '../../../../constants/utils';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import config from '../../../../constants/config';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';

export class UpcomingGamesWidget extends Component {
    async componentDidMount() {
        await this.init();
    }
    init = async () => {
        const { getUpcomingGamesForRetailerReference } = this.props;
        await getUpcomingGamesForRetailerReference(
            this.props.retailerInfo?.type?.reference,
            getNoOfDaysForUpcomingGames(),
            this.props.retailerInfo?.jurisdiction?.code
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    goToGamesPage = () => {
        this.props.history.push(config.ROUTE_URLS.GAMES);
    };

    render() {
        const { loading, upcomingGamesForRetailer, errorResponse } = this.props;

        const footer = <MoreDetail onClick={this.goToGamesPage} />;

        return (
            <Widget loading={loading} footer={!errorResponse ? footer : null}>
                <div className="bold widget-heading">
                    Upcoming Games
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!upcomingGamesForRetailer && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : (upcomingGamesForRetailer &&
                      upcomingGamesForRetailer.length) > 0 ? (
                    <div
                        headClassName="progress-to-target-header summary-widget"
                        data-test="withData"
                    >
                        <Table
                            headClassName="progress-to-target-header summary-widget"
                            contentKey="item"
                            content={upcomingGamesForRetailer}
                            totalElements={upcomingGamesForRetailer.length}
                            supressSearch={true}
                            columns={[
                                {
                                    name: 'launchDate',
                                    title: 'Launch Date',
                                    formatter: (val) =>
                                        val ? formatToDisplayDate(val) : '-',
                                    columnClassName: () =>
                                        'upcomingGames-dir-col',
                                },
                                {
                                    name: 'pricePoint',
                                    title: 'Price Point',
                                    formatter: (val) =>
                                        val
                                            ? numeral(val).format('$0.00')
                                            : '$0',
                                    columnClassName: () =>
                                        'upcomingGames-dir-col',
                                },
                                {
                                    name: 'gameNumber',
                                    title: 'Game No.',
                                    columnClassName: () =>
                                        'upcomingGames-dir-col',
                                },
                                {
                                    name: 'gameName',
                                    title: 'Game Name',
                                    columnClassName: () =>
                                        'upcomingGames-right-col',
                                },
                            ]}
                        />
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no upcoming games!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    upcomingGamesWidget: { upcomingGamesForRetailer, loading, errorResponse },
}) => {
    return { loading, upcomingGamesForRetailer, retailerInfo, errorResponse };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUpcomingGamesForRetailerReference: (
            retailerType,
            noOfDaysForUpcomingGames,
            jurisdictionCode
        ) =>
            dispatch(
                getUpcomingGamesForRetailerReference(
                    retailerType,
                    noOfDaysForUpcomingGames,
                    jurisdictionCode
                )
            ),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UpcomingGamesWidget)
);
