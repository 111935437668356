import moment from 'moment';

import {
    CART_LOADING,
    CART_LOADING_DONE,
    CART_INIT,
    CART_SAVE,
    TOGGLE_CART,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    orderedItems: [],
    lastUpdated: null,
    toggleCart: false,
};

const cart = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case CART_INIT:
            return { ...INITIAL_STATE, orderedItems: [] };

        case CART_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CART_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case CART_SAVE:
            return {
                ...state,
                orderedItems: [...payload],
                lastUpdated: moment(),
            };

        case TOGGLE_CART:
            return {
                ...state,
                toggleCart: payload.isShow,
            };

        default:
            return state;
    }
};
export default cart;
