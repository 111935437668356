import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { HotKeys } from 'react-hotkeys';
import Page from '../../../layout/Page';
import SettingsTab from './SettingsTab';
import { fetchDashboardSettings } from '../../../redux/actions';
import { clearNotification } from '../../../redux/actions';

import './styles.css';

export class DashboardSettings extends Component {
    componentWillMount() {
        this.props.fetchDashboardSettings(true);
    }

    render() {
        return (
            <HotKeys
                data-test="DashboardSettings"
                innerRef={(dashboardSettings) =>
                    (this._container = dashboardSettings)
                }
            >
                <Page name="DashboardSettings">
                    <div>
                        <div className="title-section">
                            <Header className="subheader-text">
                                User Settings
                            </Header>
                        </div>
                        <SettingsTab />
                    </div>
                </Page>
            </HotKeys>
        );
    }
}

const mapStateToProps = ({
    dashboardSettings: { dashboardSettingsPageLoading },
}) => ({
    dashboardSettingsPageLoading,
    clearNotification,
});

export default connect(mapStateToProps, { fetchDashboardSettings })(
    DashboardSettings
);
