import {
    TABLE_SEARCH_CHANGE,
    TABLE_SEARCH_BUTTON_TOGGLE,
    TABLE_SEARCH_CLEAR,
    TABLE_SEARCH_PREVIOUS_VALUE,
} from '../actions';

const INITIAL_STATE = {
    searchValue: '',
    searchButtonToggle: false,
    prevSearchVal: '',
};

export const tableSearch = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TABLE_SEARCH_CHANGE:
            return { ...state, searchValue: action.value };
        case TABLE_SEARCH_BUTTON_TOGGLE:
            return { ...state, searchButtonToggle: action.toggle };
        case TABLE_SEARCH_CLEAR:
            return { ...INITIAL_STATE };
        case TABLE_SEARCH_PREVIOUS_VALUE:
            return { ...state, prevSearchVal: action.value };
        default:
            return state;
    }
};
