import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Header } from 'semantic-ui-react';
import _ from 'lodash';
import SfaForm from '../../../components/SfaForm';
import config from '../../../constants/config';
import {
    saveSettings,
    resetSettings,
    updateTempDashboardSettings,
    setActiveItem,
} from '../../../redux/actions';
import SettingsRow from './SettingsRow';
import retailerNavMenuItemNames from '../../../constants/retailerNav';

const { DASHBOARD } = config.ROUTE_URLS;

export class SettingsTab extends Component {
    componentDidMount() {
        this.props.setActiveItem(retailerNavMenuItemNames.ACCOUNT_NAME);
    }

    onCancel = () => {
        this.props.history.push(DASHBOARD);
    };

    onSave = async () => {
        await this.props.saveSettings(this.props.settings, () =>
            this.props.history.push(DASHBOARD)
        );
    };

    onWidgetSelect = (widgetName, selectedName, sectionId, index) => {
        if (widgetName === selectedName) {
            return;
        }

        const { settings } = this.props;

        const section = _.find(
            settings,
            ({ sectionId: id }) => sectionId === id
        );
        var old;
        var next;
        let newIndex = index;
        if (selectedName === 'PLACEHOLDER') {
            const noneSection = {
                id: null,
                name: 'PLACEHOLDER',
                displayName: 'None',
                position: null,
            };
            old = section.widgets[newIndex];
            next = noneSection;
            next.position = old.position;
            section.widgets[newIndex] = next;
            this.props.updateTempDashboardSettings([...settings]);
        } else if (section) {
            const oldIndex = index;
            newIndex = _.findIndex(
                section.availableWidgets,
                ({ name }) => name === selectedName
            );
            old = section.widgets[oldIndex];
            next = section.availableWidgets[newIndex];
            next.position = old.position;
            section.widgets[oldIndex] = next;
            this.props.updateTempDashboardSettings([...settings]);
        }
    };

    render() {
        const { handleSubmit, settings, settingsObject, loading } = this.props;
        const sectionCount = settings.length;

        return (
            <SfaForm
                name="updateDashboardSettings"
                onSubmit={handleSubmit(this.onSave)}
                onCancel={this.onCancel}
                showSaveCancel
                submitButtonText="Save"
                loading={loading}
                data-test="SettingsTab"
            >
                <div>
                    <Header className="info-header">Dashboard Settings</Header>
                    {_.map(_.sortBy(settings, ['position']), (section) => (
                        <SettingsRow
                            key={section.sectionId}
                            section={section}
                            sectionCount={sectionCount}
                            settingsObject={settingsObject}
                            onWidgetSelect={this.onWidgetSelect}
                        />
                    ))}
                    {settings.length === 0 && 'Not subscribed to any widgets.'}
                </div>
            </SfaForm>
        );
    }
}

const mapStateToProps = ({
    dashboardSettings: { loading },
    tempDashboardSettings: { settings },
}) => {
    const settingsObject = _.reduce(
        settings,
        (result, data) => {
            const widgets = _.reduce(
                data.widgets,
                (r, d) => ({
                    ...r,
                    [`${data.position}-${d.position}`]: d.name,
                }),
                {}
            );
            return {
                ...result,
                ...widgets,
            };
        },
        {}
    );

    return {
        settings,
        loading,
        settingsObject,
        initialValues: settingsObject, //need to init the values on the form
    };
};

export default withRouter(
    connect(mapStateToProps, {
        saveSettings,
        resetSettings,
        updateTempDashboardSettings,
        setActiveItem,
    })(
        reduxForm({
            form: 'updateDashboardSettings',
            enableReinitialize: true,
        })(SettingsTab)
    )
);
