import { Backend } from './backend';
import { checkFilters } from './utils';

class MarketingSurveyApi {
    constructor() {
        this.path = '/surveys';
    }

    async searchMarketingSurveys({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'name',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }
}

export { MarketingSurveyApi };
