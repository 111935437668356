import { TargetApi } from '../../../services/sales-target-service';
import { SalesRoutesApi } from '../../../services/sales-routes-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import moment from 'moment';
import { OrganizationApi } from '../../../services/organization-service';

export const SALES_TARGET_PROCESSING = 'SALES_TARGET_PROCESSING';
export const SALES_TARGET_CREATE_SUCCESS = 'SALES_TARGET_CREATE_SUCCESS';
export const SALES_TARGET_PAGE_DONE = 'SALES_TARGET_PAGE_DONE';
export const SALES_TARGET_CREATE_PAGE_LOADING =
    'SALES_TARGET_CREATE_PAGE_LOADING';
export const GET_TARGET_CREATE_PAGE_LOADING = 'GET_TARGET_CREATE_PAGE_LOADING';
export const SALES_TARGET_CREATE_INIT = 'SALES_TARGET_CREATE_INIT';
export const SALES_TARGET_UPDATE_INIT = 'SALES_TARGET_UPDATE_INIT';
export const TARGET_CREATE = 'TARGET_CREATE';
export const TARGET_UPDATE = 'TARGET_UPDATE';
export const SALES_TARGETS_GET_RECORD_LIST = 'SALES_TARGETS_GET_RECORD_LIST';
export const SALES_TARGETS_LOADING = 'SALES_TARGETS_LOADING';
export const SALES_TARGETS_LIST = 'SALES_TARGETS_LIST';
export const SALES_TARGETS_FISCAL_YEARS_LOADING =
    'SALES_TARGETS_FISCAL_YEARS_LOADING';
export const SALES_TARGETS_FISCAL_YEARS_LIST =
    'SALES_TARGETS_FISCAL_YEARS_LIST';

const targetApi = new TargetApi();
const organizationApi = new OrganizationApi();
const salesRoutesApi = new SalesRoutesApi();

const createSalesTargetError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'CreateSalesTarget' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const createSalesTarget = (target) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: SALES_TARGET_PROCESSING });
        try {
            await targetApi.createSalesTarget(target);
            dispatch({
                type: SALES_TARGET_CREATE_SUCCESS,
            });
        } catch (e) {
            success = false;
            dispatch(createSalesTargetError(e));
        } finally {
            dispatch({ type: SALES_TARGET_PAGE_DONE });
        }

        return success;
    };
};

export const updateSalesTarget = (id, target) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: SALES_TARGET_PROCESSING });
        try {
            await targetApi.updateSalesTarget(id, target);
            dispatch({
                type: SALES_TARGET_CREATE_SUCCESS,
            });
        } catch (e) {
            success = false;
            dispatch(createSalesTargetError(e));
        } finally {
            dispatch({ type: SALES_TARGET_PAGE_DONE });
        }

        return success;
    };
};

export const searchSalesTargets = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SALES_TARGETS_LOADING });

            const list = await targetApi.searchSalesTargets(args);

            dispatch({
                type: SALES_TARGETS_LIST,
                list,
            });
        } catch (e) {
            dispatch(createSalesTargetError(e));
        }
    };
};

const getDateOptions = async () => {
    const businessFiscalDate = await organizationApi.getBusinessFiscalDate();
    const currentFiscalYear =
        moment(businessFiscalDate).format('MMM DD/YY') +
        ' - ' +
        moment(businessFiscalDate)
            .add(1, 'years')
            .subtract(1, 'days')
            .format('MMM DD/YY');

    const nextFiscalYear =
        moment(businessFiscalDate).add(1, 'years').format('MMM DD/YY') +
        ' - ' +
        moment(businessFiscalDate)
            .add(2, 'years')
            .subtract(1, 'days')
            .format('MMM DD/YY');

    return [currentFiscalYear, nextFiscalYear];
};

export const getSalesTargetsToUpdate = (args) => {
    return async (dispatch) => {
        dispatch({ type: GET_TARGET_CREATE_PAGE_LOADING });

        try {
            const data = await targetApi.getSalesTargetsToUpdate(args);
            const salesRouteData = await salesRoutesApi.getSalesRouteById(
                data.salesRouteId
            );
            const fiscalDateOptions = await getDateOptions();
            dispatch({
                type: SALES_TARGETS_GET_RECORD_LIST,
                data: {
                    ...data,
                    drawBaseTarget:
                        data.drawBaseTarget > -1
                            ? data.drawBaseTarget.toString()
                            : '',
                    instantTarget:
                        data.instantTarget > -1
                            ? data.instantTarget.toString()
                            : '',
                    passiveTarget:
                        data.passiveTarget > -1
                            ? data.passiveTarget.toString()
                            : '',
                    vltTarget:
                        data.vltTarget > -1 ? data.vltTarget.toString() : '',
                    pulltabTarget:
                        data.pulltabTarget > -1
                            ? data.pulltabTarget.toString()
                            : '',
                    salesRouteId: [
                        {
                            id: salesRouteData.id,
                            salesManager: salesRouteData.salesManager,
                            salesRep: salesRouteData.salesRep,
                            salesManagerName: salesRouteData.salesManagerName,
                            salesRepName: salesRouteData.salesRepName,
                            name: salesRouteData.name,
                        },
                    ],
                },
                fiscalYearOptions: fiscalDateOptions,
            });
        } catch (err) {
            dispatch(createSalesTargetError(err));
        } finally {
            dispatch({ type: SALES_TARGET_PAGE_DONE });
        }
    };
};

export const initTarget = () => {
    return async (dispatch) => {
        dispatch({ type: SALES_TARGETS_LOADING });
        const fiscalDateOptions = await getDateOptions();
        dispatch({
            type: SALES_TARGET_CREATE_INIT,
            fiscalYearOptions: fiscalDateOptions,
        });
        dispatch({ type: SALES_TARGET_PAGE_DONE });
    };
};

export const getAllFiscalYearsWithSalesTargets = () => {
    return async (dispatch) => {
        dispatch({ type: SALES_TARGETS_FISCAL_YEARS_LOADING });

        try {
            const list = await targetApi.getAllFiscalYearsWithSalesTargets();
            dispatch({
                type: SALES_TARGETS_FISCAL_YEARS_LIST,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};
