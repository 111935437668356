import {
    CognitoLoginActionTypes,
    CognitoLoginStateType,
    ENABLE_LOADING,
} from '../../type/cognito/cognito-login';

const initialState: CognitoLoginStateType = {
    isLoading: true,
};
const cognitoLoginReducer = (
    state = initialState,
    action: CognitoLoginActionTypes
) => {
    switch (action.type) {
        case ENABLE_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            return state;
    }
};
export default cognitoLoginReducer;
