import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
    getPrivateContactUsData,
    initPrivateContact,
} from '../../redux/actions';
import Page from '../../layout/Page';
import ContactDetails from '../../components/contact/ContactDetails';

class ContactUsPage extends Component {
    componentDidMount() {
        this.props.getData();
    }

    componentWillUnmount() {
        this.props.initPrivateContact();
    }

    render() {
        const { loading, contact } = this.props;
        return (
            <Page name="ContactUs" title="Contact Us">
                <Dimmer page inverted active={loading}>
                    <Loader />
                </Dimmer>
                <div className="data-section">
                    <ContactDetails contact={contact} />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ privateContact }) => {
    return { ...privateContact };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => dispatch(getPrivateContactUsData()),
        initPrivateContact: () => dispatch(initPrivateContact()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
