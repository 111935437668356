import {
    surveyStatuses,
    questionTypes,
} from '../../../constants/surveyOptions';
import {
    COMPLIANCE_UPDATE_SUCCESS,
    COMPLIANCE_GET_SUCCESS,
    COMPLIANCE_UPDATE_INIT,
    COMPLIANCE_UPDATE_PAGE_LOADING,
    COMPLIANCE_UPDATE_PAGE_DONE,
} from '../../actions';

const INIT_COMPLIANCE_UPDATE = {
    id: '',
    name: '',
    description: '',
    question: '',
    questionType: null,
    status: 'DRAFT',
};

const INITIAL_STATE = {
    loading: false,
    complianceTemplate: { ...INIT_COMPLIANCE_UPDATE },
    complianceOptions: {
        questionTypes: [...questionTypes],
        statuses: [...surveyStatuses],
    },
};

const updateCompliance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPLIANCE_UPDATE_PAGE_LOADING:
            return { ...state, loading: true };
        case COMPLIANCE_UPDATE_PAGE_DONE:
            return { ...state, loading: false };
        case COMPLIANCE_UPDATE_SUCCESS:
            return {
                ...state,
                complianceTemplate: { ...INIT_COMPLIANCE_UPDATE },
            };
        case COMPLIANCE_GET_SUCCESS:
            return {
                ...state,
                complianceTemplate: { ...action.complianceObject },
            };
        case COMPLIANCE_UPDATE_INIT:
            return {
                ...INITIAL_STATE,
                complianceTemplate: { ...INIT_COMPLIANCE_UPDATE },
            };
        default:
            return state;
    }
};
export default updateCompliance;
