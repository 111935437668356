import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import { getComplianceSurveyType } from '../survey/survey';

export const COMPLIANCE_LIST = 'COMPLIANCE_LIST';
export const COMPLIANCE_LIST_LOADING = 'COMPLIANCE_LIST_LOADING';
export const COMPLIANCE_LIST_LOADING_DONE = 'COMPLIANCE_LIST_LOADING_DONE';

const surveyApi = new SurveyApi();

const complianceListError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'CompliancePage' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchSurveys = (args) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: COMPLIANCE_LIST_LOADING });

            const complianceSurveyType = await dispatch(
                getComplianceSurveyType()
            );

            //add surveyType to filters to only get compliance
            const filtersWithCompliance = args.filters
                ? [
                      ...args.filters,
                      {
                          property: 'surveyType',
                          data: [complianceSurveyType.id],
                      },
                  ]
                : [
                      {
                          property: 'surveyType',
                          data: [complianceSurveyType.id],
                      },
                  ];

            const listArgs = { ...args, filters: filtersWithCompliance };

            const list = await surveyApi.searchSurveys(listArgs);

            dispatch({
                type: COMPLIANCE_LIST,
                list,
            });
        } catch (e) {
            console.error(e);
            dispatch(complianceListError(e));
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: COMPLIANCE_LIST_LOADING_DONE });
        }
    };
};
