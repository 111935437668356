import { Backend } from './backend';

class RetailerPosApi {
    constructor() {
        this.path = 'retailers/retailerPos';
    }

    async listRetailerPosItems(
        { page, size, sortOrder, sortColumn },
        retailerReference
    ) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search/${retailerReference}`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'posItem.name',
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async createRetailerPos(retailerPosToCreate) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/`,
                body: retailerPosToCreate,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateRetailerPos(retailerPos) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/${retailerPos.id}`,
                body: retailerPos,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteRetailerPos(retailerPosId) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/${retailerPosId}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { RetailerPosApi };
