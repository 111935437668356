import {
    NOTIFY_ERROR,
    NOTIFY_SUCCESS,
    NOTIFY_WARN,
    NOTIFY_CLEAR,
    NOTIFY_QUEUE_INFO,
    NOTIFY_MULTI_NOTIFICATION,
} from '../actions/notifications';

const INITIAL_STATE = {
    msg: '',
    target: null,
    type: null,
};

const notifications = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFY_WARN:
            return {
                ...INITIAL_STATE,
                type: 'warning',
                msg: action.msg,
                target: action.target,
            };
        case NOTIFY_ERROR:
            return {
                ...INITIAL_STATE,
                type: 'error',
                msg: action.msg,
                target: action.target,
            };
        case NOTIFY_SUCCESS:
            return {
                ...INITIAL_STATE,
                type: 'success',
                msg: action.msg,
                target: action.target,
            };
        case NOTIFY_QUEUE_INFO:
            return {
                ...INITIAL_STATE,
                type: 'queueInfo',
                msg: action.msg,
                target: action.target,
            };

        case NOTIFY_MULTI_NOTIFICATION:
            return {
                ...INITIAL_STATE,
                type: 'multiNotification',
                msg: action.msg,
                target: action.target,
            };

        case NOTIFY_CLEAR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default notifications;
