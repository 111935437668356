import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Popup, Input } from 'semantic-ui-react';
import Calendar from './calendar/Calendar';
import UneditableLabel from './UneditableLabel';
import { formatToDisplayDate } from '../constants/utils';

class CalendarPicker extends Component {
    state = {
        formattedDate: '',
        dateValue: '',
        isPopUpOpen: false,
    };

    componentWillMount = () => {
        const { value, minDate, maxDate } = this.props;
        this.setDate(value, minDate);
        this.setDate(value, maxDate);
    };

    //if prop val updates, update the state
    componentWillReceiveProps = (nextProps) => {
        const { value, minDate, maxDate } = nextProps;
        this.setDate(value, minDate);
        this.setDate(value, maxDate);
    };

    setDate = (date, minDate, maxDate) => {
        const { enforceMinDate } = this.props;

        if (date) {
            if (minDate && enforceMinDate) {
                const momentMinDate = moment(minDate);
                const momentDateVal = moment(date);

                //check to see if minDate is less than date passed else set date to min date
                if (momentDateVal.diff(momentMinDate) < 0) {
                    this.setDateState(minDate);
                } else {
                    this.setDateState(date);
                }
            } else if (maxDate) {
                const momentMaxDate = moment(maxDate);
                const momentDateVal = moment(date);

                if (momentDateVal.diff(momentMaxDate) > 0) {
                    this.setDateState(maxDate);
                } else {
                    this.setDateState(date);
                }
            } else {
                this.setDateState(date);
            }
        }
    };

    setDateState = (date) => {
        const formattedDate = `${formatToDisplayDate(date)} ${
            moment(date).isSame(moment(new Date()), 'day') ? '(Today)' : ''
        }`;
        this.setState({
            formattedDate: formattedDate,
            dateValue: moment(date),
        });
        this.forceUpdate();
    };

    dateChange = (value) => {
        const newDate = moment(value);

        this.setState({ dateValue: newDate, formattedDate: value });
        this.props.handleChange(newDate, value, this.props.name);
    };

    togglePopUp = (isShow) => {
        this.setState({ isPopUpOpen: isShow });
    };

    render() {
        const {
            disabled,
            labelText,
            minDate,
            maxDate,
            name,
            disabledText,
            format,
            showYear,
        } = this.props;
        return (
            <Form.Field>
                <div className="readonly">
                    <UneditableLabel text={labelText} />
                </div>
                <Popup
                    trigger={
                        <Input
                            name={name}
                            icon="calendar"
                            value={
                                disabled && disabledText
                                    ? disabledText
                                    : this.state.formattedDate
                            }
                            disabled={disabled}
                            readOnly
                        />
                    }
                    content={
                        disabled && disabledText ? null : (
                            <Calendar
                                selectedDate={this.state.dateValue}
                                onValuesChange={(value) => {
                                    this.dateChange(value);
                                    this.togglePopUp(false);
                                }}
                                minDate={minDate}
                                format={format}
                                maxDate={maxDate}
                                showYear={showYear}
                            />
                        )
                    }
                    on="click"
                    style={{ height: '290px' }}
                    open={this.state.isPopUpOpen}
                    onOpen={() => {
                        this.togglePopUp(true);
                    }}
                    onClose={() => {
                        this.togglePopUp(false);
                    }}
                />
            </Form.Field>
        );
    }
}

CalendarPicker.propTypes = {
    disabled: PropTypes.bool,
    minDate: PropTypes.instanceOf(moment),
    labelText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default CalendarPicker;
