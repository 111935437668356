import React, { PureComponent } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import _, { sortBy, uniqBy, map, find } from 'lodash';
import Page from '../../layout/Page';
import GameCatalog from '../../components/gameCatalog/GameCatalog';
import './styles.css';

import { connect } from 'react-redux';
import { formatMoney } from '../../constants/utils';
import { clearNotification, getAllAdminGames } from '../../redux/actions';
class GamesListPage extends PureComponent {
    state = { games: [], retailerTypes: [], loading: false };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const { isInstantTicket } = this.props;
        await this.props.getAllAdminGames(isInstantTicket);
    };

    formatPrice = (price) => formatMoney(price);

    getPriceList = () => {
        let data = uniqBy(
            map(sortBy(this.props.filteredGames, 'price'), (game) => {
                if (game.gameType === 'PS') {
                    return {
                        name: 'POS',
                        id: game.gameType,
                    };
                } else {
                    return {
                        name: this.formatPrice(game.price),
                        id: game.price,
                    };
                }
            }),
            'name'
        );
        try {
            const index = data.findIndex((item) => item.name === 'POS');
            if (index !== -1) {
                const posItem = data[index];
                data.splice(index, 1);
                data.splice(data.length, 0, posItem);
            }
        } catch (e) {}
        return data;
    };

    getHeaderType = () => {
        const { isInstantTicket } = this.props;
        return isInstantTicket ? this.getPriceList() : this.getRetailerTypes();
    };
    getRetailerTypes = () =>
        sortBy(
            uniqBy(
                map(this.props.filteredGames, (game) => ({
                    name: game.retailerType,
                    id: game.id,
                })),
                'name'
            ),
            'name'
        );
    formatHeaderText = (game) => formatMoney(game?.price);
    render() {
        const { isInstantTicket, filteredGames, loading, games } = this.props;
        const themes = _.uniqBy(games, function (game) {
            return game.themeName;
        });
        return (
            <Page
                className="game-list"
                name="GameCatalogPage"
                data-test="GameCatalogPage"
            >
                <Dimmer active={loading} page inverted>
                    <Loader />
                </Dimmer>
                <GameCatalog
                    headerList={this.getHeaderType()}
                    themes={themes}
                    games={filteredGames}
                    groupByProp={
                        isInstantTicket ? 'formatedPrice' : 'retailerType'
                    }
                    groupByPropForPos={isInstantTicket ? 'headerText' : ''}
                    sortByProp={
                        isInstantTicket ? 'gameRefAndName' : 'ticketPrice'
                    }
                    headerKey={
                        isInstantTicket ? this.formatHeaderText : 'retailerType'
                    }
                    stickyHeader={false}
                    readOnly
                />
            </Page>
        );
    }
}
const mapStateToProps = ({
    gameItems: { filteredGames, content, loading },
    organization: { configs },
}) => {
    return {
        isInstantTicket:
            find(configs, { name: 'instant-ticket-view' })?.value === 'true',
        filteredGames,
        loading: loading,
        games: content,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllAdminGames: (isInstantTicket) =>
            dispatch(getAllAdminGames(isInstantTicket)),
        clearNotifications: () => dispatch(clearNotification()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesListPage);
