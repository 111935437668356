import querystring from 'querystring';
import { checkFilters } from './utils';
import { Backend } from './backend';

class SalesRoutesApi {
    constructor() {
        this.path = '/sales-routes';
    }

    async getAllSalesRoutes({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'name',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllActiveSalesRoutes() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/active`,
            });
        } catch (e) {
            throw e;
        }
    }

    async listAllSalesRoutes() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/`,
            });
        } catch (e) {
            throw e;
        }
    }

    async createSalesRoute(salesRoute) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/create`,
                body: salesRoute,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSalesRoute(salesRoute) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/update`,
                body: salesRoute,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteById(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteByRetailerId(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/for-retailer/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async saveSalesRouteToRetailer(retailerId, salesRouteId) {
        try {
            const params = `?${querystring.stringify({
                'retailer-id': retailerId,
            })}`;

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${salesRouteId}/assign-retailer${params}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async unassignSalesRouteToRetailer(retailerId, salesRouteId) {
        try {
            const params = `?${querystring.stringify({
                'retailer-id': retailerId,
            })}`;

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${salesRouteId}/unassign-retailer${params}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerIdsForSalesRoutesForSalesRep(username) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/managed-retailers/for-sales-rep/${username}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerIdsForSalesRoutesForSalesManager(username) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/managed-retailers/for-sales-manager/${username}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteByRetailerIds(retailerIdList) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/by-retailer-ids`,
                body: retailerIdList,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteBySalesRep(salesRep) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-sales-rep/${salesRep}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteBySalesManager(salesManager) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-sales-manager/${salesManager}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesRouteByAllSalesManager() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-all-sales-managers`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { SalesRoutesApi };
