import {
    TOTAL_PURCHASE_YTD_PP_INIT,
    TOTAL_PURCHASE_YTD_PP_LOADING,
    TOTAL_PURCHASE_YTD_PP,
    TOTAL_PURCHASE_YTD_PP_ERROR,
} from '../../../../actions/retailerDashboard/widgets/totalPurchaseYTDByPP/totalpurchaseYTDByPP';

const INITIAL_STATE = {
    totalPurchaseYTDByPPs: undefined,
    loading: false,
    errorResponse: null,
};

const totalPurchaseYTDByPP = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOTAL_PURCHASE_YTD_PP_INIT:
            return { ...INITIAL_STATE };
        case TOTAL_PURCHASE_YTD_PP_LOADING:
            return { ...state, loading: true };
        case TOTAL_PURCHASE_YTD_PP:
            return {
                ...state,
                totalPurchaseYTDByPPs: action.totalPurchaseYTDByPPs,
                loading: false,
                errorResponse: null,
            };
        case TOTAL_PURCHASE_YTD_PP_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default totalPurchaseYTDByPP;
