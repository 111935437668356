import _ from 'lodash';

import {
    FILTER_INIT,
    TOGGLE_FILTER,
    FILTER_ADD_SELECTION,
    FILTER_REPLACE_SELECTION,
    FILTER_REMOVE_SELECTION,
    FILTER_ADD_ALL_SELECTION,
    FILTER_UPDATE_ALL_SELECTION,
    FILTER_REMOVE_ALL_SELECTION,
    FILTER_CLEAR_ALL,
    FILTER_RESET,
    FILTER_APPLY,
} from '../actions';

const INITIAL_STATE = {
    name: null,
    toggle: false,
    selectedItems: {},
    clearAll: false,
    applied: false,
};

export const tableFilters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_FILTER:
            return {
                ...state,
                name: action.payload.name,
                toggle: action.payload.toggle,
            };
        case FILTER_INIT:
            return {
                ...state,
                selectedItems: {
                    ...state.selectedItems,
                    [action.name]: action.values,
                },
            };
        case FILTER_ADD_SELECTION: {
            const { filterName } = action.payload;
            const existingItem = state.selectedItems[filterName] || [];

            const selectedItems = {
                ...state.selectedItems,
                [filterName]: _.uniq([...existingItem, action.payload.item]),
            };

            return {
                ...state,
                selectedItems,
            };
        }
        case FILTER_REPLACE_SELECTION: {
            const { filterName } = action.payload;

            const selectedItems = {
                ...state.selectedItems,
                [filterName]: [action.payload.item],
            };

            return {
                ...state,
                selectedItems,
            };
        }
        case FILTER_ADD_ALL_SELECTION: {
            const { filterName, items } = action.payload;

            const itemsToSelect = {
                ...state.selectedItems,
                [filterName]: [...items],
            };

            return {
                ...state,
                selectedItems: itemsToSelect,
            };
        }
        case FILTER_UPDATE_ALL_SELECTION: {
            const { filterName, items } = action.payload;

            const itemsToSelect = {
                ...state.selectedItems,
                [filterName]: _.uniq([
                    ...state.selectedItems[filterName],
                    ...items,
                ]),
            };

            return {
                ...state,
                selectedItems: itemsToSelect,
            };
        }
        case FILTER_REMOVE_SELECTION: {
            const { filterName, item } = action.payload;

            const items = state.selectedItems[filterName];
            if (!items) {
                return state;
            }

            const newItems = _.filter(items, (i) => i !== item);

            return {
                ...state,
                selectedItems: {
                    ...state.selectedItems,
                    [filterName]: [...newItems],
                },
            };
        }
        case FILTER_REMOVE_ALL_SELECTION: {
            const { filterName } = action.payload;

            return {
                ...state,
                selectedItems: {
                    ...state.selectedItems,
                    [filterName]: [],
                },
            };
        }
        case FILTER_CLEAR_ALL:
            return {
                ...INITIAL_STATE,
                clearAll: true,
                selectedItems: { applied: false },
            };
        case FILTER_RESET:
            return {
                ...INITIAL_STATE,
                clearAll: false,
                selectedItems: { applied: false },
            };
        case FILTER_APPLY:
            return {
                ...state,
                selectedItems: { ...state.selectedItems, applied: true },
            };
        default:
            return state;
    }
};
