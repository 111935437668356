import { TextNotesApi } from '../../../services/text-notes-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const TEXT_NOTE_LOADING = 'TEXT_NOTE_LOADING';
export const TEXT_NOTE_LOADING_DONE = 'TEXT_NOTE_LOADING_DONE';
export const TEXT_NOTE_INFO_UPDATE = 'TEXT_NOTE_INFO_UPDATE';
export const INIT_TEXT_NOTE_INFO = 'INIT_TEXT_NOTE_INFO';

const textNotesApi = new TextNotesApi();

const errorHandler = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'ReadTextNotes' })
        );
    };
};

export const getTextNoteById = (retailerId) => {
    return async (dispatch) => {
        dispatch({ type: TEXT_NOTE_LOADING });

        try {
            const data = await textNotesApi.getTextNoteById(retailerId);
            dispatch({
                type: TEXT_NOTE_INFO_UPDATE,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch(errorHandler(e));
        }
    };
};

export const initTextNoteData = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_TEXT_NOTE_INFO });
    };
};
