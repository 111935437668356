export const APP_READY = 'APP_READY';

export const setAppIsReady = (isAppReady) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({
            type: APP_READY,
            appReady: auth.isAuthenticated && !isAppReady,
        });
    };
};
