import {
    SALES_ROUTE_OPTIONS_LOADING,
    GET_OPTIONS_SUCCESS,
} from '../actions/salesRouteOptions';

const INITIAL_STATE = {
    loading: false,
    options: [],
};

const salesRouteOptions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_ROUTE_OPTIONS_LOADING:
            return { ...state, loading: true };
        case GET_OPTIONS_SUCCESS:
            return { ...state, options: action.options, loading: false };
        default:
            return state;
    }
};
export default salesRouteOptions;
