import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Transition } from 'semantic-ui-react';
import config from '../../../constants/config';

const animationLen = config.ANIMATION_LENGTH;

export default function SemanticCheckbox({
    input,
    type,
    label,
    meta: { touched, error, warning, pristine },
    as: As = Checkbox,
    ...props
}) {
    const { value, ...otherInputProps } = input;

    function handleChange(e, { checked }) {
        return input.onChange(checked);
    }
    return (
        <Form.Field>
            <As
                {...props}
                {...otherInputProps}
                type={type}
                label={label}
                onChange={handleChange}
                className={touched && error ? 'invalid' : ''}
            />
            <Transition
                visible={touched && error}
                animation="fade"
                duration={animationLen}
            >
                <span className="error-text">
                    <i>{error}</i>
                </span>
            </Transition>
            <Transition
                visible={touched && warning}
                animation="fade"
                duration={animationLen}
            >
                <span className="warning-text">
                    <i>{warning}</i>
                </span>
            </Transition>
        </Form.Field>
    );
}

SemanticCheckbox.propTypes = {
    as: PropTypes.any,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
};
