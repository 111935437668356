import _ from 'lodash';
import i18n from 'react-intl-universal';

import { I18nApi } from '../../services/i18n-service';

export const I18N_LOAD_RESOURCES_SUCCESS = 'I18N_LOAD_RESOURCES_SUCCESS';
export const I18N_LOAD_SUPPORTED_LOCALES = 'I18N_LOAD_SUPPORTED_LOCALES';

const i18nApi = new I18nApi();

export const loadSupportedLocales = () => {
    return async (dispatch) => {
        try {
            const data = await i18nApi.getSupportedLocales();

            dispatch({
                type: I18N_LOAD_SUPPORTED_LOCALES,
                locales: data,
            });
        } catch (e) {
            console.error('unable to retrieved supported languages');
        }
    };
};

export const loadLocalizedResources = (locale) => {
    return async (dispatch, getState) => {
        try {
            const data = await i18nApi.getLocalizedResources(locale);

            const resources = _.reduce(
                data,
                (result, { key, message }) => {
                    return {
                        ...result,
                        [key]: message,
                    };
                },
                {}
            );

            const warningHandler = (message) => {
                //remove info on the component being used
                console.warn(message.replace('react-intl-universal', ''));
            };

            i18n.init({
                currentLocale: locale,
                locales: {
                    [locale]: resources,
                },
                warningHandler,
            });

            dispatch({
                type: I18N_LOAD_RESOURCES_SUCCESS,
                resources: resources,
                currentLocale: locale,
            });
        } catch (e) {
            tryPersistedLocales(getState);
        }
    };
};

const tryPersistedLocales = (state) => {
    const { i18n } = state;
    if (i18n) {
        const { currentLocale, localResources } = i18n;
        if (currentLocale && localResources) {
            i18n.init({
                currentLocale,
                locales: {
                    [currentLocale]: localResources,
                },
            });
            console.warn('i18n unavailable. Using previously saved language');
            return;
        }
    }
    console.error('i18n unavailable. Using all default values');
};
