import { Backend } from './backend';
import type { UserPreference } from '../DTOs/user-preference';

class UserPreferenceApi {
    constructor() {
        this.path = '/users/preference';
    }
    async getUserPreferenceByKey(key) {
        try {
            return await Backend.send({
                url: `${this.path}/${key}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }
    async saveUserPreferenceByKey(userPreference: UserPreference) {
        try {
            return await Backend.send({
                url: `${this.path}`,
                method: 'POST',
                body: userPreference,
            });
        } catch (e) {
            throw e;
        }
    }
}
export default UserPreferenceApi;
