const widgetNames = {
    WIDGET_RE_TOUR: 'RE_TOUR',
    WIDGET_RE_COMMISSIONS: 'RE_COMMISSIONS',
    WIDGET_RE_TOP_SELLING_GAMES_PP: 'RE_TOP_SELLING_GAMES_PP',
    WIDGET_RE_CAROUSEL: 'RE_CAROUSEL',
    WIDGET_RE_LAST_ORDER_SUMMARY: 'RE_LAST_ORDER_SUMMARY',
    WIDGET_RE_TOTAL_PURCHASE_YTD: 'RE_TOTAL_PURCHASE_YTD',
    WIDGET_RE_TOTAL_PURCHASE_YTD_PP: 'RE_TOTAL_PURCHASE_YTD_PP',
    WIDGET_RE_TOTAL_PURCHASE_YTD_NON_PRODUCTS:
        'RE_TOTAL_PURCHASE_YTD_NON_PRODUCTS',
    WIDGET_RE_SYSTEM_TIME: 'RE_SYSTEM_TIME',
    WIDGET_RE_SERVICES_VERSIONS: 'RE_SERVICES_VERSIONS',
    WIDGET_RE_PLANOGRAMS: 'RE_PLANOGRAMS',
    WIDGET_RE_UPCOMING_GAMES: 'RE_UPCOMING_GAMES',
    WIDGET_RE_CLOSING_GAMES: 'RE_CLOSING_GAMES',
};
export default widgetNames;
