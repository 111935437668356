import React from 'react';
import DateRangeFilter from './DateRangeFilter';
import ReportNoFilter from './ReportNoFilter';

const ReportFilter = ({ name, reportId }) => {
    switch (name) {
        case 'ReCommissionsReport':
        case 'ReInvoiceReport':
        case 'ReOrderDetail':
            return <DateRangeFilter reportId={reportId} maxRangeDays={365} />;
        default:
            return <ReportNoFilter reportId={reportId} />;
    }
};

export default ReportFilter;
