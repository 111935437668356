import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filter, map } from 'lodash';
import { getUserRoles } from '../redux/actions';
import { required } from '../constants/validate';
import { PSelect } from './form';
import { Field } from 'redux-form';

class UserRoles extends Component {
    componentWillMount() {
        this.props.getUserRoles();
    }

    render() {
        const { roleData } = this.props;
        const roleOptions = filter(roleData, (r) => r.name !== 'DEVELOPER');

        return (
            <Field
                name="roles"
                component={PSelect}
                label="User Roles"
                placeholder="Select User Role"
                fieldName="roles"
                search={false}
                options={map(roleOptions, (role) => ({
                    text: role.displayName,
                    value: role.name,
                }))}
                validate={required}
            />
        );
    }
}

const mapStateToProps = ({ userRoles: { roleData } }) => ({
    roleData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getUserRoles: () => dispatch(getUserRoles()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
