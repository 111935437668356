import {
    DOCUMENT_LIST,
    DOCUMENT_LOADING,
    DOCUMENT_LOADING_DONE,
    DOCUMENT_ATTACHMENT_INIT,
    DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE,
    DOCUMENT_ATTACHMENT_UPLOAD_LOADING,
} from '../../actions/documentUpload/documentUpload';

import {
    MULTIPLE_DOCUMENT_LIST,
    MULTIPLE_DOCUMENT_LOADING,
    MULTIPLE_DOCUMENT_LOADING_DONE,
    ACTIVE_MESSAGE_FILES,
    ALL_MESSAGE_FILE,
    GET_EDIT_MESSAGE_FILE,
    UPDATE_DESCREPTION,
} from '../../actions/multiDocumentUpload/MultiDocumentUpload';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
    category: {
        id: 0,
        name: '',
    },
    categoryOptions: [],
    files: null,
    fileImages: null,
    document: undefined,
};

const documentUpload = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DOCUMENT_LOADING:
            return { ...state, loading: true };
        case DOCUMENT_LIST:
        case MULTIPLE_DOCUMENT_LIST:
            return {
                ...state,
                ...action.data,
            };
        case ACTIVE_MESSAGE_FILES:
            return {
                ...state,
                files: {
                    ...action.data,
                },
            };
        case ALL_MESSAGE_FILE:
            return {
                ...state,
                fileImages: {
                    ...action.data,
                },
            };
        case GET_EDIT_MESSAGE_FILE:
            return {
                ...state,
                fileImages: {
                    ...action.data,
                },
            };
        case DOCUMENT_ATTACHMENT_INIT:
            return {
                ...state,
                document: action.attachment,
                categoryOptions: action.options,
            };
        case DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE:
            return { ...state, loading: false };
        case DOCUMENT_ATTACHMENT_UPLOAD_LOADING:
            return { ...state, loading: true };
        case DOCUMENT_LOADING_DONE:
            return { ...state, loading: false };
        case MULTIPLE_DOCUMENT_LOADING:
            return { ...state, loading: true };
        case MULTIPLE_DOCUMENT_LOADING_DONE:
            return { ...state, loading: false };
        case UPDATE_DESCREPTION:
            return { ...state, description: action.data };

        default:
            return state;
    }
};
export default documentUpload;
