import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../Widget';
import config from '../../../../constants/config';
import {
    getLastOrderSummaryForRetailer,
    pushReferrer,
} from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import NoItems from '../../../../components/NoItems';

import './styles.css';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import { Icon } from 'semantic-ui-react';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { formatToDisplayDate } from '../../../../constants/utils';
const { ORDER_TAB_PROGRESS, DASHBOARD } = config.ROUTE_URLS;
export class LastOrderSummaryWidget extends Component {
    async componentDidMount() {
        await this.init();
    }

    init = async () => {
        const { getLastOrderSummaryForRetailer } = this.props;
        await getLastOrderSummaryForRetailer(
            this.props.retailerInfo?.reference
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    goToPastOrders = () => {
        this.props.history.push(ORDER_TAB_PROGRESS);
        this.props.pushReferrer({ page: 'Dashboard', path: DASHBOARD });
    };

    render() {
        const { loading, lastOrderSummaries, errorResponse } = this.props;
        const footer = <MoreDetail onClick={this.goToPastOrders} />;
        let lastOrderDate = null;
        let data = lastOrderSummaries?.itemSummaries;
        let orderDate = lastOrderSummaries?.lastOrderDate;
        if (orderDate) {
            lastOrderDate = (
                <div className="bold price-point">
                    Last Order : {formatToDisplayDate(orderDate)}
                </div>
            );
        }

        return (
            <Widget loading={loading} footer={!errorResponse ? footer : ''}>
                <div className="bold widget-heading">
                    Last Order Summary
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!lastOrderSummaries && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : data?.length > 0 ? (
                    <div>
                        {lastOrderDate}
                        <div
                            className="last-order-summary-widget-content"
                            data-test="withData">
                            <Table
                                headClassName="progress-to-target-header summary-widget"
                                contentKey="item"
                                content={data}
                                totalElements={data.length}
                                supressSearch={true}
                                columns={[
                                    {
                                        name: 'item',
                                        title: 'Item Name',
                                        columnClassName: () =>
                                            'last-order-summary-dir-col',
                                    },
                                    {
                                        name: 'quantity',
                                        title: 'Quantity',
                                        columnClassName: () =>
                                            'last-order-summary-right-col',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no orders!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    lastOrderSummary: { lastOrderSummaries, loading, errorResponse },
    organization: { portalDateTimeFormat },
}) => {
    // const { lastOrderSummaries } = ;
    return {
        portalDateTimeFormat,
        loading,
        lastOrderSummaries,
        retailerInfo,
        errorResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushReferrer: (ref) => dispatch(pushReferrer(ref)),

        getLastOrderSummaryForRetailer: (retailerReference) =>
            dispatch(getLastOrderSummaryForRetailer(retailerReference)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LastOrderSummaryWidget)
);
