import axios from 'axios/index';
import { saveNewUserValidTime, logout } from '../redux/actions/auth';
import config from '../constants/config';
import Store from '../redux/store';

class Backend {
    static async isUserActive() {
        try {
            const { auth } = Store.store.getState();
            const { timeUserCheckedActive, info } = auth;
            const timeoutUserActive = config.AUTH_TIMEOUT;

            //if doesn't have username let it kick out on subsequent requests
            if (
                info &&
                info.user_name &&
                Math.floor(
                    (new Date() - new Date(timeUserCheckedActive)) / 60000,
                ) > timeoutUserActive
            ) {
                const { data } = await axios({
                    method: 'GET',
                    url: `/users/is-enabled?username=${info.user_name}`,
                    headers: { Authorization: await this.getAuthHeader() },
                });

                if (data) {
                    Store.store.dispatch(saveNewUserValidTime());
                } else {
                    Store.store.dispatch(logout());
                }
            }
        } catch (e) {
            Store.store.dispatch(logout());
            throw e;
        }
    }

    static async getAuthHeader() {
        let authHeader = '';
        const { auth } = Store.store.getState();
        if (auth?.info?.exp * 1000 > Date.now()) {
            const { token } = auth;
            authHeader = token
                ? `${token.token_type} ${token.access_token}`
                : null;
        } else {
            Store.store.dispatch(logout());
        }

        const { token } = auth;
        authHeader = token ? `${token.token_type} ${token.access_token}` : null;
        return authHeader;
    }

    static async send(args) {
        try {
            const { data } = await this.sendReturnWithCompleteResponse(args);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendNoAuth(args) {
        const {
            url,
            method,
            body,
            headers,
            timeout,
            contentType,
            responseType,
            noAuth,
        } = args;
        try {
            const _headers = headers || {
                Authorization: noAuth ? null : await this.getAuthHeader(),
                'Content-Type': contentType ? contentType : 'application/json',
            };

            const request = {
                method,
                url,
                data: body,
                headers: _headers,
            };

            if (timeout) {
                request.timeout = timeout;
            }

            if (responseType) {
                request.responseType = responseType;
            }

            const { data } = await axios(request);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendReturnWithCompleteResponse(args) {
        const {
            url,
            method,
            body,
            headers,
            timeout,
            contentType,
            responseType,
        } = args;
        try {
            const { auth } = Store.store.getState();
            const { isAuthenticated } = auth;

            if (
                url.indexOf('/uaa/oauth/token') < 0 &&
                url.indexOf('/organization/app-logo-key') < 0 &&
                !isAuthenticated
            ) {
                console.warn(
                    `not making request to ${url} because user is not authenicated`,
                );
                //build dummy response to return

                return new Response();
            }

            await this.isUserActive();

            let _headers;

            if (!headers) {
                _headers = {
                    Authorization: await this.getAuthHeader(),
                    'Content-Type': contentType
                        ? contentType
                        : 'application/json',
                };
            } else {
                _headers = headers;
            }

            let request = {
                method,
                url,
                data: body,
                headers: _headers,
            };

            if (responseType) {
                request.responseType = responseType;
            }

            if (timeout) {
                request.timeout = timeout;
            }

            return await axios(request);
        } catch (e) {
            if (e.response?.status && e.response?.status === 401) {
                Store.store.dispatch(logout());
            }
            throw e;
        }
    }

    static async downloadImg(args) {
        try {
            return await this.sendReturnWithCompleteResponse({
                ...args,
                responseType: 'arraybuffer',
            }).then((response) =>
                Buffer.from(response.data, 'binary').toString('base64'),
            );
        } catch (e) {
            throw e;
        }
    }

    static async downloadInsecureImg(args) {
        const { url, timeout } = args;
        try {
            let request = {
                method: 'GET',
                url,
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'arraybuffer',
            };

            if (timeout) {
                request.timeout = timeout;
            }

            return await axios(request).then((response) =>
                Buffer.from(response.data, 'binary').toString('base64'),
            );
        } catch (e) {
            throw e;
        }
    }

    static async download(args, fileName, fileType) {
        try {
            const response = await this.sendReturnWithCompleteResponse({
                ...args,
                responseType: 'blob', // important
            });

            /*
             * NOTE: iOs safari is restricted and doesn't download automatically
             * when file opens, it must be in current tab, for iOs also for auth
             *
             * references:
             * http://www.idownloadblog.com/2016/04/14/how-to-download-files-and-documents-to-iphone-or-ipad/
             * https://stackoverflow.com/questions/46690722/download-attribute-not-working-in-safari
             * https://discussions.apple.com/thread/6793241
             * https://github.com/eligrey/FileSaver.js/issues/12
             */
            if (response && response.data) {
                let actualFileName = fileName;
                if (!fileName) {
                    //use the file name from the header if none is passed to the function
                    const disposition = response.headers['content-disposition'];
                    if (
                        disposition &&
                        (disposition.indexOf('inline') !== -1 ||
                            disposition.indexOf('attachment') !== -1)
                    ) {
                        const FILE_NAME_REGEX =
                            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = FILE_NAME_REGEX.exec(disposition);
                        if (matches && matches[1]) {
                            actualFileName = matches[1].replace(/['"]/g, '');
                        }
                    }
                }

                if (actualFileName) {
                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(
                            response.data,
                            actualFileName,
                        );
                    } else {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data], { type: fileType }),
                        );
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute('download', actualFileName || '');
                        document.body.appendChild(link);
                        link.click();
                    }
                }
                return response.data;
            }
            return null;
        } catch (e) {
            throw e;
        }
    }

    static async getDownloadUrl(args, fileName, fileType) {
        let url = '';

        try {
            const response = await this.sendReturnWithCompleteResponse({
                ...args,
                responseType: 'blob', // important
            });

            /*
             * NOTE: iOs safari is restricted and doesn't download automatically
             * when file opens, it must be in current tab, for iOs also for auth
             *
             * references:
             * http://www.idownloadblog.com/2016/04/14/how-to-download-files-and-documents-to-iphone-or-ipad/
             * https://stackoverflow.com/questions/46690722/download-attribute-not-working-in-safari
             * https://discussions.apple.com/thread/6793241
             * https://github.com/eligrey/FileSaver.js/issues/12
             */

            if (response && response.data) {
                let actualFileName = fileName;
                if (!fileName) {
                    const disposition = response.headers['content-disposition'];
                    if (
                        disposition &&
                        (disposition.indexOf('inline') !== -1 ||
                            disposition.indexOf('attachment') !== -1)
                    ) {
                        const FILE_NAME_REGEX =
                            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = FILE_NAME_REGEX.exec(disposition);
                        if (matches && matches[1]) {
                            actualFileName = matches[1].replace(/['"]/g, '');
                        }
                    }
                }

                if (actualFileName) {
                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(
                            response.data,
                            actualFileName,
                        );
                    } else {
                        url = window.URL.createObjectURL(
                            new Blob([response.data], { type: fileType }),
                        );
                    }
                }
            }

            return url;
        } catch (e) {
            throw e;
        }
    }
}

export { Backend };
