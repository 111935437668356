import { Page } from '../../../../../layout/Page';
import { Planogram } from '../../../../../DTOs/planogram';
import PlanogramService from '../../../../../services/planogram-service';
import config from '../../../../../constants/config';
import {
    getCurrentStatusQuery,
    getUpcomingQuery,
    myPlanogramQuery,
} from '../../../../../utils/planogram/planograme-query-helper';
export const PLANOGRAMS_LOADING = 'PLANOGRAMS_LOADING';
export const PLANOGRAMS = 'PLANOGRAMS';
export const PLANOGRAMS_INIT = 'PLANOGRAMS_INIT';
export const PLANOGRAMS_ERROR = 'PLANOGRAMS_ERROR';

const planogramService = new PlanogramService();
export const initPlanogramsForRetailerReference = () => {
    return async (dispatch) => {
        dispatch({ type: PLANOGRAMS_INIT });
    };
};

export const getPlanogramsForRetailerReference = (retailerReference) => {
    return async (dispatch) => {
        dispatch({ type: PLANOGRAMS_LOADING });
        try {
            const currentQuery = {
                page: 0,
                size: config.PLANOGRAMS_WIDGET_CURRENT_PAGE_SIZE,
                sort: [
                    {
                        direction: 'ASC',
                        property: 'startDate',
                    },
                ],
                query: [
                    myPlanogramQuery(
                        retailerReference.numberOfFacings,
                        retailerReference.jurisdiction
                    ),
                    getCurrentStatusQuery(),
                ],
            };
            const currentPlanogramsForRetailer: Page<Planogram> =
                await planogramService.getBySearchSpecificationModel(
                    currentQuery
                );
            const upcomingQuery = {
                page: 0,
                size: config.PLANOGRAMS_WIDGET_UPCOMING_PAGE_SIZE,
                sort: [
                    {
                        direction: 'ASC',
                        property: 'startDate',
                    },
                ],
                query: [
                    myPlanogramQuery(
                        retailerReference.numberOfFacings,
                        retailerReference.jurisdiction
                    ),
                    getUpcomingQuery(),
                ],
            };

            const upcomingPlanogramsForRetailer: Page<Planogram> =
                await planogramService.getBySearchSpecificationModel(
                    upcomingQuery
                );
            dispatch({
                type: PLANOGRAMS,
                currentPlanogramsForRetailer,
                upcomingPlanogramsForRetailer,
            });
        } catch (e) {
            dispatch({
                type: PLANOGRAMS_ERROR,
                errorResponse: e.response.data,
                loading: false,
            });
        }
    };
};
