import moment from 'moment';

import { error } from '../../notifications';
import { getErrorMessage } from '../../../../constants/utils';

import { SalesRoutesApi } from '../../../../services/sales-routes-service';
import { TaskApi } from '../../../../services/task-service';
import { UserApi } from '../../../../services/user-service';
import _ from 'lodash';

export const SALES_ROUTES_WIDGET_LOADING = 'SALES_ROUTES_WIDGET_LOADING';
export const SALES_ROUTES_WIDGET_DATA = 'SALES_ROUTES_WIDGET_DATA';

const salesRoutesApi = new SalesRoutesApi();
const taskApi = new TaskApi();
const userApi = new UserApi();

const createTaskWidgetError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'Dashboard' }));
    };
};

export const getSalesRoutesTaskData = (userInfo) => {
    return async (dispatch) => {
        let salesRouteTaskCompletion = [];

        dispatch({ type: SALES_ROUTES_WIDGET_LOADING });
        try {
            if (_.includes(userInfo.roles, 'SALES_REP')) {
                const salesRoutes =
                    await salesRoutesApi.getSalesRouteBySalesRep(
                        userInfo.user_name
                    );
                if (salesRoutes !== null && salesRoutes.length > 0) {
                    const today = new Date();
                    const start = moment(today).isoWeek();
                    const firstDayOfWeek = moment(today)
                        .isoWeek(start)
                        .startOf('isoWeek')
                        .clone()
                        .format('YYYY-MM-DD');
                    const allRetailerTasks =
                        await taskApi.getAllMyRetailerTasksForWeek(
                            firstDayOfWeek
                        );

                    salesRoutes.forEach((salesRoute) => {
                        const salesRouteTasks = allRetailerTasks.filter(
                            (task) =>
                                _.includes(
                                    salesRoute.retailerIds,
                                    task.retailerId
                                )
                        );
                        const completedSalesRouteTasks = salesRouteTasks.filter(
                            (task) => task.status === 'COMPLETE'
                        );
                        const taskCompletionPercentage =
                            salesRouteTasks.length > 0
                                ? (100 * completedSalesRouteTasks.length) /
                                  salesRouteTasks.length
                                : 0;

                        salesRouteTaskCompletion.push({
                            name: salesRoute.name,
                            id: salesRoute.id,
                            tasksCompleted: completedSalesRouteTasks.length,
                            totalTasks: salesRouteTasks.length,
                            percentage: taskCompletionPercentage,
                        });
                    });
                }
            } else if (_.includes(userInfo.roles, 'SALES_MANAGER')) {
                const salesRoutes =
                    await salesRoutesApi.getSalesRouteBySalesManager(
                        userInfo.user_name
                    );
                if (salesRoutes !== null && salesRoutes.length > 0) {
                    const today = new Date();
                    const start = moment(today).isoWeek();
                    const firstDayOfWeek = moment(today)
                        .isoWeek(start)
                        .startOf('isoWeek')
                        .clone()
                        .format('YYYY-MM-DD');
                    let salesRepTasks = [];
                    const salesRepsForSalesroutes = _.uniq(
                        salesRoutes.map((salesRoute) => salesRoute.salesRep)
                    );
                    for (let i = 0; i < salesRepsForSalesroutes.length; i++) {
                        let salesRep = salesRepsForSalesroutes[i];
                        let userInfo = salesRep
                            ? await userApi.getUserByUsername(salesRep)
                            : null;
                        if (userInfo) {
                            const salesRepAllRetailerTasksForWeek =
                                await taskApi.getAllRetailerTasksForWeek(
                                    firstDayOfWeek,
                                    userInfo.id
                                );
                            salesRepTasks.push({
                                user: userInfo.username,
                                tasks: salesRepAllRetailerTasksForWeek,
                            });
                        }
                    }

                    salesRoutes.forEach((salesRoute) => {
                        const allRetailerTasks = _.find(
                            salesRepTasks,
                            (salesRepTasks) =>
                                salesRepTasks.user === salesRoute.salesRep
                        );
                        const salesRouteTasks = allRetailerTasks
                            ? allRetailerTasks.tasks.filter((task) =>
                                  _.includes(
                                      salesRoute.retailerIds,
                                      task.retailerId
                                  )
                              )
                            : [];
                        const completedSalesRouteTasks = salesRouteTasks.filter(
                            (task) => task.status === 'COMPLETE'
                        );
                        const taskCompletionPercentage =
                            salesRouteTasks.length > 0
                                ? (100 * completedSalesRouteTasks.length) /
                                  salesRouteTasks.length
                                : 0;

                        salesRouteTaskCompletion.push({
                            name: salesRoute.name,
                            id: salesRoute.id,
                            tasksCompleted: completedSalesRouteTasks.length,
                            totalTasks: salesRouteTasks.length,
                            percentage: taskCompletionPercentage,
                        });
                    });
                }
            }
            salesRouteTaskCompletion.forEach((task) => {});

            dispatch({
                type: SALES_ROUTES_WIDGET_DATA,
                data: salesRouteTaskCompletion,
            });
        } catch (e) {
            console.error(e);
            dispatch(createTaskWidgetError(e));
        }
    };
};
