import {
    INIT_UPDATE_USER,
    GET_USER_TO_UPDATE_SUCCESS,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_LOADING,
    UPDATE_USER_LOADING_DONE,
} from '../../actions';

const INIT_UPDATE_USER_FIELDS = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    officePhone: '',
    department: '',
    roles: [],
    enabled: '',
};

const INITIAL_STATE = {
    fields: { ...INIT_UPDATE_USER_FIELDS },
    isUserVerified: false,
    isUsernameChanged: false,
    errors: [],
    userSuccessMessage: null,
    loading: false,
};

const updateUser = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_LOADING:
            return { ...state, loading: true };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                fields: { ...INIT_UPDATE_USER_FIELDS },
                userSuccessMessage: `${action.username} was updated successfully`,
            };
        case INIT_UPDATE_USER:
            return { ...INITIAL_STATE, fields: { ...INIT_UPDATE_USER_FIELDS } };
        case GET_USER_TO_UPDATE_SUCCESS:
            return { ...INITIAL_STATE, fields: { ...action.userData } };
        case UPDATE_USER_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default updateUser;
