import { Planogram } from '../../../DTOs/planogram';

export const GET_PLANOGRAM = 'EditPlanogram/Get';
export const UPDATE_LOADING_STATE = 'EditPlanogram/Loading';

export interface EditPlanogramStateType {
    isLoading: boolean;
    planogram: Planogram;
}

interface GetPlanogramType {
    type: typeof GET_PLANOGRAM;
    payload: Planogram;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type EditPlanogramActionTypes = GetPlanogramType | UpdateLoadingState;
