import {
    SALES_TARGETS_LIST,
    SALES_TARGETS_LOADING,
    SALES_TARGETS_FISCAL_YEARS_LIST,
    SALES_TARGETS_FISCAL_YEARS_LOADING,
} from '../../actions/salesTargets/salesTargets';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
    fiscalYears: {
        loading: false,
        list: [],
    },
    ytdSalesTarget: {
        loading: false,
        percentage: 0,
        salesTarget: 0,
    },
};

const salesTargets = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_TARGETS_LOADING:
            return { ...state, loading: true };

        case SALES_TARGETS_LIST:
            return {
                ...state,
                ...action.list,
                loading: false,
            };

        case SALES_TARGETS_FISCAL_YEARS_LOADING:
            return {
                ...state,
                fiscalYears: {
                    ...state.fiscalYears,
                    loading: true,
                },
            };

        case SALES_TARGETS_FISCAL_YEARS_LIST:
            return {
                ...state,
                fiscalYears: {
                    list: action.list,
                    loading: false,
                },
            };

        default:
            return state;
    }
};
export default salesTargets;
