import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    STORE_NEW_CHECKED_TIME,
} from '../actions';

const INITIAL_STATE = {
    isAuthenticated: false,
    token: null,
    loading: false,
    errors: {
        message: null,
    },
    info: {
        authorities: [],
    },
    timeUserCheckedActive: null,
};

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, loading: true };
        case LOGIN_SUCCESS:
            return {
                ...INITIAL_STATE,
                token: action.token,
                info: action.info,
                isAuthenticated: true,
                loading: false,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                errors: { message: action.errors },
                loading: false,
            };
        case STORE_NEW_CHECKED_TIME:
            return { ...state, timeUserCheckedActive: new Date() };
        case LOGOUT:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default auth;
