import type { UserPreference } from '../../../DTOs/user-preference';

export const UPDATE_USER_PREFERENCE = 'UserPreference/update';
export const UPDATE_LOADING_STATE = 'UserPreference/loading';

export interface UserPreferenceStateType {
    isLoading: boolean;
    userPreferenceMap: Map<string, UserPreference>;
}

interface UpdateUserPreferenceType {
    type: typeof UPDATE_USER_PREFERENCE;
    payload: UserPreference;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type UserPreferenceActionTypes =
    | UpdateUserPreferenceType
    | UpdateLoadingState;
