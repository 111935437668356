import moment from 'moment';

import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { GamesApi } from '../../../services/games-service';
import { errorHandler } from '../errorHandler/errorHandler';

export const RETAILER_INSTANT_INVENTORY_INIT =
    'RETAILER_INSTANT_INVENTORY_INIT';
export const RETAILER_INSTANT_INVENTORY = 'RETAILER_INSTANT_INVENTORY';
export const RETAILER_INSTANT_INVENTORY_LOADING =
    'RETAILER_INSTANT_INVENTORY_LOADING';
export const RETAILER_INSTANT_INVENTORY_LOADING_DONE =
    'RETAILER_INSTANT_INVENTORY_LOADING_DONE';

const gamesApi = new GamesApi();

const retailerInstantInventoryError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'RetailerInstantInventory',
            })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getInstantInventory = (retailerReference) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: RETAILER_INSTANT_INVENTORY_INIT });
            dispatch({ type: RETAILER_INSTANT_INVENTORY_LOADING });
            const list = await gamesApi.getInstantInventoryByRetailerReference(
                retailerReference
            );
            const nonZeroList = list.filter((inv) => inv.quantity > 0);
            const latestImport = await gamesApi.getLatestImportByImportName(
                'inventory'
            );
            const {
                organization: { startDate },
            } = getState();
            let lastUpdateDate = moment(startDate);
            if (latestImport && latestImport.createdDate) {
                lastUpdateDate = moment(latestImport.createdDate);
            }
            dispatch({
                type: RETAILER_INSTANT_INVENTORY,
                lastUpdateDate: lastUpdateDate,
                list: nonZeroList,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerInstantInventoryError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: RETAILER_INSTANT_INVENTORY_LOADING_DONE });
        }
    };
};
