import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, map, sortBy } from 'lodash';
import {
    getRetailerSelectData,
    initRetailerSelect,
    clearRetailerQueryData,
    updateSelectedRetailersValues,
} from '../../redux/actions';
import { PSelect } from '../form';
import { Field } from 'redux-form';

class RetailerSelect extends Component {
    constructor(props) {
        super();
        this.state = {
            searchModel: cloneDeep(this.initSearchModel),
        };
    }

    initSearchModel = {
        page: 0,
        size: 100,
        sortOrder: 'ASC',
        sortColumn: 'reference',
        sortColumns: [],
        filters: { status: ['ACTIV'] },
        query: '',
    };
    async componentDidMount() {
        this.initData();
    }

    componentWillUnmount() {
        this.props.init();
    }
    initData = async () => {
        const { selectedRetailers, init, updateSelectedRetailersValues } =
            this.props;
        await init();
        if (selectedRetailers) {
            updateSelectedRetailersValues(
                selectedRetailers.map((retailer) => ({
                    reference: retailer.userRetailerId.retailerReference,
                    name: retailer.retailerName,
                }))
            );
        }
    };
    clearData = async () => {
        const { getRetailers, clearRetailerQueryData } = this.props;
        const searchModel = cloneDeep(this.initSearchModel);
        await clearRetailerQueryData();
        await this.setState(searchModel);
        await getRetailers(searchModel);
    };

    onScrollToLast = async () => {
        const { page, getRetailers } = this.props;
        const { searchModel } = this.state;
        if (typeof page.last !== 'undefined' && page.last === false) {
            searchModel.page = page.number + 1;
            getRetailers(searchModel);
        }
    };
    onSearch = async (options, query) => {
        const { getRetailers, clearRetailerQueryData } = this.props;
        await clearRetailerQueryData();
        const searchModel = cloneDeep(this.initSearchModel);
        searchModel.query = query.searchQuery;
        await this.setState({ searchModel });
        await getRetailers(searchModel);
    };

    onChange = async (event, data) => {
        const { selectedValues, updateSelectedRetailersValues } = this.props;
        const { content } = this.props;
        data.forEach((data, index) => {
            if (
                !selectedValues.find(
                    (retailer) => String(retailer.reference) === String(data)
                )
            ) {
                selectedValues.push(
                    content.find(
                        (value) => String(value.reference) === String(data)
                    )
                );
            }
        });
        updateSelectedRetailersValues(selectedValues);
    };
    render() {
        const { loading, content } = this.props;
        const { selectedValues } = this.props;
        const options = cloneDeep(content);
        selectedValues.forEach((retailer, index) => {
            if (
                !options.find(
                    (option) =>
                        String(retailer.reference) === String(option.reference)
                )
            ) {
                options.push(retailer);
            }
        });
        return (
            <Field
                component={PSelect}
                label="Retailer"
                placeholder="Select Retailer"
                name="retailer"
                options={map(
                    sortBy(options, 'reference'),
                    ({ reference, name }) => ({
                        text: `${reference} - ${name}`,
                        value: reference,
                    })
                )}
                clearable={true}
                loading={loading}
                multiple
                onScrollToLast={this.onScrollToLast}
                onSearchChange={this.onSearch}
                onOpen={this.clearData}
                onChange={this.onChange}
            />
        );
    }
}

const mapStateToProps = ({
    retailerSelect: { content, page, selectedValues },
}) => ({
    content,
    page,
    selectedValues,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getRetailers: (searchModel) =>
            dispatch(getRetailerSelectData(searchModel)),
        init: () => dispatch(initRetailerSelect()),
        updateSelectedRetailersValues: (selectedValues) =>
            dispatch(updateSelectedRetailersValues(selectedValues)),
        clearRetailerQueryData: () => dispatch(clearRetailerQueryData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailerSelect);
