import { Dispatch } from 'redux';
import {
    GET_COURIER as LIST_GET_COURIER,
    GET_COURIER_BY_SEARCH,
    CourierActionTypes,
    UPDATE_LOADING_STATE as LIST_UPDATE_LOADING_STATE,
} from '../../type/courier/courier-type';
import { Courier } from '../../../DTOs/courier';
import {
    GET_COURIER,
    EditCourierStateType,
    UPDATE_LOADING_STATE,
} from '../../type/courier/edit-courier-type';
import { error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';
import type { SearchSpecificationModel } from '../../../interface/search-specification-model';
import { Page } from '../../../layout/Page';
import CourierService from '../../../services/courier-service';

const courierService = new CourierService();

export const getCourierList = () => {
    return async (dispatch: Dispatch<CourierActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const couriers: Courier[] = await courierService.get();
            dispatch({ type: LIST_GET_COURIER, payload: couriers });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};

export const getCourierPage = (
    searchSpecificationModel: SearchSpecificationModel
) => {
    return async (dispatch: Dispatch<CourierActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const courierPage: Page<Courier> =
                await courierService.getBySearchSpecificationModel(
                    searchSpecificationModel
                );
            dispatch({
                type: GET_COURIER_BY_SEARCH,
                payload: courierPage,
            });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const getCourier = (id: number) => {
    return async (dispatch: Dispatch<EditCourierStateType>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        try {
            const courier: Courier = await courierService.getById(id);
            dispatch({ type: GET_COURIER, payload: courier });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const createEmptyCourier = () => {
    return async (dispatch: Dispatch<EditCourierStateType>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        dispatch({ type: GET_COURIER, payload: { isActive: true } });
    };
};
export const saveCourier = (id: number, courier: Courier) => {
    return async (dispatch: Dispatch<EditCourierStateType>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });

        try {
            if (id) {
                courier = await courierService.update(id, courier);
            } else {
                courier = await courierService.create(courier);
            }
            dispatch({ type: GET_COURIER, payload: courier });
        } catch (e) {
            if (e.response.data) {
                dispatch(updateMessageError(e.response.data));
            } else {
                dispatch(updateError(e));
            }
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
            throw e;
        }
        return courier;
    };
};

const updateMessageError = (msg) => {
    return (dispatch) => {
        dispatch(error({ msg: msg, target: 'Retailer Group' }));
    };
};

const updateError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'Retailer Group' })
        );
    };
};

export const clearCourier = () => {
    return async (dispatch: Dispatch<EditCourierStateType>) => {
        dispatch({ type: GET_COURIER, payload: undefined });
    };
};
export const clearCouriers = () => {
    return async (dispatch: Dispatch<CourierActionTypes>) => {
        dispatch({ type: GET_COURIER_BY_SEARCH, payload: undefined });
        dispatch({ type: LIST_GET_COURIER, payload: undefined });
    };
};
