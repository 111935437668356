import { RetailerPosApi } from '../../../services/retailer-pos-service';
import { RetailerPosItemApi } from '../../../services/retailer-posItem-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';

export const RETAILER_POS_ITEMS = 'RETAILER_POS_ITEMS';
export const RETAILER_POS_ITEMS_LOADING = 'RETAILER_POS_ITEMS_LOADING';
export const RETAILER_POS_ITEMS_LOADING_DONE =
    'RETAILER_POS_ITEMS_LOADING_DONE';
export const POS_ITEMS = 'POS_ITEMS';
export const POS_ITEMS_LOADING = 'POS_ITEMS_LOADING';
export const POS_ITEMS_LOADING_DONE = 'POS_ITEMS_LOADING_DONE';

const retailerPosApi = new RetailerPosApi();
const retailerPosItemApi = new RetailerPosItemApi();

const retailerPosListError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'RetailerPosList' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const listRetailerPosItems = (args, retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RETAILER_POS_ITEMS_LOADING });
            const list = await retailerPosApi.listRetailerPosItems(
                args,
                retailerReference
            );
            dispatch({
                type: RETAILER_POS_ITEMS,
                retailerPosItemList: list,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerPosListError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: RETAILER_POS_ITEMS_LOADING_DONE });
        }
    };
};

export const getPosItems = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: POS_ITEMS_LOADING });
            const list = await retailerPosItemApi.getPosItems();
            dispatch({
                type: POS_ITEMS,
                list,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerPosListError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: POS_ITEMS_LOADING_DONE });
        }
    };
};
