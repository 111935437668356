import { RetailerGroup } from '../../../DTOs/retailer-group';

export const GET_RETAILER_GROUP = 'EditRetailerGroup/Get';
export const UPDATE_LOADING_STATE = 'EditRetailerGroup/Loading';

export interface EditRetailerGroupStateType {
    isLoading: boolean;
    retailerGroup: RetailerGroup;
}

interface GetRetailerGroupType {
    type: typeof GET_RETAILER_GROUP;
    payload: RetailerGroup;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type EditRetailerGroupActionTypes =
    | GetRetailerGroupType
    | UpdateLoadingState;
