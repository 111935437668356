import { SET_ACTIVE_USER, INIT_ACTIVE_USER } from '../actions';

const INITIAL_STATE = {
    isOtherUser: false,
    userInfo: null,
};

const activeUser = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_USER:
            return {
                ...state,
                isOtherUser: action.isOtherUser,
                userInfo: action.userInfo,
            };
        case INIT_ACTIVE_USER:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default activeUser;
