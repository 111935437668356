import { Backend } from './backend';
import _ from 'lodash';

class TextNotesApi {
    constructor() {
        this.path = '/notes/';
    }

    async getAllTextNotes() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTextNoteById(id) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async createTextNote(args) {
        try {
            return await Backend.send({
                method: 'post',
                url: this.path,
                body: { ...args },
            });
        } catch (e) {
            throw e;
        }
    }

    async updateTextNote(id, text) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}${id}`,
                body: text,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteTextNote(textNoteId) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}${textNoteId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async searchTextNotes({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            //need unique url to have text notes separate for each retailer
            const referenceId = _.find(filters, { property: 'reference' });
            let idQueryStr = '';
            if (referenceId) {
                idQueryStr = `?id=${referenceId.data[0]}`;
            }

            return await Backend.send({
                method: 'post',
                url: `${this.path}search${idQueryStr}`,
                body: {
                    page: page || '0',
                    size: size || '20',
                    sortColumn: sortColumn || 'createdDate',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: filters,
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async searchRetailerTextNotesForAccounts({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            //need unique url to have text notes separate for each retailer
            const referenceId = _.find(filters, { property: 'reference' });
            let idQueryStr = '';
            if (referenceId) {
                idQueryStr = `?id=${referenceId.data}`;
            }

            return await Backend.send({
                method: 'post',
                url: `${this.path}search${idQueryStr}`,
                body: {
                    page: page || '0',
                    size: size || '20',
                    sortColumn: sortColumn || 'createdDate',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: filters,
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }
}

export { TextNotesApi };
