import {
    REPORTS_LIST,
    REPORTS_LIST_LOADING,
    REPORTS_LIST_ERROR,
} from '../../actions/reports/listReports';

const INITIAL_STATE = {
    content: [],
    loading: false,
};

const listReports = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REPORTS_LIST_LOADING:
            return { ...state, loading: true };
        case REPORTS_LIST:
            return {
                ...state,
                content: action.content,
                loading: false,
            };
        case REPORTS_LIST_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default listReports;
