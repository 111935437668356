import {
    SALES_YTD_LOADING,
    SALES_YTD,
    SALE_SUMMARY_BY_GAME_LOADING,
    SALE_SUMMARY_BY_GAME_SUCCESS,
    SALE_SUMMARY_BY_GAME_FAIL,
    SALES_YTD_FOR_SALES_MANAGERS_LOADING,
    SALES_YTD_FOR_SALES_MANAGERS_SUCCESS,
    SALES_YTD_FOR_SALES_MANAGERS_FAIL,
    SALES_YTD_FOR_ROUTES_LOADING,
    SALES_YTD_FOR_ROUTES_SUCCESS,
    SALES_YTD_FOR_ROUTES_FAIL,
} from '../../actions/sales/sales';

const INITIAL_STATE = {
    ytdSalesByCategory: {
        draw: 0,
        instant: 0,
        passive: 0,
        vlt: 0,
        pulltab: 0,
    },
    loading: false,
    salesSummary: {
        error: '',
        loading: false,
        data: [],
    },
    ytdSalesManagersSalesData: {
        error: '',
        loading: false,
        salesData: [],
    },
    ytdSalesManagersSalesByRouteData: {
        error: '',
        loading: false,
        salesByRouteData: [],
    },
};

const sales = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_YTD_LOADING:
            return { ...state, loading: true };
        case SALES_YTD:
            return {
                ...state,
                ytdSalesByCategory: { ...action.ytdSalesData },
                loading: false,
            };
        case SALE_SUMMARY_BY_GAME_LOADING:
            return {
                ...state,
                salesSummary: {
                    ...INITIAL_STATE.salesSummary,
                    loading: true,
                },
            };
        case SALE_SUMMARY_BY_GAME_SUCCESS:
            return {
                ...state,
                salesSummary: {
                    ...state.salesSummary,
                    data: [...action.data],
                    loading: false,
                    error: '',
                },
            };
        case SALE_SUMMARY_BY_GAME_FAIL:
            return {
                ...state,
                salesSummary: {
                    ...state.salesSummary,
                    error: action.error,
                    loading: false,
                },
            };
        case SALES_YTD_FOR_SALES_MANAGERS_LOADING:
            return {
                ...state,
                ytdSalesManagersSalesData: {
                    ...INITIAL_STATE.ytdSalesManagersSalesData,
                    loading: true,
                },
            };
        case SALES_YTD_FOR_SALES_MANAGERS_SUCCESS:
            return {
                ...state,
                ytdSalesManagersSalesData: {
                    ...state.ytdSalesManagersSalesData,
                    salesData: [...action.salesData],
                    loading: false,
                    error: '',
                },
            };
        case SALES_YTD_FOR_SALES_MANAGERS_FAIL:
            return {
                ...state,
                ytdSalesManagersSalesData: {
                    ...state.ytdSalesManagersSalesData,
                    error: action.error,
                    loading: false,
                },
            };
        case SALES_YTD_FOR_ROUTES_LOADING:
            return {
                ...state,
                ytdSalesManagersSalesByRouteData: {
                    ...INITIAL_STATE.ytdSalesManagersSalesByRouteData,
                    loading: true,
                },
            };
        case SALES_YTD_FOR_ROUTES_SUCCESS:
            return {
                ...state,
                ytdSalesManagersSalesByRouteData: {
                    ...state.ytdSalesManagersSalesByRouteData,
                    salesByRouteData: [...action.salesByRouteData],
                    loading: false,
                    error: '',
                },
            };
        case SALES_YTD_FOR_ROUTES_FAIL:
            return {
                ...state,
                ytdSalesManagersSalesByRouteData: {
                    ...state.ytdSalesManagersSalesByRouteData,
                    error: action.error,
                    loading: false,
                },
            };
        default:
            return state;
    }
};
export default sales;
