import React from 'react';
import Page from '../../layout/Page';
import { Modal } from 'semantic-ui-react';
import PlaceOrderVideo from '../../assets/videos/place_order.mp4';
import ReviewInvoiceVideo from '../../assets/videos/review_invoices.mp4';
import ReviewOrdersAndShipmentsVideo from '../../assets/videos/review_orders_shipments.mp4';
import _ from 'lodash';
import './style.css';
const GettingStartedPage = () => {
    const videoEnum = Object.freeze({
        placeOrder: 0,
        reviewOrdersAndShipments: 1,
        reviewInvoices: 2,
    });

    const videos = [
        {
            file: PlaceOrderVideo,
            headerText: 'Placing Orders',
        },
        {
            file: ReviewOrdersAndShipmentsVideo,
            headerText: 'Reviewing Orders and Shipments',
        },
        {
            file: ReviewInvoiceVideo,
            headerText: 'Reviewing Invoices',
        },
    ];

    const videoJSXs = _.map(videos, ({ file, headerText }) => {
        const videoHtml = (
            <video
                style={{ width: '100%', margin: 'auto' }}
                width="950"
                height="600"
                autoPlay
                controls
            >
                <source src={file} type="video/mp4" />
            </video>
        );

        return (
            <span>
                {headerText}&nbsp;
                <Modal
                    closeIcon
                    content={videoHtml}
                    trigger={
                        <div
                            role="button"
                            className={'anchor-div'}
                            tabIndex="0"
                        >
                            [View Video]
                        </div>
                    }
                    size="fullscreen"
                />
            </span>
        );
    });

    return (
        <Page title="Take A Tour Videos">
            <div
                style={{ background: 'white', padding: 20, textAlign: 'left' }}
            >
                <h4>{videoJSXs[videoEnum.placeOrder]}</h4>
                <p>
                    Learn how to place an order on the new website. This video
                    includes:
                    <ul>
                        <li>viewing game details</li>
                        <li>adding deals to your shopping cart</li>
                        <li>editing your order</li>
                        <li>adding samples to your order</li>
                        <li>submitting your order</li>
                    </ul>
                </p>
                <h4>{videoJSXs[videoEnum.reviewOrdersAndShipments]}</h4>
                <p>
                    Learn how to review your order history and your shipments.
                    This video includes:
                    <ul>
                        <li>viewing your pending orders and shipped orders</li>
                        <li>downloading order and shipment records</li>
                        <li>tracking your shipped orders</li>
                    </ul>
                </p>
                <h4>{videoJSXs[videoEnum.reviewInvoices]}</h4>
                <p>
                    Learn how to review your invoices. This video includes:
                    <ul>
                        <li>viewing your invoices</li>
                        <li>downloading invoice records</li>
                        <li>
                            understanding how your invoice is calculated by
                            deducting your commission and redemption payments to
                            players
                        </li>
                    </ul>
                </p>
            </div>
        </Page>
    );
};

export default GettingStartedPage;
