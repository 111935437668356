import {
    RETAILER_ORDER_DETAILS,
    RETAILER_ORDER_DETAILS_LOADING,
    RETAILER_ORDER_DETAILS_LOADING_DONE,
    RETAILER_ORDER_DETAILS_SHOW_MODAL,
    RETAILER_ORDER_DETAILS_CLOSE_MODAL,
} from '../../actions';

const INITIAL_STATE = {
    orderDetails: {},
    loading: false,
    showModal: false,
};

const retailerOrderDetails = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_ORDER_DETAILS_LOADING:
            return { ...state, loading: true };
        case RETAILER_ORDER_DETAILS_LOADING_DONE:
            return { ...state, loading: false };
        case RETAILER_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: { ...action.data },
                loading: false,
            };
        case RETAILER_ORDER_DETAILS_SHOW_MODAL:
            return { ...state, showModal: true };
        case RETAILER_ORDER_DETAILS_CLOSE_MODAL:
            return { ...state, showModal: false };
        default:
            return state;
    }
};
export default retailerOrderDetails;
