import React from 'react';
import classNames from 'classnames';

export const PLabel = ({ text, className, style, ...props }) => (
    <label
        className={classNames('body-text', 'secondary-color', className)}
        style={{
            fontWeight: 'normal',
            fontSize: '12px',
            marginBottom: '5px',
            display: 'block',
            ...style,
        }}
        {...props}
    >
        {text}
    </label>
);
