import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Icon } from 'semantic-ui-react';
import './styles.css';
import { sumBy } from 'lodash';

class CartIcon extends Component {
    render() {
        const { orderedItems } = this.props;

        return (
            <div className="cart-icon-wrapper">
                <Icon className="icon-Cart" />
                {orderedItems.length > 0 && (
                    <Label circular floating color="yellow">
                        {sumBy(orderedItems, 'qty')}
                    </Label>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ cart: { orderedItems } }) => ({ orderedItems });

export default connect(mapStateToProps, null)(CartIcon);
