import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import OkCancelModal from './OkCancelModal';
import * as H from 'history';
import { useSelector } from 'react-redux';
interface Props {
    when?: boolean | undefined;
    history: History;
    shouldBlockNavigation: (location: any) => boolean;
}

const UnsavedFromDataAlertModel = ({
    when,
    history,
    shouldBlockNavigation,
}: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [lastAction, setLastAction] = useState(null);

    const isFormDirty: boolean = useSelector(
        (state) => state.formsMetadata.isFormDirty
    );

    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (
        location: H.Location,
        action: H.Action
    ): boolean => {
        if (
            isFormDirty &&
            !confirmedNavigation &&
            (!shouldBlockNavigation || shouldBlockNavigation(location, action))
        ) {
            setModalVisible(true);
            setLastLocation(location);
            setLastAction(action);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };
    useEffect(() => {
        if (confirmedNavigation && lastLocation && isFormDirty) {
            // Navigate to the previous blocked location with your navigate function
            switch (lastAction) {
                case 'REPLACE':
                    history.replace(lastLocation.pathname);
                    break;
                case 'POP':
                    history.goBack();
                    break;
                case 'PUSH':
                default:
                    history.push(lastLocation.pathname);
            }
        }
    }, [confirmedNavigation, lastLocation, lastAction, history, isFormDirty]);
    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <OkCancelModal
                isOpen={modalVisible}
                header="Warning"
                body="You have unsaved data. Do you want to exit without saving?"
                okText="Yes"
                cancelText="No"
                okFunction={handleConfirmNavigationClick}
                cancelFunction={closeModal}
            />
        </>
    );
};

export default UnsavedFromDataAlertModel;
