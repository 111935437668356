import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';

class MoreDetail extends Component {
    render() {
        const { onClick, className, moreDetailLabel } = this.props;
        return (
            <Button
                className={classnames(
                    'link-button more-detail-link widget-clickable-item',
                    className
                )}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            >
                {`${moreDetailLabel ? moreDetailLabel.label : ''} >`}
            </Button>
        );
    }
}

const mapStateToProps = ({ dashboardSettings: { moreDetailLabel } }) => ({
    moreDetailLabel,
});

export default withRouter(connect(mapStateToProps, null)(MoreDetail));
