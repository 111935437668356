import { TOGGLE_OPTIMIZER } from '../actions/optimizer';

const INITIAL_STATE = {
    toggleOptimizer: true,
};

const optimizer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_OPTIMIZER:
            return {
                ...state,
                toggleOptimizer: action.val,
            };
        default:
            return state;
    }
};
export default optimizer;
