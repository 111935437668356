import React, { Component } from 'react';
import { Form, Input, Button, Message, Transition } from 'semantic-ui-react';
import { map, uniq } from 'lodash';

import Localize from '../../constants/i18n-utils';
import config from '../../constants/config';
import Copyright from '../copyright/Copyright';
import { isTokenExpired } from '../../constants/utils';
import './LoginForm.css';

const animationLen = config.ANIMATION_LENGTH;

class LoginForm extends Component {
    showMessages = (show, errors, isWarning) => {
        if (!this.props.showMessage || !show) {
            return;
        }

        return (
            <Transition
                visible={show}
                transitionOnMount
                animation="fade"
                duration={animationLen}
            >
                <div className="login-notification">
                    {isWarning && isTokenExpired ? (
                        <Message
                            warning
                            onDismiss={this.props.onDismiss}
                            style={{ display: 'block' }}
                        >
                            {map(uniq(errors), (error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </Message>
                    ) : (
                        <Message negative onDismiss={this.props.onDismiss}>
                            {map(uniq(errors), (error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </Message>
                    )}
                </div>
            </Transition>
        );
    };

    render() {
        const {
            onSubmit,
            onChange,
            errors,
            warnings,
            fields,
            loading,
            logoImage,
            forgotPasswordLink,
        } = this.props;

        const hasError = errors && errors.length > 0;
        const hasWarnings = warnings && warnings.length > 0;

        return (
            <div>
                <Form
                    action="/"
                    onSubmit={onSubmit}
                    className="vertical-form sign-in"
                >
                    <div className="login-form-controls bg-white">
                        <div className="login-header">
                            <span className="subheader bold">
                                {Localize.text('Login.header', 'SIGN IN')}
                            </span>
                        </div>
                        <div className="login-logo-wrapper">
                            <img src={logoImage.src} alt={logoImage.alt} />
                        </div>

                        {this.showMessages(hasError, errors)}
                        {this.showMessages(hasWarnings, warnings, true)}

                        <Input
                            autoFocus
                            placeholder={Localize.text(
                                'Login.username.placeholder',
                                'Username'
                            )}
                            name="username"
                            onChange={onChange}
                            value={fields.username}
                        />
                        <Input
                            placeholder={Localize.text(
                                'Login.password.placeholder',
                                'Password'
                            )}
                            type="password"
                            name="password"
                            onChange={onChange}
                            value={fields.password}
                        />
                        {forgotPasswordLink && (
                            <div className="forgot-password-wrapper">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={forgotPasswordLink.value}
                                >
                                    {Localize.text(
                                        'Login.password.forgotPassword',
                                        'Forgot Password'
                                    )}
                                    ?
                                </a>
                            </div>
                        )}
                        <Button type="submit" primary loading={loading}>
                            {Localize.text('Login.button', 'SIGN IN')}
                        </Button>
                    </div>
                </Form>
                <Copyright />
            </div>
        );
    }
}

export default LoginForm;
