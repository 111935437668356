export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP';
export const SET_PATH = 'SET_PATH';
export const SET_ACTIVE_NAME = 'SET_ACTIVE_NAME';
export const CLEAR_ACTIVE_MENU = 'CLEAR_ACTIVE_MENU';

export const toggleDrawer = (toggle) => ({
    type: TOGGLE_DRAWER,
    payload: toggle,
});

export const closeDrawer = () => ({
    type: CLOSE_DRAWER,
});

export const setActiveMenu = (name) => ({
    type: SET_ACTIVE_MENU,
    payload: name,
});

export const setPath = (path) => ({
    type: SET_PATH,
    payload: path,
});

export const setactiveName = (name) => ({
    type: SET_ACTIVE_NAME,
    payload: name,
});

export const showConfirmationPopup = (value) => ({
    type: SHOW_CONFIRMATION_POPUP,
    payload: value,
});

export const clearActiveMenu = (name) => ({
    type: CLEAR_ACTIVE_MENU,
});
