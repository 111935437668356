import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Icon, Button, Grid } from 'semantic-ui-react';
import { map, sumBy, sortBy } from 'lodash';
import CartIcon from '../cartIcon/CartIcon';
import {
    incrementCartQuantityForGame,
    decrementCartQuantityForGame,
    removeFromCart,
    removeAllFromCart,
    showCart,
} from '../../redux/actions';

import OkCancelModal from '../../components/OkCancelModal';
import config from '../../constants/config';
import './styles.css';
import NoItems from '../NoItems';
import { formatMoney, calculateTotalCostForOrder } from '../../constants/utils';
import { CustomPopUp } from '../custom-pop-up/custom-pop-up';

const { CHECKOUT } = config.ROUTE_URLS;

class CartDropdown extends Component {
    state = {
        isOpen: false,
        showCancelModal: false,
    };

    handleItemClick = (e) => e.stopPropagation();

    handleClose = () => {
        const { showCart, toggleCart } = this.props;
        if (toggleCart) {
            showCart(false);
        }
        this.setState({ showCancelModal: false });
    };

    handleCancel = () => {
        this.props.removeAllFromCart();
        this.setState({ showCancelModal: false });
    };

    handleCheckoutCancel = () => {
        this.setState({ showCancelModal: true });
    };
    handleCheckoutClose = () => {
        this.setState({ showCancelModal: false });
    };

    toggleOpen = () => {
        const { toggleCart } = this.props;
        this.props.showCart(!toggleCart);
        // this.setState({ isOpen: !this.state.isOpen });
    };

    handleProcessToCheckout = () => {
        if (this.props.location.pathname === CHECKOUT) {
            return;
        }

        this.props.history.push(CHECKOUT);
        this.handleClose();
    };

    handleLastItemRemoved = (game, type) => {
        type && this.props.removeFromCart(game);
        !type && this.props.decrementCartQuantityForGame(game);
        this.handleClose();
    };

    render() {
        const {
            orderedItems,
            incrementCartQuantityForGame,
            decrementCartQuantityForGame,
            removeFromCart,
            cartLocalized,
            toggleCart,
        } = this.props;

        const isOrderValid = orderedItems.find(
            (orderedItem) =>
                orderedItem.game.minimumOrderQuantity &&
                orderedItem.qty < orderedItem.game.minimumOrderQuantity
        );
        const { showCancelModal } = this.state;
        const orderItemCount = sumBy(orderedItems, 'qty');
        const totalCost = formatMoney(calculateTotalCostForOrder(orderedItems));

        if (showCancelModal) {
            return (
                <OkCancelModal
                    header="Remove all items"
                    body="Are you sure you want to remove all items from cart?"
                    okText={'Ok'}
                    okFunction={this.handleCancel}
                    cancelFunction={this.handleCheckoutClose}
                    cssHeader={'center-header'}
                />
            );
        }
        const menuJsxData = (
            <div className={'p-4'}>
                {' '}
                <Grid>
                    <Grid.Row columns={2} className={'cart-menu cart-header'}>
                        <Grid.Column className={'text-align-s'}>
                            <span className="subheader-text">
                                {cartLocalized && cartLocalized.cart}
                            </span>
                        </Grid.Column>
                        <Grid.Column className={'text-align-e'}>
                            <span className="caption-text">{`${
                                orderedItems.length
                            } ${cartLocalized && cartLocalized.items}`}</span>
                        </Grid.Column>
                    </Grid.Row>
                    {orderedItems.length > 0 &&
                        map(
                            sortBy(orderedItems, 'game.gameRefAndName'),
                            ({ game, qty, suggestedQuantity }, i) => (
                                <Grid.Row className={'pt-2 p-4 pb-0 pr-0'}>
                                    <Grid.Column
                                        className={
                                            'text-align-s game-info-wrapper p-3 pl-2 pr-1'
                                        }
                                        width={10}
                                    >
                                        <div className="d-flex white-space-normal">
                                            <span>{`${i + 1}. `}</span>
                                            <span>{`${game.gameRefAndName}`}</span>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column
                                        className={
                                            'text-align-c game-info-wrapper p-3 pr-0 pl-1 '
                                        }
                                        width={4}
                                    >
                                        <Icon
                                            className="icon-Minus"
                                            color={'black'}
                                            onClick={() => {
                                                orderItemCount === 1
                                                    ? this.handleLastItemRemoved(
                                                          game,
                                                          null
                                                      )
                                                    : decrementCartQuantityForGame(
                                                          game
                                                      );
                                            }}
                                        />
                                        <span className="quantity pr-3">
                                            {qty}
                                        </span>
                                        <Icon
                                            className="icon-Plus"
                                            color={'black'}
                                            onClick={() =>
                                                incrementCartQuantityForGame(
                                                    game
                                                )
                                            }
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        className={'text-align-s  p-1 pt-3 '}
                                        width={1}
                                    >
                                        <div className="d-flex white-space-normal">
                                            <Icon
                                                className="icon-Close"
                                                color={'black'}
                                                onClick={() => {
                                                    orderedItems.length === 1
                                                        ? this.handleLastItemRemoved(
                                                              game,
                                                              'close'
                                                          )
                                                        : removeFromCart(game);
                                                }}
                                            />
                                            {game.minimumOrderQuantity &&
                                            game.minimumOrderQuantity > qty ? (
                                                <CustomPopUp
                                                    content={`Minimum order limit is ${game.minimumOrderQuantity}`}
                                                >
                                                    <Icon
                                                        className="exclamation "
                                                        color="red"
                                                    />
                                                </CustomPopUp>
                                            ) : (
                                                <div></div>
                                            )}
                                            {suggestedQuantity &&
                                                qty < suggestedQuantity && (
                                                    <CustomPopUp
                                                        content={
                                                            'Ordered quantity is less than the suggested quantity'
                                                        }
                                                    >
                                                        <Icon
                                                            className="exclamation "
                                                            color="yellow"
                                                        />
                                                    </CustomPopUp>
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        )}
                    {orderedItems.length > 0 && (
                        <Grid.Row columns={2} className={'pt-2 p-4 pb-4 pr-0'}>
                            <Grid.Column
                                className={'bold game-info-wrapper pt-2'}
                                width={7}
                            >
                                {`${
                                    cartLocalized && cartLocalized.totalQuantity
                                }:`}
                            </Grid.Column>
                            <Grid.Column
                                className={
                                    'bold game-info-wrapper pt-2 text-align-e'
                                }
                                width={7}
                            >
                                {orderItemCount}
                            </Grid.Column>
                            <Grid.Column
                                className={'bold game-info-wrapper pt-4  pb-2'}
                                width={7}
                            >
                                {`${cartLocalized && cartLocalized.totalCost}:`}
                            </Grid.Column>
                            <Grid.Column
                                className={
                                    'bold game-info-wrapper pt-4 pb-24 text-align-e'
                                }
                                width={7}
                            >
                                {totalCost}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {orderedItems.length > 0 && (
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Button
                                    primary
                                    content={
                                        cartLocalized &&
                                        cartLocalized.proceedToCart
                                    }
                                    disabled={isOrderValid}
                                    onClick={this.handleProcessToCheckout}
                                />
                            </Grid.Column>
                            <Grid.Column className={'text-align-e'}>
                                <Button
                                    secondary
                                    content={
                                        cartLocalized && cartLocalized.close
                                    }
                                    onClick={this.handleCheckoutCancel}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {orderedItems.length === 0 && (
                        <Grid.Row>
                            <Grid.Column>
                                <NoItems
                                    message={
                                        cartLocalized && cartLocalized.noItems
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </div>
        );
        return (
            <Dropdown
                onClick={this.toggleOpen}
                open={this.state.isOpen || toggleCart}
                onBlur={this.handleClose}
                item
                trigger={<CartIcon />}
                className={'cart-drop-down-menu'}
            >
                <Dropdown.Menu className="cart-menu">
                    <Dropdown.Item
                        content={menuJsxData}
                        className="cart-menu-item-wrapper"
                        onClick={this.handleItemClick}
                    />
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ cart: { orderedItems, toggleCart } }) => ({
    orderedItems,
    toggleCart,
});

const mapDispatchToProps = (dispatch) => ({
    removeFromCart: (game) => dispatch(removeFromCart(game)),
    incrementCartQuantityForGame: (game) =>
        dispatch(incrementCartQuantityForGame(game)),
    decrementCartQuantityForGame: (game) =>
        dispatch(decrementCartQuantityForGame(game)),
    removeAllFromCart: () => dispatch(removeAllFromCart()),
    showCart: (isShow) => dispatch(showCart(isShow)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CartDropdown)
);
