import { error, success } from '../notifications';
import { ReturnsApi } from '../../../services/returns-service';

export const CANCEL_RETURN__LOADING = 'CANCEL_RETURN__LOADING';
export const CANCEL_RETURN__INIT = 'CANCEL_RETURN__INIT';
export const CANCEL_RETURN__LOADING_DONE = 'CANCEL_RETURN__LOADING_DONE';

const returnsAPI = new ReturnsApi();

export const cancelReturn = (args) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CANCEL_RETURN__LOADING,
            });

            await returnsAPI.cancelReturn(args);

            dispatch({
                type: CANCEL_RETURN__INIT,
            });

            dispatch(
                success({
                    msg: `Return No. ${args.externalReturnNumber} canceled Successfully`,
                })
            );
        } catch (err) {
            const errorMessage = err?.response?.data;
            if (errorMessage) {
                dispatch(error({ msg: errorMessage }));
            } else {
                dispatch(
                    error({
                        msg: `Error while canceling Return No. ${args.externalReturnNumber}`,
                    })
                );
            }
        } finally {
            dispatch({
                type: CANCEL_RETURN__LOADING_DONE,
            });
        }
    };
};
