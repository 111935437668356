import {
    surveyStatuses,
    questionTypes,
} from '../../../constants/surveyOptions';
import {
    COMPLIANCE_CREATE_SUCCESS,
    COMPLIANCE_CREATE_INIT,
    COMPLIANCE_CREATE_PAGE_LOADING,
    COMPLIANCE_CREATE_PAGE_DONE,
} from '../../actions';

const INIT_COMPLIANCE_CREATE = {
    id: '',
    name: '',
    description: '',
    question: '',
    questionType: null,
    status: 'DRAFT',
};

const INITIAL_STATE = {
    loading: false,
    complianceTemplate: { ...INIT_COMPLIANCE_CREATE },
    createComplianceOptions: {
        questionTypes: [...questionTypes],
        statuses: [...surveyStatuses],
    },
};

const createCompliance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPLIANCE_CREATE_PAGE_LOADING:
            return { ...state, loading: true };
        case COMPLIANCE_CREATE_PAGE_DONE:
            return { ...state, loading: false };
        case COMPLIANCE_CREATE_SUCCESS:
            return {
                ...state,
                complianceTemplate: { ...INIT_COMPLIANCE_CREATE },
            };
        case COMPLIANCE_CREATE_INIT:
            return {
                ...INITIAL_STATE,
                complianceTemplate: { ...INIT_COMPLIANCE_CREATE },
            };
        default:
            return state;
    }
};
export default createCompliance;
