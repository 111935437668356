import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, find } from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';
import Localize from '../../constants/i18n-utils';
import {
    searchInProgressOrders,
    initInProgressOrders,
    generateOrderByExternalOrderNumber,
    cancelOrder,
    openEditView,
} from '../../redux/actions';
import './styles.css';
import NoItems from '../NoItems';
import { InProgressOrderItem } from './InProgressOrderItem';

const IN_PROGRESS_ORDER_DEFAULTS = {
    filters: [{ property: 'isComplete', data: ['false'] }],
    size: 9999,
    sortColumns: [
        { column: 'orderDate', order: 'DESC' },
        { column: 'externalOrderNumber', order: 'DESC' },
    ],
};

export class InProgressOrders extends Component {
    componentDidMount() {
        this.init();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            this.init();
        }
    }

    init() {
        this.search();
    }
    componentWillUnmount() {
        this.props.init();
    }

    search = () => {
        const { activeRetailer } = this.props;
        this.props.search({
            page: 0,
            ...IN_PROGRESS_ORDER_DEFAULTS,
            filters: [
                ...IN_PROGRESS_ORDER_DEFAULTS.filters,
                {
                    property: 'retailer',
                    data: [activeRetailer?.userRetailerId?.retailerReference],
                },
            ],
        });
    };

    render() {
        const { content, loading, cancelRequestLoading } = this.props;

        return (
            <div data-test="inProgressOrders">
                <Dimmer page inverted active={loading || cancelRequestLoading}>
                    <Loader />
                </Dimmer>
                {content.length > 0 ? (
                    map(content, (order) => (
                        <InProgressOrderItem order={order} />
                    ))
                ) : (
                    <NoItems
                        message={Localize.text(
                            'orderHistory.noOrdersPendingInProgress',
                            'No Pending or In Progress Orders'
                        )}
                        data-test="inProgressOrdersWithoutData"
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    inProgressOrders,
    organization: { configs },
    userRetailer: { activeRetailer },
    cancelOrder: { loading: cancelRequestLoading },
}) => {
    const upsTrackingUrlConfig = find(configs, ['name', 'ups-tracking-url']);
    const upsTrackingUrl = upsTrackingUrlConfig && upsTrackingUrlConfig.value;
    return {
        ...inProgressOrders,
        upsTrackingUrl,
        activeRetailer,
        enableCancelOrder:
            find(configs, { name: 'enable-cancel-order' })?.value === 'true',
        cancelRequestLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    openEditView: (edit) => dispatch(openEditView(edit)),
    search: (args) => dispatch(searchInProgressOrders(args)),
    init: () => dispatch(initInProgressOrders()),
    generateOrderByExternalOrderNumber: (sfaOrderNumber, externalOrderNumber) =>
        dispatch(
            generateOrderByExternalOrderNumber(
                sfaOrderNumber,
                externalOrderNumber
            )
        ),
    cancelOrder: (id, externalOrderNumber, version, args) =>
        dispatch(cancelOrder(id, externalOrderNumber, version, args)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InProgressOrders)
);
