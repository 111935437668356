import _ from 'lodash';

//fix for ie https://github.com/lodash/lodash/issues/3323
const isEmpty = (value) => !_.size(value);

export const required = (value) => {
    if (value) {
        if (_.isArray(value) || _.isObject(value)) {
            return !isEmpty(value) ? undefined : 'Required';
        }

        return undefined;
    }

    return 'Required';
};
export const complianceSurveyCompleted = (value) =>
    value === 'COMPLETE'
        ? 'Compliance survey must be completed before changing status to Complete'
        : undefined;
export const requiredObj = (value) =>
    value && !isObjEmpty(value) ? undefined : 'Required';
export const number = (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const maxLength = (value) =>
    value && value.length > 255
        ? 'Must be shorter than 256 characters'
        : undefined;
export const maxLength30 = (value) =>
    value && value.length > 30 ? 'Maximum 30 characters' : undefined;
export const maxIntVal = (value) =>
    value && value > 2147483647
        ? 'Value must be smaller than 2147483647'
        : undefined;
export const intRequired = (value) =>
    value && value !== `${parseInt(value, 10)}`
        ? 'Must be a whole number'
        : undefined;
export const postiveInt = (value) =>
    value && value < 0 ? 'Number cannot be negative' : undefined;
export const nonZeroPositive = (value) =>
    value && value > 0 ? undefined : 'Number must be greater than 0';
export const validFileExtension = (value) =>
    isFileExtensionValid(value) ? undefined : 'File type not allowed';
export const validFileExtensionForMessage = (value) =>
    isFileExtensionValidForMessage(value) ? undefined : 'File type not allowed';

export const validLogoFileExtension = (value) =>
    isLogoFileExtensionValid(value)
        ? undefined
        : 'File type not allowed for logo';

export const digitsOnly = (value) => {
    const reg = /^\d+$/;
    if (value && reg.test(value)) {
        return;
    } else {
        return 'Must be 0-9';
    }
};

export const maxFileSize = (value) => {
    const MAX_FILE_SIZE = 100 * 1024 * 1024;
    if (value && value.length > 0) {
        return value[0].size > MAX_FILE_SIZE
            ? 'Maximum file size exceeded'
            : undefined;
    }
};

export const maxUploadFileSize = (value) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    if (value && value.length > 0) {
        return value[0].size > MAX_FILE_SIZE
            ? 'Maximum file size exceeded'
            : undefined;
    }
};

function isObjEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export const isNumberAndDecimal = (value) => {
    const reg = /^\d+(\.\d{0,2})?$/g;
    if (value && reg.test(value)) {
        return;
    } else {
        return 'Must be a number greater than 0, with or without a decimal point';
    }
};

export const isStringDate = (value) => {
    const reg = /^\d{4}-\d{2}-\d{2}$/g;
    if (!value || value.length !== 10 || !reg.test(value)) {
        return 'Must be a date of format YYYY-MM-DD';
    }
};

export const isFileExtensionValid = (fileList) => {
    if (fileList && fileList.length > 0) {
        let fileName = fileList[0].name;
        if (fileName && fileName.length > 0) {
            const validExtensions = [
                'pdf',
                'jpeg',
                'jpg',
                'png',
                'mp4',
                '3gp',
                'mov',
                'doc',
                'docx',
            ];
            //sets ext to empty string or the extension if present
            let ext = fileName.slice(
                ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
            );
            return validExtensions.includes(ext.toLowerCase());
        }
        return false;
    } else {
        //must not fail on extension test if fileList is undef.
        return true;
    }
};

export const isFileExtensionValidForMessage = (fileList) => {
    if (fileList && fileList.length > 0) {
        let fileName = fileList[0].name;
        if (fileName && fileName.length > 0) {
            const validExtensions = ['jpeg', 'jpg', 'png'];
            //sets ext to empty string or the extension if present
            let ext = fileName.slice(
                ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
            );
            return validExtensions.includes(ext.toLowerCase());
        }
        return false;
    } else {
        //must not fail on extension test if fileList is undef.
        return true;
    }
};

export const isLogoFileExtensionValid = (fileList) => {
    return isExtensionValid(fileList, ['jpeg', 'jpg', 'png', 'svg']);
};

export const isVideoExtenionValid = (fileList) => {
    return isExtensionValid(fileList, ['.mp4', '.ogg']);
};

export const isExtensionValid = (fileList, validExtensions) => {
    if (fileList && fileList.length > 0) {
        let fileName = fileList[0].name;
        if (fileName && fileName.length > 0) {
            //sets ext to empty string or the extension if present
            let ext = fileName.slice(
                ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
            );
            return validExtensions.includes(ext.toLowerCase());
        }
        return false;
    } else {
        //must not fail on extension test if fileList is undef.
        return true;
    }
};

export const isValidEmail = (email, requireNonNull, errorMessage) => {
    var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((!requireNonNull && !email) || re.test(String(email).toLowerCase())) {
        return;
    }
    return errorMessage ?? 'Enter a valid email';
};

export const isValidPhone = (phone) =>
    phone && !isValidPhoneFax(phone) ? 'Enter a valid phone number' : undefined;

export const isValidFax = (fax) =>
    fax && !isValidPhoneFax(fax) ? 'Enter a valid fax number' : undefined;

const isValidPhoneFax = (number) => {
    var re = /^(\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return !number || re.test(String(number));
};

export const isValidLength = (text, min, max, requireNonNull, errorMsg) => {
    const success = errorMsg ? '' : true;
    return (requireNonNull && !text) ||
        text.length < min ||
        (max && text.length > max)
        ? errorMsg ?? false
        : success;
};
