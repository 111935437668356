import _ from 'lodash';
import moment from 'moment';

import { updateRetailerFromScheduledList } from '../weeklyScheduler';
import { updateRetailerDailyTaskList } from '../tasks/dailyTasks';
import { updateRetailerUTR } from '../tasks/retailerTask';
import { updatePastDueUTR } from '../tasks/pastDueTasks';
import { getErrorMessage, uuidv4 } from '../../../constants/utils';
import { error } from '../notifications';

import { loadLastVisitDateLocally } from '../../actions/retailerDashboard/widgets/lastVisitDateWidget';

export const MARK_TASK_LOADING = 'MARK_TASK_LOADING';
export const MARK_TASK_DONE = 'MARK_TASK_DONE';

const status = {
    complete: 'COMPLETE',
    incomplete: 'INCOMPLETE',
};

const markTaskError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'RetailerTasksForDay',
            })
        );
    };
};

export const markTaskCompletion = (utr, isComplete, notChangeStatus) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (!notChangeStatus) {
            utr.status = isComplete ? status.complete : status.incomplete;
        }

        utr.completedDate = new Date(); //completed or incomplete right now
        utr.completedByUserId = auth.info.user_id;

        return await dispatch(updateUTR(utr));
    };
};

export const updateSurveyAnswers = (survey) => {
    return async (dispatch) => {
        try {
            if (survey.answer) {
                //create answer body

                const answers = _.map(survey.answer, (answer) => ({
                    text: answer.value,
                    question: { id: answer.questionId },
                }));

                dispatch({
                    type: 'UPDATE_SURVEY_ANSWERS',
                    payload: { answers },
                    meta: {
                        offline: {
                            effect: {
                                method: 'PUT',
                                url: `/surveys/responses/${survey.surveyResultUuid}/answers`,
                                body: answers,
                            },
                        },
                    },
                });

                return survey.surveyResultUuid;
            }
        } catch (e) {
            dispatch(markTaskError(e));
        }
        return null;
    };
};

export const saveSurvey = (survey) => {
    return async (dispatch) => {
        //save survey response
        try {
            if (survey.answer) {
                //create an id so we can do offline saves and not depend on the survey response to return an surveyResponseId
                const surveyResponseId = uuidv4();

                const answer = _.map(survey.answer, (answer) => ({
                    text: answer.value,
                    question: { id: answer.questionId },
                }));

                dispatch({
                    type: 'CREATE_SURVEY_ANSWERS',
                    payload: { answer },
                    meta: {
                        offline: {
                            effect: {
                                method: 'POST',
                                url: `/surveys/responses/${survey.id}/answers?surveyResponseId=${surveyResponseId}`,
                                body: answer,
                            },
                        },
                    },
                });
                return surveyResponseId;
            }
        } catch (e) {
            dispatch(markTaskError(e));
        }
        return null;
    };
};

export const updateUTR = (utr) => {
    return async (dispatch, getState) => {
        let success = true;

        const today = new Date();
        // make sure completedByUserId is set when changing task status to COMPLETE or INCOMPLETE
        if (
            utr.status === status.complete ||
            utr.status === status.incomplete
        ) {
            if (!utr.completedByUserId) {
                const { auth } = getState();
                utr.completedDate = today;
                utr.completedByUserId = auth.info.user_id;
            }
        }

        utr.modifiedDate = today.getTime();
        utr.updated = true;

        try {
            dispatch({ type: MARK_TASK_LOADING });

            dispatch({
                type: 'UPDATE_UTR',
                payload: { utr },
                meta: {
                    offline: {
                        effect: {
                            method: 'post',
                            url: `/tasks/utr/`,
                            body: utr,
                        },
                    },
                },
            });

            const updatedTask = { ...utr }; // force change in data so that corresponding components get updated
            dispatch(updateRetailerFromScheduledList(updatedTask));
            dispatch(updateRetailerDailyTaskList(updatedTask));
            dispatch(updateRetailerUTR(updatedTask));
            dispatch(updatePastDueUTR(updatedTask));

            //update this just in case the user tries to view the retailer dashboard immediately after completing task
            dispatch(
                loadLastVisitDateLocally(
                    moment(utr.completedDate).format('YYYY-MM-DD')
                )
            );
        } catch (err) {
            success = false;
            dispatch(markTaskError(err));
        } finally {
            dispatch({ type: MARK_TASK_DONE });
        }

        return success;
    };
};
