import React from 'react';
import './ErrorPage.css';

const ErrorPage = ({ msg }) => {
    let msgHeader, msgTag, msgDescription;

    switch (msg) {
        case 401:
        case 403:
            msgHeader = 'Access Denied!';
            msgTag = '';
            msgDescription =
                'The page you were trying to reach is absolutely forbidden for some reason.';
            break;
        default:
            msgHeader = 'Uh...';
            msgTag = 'Something went wrong!';
            msgDescription = 'Don’t worry, it’s not you, it is us.';
            break;
    }

    return (
        <div className="error-page-layout">
            <span
                className="icon-Error secondary-color"
                style={{ fontSize: 50 }}
            >
                {' '}
            </span>
            <span className="error-page-style1">{msgHeader}</span>
            <span className="error-page-style2">{msgTag}</span>
            <span className="error--page-decription">{msgDescription}</span>
        </div>
    );
};

export default ErrorPage;
