import { UserApi } from '../../services/user-service';
import { RetailersApi } from '../../services/retailers-service';
import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

const userApi = new UserApi();
const retailerApi = new RetailersApi();

export const FILTER_LIST_SALES_REP = 'FILTER_LIST_SALES_REP';
export const FILTER_LIST_SALES_REP_LOADING = 'FILTER_LIST_SALES_REP_LOADING';
export const FILTER_LIST_SALES_MANAGER = 'FILTER_LIST_SALES_MANAGER';
export const FILTER_LIST_SALES_MANAGER_LOADING =
    'FILTER_LIST_SALES_MANAGER_LOADING';
export const FILTER_LIST_RETAILER_TYPES = 'FILTER_LIST_RETAILER_TYPES';
export const FILTER_LIST_RETAILER_TYPES_LOADING =
    'FILTER_LIST_RETAILER_TYPES_LOADING';
export const FILTER_LIST_INDUSTRY_TYPES = 'FILTER_LIST_INDUSTRY_TYPES';
export const FILTER_LIST_INDUSTRY_TYPES_LOADING =
    'FILTER_LIST_INDUSTRY_TYPES_LOADING';
export const FILTER_RETAILER_LOADING = 'FILTER_RETAILER_LOADING';
export const FILTER_RETAILER = 'FILTER_RETAILER';
export const FILTER_ASSIGNEE_LOADING = 'FILTER_ASSIGNEE_LOADING';
export const FILTER_ASSIGNEE = 'FILTER_ASSIGNEE';
export const FILTER_ROLES_LOADING = 'FILTER_ROLES_LOADING';
export const FILTER_ROLES = 'FILTER_ROLES';
export const FILTER_TERRITORIES_LOADING = 'FILTER_TERRITORIES_LOADING';
export const FILTER_TERRITORIES = 'FILTER_TERRITORIES';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const loadAssigneeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FILTER_ASSIGNEE_LOADING });
            const data = await userApi.getActiveAssignees();
            dispatch({
                type: FILTER_ASSIGNEE,
                payload: data,
            });
        } catch (e) {
            dispatch(
                {
                    type: FILTER_ASSIGNEE,
                    payload: [],
                },
                createErrorHandlerError(e)
            );
        }
    };
};

export const loadRetailerTypesList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FILTER_LIST_RETAILER_TYPES_LOADING });
            const data = await retailerApi.getRetailerTypes();
            dispatch({
                type: FILTER_LIST_RETAILER_TYPES,
                payload: data,
            });
        } catch (e) {
            dispatch({
                type: FILTER_LIST_RETAILER_TYPES,
                payload: [],
            });
        }
    };
};

export const loadRolesFilter = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FILTER_ROLES_LOADING });
            const data = await userApi.getUserRoles();
            dispatch({
                type: FILTER_ROLES,
                payload: data,
            });
        } catch (e) {
            dispatch({
                type: FILTER_ROLES,
                payload: [],
            });
        }
    };
};

export const loadIndustryCodeFilter = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FILTER_LIST_INDUSTRY_TYPES_LOADING });
            const data = await retailerApi.getIndustryCodes();
            dispatch({
                type: FILTER_LIST_INDUSTRY_TYPES,
                payload: data,
            });
        } catch (e) {
            dispatch({
                type: FILTER_LIST_INDUSTRY_TYPES,
                payload: [],
            });
        }
    };
};

export const loadTerritoriesFilter = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: FILTER_TERRITORIES_LOADING });
            const data = await retailerApi.getTerritories();
            dispatch({
                type: FILTER_TERRITORIES,
                payload: data,
            });
        } catch (e) {
            dispatch({
                type: FILTER_TERRITORIES,
                payload: [],
            });
        }
    };
};
