import {
    PRIVATE_FAQ_LOADING,
    PRIVATE_FAQ_LOADING_DONE,
    PRIVATE_FAQ_INIT,
    PRIVATE_FAQ_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    faqHtml: null,
};

const privateFaq = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PRIVATE_FAQ_GET:
            return {
                ...state,
                faqHtml: payload,
            };
        case PRIVATE_FAQ_INIT:
            return { ...INITIAL_STATE };

        case PRIVATE_FAQ_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PRIVATE_FAQ_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default privateFaq;
