import { Dispatch } from 'redux';
import JurisdictionService from '../../../services/jurisdiction-service';
import {
    JurisdictionActionTypes,
    GET_JURISDICTION_LIST,
    UPDATE_LOADING_STATE,
} from '../../type/Jurisdiction/Jurisdiction-type';
import type { Jurisdiction } from '../../../DTOs/jurisdiction';

const jurisdictionService: JurisdictionService = new JurisdictionService();
export const getJurisdictions = () => {
    return async (dispatch: Dispatch<JurisdictionActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        const jurisdictions: Jurisdiction[] = await jurisdictionService.get();
        dispatch({
            type: GET_JURISDICTION_LIST,
            payload: jurisdictions.filter(
                (jurisdiction) => jurisdiction.isActive === true
            ),
        });
    };
};
