import {
    DASHBOARD_LOADING,
    DASHBOARD_SETTINGS_PAGE_LOAD,
    DASHBOARD_SETTINGS_LOAD,
    DASHBOARD_SETTINGS_SUCCESS,
    DASHBOARD_SETTINGS_CLEAR,
    DASHBOARD_SETTINGS_LABEL,
} from '../../actions/dashboard/settings';

const INITIAL_STATE = {
    dashboardLoading: false,
    dashboardSettingsPageLoading: false,
    loading: false,
    userData: {},
    dashboardData: [],
    phoneFaxMask: {},
    moreDetailLabel: {},
};

const settings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_LOADING:
            return { ...state, dashboardLoading: action.loading };
        case DASHBOARD_SETTINGS_PAGE_LOAD:
            return { ...state, dashboardSettingsPageLoading: action.loading };
        case DASHBOARD_SETTINGS_LOAD:
            return { ...state, loading: action.loading };
        case DASHBOARD_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.data,
                phoneFaxMask: action.phoneFaxMask,
                loading: false,
                dashboardLoading: false,
                dashboardSettingsPageLoading: false,
            };
        case DASHBOARD_SETTINGS_CLEAR:
            return { ...INITIAL_STATE };
        case DASHBOARD_SETTINGS_LABEL:
            return {
                ...state,
                moreDetailLabel: action.label,
            };
        default:
            return state;
    }
};
export default settings;
