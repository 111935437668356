import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';

import Table from '../../components/datatable/Table';
import { setupOrganizationInfo } from '../../redux/actions';
import Page from '../../layout/Page';
import { clearNotification } from '../../redux/actions/index';
import config from '../../constants/config';
const { UPDATE_CONFIGURATION_MANAGEMENT } = config.ROUTE_URLS;

export class ConfigurationManagementPage extends Component {
    async componentDidMount() {
        const { setupOrganizationInfo } = this.props;
        await setupOrganizationInfo();
    }

    componentWillUnmount() {
        this.props.clearNotifications();
    }

    render() {
        const {
            organization: { configs },
            loading,
        } = this.props;

        return (
            <Page
                name="ConfigurationManagementPage"
                title="Configuration Management"
            >
                <div
                    className="data-section"
                    data-test="configurationManagementPage"
                >
                    <Table
                        loading={loading}
                        content={sortBy(configs, 'name')}
                        contentKey="id"
                        totalElements={configs.length}
                        supressSearch={true}
                        selector={{
                            variables: ['name'],
                            path: UPDATE_CONFIGURATION_MANAGEMENT,
                            callback: (p) => this.props.history.push(p),
                        }}
                        columns={[
                            {
                                name: 'description',
                                title: 'Configuration',
                            },
                            {
                                name: 'value',
                                title: 'Value',
                                formatter: (value, item) => {
                                    switch (item.name) {
                                        default:
                                            return value;
                                    }
                                },
                            },
                        ]}
                        noDataMessage="There are no configuration settings "
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = (setupOrganizationInfo) => {
    const { organization, loading } = setupOrganizationInfo;
    return { organization, loading };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setupOrganizationInfo: () => dispatch(setupOrganizationInfo()),
        clearNotifications: () => dispatch(clearNotification()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ConfigurationManagementPage)
);
