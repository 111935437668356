import { CorpAccountsApi } from '../../services/corp-accounts-service';

export const CORP_ACCOUNTS_LIST = 'CORP_ACCOUNTS_LIST';
export const CORP_ACCOUNTS_LOADING = 'CORP_ACCOUNTS_LOADING';

const corpAccountsApi = new CorpAccountsApi();

export const searchCorpAccounts = (args) => {
    return async (dispatch) => {
        dispatch({ type: CORP_ACCOUNTS_LOADING });

        let list = await corpAccountsApi.searchCorpAccounts(args);

        dispatch({
            type: CORP_ACCOUNTS_LIST,
            list,
        });
    };
};

export const fetchCorpAccountsAsync = (args) => {
    return async (dispatch) => {
        return await corpAccountsApi.searchCorpAccounts(args);
    };
};
