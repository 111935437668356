import {
    PLANOGRAMS_LOADING,
    PLANOGRAMS,
    PLANOGRAMS_INIT,
    PLANOGRAMS_ERROR,
} from '../../../../actions/retailerDashboard/widgets/planograms/planograms';

const INITIAL_STATE = {
    upcomingPlanogramsForRetailer: undefined,
    currentPlanogramsForRetailer: undefined,
    loading: false,
    errorResponse: null,
};

const planogramsWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLANOGRAMS_INIT:
            return { ...INITIAL_STATE };
        case PLANOGRAMS_LOADING:
            return { ...state, loading: true };
        case PLANOGRAMS:
            return {
                ...state,
                upcomingPlanogramsForRetailer:
                    action.upcomingPlanogramsForRetailer,
                currentPlanogramsForRetailer:
                    action.currentPlanogramsForRetailer,
                loading: false,
                errorResponse: null,
            };
        case PLANOGRAMS_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default planogramsWidget;
