import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SfaTransition from './SfaTransition';
import { popReferrer } from '../redux/actions';

class SfaBreadCrumb extends Component {
    render() {
        const {
            animation,
            duration,
            onBreadCrumb,
            breadCrumbText,
            referringPath,
            location,
            popReferrer,
            cartIcon,
        } = this.props;
        const isBreadcrumbPathIsSameAsCurrentLocation =
            referringPath === location.pathname;

        if (isBreadcrumbPathIsSameAsCurrentLocation) {
            popReferrer(); //remove the referrer
            return null;
        }

        return (
            <SfaTransition
                animation={animation ? animation : 'fade right'}
                duration={duration}
            >
                {onBreadCrumb && (
                    <div className="breadcrumb">
                        <span
                            onClick={onBreadCrumb}
                            className="caption-text clickable"
                        >
                            <span className="icon-Arrow-Left" /> back to{' '}
                            <b>
                                {breadCrumbText
                                    ? breadCrumbText
                                    : 'previous page'}
                            </b>
                        </span>
                        {cartIcon}
                    </div>
                )}
            </SfaTransition>
        );
    }
}

const mapStateToProps = ({ referrer: { referrer } }) => {
    const referringPath =
        referrer && referrer.length > 0
            ? referrer[referrer.length - 1].referringPath
            : null;
    return { referringPath };
};

const mapDispatchToProps = (dispatch) => {
    return {
        popReferrer: () => dispatch(popReferrer()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SfaBreadCrumb)
);
