import React, { Component } from 'react';
import '../index.css';
import { Form } from 'semantic-ui-react';
import SfaReadView from './SfaReadView';
import config from '../constants/config';
import SfaInput from './SfaInput';

class UserInfo extends Component {
    render() {
        const { fields, onChange } = this.props;

        let readViewElements = [
            {
                label: 'First Name',
                value: fields.firstName,
                field: 'firstName',
            },
            {
                label: 'Last Name',
                value: fields.lastName,
                field: 'lastName',
            },
            { label: 'Email', value: fields.email, field: 'email' },
        ];

        return (
            <Form.Field>
                {config.COGNITO_ENABLED ? (
                    <>
                        {readViewElements.map(({ label, value, field }) => (
                            <SfaInput
                                onChange={onChange}
                                label={label}
                                name={field}
                                value={value}
                            />
                        ))}
                    </>
                ) : (
                    <SfaReadView elements={readViewElements} />
                )}
            </Form.Field>
        );
    }
}

export default UserInfo;
