import {
    PAST_VISITS_LIST,
    PAST_VISITS_LIST_LOADING,
    PAST_VISITS_LIST_INIT,
} from '../../actions/tasks/retailerPastVisitTasks';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 0,
    number: 0,
    numberOfElements: 0,
    first: false,
    last: false,
    loading: false,
};

const retailerPastVisitTasks = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAST_VISITS_LIST_LOADING:
            return { ...state, loading: true };
        case PAST_VISITS_LIST:
            return {
                ...state,
                ...action.list,
                content: [...state.content, ...action.list.content],
                loading: false,
            };
        case PAST_VISITS_LIST_INIT:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default retailerPastVisitTasks;
