import { UserApi } from '../../services/user-service';
import _ from 'lodash';

import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

export const USERS_LIST = 'USERS_LIST';
export const USERS_LOADING = 'USERS_LOADING';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const userApi = new UserApi();

export const fetchUsers = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: USERS_LOADING });
            const list = await userApi.getAllUsers(args);
            _.each(list.content, function (user) {
                user.roles = _.sortBy(user.roles, ['displayName']);
            });
            dispatch({
                type: USERS_LIST,
                list,
            });
        } catch (err) {
            console.error(err);
            dispatch(createErrorHandlerError(err));
        }
    };
};

export const fetchUsersAsync = (args) => {
    return async (dispatch) => {
        return await userApi.getAllUsersFilter(args);
    };
};

export const getUsersAsync = async (args) => {
    return await userApi.getAllUsersFilter(args);
};
