import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import Page from '../../layout/Page';
import SfaBreadCrumb from '../SfaBreadCrumb';
import {
    getPublicNewRetailerHtml,
    initPublicNewRetailer,
} from '../../redux/actions';

class PublicNewRetailer extends Component {
    componentDidMount() {
        this.props.getNewRetailer();
    }

    componentWillUnmount() {
        this.props.init();
    }

    render() {
        const { logoImage, hideNewRetailer, loading, newRetailerHtml } =
            this.props;
        return (
            <Page>
                <Dimmer page inverted active={loading}>
                    <Loader />
                </Dimmer>
                <div className="faq-wrapper">
                    <div className="login-logo-wrapper">
                        <img src={logoImage.src} alt={logoImage.alt} />
                    </div>
                    <SfaBreadCrumb
                        onBreadCrumb={hideNewRetailer}
                        breadCrumbText="sign-in"
                        animation="fade right"
                    />
                    <Header className="faq-title">New Retailer</Header>
                    <div
                        className="faq-content"
                        dangerouslySetInnerHTML={{
                            __html: newRetailerHtml,
                        }}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ publicNewRetailer }) => ({ ...publicNewRetailer });

const mapDispatchToProps = (dispatch) => {
    return {
        getNewRetailer: () => dispatch(getPublicNewRetailerHtml()),
        init: () => dispatch(initPublicNewRetailer()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicNewRetailer);
