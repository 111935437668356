import React from 'react';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';

import UneditableInput from './UneditableInput';

const SfaReadView = ({ elements }) => {
    return (
        <Form.Field>
            {_.map(_.compact(elements), (el, index) => {
                return (
                    el.value && (
                        <UneditableInput
                            key={index}
                            label={el.label}
                            data={el.value}
                            dataClassName={el.className}
                            callback={el.callback}
                        />
                    )
                );
            })}
        </Form.Field>
    );
};

export default SfaReadView;
