import {
    SERVICE_VERSIONS_INIT,
    SERVICE_VERSIONS_LOADING,
    SERVICE_VERSIONS_DATA,
} from '../../actions';

const NOT_AVAILABLE = 'NOT AVAILABLE';

const INIT_VERSION_DETAIL = {
    version: NOT_AVAILABLE,
    time: null,
};

const INITIAL_STATE = {
    serviceVersionsData: {
        Game: INIT_VERSION_DETAIL,
        Media: INIT_VERSION_DETAIL,
        Organization: INIT_VERSION_DETAIL,
        Retailer: INIT_VERSION_DETAIL,
        Report: INIT_VERSION_DETAIL,
        User: INIT_VERSION_DETAIL,
    },
    loading: false,
};

const serviceVersions = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SERVICE_VERSIONS_INIT:
            return { ...INITIAL_STATE };
        case SERVICE_VERSIONS_LOADING:
            return { ...state, loading: payload };
        case SERVICE_VERSIONS_DATA:
            return {
                ...state,
                serviceVersionsData: {
                    ...state.serviceVersionsData,
                    [payload.serviceName]: {
                        version: payload.version,
                        time: payload.time,
                    },
                },
            };
        default:
            return state;
    }
};

export default serviceVersions;
