import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/datatable/Table';
import {
    getAllDocuments,
    downloadDocument,
    deleteDocument,
    success,
} from '../../redux/actions';
import './styles.css';
import OkCancelModal from '../../components/OkCancelModal';
import { find, truncate } from 'lodash';
import SfaForm from '../../components/SfaForm';
import SfaReadView from '../../components/SfaReadView';
import permissions from '../../constants/permissions';
import UploadDocument from '../../components/uploadDocument/UploadDocument';
import Page from '../../layout/Page';
import { updateSortDetails } from '../../components/datatable/actions';
import {
    formatToDisplayDate,
    formatToDisplayDateTime,
} from '../../constants/utils';

const TOTAL_PER_PAGE = 10;
const DOCUMENT_LIST = 'DOCUMENT_LIST';
const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
const READ_DOCUMENT = 'READ_DOCUMENT';
const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

class DocumentListPage extends Component {
    state = {
        action: DOCUMENT_LIST,
        prevAction: null,
        selectedDocument: null,
        page: 0,
    };

    componentDidMount() {
        this.props.updateSortDetails({
            sortColumnName: 'createdDate',
            ascending: false,
        });
        this.initDocumentList();
    }

    initDocumentList = () => {
        this.props.fetchListOfDocuments({
            page: this.state.page,
            size: TOTAL_PER_PAGE,
            sortColumn: 'createdDate',
            sortOrder: 'DESC',
            filters: [],
        });
    };

    getSelectedDocument = (id) => {
        const { content } = this.props;
        const ret = find(content, { id: id });

        if (!ret) {
            console.error(
                'something really wrong happened. should of found document in list'
            );
        }

        return ret;
    };

    showReadForm = (id) => {
        const selected = this.getSelectedDocument(id);

        this.setState({
            action: READ_DOCUMENT,
            selectedDocument: selected,
        });
    };

    showCreateForm = () => {
        this.setState({ action: CREATE_DOCUMENT });
    };

    showList = () => {
        this.setState({ action: DOCUMENT_LIST });
        this.initDocumentList();
    };

    downloadDocument = (urlPath) => {
        console.debug(`received urlPath ${urlPath}`);

        //need to strip out the beginning '/' if exists
        const formattedUrlPath =
            urlPath[0] === '/' ? urlPath.substr(1) : urlPath;

        console.debug(`downloading file for path ${formattedUrlPath}`);
        this.props.downloadDocument(formattedUrlPath);
    };

    editDocument = (id) => {
        const selected = this.getSelectedDocument(id);
        this.setState({
            action: EDIT_DOCUMENT,
            selectedDocument: selected,
        });
    };

    deleteDocument = async (documentId) => {
        console.debug(`deleting documentId ${documentId}`);
        const success = await this.props.deleteDocument(documentId);
        this.showList();
        if (success) {
            this.props.updateSuccess({
                msg: `The record was successfully deleted.`,
                target: 'DocumentListPage',
            });
        }
    };

    showDeleteDocumentModal = (id) => {
        const selected = this.getSelectedDocument(id);
        const prevAction = this.state.action;
        this.setState({
            action: DELETE_DOCUMENT,
            prevAction,
            selectedDocument: selected,
        });
    };

    cancelDelete = () => {
        this.setState({ action: this.state.prevAction });
    };

    handleSearch = (query) => {
        this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters: [],
            query,
        });
    };

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        page = page <= 0 ? 0 : page - 1;
        this.setState({ page: page });

        this.props.fetchListOfDocuments({
            page: page,
            size,
            sortOrder,
            sortColumn,
            filters: [],
            query,
        });
    };

    render() {
        const { action, selectedDocument } = this.state;
        const {
            content,
            totalElements,
            totalPages,
            size,
            number,
            loading,
            categoryOptions,
        } = this.props;

        const headerText =
            selectedDocument && selectedDocument.type === 'FAQ'
                ? 'FAQ Document'
                : selectedDocument && selectedDocument.type === 'Policy'
                ? 'Policy Document'
                : 'Retailer Document';

        let actionsMenu = [
            {
                iconClass: 'icon-Open-in-new-tab',
                text: 'Download File',
                handleClick: this.downloadDocument,
                handleClickParams: 'mediaKey',
            },
        ];

        actionsMenu.push({
            iconClass: 'icon-Edit',
            text: 'Edit',
            handleClick: this.editDocument,
            handleClickParams: 'id',
        });

        actionsMenu.push(
            { linebreak: true },
            {
                iconClass: 'icon-Delete',
                text: 'Delete',
                class: 'error-text',
                handleClick: this.showDeleteDocumentModal,
                handleClickParams: 'id',
            }
        );

        const backToListJSX = (
            <div className="nav-back">
                <span
                    onClick={this.showList}
                    className="caption-text clickable"
                >
                    {'<'} back to <b>File List</b>
                </span>
            </div>
        );

        if (action === DELETE_DOCUMENT) {
            return (
                <OkCancelModal
                    header={`Delete ${selectedDocument.fileName} Document?`}
                    body={
                        <div className="doc-del-container">
                            Do you want to delete this {selectedDocument.type}{' '}
                            document? Deleting will permanently remove it, and
                            this action cannot be undone.
                            <br />
                            <br />
                            <br />
                            Description
                            <n />
                            <h4>
                                <bold>
                                    {truncate(selectedDocument.description, {
                                        length: 90,
                                    })}
                                </bold>
                            </h4>
                        </div>
                    }
                    okText={'Delete'}
                    data-test="okcancelmodal"
                    okFunction={() => this.deleteDocument(selectedDocument.id)}
                    cancelFunction={this.cancelDelete}
                    cssHeader={'center-header'}
                />
            );
        }

        if (action === CREATE_DOCUMENT) {
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <UploadDocument
                        data-test="uploaddocument"
                        returnToList={this.showList}
                        documentType={this.props.type}
                        categoryOptions={categoryOptions}
                    />
                </div>
            );
        }

        if (action === EDIT_DOCUMENT) {
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <UploadDocument
                        returnToList={this.showList}
                        documentType={this.props.type}
                        documentInfo={selectedDocument}
                        categoryOptions={categoryOptions}
                        headerText={headerText}
                        data-test="uploaddocument"
                        onDelete={() =>
                            this.showDeleteDocumentModal(selectedDocument.id)
                        }
                    />
                </div>
            );
        }

        if (action === READ_DOCUMENT) {
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <SfaForm
                        name="DocumentForm"
                        headerText={selectedDocument.fileName}
                        onCancel={this.showList}
                        showSaveCancel={false}
                        showEdit={true}
                        showDelete={true}
                        editButtonRoles={[permissions.DOCUMENT_UPDATE]}
                        deleteButtonRoles={[permissions.DOCUMENT_DELETE]}
                        onDelete={() =>
                            this.showDeleteDocumentModal(selectedDocument.id)
                        }
                        onEdit={() => this.editDocument(selectedDocument.id)}
                        deleteAction={() =>
                            this.showDeleteDocumentModal(selectedDocument.id)
                        }
                    >
                        <SfaReadView
                            elements={[
                                {
                                    label: 'File',
                                    value: 'Download',
                                    className: 'button',
                                    callback: () =>
                                        this.downloadDocument(
                                            selectedDocument.mediaKey
                                        ),
                                },
                                {
                                    label: 'Description',
                                    value: selectedDocument.description,
                                },
                                {
                                    label: 'Category',
                                    value: selectedDocument.category.name,
                                },
                                {
                                    label: 'Last Modified',
                                    value: formatToDisplayDateTime(
                                        selectedDocument.modifiedDate
                                    ),
                                },
                                {
                                    label: 'Document Type',
                                    value: headerText,
                                },
                            ]}
                        />
                    </SfaForm>
                </div>
            );
        }

        //default show list
        return (
            <Page
                name="DocumentListPage"
                title="Upload Files"
                data-test="documentListPage"
                onAddButtonClick={this.showCreateForm}
                addButtonRoles={[permissions.DOCUMENT_CREATE]}
            >
                <div>
                    <div className="data-display-content">
                        <Table
                            data-test="searchButton"
                            actionsMenu={actionsMenu}
                            pagination
                            loading={loading}
                            content={content}
                            contentKey="id"
                            totalPages={totalPages}
                            totalElements={totalElements}
                            size={size}
                            page={number}
                            selector={{
                                type: 'callbackWithId',
                                callback: (id) => this.showReadForm(id),
                            }}
                            columns={[
                                {
                                    name: 'description',
                                    title: 'Description',
                                    sortable: true,
                                    searchable: true,
                                },
                                {
                                    name: 'fileName',
                                    title: 'File Name',
                                    sortable: true,
                                    searchable: true,
                                },
                                {
                                    name: 'category.name',
                                    title: 'Category',
                                    sortable: true,
                                    searchable: true,
                                    formatter: (val, { category }) => {
                                        if (category) {
                                            return category && category.name;
                                        }
                                    },
                                },
                                {
                                    name: 'modifiedDate',
                                    title: 'Last Modified',
                                    sortable: true,
                                    formatter: (val) =>
                                        val ? formatToDisplayDate(val) : '-',
                                },
                                {
                                    name: 'createdDate',
                                    title: 'Create Date',
                                    sortable: true,
                                    formatter: formatToDisplayDate,
                                },
                            ]}
                            fetchData={this.handleSelectTablePage}
                            onSearch={this.handleSearch}
                            noDataMessage="There are no documents"
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ documentUpload, organization, loading }) => ({
    loading,
    ...documentUpload,
    ...organization,
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListOfDocuments: (args) => dispatch(getAllDocuments(args)),
        updateSortDetails: (args) => dispatch(updateSortDetails(args)),
        downloadDocument: (urlPath) => dispatch(downloadDocument(urlPath)),
        deleteDocument: (documentId) => dispatch(deleteDocument(documentId)),
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListPage);
