import React from 'react';
import { connect } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

import './Unauthorized.css';

export const Unauthorized = ({ location, isAppReady }) => {
    const msgHeader = 'Access Denied!';
    const msgTag = '';
    const msgDescription =
        'The page you were trying to reach is absolutely forbidden for some reason.';

    if (!isAppReady) {
        return (
            <Dimmer>
                <Loader />
            </Dimmer>
        );
    }

    return (
        <div data-test="unauthorized">
            <Segment>
                <div className="error-layout">
                    <span
                        className="icon-Error secondary-color"
                        style={{ fontSize: 90 }}
                    >
                        {' '}
                    </span>
                    <span className="error-style1">{msgHeader}</span>
                    <span className="error-style2">{msgTag}</span>
                    <span className="error-decription">{msgDescription}</span>
                </div>
            </Segment>
        </div>
    );
};

const mapStateToProps = ({ readyApp }) => {
    return {
        isAppReady: readyApp.appReadyAuthenication,
    };
};

export default connect(mapStateToProps)(Unauthorized);
