import {
    GET_PLANOGRAM,
    EditPlanogramActionTypes,
    EditPlanogramStateType,
    UPDATE_LOADING_STATE,
} from '../../type/planogram/edit-planogram-type';

const initialState: EditPlanogramStateType = {
    isLoading: false,
    planogram: undefined,
};
const editPlanogramReducer = (
    state = initialState,
    action: EditPlanogramActionTypes
) => {
    switch (action.type) {
        case GET_PLANOGRAM:
            return {
                ...state,
                isLoading: false,
                planogram: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};
export default editPlanogramReducer;
