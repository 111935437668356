import {
    CREATE_PLANOGRAMGROUP_PROCESSING,
    PLANOGRAMGROUP_CREATE_SUCCESS,
    CREATE_PLANOGRAMGROUP_INIT,
    CREATE_PLANOGRAMGROUP_GET_SUCCESS,
    CREATE_PLANOGRAM_LOADING,
    CREATE_PLANOGRAM_LOADING_DONE,
} from '../../actions';

const INIT_PLANOGRAMGROUP_CREATE = {
    id: '',
    name: '',
    description: '',
    facings: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    planogramToCreate: { ...INIT_PLANOGRAMGROUP_CREATE },
};

const createPlanogramGroup = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_PLANOGRAM_LOADING:
            return { ...state, loading: true };
        case CREATE_PLANOGRAM_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case CREATE_PLANOGRAMGROUP_PROCESSING:
            return { ...state, processing: true };
        case CREATE_PLANOGRAMGROUP_GET_SUCCESS:
            return {
                ...state,
                planogramToCreate: action.planogram,
                processing: false,
                loading: false,
            };
        case PLANOGRAMGROUP_CREATE_SUCCESS:
            return {
                ...state,
                planogramToCreate: { ...INIT_PLANOGRAMGROUP_CREATE },
                processing: false,
            };
        case CREATE_PLANOGRAMGROUP_INIT:
            return {
                ...INITIAL_STATE,
                planogramToCreate: { ...INIT_PLANOGRAMGROUP_CREATE },
            };
        default:
            return state;
    }
};
export default createPlanogramGroup;
