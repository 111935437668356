import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import SfaForm from '../../components/SfaForm';
import Page from '../../layout/Page';
import SfaReadView from '../../components/SfaReadView';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import { hasRole } from '../../constants/utils';
import config from '../../constants/config';
import {
    updateConfigurationByKeyValue,
    onSelectedConfig,
    initConfig,
    logoUpload,
    initLogoUpload,
    success,
    setupOrganizationInfo,
} from '../../redux/actions/index';
import ConfigurationManagementForm from '../../components/configuration-management/ConfigurationManagementForm';
import LogoUploadForm from '../../components/configuration-management/LogoUpLoadForm';
import OkCancelModal from '../../components/OkCancelModal';
import './logoSize.css';
import _ from 'lodash';

const { CONFIGURATION_MANAGEMENT } = config.ROUTE_URLS;
//TODO: refactor configurations
class UpdateConfigurationManagementPage extends Component {
    state = {
        viewReadOnly: true,
        showSave: false,
        showConfirmation: false,
    };

    closeModal = () => {
        this.setState({ showConfirmation: false });
        this.gotoListPage();
    };

    componentDidMount() {
        const name = this.props.match.params.key;
        const config = _.find(this.props.organization.configs, { name });

        if (!config) {
            console.warn(`no config found for ${name}`);
        }

        this.props.onSelectedConfig(config);
    }

    componentWillUnmount() {
        this.props.onInitLogoUpload();
    }

    showConfirmationModal = () => {
        this.setState({ showConfirmation: true });
    };

    closeConfirmationModal = () => {
        this.setState({ showConfirmation: false });
    };

    updateConfig = async (config) => {
        this.closeModal();
        const {
            updateConfigurationByKeyValue,
            updateSuccess,
            setupOrganizationInfo,
        } = this.props;

        const success = await updateConfigurationByKeyValue(config);

        if (success) {
            setupOrganizationInfo();
            this.gotoListPage();
            updateSuccess({
                msg: 'The configuration was successfully updated.',
                target: 'ConfigurationManagementPage',
            });
        } else {
            this.gotoListPage();
            updateSuccess({
                msg: 'The configuration update failed. Please try again',
                target: 'ConfigurationManagementPage',
            });
        }
    };

    logoUpload = async (values) => {
        const { onLogoUpload, updateSuccess, updateConfigurationByKeyValue } =
            this.props;
        const key = this.props.match.params.key;
        const value = values.description;

        //Save the description of the logo file
        await updateConfigurationByKeyValue({ key, value });

        values.name = values.file[0].name;
        values.file = values.file[0];
        values.key = key;
        const success = await onLogoUpload(values);

        this.gotoListPage();
        if (success) {
            updateSuccess({
                msg: 'The configuration was successfully updated.',
                target: 'ConfigurationManagementPage',
            });
        }
    };

    editConfig = () =>
        this.setState({ viewReadOnly: !this.state.viewReadOnly });

    gotoListPage = () => {
        this.props.history.push(CONFIGURATION_MANAGEMENT);
    };

    handleCancel = () => {
        this.gotoListPage();
    };

    isSvgLogo = (file, configToUpdate, appLogoFile, reportLogoFile) => {
        const extAppLogo =
            file && file.retailerPortalAppLogo
                ? file.retailerPortalAppLogo.slice(
                      ((file.retailerPortalAppLogo.lastIndexOf('.') - 1) >>>
                          0) +
                          2
                  )
                : '';
        const extLogo =
            file && file.retailerPortalReportLogo
                ? file.retailerPortalReportLogo.slice(
                      ((file.retailerPortalReportLogo.lastIndexOf('.') - 1) >>>
                          0) +
                          2
                  )
                : '';
        if (
            extAppLogo.toLowerCase() === 'svg' &&
            file &&
            file.appLogo &&
            configToUpdate.name === 'app-logo' &&
            appLogoFile != null
        ) {
            return `data:image/svg+xml;base64,${appLogoFile}`;
        } else if (
            extLogo.toLowerCase() === 'svg' &&
            file &&
            file.logo &&
            configToUpdate.key === 'retailerPortalReportLogo' &&
            reportLogoFile != null
        ) {
            return `data:image/svg+xml;base64,${reportLogoFile}`;
        } else if (configToUpdate.name === 'app-logo') {
            return `data:image/${extLogo};base64,${
                configToUpdate.name === 'app-logo' ? appLogoFile : ''
            }`;
        } else if (configToUpdate.key === 'report-logo') {
            return `data:image/${extLogo};base64,${
                configToUpdate.key === 'report-logo' ? reportLogoFile : ''
            }`;
        }
    };

    render() {
        const {
            handleSubmit,
            userInfo,
            organization,
            appLogoFile,
            reportLogoFile,
            pristine,
        } = this.props;
        const {
            processing,
            loading,
            configToUpdate,
            appLogoDescription,
            logoDescription,
        } = organization;

        const { viewReadOnly, showSave, showConfirmation } = this.state;

        const isLogoKey =
            configToUpdate.key === 'retailerPortalReportLogo' ||
            configToUpdate.key === 'retailerPortalAppLogo';
        const logoDescriptionValue =
            configToUpdate.key === 'retailerPortalAppLogo'
                ? appLogoDescription
                : configToUpdate.key === 'retailerPortalReportLogo'
                ? logoDescription
                : null;
        const ImageSrc = isLogoKey
            ? this.isSvgLogo(
                  organization,
                  configToUpdate,
                  appLogoFile,
                  reportLogoFile
              )
            : '';
        const logoType =
            configToUpdate.key === 'retailerPortalAppLogo'
                ? appLogoDescription
                : configToUpdate.key === 'retailerPortalReportLogo'
                ? logoDescription
                : null;

        const headerText = configToUpdate.description;

        const isImageUpload =
            configToUpdate &&
            configToUpdate.group &&
            configToUpdate.group.name === 'image';
        const formToDisplay = isImageUpload ? (
            <LogoUploadForm
                descriptionType={configToUpdate.key}
                description={logoType}
            />
        ) : (
            <ConfigurationManagementForm configObject={configToUpdate} />
        );

        const value =
            configToUpdate.key === 'retailerPortalAppLogo' ? (
                <img src={ImageSrc} className="appLogo" alt="app logo" />
            ) : (
                <img src={ImageSrc} className="logo" alt="report logo" />
            );

        const configValue = { label: 'Logo', value };

        return (
            <Page name="UpdateConfigurationManagementPage">
                <SfaBreadCrumb
                    onBreadCrumb={this.gotoListPage}
                    breadCrumbText="Configuration Management"
                    animation="fade right"
                />
                <SfaForm
                    name="UpdateConfigurationManagementPage"
                    onSubmit={handleSubmit(
                        isImageUpload
                            ? this.logoUpload
                            : this.showConfirmationModal
                    )}
                    headerText={headerText}
                    onCancel={this.handleCancel}
                    showSaveCancel={!loading && !viewReadOnly}
                    submitIsDisabled={pristine}
                    showEdit={viewReadOnly}
                    editButtonRoles={['ROLE_RE_SYSTEM_CONFIG_UPDATE']}
                    deleteButtonRoles={['ROLE_RE_SYSTEM_CONFIG_UPDATE']}
                    editIsDisabled={
                        !viewReadOnly ||
                        !(
                            hasRole(
                                userInfo.authorities,
                                'ROLE_RE_SYSTEM_CONFIG_UPDATE'
                            ) &&
                            hasRole(userInfo.authorities, 'ROLE_RE_LOGO_UPLOAD')
                        ) //add is editable
                    }
                    deleteButtonText="Cancel"
                    showDelete={viewReadOnly}
                    onDelete={this.handleCancel}
                    onEdit={this.editConfig}
                    processing={processing}
                    loading={loading}
                    animation="fade left"
                >
                    <Form.Field>
                        {!viewReadOnly ? (
                            <div>
                                <OkCancelModal
                                    header="Save Date"
                                    body="Updating this date will have an impact on the application, including to pre-existing sales targets. Are you sure you wish to save?"
                                    cancelFunction={this.closeModal}
                                    okFunction={handleSubmit(this.updateConfig)}
                                    isOpen={showSave}
                                />
                                <OkCancelModal
                                    header={`Save to Configurations`}
                                    body={`Are you sure you want to save the changes?`}
                                    cancelFunction={this.closeConfirmationModal}
                                    okFunction={handleSubmit(this.updateConfig)}
                                    isOpen={showConfirmation}
                                    okText={'Save'}
                                />
                                {formToDisplay}
                            </div>
                        ) : (
                            <div>
                                <SfaReadView
                                    elements={[
                                        {
                                            label: 'Configuration',
                                            value: configToUpdate.description,
                                        },
                                        {
                                            label: 'Value*',
                                            value: configToUpdate.value,
                                        },

                                        configToUpdate.key ===
                                            'retailerPortalAppLogo' ||
                                        configToUpdate.key ===
                                            'retailerPortalReportLogo'
                                            ? configValue
                                            : null,
                                    ]}
                                />
                                {isLogoKey ? logoDescriptionValue : ''}
                            </div>
                        )}
                    </Form.Field>
                </SfaForm>
            </Page>
        );
    }
}

const mapStateToProps = ({
    organization,
    auth: { info },
    appLogo: { appLogoFile, reportLogoFile },
}) => ({
    organization,
    userInfo: info,
    appLogoFile,
    reportLogoFile,
    initialValues: organization.configToUpdate,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateConfigurationByKeyValue: (args) =>
            dispatch(updateConfigurationByKeyValue(args)),
        onLogoUpload: (args) => dispatch(logoUpload(args)),
        onInitLogoUpload: () => dispatch(initLogoUpload()),
        onSelectedConfig: (config) => dispatch(onSelectedConfig(config)),
        initConfig: () => dispatch(initConfig()),
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
        setupOrganizationInfo: () => dispatch(setupOrganizationInfo()),
    };
};

UpdateConfigurationManagementPage.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: 'UpdateConfigurationManagementPage',
            enableReinitialize: true,
        })(UpdateConfigurationManagementPage)
    )
);
