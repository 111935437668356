import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import permissions from '../../constants/permissions';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearCouriers,
    getCourierPage,
} from '../../redux/actions/courier/courier-actions';
import type { Courier } from '../../DTOs/courier';
import SfaTable from '../../components/datatable/Table';
import config from '../../constants/config';
import type {
    Query,
    SearchSpecificationModel,
    Sort,
} from '../../interface/search-specification-model';

const { COURIER_UPDATE, COURIER_CREATE } = config.ROUTE_URLS;
const CourierListPage = ({ history }) => {
    const dispatch = useDispatch();
    const [searchQuery: SearchSpecificationModel, setSearchQuery] = useState({
        page: 0,
        size: 15,
        sort: [
            {
                direction: 'DESC',
                property: 'modifiedDate',
            },
        ],
        query: undefined,
    });
    useEffect(() => {
        dispatch(getCourierPage(searchQuery));
        return () => {
            dispatch(clearCouriers());
        };
    }, [searchQuery, dispatch]);
    const courierPage: Page<Courier> = useSelector(
        (state) => state.courier.courierPage
    );

    const isLoading: boolean = useSelector((state) => state.courier.isLoading);
    const handleSelectTablePage = ({
        size,
        page,
        sortOrder,
        sortColumn,
        query,
    }) => {
        searchData(size, page, sortOrder, sortColumn, query);
    };

    const searchData = (size, page, sortOrder, sortColumn, query) => {
        const queryObject: Query = query
            ? [
                  {
                      operator: 'or',
                      value: [
                          {
                              value: `%${query}%`,
                              operator: 'like',
                              property: 'code',
                          },
                          {
                              value: `%${query}%`,
                              operator: 'like',
                              property: 'description',
                          },
                      ],
                  },
              ]
            : undefined;

        const sort: Sort[] =
            sortOrder && sortColumn
                ? [
                      {
                          direction: sortOrder.toUpperCase(),
                          property: sortColumn,
                      },
                  ]
                : [
                      {
                          direction: 'DESC',
                          property: 'modifiedDate',
                      },
                  ];

        setSearchQuery({
            page: page < 1 ? 0 : page - 1,
            size,
            sort: sort,
            query: queryObject,
        });
    };

    const handleSearch = (query) => {
        searchData(
            courierPage.size,
            0,
            courierPage?.sort ? [0].direction : undefined,
            courierPage?.sort ? [0].property : undefined,
            query
        );
    };
    return (
        <Page
            name="CourierListPage"
            title="Couriers"
            data-test="CourierListPage"
            addButtonRoles={[permissions.ROLE_COURIER_CREATE]}
            onAddButtonClick={() => {
                history.push(COURIER_CREATE);
            }}
        >
            <Container className={'data-section'}>
                <SfaTable
                    pagination
                    contentKey="id"
                    loading={isLoading}
                    content={courierPage?.content}
                    page={courierPage?.number}
                    size={courierPage?.size}
                    totalElements={courierPage?.totalElements}
                    totalPages={courierPage?.totalPages}
                    noDataMessage={'There are no Couriers.'}
                    selector={{
                        variables: ['id'],
                        path: COURIER_UPDATE,
                        callback: (p) => history.push(p),
                    }}
                    columns={[
                        {
                            name: 'code',
                            title: 'Code',
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'isActive',
                            title: 'Status',
                            sortable: true,
                            searchable: true,
                            formatter: (val) => {
                                return val ? 'Active' : 'Inactive';
                            },
                        },
                        {
                            name: 'description',
                            title: 'Description',
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'trackingUrl',
                            title: 'trackingUrl',
                            sortable: true,
                            searchable: true,
                        },
                    ]}
                    fetchData={handleSelectTablePage}
                    onSearch={handleSearch}
                />
            </Container>
        </Page>
    );
};
export default CourierListPage;
