import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { Field } from 'redux-form';

import SemanticCheckbox from '../form/semanticCheckbox/SemanticCheckbox';

const SfaCheckbox = ({ ...otherProps }) => {
    return (
        <Form.Field>
            <Field component={SemanticCheckbox} as={Checkbox} {...otherProps} />
        </Form.Field>
    );
};

export default SfaCheckbox;
