import {
    SERVER_TIME_INIT,
    SERVER_TIME_LOADING,
    SERVER_TIME,
} from '../../../../actions';

const NOT_AVAILABLE = 'NOT AVAILABLE';

const INITIAL_STATE = {
    serverTimes: {
        Game: NOT_AVAILABLE,
        Media: NOT_AVAILABLE,
        Organization: NOT_AVAILABLE,
        Retailer: NOT_AVAILABLE,
        Report: NOT_AVAILABLE,
        User: NOT_AVAILABLE,
    },
    loading: false,
};

const serverTime = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SERVER_TIME_INIT:
            return { ...INITIAL_STATE };
        case SERVER_TIME_LOADING:
            return { ...state, loading: payload };
        case SERVER_TIME:
            return {
                ...state,
                serverTimes: {
                    ...state.serverTimes,
                    [payload.serviceName]: payload.value,
                },
            };
        default:
            return state;
    }
};
export default serverTime;
