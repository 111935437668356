import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import createUser from './user/createUser';
import updateUser from './user/updateUser';
import userInfo from './user/userInfo';
import tasks from './tasks';
import drawer from './drawer';
import auth from './auth';
import userRoles from './user-roles';
import readyApp from './readyApp';
import referrer from './referrer';
import updateTask from './tasks/updateTask';
import createTask from './tasks/createTask';
import {
    tableSort,
    tableSearch,
    tableSelect,
} from '../../components/datatable/reducers';
import { tableFilters, filters } from '../../components/filter/reducers/index';
import filterOptions from './filterOptions';
import salesRoutes from './salesRoutes';
import createSalesRoute from './salesRoute/createSalesRoute';
import updateSalesRoute from './salesRoute/updateSalesRoute';
import retailers from './retailers';
import { errorReducer } from '../../services/error-handler';
import listUsers from './listUsers';
import planograms from './planograms';
import createPlanogramGroup from './planogram-group/createPlanogramGroup';
import updatePlanogram from './planogram-group/updatePlanogram';
import getPlanogramUploadList from './planogram-group/getPlanogramUploadList';
import uploadPlanogram from './planogram-group/uploadPlanogram';
import updatePlanogramMetadata from './planogram-group/updatePlanogramMetadata';
import planogramOptions from './planogram-group/planogramOptions';
import readRetailer from './readRetailer';
import salesRouteOptions from './salesRouteOptions';
import tierOptions from './tier/tierOptions';
import createTiers from './tier/createTiers';
import updateTiers from './tier/updateTiers';
import tiers from './tiers';
import weeklyScheduler from './weeklyScheduler';
import organization from './organization';
import allTasks from './tasks/allTasks';
import tasksCreatedByMe from './tasks/tasksCreatedByMe';
import draftTasks from './tasks/draftTasks';
import updateUTR from './tasks/updateUTR';
import retailerTask from './tasks/retailerTask';
import dailyTasks from './tasks/dailyTasks';
import pastDueTasks from './tasks/pastDueTasks';
import retailerPastVisitTasks from './tasks/retailerPastVisitTasks';
import oneTimePopup from '../../components/onetime-popup/reducers';
import notifications from './notifications';
import footer from './footer';
import corpAccounts from './corpAccounts';
import readCorpAccount from './readCorpAccount';
import prizeWinnings from './prizeWinnings';
import retailerPrizeWinnings from './retailerPrizeWinnings';
import zones from './zones';
import readZone from './readZone';
import createZone from './zones/createZones';
import textNotes from './textNotes/textNotes';
import textNotesRetailerAccounts from './textNotesRetailerAccounts/textNotesRetailerAccounts';
import readTextNote from './textNotes/readTextNote';
import createTextNote from './textNotes/createTextNote';
import retailerAttachments from './retailerAttachments/retailerAttachments';
import uploadRetailerAttachment from './retailerAttachments/uploadRetailerAttachment';
import listReports from './reports/listReports';
import generateReport from './reports/generateReport';
import createComplianceTemplate from './compliance/createCompliance';
import updateComplianceTemplate from './compliance/updateCompliance';
import listComplianceTemplate from './compliance/compliance';
import listMarketingSurvey from './marketing/marketing';
import createMarketingSurvey from './marketing/createMarketing';
import updateMarketingSurvey from './marketing/updateMarketing';
import deleteMarketingSurvey from './marketing/deleteMarketing';
import survey from './survey/survey';
import salesTargets from './salesTargets/salesTargets';
import createSalesTarget from './salesTargets/createSalesTarget';
import activeUser from './activeUser';
import errorMessage from './errorHandler/errorHandler';
import pricePoints from './pricePoints';
import weeklyTaskCompletion from './dashboard/widgets/weeklyTaskCompletion';
import weeklyTaskCompletionForSalesManagers from './dashboard/widgets/weeklyTaskCompletionForSalesManagers';
import sales from './sales/sales';
import listRetailerInventory from './retailerInventory/retailerInventory';
import listRetailerPosItems from './retailerPos/retailerPos';
import createRetailerPos from './retailerPos/createRetailerPos';
import updateRetailerPos from './retailerPos/updateRetailerPos';
import retailerOrderDetails from './retailerInventory/retailerOrderDetails';
import retailerOrderGameDetails from './retailerInventory/retailerOrderGameDetails';
import shortTermSales from './shortTermSales';
import taskSummaryWidget from './tasks/taskSummaryWidget';
import topGames from './retailerDashboard/widgets/topGames/topGames';
import lastOrderSummary from './retailerDashboard/widgets/lastOrderSummary/lastOrderSummary';
import serverTime from './retailerDashboard/widgets/serverTime/serverTime';
import totalPurchaseYTD from './retailerDashboard/widgets/totalPurchaseYTD/totalPurchaseYTD';
import totalPurchaseYtdNonProducts from './retailerDashboard/widgets/totalPurchaseYtdNonProducts/totalPurchaseYtdNonProducts';
import totalPurchaseYTDByPP from './retailerDashboard/widgets/totalPurchaseYTDByPP/totalPurchaseYTDByPP';
import exportSurvey from './marketing/exportSurvey';
import serviceCall from './retailerDashboard/widgets/serviceCall/serviceCall';
import ytdTotalCommissions from './invoice/ytdTotalCommissions';
import lastPeriodCommissions from './invoice/lastPeriodCommissions';
import ytdSalesTargetWidget from './dashboard/widgets/ytdSalesTargetWidget';
import ytdSalesTargetComparisonWidget from './sales/sales';
import dashboardSettings from './dashboard/settings';
import tempDashboardSettings from './dashboard/tempSettings';
import updateConfiguration from './organization';
import appLogo from './appLogo';
import visitPlanner from './visits/planner';
import retailerDashboardModal from '../../components/retailerDashboardModal/reducers';
import todayPlanWidget from './dashboard/widgets/todayPlanWidget';
import lastVisitDateWidget from './retailerDashboard/widgets/lastVisitDateWidget';
import retailerInstantInventory from './retailerInstantInventory/retailerInstantInventory';
import routeMapping from './routeMapping/routeMapping';
import i18n from './i18n';
import toggleOptimizer from './optimizer';
import orderItem from './orderItem/orderItem';
import posItems from './posItems/posItems';
import gameItems from './gameItems/gameItems';
import privileges from './privileges/privileges';
import retailerSelect from './retailerSelect/retailerSelect';
import orderQuantity from './cart/orderQuantity';
import cart from './cart/cart';
import checkout from './checkout/checkout';
import pastOrders from './orders/pastOrders';
import inProgressOrders from './orders/inProgressOrders';
import orderDetail from './orders/orderDetail';
import cancelOrder from './orders/cancelOrder';
import invoices from './invoice/invoices';
import invoiceDetail from './invoice/invoiceDetail';
import generateInvoice from './invoice/generateInvoice';
import contactUsConfiguration from './contactUsConfiguration/contactUsConfiguration';
import forgotPassword from './forgotPassword/forgotPassword';
import retailerNav from './retailerNav/retailerNav';
import publicFaq from './publicFaq/publicFaq';
import privateFaq from './privateFaq/privateFaq';
import privacyPolicy from './privacyPolicy/privacyPolicy';
import publicContact from './publicContact/publicContact';
import privateContact from './privateContact/privateContact';
import documentUpload from './documentUpload/documentUpload';
import generateOrder from './orders/generateOrder';
import formsMetadata from './formsMetadata/formsMetadata';
import createRole from './privileges/createRole';
import publicNewRetailer from './publicNewRetailer/publicNewRetailer';
import serviceVersions from './adminWidgets/serviceVersions';
import retailerTypeOptions from './selectOptions/retailerTypes/retailerTypeOptions';
import tourConfiguration from './tourConfiguration/tourConfiguration';
import frontendConfig from './config/frontendConfig';
import planogramsWidget from './retailerDashboard/widgets/planograms/planograms';
import jurisdictionReducer from './jurisdiction/jurisdiction-reducer';
import retailerGroupReducer from './retailer-group/retailer-group-reducer';
import editRetailerGroupReducer from './retailer-group/edit-retailer-group-reducer';
import courierReducer from './courier/courier-reducer';
import editCourierReducer from './courier/edit-courier-reducer';
import planogramReducer from './planogram/planogram-reducer';
import editPlanogramReducer from './planogram/edit-planogram-reducer';
import suggestedQuantityReducer from './gameItems/suggested-quanity-reducer';
import upcomingGamesWidget from './retailerDashboard/widgets/upcomingGames/upcomingGames';
import closingGamesWidget from './retailerDashboard/widgets/closingGames/closingGames';
import returns from './returns/returns';
import createReturn from './returns/createReturn';
import returnReasonSelect from './returns/returnReasonSelect';
import cognitoLoginReducer from './cognito/cognito-login';
import cancelReturn from './returns/cancelReturn';
import viewReturn from './returns/viewReturn';
import userRetailer from './user/user-retailer';
import userPreference from './user/user-preference';
export default combineReducers({
    createUser,
    updateUser,
    userInfo,
    tasks,
    allTasks,
    tasksCreatedByMe,
    draftTasks,
    drawer,
    auth,
    userRoles,
    readyApp,
    referrer,
    createTask,
    updateTask,
    tableSort,
    tableSearch,
    tableSelect,
    tableFilter: tableFilters,
    filters,
    filterOptions,
    salesRoutes,
    salesRouteOptions,
    tierOptions,
    createSalesRoute,
    updateSalesRoute,
    createPlanogram: createPlanogramGroup,
    updatePlanogram,
    getPlanogramUploadList,
    uploadPlanogram,
    updatePlanogramMetadata,
    planogramOptions,
    retailers,
    readRetailer,
    listUsers,
    planograms,
    createTiers,
    updateTiers,
    tiers,
    organization,
    weeklyScheduler,
    updateUTR,
    retailerTask,
    dailyTasks,
    pastDueTasks,
    retailerPastVisitTasks,
    apiError: errorReducer,
    notifications,
    oneTimePopup,
    footer,
    corpAccounts,
    readCorpAccount,
    prizeWinnings,
    retailerPrizeWinnings,
    zones,
    router: routerReducer,
    form: formReducer,
    readZone,
    createZone,
    textNotes,
    readTextNote,
    createTextNote,
    retailerAttachments,
    uploadRetailerAttachment,
    listReports,
    generateReport,
    createComplianceTemplate,
    updateComplianceTemplate,
    listComplianceTemplate,
    listMarketingSurvey,
    createMarketingSurvey,
    updateMarketingSurvey,
    deleteMarketingSurvey,
    survey,
    salesTargets,
    createSalesTarget,
    activeUser,
    errorMessage,
    pricePoints,
    weeklyTaskCompletion,
    weeklyTaskCompletionForSalesManagers,
    sales,
    listRetailerInventory,
    listRetailerPosItems,
    createRetailerPos,
    updateRetailerPos,
    retailerOrderDetails,
    retailerOrderGameDetails,
    shortTermSales,
    taskSummaryWidget,
    topGames,
    lastOrderSummary,
    serverTime,
    totalPurchaseYTD,
    totalPurchaseYtdNonProducts,
    totalPurchaseYTDByPP,
    exportSurvey,
    ytdTotalCommissions,
    lastPeriodCommissions,
    serviceCall,
    ytdSalesTargetWidget,
    ytdSalesTargetComparisonWidget,
    dashboardSettings,
    tempDashboardSettings,
    updateConfiguration,
    appLogo,
    visitPlanner,
    retailerDashboardModal,
    todayPlanWidget,
    lastVisitDateWidget,
    retailerInstantInventory,
    routeMapping,
    i18n,
    toggleOptimizer,
    textNotesRetailerAccounts,
    orderItem,
    posItems,
    gameItems,
    privileges,
    retailerSelect,
    orderQuantity,
    cart,
    checkout,
    pastOrders,
    inProgressOrders,
    orderDetail,
    cancelOrder,
    invoices,
    invoiceDetail,
    generateInvoice,
    contactUsConfiguration,
    forgotPassword,
    retailerNav,
    publicFaq,
    privateFaq,
    privacyPolicy,
    publicContact,
    privateContact,
    documentUpload,
    generateOrder,
    formsMetadata,
    createRole,
    publicNewRetailer,
    serviceVersions,
    retailerTypeOptions,
    tourConfiguration,
    frontendConfig,
    planogramsWidget,
    jurisdiction: jurisdictionReducer,
    retailerGroup: retailerGroupReducer,
    editRetailerGroup: editRetailerGroupReducer,
    planogram: planogramReducer,
    editPlanogram: editPlanogramReducer,
    suggestedQuantity: suggestedQuantityReducer,
    upcomingGamesWidget,
    closingGamesWidget,
    returns,
    createReturn,
    returnReasonSelect,
    cognitoLoginReducer,
    cancelReturn,
    viewReturn,
    courier: courierReducer,
    editCourier: editCourierReducer,
    userRetailer,
    userPreference,
});
