import {
    PUSH_REFERRER,
    POP_REFERRER,
    REPLACE_REFERRER,
    CLEAR_REFERRER,
} from '../actions/referrer';

const INITIAL_STATE = {
    referrer: [],
};

const referrer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PUSH_REFERRER:
            return {
                ...state,
                referrer: [
                    ...state.referrer,
                    {
                        referringPage: action.page,
                        referringPath: action.path,
                        referringData: action.data,
                    },
                ],
            };
        case POP_REFERRER:
            return {
                ...state,
                referrer:
                    state.referrer.length > 0
                        ? state.referrer.slice(0, -1)
                        : [],
            };
        case REPLACE_REFERRER:
            const newReferrer = {
                referringPage: action.page,
                referringPath: action.path,
                referringData: action.data,
            };
            return {
                ...state,
                referrer:
                    state.referrer.length > 0
                        ? [...state.referrer.slice(0, -1), newReferrer]
                        : [newReferrer],
            };
        case CLEAR_REFERRER:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default referrer;
