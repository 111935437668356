import { React } from 'react';
import { Form } from 'semantic-ui-react';
export const FormWrapper = (props) => {
    const onCustomSubmit = (event) => {
        const { onSubmit } = props;
        onSubmit();
        document.activeElement.blur();
    };

    return (
        <Form props={{ ...props }} onSubmit={onCustomSubmit}>
            {props.children}
        </Form>
    );
};
