import type { Jurisdiction } from '../../../DTOs/jurisdiction';

export const GET_JURISDICTION_LIST = 'Jurisdiction/Get';
export const UPDATE_LOADING_STATE = 'Jurisdiction/loading';

export interface JurisdictionStateType {
    isLoading: boolean;
    jurisdictions: Jurisdiction[];
}

interface GetJurisdictionType {
    type: typeof GET_JURISDICTION_LIST;
    payload: Jurisdiction[];
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type JurisdictionActionTypes = GetJurisdictionType | UpdateLoadingState;
