import {
    PRICE_POINTS_LOADED,
    PRICE_POINTS_LOADING,
} from '../actions/pricePoints';

const INITIAL_STATE = {
    content: [],
    loading: false,
};

const pricePoints = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRICE_POINTS_LOADING:
            return { ...state, loading: true };
        case PRICE_POINTS_LOADED:
            return {
                ...state,
                content: action.list,
                loading: false,
            };
        default:
            return state;
    }
};
export default pricePoints;
