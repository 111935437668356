import config from '../../constants/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import SfaForm from '../../components/SfaForm';
import { initCreateRole, createRole } from '../../redux/actions/';
import { success } from '../../redux/actions';
import PropTypes from 'prop-types';
import Page from '../../layout/Page';
import CreateRoleForm from '../../components/permissions-management/CreateRoleForm';
import { salesRouteActions } from '../../constants/salesRoutes';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import OkCancelModal from '../../components/OkCancelModal';

const { PERMISSIONS_CONFIGURATION_MANAGEMENT } = config.ROUTE_URLS;

class CreateRolePage extends Component {
    state = {
        isEditable: true,
        showConfirmation: false,
    };
    componentWillMount() {
        this.props.initCreateRole();
    }

    handleCancelClick = () => {
        this.goToListPage();
    };

    goToListPage = () => {
        this.props.history.push(PERMISSIONS_CONFIGURATION_MANAGEMENT);
    };

    showConfirmationModal = () => this.setState({ showConfirmation: true });
    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    onSubmit = async (values) => {
        this.closeConfirmationModal();
        const { createSuccess, createRoleObject } = this.props;
        const valuesToUpdate = {
            displayName: values.displayName,
            isEnabled: JSON.stringify(createRoleObject.isEnabled),
        };

        const success = await this.props.createRole(valuesToUpdate);
        if (success) {
            this.goToListPage();
            createSuccess({
                msg:
                    'Successfully created ' +
                    this.props.createdRole.displayName +
                    ' role',
            });
        }
    };

    render() {
        const {
            processing,
            createRoleObject,
            loading,
            handleSubmit,
            pristine,
        } = this.props;

        return (
            <Page name="CreateRolePage" data-test="createrolepage">
                <SfaBreadCrumb
                    onBreadCrumb={this.goToListPage}
                    breadCrumbText="User Roles & Permissions"
                    animation="fade right"
                />

                <SfaForm
                    name="CreateSalesRoute"
                    headerText="Create User Role"
                    onSubmit={handleSubmit(this.showConfirmationModal)}
                    onCancel={this.handleCancelClick}
                    submitButtonText="Create"
                    showSaveCancel={!loading}
                    processing={processing}
                    loading={loading}
                    submitIsDisabled={pristine}
                    formName="CreateRoleForm"
                >
                    {
                        <div>
                            <OkCancelModal
                                header={`Create Role`}
                                body={`Are you sure you want to save the changes?`}
                                cancelFunction={this.closeConfirmationModal}
                                okFunction={handleSubmit(this.onSubmit)}
                                isOpen={this.state.showConfirmation}
                                okText={'Save'}
                            />
                            <CreateRoleForm
                                action={salesRouteActions.SALES_ROUTE_CREATE}
                                createRoleObject={createRoleObject}
                                data-test="createroleform"
                            />
                        </div>
                    }
                </SfaForm>
            </Page>
        );
    }
}

const mapStateToProps = ({
    createRole: { createRoleObject, loading, roleData },
    auth: { info },
}) => ({
    createRoleObject,
    loading,
    createdRole: roleData,
    userInfo: info,
    initialValues: createRoleObject,
});

const mapDispatchToProps = (dispatch) => {
    return {
        createRole: (args) => dispatch(createRole(args)),
        initCreateRole: () => dispatch(initCreateRole()),
        createSuccess: ({ msg }) => dispatch(success({ msg })),
    };
};

CreateRolePage.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(reduxForm({ form: 'CreateRoleForm' })(CreateRolePage))
);
