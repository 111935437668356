import { GamesApi } from '../../../../../services/games-service';
export const UPCOMING_GAMES_LOADING = 'UPCOMING_GAMES_LOADING';
export const UPCOMING_GAMES = 'UPCOMING_GAMES';
export const UPCOMING_GAMES_INIT = 'UPCOMING_GAMES_INIT';
export const UPCOMING_GAMES_ERROR = 'UPCOMING_GAMES_ERROR';

const gamesApi = new GamesApi();

export const initUpcomingGamesForRetailerReference = () => {
    return async (dispatch) => {
        dispatch({ type: UPCOMING_GAMES_INIT });
    };
};

export const getUpcomingGamesForRetailerReference = (
    retailerType,
    noOfDaysForUpcomingGames,
    jurisdictionCode
) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPCOMING_GAMES_LOADING });
            const upcomingGamesForRetailer = await gamesApi.getUpcomingGames(
                retailerType,
                noOfDaysForUpcomingGames,
                jurisdictionCode
            );
            dispatch({
                type: UPCOMING_GAMES,
                upcomingGamesForRetailer,
            });
        } catch (e) {
            dispatch({
                type: UPCOMING_GAMES_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
