import React from 'react';
import moment from 'moment';

const Copyright = () => (
    <span className="caption-text">
        Copyright © {moment().format('YYYY')} Pollard Banknote Limited. All
        rights reserved.
    </span>
);

export default Copyright;
