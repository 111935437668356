import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    filterToggle,
    addSelectedItem,
    addAllSelectedItems,
    removeSelectedItem,
    removeAllSelectedItems,
} from '../actions';
import Calendar from '../../calendar/Calendar';

class FilterContent extends Component {
    state = {
        list: [],
        filteredList: [],
    };

    componentWillMount() {
        const { items, name, defaultSelected, selectedItems, clearAll } =
            this.props;

        this.setState({ list: items, filteredList: items });

        if (!selectedItems[name] && !clearAll && defaultSelected) {
            this.props.selectItem(name, defaultSelected);
        }
    }

    onDateValueChange = async (name, value) => {
        const { selectedItems } = this.props;

        if (selectedItems[name]) {
            await this.props.deselectAllItems(name);
        }

        await this.props.selectItem(name, value);
        if (this.props.onValuesChange) {
            this.props.onValuesChange(name, value);
        }
    };

    renderCalendar = () => {
        const { selectedItems, name, minDate, maxDate } = this.props;
        let date = moment().format('YYYY-MM-DD');
        if (selectedItems[name]) {
            date = selectedItems[name][0];
        }

        return (
            <Calendar
                selectedDate={date}
                maxDate={maxDate}
                minDate={minDate}
                onValuesChange={(value) => this.onDateValueChange(name, value)}
            />
        );
    };

    render() {
        const { containerStyle } = this.props;

        return (
            <div className="filter-content" style={{ ...containerStyle }}>
                <div style={{ overflowY: 'auto', height: '100%' }}>
                    {this.renderCalendar()}
                </div>
            </div>
        );
    }
}

export default connect(
    ({ tableFilter }) => {
        const { name, toggle, selectedItems, clearAll } = tableFilter;
        return { filterName: name, toggle, selectedItems, clearAll };
    },
    (dispatch) => ({
        toggleFilter: (name, toggle) => dispatch(filterToggle(name, toggle)),
        selectItem: (name, item) => dispatch(addSelectedItem(name, item)),
        deselectItem: (name, item) => dispatch(removeSelectedItem(name, item)),
        selectAllItems: (name, items) =>
            dispatch(addAllSelectedItems(name, items)),
        deselectAllItems: (name) => dispatch(removeAllSelectedItems(name)),
    })
)(FilterContent);
