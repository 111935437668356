import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import UserInfo from '../../components/UserInfo';
import UserRole from '../../components/UserRole';
import {
    verifyUser,
    createUser,
    initCreateUser,
    changeUsername,
    success,
    error,
} from '../../redux/actions';

import SfaForm from '../../components/SfaForm';
import config from '../../constants/config';
import { Form } from 'semantic-ui-react';
import RetailerSelect from '../../components/retailerSelect/RetailerSelect';
import Page from '../../layout/Page';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import OkCancelModal from '../../components/OkCancelModal';
import SfaInput from '../../components/SfaInput';
import {
    isValidEmail,
    isValidLength,
    required,
} from '../../constants/validate';
import { role_names } from '../../constants/roles';

const { USER_MANAGEMENT } = config.ROUTE_URLS;

export class CreateUser extends Component {
    state = {
        firstTimeVerify: true,
        showConfirmation: false,
        isUserNameVerified: false,
    };

    createUser = async (values) => {
        const { createUser, createSuccess } = this.props;
        this.closeConfirmationModal();
        const success = await createUser(values);

        if (success) {
            this.gotoListPage();
            createSuccess({
                msg: 'User was successfully created.',
                target: 'UsersPage',
            });
        }
    };

    onChange = (event) => {
        const fields = this.props.fields;
        const { name, value } = event.target;
        fields[name] = value;
        this.setState({ fields });
    };

    onUsernameChange = async (event) => {
        const { isUserNameVerified } = this.state;
        if (isUserNameVerified) {
            this.setState({ isUserNameVerified: !isUserNameVerified });
        }
    };

    saveField = (name, value) => {
        const fields = this.props.fields;
        fields[name] = value;
        this.setState({ fields });
    };

    handleVerifyUser = async (values) => {
        this.setState({ firstTimeVerify: false });

        let result = await this.props.verifyUser(values);
        if (!result.isAxiosError) {
            this.setState({ isUserNameVerified: true });
        }
    };

    handleCancelUser = (e) => {
        e.preventDefault();
        this.gotoListPage();
    };

    gotoListPage = () => {
        this.props.initCreateUser();
        this.props.history.push(USER_MANAGEMENT);
    };

    showConfirmationModal = (values) => {
        const { isUserNameVerified } = this.state;
        if (!isUserNameVerified) {
            this.handleVerifyUser(values);
        } else {
            this.setState({ showConfirmation: true });
        }
    };
    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    render() {
        const { infoText } = style;
        const { fields, errors, loading, handleSubmit } = this.props;
        const { isUserNameVerified } = this.state;
        const isValid = errors.length === 0;

        return (
            <Page name="CreateUserPage" data-test="createUserPage">
                <SfaBreadCrumb
                    onBreadCrumb={this.gotoListPage}
                    breadCrumbText="User Management"
                    animation="fade right"
                />
                <OkCancelModal
                    header={`Create User`}
                    body={`Are you sure you want to create the user?`}
                    cancelFunction={this.closeConfirmationModal}
                    okFunction={handleSubmit(this.createUser)}
                    isOpen={this.state.showConfirmation}
                    okText={'Create'}
                />
                <SfaForm
                    name="CreateUser"
                    onSubmit={handleSubmit(this.showConfirmationModal)}
                    headerText="Create User"
                    onCancel={this.handleCancelUser}
                    errors={errors}
                    isValid={isValid}
                    submitButtonText={isUserNameVerified ? 'Create' : 'Fetch'}
                    showSaveCancel={true}
                    // submitIsDisabled={!isUserVerified}

                    loading={loading}
                >
                    <Form.Field>
                        <p className="half-opaque-text" style={infoText}>
                            To add an user account you need an active user
                            already created on your organizations central
                            accounts repository. Enter the username below to
                            fetch the user information. If you have questions,
                            check with your organization's administrator.
                        </p>
                    </Form.Field>
                    <Form.Field>
                        <SfaInput
                            label={'Username *'}
                            inputName={'username'}
                            validations={required}
                            helpText="Max 100 characters"
                            maxLength={100}
                            onChange={this.onUsernameChange}
                        />
                    </Form.Field>

                    {isUserNameVerified ? (
                        <Form.Field>
                            <UserInfo fields={fields} />
                            <UserRole fields={fields} />
                            <RetailerSelect />
                        </Form.Field>
                    ) : null}
                </SfaForm>
            </Page>
        );
    }
}

const style = {
    helperText: {
        color: '#337ab7',
    },
    spinner: {
        fill: 'rgb(255, 255, 255)',
        height: '20px',
        width: '25px',
        margin: 'auto',
    },
    infoText: {
        fontSize: '12px',
    },
};

const mapStateToProps = ({
    createUser: {
        fields,

        errors,
        loading,
        userSuccessMessage,
    },
}) => ({
    fields,
    errors,
    loading,
    userSuccessMessage,
    initialValues: {
        ...fields,
        roles: fields.roles && fields.roles[0] ? fields.roles[0].name : '',
        retailer: fields.retailerReference,
    },
});

const mapDispatchToProps = (dispatch) => {
    return {
        verifyUser: (args) => dispatch(verifyUser(args)),
        createUser: (args) => dispatch(createUser(args)),
        initCreateUser: () => dispatch(initCreateUser()),
        changeUsername: (args) => dispatch(changeUsername(args)),
        createSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
        createError: ({ msg, target }) => dispatch(error({ msg, target })),
    };
};
const validate = (values) => {
    let error = {};
    if (
        values &&
        (!values.username ||
            !(
                values.username.match('^[a-zA-Z0-9-_]{6,25}$') ||
                !isValidEmail(values.username, true)
            ))
    ) {
        error.username =
            "Choose a username 6–25 characters long. Your username can be an email ID or any combination of letters, numbers, or symbols('-','_')";
    }
    if (values) {
        error.firstName = isValidLength(
            values.firstName,
            1,
            25,
            true,
            'Choose a firstName 1–25 characters long.',
        );
        error.lastName = isValidLength(
            values.lastName,
            1,
            25,
            true,
            'Choose a lastname 1–25 characters long.',
        );
        error.email = isValidEmail(values.email, true, 'Choose a valid email.');
    }
    if (values && (!values.roles || values.roles.length < 1)) {
        error.roles = 'Please select role.';
    }
    if (values && (!values.retailer || values.retailer.length < 1)) {
        if (
            !values.roles ||
            values.roles.length < 1 ||
            values.roles === role_names.RETAILER
        ) {
            error.retailer = 'Please select retailer.';
        }
    }
    return error;
};
CreateUser.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        reduxForm({
            form: 'CreateUser',
            validate: validate,
            enableReinitialize: true,
        })(CreateUser),
    ),
);
