import type { Query } from '../../interface/search-specification-model';

export const myPlanogramQuery = (numberOfFacings, jurisdiction) => {
    const btwQuery: Query = {
        operator: 'and',
        value: [
            {
                value: numberOfFacings,
                operator: 'gte',
                property: 'retailerGroup.maxFacings',
            },
            {
                value: numberOfFacings,
                operator: 'lte',
                property: 'retailerGroup.minFacings',
            },
        ],
    };

    const maxNullQuery: Query = {
        operator: 'and',
        value: [
            {
                operator: 'null',
                property: 'retailerGroup.maxFacings',
            },
            {
                value: numberOfFacings,
                operator: 'lte',
                property: 'retailerGroup.minFacings',
            },
        ],
    };
    const orQuery = {
        operator: 'or',
        value: [btwQuery, maxNullQuery],
    };

    const finalQueryArray = [orQuery, getIsActiveQuery()];
    if (jurisdiction) {
        finalQueryArray.push(getJurisdictionQuery(jurisdiction.code));
    }
    return {
        operator: 'and',
        value: finalQueryArray,
    };
};
export const getIsActiveQuery = () => {
    return {
        value: true,
        operator: 'eq',
        property: 'isActive',
    };
};
export const getJurisdictionQuery = (id) => {
    return {
        value: id,
        operator: 'eq',
        property: 'retailerGroup.jurisdictionCode',
    };
};

export const getCurrentStatusQuery = () => {
    const startQuery: Query = {
        value: getCurrentDate(),
        operator: 'lte',
        property: 'startDate',
    };
    const endQuery: Query = {
        value: getCurrentDate(),
        operator: 'gte',
        property: 'endDate',
    };
    const innerQuery: Query = {
        operator: 'and',
        value: [startQuery, endQuery],
    };
    return innerQuery;
};
export const getUpcomingQuery = () => {
    const innerQuery: Query = {
        value: getCurrentDate(),
        operator: 'gt',
        property: 'startDate',
    };
    return innerQuery;
};
export const getPreviousQuery = () => {
    const innerQuery: Query = {
        value: getCurrentDate(),
        operator: 'lt',
        property: 'endDate',
    };
    return innerQuery;
};

const getCurrentDate = () => {
    return new Date().toISOString().slice(0, 10);
};

export const createDateRangeQuery = (startDate, endDate) => {
    const startQuery: Query = {
        value: [startDate, endDate],
        operator: 'bw',
        property: 'startDate',
    };
    const endQuery: Query = {
        value: [startDate, endDate],
        operator: 'bw',
        property: 'endDate',
    };
    const betweenQuery: Query = {
        operator: 'or',
        value: [startQuery, endQuery],
    };
    const startLessThanQuery: Query = {
        value: startDate,
        operator: 'lt',
        property: 'startDate',
    };
    const endGreaterThanQuery: Query = {
        value: endDate,
        operator: 'gt',
        property: 'endDate',
    };
    const lessThanOrEqualThan: Query = {
        operator: 'and',
        value: [startLessThanQuery, endGreaterThanQuery],
    };
    const innerQuery: Query = {
        operator: 'or',
        value: [betweenQuery, lessThanOrEqualThan],
    };
    return innerQuery;
};
