import { ZonesApi } from '../../../services/zones-service';
import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

export const ZONES_LIST = 'ZONES_LIST';
export const ZONES_LOADING = 'ZONES_LOADING';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const zonesApi = new ZonesApi();

export const searchZones = (args) => {
    return async (dispatch) => {
        dispatch({ type: ZONES_LOADING });
        try {
            let list = await zonesApi.searchZones(args);

            dispatch({
                type: ZONES_LIST,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchZonesAsync = (args) => {
    return async (dispatch) => {
        return await zonesApi.searchZones(args);
    };
};
