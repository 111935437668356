import { Backend } from '../backend';

class TourServiceApi {
    constructor() {
        this.path = '/organization/tour';
    }

    async getTourData() {
        try {
            return await Backend.send({
                method: 'GET',
                url: this.path,
            });
        } catch (e) {
            throw e;
        }
    }

    async setTourData(tourData) {
        try {
            let formData = new FormData();
            formData.append('image', tourData.image);
            formData.append('video', tourData.video);
            formData.append('isEnabled', tourData.isEnabled);

            return await Backend.send({
                method: 'POST',
                url: this.path,
                body: formData,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }
}

export default TourServiceApi;
