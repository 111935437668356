import { ONETIME_TOGGLE_POPUP, ONETIME_DISABLE_POPUP } from './actions';

const INITIAL_STATE = {
    isPopupOpen: false,
    disabledPopUps: [],
    currentId: null,
};

const oneTimePopup = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ONETIME_TOGGLE_POPUP:
            return {
                ...state,
                isPopupOpen: action.isPopupOpen,
                currentId: action.id,
            };
        case ONETIME_DISABLE_POPUP:
            return {
                isPopupOpen: false,
                currentId: null,
                disabledPopUps: [...state.disabledPopUps, action.popupName],
            };
        default:
            return state;
    }
};

export default oneTimePopup;
