import {
    surveyStatuses,
    questionTypes,
} from '../../../constants/surveyOptions';
import {
    MARKETING_CREATE_SUCCESS,
    MARKETING_CREATE_INIT,
    MARKETING_CREATE_PAGE_LOADING,
    MARKETING_CREATE_PAGE_DONE,
    MARKETING_ADD_QUESTION,
    MARKETING_QUESTION_SORT,
    MARKETING_UPDATE_QUESTIONS,
    MARKETING_UPDATE_COMBO,
} from '../../actions';

const INIT_MARKETING_CREATE = {
    id: '',
    name: '',
    description: '',
    question: '',
    questionType: null,
    status: 'DRAFT',
};

const INITIAL_STATE = {
    loading: false,
    marketingSurvey: { ...INIT_MARKETING_CREATE },
    createMarketingOptions: {
        questionTypes: [...questionTypes],
        statuses: [...surveyStatuses],
    },
    questions: [],
    comboOptions: {},
};

const updateSortOrder = (state, oldIndex, newIndex) => {
    const { questions } = state;

    if (
        oldIndex > -1 &&
        oldIndex < questions.length &&
        newIndex > -1 &&
        newIndex < questions.length
    ) {
        let currentQuestion = { ...questions[oldIndex] };
        const temp = { ...questions[newIndex] };

        questions[newIndex] = currentQuestion;
        questions[oldIndex] = temp;
        return questions;
    }

    return state.questions;
};

const createMarketing = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MARKETING_CREATE_PAGE_LOADING:
            return { ...state, loading: true };
        case MARKETING_CREATE_PAGE_DONE:
            return { ...state, loading: false };
        case MARKETING_CREATE_SUCCESS:
            return { ...state, marketingSurvey: { ...INIT_MARKETING_CREATE } };
        case MARKETING_CREATE_INIT:
            return {
                ...INITIAL_STATE,
                marketingSurvey: { ...INIT_MARKETING_CREATE },
            };
        case MARKETING_ADD_QUESTION:
            return {
                ...state,
                questions: [...state.questions, action.question],
            };
        case MARKETING_QUESTION_SORT:
            return {
                ...state,
                questions: [
                    ...updateSortOrder(
                        state,
                        action.oldSortOrder,
                        action.newSortOrder
                    ),
                ],
            };
        case MARKETING_UPDATE_QUESTIONS:
            return { ...state, questions: [...action.questions] };
        case MARKETING_UPDATE_COMBO:
            return { ...state, comboOptions: { ...action.options } };

        default:
            return state;
    }
};
export default createMarketing;
