import React from 'react';
import UneditableLabel from './UneditableLabel';
import { Form } from 'semantic-ui-react';
import Pill from './pill/Pill';

const UneditablePill = ({ label, data }) => (
    <Form.Field>
        <div className="readonly">
            <UneditableLabel text={label} />
        </div>
        <div>
            <Pill text={data} />
        </div>
    </Form.Field>
);

export default UneditablePill;
