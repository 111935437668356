export const TASK_OPTIONS = {
    assignees: [],
    days: [],
    months: [],
    priorities: [],
    recurrencePatterns: [],
    schedules: [],
    statuses: [],
    taskCategories: [],
    retailers: [],
};
