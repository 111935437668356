import {
    RETAILER_POS_ITEMS,
    RETAILER_POS_ITEMS_LOADING,
    RETAILER_POS_ITEMS_LOADING_DONE,
    POS_ITEMS,
    POS_ITEMS_LOADING,
    POS_ITEMS_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 10,
    number: 0,
    loading: false,
    loadingItems: false,
};

const retailerPos = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_POS_ITEMS_LOADING:
            return { ...state, loading: true };
        case RETAILER_POS_ITEMS:
            return {
                ...state,
                ...action.retailerPosItemList,
            };
        case RETAILER_POS_ITEMS_LOADING_DONE:
            return { ...state, loading: false };
        case POS_ITEMS_LOADING:
            return { ...state, loadingItems: true };
        case POS_ITEMS:
            return {
                ...state,
                itemList: action.list,
            };
        case POS_ITEMS_LOADING_DONE:
            return { ...state, loadingItems: false };
        default:
            return state;
    }
};
export default retailerPos;
