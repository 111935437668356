import {
    CREATE_RETAILER_POS_SELECT_ITEM,
    RETAILER_POS_INIT,
    CREATE_RETAILER_POS_LOADING,
    CREATE_RETAILER_POS_LOADING_DONE,
    POS_ITEMS_LOADING,
    POS_ITEMS_LOADING_DONE,
    POS_ITEMS,
} from '../../actions';

const INIT_RETAILER_POS = {
    posItem: {
        id: '',
        name: '',
        description: '',
    },
    quantity: 1,
    retailerReference: '',
    tag: '',
    deleted: false,
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    retailerPosToCreate: { ...INIT_RETAILER_POS },
    itemList: [],
    createRetailerPosSuccess: false,
    itemsLoading: false,
};

const createRetailerPos = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_RETAILER_POS_LOADING:
            return { ...state, loading: true };
        case CREATE_RETAILER_POS_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case RETAILER_POS_INIT:
            return { ...INITIAL_STATE };
        case CREATE_RETAILER_POS_SELECT_ITEM:
            const tptc = { ...INIT_RETAILER_POS };
            tptc.posItem = action.item;
            return {
                ...state,
                retailerPosToCreate: { ...tptc },
                loading: false,
                processing: false,
                createRetailerPosSuccess: false,
            };
        case POS_ITEMS_LOADING:
            return { ...state, loadingItems: true };
        case POS_ITEMS:
            return {
                ...state,
                itemList: action.list,
            };
        case POS_ITEMS_LOADING_DONE:
            return { ...state, loadingItems: false };
        default:
            return state;
    }
};
export default createRetailerPos;
