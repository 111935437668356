import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { PrivilegesApi } from '../../../services/privileges-service';
import { UserApi } from '../../../services/user-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../../actions/notifications';
import _ from 'lodash';
import {
    RNP__SET_PERMISSIONS,
    RNP__SET_ROLES,
    RNP__SET_EDIT_PERMISSIONS,
    RNP__SET_ACTIVE_ROLES,
    RNP__LOADING,
    RNP__LOADED,
    RNP__SET_FILTER_PERMISSIONS,
} from './types';

export const PRIVILEGES_LIST = 'PRIVILEGES_LIST';
export const PRIVILEGES_LIST_LOADING = 'PRIVILEGES_LIST_LOADING';
export const PRIVILEGES_LIST_LOADING_DONE = 'PRIVILEGES_LIST_LOADING_DONE';

const privilegesApi = new PrivilegesApi();
const userApi = new UserApi();

export const rnpLoading = () => ({
    type: RNP__LOADING,
});

export const rnpLoaded = () => ({
    type: RNP__LOADED,
});

export const setRoles = (rolesList) => ({
    type: RNP__SET_ROLES,
    payload: rolesList,
});
export const setFileterPermissions = (permissionsList) => ({
    type: RNP__SET_FILTER_PERMISSIONS,
    payload: permissionsList,
});
export const setPermissions = (permissionsList) => ({
    type: RNP__SET_PERMISSIONS,
    payload: permissionsList,
});

export const setEditPermissions = (shouldEditPermissions) => ({
    type: RNP__SET_EDIT_PERMISSIONS,
    payload: shouldEditPermissions,
});

export const setActiveRoles = (rolesList) => ({
    type: RNP__SET_ACTIVE_ROLES,
    payload: rolesList,
});

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const initRolesAndPrivileges = () => async (dispatch, getState) => {
    let [fetchRolesSuccess, fetchPrivilegesSuccess] = await Promise.all([
        dispatch(getRoles()),
        dispatch(getPrivilegesList()),
    ]);
    !fetchRolesSuccess && error({ msg: 'Error fetching roles.' });
    if (fetchRolesSuccess) {
        const tempRoles = getState().privileges.tempRoles;
        let roles = _.cloneDeep(getState().privileges.rolesList);
        for (let i = 0; i < roles.length && i < 3; i++) {
            roles[i].display = true;
        }
        await dispatch(setRoles(tempRoles.length > 0 ? tempRoles : roles));
    }
    !fetchPrivilegesSuccess && error({ msg: 'Error fetching permissions' });
};

export const getRoles = () => async (dispatch) => {
    let success = false;
    dispatch(rnpLoading());
    try {
        let roles = await userApi.getUserRoles();
        _.forEach(roles, (role) => {
            _.set(role, 'display', false);
            _.set(role, 'isTouched', false);
        });

        dispatch({
            type: RNP__SET_ROLES,
            payload: _.sortBy(roles, ['id']),
        });
        success = true;
    } catch (e) {
        dispatch(error({ msg: getErrorMessage(e) }));
    } finally {
        dispatch(rnpLoaded());
    }
    return success;
};

export const getPrivilegesList = () => async (dispatch) => {
    let success = false;
    dispatch(rnpLoading());
    try {
        const privList = await privilegesApi.getPrivilegesList();
        dispatch({
            type: RNP__SET_PERMISSIONS,
            payload: privList,
        });
        success = true;
    } catch (e) {
        dispatch(createErrorHandlerError(e));
    } finally {
        dispatch(rnpLoaded());
    }
    return success;
};

export const filterPermissions = (filterQuery) => (dispatch, getState) => {
    dispatch(rnpLoading());
    const clonedPrivileges = _.cloneDeep(getState().privileges.privilegesList);

    let filteredPermissions = _.filter(clonedPrivileges, (permission) => {
        return _.includes(
            _.toLower(permission.displayName),
            _.toLower(filterQuery)
        );
    });

    if (!_.size(filteredPermissions)) {
        filteredPermissions = _.filter(clonedPrivileges, (permission) => {
            return _.includes(
                _.toLower(permission.displayName),
                _.toLower(filterQuery)
            );
        });
    }
    dispatch(setFileterPermissions(filteredPermissions));
    dispatch(rnpLoaded());
};

export const filterRoles = (filterQuery) => (dispatch, getState) => {
    dispatch(rnpLoading());
    let roleData = _.cloneDeep(getState().privileges.rolesList);
    _.forEach(roleData, (permission, i) => {
        const privileges = permission.privileges;
        const privilegeData = _.filter(privileges, (privilege) => {
            return _.includes(_.toLower(filterQuery));
        });
        if (_.size(privilegeData)) {
            roleData[i].privileges = privilegeData;
        } else {
            roleData[i] = [];
        }
    });
    _.remove(roleData, (s) => !_.size(s));
    dispatch(setRoles(roleData));
    dispatch(rnpLoaded());
};

export const addPermissionToRole =
    ({ permissionId, roleId }) =>
    (dispatch, getState) => {
        let permissions = _.cloneDeep(getState().privileges.privilegesList);
        let roles = _.cloneDeep(getState().privileges.rolesList);

        let permissionToAdd = _.find(permissions, ['id', permissionId]);
        let roleToModify = _.find(roles, ['id', roleId]);

        roleToModify.privileges.push(permissionToAdd);
        roleToModify.privileges = _.uniqBy(roleToModify.privileges, 'id');
        roleToModify.isTouched = true;

        dispatch(setRoles(_.sortBy(roles, ['id'])));
    };

export const removePermissionFromRole =
    ({ permissionId, roleId }) =>
    (dispatch, getState) => {
        let permissions = _.cloneDeep(getState().privileges.privilegesList);
        let roles = _.cloneDeep(getState().privileges.rolesList);

        let permissionToRemove = _.find(permissions, ['id', permissionId]);
        let roleToModify = _.find(roles, ['id', roleId]);
        roleToModify.isTouched = true;
        roleToModify.privileges = _.differenceBy(
            roleToModify.privileges,
            [permissionToRemove],
            'id'
        );

        dispatch(setRoles(_.sortBy(roles, ['id'])));
        // dispatch(setPermissions(_.sortBy(permissions, ['id'])));
    };

export const addPermissionGroupToRole =
    ({ groupId, roleId }) =>
    (dispatch, getState) => {
        let permissions = _.cloneDeep(
            _.filter(getState().privileges.privilegesList, (item) => {
                return _.startsWith(item.name, 'ROLE_RE_');
            })
        );
        let roles = _.cloneDeep(getState().privileges.rolesList);

        let roleToModify = _.find(roles, ['id', roleId]);
        let permissionsToAdd = _.filter(permissions, ['group.id', groupId]);

        roleToModify.privileges.push(...permissionsToAdd);
        roleToModify.privileges = _.uniqBy(roleToModify.privileges, 'id');
        roleToModify.isTouched = true;

        dispatch(setRoles(_.sortBy(roles, ['id'])));
    };

export const removePermissionGroupFromRole =
    ({ groupId, roleId }) =>
    (dispatch, getState) => {
        let permissions = _.cloneDeep(
            _.filter(getState().privileges.privilegesList, (item) => {
                return _.startsWith(item.name, 'ROLE_RE_');
            })
        );
        let roles = _.cloneDeep(getState().privileges.rolesList);

        let roleToModify = _.find(roles, ['id', roleId]);
        let permissionsToRemove = _.filter(permissions, ['group.id', groupId]);
        roleToModify.isTouched = true;
        roleToModify.privileges = _.differenceBy(
            roleToModify.privileges,
            permissionsToRemove,
            'id'
        );

        dispatch(setRoles(_.sortBy(roles, ['id'])));
    };

export const saveRolePermissions = (request) => async (dispatch) => {
    let success = false;
    dispatch(rnpLoading());
    try {
        await userApi.updateRolePermissions(request);
        success = true;
    } catch (e) {
        dispatch(error({ msg: getErrorMessage(e) }));
    } finally {
        dispatch(rnpLoaded());
    }
    return success;
};

export const displayRole = (id) => (dispatch, getState) => {
    let roles = _.cloneDeep(getState().privileges.rolesList);
    let roleToAdd = _.find(roles, ['id', id]);

    roleToAdd.display = true;
    const storedRoles = _.uniqBy(roles, 'id');
    dispatch(setActiveRoles(storedRoles));
    dispatch(setRoles(storedRoles));
};

export const hideRole = (id) => (dispatch, getState) => {
    let roles = _.cloneDeep(getState().privileges.rolesList);
    let roleToRemove = _.find(roles, ['id', id]);

    roleToRemove.display = false;
    const storedRoles = _.uniqBy(roles, 'id');
    dispatch(setActiveRoles(storedRoles));
    dispatch(setRoles(storedRoles));
};
