const defaultConfig = {
    PORT: 3000,
    ROUTE_URLS: {
        SPLASH_PAGE: '/',
        LOGIN_PAGE: '/login',
        DASHBOARD: '/dashboard',
        UNAUTHORIZED: '/unauthorized',
        NOT_FOUND: '/not-found',
        CONFIGURATION_MANAGEMENT: '/management-configuration',
        UPDATE_CONFIGURATION_MANAGEMENT: '/management-configurations/update',
        PERMISSIONS_CONFIGURATION_MANAGEMENT: '/permissions-management',
        CREATE_ROLE: '/permission-management/createRole',
        CONTACT_US_CONFIGURATION: '/contact-us-configuration',
        CONTACT_US_PAGE: '/contact-us',
        CREATE_USER: '/user/create',
        UPDATE_USER: '/user/update',
        USER_MANAGEMENT: '/user',
        GAMES: '/games',
        CHECKOUT: '/checkout',
        ORDER: '/order',
        ORDER_TAB_PAST: '/past-orders',
        ORDER_TAB_PROGRESS: '/in-progress',
        INVOICE: '/invoice',
        REPORTS: '/reports',
        FAQ_MANAGEMENT_PRIVATE: '/faqs/edit-private',
        FAQ_MANAGEMENT_PUBLIC: '/faqs/edit-public',
        FAQ_PAGE: '/faq',
        PRIVACY_POLICY_MANAGEMENT: '/privacy-policy/edit-policy',
        NEW_RETAILER_POLICY: '/new-retailer-policy/edit',
        PRIVACY_POLICY_PAGE: '/policy',
        UPLOAD_MANAGEMENT: '/uploads/file-upload',
        MESSAGE_MANAGEMENT: '/uploads/AddEditMessages',
        DASHBOARD_SETTINGS: '/dashboard-settings',
        VIRTUAL_TOUR_CONFIGURATION: '/virtual-tour-configuration',
        GETTING_STARTED: '/getting-started',
        GAMES_LIST: '/games-list',
        RETAILER_GROUP_LIST: '/group-list',
        RETAILER_GROUP_UPDATE: '/group-list',
        RETAILER_GROUP_CREATE: '/group-list/create',
        COURIER_LIST: '/courier',
        COURIER_UPDATE: '/courier',
        COURIER_CREATE: '/courier/create',
        PLANOGRAM_LIST: '/planogram',
        PLANOGRAM_UPDATE: '/planogram',
        PLANOGRAM_CREATE: '/planogram/create',
        RETAILER_PLANOGRAM_LIST: '/planograms',
        RETAILER_RETURN_LIST: '/returns',
        RETAILER_RETURN_CREATE: '/returns/create',
    },
    COGNITO_PROVIDERS: 'PBCORP',
    COGNITO_ENABLED: false,
    AWS_REGION: 'ca-central-1',
    COGNITO_USER_POOL_ID: undefined,
    COGNITO_IDENTITY_POOL_ID: undefined,
    COGNITO_IDP_NAME: undefined,
    COGNITO_CLIENT_ID: undefined,
    COGNITO_CLIENT_DOMAIN_NAME: undefined,
    PLANOGRAMS_WIDGET_UPCOMING_PAGE_SIZE: 2,
    PLANOGRAMS_WIDGET_CURRENT_PAGE_SIZE: 3,
    COGNITO_SIGNIN_URL: 'http://localhost:3000/',
    COGNITO_SIGNOUT_URL: 'http://localhost:3000/',
    COGNITO_OAUTH_CLAIMS: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
    ],
    COGNITO_GRANT_FLOW: 'code',

    INPUT_MAX_LENGTH: 255,
    AUTH_TIMEOUT: process.env.REACT_APP_AUTH_TIMEOUT || 5, //in minutes
    ANIMATION_LENGTH: 1000, //in milliseconds
    DISPLAY_DATE_FORMAT: 'MMM DD, YYYY',
    DATE_FORMAT_FOR_SERVER: 'YYYY-MM-DD',
    DISPLAY_DATE_TIME_FORMAT: 'MM-DD-YYYY HH:mm:ss',
    DATE_TIME_FORMAT_FOR_SERVER: 'YYYY-MM-DD HH:mm:ss',
    DATE_TIME_FORMAT_FOR_SERVER_VERSION: 'MMM DD, YYYY, HH:mm:ss a',
    NO_OF_DAYS_FOR_UPCOMING_GAMES: '30',
};

const config = {
    ...defaultConfig,
    ...window._env_,
};

export default config;
