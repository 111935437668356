import { find } from 'lodash';
import LottoContactInfoApi from '../../../services/lotto-contact-info-service';
import { UserApi } from '../../../services/user-service';
import { getErrorMessage } from '../../../constants/utils';
import contactus from '../../../constants/contact-us';

export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_LOADING_DONE = 'FORGOT_PASSWORD_LOADING_DONE';
export const FORGOT_PASSWORD_INIT = 'FORGOT_PASSWORD_INIT';
export const FORGOT_PASSWORD_CONTACT_INFO = 'FORGOT_PASSWORD_CONTACT_INFO';
export const FORGOT_PASSWORD_SHOW_NOTIFY = 'FORGOT_PASSWORD_SHOW_NOTIFY';
export const FORGOT_PASSWORD_HIDE_NOTIFY = 'FORGOT_PASSWORD_HIDE_NOTIFY';

const lottoContactInfoApi = new LottoContactInfoApi();
const userApi = new UserApi();
const { PHONE_1, PHONE_2, EMAIL } = contactus;

export const sendResetPasswordEmail = (retailerReference) => {
    return async (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD_LOADING });

        try {
            dispatch({ type: FORGOT_PASSWORD_HIDE_NOTIFY });

            await userApi.resetPasswordForRetailer(retailerReference);

            dispatch({
                type: FORGOT_PASSWORD_SHOW_NOTIFY,
                payload: { msg: 'Successfully sent email', isError: false },
            });
        } catch (err) {
            console.error(err);
            dispatch({
                type: FORGOT_PASSWORD_SHOW_NOTIFY,
                payload: { msg: getErrorMessage(err), isError: true },
            });
        }

        dispatch({ type: FORGOT_PASSWORD_LOADING_DONE });
    };
};

export const getContactData = () => {
    return async (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD_LOADING });

        try {
            const contactInfo =
                await lottoContactInfoApi.getPublicContactUsData();
            const email = find(contactInfo, { name: EMAIL });
            const phone1 = find(contactInfo, { name: PHONE_1 });
            const phone2 = find(contactInfo, { name: PHONE_2 });

            let phoneList = [];

            if (phone1) {
                phoneList.push(phone1.value);
            }

            if (phone2) {
                phoneList.push(phone2.value);
            }

            const payload = {
                email: email ? email.value : null,
                phone: phoneList.join(' or '),
            };

            dispatch({ type: FORGOT_PASSWORD_CONTACT_INFO, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: FORGOT_PASSWORD_LOADING_DONE });
    };
};

export const initForgotPassword = () => ({
    type: FORGOT_PASSWORD_INIT,
});

export const hideForgotPasswordNotifications = () => ({
    type: FORGOT_PASSWORD_HIDE_NOTIFY,
});
