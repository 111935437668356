export const TABLE_SEARCH_CHANGE = 'TABLE_SEARCH_CHANGE';
export const TABLE_SEARCH_BUTTON_TOGGLE = 'TABLE_SEARCH_BUTTON_TOGGLE';
export const TABLE_SEARCH_CLEAR = 'TABLE_SEARCH_CLEAR';
export const TABLE_SEARCH_PREVIOUS_VALUE = 'TABLE_SEARCH_PREVIOUS_VALUE';

export const TABLE_SORT = 'TABLE_SORT';
export const TABLE_MOUSE_OVER = 'TABLE_MOUSE_OVER';
export const TABLE_MOUSE_OUT = 'TABLE_MOUSE_OUT';
export const TABLE_SORT_CLEAR = 'TABLE_SORT_CLEAR';

export const TABLE_SELECT_ROW = 'TABLE_SELECT_ROW';
export const TABLE_SELECT_ALL_ROW = 'TABLE_SELECT_ALL_ROW';
export const TABLE_SELECT_CLEAR = 'TABLE_SELECT_CLEAR';

export const TABLE_SAVE_RETAILERS_AS_PREV = 'TABLE_SAVE_RETAILERS_AS_PREV';
export const TABLE_RESTORE_PREV_RETAILERS = 'TABLE_RESTORE_PREV_RETAILERS';

export const TABLE_INIT_RETAILERS = 'TABLE_INIT_RETAILERS';

export const searchValueChange = (value) => {
    return (dispatch) => {
        dispatch({
            type: TABLE_SEARCH_CHANGE,
            value,
        });
        dispatch(searchButtonToggle(value && value.trim().length > 0));
    };
};

export const searchButtonToggle = (toggle) => ({
    type: TABLE_SEARCH_BUTTON_TOGGLE,
    toggle,
});

export const prevSearchValue = (value) => ({
    type: TABLE_SEARCH_PREVIOUS_VALUE,
    value,
});

export const clearSearch = () => ({
    type: TABLE_SEARCH_CLEAR,
});

export const updateSortDetails = (args) => ({
    type: TABLE_SORT,
    args,
});

export const tableMouseOver = (args) => ({
    type: TABLE_MOUSE_OVER,
    args,
});

export const tableMouseOut = () => ({
    type: TABLE_MOUSE_OUT,
});

export const clearSortDetails = () => ({
    type: TABLE_SORT_CLEAR,
});

export const selectTableRow = (id, item) => ({
    type: TABLE_SELECT_ROW,
    id,
    item,
});

export const selectAllTableRow = (contentKey, items, allChecked) => ({
    type: TABLE_SELECT_ALL_ROW,
    contentKey,
    items,
    allChecked,
});

export const clearAllTableSelection = () => ({
    type: TABLE_SELECT_CLEAR,
});

export const saveSelectedRetailersAsPrev = () => ({
    type: TABLE_SAVE_RETAILERS_AS_PREV,
});

export const restorePrevSelectedRetailers = () => ({
    type: TABLE_RESTORE_PREV_RETAILERS,
});

export const initSelectedRetailers = (retailers) => ({
    type: TABLE_INIT_RETAILERS,
    retailers,
});
