import LottoContactInfoApi from '../../../services/lotto-contact-info-service';
import { find } from 'lodash';
import contactus from '../../../constants/contact-us';

export const PRIVATE_CONTACT_LOADING = 'PRIVATE_CONTACT_LOADING';
export const PRIVATE_CONTACT_LOADING_DONE = 'PRIVATE_CONTACT_LOADING_DONE';
export const PRIVATE_CONTACT_INIT = 'PRIVATE_CONTACT_INIT';
export const PRIVATE_CONTACT_GET = 'PRIVATE_CONTACT_GET';

const lottoContactInfoApi = new LottoContactInfoApi();
const { PHONE_1, PHONE_2, EMAIL, FAX, MAIL_ADDRESS } = contactus;

export const getPrivateContactUsData = () => {
    return async (dispatch) => {
        dispatch({ type: PRIVATE_CONTACT_LOADING });

        try {
            const contactInfo = await lottoContactInfoApi.getAllContactUsData();
            const email = find(contactInfo, { name: EMAIL });
            const phone1 = find(contactInfo, { name: PHONE_1 });
            const phone2 = find(contactInfo, { name: PHONE_2 });
            const mailAddress = find(contactInfo, { name: MAIL_ADDRESS });
            const fax = find(contactInfo, { name: FAX });

            let phoneList = [];

            if (phone1) {
                phoneList.push(phone1.value);
            }

            if (phone2) {
                phoneList.push(phone2.value);
            }

            const payload = [
                {
                    label: 'Mail',
                    value: mailAddress ? mailAddress.value : null,
                },
                { label: 'Phone', value: phoneList.join(' or ') },
                { label: 'Fax', value: fax ? fax.value : null },
                { label: 'E-mail', value: email ? email.value : null },
            ];
            dispatch({ type: PRIVATE_CONTACT_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PRIVATE_CONTACT_LOADING_DONE });
    };
};

export const initPrivateContact = () => ({
    type: PRIVATE_CONTACT_INIT,
});
