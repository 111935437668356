import OrderApi from '../../../services/order-service';
import { OrganizationApi } from '../../../services/organization-service';

export const CHECKOUT_LOADING = 'CHECKOUT_LOADING';
export const CHECKOUT_LOADING_DONE = 'CHECKOUT_LOADING_DONE';
export const CHECKOUT_INIT = 'CHECKOUT_INIT';
export const CHECKOUT_SAVE = 'CHECKOUT_SAVE';
export const INCLUDE_SAMPLE_GAME_SAVE = 'INCLUDE_SAMPLE_GAME_SAVE';

const orderApi = new OrderApi();
const organizationApi = new OrganizationApi();
export const getLastOrder = () => {
    return async (dispatch, getState) => {
        dispatch({ type: CHECKOUT_LOADING });

        try {
            const {
                auth: { info },
            } = getState();

            const payload = await orderApi.getLastOrder(info.retailerReference);
            dispatch({ type: CHECKOUT_SAVE, payload });
            dispatch({ type: CHECKOUT_LOADING_DONE });
        } catch (err) {
            console.error(err);
            dispatch({ type: CHECKOUT_LOADING_DONE });
        }
    };
};

export const getIncludeSampleGames = () => {
    return async (dispatch) => {
        try {
            const payload = await organizationApi.getIncludeSampleGames();

            dispatch({ type: INCLUDE_SAMPLE_GAME_SAVE, payload });
            dispatch({ type: CHECKOUT_LOADING_DONE });
        } catch (err) {
            console.error(err);
            dispatch({ type: CHECKOUT_LOADING_DONE });
        }
    };
};
export const initCheckout = () => ({
    type: CHECKOUT_INIT,
});
