import { Backend } from './backend';
import type { Courier } from '../DTOs/courier';
import type { SearchSpecificationModel } from '../interface/search-specification-model';

class CourierService {
    constructor() {
        this.path = '/retailers/courier';
    }

    update = async (id: number, courier: Courier) => {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${id}`,
                body: courier,
            });
        } catch (e) {
            throw e;
        }
    };

    create = async (courier: Courier) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}`,
                body: courier,
            });
        } catch (e) {
            throw e;
        }
    };
    getBySearchSpecificationModel = async (ssf: SearchSpecificationModel) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: ssf,
            });
        } catch (e) {
            throw e;
        }
    };
    getById = async (id: number) => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    };

    get = async () => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/`,
            });
        } catch (e) {
            throw e;
        }
    };
}

export default CourierService;
