import {
    INIT_UPDATE_TASK,
    FETCH_TASK_OPTIONS,
    GET_TASK_TO_UPDATE_SUCCESS,
    UPDATE_TASK_VALIDATE,
    UPDATE_TASK_PROCESSING,
    UPDATE_TASK_LOADING,
    UPDATE_TASK_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    taskToUpdate: {
        title: '',
        description: '',
        category: '',
        priority: '',
        taskSchedule: {
            recurrenceType: '',
            dueDate: '',
            startDate: '',
            endDate: '',
        },
        status: '',
        taskAssignments: [],
        includePlanogram: false,
        hotTopic: false,
    },
    errors: [],
    processing: false,
    loading: false,
    taskOptions: {
        taskCategories: [],
        assignees: [],
        schedules: [],
        priorities: [],
        statuses: [],
    },
};

const updateTask = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_TASK_LOADING:
            return { ...state, loading: true };
        case UPDATE_TASK_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case INIT_UPDATE_TASK:
            return { ...INITIAL_STATE, processing: false };
        case UPDATE_TASK_PROCESSING:
            return { ...state, processing: true };
        case FETCH_TASK_OPTIONS:
            return {
                ...state,
                taskOptions: { ...action.taskOptions },
                processing: false,
            };
        case GET_TASK_TO_UPDATE_SUCCESS:
            return {
                ...state,
                taskToUpdate: { ...action.taskToUpdate },
                processing: false,
            };
        case UPDATE_TASK_VALIDATE:
            return { ...state, errors: action.validateTaskErrors };
        default:
            return state;
    }
};
export default updateTask;
