import _ from 'lodash';

import { getAttribute } from '../../../constants/utils';
import {
    TABLE_SELECT_ROW,
    TABLE_SELECT_ALL_ROW,
    TABLE_SELECT_CLEAR,
    TABLE_SAVE_RETAILERS_AS_PREV,
    TABLE_RESTORE_PREV_RETAILERS,
    TABLE_INIT_RETAILERS,
} from '../actions';

const INITIAL_STATE = {
    itemsSelected: {},
    prevSelected: {},
};

export const tableSelect = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TABLE_SELECT_ROW: {
            const id = action.id;
            const item = action.item;

            let newItems;
            if (state.itemsSelected[id]) {
                newItems = _.pickBy(
                    state.itemsSelected,
                    (item, keyValue) => keyValue !== id.toString()
                ); //remove item if it exists. key is always a string
            } else {
                newItems = { ...state.itemsSelected, [id]: item };
            }

            return { ...state, itemsSelected: newItems };
        }
        case TABLE_SELECT_ALL_ROW: {
            const key = action.contentKey;
            const items = action.items;
            const allChecked = action.allChecked;

            let currentItems = state.itemsSelected;
            let newItems;

            if (allChecked) {
                const keys = _.map(items, (i) =>
                    getAttribute(i, key).toString()
                );
                newItems = _.pickBy(
                    currentItems,
                    (item, keyValue) => !_.includes(keys, keyValue)
                );
            } else {
                newItems = { ...currentItems };
                _.each(items, (item) => {
                    const keyValue = getAttribute(item, key);
                    newItems[keyValue] = item;
                });
            }

            return { ...state, itemsSelected: { ...newItems } };
        }
        case TABLE_SAVE_RETAILERS_AS_PREV:
            return { ...state, prevSelected: { ...state.itemsSelected } };
        case TABLE_RESTORE_PREV_RETAILERS:
            return { ...state, itemsSelected: { ...state.prevSelected } };
        case TABLE_SELECT_CLEAR:
            return { ...INITIAL_STATE };
        case TABLE_INIT_RETAILERS:
            let retailersToAdd = {};
            for (let ind in action.retailers) {
                const retailer = action.retailers[ind];
                const id = retailer.id;

                retailersToAdd = { ...retailersToAdd, [id]: retailer };
            }
            return { ...INITIAL_STATE, itemsSelected: { ...retailersToAdd } };
        default:
            return state;
    }
};
