import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { map, max, truncate, sortBy } from 'lodash';
import Widget from '../Widget';
import { formatMoney, formatDate } from '../../../../constants/utils';
import {
    getTopGamesByPricePoint,
    fetchReportList,
    pushReferrer,
} from '../../../../redux/actions';
import subMonths from 'date-fns/subMonths';
import config from '../../../../constants/config';
import NoItems from '../../../../components/NoItems';

import './styles.css';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';

export class TopGamesByPricePointWidget extends Component {
    async componentDidMount() {
        await this.init();
    }

    init = async () => {
        const { getTopGamesByPricePoint, retailerInfo } = this.props;

        const startDate = subMonths(moment().toDate(), 1);
        await getTopGamesByPricePoint(
            retailerInfo?.reference,
            retailerInfo?.jurisdiction?.code,
            formatDate(startDate, config.DATE_FORMAT_FOR_SERVER),
            formatDate(moment(), config.DATE_FORMAT_FOR_SERVER)
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    renderWidgetBar = (max, value) => {
        return (
            <div
                className="total-percentage-bar text-right"
                style={{ width: `${(value / max) * 100}%`, minWidth: '7%' }}
            >
                <div className="bar-text">
                    <span>{formatMoney(value)}</span>
                </div>
            </div>
        );
    };

    render() {
        const { loading, topGamesByPricePoint, errorResponse } = this.props;
        const noContent =
            !topGamesByPricePoint && topGamesByPricePoint?.length > 0;
        const sortedData = sortBy(topGamesByPricePoint, 'totalSales').reverse();
        let maxTotal = 0;

        if (!noContent) {
            maxTotal = max(map(topGamesByPricePoint, 'totalSales'));
        }

        return (
            <Widget loading={loading} data-test="topSelligWidget">
                <div className="bold widget-heading">
                    Top Selling Games - In last 30 Days:
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!topGamesByPricePoint && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : sortedData && sortedData.length > 0 ? (
                    <div
                        className="top-selling-games-widget-content widget-container-height"
                        data-test="withData"
                    >
                        <table>
                            <tbody>
                                {map(sortedData, (game) => (
                                    <tr
                                        className="task-completion-bar"
                                        key={game.pricePoint}
                                    >
                                        <td>
                                            <div className="game-info">
                                                <div className="bold price-point">
                                                    {formatMoney(
                                                        game.pricePoint
                                                    )}
                                                </div>
                                                <div className="name">
                                                    {truncate(
                                                        `${game.gameReference} - ${game.gameName}`,
                                                        { length: 30 }
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                {this.renderWidgetBar(
                                                    maxTotal,
                                                    game.totalSales
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no top games!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({ topGames, userInfo: { retailerInfo } }) => {
    const { loading, topGamesByPricePoint, errorResponse } = topGames;
    return { topGamesByPricePoint, loading, retailerInfo, errorResponse };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
        fetchReportList: () => dispatch(fetchReportList()),
        getTopGamesByPricePoint: (
            retailerReference,
            jurisdictionCode,
            startDate,
            endDate
        ) =>
            dispatch(
                getTopGamesByPricePoint(
                    retailerReference,
                    jurisdictionCode,
                    startDate,
                    endDate
                )
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopGamesByPricePointWidget);
