import { Planogram } from '../../../DTOs/planogram';
import type { Page } from '../../../interface/page';

export const GET_PLANOGRAMS = 'Planogram/Get';
export const GET_PLANOGRAM_SEARCH = 'Planogram/Search';

export const UPDATE_LOADING_STATE = 'Planogram/loading';

export interface PlanogramStateType {
    isLoading: boolean;
    planogramList: Planogram[];
    planogramPage: Page<Planogram>;
}

interface GetPlanogramType {
    type: typeof GET_PLANOGRAMS;
    payload: Planogram[];
}

interface GetPlanogramTypeBySearch {
    type: typeof GET_PLANOGRAM_SEARCH;
    payload: Page<Planogram>;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type PlanogramActionTypes =
    | GetPlanogramType
    | UpdateLoadingState
    | GetPlanogramTypeBySearch;
