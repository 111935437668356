import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const COMPLIANCE_UPDATE_SUCCESS = 'COMPLIANCE_UPDATE_SUCCESS';
export const COMPLIANCE_GET_SUCCESS = 'COMPLIANCE_GET_SUCCESS';
export const COMPLIANCE_UPDATE_INIT = 'COMPLIANCE_UPDATE_INIT';
export const COMPLIANCE_UPDATE_PAGE_LOADING = 'COMPLIANCE_UPDATE_PAGE_LOADING';
export const COMPLIANCE_UPDATE_PAGE_DONE = 'COMPLIANCE_UPDATE_PAGE_DONE';

const surveyApi = new SurveyApi();

const updateComplianceError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'UpdateComplianceTemplate',
            })
        );
    };
};

export const getComplianceTemplate = (surveyId) => {
    return async (dispatch) => {
        try {
            //get the data
            const surveyData = await surveyApi.getSurvey(surveyId);
            const questionData = await surveyApi.getQuestionsForSurvey(
                surveyId
            );

            //compliance always has ONLY 1 question
            const firstQuestionEl = questionData[0];

            dispatch({
                type: COMPLIANCE_GET_SUCCESS,
                complianceObject: {
                    id: surveyId,
                    name: surveyData.name,
                    description: surveyData.description,
                    question: firstQuestionEl.text,
                    questionType: firstQuestionEl.questionType,
                    questionId: firstQuestionEl.id,
                    status: surveyData.status,
                    originalStatus: surveyData.status,
                },
            });
        } catch (e) {
            dispatch(updateComplianceError(e));
        }
    };
};

export const updateComplianceTemplate = (complianceSurveyToUpdate) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: COMPLIANCE_UPDATE_PAGE_LOADING });

            //must update questions first in case survey status changes
            //if original status is draft do the update only
            if (complianceSurveyToUpdate.originalStatus === 'DRAFT') {
                const questionPayload = {
                    text: complianceSurveyToUpdate.question,
                    questionType: complianceSurveyToUpdate.questionType,
                    id: complianceSurveyToUpdate.questionId,
                };

                await surveyApi.updateSurveyQuestions(questionPayload);
            }

            const surveyPayload = {
                id: complianceSurveyToUpdate.id,
                name: complianceSurveyToUpdate.name,
                description: complianceSurveyToUpdate.description,
                status: complianceSurveyToUpdate.status,
            };

            await surveyApi.updateSurvey(surveyPayload);
            dispatch({
                type: COMPLIANCE_UPDATE_INIT,
            });
        } catch (err) {
            console.error(err);
            dispatch(updateComplianceError(err));
            success = false;
        } finally {
            dispatch({ type: COMPLIANCE_UPDATE_PAGE_DONE });
        }

        return success;
    };
};

export const initUpdateComplianceTemplate = () => {
    return async (dispatch) => {
        dispatch({ type: COMPLIANCE_UPDATE_INIT });
    };
};
