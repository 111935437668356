import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';

import Page from '../layout/Page';
import './SfaForm.css';
import SfaTransition from './SfaTransition';
import FormFooter from './formFooter/FormFooter';
import config from '../constants/config';
import { setIsFormDirty } from '../redux/actions';
import Secured from './security/Secured';
import { userHasPermission } from '../constants/utils';

export class SfaForm extends Component {
    componentDidUpdate(prevProps) {
        const { submitIsDisabled, formDirty, setIsFormDirty } = this.props;
        if (!formDirty && prevProps.submitIsDisabled !== submitIsDisabled) {
            setIsFormDirty(true);
        } else if (formDirty && submitIsDisabled) {
            setIsFormDirty(false);
        }
    }

    componentWillUnmount() {
        const { setIsFormDirty } = this.props;
        setIsFormDirty(false);
    }

    render() {
        const {
            name,
            onSubmit,
            headerText,
            onCancel,
            onEdit,
            onDelete,
            showSaveCancel,
            showEdit,
            showDelete,
            showExport,
            onExport,
            processing,
            submitIsDisabled,
            editIsDisabled,
            editButtonText,
            editButtonRoles,
            deleteButtonText,
            deleteActionButtonText,
            deleteButtonClass,
            deleteActionButtonClass,
            submitButtonText,
            cancelButtonText,
            loading,
            children,
            deleteAction,
            extraElement,
            animation,
            deleteButtonRoles,
        } = this.props;
        const formJSX = (
            <Page
                title={headerText}
                name={name}
                className="word-wrap-with-word-break"
            >
                <div className="main-form" data-test="sfaForm">
                    <Form
                        autoComplete="off"
                        className="vertical-form sign-in"
                        loading={loading || processing}
                    >
                        <div className="sfaform-padding">{children}</div>
                        {showSaveCancel && (
                            <FormFooter data-test="showSaveCancel">
                                {extraElement && (
                                    <Segment
                                        as={Grid.Column}
                                        width={6}
                                        floated="left"
                                        textAlign="left"
                                        className="max-mobile"
                                    >
                                        {extraElement}
                                    </Segment>
                                )}

                                <Grid.Column
                                    className="nopadding"
                                    width={extraElement ? 10 : 16}
                                    floated="right"
                                    verticalAlign="bottom"
                                    textAlign="right"
                                >
                                    <Button
                                        type="button"
                                        primary
                                        disabled={submitIsDisabled}
                                        onClick={onSubmit}
                                    >
                                        {submitButtonText || 'Save'}
                                    </Button>
                                    <Button
                                        type="button"
                                        className="transparent-btn"
                                        onClick={onCancel}
                                        disabled={
                                            cancelButtonText && submitIsDisabled
                                        }
                                    >
                                        {cancelButtonText || 'Cancel'}
                                    </Button>
                                    {deleteAction &&
                                        userHasPermission(
                                            deleteButtonRoles
                                        ) && (
                                            <Button
                                                className={
                                                    deleteActionButtonClass ||
                                                    'transparent-btn black-text secondary-color delete-btn'
                                                }
                                                onClick={deleteAction}
                                            >
                                                {deleteActionButtonText ||
                                                    'Delete'}
                                            </Button>
                                        )}
                                    {extraElement && (
                                        <div className="max-mobile mobile only">
                                            {extraElement}
                                        </div>
                                    )}
                                </Grid.Column>
                            </FormFooter>
                        )}
                        {(showEdit || showDelete || showExport) && (
                            <FormFooter>
                                <Grid.Column
                                    className="nopadding"
                                    floated="right"
                                    verticalAlign="bottom"
                                    textAlign="right"
                                >
                                    {showEdit && (
                                        <Secured hasAnyRole={editButtonRoles}>
                                            {
                                                <Button
                                                    type="button"
                                                    primary
                                                    disabled={editIsDisabled}
                                                    onClick={onEdit}
                                                >
                                                    {editButtonText || 'Edit'}
                                                </Button>
                                            }
                                        </Secured>
                                    )}
                                    {showDelete && (
                                        <Secured hasAnyRole={deleteButtonRoles}>
                                            {
                                                <Button
                                                    type="button"
                                                    className={
                                                        deleteButtonClass ||
                                                        'transparent-btn'
                                                    }
                                                    onClick={onDelete}
                                                >
                                                    {deleteButtonText ||
                                                        'Delete'}
                                                </Button>
                                            }
                                        </Secured>
                                    )}
                                    {showExport && (
                                        <Button
                                            type="button"
                                            className="transparent-btn black-text secondary-color"
                                            onClick={onExport}
                                        >
                                            Export Results
                                        </Button>
                                    )}
                                </Grid.Column>
                            </FormFooter>
                        )}
                    </Form>
                </div>
            </Page>
        );

        return animation ? (
            <SfaTransition
                animation={animation}
                duration={config.ANIMATION_LENGTH}
            >
                {formJSX}
            </SfaTransition>
        ) : (
            formJSX
        );
    }
}

const mapStateToProps = ({ formsMetadata }) => ({
    formDirty: formsMetadata.isFormDirty,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SfaForm)
);
