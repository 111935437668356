import _ from 'lodash';

import { ReportsApi } from '../../../services/reports-service';
import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const REPORTS_LIST_LOADING = 'REPORTS_LIST_LOADING';
export const REPORTS_LIST = 'REPORTS_LIST';
export const REPORTS_LIST_ERROR = 'REPORTS_LIST_ERROR';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const reportsApi = new ReportsApi();

export const fetchReportList = (handleError) => {
    return async (dispatch) => {
        dispatch({ type: REPORTS_LIST_LOADING });

        try {
            const data = await reportsApi.getAllReports();
            const content = _.chain(data)
                .groupBy('groupName')
                .toPairs()
                .map((currentItem) => ({
                    [currentItem[0]]: _.sortBy(currentItem[1], ['sortOrder']),
                }))
                .value();

            dispatch({
                type: REPORTS_LIST,
                content,
            });
        } catch (e) {
            dispatch({ type: REPORTS_LIST_ERROR });
            dispatch(error({ msg: getErrorMessage(e) }));
            console.error(e);
        }
    };
};

export const searchReportList = (args) => {
    return async (dispatch) => {
        dispatch({ type: REPORTS_LIST_LOADING });

        try {
            const data = await reportsApi.searchAllReports(args);
            const content = _.chain(data.content)
                .groupBy('groupName')
                .toPairs()
                .map((currentItem) => ({
                    [currentItem[0]]: _.sortBy(currentItem[1], ['sortOrder']),
                }))
                .value();

            dispatch({
                type: REPORTS_LIST,
                content,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};
