import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'react-intl-universal';
import _ from 'lodash';
import { Loader } from 'semantic-ui-react';

import {
    getLogoKey,
    loadLocalizedResources,
    loadSupportedLocales,
    setAppIsReady,
    setupLogo,
    setupOrganizationInfo,
    setupReportLogo,
} from '../redux/actions';
import SfaBranding from '../components/branding/SfaBranding';

class SplashPage extends Component {
    async componentWillMount() {
        await this.props.loadSupportedLocales();
        await this.loadLocales();
    }

    loadLocales = async () => {
        let currentLocale = i18n.determineLocale({
            urlLocaleKey: 'lang',
            cookieLocaleKey: 'lang',
        });

        //remove region code from locale
        currentLocale = currentLocale
            ? currentLocale.toLowerCase().split(/[_-]+/)[0]
            : currentLocale;

        if (
            !currentLocale ||
            !_.find(this.props.supportedLocales, { lang: currentLocale })
        ) {
            currentLocale = this.props.defaultLanguage || 'en';
        }

        await this.props.loadLocalizedResources(currentLocale);
    };

    render() {
        return (
            <div style={style.splashDiv}>
                <SfaBranding disableLoader />
                <Loader
                    active
                    key="loader"
                    size="medium"
                    inline="centered"
                    inverted
                />
            </div>
        );
    }
}

const style = {
    loading: {
        fill: 'rgb(255, 255, 255)',
        height: '64px',
        width: '64px',
        margin: 'auto',
    },
    splashDiv: {
        padding: '60px 0',
    },
};

const mapStateToProps = ({ i18n, organization }) => ({
    ...i18n,
    ...organization,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setAppIsReady: (args) => dispatch(setAppIsReady(args)),
        setupOrganizationInfo: () => dispatch(setupOrganizationInfo()),
        setupLogo: () => dispatch(setupLogo()),
        fetchReportLogo: () => dispatch(setupReportLogo()),
        fetchLogoKey: () => dispatch(getLogoKey()),
        loadSupportedLocales: () => dispatch(loadSupportedLocales()),
        loadLocalizedResources: (locale) =>
            dispatch(loadLocalizedResources(locale)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashPage);
