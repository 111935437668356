import {
    OPTIMIZED_ROUTE_LOADING,
    OPTIMIZED_ROUTE_SUCCESS,
    OPTIMIZED_ROUTE_DONE,
    ROUTE_MODAL_MAPPING_DONE,
    ROUTE_MODAL_MAPPING_LOADING,
    ROUTE_MODAL_MAPPING_SUCCESS,
    ROUTE_MODAL_MAPPING_VALUE_UPDATE,
    LAST_OPTIMIZED_DONE,
    LAST_OPTIMIZED_LOADING,
    LAST_OPTIMIZED_SUCCESS,
    ROUTE_BUTTONS_ENABLED,
} from '../../actions';

const INITIAL_STATE = {
    processing: false,
    enabled: true,
    routeMapping: {
        optimized: null,
        routing: null,
        lastOptimized: null,
    },
    prop: null,
    value: null,
    currentLocation: null,
};

const routeMapping = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROUTE_BUTTONS_ENABLED:
            return { ...state, buttonsEnabled: action.buttonsEnabled };
        case OPTIMIZED_ROUTE_LOADING:
            return { ...state, processing: true };
        case OPTIMIZED_ROUTE_SUCCESS:
            return { ...state, optimized: action.optimized };
        case OPTIMIZED_ROUTE_DONE:
            return { ...state, processing: false };
        case ROUTE_MODAL_MAPPING_DONE:
            return { ...state, processing: false };
        case ROUTE_MODAL_MAPPING_SUCCESS:
            return { ...state, routing: action.routing };
        case ROUTE_MODAL_MAPPING_LOADING:
            return { ...state, processing: true };
        case ROUTE_MODAL_MAPPING_VALUE_UPDATE:
            return { ...state, [action.prop]: action.value };
        case LAST_OPTIMIZED_DONE:
            return { ...state, processing: false };
        case LAST_OPTIMIZED_LOADING:
            return {
                ...state,
                lastOptimized: action.lastOptimized,
                processing: true,
            };
        case LAST_OPTIMIZED_SUCCESS:
            return { ...state, lastOptimized: action.lastOptimized };
        default:
            return state;
    }
};
export default routeMapping;
