import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { offline } from 'redux-offline';
import defaultOfflineConfig from 'redux-offline/lib/defaults';
import { routerMiddleware } from 'react-router-redux';

import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const history = require('history').createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)];

const offlineConfig = {
    ...defaultOfflineConfig,
    persistOptions: {
        whitelist: ['auth', 'organization', 'nav', 'userInfo', 'userRetailer'],
    },
};

const enhancer = composeWithDevTools(
    applyMiddleware(...middleware),
    offline(offlineConfig),
    (createStore) => (reducer, preloadedState, enhancer) =>
        enhancer(createStore)(reducer, preloadedState)
);

const store = createStore(reducers, enhancer);

const reduxStore = {
    store,
    history,
};
export default reduxStore;
