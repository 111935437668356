import {
    PRIVACY_POLICY_LOADING,
    PRIVACY_POLICY_LOADING_DONE,
    PRIVACY_POLICY_INIT,
    PRIVACY_POLICY_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    html: null,
};

const privacyPolicy = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PRIVACY_POLICY_GET:
            return {
                ...state,
                html: payload,
            };
        case PRIVACY_POLICY_INIT:
            return { ...INITIAL_STATE };

        case PRIVACY_POLICY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PRIVACY_POLICY_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default privacyPolicy;
