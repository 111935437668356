import {
    PRIZE_WINNINGS_INIT,
    PRIZE_WINNINGS_LOADED,
    PRIZE_WINNINGS_LOADING,
    PRIZE_WINNINGS_DASHBOARD_LOADING,
    PRIZE_WINNINGS_DASHBOARD_LOADED,
} from '../actions/prizeWinnings';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
    dashBoardLoading: false,
    dashBoardLoaded: false,
    highTierPrizeThreshold: 0,
    dashBoardHighTierWinsDays: 0,
    dashBoardHighTierNumberOfWinsToDisplay: 0,
    dashBoardHighTierWinsFullCount: 0,
    dashBoardHighTierWinsRetailersCount: 0,
    dashBoardHighTierWinsFromDate: '',
    dashBoardHighTierWinsToDate: '',
    retailerReferences: [],
};

const prizeWinnings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRIZE_WINNINGS_LOADING:
            return { ...state, loading: true };

        case PRIZE_WINNINGS_INIT:
            return { ...INITIAL_STATE };

        case PRIZE_WINNINGS_LOADED:
            return {
                ...state,
                ...action.list,
                highTierPrizeThreshold: action.highTierPrizeThreshold,
                loading: false,
            };

        case PRIZE_WINNINGS_DASHBOARD_LOADING:
            return { ...state, dashBoardLoading: true, dashBoardLoaded: false };

        case PRIZE_WINNINGS_DASHBOARD_LOADED:
            return {
                ...state,
                highTierPrizeThreshold: action.highTierPrizeThreshold,
                dashBoardHighTierWinsDays: action.dashBoardHighTierWinsDays,
                dashBoardHighTierNumberOfWinsToDisplay:
                    action.dashBoardHighTierNumberOfWinsToDisplay,
                dashBoardHighTierWinsFullCount:
                    action.dashBoardHighTierWinsFullCount,
                dashBoardHighTierWinsRetailersCount:
                    action.dashBoardHighTierWinsRetailersCount,
                dashBoardHighTierWinsFromDate:
                    action.dashBoardHighTierWinsFromDate,
                dashBoardHighTierWinsToDate: action.dashBoardHighTierWinsToDate,
                retailerReferences: action.retailerReferences,
                dashBoardLoading: false,
                dashBoardLoaded: true,
            };

        default:
            return state;
    }
};
export default prizeWinnings;
