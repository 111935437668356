import { SurveyApi } from '../../../services/survey-service';
import { getDownloadError, blobToJson } from '../../../constants/utils';
import { error, warning, clearNotification } from '../notifications';

export const SURVEY_EXPORT_LOADING = 'SURVEY_EXPORT_LOADING';

const surveyApi = new SurveyApi();

const reportError = (errors, target) => {
    return async (dispatch) => {
        let msg = await getDownloadError(errors);
        if (msg && msg.toLowerCase() === 'no data found') {
            msg = 'Sorry, no response was found for this survey';
            dispatch(warning({ msg, target }));
        } else {
            dispatch(error({ msg, target }));
        }
    };
};

export const exportSurvey = (id, target) => {
    return async (dispatch) => {
        dispatch(clearNotification());
        try {
            dispatch({ type: SURVEY_EXPORT_LOADING, loading: true });
            const data = await surveyApi.exportSurvey(id);
            if (data && data instanceof Blob) {
                const parsed = await blobToJson(data);
                if (parsed) {
                    if (parsed.error) {
                        dispatch(error({ msg: parsed.error, target }));
                    } else if (parsed.warn) {
                        dispatch(warning({ msg: parsed.warn, target }));
                    }
                }
            }
        } catch (e) {
            console.error(e);
            dispatch(reportError(e, target));
        } finally {
            dispatch({ type: SURVEY_EXPORT_LOADING, loading: false });
        }
    };
};
