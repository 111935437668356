import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { map, isFunction } from 'lodash';
import { Button, Menu } from 'semantic-ui-react';
import './styles.css';
import { isBoolean } from '../../constants/utils';

class MobileMenu extends Component {
    state = { isActive: false };

    toggleMenu = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    handleItemClick = (route) => {
        this.setState({ isActive: false }, () => {
            if (!route || this.props.location.pathname === route) {
                return;
            }

            this.props.history.push(route);
        });
    };

    render() {
        const { menuItems } = this.props;
        const { isActive } = this.state;
        return (
            <div className="mobile-menu">
                <Button
                    className={classnames('hamburger', 'hamburger--slider', {
                        'is-active': isActive,
                    })}
                    onClick={this.toggleMenu}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner" />
                    </span>
                </Button>
                {isActive && (
                    <Menu borderless vertical>
                        {map(
                            menuItems,
                            ({ name, display, dropdown, route, condition }) => {
                                if (isBoolean(condition) && !condition) {
                                    return;
                                }

                                return (
                                    <Menu.Item
                                        key={name}
                                        className={classnames(
                                            'clickable',
                                            `${name}-item`
                                        )}
                                        name={name}
                                        onClick={() =>
                                            this.handleItemClick(route)
                                        }
                                    >
                                        {(dropdown &&
                                            isFunction(dropdown) &&
                                            dropdown(
                                                'Right',
                                                true,
                                                this.toggleMenu
                                            )) ||
                                            display}
                                    </Menu.Item>
                                );
                            }
                        )}
                    </Menu>
                )}
            </div>
        );
    }
}

export default withRouter(MobileMenu);
