import { Courier } from '../../../DTOs/courier';

export const GET_COURIER = 'EditCourier/Get';
export const UPDATE_LOADING_STATE = 'EditCourier/Loading';

export interface EditCourierStateType {
    isLoading: boolean;
    courier: Courier;
}

interface GetCourierType {
    type: typeof GET_COURIER;
    payload: Courier;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type EditCourierActionTypes = GetCourierType | UpdateLoadingState;
