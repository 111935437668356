import React from 'react';
import { Form, Checkbox, Transition } from 'semantic-ui-react';
import UneditableLabel from '../UneditableLabel';
import './styles.css';
import { Field } from 'redux-form';

export const renderToggleInput = ({
    input: { name, value, onChange },
    activeLabel,
    inactiveLabel,
    meta: { error, warning },
    animationLen,
}) => {
    return (
        <Form.Field>
            <Checkbox
                toggle
                name={name}
                value={value}
                label={value ? activeLabel : inactiveLabel}
                checked={value}
                onChange={() => onChange(!value)}
            />
            <Transition
                visible={!!error}
                animation="fade"
                duration={animationLen}
            >
                <span className="error-text">
                    <i>{error}</i>
                </span>
            </Transition>
            <Transition
                visible={!!warning}
                animation="fade"
                duration={animationLen}
            >
                <span className="warning-text">
                    <i>{warning}</i>
                </span>
            </Transition>
        </Form.Field>
    );
};

const SfaReduxToggle = ({
    labelText,
    toggleName,
    activeLabel,
    inactiveLabel,
    ...otherProps
}) => {
    return (
        <Form.Field>
            <div className="readonly">
                <UneditableLabel text={labelText} />
            </div>
            <Field
                name={toggleName}
                component={renderToggleInput}
                activeLabel={'ACTIVE'}
                inactiveLabel={'INACTIVE'}
                {...otherProps}
            ></Field>
        </Form.Field>
    );
};

export default SfaReduxToggle;
