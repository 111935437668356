import { ReturnsApi } from '../../../services/returns-service';

export const RETURN_REASON_LOADING = 'RETURN_REASON_LOADING';
export const RETURN_REASON_LOADING_DONE = 'RETURN_REASON_LOADING_DONE';
export const RETURN_REASON_GET = 'RETURN_REASON_GET';
export const RETURN_REASON_INIT = 'RETURN_REASON_INIT';

const returnsApi = new ReturnsApi();

export const getReturnReasonData = () => {
    return async (dispatch) => {
        dispatch({ type: RETURN_REASON_LOADING });

        const payload = await returnsApi.getAllReturnReasons();

        try {
            dispatch({
                type: RETURN_REASON_GET,
                payload,
            });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: RETURN_REASON_LOADING_DONE });
    };
};

export const initReturnReasonSelect = () => ({
    type: RETURN_REASON_INIT,
});
