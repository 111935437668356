import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { RetailerPosApi } from '../../../services/retailer-pos-service';
import { NOTIFY_ERROR, NOTIFY_SUCCESS } from '../index';

export const RETAILER_POS_UPDATE_SUCCESS = 'RETAILER_POS_UPDATE_SUCCESS';
export const RETAILER_POS_DELETE_SUCCESS = 'RETAILER_POS_DELETE_SUCCESS';
export const UPDATE_RETAILER_POS_LOADING = 'UPDATE_RETAILER_POS_LOADING';
export const UPDATE_RETAILER_POS_LOADING_DONE =
    'UPDATE_RETAILER_POS_LOADING_DONE';

const retailerPosApi = new RetailerPosApi();

const updateRetailerPosError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'ReadRetailer' })
        );
    };
};

export const deleteRetailerPos = (retailerPosId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: UPDATE_RETAILER_POS_LOADING });
            await retailerPosApi.deleteRetailerPos(retailerPosId);
            dispatch({
                type: RETAILER_POS_DELETE_SUCCESS,
            });
        } catch (err) {
            dispatch(updateRetailerPosError(err));
            success = false;
        } finally {
            dispatch({ type: UPDATE_RETAILER_POS_LOADING_DONE });
        }
        return success;
    };
};

export const updateRetailerPos = (retailerPos) => {
    return async (dispatch, getState) => {
        let success = true;
        try {
            dispatch({ type: UPDATE_RETAILER_POS_LOADING });

            const { offline } = getState();

            //if online just do right away
            if (offline.online) {
                await retailerPosApi.updateRetailerPos(retailerPos);
            } else {
                dispatch({
                    type: RETAILER_POS_UPDATE_SUCCESS,
                    payload: { retailerPos },
                    meta: {
                        offline: {
                            effect: {
                                method: 'put',
                                url: `/retailers/retailerPos/${retailerPos.id}`,
                                body: retailerPos,
                            },
                            commit: {
                                type: NOTIFY_SUCCESS,
                                msg: 'Queued Equipment Asset was updated successfully',
                                target: 'ReadRetailer',
                            },
                            rollback: {
                                type: NOTIFY_ERROR,
                                msg: 'Queued Equipment Asset was not created',
                                target: 'ReadRetailer',
                            },
                        },
                    },
                });
            }

            //const data = await retailerPosApi.updateRetailerPos(retailerPos);
        } catch (err) {
            console.error(err);
            success = false;
            dispatch(updateRetailerPosError(err));
        } finally {
            dispatch({ type: UPDATE_RETAILER_POS_LOADING_DONE });
        }
        return success;
    };
};
