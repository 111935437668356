import { Backend } from './backend';

import { checkFilters } from './utils';

class RetailersApi {
    constructor() {
        this.path = '/retailers';
    }

    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/version`,
        });
    }

    async getAllActiveRetailersCondensed() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/active/condensed`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerById(id) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerByReference(reference) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/by-reference/${reference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerInfoByReference(reference) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/by-reference/info/${reference}`,
            });
        } catch (e) {
            throw e;
        }
    }
    async getUserRetailerSuggestedOrders(reference) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/retailer-item-inventory/by-retailer-reference/${reference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersByPlanogroupId(id) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/by-planogram-group-id/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getReferencesByIds(ids) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/references-by-ids`,
                body: ids,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerTypes() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/retailer-types`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersFilter({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search/or/condensed`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'reference',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersFilterPaged({
        page,
        size,
        sortOrder,
        sortColumn,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search/or`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllRetailers({
        page,
        size,
        sortOrder,
        sortColumn,
        sortColumns,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    sortColumns: sortColumns,
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersByIds(retailerIds) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/by-ids?ids=${JSON.stringify(retailerIds)}`,
                body: retailerIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersByReferences(references) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/by-references`,
                body: references,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailersByAccountId(accountId) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/by-account-id/${accountId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateNumberOfFacings(retailerId, facingNumber) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${retailerId}/facings/${facingNumber}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePlanogramGroup(retailerId, planogramGroupId) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${retailerId}/planogram-group/${planogramGroupId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateAllRetailersWithPlanogramGroup(retailerIds, planogramGroupId) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/by-planogram-group-id/${planogramGroupId}`,
                body: retailerIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async getIndustryCodes() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/industry-types`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTerritories() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/territory`,
            });
        } catch (e) {
            throw e;
        }
    }

    async visitPlannerSetup(salesRep, currentWeek) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/planner-setup/${salesRep}/${currentWeek}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async searchRegions({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/regions/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'name',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getByRegionId(regionId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-region-id/${regionId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async saveRetailerContacts(retailerId, retailerContactObject) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/retailer-contacts-by-id/${retailerId}`,
                body: retailerContactObject,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerIdByReference(retailerReference) {
        try {
            return await Backend.send({
                url: `${this.path}/suretrackRetailerId-by-reference/${retailerReference}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }
}

export { RetailersApi };
