import PlanogramService from '../../../services/planogram-service';
import { Dispatch } from 'redux';
import {
    GET_PLANOGRAMS,
    GET_PLANOGRAM_SEARCH,
    PlanogramActionTypes,
    UPDATE_LOADING_STATE as LIST_UPDATE_LOADING_STATE,
} from '../../type/planogram/planogram-type';
import { Planogram } from '../../../DTOs/planogram';
import {
    GET_PLANOGRAM,
    EditPlanogramActionTypes,
    UPDATE_LOADING_STATE,
} from '../../type/planogram/edit-planogram-type';
import { error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';
import type { SearchSpecificationModel } from '../../../interface/search-specification-model';
import { Page } from '../../../layout/Page';

const planogramService = new PlanogramService();

export const getPlanogramList = () => {
    return async (dispatch: Dispatch<PlanogramActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const planograms: Planogram[] = await planogramService.get();
            dispatch({ type: GET_PLANOGRAMS, payload: planograms });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};

export const getPlanogramPage = (
    searchSpecificationModel: SearchSpecificationModel
) => {
    return async (dispatch: Dispatch<PlanogramActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const planograms: Page<Planogram> =
                await planogramService.getBySearchSpecificationModel(
                    searchSpecificationModel
                );
            dispatch({
                type: GET_PLANOGRAM_SEARCH,
                payload: planograms,
            });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const getPlanogram = (id: number) => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        try {
            const planogram: Planogram = await planogramService.getById(id);
            dispatch({ type: GET_PLANOGRAM, payload: planogram });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const createEmptyPlanogram = () => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        dispatch({ type: GET_PLANOGRAM, payload: { isActive: true } });
    };
};

export const deletePlanogram = (id: number) => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        var success = false;
        try {
            await planogramService.deleteById(id);
            success = true;
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
        }
        return success;
    };
};
export const savePlanogram = (id: number, planogram: Planogram) => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });

        try {
            if (id) {
                planogram = await planogramService.update(id, planogram);
            } else {
                planogram = await planogramService.create(planogram);
            }
            dispatch({ type: GET_PLANOGRAM, payload: planogram });
        } catch (e) {
            if (e.response.data) {
                if (
                    typeof e.response.data === 'object' &&
                    e.response.data.error
                ) {
                    dispatch(
                        updateMessageError(
                            'File upload failed, contact administrator'
                        )
                    );
                } else {
                    dispatch(updateMessageError(e.response.data));
                }
            } else {
                dispatch(updateError(e));
            }
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
            throw e;
        }
        return planogram;
    };
};

const updateMessageError = (msg) => {
    return (dispatch) => {
        dispatch(error({ msg: msg, target: 'Retailer Group' }));
    };
};

const updateError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'Retailer Group' })
        );
    };
};

export const clearPlanogram = () => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: GET_PLANOGRAM, payload: undefined });
    };
};
export const clearPlanograms = () => {
    return async (dispatch: Dispatch<EditPlanogramActionTypes>) => {
        dispatch({ type: GET_PLANOGRAM_SEARCH, payload: undefined });
        dispatch({ type: GET_PLANOGRAMS, payload: undefined });
    };
};
