import { PlanogramApi } from '../../../services/planogram-group-service';

export const PLANOGRAM_OPTIONS_LOADING = 'PLANOGRAM_OPTIONS_LOADING';
export const GET_PLANOGRAM_OPTIONS_SUCCESS = 'GET_PLANOGRAM_OPTIONS_SUCCESS';

const planogramApi = new PlanogramApi();

export const getPlanogramGroups = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: PLANOGRAM_OPTIONS_LOADING });

            const searchOptions = {
                page: 0,
                size: 100,
            };
            const options = await planogramApi.getAllPlanogramGroups(
                searchOptions
            );

            dispatch({
                type: GET_PLANOGRAM_OPTIONS_SUCCESS,
                options,
            });
        } catch (e) {
            console.error(e);
        }
    };
};
