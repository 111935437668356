import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage } from '../../../constants/utils';
import { error, success } from '../notifications';

export const MARKETING_DELETE_LOADING = 'MARKETING_DELETE_LOADING';
export const MARKETING_DELETE_SUCCESS = 'MARKETING_DELETE_SUCCESS';
export const MARKETING_DELETE_FAIL = 'MARKETING_DELETE_FAIL';
export const MARKETING_DELETE_MODAL = 'MARKETING_DELETE_MODAL';

const surveyApi = new SurveyApi();

export const showDeleteMarketingSurveyModal = () => ({
    type: MARKETING_DELETE_MODAL,
    showModal: true,
});
export const closeDeleteMarketingSurveyModal = () => ({
    type: MARKETING_DELETE_MODAL,
    showModal: false,
});

export const deleteMarketingSurvey = (
    id,
    currentPage,
    target,
    successCallback
) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MARKETING_DELETE_LOADING });

            await surveyApi.deleteMarketingSurvey(id);

            dispatch({ type: MARKETING_DELETE_SUCCESS });

            dispatch(
                success({
                    msg: 'Marketing Survey was successfully deleted',
                    target,
                })
            );

            if (successCallback) {
                successCallback();
            }
        } catch (err) {
            dispatch(error({ msg: getErrorMessage(err), target: currentPage }));
            dispatch({ type: MARKETING_DELETE_FAIL });
        }
    };
};
