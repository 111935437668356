import {
    FRONTEND_CONFIG_LOADING,
    FRONTEND_CONFIG_LOADING_DONE,
    FRONTEND_CONFIG_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    configs: [],
};

const frontendConfig = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FRONTEND_CONFIG_GET:
            return { ...state, configs: [...payload] };

        case FRONTEND_CONFIG_LOADING:
            return {
                ...state,
                loading: true,
            };

        case FRONTEND_CONFIG_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default frontendConfig;
