import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, max, truncate } from 'lodash';
import Widget from '../Widget';
import { formatMoney } from '../../../../constants/utils';
import {
    getTotalpurchaseYtdForNonProductItems,
    pushReferrer,
} from '../../../../redux/actions';
import NoItems from '../../../../components/NoItems';

import './styles.css';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';

export class TotalPurchaseYtdNonProductWidget extends Component {
    async componentWillMount() {
        await this.init();
    }

    init = async () => {
        const { getTotalpurchaseYtdForNonProductItems } = this.props;
        await getTotalpurchaseYtdForNonProductItems(
            this.props.retailerInfo?.reference
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    renderWidgetBar = (max, value) => {
        return (
            <div
                className="total-percentage-bar text-right"
                style={{ width: `${(value / max) * 100}%`, minWidth: '7%' }}
            >
                <div className="bar-text">
                    <span>{value}</span>
                </div>
            </div>
        );
    };

    render() {
        const { loading, totalPurchaseYtdNonProducts, errorResponse } =
            this.props;

        const noContent =
            !totalPurchaseYtdNonProducts &&
            totalPurchaseYtdNonProducts?.length > 0;

        let maxTotal = 0;

        if (!noContent) {
            maxTotal = max(map(totalPurchaseYtdNonProducts, 'itemCount'));
        }
        return (
            <Widget loading={loading}>
                <div className="bold widget-heading">
                    Total Purchase YTD (Non - Product Items)
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!totalPurchaseYtdNonProducts && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : totalPurchaseYtdNonProducts &&
                  totalPurchaseYtdNonProducts.length > 0 ? (
                    <div
                        className="total-purchase-YTD-widget-non-product-content widget-container-height"
                        data-test="withData"
                    >
                        <table>
                            <tbody>
                                {map(
                                    totalPurchaseYtdNonProducts,
                                    (totalPurchaseYtdNonProduct) => (
                                        <tr
                                            className="task-completion-bar"
                                            key={
                                                totalPurchaseYtdNonProduct.itemName
                                            }
                                        >
                                            <td>
                                                <div className="game-info">
                                                    <div className="bold price-point">
                                                        {formatMoney(
                                                            totalPurchaseYtdNonProduct.itemPrice
                                                        )}
                                                    </div>
                                                    <div className="name">
                                                        {truncate(
                                                            `${totalPurchaseYtdNonProduct.itemReferenceName} - ${totalPurchaseYtdNonProduct.itemName}`,
                                                            { length: 30 }
                                                        )}
                                                    </div>
                                                    <div>
                                                        {this.renderWidgetBar(
                                                            maxTotal,
                                                            totalPurchaseYtdNonProduct.itemCount
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no delivered order!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, totalPurchaseYtdNonProducts, errorResponse } =
        state.totalPurchaseYtdNonProducts;
    const { retailerInfo } = state.userInfo;
    return {
        loading,
        totalPurchaseYtdNonProducts,
        retailerInfo,
        errorResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
        getTotalpurchaseYtdForNonProductItems: (retailerReference) =>
            dispatch(getTotalpurchaseYtdForNonProductItems(retailerReference)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalPurchaseYtdNonProductWidget);
