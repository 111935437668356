import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Input } from 'semantic-ui-react';
import _ from 'lodash';
import {
    success,
    clearNotification,
    filterPermissions,
    setEditPermissions,
    addPermissionGroupToRole,
    addPermissionToRole,
    removePermissionFromRole,
    removePermissionGroupFromRole,
    saveRolePermissions,
    setIsFormDirty,
    initCreateRole,
    initRolesAndPrivileges,
    setActiveRoles,
} from '../../redux/actions';
import { addSelectedItem } from '../../components/filter/actions';
import config from '../../constants/config';
import Page from '../../layout/Page';
import RolesAndPermissionsTable from './RolesAndPermissionsTable';
import RolesFilter from './RolesFilter';

import './styles.css';
import NoResult from '../../components/datatable/NoResult';
import OkCancelModal from '../../components/OkCancelModal';
const { CREATE_ROLE } = config.ROUTE_URLS;
class PermissionsManagementPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultFilterItems: {
                status: 'ACTIVE',
                roles: '*',
                useDefault: true,
            },
            roleData: this.props.roles,
            prevSearchValue: '',
            currentSearchValue: '',
            showConfirmation: false,
        };
    }

    componentDidMount() {
        this._initRolesAndPrivileges();
    }

    componentWillUnmount() {
        this.props.clearNotifications();
        this.props.setEditPermissions(false);
        this.props.setActiveRoles([]);
    }

    _initRolesAndPrivileges = async () => {
        await this.props.initRolesAndPrivileges();
    };

    handleRoleHeaderClicked = (roleId) => {
        const { UPDATE_ROLE } = config.ROUTE_URLS;
        this.props.history.push(`${UPDATE_ROLE}/${roleId}`);
    };

    getRolesToDisplay = () => {
        const { rolesList } = this.props;
        let newRoles = [];
        const selectedRoles = this.props.selectedItems.roles;
        const filter =
            selectedRoles && selectedRoles.length > 0
                ? selectedRoles
                : _.map(
                      _.filter(rolesList, (r) => r.displayName !== 'Developer'),
                      ({ displayName }) => displayName
                  );

        _.forEach(rolesList, (role) => {
            const thisPrivilege = role.privileges;
            if (_.includes(filter, role.displayName)) {
                newRoles.push({
                    id: role.id,
                    display: role.display,
                    name: role.name,
                    isAdmin: role.name === 'ADMIN',
                    isEnabled: true,
                    displayName: role.displayName,
                    permissions: _.uniq(
                        _.map(thisPrivilege, (privilege) => {
                            if (
                                privilege.group !== null &&
                                _.startsWith(privilege.name, 'ROLE_RE_')
                            ) {
                                return {
                                    id: privilege.id,
                                    name: privilege.name,
                                    displayName: privilege.displayName,
                                    group: {
                                        name: privilege.group.name,
                                        subMenu: privilege.subMenu,
                                        type: privilege.type,
                                        id: privilege.group.id,
                                    },
                                };
                            }
                        })
                    ),
                });
            }
        });

        return newRoles;
    };

    onSearchFieldChange = (e) => {
        this.setState({ currentSearchValue: e.target.value });
    };

    onSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._onSearch();
        }
    };

    _onSearch = async () => {
        const { currentSearchValue } = this.state;

        this.setState({
            prevSearchValue: currentSearchValue,
        });
        await this.props.filterPermissions(currentSearchValue);
    };

    _onSearchClear = async () => {
        await this.setState({
            currentSearchValue: '',
            prevSearchValue: '',
        });
        this._onSearch();
    };

    handlePermissionCheckedChanged = ({ permissionId, roleId, checked }) => {
        checked
            ? this.props.addPermissionToRole({ permissionId, roleId })
            : this.props.removePermissionFromRole({
                  permissionId,
                  roleId,
              });
    };

    handlePermissionGroupCheckedChanged = ({ groupId, roleId, checked }) => {
        checked
            ? this.props.addPermissionGroupToRole({ groupId, roleId })
            : this.props.removePermissionGroupFromRole({
                  groupId,
                  roleId,
              });
    };

    handleCancelEditRoles = () => {
        this.props.setEditPermissions(false);
        this._initRolesAndPrivileges();
    };

    showConfirmationModal = () => this.setState({ showConfirmation: true });
    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    saveRolePermissions = async () => {
        this.closeConfirmationModal();
        const { rolesList } = this.props;
        let request = _.map(
            _.filter(rolesList, ['isTouched', true]),
            (role) => {
                let permissions = _.map(role.privileges, (permission) => {
                    return permission.id;
                });
                return { id: role.id, privilegesIds: permissions };
            }
        );

        let success = await this.props.saveRolePermissions(request);

        if (success) {
            this.props.success({ msg: 'Privileges updated successfully' });
            this.props.setEditPermissions(false);
        }
    };
    handleCreatePress = () => {
        this.props.history.push(CREATE_ROLE);
    };

    render() {
        const {
            privilegesList,
            editPermissions,
            rolesList,
            permissionsLoading,
        } = this.props;
        const { currentSearchValue, prevSearchValue, showConfirmation } =
            this.state;
        const newRoles = _.filter(this.getRolesToDisplay(), ['display', true]);
        const searchIcon =
            currentSearchValue && currentSearchValue.trim().length > 0 ? (
                <Icon name="close" link onClick={this._onSearchClear} />
            ) : null;

        let permissionsList = [];
        let noResults =
            !_.size(privilegesList) && prevSearchValue.trim() !== '';
        let disableSaveButton = !_.size(
            _.filter(rolesList, ['isTouched', true])
        );

        _.forEach(privilegesList, (privilege) => {
            if (
                privilege.type !== '' &&
                _.startsWith(privilege.name, 'ROLE_RE_') &&
                privilege.group !== null
            ) {
                permissionsList.push({
                    id: privilege.id,
                    name: privilege.name,
                    displayName: privilege.displayName,
                    group: {
                        name: privilege.group.name,
                        subMenu: privilege.subMenu,
                        type: privilege.type,
                        id: privilege.group.id,
                    },
                });
            }
        });
        return (
            <Page
                name="PermissionsManagementPage"
                title="User Roles & Permissions"
                data-test="PermissionManagementPage"
                addButtonRoles={[
                    'ROLE_RE_PRIVILEGES_LIST',
                    'ROLE_RE_PRIVILEGES_CREATE',
                ]}
                onAddButtonClick={this.handleCreatePress}
                loading={permissionsLoading}
            >
                <OkCancelModal
                    header={`Update Permissions`}
                    body={`Are you sure you want to save the changes?`}
                    cancelFunction={this.closeConfirmationModal}
                    okFunction={this.saveRolePermissions}
                    isOpen={showConfirmation}
                    okText={'Save'}
                />
                <div className="data-section">
                    <div className="edit-roles-section">
                        {editPermissions ? (
                            <span>
                                <Button
                                    content="Cancel"
                                    className="transparent-btn"
                                    size="small"
                                    onClick={this.handleCancelEditRoles}
                                />
                                <Button
                                    content="Save"
                                    primary
                                    disabled={disableSaveButton}
                                    size="small"
                                    onClick={this.showConfirmationModal}
                                />
                            </span>
                        ) : (
                            <Button
                                content="Edit"
                                primary
                                size="small"
                                onClick={() =>
                                    this.props.setEditPermissions(true)
                                }
                            />
                        )}
                    </div>
                    <div className="flex-row space-between">
                        <div className="search-section">
                            <Input
                                placeholder="Permission..."
                                value={this.state.currentSearchValue}
                                onChange={this.onSearchFieldChange}
                                onKeyPress={this.onSearchKeyPress}
                                icon={searchIcon}
                            />
                            <Button
                                className="search-btn"
                                data-test="searchBtn"
                                disabled={
                                    this.state.currentSearchValue ===
                                    this.state.prevSearchValue
                                }
                                primary={
                                    !(
                                        this.state.currentSearchValue ===
                                        this.state.prevSearchValue
                                    )
                                }
                                onClick={this._onSearch}
                            >
                                <span className="icon-Search"> </span>
                            </Button>

                            <div className="roles-filter-section">
                                <RolesFilter />
                            </div>
                        </div>
                        {noResults ? (
                            <NoResult />
                        ) : (
                            <RolesAndPermissionsTable
                                roles={newRoles}
                                permissions={permissionsList}
                                readOnly={!editPermissions}
                                data-test="rolesPermissionTable"
                                handlePermissionGroupCheckedChanged={
                                    this.handlePermissionGroupCheckedChanged
                                }
                                handlePermissionCheckedChanged={
                                    this.handlePermissionCheckedChanged
                                }
                                onRoleHeaderClicked={
                                    this.handleRoleHeaderClicked
                                }
                            />
                        )}
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ listUsers, auth, tableFilter, privileges }) => {
    const { content, totalElements, totalPages, size, number, loading } =
        listUsers;
    const { selectedItems } = tableFilter;
    const {
        editPermissions,
        rolesList,
        initialRoles,
        permissionsLoading,
        filteredPrivileges: privilegesList,
    } = privileges;
    return {
        auth,
        content,
        totalElements,
        totalPages,
        size,
        number,
        loading,
        selectedItems,
        privileges,
        editPermissions,
        privilegesList,
        rolesList,
        initialRoles,
        permissionsLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initRolesAndPrivileges: () => dispatch(initRolesAndPrivileges()),
        clearNotifications: () => dispatch(clearNotification()),
        onSelectFilterItem: (filterName, item) =>
            dispatch(addSelectedItem(filterName, item)),
        filterPermissions: (filterQuery) =>
            dispatch(filterPermissions(filterQuery)),
        setEditPermissions: (shouldEditPermissions) =>
            dispatch(setEditPermissions(shouldEditPermissions)),
        addPermissionToRole: (args) => dispatch(addPermissionToRole(args)),
        removePermissionFromRole: (args) =>
            dispatch(removePermissionFromRole(args)),
        addPermissionGroupToRole: (args) =>
            dispatch(addPermissionGroupToRole(args)),
        removePermissionGroupFromRole: (args) =>
            dispatch(removePermissionGroupFromRole(args)),
        saveRolePermissions: (request) =>
            dispatch(saveRolePermissions(request)),
        success: (args) => dispatch(success(args)),
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
        initCreateRole: () => dispatch(initCreateRole()),
        setActiveRoles: (list) => dispatch(setActiveRoles(list)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PermissionsManagementPage)
);
