import {
    FILTER_LIST_LOADING,
    FILTER_LIST,
    FILTER_LIST_MORE,
    FILTER_LIST_MORE_LOADING,
    FILTER_LIST_SEARCH_LOADING,
    FILTER_LIST_SEARCH_CLIENT,
    FILTER_LIST_SEARCH,
    FILTER_LIST_UPDATE_SEARCH,
} from '../actions';

const INITIAL_STATE = {
    filterItems: {},
    searchValues: {},
};

export const filters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILTER_LIST_LOADING:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        loading: true,
                    },
                },
            };
        case FILTER_LIST:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        content: action.list,
                        page: action.page,
                        totalPages: action.totalPages,
                        totalElements: action.totalElements,
                        loading: false,
                    },
                },
            };
        case FILTER_LIST_MORE: {
            const filter = state.filterItems[action.name] || {};
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...filter,
                        content: [...(filter.content || []), ...action.list],
                        totalPages: action.totalPages,
                        totalElements: action.totalElements,
                        page: action.page,
                        loadingMore: false,
                    },
                },
            };
        }
        case FILTER_LIST_MORE_LOADING:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        loadingMore: true,
                    },
                },
            };
        case FILTER_LIST_SEARCH_LOADING:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        searching: true,
                    },
                },
            };
        case FILTER_LIST_SEARCH_CLIENT:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        content: action.list,
                        page: action.page,
                        totalPages: action.totalPages,
                        totalSearchResults: action.totalElements,
                        searching: false,
                    },
                },
            };
        case FILTER_LIST_SEARCH:
            return {
                ...state,
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        content: action.list,
                        page: action.page,
                        query: action.query,
                        totalPages: action.totalPages,
                        totalSearchResults: action.totalElements,
                        searching: false,
                    },
                },
            };
        case FILTER_LIST_UPDATE_SEARCH:
            return {
                ...state,
                searchValues: {
                    ...state.searchValues,
                    [action.name]: action.value,
                },
                filterItems: {
                    ...state.filterItems,
                    [action.name]: {
                        ...state.filterItems[action.name],
                        query: action.value,
                    },
                },
            };
        default:
            return state;
    }
};
