import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { POSItemsApi } from '../../../services/pos-order-service';

export const POS_ITEM_LIST = 'POS_ITEM_LIST';
export const POS_ITEM_LIST_LOADING = 'POS_ITEM_LIST_LOADING';
export const POS_ITEM_LIST_LOADING_DONE = 'POS_ITEM_LIST_LOADING_DONE';

const posItemsApi = new POSItemsApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getAllPOSItems = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: POS_ITEM_LIST_LOADING });
            const list = await posItemsApi.getAllPOSItems();
            dispatch({
                type: POS_ITEM_LIST,
                list,
            });
        } catch (err) {
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: POS_ITEM_LIST_LOADING_DONE });
        }
    };
};
