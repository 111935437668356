import {
    LAST_VISIT_DATE_WIDGET_LOADING,
    LAST_VISIT_DATE_WIDGET_SUCCESS,
    LAST_VISIT_DATE_WIDGET_UPDATE,
    LAST_VISIT_DATE_WIDGET_CLEAR,
} from '../../../actions/retailerDashboard/widgets/lastVisitDateWidget';

const INITIAL_STATE = {
    loading: false,
    lastCompletedTaskDate: null,
};

const lastVisitDateWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LAST_VISIT_DATE_WIDGET_LOADING:
            return { ...INITIAL_STATE, loading: action.loading };
        case LAST_VISIT_DATE_WIDGET_SUCCESS:
            return {
                ...state,
                lastCompletedTaskDate: action.lastCompletedTaskDate,
                loading: false,
            };
        case LAST_VISIT_DATE_WIDGET_UPDATE:
            return {
                ...state,
                lastCompletedTaskDate: action.lastCompletedTaskDate,
                loading: false,
            };
        case LAST_VISIT_DATE_WIDGET_CLEAR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default lastVisitDateWidget;
