import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PortalFileUploadButton from './PortalFileUploadButton';

class WysiwygEditor extends Component {
    render() {
        return (
            <Editor
                editorStyle={{ backgroundColor: '#d8ebf0' }}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbarCustomButtons={[
                    <PortalFileUploadButton docType={this.props.docType} />,
                ]}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: {
                        inDropdown: true,
                    },
                    history: { inDropdown: true },
                }}
                {...this.props}
            />
        );
    }
}

export default WysiwygEditor;
