import { RetailersApi } from '../../services/retailers-service';
import { SalesRoutesApi } from '../../services/sales-routes-service';
import { TiersApi } from '../../services/tiers-service';
import { PlanogramApi } from '../../services/planogram-group-service';
import { salesRouteActions } from '../../constants/salesRoutes';
import { getErrorMessage } from '../../constants/utils';
import { error, success } from './notifications';

import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';
import { NOTIFY_ERROR, NOTIFY_SUCCESS } from './index';

export const GET_RETAILER_SUCCESS = 'GET_RETAILER_SUCCESS';
export const RETAILER_LOADING = 'RETAILERS_LOADING';
export const RETAILER_ASSOCIATION_FAILED = 'RETAILER_ASSOCIATION_FAILED';
export const RETAILER_INIT_STATE = 'RETAILER_INIT_STATE';

export const UPDATE_RETAILER_CONTACTS_PROCESSING =
    'UPDATE_RETAILER_CONTACTS_PROCESSING';
export const UPDATE_RETAILER_CONTACTS_SUCCESS =
    'UPDATE_RETAILER_CONTACTS_SUCCESS';
export const UPDATE_RETAILER_CONTACTS_DONE = 'UPDATE_RETAILER_CONTACTS_DONE';

const retailersApi = new RetailersApi();
const salesRoutesApi = new SalesRoutesApi();
const tiersApi = new TiersApi();
const planogramApi = new PlanogramApi();

const { SALES_ROUTE_ASSIGN_RETAILER } = salesRouteActions;

const readRetailerErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getRetailerById = (retailerId) => {
    return async (dispatch, getState) => {
        const {
            organization: { phoneFaxMask },
        } = getState();
        dispatch({ type: RETAILER_LOADING });
        let retailer;
        try {
            retailer = await retailersApi.getRetailerById(retailerId);
        } catch (e) {
            //send to error page here, if makes it farther then it is legit retailer
            console.error(e);
            dispatch(readRetailerErrorHandlerError(e));
        }

        if (retailer) {
            let tier;
            try {
                tier = await tiersApi.getTierByRetailerId(retailerId);
            } catch (e) {
                console.error(e);
                tier = {
                    notAvailable: true,
                };
            }

            let salesRoute;
            try {
                salesRoute = await salesRoutesApi.getSalesRouteByRetailerId(
                    retailerId
                );
            } catch (e) {
                console.error(e);
                salesRoute = {
                    notAvailable: true,
                };
            }

            let planogramGroup;
            const planogramGroupId = retailer.planogramGroupId;
            if (planogramGroupId) {
                try {
                    planogramGroup = await planogramApi.getPlanogramGroupById(
                        planogramGroupId
                    );
                } catch (e) {
                    console.error(e);
                    planogramGroup = {
                        notAvailable: true,
                    };
                }
            }

            dispatch({
                type: GET_RETAILER_SUCCESS,
                retailer,
                salesRoute,
                tier,
                planogramGroup,
                phoneFaxMask,
            });
        }
    };
};

export const saveAssociations = ({
    retailerId,
    salesRouteId,
    action,
    tierObj,
    numberOfFacings,
    planogramGroupId,
}) => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_LOADING });

        let salesRouteComplete = true;
        let errorMsg;

        if (salesRouteId) {
            try {
                action === SALES_ROUTE_ASSIGN_RETAILER
                    ? await salesRoutesApi.saveSalesRouteToRetailer(
                          retailerId,
                          salesRouteId
                      )
                    : await salesRoutesApi.unassignSalesRouteToRetailer(
                          retailerId,
                          salesRouteId
                      );
            } catch (e) {
                console.error(e);
                salesRouteComplete = false;
                errorMsg = `Failed to save sales route association due to: ${getErrorMessage(
                    e
                )}`;
            }
        }

        if (salesRouteComplete) {
            let tierComplete = true;
            if (tierObj) {
                try {
                    await tiersApi.saveTierToRetailer(retailerId, tierObj);
                } catch (e) {
                    console.error(e);
                    tierComplete = false;
                    errorMsg = `Failed to save tier association due to: ${getErrorMessage(
                        e
                    )}`;
                }
            }

            if (tierComplete) {
                let facingComplete = true;
                if (numberOfFacings > -1 || !numberOfFacings) {
                    //allow 0 value
                    try {
                        if (!numberOfFacings && numberOfFacings !== 0) {
                            numberOfFacings = null;
                        }
                        await retailersApi.updateNumberOfFacings(
                            retailerId,
                            numberOfFacings
                        );
                    } catch (e) {
                        console.error(e);
                        facingComplete = false;
                        errorMsg = `Failed to save number of facing due to: ${getErrorMessage(
                            e
                        )}`;
                    }
                }

                if (facingComplete) {
                    let planogramGroupComplete = true;
                    if (planogramGroupId && planogramGroupId >= -1) {
                        try {
                            // set id to null if user selected "Not Assigned" (i.e. unassign planogram group from retailer)
                            planogramGroupId =
                                planogramGroupId !== -1
                                    ? planogramGroupId
                                    : null;
                            await retailersApi.updatePlanogramGroup(
                                retailerId,
                                planogramGroupId
                            );
                        } catch (e) {
                            console.error(e);
                            planogramGroupComplete = false;
                            errorMsg = `Failed to save planogram group due to: ${getErrorMessage(
                                e
                            )}`;
                        }
                    }

                    if (planogramGroupComplete) {
                        dispatch(
                            success({
                                msg: 'Retailer was successfully updated.',
                                target: 'ReadRetailer',
                            })
                        );
                        dispatch(getRetailerById(retailerId));
                    } else {
                        dispatch({ type: RETAILER_ASSOCIATION_FAILED });
                        dispatch(
                            error({
                                msg:
                                    errorMsg ||
                                    'System error occurred while performing operation',
                                target: 'ReadRetailer',
                            })
                        );
                    }
                } else {
                    dispatch({ type: RETAILER_ASSOCIATION_FAILED });
                    dispatch(
                        error({
                            msg:
                                errorMsg ||
                                'System error occurred while performing operation',
                            target: 'ReadRetailer',
                        })
                    );
                }
            } else {
                dispatch({ type: RETAILER_ASSOCIATION_FAILED });
                dispatch(
                    error({
                        msg:
                            errorMsg ||
                            'System error occurred while performing operation',
                        target: 'ReadRetailer',
                    })
                );
            }
        } else {
            dispatch({ type: RETAILER_ASSOCIATION_FAILED });
            dispatch(
                error({
                    msg:
                        errorMsg ||
                        'System error occurred while performing operation',
                    target: 'ReadRetailer',
                })
            );
        }
    };
};

export const saveRetailerContacts = (retailerId, retailerContactObject) => {
    return async (dispatch, getState) => {
        let success = true;
        dispatch({ type: UPDATE_RETAILER_CONTACTS_PROCESSING });
        try {
            const { offline } = getState();
            if (offline.online) {
                await retailersApi.saveRetailerContacts(
                    retailerId,
                    retailerContactObject
                );
            } else {
                dispatch({
                    type: UPDATE_RETAILER_CONTACTS_SUCCESS,
                    payload: { retailerContactObject },
                    meta: {
                        offline: {
                            effect: {
                                method: 'put',
                                url: `/retailers/retailer-contacts-by-id/${retailerId}`,
                                body: retailerContactObject,
                            },
                            commit: {
                                type: NOTIFY_SUCCESS,
                                msg: 'Queued Retailer Contact was updated successfully',
                                target: 'ReadRetailer',
                            },
                            rollback: {
                                type: NOTIFY_ERROR,
                                msg: 'Queued Retailer Contact was not updated',
                                target: 'ReadRetailer',
                            },
                        },
                    },
                });
            }
        } catch (err) {
            console.error(err);
            success = false;
            dispatch(readRetailerErrorHandlerError(err));
        } finally {
            dispatch({ type: UPDATE_RETAILER_CONTACTS_DONE });
        }
        return success;
    };
};

export const clearRetailerState = () => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_INIT_STATE });
    };
};
