import moment from 'moment';

import { ReportsApi } from '../../../services/reports-service';
import { getErrorStatus, getErrorMessage } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { OrganizationApi } from '../../../services/organization-service';

export const SALES_YTD_LOADING = 'SALES_YTD_LOADING';
export const SALES_YTD = 'SALES_YTD';

export const SALE_SUMMARY_BY_GAME_FAIL = 'SALE_SUMMARY_BY_GAME_FAIL';
export const SALE_SUMMARY_BY_GAME_LOADING = 'SALE_SUMMARY_BY_GAME_LOADING';
export const SALE_SUMMARY_BY_GAME_SUCCESS = 'SALE_SUMMARY_BY_GAME_SUCCESS';

export const SALES_YTD_FOR_SALES_MANAGERS_FAIL =
    'SALES_YTD_FOR_SALES_MANAGERS_FAIL';
export const SALES_YTD_FOR_SALES_MANAGERS_LOADING =
    'SALES_YTD_FOR_SALES_MANAGERS_LOADING';
export const SALES_YTD_FOR_SALES_MANAGERS_SUCCESS =
    'SALES_YTD_FOR_SALES_MANAGERS_SUCCESS';

export const SALES_YTD_FOR_ROUTES_FAIL = 'SALES_YTD_FOR_ROUTES_FAIL';
export const SALES_YTD_FOR_ROUTES_LOADING = 'SALES_YTD_FOR_ROUTES_LOADING';
export const SALES_YTD_FOR_ROUTES_SUCCESS = 'SALES_YTD_FOR_ROUTES_SUCCESS';

const reportsApi = new ReportsApi();
const organizationApi = new OrganizationApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getYtdSalesData = (username) => {
    return async (dispatch) => {
        dispatch({ type: SALES_YTD_LOADING });

        try {
            const ytdSalesData = await reportsApi.getYtdSales(username);
            dispatch({
                type: SALES_YTD,
                ytdSalesData,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchSalesPerformanceSummary = (retailerReference) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SALE_SUMMARY_BY_GAME_LOADING });
            const {
                organization: { accountingWeek, fiscalDate },
            } = getState();
            const DATE_FORMAT = 'YYYY-MM-DD';

            const weekStart = !accountingWeek
                ? await organizationApi.getAccountingWeek()
                : accountingWeek;

            const today = moment().isoWeekday(); //1 - Monday, 7 - Sunday

            let daysAgo;
            if (weekStart < today) {
                daysAgo = today + 6;
            } else if (weekStart > today) {
                daysAgo = 14 - (weekStart - today);
            } else {
                daysAgo = 7;
            }

            const currentWeekStart = moment()
                .subtract(daysAgo, 'days')
                .format(DATE_FORMAT);
            const currentWeekEnd = moment(currentWeekStart)
                .add(6, 'days')
                .format(DATE_FORMAT);

            const previousWeekStart = moment()
                .subtract(daysAgo + 7, 'days')
                .format(DATE_FORMAT);
            const previousWeekEnd = moment(previousWeekStart)
                .add(6, 'days')
                .format(DATE_FORMAT);

            const currentYearStart = moment(fiscalDate).format(DATE_FORMAT);
            const currentYearEnd = moment().format(DATE_FORMAT);

            const previousYearStart = moment(fiscalDate)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const previousYearEnd = moment(currentYearEnd)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const data = await reportsApi.getSalesPerformanceSummary({
                retailerReference,
                currentWeekStart,
                currentWeekEnd,
                previousWeekStart,
                previousWeekEnd,
                currentYearStart,
                currentYearEnd,
                previousYearStart,
                previousYearEnd,
            });

            dispatch({
                type: SALE_SUMMARY_BY_GAME_SUCCESS,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: SALE_SUMMARY_BY_GAME_FAIL,
                error: getErrorMessage(e),
            });
        }
    };
};

export const fetchSalesPerformanceForSalesManagers = (username) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SALES_YTD_FOR_SALES_MANAGERS_LOADING });

            const fiscalDate = await organizationApi.getBusinessFiscalDate();
            const DATE_FORMAT = 'YYYY-MM-DD';

            const currentYearStart = moment(fiscalDate).format(DATE_FORMAT);
            const currentYearEnd = moment().format(DATE_FORMAT);

            const previousYearStart = moment(fiscalDate)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const previousYearEnd = moment(currentYearEnd)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const salesData =
                await reportsApi.getSalesPerformanceForSalesManagers({
                    currentYearStart,
                    currentYearEnd,
                    previousYearStart,
                    previousYearEnd,
                    username,
                });
            dispatch({
                type: SALES_YTD_FOR_SALES_MANAGERS_SUCCESS,
                salesData,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: SALES_YTD_FOR_SALES_MANAGERS_FAIL,
                error: getErrorMessage(e),
            });
        }
    };
};

export const fetchSalesPerformanceByRoute = (username) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SALES_YTD_FOR_ROUTES_LOADING });

            const fiscalDate = await organizationApi.getBusinessFiscalDate();
            const DATE_FORMAT = 'YYYY-MM-DD';

            const currentStartDate = moment(fiscalDate).format(DATE_FORMAT);
            const currentEndDate = moment().format(DATE_FORMAT);

            const previousStartDate = moment(fiscalDate)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const previousEndDate = moment(currentEndDate)
                .subtract(1, 'years')
                .format(DATE_FORMAT);

            const salesByRouteData =
                await reportsApi.getSalesPerformanceByRoute({
                    currentStartDate,
                    currentEndDate,
                    previousStartDate,
                    previousEndDate,
                    username,
                });
            dispatch({
                type: SALES_YTD_FOR_ROUTES_SUCCESS,
                salesByRouteData,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: SALES_YTD_FOR_ROUTES_FAIL,
                error: getErrorMessage(e),
            });
        }
    };
};
