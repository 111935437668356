import { GamesApi } from '../../services/games-service';

export const PRICE_POINTS_LOADING = 'PRICE_POINTS_LOADING';
export const PRICE_POINTS_LOADED = 'PRICE_POINTS_LOADED';

const gamesApi = new GamesApi();

export const getPricePoints = (args) => {
    return async (dispatch) => {
        dispatch({ type: PRICE_POINTS_LOADING });

        let list = await gamesApi.getPricePoints(args);

        dispatch({
            type: PRICE_POINTS_LOADED,
            list,
        });
    };
};
