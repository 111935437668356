import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import UneditableLabel from '../UneditableLabel';

import MaskInputFormField from '../maskInputFormField/MaskInputFormField';

const ReduxFormMaskInput = ({ label, ...otherProps }) => {
    return (
        <Form.Field>
            <div className="readonly">
                <UneditableLabel text={label} />
            </div>
            <Field component={MaskInputFormField} {...otherProps} />
        </Form.Field>
    );
};

export default ReduxFormMaskInput;
