import { MediaApi } from '../../../services/media-service';

import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { NOTIFY_ERROR, NOTIFY_SUCCESS } from '../index';

export const RETAILER_ATTACHMENT_INIT = 'RETAILER_ATTACHMENT_INIT';
export const RETAILER_ATTACHMENT_INIT_INIT_STATE =
    'RETAILER_ATTACHMENT_INIT_INIT_STATE';
export const RETAILER_ATTACHMENT_UPLOAD_LOADING =
    'RETAILER_ATTACHMENT_UPLOAD_LOADING';
export const RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE =
    'RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE';
export const RETAILER_ATTACHMENT_CREATE = 'RETAILER_ATTACHMENT_CREATE';

const mediaApi = new MediaApi();

const retailerUploadError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'ReadRetailer' })
        );
    };
};

export const initRetailerAttachmentUpload = (attachmentInfo) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING });

            const options = await mediaApi.getAttachmentCategories();

            //if have attachmentInfo its an update else its create
            if (attachmentInfo) {
                dispatch({
                    type: RETAILER_ATTACHMENT_INIT,
                    options,
                    attachment: attachmentInfo,
                });
            } else {
                dispatch({
                    type: RETAILER_ATTACHMENT_INIT,
                    options,
                });
            }
        } catch (err) {
            console.error(err);
            dispatch(retailerUploadError(err));
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE });
        }
    };
};

export const uploadRetailerAttachment = (formData, retailerId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING });
            await mediaApi.uploadRetailerAttachment(formData, retailerId);
        } catch (err) {
            dispatch(retailerUploadError(err));
            success = false;
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE });
        }

        return success;
    };
};

export const updateRetailerAttachment = (data) => {
    return async (dispatch, getState) => {
        const { offline } = getState();
        let success = true;
        try {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING });
            if (offline.online) {
                await mediaApi.updateAttachment(data);
            } else {
                let formData = new FormData();
                formData.append('description', data.description);
                formData.append('category', data.category);

                dispatch({
                    type: RETAILER_ATTACHMENT_CREATE,
                    payload: { formData },
                    meta: {
                        offline: {
                            effect: {
                                method: 'POST',
                                url: `/media/attachments/${data.id}`,
                                body: formData,
                            },
                            commit: {
                                type: NOTIFY_SUCCESS,
                                msg: 'Successfully updated queued attachment',
                                target: 'ReadRetailer',
                            },
                            rollback: {
                                type: NOTIFY_ERROR,
                                msg: 'Queued attachment was not updated',
                                target: 'ReadRetailer',
                            },
                        },
                    },
                });
            }
        } catch (err) {
            dispatch(retailerUploadError(err));
            success = false;
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE });
        }

        return success;
    };
};

export const deleteAttachment = (attachmentId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING });
            await mediaApi.deleteAttachment(attachmentId);
        } catch (err) {
            dispatch(retailerUploadError(err));
            success = false;
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE });
        }

        return success;
    };
};

export const initRetailerUploadState = () => ({
    type: RETAILER_ATTACHMENT_INIT_INIT_STATE,
});
