import './PNotificationModal.css';
import React, { Component } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export const NOTIFICATION_TYPE = {
    SUCCESS: 'SUCCESS',
    INFORMATION: 'INFORMATION',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
};

export const NOTIFICATION_SIZE = {
    FULLSCREEN: 'fullscreen',
    LARGE: 'large',
    MINI: 'mini',
    SMALL: 'small',
    TINY: 'tiny',
};

export const NOTIFICATION_ACTION_CONSEQUENCE = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    NEUTRAL: 'NEUTRAL',
};

export const NOTIFICATION_BUTTON_ALIGNMENT = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

const NOTIFICATION_HEADER = {
    SUCCESS: {
        iconClassName: 'icon-Success-2',
        headerClasName: 'p-notification-modal-header-success',
    },

    INFORMATION: {
        iconClassName: 'icon-Info',
        headerClasName: 'p-notification-modal-header-info',
    },

    WARNING: {
        iconClassName: 'icon-Info',
        headerClasName: 'p-notification-modal-header-warn',
    },

    ERROR: {
        iconClassName: 'icon-Error',
        headerClasName: 'p-notification-modal-header-error',
    },
};

export class PNotificationModal extends Component {
    componentDidMount() {
        const { suppressEnterKeySubmit, audioNotificationOnEnterKey } =
            this.props;

        if (!suppressEnterKeySubmit && this.primaryButton)
            this.primaryButton.focus();

        if (audioNotificationOnEnterKey) {
            document.addEventListener('keyPress', this.handleKeyDown, false);
        }
    }

    componentWillUnmount() {
        if (this.props.audioNotificationOnEnterKey)
            document.removeEventListener('keyPress', this.handleKeyDown, false);
    }

    createAndPlayAudio = (element) => {
        this.audioPlayer = element;
        this.playAudio();
    };

    playAudio = () => {
        if (this.audioPlayer) {
            this.audioPlayer.load();
            this.audioPlayer.play();
        }
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') this.playAudio();
    };

    getNotificationHeader = (
        notificationType,
        titleText,
        audioNotification
    ) => {
        let iconClassName, headerClasName;

        switch (notificationType) {
            case NOTIFICATION_TYPE.SUCCESS:
                iconClassName = NOTIFICATION_HEADER.SUCCESS.iconClassName;
                headerClasName = NOTIFICATION_HEADER.SUCCESS.headerClasName;
                break;

            case NOTIFICATION_TYPE.INFORMATION:
                iconClassName = NOTIFICATION_HEADER.INFORMATION.iconClassName;
                headerClasName = NOTIFICATION_HEADER.INFORMATION.headerClasName;
                break;

            case NOTIFICATION_TYPE.WARNING:
                iconClassName = NOTIFICATION_HEADER.WARNING.iconClassName;
                headerClasName = NOTIFICATION_HEADER.WARNING.headerClasName;
                break;

            case NOTIFICATION_TYPE.ERROR:
                iconClassName = NOTIFICATION_HEADER.ERROR.iconClassName;
                headerClasName = NOTIFICATION_HEADER.ERROR.headerClasName;
                break;

            default:
                iconClassName = NOTIFICATION_HEADER.INFORMATION.iconClassName;
                headerClasName = NOTIFICATION_HEADER.INFORMATION.headerClasName;
                break;
        }
        return (
            <Header className={headerClasName}>
                <Icon className={iconClassName} />
                <span>
                    {titleText}
                    <Icon
                        className={'icon-Audio'}
                        style={
                            audioNotification ? undefined : { display: 'none' }
                        }
                    />
                </span>
            </Header>
        );
    };

    render() {
        const {
            notificationType,
            titleText,
            notificationHeader,
            notificationText,
            notificationContent,
            notificationIsOpen,
            size,
            audioNotification,
            value = '',
            actionButtonAlignment = NOTIFICATION_BUTTON_ALIGNMENT.RIGHT,
            primaryButtonText = 'OK',
            onPrimaryButtonClick,
            primaryButtonStyle,
            primaryActionConsequence = NOTIFICATION_ACTION_CONSEQUENCE.POSITIVE,
            secondaryButtonText = 'Cancel',
            onSecondaryButtonClick,
            secondaryButtonStyle,
            secondaryActionConsequence = NOTIFICATION_ACTION_CONSEQUENCE.NEGATIVE,
            tertiaryButtonText,
            onTertiaryButtonClick,
            tertiaryButtonStyle,
            tertiaryActionConsequence = NOTIFICATION_ACTION_CONSEQUENCE.NEUTRAL,
            autoFocusPrimary = false,
            autoFocusSecondary = false,
            autoFocusTertiary = false,
        } = this.props;

        const modalActions = [
            secondaryButtonText && onSecondaryButtonClick ? (
                <Button
                    key={secondaryButtonText}
                    content={secondaryButtonText}
                    onClick={(event) => onSecondaryButtonClick(event, value)}
                    style={primaryButtonStyle ? primaryButtonStyle : null}
                    positive={
                        secondaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.POSITIVE
                    }
                    negative={
                        secondaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.NEGATIVE
                    }
                    autoFocus={autoFocusSecondary}
                />
            ) : null,
            tertiaryButtonText && onTertiaryButtonClick ? (
                <Button
                    key={tertiaryButtonText}
                    content={tertiaryButtonText}
                    onClick={(event) => onTertiaryButtonClick(event, value)}
                    style={tertiaryButtonStyle ? tertiaryButtonStyle : null}
                    positive={
                        tertiaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.POSITIVE
                    }
                    negative={
                        tertiaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.NEGATIVE
                    }
                    autoFocus={autoFocusTertiary}
                />
            ) : null,
            primaryButtonText && onPrimaryButtonClick ? (
                <Button
                    key={primaryButtonText}
                    content={primaryButtonText}
                    onClick={(event) => onPrimaryButtonClick(event, value)}
                    style={primaryButtonStyle ? secondaryButtonStyle : null}
                    positive={
                        primaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.POSITIVE
                    }
                    negative={
                        primaryActionConsequence ===
                        NOTIFICATION_ACTION_CONSEQUENCE.NEGATIVE
                    }
                    ref={(element) => (this.primaryButton = element)}
                    autoFocus={autoFocusPrimary}
                />
            ) : null,
        ];

        return (
            <Modal
                className="p-notification-modal"
                open={notificationIsOpen}
                size={size}
                tabIndex={-1}
                ref={(element) => (this.modal = element)}
                onKeyDown={this.handleKeyDown}
            >
                {this.getNotificationHeader(
                    notificationType,
                    titleText,
                    audioNotification
                )}
                {audioNotification ? (
                    <audio preload={'auto'} ref={this.createAndPlayAudio}>
                        <source src={audioNotification} />
                    </audio>
                ) : null}
                <Modal.Content>
                    {notificationHeader ? (
                        <Header as="h2" className="content-header">
                            {notificationHeader}
                        </Header>
                    ) : null}
                    {notificationText ? (
                        <span
                            className="content-text"
                            style={{ whiteSpace: 'pre-wrap' }}
                        >
                            {notificationText}
                        </span>
                    ) : null}
                    {notificationContent}
                </Modal.Content>
                <Modal.Actions
                    style={{ textAlign: actionButtonAlignment }}
                    actions={modalActions}
                />
            </Modal>
        );
    }
}
