import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

import UneditableLabel from '../UneditableLabel';
import './styles.css';

const SfaToggle = ({
    labelText,
    toggleValue,
    toggleName,
    handleChange,
    checked,
    checkedValue,
}) => {
    const label1 = checked && checkedValue ? 'YES' : 'NO';
    const label2 = checked && !checkedValue ? 'ACTIVE' : 'INACTIVE';

    return (
        <Form.Field>
            <div className="readonly">
                <UneditableLabel text={labelText} />
            </div>
            <Checkbox
                toggle
                name={toggleName}
                value={toggleValue}
                onChange={handleChange}
                checked={checked ? checked : false}
                label={checkedValue ? label1 : label2}
            />
        </Form.Field>
    );
};

export default SfaToggle;
