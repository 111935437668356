import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getServerTime } from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import NoItems from '../../../../components/NoItems';
import Widget from '../Widget';
import { Icon } from 'semantic-ui-react';
import './styles.css';
import { formatToDisplayDateTime } from '../../../../constants/utils';
import _ from 'lodash';
import classNames from 'classnames';

export class SystemTimeWidget extends Component {
    async componentDidMount() {
        this.getTimes();
    }

    getTimes = () => {
        const { getServerTime } = this.props;
        getServerTime();
    };

    render() {
        const { loading, serverTimes } = this.props;
        let content = null;
        if (!serverTimes) {
            content = (
                <div className="no-sales-targets">
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no service time!"
                    />
                </div>
            );
        } else {
            const widgetContent = _.map(serverTimes, (value, key) => ({
                serviceName: key + ':',
                value: value,
            }));
            content = (
                <div className="server-time-widget-content">
                    <Table
                        headClassName="progress-to-target-header service-widget"
                        contentKey="serviceName"
                        content={widgetContent}
                        totalElements={widgetContent.length}
                        supressSearch={true}
                        columns={[
                            {
                                name: 'serviceName',
                                columnClassName: () => 'System-time-dir-col',
                            },
                            {
                                name: 'value',
                                formatter: (value) =>
                                    value === 'NOT AVAILABLE'
                                        ? value
                                        : formatToDisplayDateTime(value),
                                columnClassName: (value) =>
                                    classNames(
                                        { error: value === 'NOT AVAILABLE' },
                                        'System-time-right-col'
                                    ),
                            },
                        ]}
                    />
                </div>
            );
        }
        return (
            <Widget loading={loading} data-test="systemTimeWidget">
                <div className="bold widget-heading">
                    Server Time
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.getTimes} />
                    </div>
                </div>
                {content}
            </Widget>
        );
    }
}

const mapStateToProps = ({ serverTime: { serverTimes, loading } }) => {
    return { loading, serverTimes };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getServerTime: () => dispatch(getServerTime()),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SystemTimeWidget)
);
