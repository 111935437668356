export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const INIT_ACTIVE_USER = 'INIT_ACTIVE_USER';

export const setActiveUser = (userInfo) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({
            type: SET_ACTIVE_USER,
            isOtherUser: userInfo ? userInfo.id !== auth.info.user_id : false,
            userInfo,
        });
    };
};

export const initActiveUser = () => {
    return (dispatch) => {
        dispatch({ type: INIT_ACTIVE_USER });
    };
};
