import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import WebFont from 'webfontloader';

import config from './constants/config';
import './index.css';
import './assets/polyfills';
import App from './App';
import 'semantic-ui-less/semantic.less';
import 'core-js/es6/map';
import 'core-js/es6/set';

axios.defaults.baseURL = config.BASE_URL;

WebFont.load({
    google: {
        families: ['Open Sans:300,400,600,700,800', 'sans-serif'],
    },
    custom: {
        families: ['icomoon', 'Open Sans'],
        urls: ['/icon-fonts.css', '/open-sans.css'],
    },
});

ReactDOM.render(<App />, document.getElementById('root'));
