import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import {
    getMarketingSurveyTypeFromRedux,
    getSurveyTypes,
} from '../survey/survey';

export const MARKETING_UPDATE_SUCCESS = 'MARKETING_UPDATE_SUCCESS';
export const MARKETING_GET_SUCCESS = 'MARKETING_GET_SUCCESS';
export const MARKETING_UPDATE_INIT = 'MARKETING_UPDATE_INIT';
export const MARKETING_UPDATE_PAGE_LOADING = 'MARKETING_UPDATE_PAGE_LOADING';
export const MARKETING_UPDATE_PAGE_DONE = 'MARKETING_UPDATE_PAGE_DONE';

const surveyApi = new SurveyApi();

const updateMarketingError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'UpdateMarketingSurvey',
            })
        );
    };
};

export const getMarketingSurvey = (surveyId) => {
    return async (dispatch) => {
        try {
            //get the data
            const surveyData = await surveyApi.getSurvey(surveyId);

            //get questions same format as form so can init the form properly
            let formattedData = {
                id: surveyId,
                name: surveyData.name,
                description: surveyData.description,
                questions: surveyData.questions,
                status: surveyData.status,
                originalStatus: surveyData.status,
            };

            for (let i = 0; i < surveyData.questions.length; i++) {
                const question = surveyData.questions[i];

                const questionTypeFieldName = `question-type-${i}`;
                formattedData[`question-question-${i}`] = question.text;
                formattedData[`question-id-${i}`] = question.id;
                formattedData[`question-sub-label-${i}`] =
                    question.subText || null;
                formattedData[questionTypeFieldName] =
                    question.questionType || null;

                if (question.options) {
                    const optionsJson = JSON.parse(question.options);
                    for (let j = 0; j < optionsJson.length; j++) {
                        const option = optionsJson[j];

                        formattedData[
                            `${questionTypeFieldName}-combo-option-value-${j}`
                        ] = option.value;
                        formattedData[
                            `${questionTypeFieldName}-combo-option-response-${j}`
                        ] = option.text;
                    }
                    formattedData[
                        `${questionTypeFieldName}-total-num-options`
                    ] = optionsJson.length;
                }
            }
            dispatch({
                type: MARKETING_GET_SUCCESS,
                marketingObject: { ...formattedData },
            });
        } catch (e) {
            dispatch(updateMarketingError(e));
        }
    };
};

export const updateMarketingSurvey = (marketingSurveyToUpdate) => {
    return async (dispatch, getState) => {
        let success = true;
        try {
            dispatch({ type: MARKETING_UPDATE_PAGE_LOADING });
            const { survey } = getState();
            //get survey type id for compliance
            const marketingType = getMarketingSurveyTypeFromRedux(survey);

            //must update questions first in case survey status changes
            //if original status is draft do the update only
            if (marketingSurveyToUpdate.originalStatus === 'DRAFT') {
                await surveyApi.updateBulkSurveyQuestions(
                    marketingSurveyToUpdate.id,
                    marketingSurveyToUpdate.marketingQuestions
                );
            }

            const surveyPayload = {
                surveyType: marketingType.id,
                id: marketingSurveyToUpdate.id,
                name: marketingSurveyToUpdate.name,
                description: marketingSurveyToUpdate.description,
                status: marketingSurveyToUpdate.status,
            };

            await surveyApi.updateSurvey(surveyPayload);
            dispatch({
                type: MARKETING_UPDATE_INIT,
            });
        } catch (err) {
            console.error(err);
            dispatch(updateMarketingError(err));
            success = false;
        } finally {
            dispatch({ type: MARKETING_UPDATE_PAGE_DONE });
        }

        return success;
    };
};

export const initUpdateMarketingSurvey = () => {
    return async (dispatch) => {
        dispatch(getSurveyTypes());
        dispatch({ type: MARKETING_UPDATE_INIT });
    };
};
