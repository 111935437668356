import {
    TODAY_PLAN_WIDGET_LOADING,
    TODAY_PLAN_WIDGET_SUCCESS,
} from '../../../actions/dashboard/widgets/todayPlanWidget';

const INITIAL_STATE = {
    loading: true,
    pastDueTasksCount: 0,
    scheduledTasksCount: 0,
    retailerVisitsCount: 0,
    adHocTasksCount: 0,
};

const todayPlanWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TODAY_PLAN_WIDGET_LOADING:
            return { ...state, loading: action.loading };
        case TODAY_PLAN_WIDGET_SUCCESS:
            return {
                ...state,
                pastDueTasksCount: action.pastDueTasksCount,
                scheduledTasksCount: action.scheduledTasksCount,
                retailerVisitsCount: action.retailerVisitsCount,
                adHocTasksCount: action.adHocTasksCount,
                loading: false,
            };
        default:
            return state;
    }
};
export default todayPlanWidget;
