import { TaskApi } from '../../../services/task-service';
import { UserApi } from '../../../services/user-service';
import { RetailersApi } from '../../../services/retailers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { SalesRoutesApi } from '../../../services/sales-routes-service';
import { ZonesApi } from '../../../services/zones-service';
import { CorpAccountsApi } from '../../../services/corp-accounts-service';
import _ from 'lodash';
import { SurveyApi } from '../../../services/survey-service';

export const INIT_UPDATE_TASK = 'INIT_UPDATE_TASK';
export const FETCH_TASK_OPTIONS = 'FETCH_TASK_OPTIONS';
export const UPDATE_TASK_PROCESSING = 'UPDATE_TASK_PROCESSING';
export const GET_TASK_TO_UPDATE_SUCCESS = 'GET_TASK_TO_UPDATE_SUCCESS';
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS';
export const TASK_UPDATE_FAILURE = 'TASK_UPDATE_FAILURE';
export const UPDATE_TASK_VALIDATE = 'UPDATE_TASK_VALIDATE';
export const UPDATE_TASK_LOADING = 'UPDATE_TASK_LOADING';
export const UPDATE_TASK_LOADING_DONE = 'UPDATE_TASK_LOADING_DONE';

const taskApi = new TaskApi();
const userApi = new UserApi();
const retailersApi = new RetailersApi();
const salesRouteApi = new SalesRoutesApi();
const zonesApi = new ZonesApi();
const corpAccountsApi = new CorpAccountsApi();
const surveyApi = new SurveyApi();

const updateTaskError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'UpdateTask' }));
    };
};

export const initUpdateTask = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_UPDATE_TASK });
    };
};

export const fetchTaskOptions = () => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_TASK_PROCESSING });
        const taskOptions = await taskApi.getTaskOptions();

        //modify verbage for ACTIVE and scheduled to match design
        const statusOptions = _.map(taskOptions.statuses, (i) => {
            if (i.id === 'ACTIVE') {
                i.description = 'Activate Now';
            } else if (i.id === 'SCHEDULED') {
                i.description = 'Scheduled Activation';
            }
            return i;
        });

        dispatch({
            type: FETCH_TASK_OPTIONS,
            taskOptions: {
                ...taskOptions,
                statuses: statusOptions,
            },
        });
    };
};

export const getTaskToUpdate = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: UPDATE_TASK_LOADING });
            const taskToUpdate = await taskApi.getTaskToUpdate(id);
            taskToUpdate.retailers = [];
            taskToUpdate.users = [];
            taskToUpdate.salesRoutes = [];
            taskToUpdate.zones = [];
            taskToUpdate.corpAccounts = [];

            if (
                taskToUpdate.category &&
                (taskToUpdate.category === 'COMPLIANCE' ||
                    taskToUpdate.category === 'MARKETING')
            ) {
                taskToUpdate.taskCategory = taskToUpdate.category;
                if (taskToUpdate.surveyId) {
                    taskToUpdate.survey = await surveyApi.getSurvey(
                        taskToUpdate.surveyId
                    );
                }
            }

            if (
                taskToUpdate.taskAssignments &&
                taskToUpdate.taskAssignments.length > 0
            ) {
                switch (taskToUpdate.taskAssignments[0].assignmentType) {
                    case 'RETAILER': {
                        taskToUpdate.retailers = _.orderBy(
                            await Promise.all(
                                _.map(
                                    taskToUpdate.taskAssignments,
                                    async (ta) =>
                                        await retailersApi.getRetailerById(
                                            ta.assignmentId
                                        )
                                )
                            ),
                            '[name]'
                        );
                        break;
                    }
                    case 'USER': {
                        taskToUpdate.users = _.orderBy(
                            await Promise.all(
                                _.map(
                                    taskToUpdate.taskAssignments,
                                    async (ta) => {
                                        let user = await userApi.getUserByID(
                                            ta.assignmentId
                                        );
                                        user.fullName = `${user.firstName} ${user.lastName}`;
                                        return user;
                                    }
                                )
                            ),
                            '[fullname]'
                        );
                        break;
                    }
                    case 'SALES_ROUTE': {
                        taskToUpdate.salesRoutes = _.orderBy(
                            await Promise.all(
                                _.map(
                                    taskToUpdate.taskAssignments,
                                    async (ta) =>
                                        await salesRouteApi.getSalesRouteById(
                                            ta.assignmentId
                                        )
                                )
                            ),
                            '[name]'
                        );
                        break;
                    }
                    case 'ACCOUNT': {
                        taskToUpdate.corpAccounts = _.orderBy(
                            await Promise.all(
                                _.map(
                                    taskToUpdate.taskAssignments,
                                    async (ta) =>
                                        await corpAccountsApi.getCorpAccountById(
                                            ta.assignmentId
                                        )
                                )
                            ),
                            '[name]'
                        );
                        break;
                    }
                    case 'ZONE': {
                        taskToUpdate.zones = _.orderBy(
                            await Promise.all(
                                _.map(
                                    taskToUpdate.taskAssignments,
                                    async (ta) =>
                                        await zonesApi.getZoneById(
                                            ta.assignmentId
                                        )
                                )
                            ),
                            '[name]'
                        );
                        break;
                    }
                    case 'ALL_RETAILERS': {
                        break;
                    }
                    default: {
                        throw new Error(
                            `Assignment Type ${taskToUpdate.taskAssignments[0].assignmentType} is unknown`
                        );
                    }
                }
            }
            const {
                auth: { info },
            } = getState();

            dispatch({
                type: GET_TASK_TO_UPDATE_SUCCESS,
                taskToUpdate: {
                    title: taskToUpdate.title,
                    description: taskToUpdate.description,
                    taskCategory: taskToUpdate.category,
                    priority: taskToUpdate.priority,
                    taskAssignments: taskToUpdate.taskAssignments, //need to keep this for now so on save it will update properly
                    assignee:
                        taskToUpdate.taskAssignments[0].assignmentType ===
                            'USER' &&
                        taskToUpdate.taskAssignments[0].assignmentId ===
                            info.user_id
                            ? 'MYSELF'
                            : taskToUpdate.taskAssignments[0].assignmentType,
                    schedule: taskToUpdate.taskSchedule.recurrenceType,
                    taskSchedule: taskToUpdate.taskSchedule, //also keep for update
                    responseDueDate: taskToUpdate.taskSchedule.dueDate,
                    status: taskToUpdate.status,
                    createdBy: taskToUpdate.createdBy,
                    retailers: taskToUpdate.retailers,
                    users: taskToUpdate.users,
                    salesRoutes: taskToUpdate.salesRoutes,
                    corpAccounts: taskToUpdate.corpAccounts,
                    zones: taskToUpdate.zones,
                    startDate: taskToUpdate.taskSchedule.startDate,
                    endDate: taskToUpdate.taskSchedule.endDate,
                    survey: taskToUpdate.survey,
                    includePlanogram: taskToUpdate.includePlanogram,
                    hotTopic: taskToUpdate.hotTopic,
                },
            });
        } catch (err) {
            dispatch(updateTaskError(err));
        } finally {
            dispatch({ type: UPDATE_TASK_LOADING_DONE });
        }
    };
};

export const updateTask = (id, taskToUpdate) => {
    return async (dispatch) => {
        let success = true;

        try {
            dispatch({ type: UPDATE_TASK_LOADING });
            await taskApi.updateTask(id, taskToUpdate);
            dispatch({
                type: TASK_UPDATE_SUCCESS,
            });
        } catch (err) {
            success = false;
            dispatch(updateTaskError(err));
        } finally {
            dispatch({ type: UPDATE_TASK_LOADING_DONE });
        }

        return success;
    };
};

export const deleteTask = (id) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: UPDATE_TASK_LOADING });
            await taskApi.deleteTask(id);
        } catch (err) {
            success = false;
            dispatch(updateTaskError(err));
        } finally {
            dispatch({ type: UPDATE_TASK_LOADING_DONE });
        }

        return success;
    };
};

export const setUpdateTaskPageLoading = () => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_TASK_LOADING });
    };
};

export const setUpdateTaskPageLoadingDone = () => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_TASK_LOADING_DONE });
    };
};
