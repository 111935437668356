import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Button, Dropdown } from 'semantic-ui-react';
import { map, sortBy, sumBy } from 'lodash';
import Page from '../../layout/Page';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import { formatMoney, formatToDisplayDate } from '../../constants/utils';
import { ShipmentHeader } from '../shipments/shipment-header';
import _ from 'lodash';

class OrderDetailWithShipments extends Component {
    gotoCourierUrl = (trackingNumber, trackingUrl) =>
        window.open(`${trackingUrl}${trackingNumber}`, '_blank');
    getDownloadButton = () => {
        const { downloadOrder } = this.props;
        return (
            <Button primary onClick={downloadOrder}>
                <span>
                    Download Order Items <span className="icon-Download" />
                </span>
            </Button>
        );
    };

    render() {
        const {
            order: {
                orderDate,
                status,
                externalOrderNumber,
                shipments,
                orderDetails,
            },
            loading,
            gotoOrderHistory,
        } = this.props;

        const totalCost = sumBy(orderDetails, 'cost');
        let orderStatusByShipment = undefined;
        const totalShipments = _.size(shipments);
        if (totalShipments > 0) {
            if (
                totalShipments ===
                _.size(
                    _.filter(
                        shipments,
                        (shipment) => shipment.status?.code === 'CANCL'
                    )
                )
            ) {
                orderStatusByShipment = 'Canceled';
            } else if (
                totalShipments ===
                _.size(
                    _.filter(
                        shipments,
                        (shipment) =>
                            shipment.received ||
                            shipment.inTransit ||
                            shipment.status?.code === 'CANCL'
                    )
                )
            ) {
                orderStatusByShipment = 'Processed';
            } else {
                orderStatusByShipment = 'In Shipment';
            }
        }
        return (
            <Page
                title={`Order #${externalOrderNumber || ''}`}
                breadCrumbComponent={
                    <BreadCrumb
                        location={'Orders'} //Localized in breadCrumb component
                        onClick={gotoOrderHistory}
                    />
                }
                className="order-detail-page"
            >
                <div className="content">
                    <Dimmer page active={loading}>
                        <Loader />
                    </Dimmer>
                    <div className="order-info-wrapper">
                        <div className="order-info">
                            <div className="info text-align-s">
                                <div className="info-cell">
                                    <div>Order Placed On</div>
                                    <div className="bold">
                                        {formatToDisplayDate(orderDate)}
                                    </div>
                                </div>
                                <div className="info-cell">
                                    <div>Status</div>
                                    <div className="bold">
                                        {orderStatusByShipment
                                            ? orderStatusByShipment
                                            : status.description}
                                    </div>
                                </div>
                                <div className="info-cell min-desktop">
                                    <div>Order No #</div>
                                    <div className="bold">
                                        {externalOrderNumber}
                                    </div>
                                </div>
                                <div className="info-cell">
                                    <div>Cost</div>
                                    <div className="bold">
                                        {formatMoney(totalCost)}
                                    </div>
                                </div>
                                <div className="info-cell  min-desktop">
                                    {this.getDownloadButton()}
                                </div>
                                <div className={'max-tablet static-options'}>
                                    <Dropdown
                                        item
                                        icon="ellipsis vertical"
                                        floating
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                {this.getDownloadButton()}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="shipment-details">
                            {map(
                                sortBy(shipments, 'externalShipmentNumber'),
                                (shipment) => (
                                    <ShipmentHeader
                                        shipment={shipment}
                                        showDetail={true}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ orderDetail: { loading, order } }) => {
    return {
        loading,
        order,
    };
};

export default connect(mapStateToProps, null)(OrderDetailWithShipments);
