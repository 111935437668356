import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map } from 'lodash';

import Table from '../../components/datatable/Table';
import {
    fetchUsers,
    loadRolesFilter,
    initCreateUser,
    clearNotification,
} from '../../redux/actions';
import FilterSection from '../../components/filter/FilterSection';
import {
    addSelectedItem,
    clearFilters,
    shouldApplyFilters,
} from '../../components/filter/actions';
import {
    clearSortDetails,
    updateSortDetails,
} from '../../components/datatable/actions';
import config from '../../constants/config';
import Page from '../../layout/Page';
import { selectItemsToFilter } from '../../constants/utils';
import './styles.css';

const TOTAL_PER_PAGE = 14;

const DEFAULT_USER_FILTER = {
    property: 'status',
    data: ['ACTIVE'],
};
const IS_RETAILER_PORTAL_USER = {
    property: 'isRetailerPortal',
    data: ['true'],
};
export class UserPage extends Component {
    state = {
        defaultFilterItems: {
            status: 'ACTIVE',
            roles: '',
            useDefault: true,
        },
    };

    componentDidMount() {
        const {
            selectedItems,
            onFetchRoles,
            onSelectFilterItem,
            updateSortDetails,
            shouldApplyFilters,
        } = this.props;

        onFetchRoles();
        onSelectFilterItem('status', this.state.defaultFilterItems.status);
        shouldApplyFilters();

        let filters = selectItemsToFilter(selectedItems);

        updateSortDetails({
            sortColumnName: 'username',
            ascending: true,
        });

        if (filters.length <= 0) {
            filters.push(DEFAULT_USER_FILTER, IS_RETAILER_PORTAL_USER);
        }

        this.searchUsers({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters,
        });
    }

    componentWillUnmount() {
        this.props.clearNotifications();
        this.props.clearFilters();
        this.props.clearSortDetails();
    }

    handleCreatePress = () => {
        this.props.initCreateUser();
        const { CREATE_USER } = config.ROUTE_URLS;
        this.props.history.push(CREATE_USER);
    };

    searchUsers = (args) =>
        this.props.onFetchUsers({
            ...args,
            filters: [
                ...args.filters,
                {
                    property: 'isRetailerPortal',
                    data: ['true'],
                },
            ],
        });

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        const { auth, selectedItems } = this.props;
        const filters = selectItemsToFilter(selectedItems);

        this.searchUsers({
            token: auth.token,
            page: page <= 0 ? 0 : page - 1,
            size,
            sortOrder,
            sortColumn,
            filters,
            query,
        });
    };

    handleFilterApply = (selectedItems, query) => {
        let filters = selectItemsToFilter(selectedItems);
        this.setState({ defaultFilterItems: { useDefault: false } });

        this.searchUsers({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters,
            query,
        });
    };

    handleClearFilters = (selectedItems, query) => {
        this.searchUsers({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters: [DEFAULT_USER_FILTER, IS_RETAILER_PORTAL_USER],
        });

        this.setState({ defaultFilterItems: { useDefault: false } });
    };

    handleSearch = (query) => {
        const { selectedItems } = this.props;
        let filters = selectItemsToFilter(selectedItems);

        this.searchUsers({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters,
            query,
        });
    };
    getUserRetailerText = (val, { userRetailers }) => {
        if (!userRetailers || userRetailers.length === 0) {
            return '';
        } else if (userRetailers.length === 1) {
            return `${userRetailers[0].userRetailerId.retailerReference} - ${userRetailers[0].retailerName}`;
        }
        return 'Multiple';
    };

    render() {
        const {
            content,
            totalElements,
            totalPages,
            size,
            number,
            loading,
            roles,
        } = this.props;

        return (
            <Page
                name="userPage"
                title="User Management"
                data-test="usersPage"
                onAddButtonClick={this.handleCreatePress}
                addButtonRoles={['ROLE_RE_USERS_CREATE']}
            >
                <FilterSection
                    filters={[
                        {
                            label: 'Status: ',
                            name: 'status',
                            multiple: true,
                            toggleAll: true,
                            client: true,
                            searchable: true,
                            items: [
                                { text: 'Active', value: 'ACTIVE' },
                                { text: 'Inactive', value: 'INACTIVE' },
                            ],
                            defaultSelected: this.state.defaultFilterItems
                                .useDefault
                                ? this.state.defaultFilterItems.status
                                : '',
                        },
                        {
                            label: 'Roles: ',
                            name: 'roles',
                            multiple: true,
                            toggleAll: true,
                            client: true,
                            searchable: true,
                            items: map(roles, ({ name, displayName }) => ({
                                text: displayName,
                                value: name,
                            })),
                            defaultSelected: this.state.defaultFilterItems
                                .useDefault
                                ? this.state.defaultFilterItems.roles
                                : '',
                        },
                    ]}
                    onApplyFilter={this.handleFilterApply}
                    onClearFilters={this.handleClearFilters}
                />

                <div className="data-section">
                    <Table
                        pagination
                        loading={loading}
                        content={content}
                        contentKey="id"
                        totalPages={totalPages}
                        totalElements={totalElements}
                        size={size}
                        page={number}
                        selector={{
                            variables: ['username'],
                            path: '/user/update',
                            callback: (p) => this.props.history.push(p),
                        }}
                        columns={[
                            {
                                name: 'username',
                                title: 'User Name',
                                sortable: true,
                                searchable: true,
                            },
                            {
                                name: 'firstName',
                                title: 'First Name',
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'lastName',
                                title: 'Last Name',
                                searchable: true,
                                sortable: true,
                            },
                            { name: 'email', title: 'Email', sortable: true },
                            {
                                name: 'roles.displayName',
                                title: 'User Roles',
                                sortable: true,
                                formatter: (val, { roles }) => {
                                    const displayNames = [];
                                    [].forEach.call(roles, (role) => {
                                        displayNames.push(role.displayName);
                                    });

                                    return displayNames
                                        ? displayNames.join(' ,')
                                        : '';
                                },
                            },
                            {
                                name: 'userRetailers',
                                title: 'Retailer',
                                sortable: true,
                                formatter: this.getUserRetailerText,
                                tooltip: this.getUserRetailerText,
                            },
                            {
                                name: 'enabled',
                                title: 'Status',
                                sortable: true,
                                capitalize: true,
                                formatter: (val) =>
                                    val ? 'Active' : 'Inactive',
                            },
                        ]}
                        fetchData={this.handleSelectTablePage}
                        onSearch={this.handleSearch}
                        noDataMessage="There are no users"
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ listUsers, auth, filterOptions, tableFilter }) => {
    const { content, totalElements, totalPages, size, number, loading } =
        listUsers;
    const { roles } = filterOptions;
    const { selectedItems } = tableFilter;
    return {
        auth,
        content,
        totalElements,
        totalPages,
        size,
        number,
        loading,
        roles,
        selectedItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUsers: (args) => dispatch(fetchUsers(args)),
        onFetchRoles: () => dispatch(loadRolesFilter()),
        initCreateUser: () => dispatch(initCreateUser()),
        clearNotifications: () => dispatch(clearNotification()),
        onSelectFilterItem: (filterName, item) =>
            dispatch(addSelectedItem(filterName, item)),
        clearFilters: () => dispatch(clearFilters()),
        updateSortDetails: (args) => dispatch(updateSortDetails(args)),
        shouldApplyFilters: () => dispatch(shouldApplyFilters()),
        clearSortDetails: () => dispatch(clearSortDetails()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserPage)
);
