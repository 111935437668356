import {
    SURVEY_INIT,
    SURVEY_GET_TYPES,
    SURVEY_LOADING,
    SURVEY_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    types: [],
};

const survey = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SURVEY_LOADING:
            return { ...state, loading: true };
        case SURVEY_LOADING_DONE:
            return { ...state, loading: false };
        case SURVEY_GET_TYPES:
            return { ...state, types: [...action.surveyTypes] };
        case SURVEY_INIT:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default survey;
