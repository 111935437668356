import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Grid, Button } from 'semantic-ui-react';
import Table from '../../components/datatable/Table';

import FormFooter from '../formFooter/FormFooter';
import config from '../../constants/config';

import './styles.css';
import SfaTransition from '../SfaTransition';
import { find } from 'lodash';
import Localize from '../../constants/i18n-utils';
import { connect } from 'react-redux';
import { formatToDisplayDateTime } from '../../constants/utils';

const animationLen = config.ANIMATION_LENGTH;

class DocumentWysiwygModal extends Component {
    cancel = () => {
        this.props.hideModal();
    };

    returnSelectedDocument = (id) => {
        const {
            documentUpload: { content },
        } = this.props;
        const selectedDocument = find(content, { id: id });
        this.props.getUrl(selectedDocument);
        this.cancel();
    };

    render() {
        const {
            isModalOpen,
            loading,
            documentUpload: {
                content,
                totalElements,
                totalPages,
                size,
                number,
            },
        } = this.props;

        return (
            <SfaTransition animation="fade up" duration={animationLen}>
                <Modal
                    open={isModalOpen}
                    closeIcon
                    onClose={this.cancel}
                    size="fullscreen"
                >
                    <Modal.Content>
                        <Modal.Header>
                            {Localize.text(
                                'documentWysiwygModal.document.heading',
                                'Available Uploaded Documents'
                            )}
                        </Modal.Header>
                        <div>
                            <div className="data-display-content">
                                <Table
                                    pagination
                                    loading={loading}
                                    content={content}
                                    contentKey="id"
                                    totalPages={totalPages}
                                    totalElements={totalElements}
                                    size={size}
                                    page={number}
                                    selector={{
                                        type: 'callbackWithId',
                                        callback: (id) =>
                                            this.returnSelectedDocument(id),
                                    }}
                                    columns={[
                                        {
                                            name: 'description',
                                            title: 'Description',
                                            sortable: true,
                                            searchable: true,
                                            tooltip: 'Click to get url',
                                        },
                                        {
                                            name: 'fileName',
                                            title: 'File Name',
                                            sortable: true,
                                            searchable: true,
                                        },
                                        {
                                            name: 'category.name',
                                            title: 'Category',
                                            sortable: true,
                                            searchable: true,
                                        },
                                        {
                                            name: 'modifiedDate',
                                            title: 'Last Modified',
                                            sortable: true,
                                            formatter: (val) =>
                                                val
                                                    ? formatToDisplayDateTime(
                                                          val
                                                      )
                                                    : '-',
                                        },
                                        {
                                            name: 'createdDate',
                                            title: 'Create Date',
                                            sortable: true,
                                            formatter: (val) =>
                                                val
                                                    ? formatToDisplayDateTime(
                                                          val
                                                      )
                                                    : '-',
                                        },
                                    ]}
                                    fetchData={this.props.handleSelectTablePage}
                                    onSearch={this.props.handleSearch}
                                    noDataMessage={Localize.text(
                                        'documentWysiwygModal.document.noData',
                                        'There are no documents'
                                    )}
                                />
                            </div>
                        </div>
                        <FormFooter>
                            <Grid.Column
                                className="nopadding modal-actions-wrapper"
                                floated="right"
                                verticalAlign="bottom"
                                textAlign="right"
                            >
                                <Form.Field>
                                    <Button
                                        className="white-text"
                                        primary
                                        onClick={this.cancel}
                                    >
                                        {Localize.text(
                                            'documentWysiwygModal.close',
                                            'Close'
                                        )}
                                    </Button>
                                </Form.Field>
                            </Grid.Column>
                        </FormFooter>
                    </Modal.Content>
                </Modal>
            </SfaTransition>
        );
    }
}

DocumentWysiwygModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleSelectTablePage: PropTypes.func.isRequired,
    getUrl: PropTypes.func.isRequired,
};

export default connect(null, null)(DocumentWysiwygModal);
