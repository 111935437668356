import _ from 'lodash';
import { TaskApi } from '../../../services/task-service';
import { RetailersApi } from '../../../services/retailers-service';
import { _getSurveyForTasks } from '../weeklyScheduler';
import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

export const PASTDUE_TASKS = 'PASTDUE_TASKS';
export const PASTDUE_TASKS_LOADING = 'PASTDUE_TASKS_LOADING';

const taskApi = new TaskApi();
const retailersApi = new RetailersApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchPastDueTasks = (date) => {
    return async (dispatch) => {
        let content = [];
        try {
            dispatch({ type: PASTDUE_TASKS_LOADING });
            let pastDueTasks = await taskApi.getMyPastDueTasks(date);

            // find retailers for tasks
            const pastDueRetailers = [];
            pastDueTasks.forEach((task) => {
                if (
                    task.retailerId != null &&
                    !pastDueRetailers.includes(task.retailerId)
                ) {
                    pastDueRetailers.push(task.retailerId);
                }
            });

            //get survey for tasks
            pastDueTasks = await _getSurveyForTasks(pastDueTasks);

            const retailers = await retailersApi.getRetailersByIds(
                pastDueRetailers
            );
            const activeRetailers = retailers.filter(
                (retailer) => retailer.status === 'ACTIVE'
            );

            // group by retailer
            activeRetailers.forEach((retailer) => {
                retailer.tasks = pastDueTasks.filter(
                    (task) => task.retailerId === retailer.id
                );
            });

            // const allAdhocTasksForWeek = await taskApi.getMyAdHocTasksForWeek(week);

            activeRetailers.forEach((retailer) => {
                if (retailer.tasks && retailer.tasks.length > 0) {
                    if (retailer.tasks && retailer.tasks.length > 0) {
                        const groupByDate = _.groupBy(
                            retailer.tasks,
                            'dueDate'
                        );
                        _.each(_.keys(groupByDate), (date) => {
                            const tasks = groupByDate[date];
                            content.push({
                                ...retailer,
                                tasks,
                                highPriorityTasks: tasks.filter(
                                    (task) => task.priority === 'HIGH'
                                ).length,
                                adhocTasks: tasks.filter(
                                    (task) => task.scheduleType === 'ONE_TIME'
                                ),
                                dueDate: date,
                            });
                        });
                    }
                }
            });

            // add internal ids used as reference when selecting a retailer on Past Due Page
            _.forEach(content, (retailer, id) => (retailer.internalRef = id));

            // find other (i.e. non-retailer) tasks
            let otherTasks = [];
            pastDueTasks.forEach((task) => {
                if (task.retailerId == null) {
                    otherTasks.push(task);
                }
            });

            dispatch({
                type: PASTDUE_TASKS,
                content: {
                    retailerTasks: content,
                    otherTasks: otherTasks,
                    activeRetailers: activeRetailers,
                },
                pastDueTasksCount: pastDueTasks.length,
            });
        } catch (err) {
            dispatch(createErrorHandlerError(err));
            //console.error(err);
        }
    };
};

export const updatePastDueUTR = (utr) => {
    return (dispatch, getState) => {
        const {
            pastDueTasks: { content, pastDueTasksCount },
        } = getState();
        const searchPredicate = { id: utr.retailerId, tasks: [{ id: utr.id }] };
        let didUpdate = false;

        let retailerToUpdate = _.find(content.retailerTasks, searchPredicate);
        if (retailerToUpdate) {
            let taskToRemove = _.find(retailerToUpdate.tasks, { id: utr.id });
            if (taskToRemove) {
                _.remove(retailerToUpdate.tasks, taskToRemove);
                retailerToUpdate.tasks.push(utr);
                didUpdate = true;
            }

            // also update the adhoc task list
            taskToRemove = _.find(retailerToUpdate.adhocTasks, { id: utr.id });
            if (taskToRemove) {
                _.remove(retailerToUpdate.adhocTasks, taskToRemove);
                retailerToUpdate.adhocTasks.push(utr);
                didUpdate = true;
            }
        } else {
            const taskToRemove = _.find(content.otherTasks, { id: utr.id });

            if (taskToRemove) {
                _.remove(content.otherTasks, taskToRemove);
                content.otherTasks.push(utr);
                didUpdate = true;
            }
        }

        if (didUpdate) {
            dispatch({
                type: PASTDUE_TASKS,
                content,
                pastDueTasksCount,
            });
            return true;
        }

        return false;
    };
};
