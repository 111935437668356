import NewRetailerApi from '../../../services/new-retailer-service';
import { success, error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

export const PUBLIC_NEW_RETAILER_LOADING = 'PUBLIC_NEW_RETAILER_LOADING';
export const PUBLIC_NEW_RETAILER_LOADING_DONE =
    'PUBLIC_NEW_RETAILER_LOADING_DONE';
export const PUBLIC_NEW_RETAILER_INIT = 'PUBLIC_NEW_RETAILER_INIT';
export const PUBLIC_NEW_RETAILER_GET = 'PUBLIC_NEW_RETAILER_GET';

const newRetailerApi = new NewRetailerApi();

export const getPublicNewRetailerHtml = () => {
    return async (dispatch) => {
        dispatch({ type: PUBLIC_NEW_RETAILER_LOADING });

        try {
            const payload = await newRetailerApi.getPublicNewRetailer();
            dispatch({ type: PUBLIC_NEW_RETAILER_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PUBLIC_NEW_RETAILER_LOADING_DONE });
    };
};

export const updatePublicNewRetailerHtml = (html) => {
    return async (dispatch) => {
        dispatch({ type: PUBLIC_NEW_RETAILER_LOADING });

        try {
            await newRetailerApi.updatePublicNewRetailer(html);

            dispatch(
                success({
                    msg: 'The New Retailer Policy was successfully updated.',
                })
            );
            dispatch({ type: PUBLIC_NEW_RETAILER_GET, payload: html });
        } catch (err) {
            console.error(err);
            dispatch(
                error({
                    msg: `Could not save New Retailer Policy: ${getErrorMessage(
                        err
                    )}`,
                })
            );
        }

        dispatch({ type: PUBLIC_NEW_RETAILER_LOADING_DONE });
    };
};

export const initPublicNewRetailer = () => ({
    type: PUBLIC_NEW_RETAILER_INIT,
});
