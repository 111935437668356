import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import i18n from 'react-intl-universal';
import _ from 'lodash';

import { isTokenExpired, isSvgLogo } from '../constants/utils';
import LoginForm from '../components/login/LoginForm';
import {
    login,
    setupLogo,
    setupReportLogo,
    getLogoKey,
    loadLocalizedResources,
    getDefaultLanguage,
    loadSupportedLocales,
} from '../redux/actions';

import defaultLogo from '../assets/logo.png';
import './LoginPage.css';
import PublicFaq from '../components/publicFaq/PublicFaq';
import PublicNewRetailer from '../components/publicNewRetailer/publicNewRetailer';
import ContactUsPublic from './contactUsPublic/ContactUsPublic';

const LogoImage = {
    alt: 'appLogo',
};

export class LoginPage extends Component {
    state = {
        fields: {
            username: '',
            password: '',
            grant_type: 'password',
        },
        showMessage: false,
        toggleFaq: false,
        toggleContact: false,
        toggleNewRetailer: false,
    };

    componentWillReceiveProps(nextProps) {
        const { apiError } = nextProps;
        const { errors, warnings } = apiError;
        const showMessage =
            (errors && errors.length > 0) || (warnings && warnings.length > 0);

        this.setState({ ...this.state, showMessage });
    }

    loadLocales = async () => {
        let currentLocale = i18n.determineLocale({
            urlLocaleKey: 'lang',
            cookieLocaleKey: 'lang',
        });

        //remove region code from locale
        currentLocale = currentLocale
            ? currentLocale.toLowerCase().split(/[_-]+/)[0]
            : currentLocale;

        if (
            !currentLocale ||
            !_.find(this.props.supportedLocales, { lang: currentLocale })
        ) {
            currentLocale = this.props.defaultLanguage || 'en';
        }

        await this.props.loadLocalizedResources(currentLocale);
    };

    onDismiss = () => {
        this.setState({ showMessage: false });
    };

    processForm = (event) => {
        event.preventDefault();

        const { fields } = this.state;
        this.props.onLogin(fields);
    };

    onChange = (event) => {
        const { name, value } = event.target;
        const fields = this.state.fields;
        fields[name] = value;
        this.setState({ fields });
    };

    showFaq = async () => {
        this.setState({
            toggleFaq: true,
            toggleContact: false,
            toggleNewRetailer: false,
        });
    };

    hideFaq = () => {
        this.setState({
            toggleFaq: false,
        });
    };

    showContact = async () => {
        this.setState({
            toggleContact: true,
            toggleFaq: false,
            toggleNewRetailer: false,
        });
    };

    hideContact = () => {
        this.setState({
            toggleContact: false,
        });
    };

    showNewRetailer = async () => {
        this.setState({
            toggleNewRetailer: true,
            toggleContact: false,
            toggleFaq: false,
        });
    };

    hideNewRetailer = () => {
        this.setState({
            toggleNewRetailer: false,
        });
    };

    render() {
        const {
            apiError,
            authenticated,
            loading,
            appLogo,
            appLogoFile,
            forgotPasswordLink,
        } = this.props;

        const { toggleFaq, toggleContact, toggleNewRetailer } = this.state;

        if (authenticated && !isTokenExpired()) {
            return (
                <Redirect
                    to={{
                        pathname: '/dashboard',
                        state: { from: this.props.location },
                    }}
                />
            );
        }

        LogoImage.src = isSvgLogo(appLogo, appLogoFile);

        if (!LogoImage.src) {
            LogoImage.src = defaultLogo;
        }

        return (
            <div className="login-wrapper" data-test="loginPage">
                {toggleFaq && (
                    <PublicFaq logoImage={LogoImage} hideFaq={this.hideFaq} />
                )}
                {toggleContact && (
                    <ContactUsPublic
                        logoImage={LogoImage}
                        hideContact={this.hideContact}
                    />
                )}
                {toggleNewRetailer && (
                    <PublicNewRetailer
                        logoImage={LogoImage}
                        hideNewRetailer={this.hideNewRetailer}
                    />
                )}
                {!toggleFaq && !toggleContact && !toggleNewRetailer && (
                    <LoginForm
                        onSubmit={this.processForm}
                        onChange={this.onChange}
                        errors={apiError.errors}
                        warnings={apiError.warnings}
                        fields={this.state.fields}
                        loading={loading}
                        onDismiss={this.onDismiss}
                        showMessage={this.state.showMessage}
                        logoImage={LogoImage}
                        forgotPasswordLink={forgotPasswordLink}
                    />
                )}

                <div className="login-footer-wrapper bg-black">
                    <Button
                        className="white-text"
                        content="New Retailer"
                        onClick={this.showNewRetailer}
                    />
                    <Button
                        className="white-text"
                        content="Contact Us"
                        onClick={this.showContact}
                    />
                    <Button
                        className="white-text"
                        content="FAQs"
                        onClick={this.showFaq}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    apiError,
    auth: { errors, isAuthenticated, loading },
    i18n: { supportedLocales, localResources },
    organization: { defaultLanguage, appLogo, reFaq },
    appLogo: { appLogoFile },
    frontendConfig: { configs },
}) => ({
    errors,
    apiError,
    authenticated: isAuthenticated,
    loading,
    supportedLocales,
    localResources,
    defaultLanguage,
    appLogo,
    appLogoFile,
    reFaq,
    forgotPasswordLink: _.find(configs, ['name', 'forgot-password-url']),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (args) => dispatch(login(args)),
        setupLogo: () => dispatch(setupLogo()),
        fetchReportLogo: () => dispatch(setupReportLogo()),
        fetchLogoKey: () => dispatch(getLogoKey()),
        loadSupportedLocales: () => dispatch(loadSupportedLocales()),
        loadLocalizedResources: (locale) =>
            dispatch(loadLocalizedResources(locale)),
        getDefaultLanguage: () => dispatch(getDefaultLanguage()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
