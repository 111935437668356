import OrderApi from '../../../services/order-service';
import { success, error } from '../notifications';

export const CANCEL_ORDER_LOADING = 'CANCEL_ORDER_LOADING';
export const CANCEL_ORDER_DONE = 'CANCEL_ORDER_DONE';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const ORDER_DETAIL_GET = 'ORDER_DETAIL_GET';
export const IN_PROGRESS_ORDERS_SEARCH = 'IN_PROGRESS_ORDERS_SEARCH';

const orderApi = new OrderApi();

export const cancelOrder = (orderId, externalOrderNumber, version, args) => {
    return async (dispatch) => {
        dispatch({ type: CANCEL_ORDER_LOADING });
        try {
            await orderApi.cancelOrder(orderId, version);
            let payload = null;
            if (args === undefined) {
                payload = await orderApi.getOrder(orderId);
                dispatch({ type: ORDER_DETAIL_GET, payload });
            } else {
                payload = await orderApi.search(args);
                dispatch({ type: IN_PROGRESS_ORDERS_SEARCH, payload });
            }
            dispatch(
                success({
                    msg: 'Successfully canceled order : ' + externalOrderNumber,
                })
            );
            dispatch({ type: CANCEL_ORDER_DONE });
        } catch (err) {
            dispatch(
                error({
                    msg:
                        'Failed to cancel order. Please try again later or contact customer service to cancel your order : ' +
                        externalOrderNumber,
                })
            );
            dispatch({ type: CANCEL_ORDER_ERROR });
        }
    };
};
