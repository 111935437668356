import React from 'react';
import UneditableLabel from './UneditableLabel';
import { Table } from 'semantic-ui-react';
import './FileSelector.css';

const FileSelector = ({
    input,
    labelText,
    name,
    accept,
    className,
    style,
    helpText,
    helpText2,
    meta: { pristine, valid, touched, error, warning },
    multiple,
}) => {
    const updatedInput = input; // workaround to get rid of React and DOM errors
    delete updatedInput.value; // see https://github.com/erikras/redux-form/issues/2532
    return (
        <div>
            <div className="readonly">
                <UneditableLabel text={labelText} />
            </div>

            <input
                {...updatedInput}
                type="file"
                accept={accept}
                style={
                    touched && error
                        ? { borderColor: '#ff0048 !important' }
                        : {}
                }
                multiple={multiple}
            />
            {helpText && (
                <div className="caption-text secondary-color">
                    <Table
                        basic="very"
                        compact="very"
                        collapsing
                        className="file-selector-table"
                    >
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="file-selector-icon-cell">
                                    <i className="icon-Info" />
                                </Table.Cell>
                                <Table.Cell>
                                    {helpText}
                                    <br />
                                    <i>{helpText2}</i>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            )}
            {touched &&
                ((error && (
                    <span className="error-text">
                        <i>{error}</i>
                    </span>
                )) ||
                    (warning && (
                        <span className="warning-text">
                            <i>{warning}</i>
                        </span>
                    )))}
        </div>
    );
};

export default FileSelector;
