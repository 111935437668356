import _ from 'lodash';
import moment from 'moment/moment';

import { TaskApi } from '../../../services/task-service';
import { SalesRoutesApi } from '../../../services/sales-routes-service';

export const TASK_SUMMARY_SALES_LOADING = 'TASK_SUMMARY_SALES_LOADING';
export const TASK_SUMMARY_SALES_SUCCESS = 'TASK_SUMMARY_SALES_SUCCESS';
export const TASK_SUMMARY_SALES_FAIL = 'TASK_SUMMARY_SALES_FAIL';

const taskApi = new TaskApi();
const salesRouteApi = new SalesRoutesApi();

export const fetchTaskSummary = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: TASK_SUMMARY_SALES_LOADING });

            const {
                auth: { info },
            } = getState();

            const salesRoutes = await salesRouteApi.getSalesRouteBySalesManager(
                info.user_name
            );
            const mySalesReps = _.compact(
                _.uniq(_.map(salesRoutes, ({ salesRep }) => salesRep))
            );

            const today = new Date();
            const start = moment(today).isoWeek();
            const dateInWeek = moment(today)
                .isoWeek(start)
                .startOf('isoWeek')
                .clone()
                .format('YYYY-MM-DD');

            const data = await taskApi.getSalesManagerSummary(
                dateInWeek,
                mySalesReps
            );

            dispatch({
                type: TASK_SUMMARY_SALES_SUCCESS,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch({ type: TASK_SUMMARY_SALES_FAIL, msg: e.message });
        }
    };
};
