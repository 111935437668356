import {
    SHORT_TERM_SALES_FAIL,
    SHORT_TERM_SALES_LOADING,
    SHORT_TERM_SALES_SUCCESS,
    SHORT_TERM_SALES_UPDATE_PERIOD,
} from '../actions/shortTermSales';

const INITIAL_STATE = {
    loading: false,
    error: '',
    data: [],
    period: null,
    weekInYear: null,
};

const shortTermSales = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHORT_TERM_SALES_LOADING:
            return { ...state, loading: true };
        case SHORT_TERM_SALES_UPDATE_PERIOD:
            return {
                ...state,
                period: { ...action.period },
                weekInYear: action.weekInYear,
            };
        case SHORT_TERM_SALES_FAIL:
            return { ...state, error: action.msg, loading: false };
        case SHORT_TERM_SALES_SUCCESS:
            return {
                ...state,
                data: [...action.data],
                loading: false,
                error: '',
            };
        default:
            return state;
    }
};
export default shortTermSales;
