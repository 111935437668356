import { Backend } from './backend';

class RetailerPosItemApi {
    constructor() {
        this.path = 'retailers/posItem';
    }

    async getPosItems() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPosItemsForCreate(retailerReference) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/creatable-for-retailer/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { RetailerPosItemApi };
