import { Backend } from './backend';

class LottoContactInfoApi {
    constructor() {
        this.path = '/organization/contact-lotto';
    }

    async getPublicContactUsData() {
        try {
            return await Backend.sendNoAuth({
                method: 'GET',
                url: `${this.path}/public`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllContactUsData() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/all`,
            });
        } catch (e) {
            throw e;
        }
    }

    async saveContactUsData(data) {
        try {
            return await Backend.send({
                method: 'POST',
                url: this.path,
                body: data,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default LottoContactInfoApi;
