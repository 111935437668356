import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from '../../layout/Page';
import {
    searchInvoices,
    initInvoices,
    generateInvoice,
} from '../../redux/actions';
import { addSelectedItem, clearFilters } from '../../components/filter/actions';
import { updateSortDetails } from '../../components/datatable/actions';
import {
    formatMoney,
    formatToDisplayDate,
    userHasPermission,
} from '../../constants/utils';
import config from '../../constants/config';
import permissions from '../../constants/permissions';
import Table from '../../components/datatable/Table';
import { Dimmer, Loader } from 'semantic-ui-react';
import { selectItemsToFilter } from '../../constants/utils';
import FilterSection from '../../components/filter/FilterSection';
import './styles.css';

const SEARCH_DEFAULTS = {
    size: 10,
    sortOrder: 'desc',
    sortColumn: 'invoiceDate',
};

const { INVOICE } = config.ROUTE_URLS;

const { INVOICE_PAGE, DOWNLOAD_INVOICE, INVOICE_DETAILS } = permissions;

export class InvoicesPage extends Component {
    state = {
        defaultFilterItems: {
            status: ['ACTV', 'CANCL', 'WROFF', 'PRCSD'],
            useDefault: true,
        },
    };
    async componentDidMount() {
        await this.init();
    }
    init = async () => {
        if (this.props.activeRetailer) {
            const { selectedItems, updateSortDetails } = this.props;
            let filters = selectItemsToFilter(selectedItems);
            filters.push({
                property: 'retailerReferences',
                data: [
                    this.props.activeRetailer?.userRetailerId.retailerReference,
                ],
            });

            updateSortDetails({
                sortColumnName: SEARCH_DEFAULTS.sortColumn,
                ascending: false,
            });

            this.search({
                page: 0,
                size: SEARCH_DEFAULTS.size,
                sortOrder: 'desc',
                sortColumn: 'invoiceDate',
                filters: filters,
            });
        }
    };
    async componentDidUpdate(prevProps: Readonly<P>) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            await this.init();
        }
    }

    componentWillUnmount() {
        this.props.init();
    }

    defaultSearch = () =>
        this.search({
            page: 0,
            ...SEARCH_DEFAULTS,
        });

    search = (args) =>
        this.props.search({
            ...args,
            filters: [
                ...args.filters,
                {
                    property: 'retailerReferences',
                    data: [
                        this.props.activeRetailer?.userRetailerId
                            .retailerReference,
                    ],
                },
            ],
        });

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        const { selectedItems } = this.props;
        let filters = selectItemsToFilter(selectedItems.selectedItems);
        this.search({
            page: page <= 0 ? 0 : page - 1,
            size,
            sortOrder,
            sortColumn: sortColumn || SEARCH_DEFAULTS.sortColumn,
            filters: filters,
            query,
        });
    };

    handleSearch = (query, filteredSelections, sortOrder, sortColumn) => {
        const { selectedItems } = this.props;
        let filters = selectItemsToFilter(selectedItems.selectedItems);
        this.search({
            page: 0,
            size: SEARCH_DEFAULTS.size,
            sortOrder,
            sortColumn: sortColumn || SEARCH_DEFAULTS.sortColumn,
            filters: filters,
            query,
        });
    };

    gotoViewDetails = (path) => this.props.history.push(path);

    handleFilterApply = (selectedItems, query) => {
        let filters = selectItemsToFilter(selectedItems);
        this.setState({ defaultFilterItems: { useDefault: false } });

        this.search({
            page: 0,
            size: SEARCH_DEFAULTS.size,
            filters,
            query,
        });
    };

    handleClearFilters = () => {
        this.search({
            page: 0,
            size: SEARCH_DEFAULTS.size,
            filters: [],
        });

        this.setState({ defaultFilterItems: { useDefault: true } });
    };

    render() {
        const {
            loading,
            content,
            totalPages,
            totalElements,
            size,
            number,
            generateInvoice,
        } = this.props;

        const columns = [
            {
                name: 'reference',
                title: 'Invoice No.',
                sortable: true,
                searchable: true,
            },
            {
                name: 'period.startDate',
                title: 'Period Start',
                sortable: true,
                formatter: formatToDisplayDate,
            },
            {
                name: 'period.endDate',
                title: 'Period End',
                sortable: true,
                formatter: formatToDisplayDate,
            },
            {
                name: 'invoiceAmount',
                title: 'Amount',
                sortable: true,
                formatter: (value) => formatMoney(value || 0),
            },
            {
                name: 'status.description',
                title: 'Status',
                sortable: true,
            },
        ];

        let actions = [
            {
                text: 'Download',
                handleClick: generateInvoice,
                handleClickParams: 'id',
                canShow: () => userHasPermission(DOWNLOAD_INVOICE),
            },
            {
                text: 'View Details',
                handleClick: (id) => this.gotoViewDetails(`${INVOICE}/${id}`),
                handleClickParams: 'id',
                canShow: () => userHasPermission(INVOICE_DETAILS),
            },
        ];

        return (
            <Page title="Invoices" data-test="InvoicesPage">
                <Dimmer active={loading} page inverted>
                    <Loader />
                </Dimmer>
                <FilterSection
                    filters={[
                        {
                            label: 'Status: ',
                            name: 'status',
                            multiple: true,
                            toggleAll: true,
                            client: true,
                            searchable: true,
                            items: [
                                { text: 'Active', value: 'ACTV' },
                                { text: 'Canceled', value: 'CANCL' },
                                { text: 'Write-off', value: 'WROFF' },
                                { text: 'Processed', value: 'PRCSD' },
                            ],
                            defaultSelected: this.state.defaultFilterItems
                                .useDefault
                                ? this.state.defaultFilterItems.status
                                : '',
                        },
                    ]}
                    onApplyFilter={this.handleFilterApply}
                    onClearFilters={this.handleClearFilters}
                />
                <div className="invoices-content">
                    <Table
                        pagination
                        actionsMenu={actions}
                        loading={loading}
                        content={content}
                        contentKey="id"
                        totalPages={totalPages}
                        totalElements={totalElements}
                        size={size}
                        page={number}
                        selector={{
                            variables: ['id'],
                            type: 'allowwithactionsmenu',
                            path: INVOICE,
                            callback: userHasPermission(INVOICE_PAGE)
                                ? this.gotoViewDetails
                                : null,
                        }}
                        columns={columns}
                        fetchData={this.handleSelectTablePage}
                        onSearch={this.handleSearch}
                        noDataMessage="There are no Invoices"
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({
    invoices,
    userRetailer: { activeRetailer },
    tableFilter,
}) => ({
    ...invoices,
    activeRetailer,
    selectedItems: tableFilter,
});

const mapDispatchToProps = (dispatch) => ({
    search: (args) => dispatch(searchInvoices(args)),
    init: () => dispatch(initInvoices()),
    updateSortDetails: (args) => dispatch(updateSortDetails(args)),
    generateInvoice: (invoiceId) => dispatch(generateInvoice(invoiceId)),
    onSelectFilterItem: (filterName, item) =>
        dispatch(addSelectedItem(filterName, item)),
    clearFilters: () => dispatch(clearFilters()),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InvoicesPage)
);
