import {
    CANCEL_ORDER_LOADING,
    CANCEL_ORDER_DONE,
    CANCEL_ORDER_ERROR,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    error: false,
    content: [],
};

const cancelOrder = (state = INITIAL_STATE, { type }) => {
    switch (type) {
        case CANCEL_ORDER_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CANCEL_ORDER_DONE:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case CANCEL_ORDER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default cancelOrder;
