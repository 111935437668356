import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { success } from '../../redux/actions/notifications';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRetailerGroup,
    clearRetailerGroup,
    saveRetailerGroup,
    createEmptyRetailerGroup,
} from '../../redux/actions/retailer-group/retailer-group-actions';
import type { RetailerGroup } from '../../DTOs/retailer-group';
import { clearNotification, setIsFormDirty } from '../../redux/actions';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import SfaForm from '../../components/SfaForm';
import SfaInput from '../../components/SfaInput';
import UneditablePill from '../../components/UneditablePill';
import UneditableInput from '../../components/UneditableInput';
import OkCancelModal from '../../components/OkCancelModal';
import { getJurisdictions } from '../../redux/actions/jurisdiction/jurisdiction-action';
import type { Jurisdiction } from '../../DTOs/jurisdiction';
import SfaSelect from '../../components/SfaSelect';
import { required } from '../../constants/validate';
import SfaReduxToggle from '../../components/sfaToggle/sfaReduxToggle';
import UnsavedFromDataAlertModel from '../../components/UnsavedFromDataAlertModel';
import permissions from '../../constants/permissions';

let EditRetailerGroupPage = ({
    dirty,
    history,
    match: {
        params: { id },
    },
    handleSubmit,
}) => {
    const dispatch = useDispatch();
    const [isEditView, setIsEditView] = useState(!id);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(getRetailerGroup(id));
        } else {
            dispatch(createEmptyRetailerGroup());
        }
        dispatch(getJurisdictions());
        return () => {
            dispatch(clearNotification());
            dispatch(clearRetailerGroup());
        };
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(setIsFormDirty(dirty));
    }, [dirty, dispatch]);

    const retailerGroup: RetailerGroup = useSelector(
        (state) => state.editRetailerGroup.retailerGroup
    );

    const jurisdictions: Jurisdiction[] = useSelector(
        (state) => state.jurisdiction.jurisdictions
    );

    const loading: boolean = useSelector(
        (state) =>
            state.editRetailerGroup.isLoading || state.jurisdiction.isLoading
    );
    const toggleEditView = () => {
        id ? setIsEditView(!isEditView) : goBack();
    };

    const save = async (values) => {
        // values.jurisdiction = find(
        //     jurisdictions,
        //     (jurisdiction) => jurisdiction.id === values.jurisdictionId
        // );
        try {
            const response = await dispatch(saveRetailerGroup(id, values));
            if (response) {
                updateSuccess(
                    `The retailer group was successfully ${
                        !id ? 'saved' : 'updated'
                    }.`
                );
            }
        } catch (err) {
            setIsShowConfirmation(false);
            return { name: err.response.data };
        }

        goBack();
    };
    const goBack = () => {
        history.replace('/group-list');
    };
    const toggleConfirmationDialog = () => {
        setIsShowConfirmation(!isShowConfirmation);
    };

    const updateSuccess = (msg) => {
        setTimeout(() => {
            dispatch(
                success({
                    msg: msg,
                    target: 'RetailerGroupListPage',
                })
            );
        }, 100);
    };

    return (
        <Page name="EditRetailerGroupPage" data-test="EditRetailerGroupPage">
            <SfaBreadCrumb
                onBreadCrumb={goBack}
                breadCrumbText="Retailer Groups"
                animation="fade right"
            />
            <SfaForm
                name="UpdateRetailerGroup"
                loading={loading}
                showEdit={!isEditView}
                showSaveCancel={isEditView}
                onEdit={toggleEditView}
                editButtonRoles={[permissions.UPDATE_RETAILER_GROUP_LIST]}
                onCancel={goBack}
                showDelete={!isEditView}
                deleteButtonText="Cancel"
                onDelete={goBack}
                deleteButtonRoles={[permissions.UPDATE_RETAILER_GROUP_LIST]}
                headerText={
                    id
                        ? (isEditView ? `Update` : '') +
                          ` ${retailerGroup?.name}`
                        : 'Create Retailer Group'
                }
                onSubmit={handleSubmit(toggleConfirmationDialog)}
                animation="fade left"
            >
                <Form.Field>
                    <OkCancelModal
                        header={
                            id
                                ? `Update Retailer Group`
                                : `Create Retailer Group`
                        }
                        body={`Are you sure you want to save the retailer group?`}
                        cancelFunction={toggleConfirmationDialog}
                        okFunction={handleSubmit(save)}
                        isOpen={isShowConfirmation}
                        okText={'Save'}
                    />

                    {isEditView && (
                        <>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Group Name *'}
                                        inputName={'name'}
                                        validations={required}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="two">
                                <Form.Field>
                                    <SfaInput
                                        label={'Min Facings *'}
                                        type={'number'}
                                        inputName={'minFacings'}
                                        inputValue={retailerGroup?.minFacings}
                                        validation={required}
                                        parse={(val) => parseInt(val)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <SfaInput
                                        type={'number'}
                                        label={'Max Facings'}
                                        inputName={'maxFacings'}
                                        inputValue={retailerGroup?.maxFacings}
                                        parse={(val) => parseInt(val)}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaSelect
                                        labelText={'Jurisdiction *'}
                                        fieldName={'jurisdictionCode'}
                                        options={_.map(
                                            jurisdictions,
                                            (jurisdiction) => ({
                                                label: jurisdiction.code,
                                                value: jurisdiction.code,
                                            })
                                        )}
                                        validation={required}
                                    ></SfaSelect>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="two">
                                <Form.Field>
                                    <SfaReduxToggle
                                        toggleName={'isActive'}
                                        labelText="Status *"
                                        activeLabel={'ACTIVE'}
                                        inactiveLabel={'INACTIVE'}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                    )}
                    {!isEditView && (
                        <>
                            <UneditableInput
                                label="Group Name"
                                data={retailerGroup?.name}
                            />
                            <Form.Group widths={2}>
                                <UneditableInput
                                    label="Min Facings"
                                    data={retailerGroup?.minFacings}
                                />
                                <UneditableInput
                                    label="Max Facings"
                                    data={retailerGroup?.maxFacings}
                                />
                            </Form.Group>
                            <UneditablePill
                                label="Jurisdiction"
                                data={retailerGroup?.jurisdictionCode}
                            />
                            <UneditablePill
                                label="Status"
                                data={
                                    retailerGroup?.isActive
                                        ? 'ACTIVE'
                                        : 'INACTIVE'
                                }
                            />
                        </>
                    )}
                </Form.Field>
            </SfaForm>
            <UnsavedFromDataAlertModel when={dirty} history={history} />
        </Page>
    );
};
const warn = (values) => {
    const warnings: Object = {};
    if (!values.isActive) {
        warnings.isActive =
            'All the related Planograms with this Retailer Group will inactivate on save.';
    }
    return warnings;
};
const validate = (values) => {
    const error = {};
    if (values.name?.length > 100) {
        error.name =
            'The length of the name is too long. The maximum length is 100';
    }
    if (!values.minFacings) {
        error.minFacings = 'Required';
    }
    if (values.minFacings < 1) {
        error.minFacings = 'The min facings should not be less than 1';
    }
    const min = +values.minFacings;
    const max = +values.maxFacings;

    if (!isNaN(max) && min > max) {
        error.maxFacings = 'The max facings should be greater than min facings';
    }
    if (!values.jurisdictionCode) {
        error.jurisdictionCode = 'Required';
    }
    return error;
};
EditRetailerGroupPage = reduxForm({
    form: 'EditRetailerGroupPage',
    validate,
    warn,
    enableReinitialize: true,
    pure: true,
})(EditRetailerGroupPage);

EditRetailerGroupPage = connect((state) => {
    const initialValues = state.editRetailerGroup.retailerGroup;
    if (
        initialValues?.jurisdictionCode &&
        state.jurisdiction?.jurisdictions &&
        !state.jurisdiction.jurisdictions.find(
            (jurisdiction) =>
                jurisdiction.code === initialValues.jurisdictionCode
        )
    ) {
        initialValues.jurisdictionCode = undefined;
    }
    // if (initialValues)
    //     initialValues.jurisdictionId = initialValues?.jurisdiction?.id;
    return {
        initialValues: initialValues,
    };
})(EditRetailerGroupPage);

export default EditRetailerGroupPage;
