import {
    TEXT_NOTES_LIST,
    CURR_EDIT_TEXT_NOTE,
    TEXT_NOTES_LOADING,
    TEXT_NOTES_LOADING_DONE,
} from '../../actions/textNotes/textNotes';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
    currTextNoteText: null,
};

const textNotes = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEXT_NOTES_LOADING:
            return { ...state, loading: true };
        case CURR_EDIT_TEXT_NOTE:
            return {
                ...state,
                currTextNoteText: action.textNoteText,
            };
        case TEXT_NOTES_LIST:
            return {
                ...state,
                ...action.data,
            };
        case TEXT_NOTES_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default textNotes;
