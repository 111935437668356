import {
    RETAILER_TASK,
    RETAILER_TASK_CLEAR,
} from '../../actions/tasks/retailerTask';

const INITIAL_STATE = {
    loading: false,
    content: { notes: '' },
    isReadOnly: false,
};

const retailerTask = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_TASK:
            return {
                ...state,
                content: action.content,
                isReadOnly: action.readOnly,
                loading: false,
            };
        case RETAILER_TASK_CLEAR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default retailerTask;
