import React, { Component } from 'react';
import Localize from '../../constants/i18n-utils';

class BreadCrumb extends Component {
    render() {
        const { location, onClick } = this.props;

        return (
            <div className="breadcrumb">
                <span onClick={onClick} className="caption-text clickable">
                    <span className="icon-Arrow-Left" />
                    {` back to `}
                    <b>{Localize.text('breadcrumb.location', location)}</b>
                </span>
            </div>
        );
    }
}

export default BreadCrumb;
