import {
    UPDATE_SALES_ROUTE_LOADING,
    UPDATE_SALES_ROUTE_LOADING_DONE,
    SALES_ROUTE_UPDATE_INIT,
    GET_SALES_ROUTE_SUCCESS,
    UPDATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS,
} from '../../actions';

const INIT_SALES_ROUTE_CREATE = {
    id: '',
    name: '',
    description: '',
    status: 'INACTIVE',
    retailerIds: [],
    salesManager: '',
    salesRep: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    salesRouteToUpdate: { ...INIT_SALES_ROUTE_CREATE },
    retailersInfo: [],
    salesRouteOptions: { salesManagers: [], salesReps: [] },
    salesRouteSuccess: false,
};

const updateSalesRoute = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_SALES_ROUTE_LOADING:
            return { ...state, loading: true };
        case UPDATE_SALES_ROUTE_LOADING_DONE:
            return { ...state, loading: false };
        case GET_SALES_ROUTE_SUCCESS:
            return { ...INITIAL_STATE, salesRouteToUpdate: { ...action.data } };
        case SALES_ROUTE_UPDATE_INIT:
            return {
                ...INITIAL_STATE,
                salesRouteToUpdate: {
                    ...INIT_SALES_ROUTE_CREATE,
                    salesManager: action.currUser,
                },
                salesRouteOptions: {
                    salesManagers: action.salesManagers,
                    salesReps: action.salesReps,
                },
            };
        case UPDATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS:
            return { ...state, retailersInfo: action.info };
        default:
            return state;
    }
};
export default updateSalesRoute;
