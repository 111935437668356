import { SalesRoutesApi } from '../../services/sales-routes-service';
import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

export const SALES_ROUTES_LIST = 'SALES_ROUTES_LIST';
export const SALES_ROUTES_LOADING = 'SALES_ROUTES_LOADING';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const salesRoutesApi = new SalesRoutesApi();

export const fetchSalesRoutes = (args) => {
    return async (dispatch) => {
        dispatch({ type: SALES_ROUTES_LOADING });
        try {
            const list = await salesRoutesApi.getAllSalesRoutes(args);
            dispatch({
                type: SALES_ROUTES_LIST,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchSalesRoutesAsync = (args) => {
    return async (dispatch) => {
        let ret = {};
        try {
            ret = await salesRoutesApi.getAllSalesRoutes(args);
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
        return ret;
    };
};
