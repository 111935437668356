import React, { Component } from 'react';

import { Button } from 'semantic-ui-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import { getMessageBannerDisplayTime } from '../../constants/utils';
class ImageCarousel extends Component {
    componentDidMount() {
        this.initDocumentList();
    }

    initDocumentList = () => {};
    confirmSaveMessage = () => {
        const { fileImage, confirmSaveMessage } = this.props;
        confirmSaveMessage(fileImage);
    };

    okayMessage = () => {
        const { okayFunction } = this.props;
        okayFunction();
    };

    cancelMessageSave = () => {
        const { returnToList } = this.props;
        returnToList();
    };

    render() {
        const {
            showThumbs,
            showArrows,
            fileImage,
            showSaveCancel,
            enableOK,
            ImageHeight,
        } = this.props;
        let form;
        if (showSaveCancel) {
            form = (
                <span>
                    <Button
                        primary
                        content="Save"
                        className="add-combo-response-btn"
                        onClick={this.confirmSaveMessage}
                    />
                    <Button
                        secondary
                        content="Cancel"
                        className="btn"
                        onClick={this.cancelMessageSave}
                    />
                </span>
            );
        }

        if (enableOK) {
            form = (
                <span>
                    <Button
                        primary
                        content="Ok"
                        className="add-combo-response-btn"
                        onClick={this.okayMessage}
                    />
                </span>
            );
        }
        const interval = getMessageBannerDisplayTime();
        return (
            <div data-test="imageCarousel">
                <Carousel
                    showThumbs={showThumbs}
                    showArrows={showArrows}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={interval}>
                    {fileImage.map((file) => {
                        return (
                            <div key={file}>
                                <img
                                    src={file}
                                    key={file}
                                    alt=""
                                    height={ImageHeight}
                                />{' '}
                            </div>
                        );
                    })}
                </Carousel>

                {form}
            </div>
        );
    }
}

const mapStateToProps = ({ documentUpload, organization }) => ({
    ...documentUpload,
    ...organization,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel);
