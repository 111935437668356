import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
    success,
    initPortalFileUpload,
    portalFileUpload,
    downloadFaqFile,
    updatePublicFaqHtml,
    updatePrivateFaqHtml,
    initPrivateFaq,
    initPublicFaq,
    getPublicFaqHtml,
    getPrivateFaqHtml,
    setIsFormDirty,
} from '../../redux/actions';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import Page from '../../layout/Page';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button } from 'semantic-ui-react';
import OkCancelModal from '../../components/OkCancelModal';
import './styles.css';
import WysiwygEditor from '../../components/wysiwygEditor/WysiwygEditor';

export class AdminFaqPage extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        faqType: null,
        showSave: false,
        showCheckBoxModal: false,
        dataChange: false,
        showReset: false,
        initialContent: null,
    };

    async componentDidMount() {
        const { getPrivateData, getPublicData } = this.props;
        await getPrivateData();
        await getPublicData();

        this.onTypeChange();
    }

    componentDidUpdate(prevProps, prevState) {
        const type =
            this.props.location.pathname === '/faqs/edit-private'
                ? 'Private'
                : 'Public';

        if (prevState.faqType !== type) {
            this.onTypeChange();
        }
    }

    componentWillUnmount() {
        this.props.onInitPortalFileUpload();
        this.props.init();
    }

    onTypeChange = () => {
        const type =
            this.props.location.pathname === '/faqs/edit-private'
                ? 'Private'
                : 'Public';
        const editorState = EditorState.createWithContent(
            stateFromHTML(
                (type === 'Private'
                    ? this.props.privateHtml
                    : this.props.publicHtml) || ''
            )
        );
        this.setState({
            editorState: editorState,
            faqType: type,
            initialContent: convertToRaw(editorState.getCurrentContent())
                .blocks,
        });
    };

    toggleSaveModal = (e, v) => {
        this.state.dataChange &&
            this.setState({
                showSave: v.content !== null && v.content !== 'Cancel',
            });
        this.props.setIsFormDirty(false);
    };

    toggleResetModal = (e, v) => {
        this.state.dataChange &&
            this.setState({
                showReset: v.content !== null && v.content !== 'Cancel',
            });
        this.props.setIsFormDirty(false);
    };

    onEditorStateChange = (editorState) => {
        const prev = this.state.initialContent;
        const current = convertToRaw(editorState.getCurrentContent()).blocks;
        const currrentValue = current
            .map((block) => (!block.text.trim() && '\n') || block.text)
            .join('\n');
        const prevValue = prev
            .map((block) => (!block.text.trim() && '\n') || block.text)
            .join('\n');
        if (prevValue !== currrentValue) {
            this.props.setIsFormDirty(true);
            this.setState({
                dataChange: true,
            });
        } else {
            this.props.setIsFormDirty(false);
            this.setState({
                dataChange: false,
            });
        }
        this.setState({
            editorState,
        });
    };

    handleSave = () => {
        const { savePrivateFaq, savePublicFaq } = this.props;

        const isPrivateType = this.state.faqType === 'Private';
        const html = draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
        );

        isPrivateType ? savePrivateFaq(html) : savePublicFaq(html);

        this.setState({
            dataChange: false,
            showSave: false,
        });
    };

    halndleReset = () => {
        const type =
            this.props.location.pathname === '/faqs/edit-private'
                ? 'Private'
                : 'Public';
        this.setState({
            dataChange: false,
            showReset: false,
            editorState: EditorState.createWithContent(
                stateFromHTML(
                    (type === 'Private'
                        ? this.props.privateHtml
                        : this.props.publicHtml) || ''
                )
            ),
            faqType: type,
        });
        this.props.setIsFormDirty(false);
    };

    uploadFile = async (values) => {
        const { onPortalFileUpload, updateSuccess } = this.props;
        const key = this.state.faqType === 'Private' ? 'reMemberFaq' : 'reFaq';
        const upload = [{}];

        upload.name = values.name;
        upload.file = values;
        upload.key = key;

        const success = await onPortalFileUpload(upload);
        if (success) {
            updateSuccess({
                msg: `The ${key} was successfully uploaded.`,
                target: 'AdminFaqPage',
            });
        }
    };

    downloadFile = async () => {
        await this.props.onDownloadFaqFile();
    };

    render() {
        const { showSave, showReset } = this.state;

        return (
            <Page
                name="AdminFaqPage"
                title={`FAQ - ${this.state.faqType}`}
                data-test="AdminFaqPage"
            >
                <div className="data-section">
                    <Dimmer page inverted active={this.props.loading}>
                        <Loader />
                    </Dimmer>
                    <WysiwygEditor
                        onEditorStateChange={this.onEditorStateChange}
                        editorState={this.state.editorState}
                    />
                    <div style={{ marginTop: 5, textAlign: 'right' }}>
                        <Button
                            type="button"
                            className="transparent-btn"
                            onClick={this.toggleResetModal}
                            disabled={!this.state.dataChange}
                            size="mini"
                        >
                            Reset
                        </Button>
                        <Button
                            type="button"
                            onClick={this.toggleSaveModal}
                            size="mini"
                            primary
                            disabled={!this.state.dataChange}
                        >
                            Save
                        </Button>
                    </div>
                    <OkCancelModal
                        header={`Save FAQ - ${this.state.faqType}?`}
                        body={`Are you sure you want to save the changes?`}
                        cancelFunction={this.toggleSaveModal}
                        okFunction={this.handleSave}
                        isOpen={showSave}
                        okText={'Save'}
                    />
                    <OkCancelModal
                        header="Reset FAQ"
                        body={`Do you want to undo changes?`}
                        cancelFunction={this.toggleResetModal}
                        okFunction={this.halndleReset}
                        isOpen={showReset}
                        okText={'Reset'}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ privateFaq, publicFaq }) => {
    return {
        privateHtml: privateFaq.faqHtml,
        publicHtml: publicFaq.faqHtml,
        loading: privateFaq.loading || publicFaq.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPublicData: () => dispatch(getPublicFaqHtml()),
        getPrivateData: () => dispatch(getPrivateFaqHtml()),
        init: () => {
            dispatch(initPrivateFaq());
            dispatch(initPublicFaq());
        },
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
        onInitPortalFileUpload: () => dispatch(initPortalFileUpload()),
        onPortalFileUpload: (args) => dispatch(portalFileUpload(args)),
        onDownloadFaqFile: () => dispatch(downloadFaqFile()),
        savePublicFaq: (html) => dispatch(updatePublicFaqHtml(html)),
        savePrivateFaq: (html) => dispatch(updatePrivateFaqHtml(html)),
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminFaqPage)
);
