import React, { useState } from 'react';
import { Accordion, Button, Dropdown, Grid } from 'semantic-ui-react';
import moment from 'moment';
import config from '../../constants/config';
import { find, map, sumBy } from 'lodash';
import {
    formatMoney,
    formatToDisplayDate,
    userHasPermission,
} from '../../constants/utils';
import { ORDER_STATUSES } from '../../constants/orderStatus';
import permissions from '../../constants/permissions';
import OkCancelModal from '../OkCancelModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    cancelOrder,
    generateOrderByExternalOrderNumber,
    openEditView,
} from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import { ShipmentHeader } from '../shipments/shipment-header';
import _ from 'lodash';

const { VIEW_ORDER, DOWNLOAD_ORDERS, CANCEL_ORDER, EDIT_ORDER } = permissions;
const { ORDER } = config.ROUTE_URLS;
const IN_PROGRESS_ORDER_DEFAULTS = {
    filters: [{ property: 'isComplete', data: ['false'] }],
    size: 9999,
    sortColumns: [
        { column: 'orderDate', order: 'DESC' },
        { column: 'externalOrderNumber', order: 'DESC' },
    ],
};
export const InProgressOrderItem = ({
    order: {
        id,
        orderDate,
        orderDetails,
        externalOrderNumber,
        shipments,
        status,
        sfaOrderNumber,
        totalCost,
        version,
    },
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [cancelModalOpened, setCancelModalOpened] = useState(false);
    const configs = useSelector((state) => state.organization.configs);
    const activeRetailer = useSelector(
        (state) => state.userRetailer.activeRetailer
    );

    const enableCancelOrder =
        find(configs, { name: 'enable-cancel-order' })?.value === 'true';
    const toggleCancelModalOpened = () => {
        setCancelModalOpened(!cancelModalOpened);
    };
    const gotoOrderDetails = (edit = false) => {
        dispatch(openEditView(edit));
        history.push(`${ORDER}/${id}`);
    };

    const handleCancel = () => {
        dispatch(
            cancelOrder(id, externalOrderNumber, version, {
                page: 0,
                ...IN_PROGRESS_ORDER_DEFAULTS,
                filters: [
                    ...IN_PROGRESS_ORDER_DEFAULTS.filters,
                    {
                        property: 'retailer',
                        data: [
                            activeRetailer?.userRetailerId?.retailerReference,
                        ],
                    },
                ],
            })
        );
        toggleCancelModalOpened();
    };

    const downloadOrder = () =>
        dispatch(
            generateOrderByExternalOrderNumber(
                sfaOrderNumber,
                externalOrderNumber
            )
        );

    const getViewOrderDetailButton = () =>
        userHasPermission(VIEW_ORDER) && (
            <Button
                className=" clickable w-100"
                onClick={() => gotoOrderDetails()}
            >
                View Order Details
            </Button>
        );

    const getDownLoadOrderButton = () =>
        userHasPermission(DOWNLOAD_ORDERS) && (
            <Button
                primary
                className={'w-100'}
                onClick={() =>
                    downloadOrder(sfaOrderNumber, externalOrderNumber)
                }
            >
                <span>
                    Download <span className="icon-Download" />
                </span>
            </Button>
        );

    const getEditButton = () =>
        userHasPermission(EDIT_ORDER) && (
            <Button
                disabled={
                    status.code === ORDER_STATUSES.UNPROCESSED ||
                    status.code === ORDER_STATUSES.ON_HOLD
                        ? false
                        : true
                }
                className={'w-100'}
                primary
                onClick={() => {
                    gotoOrderDetails(true);
                }}
            >
                <span>Edit Order </span>
            </Button>
        );

    const getCancelButton = () =>
        enableCancelOrder &&
        userHasPermission(CANCEL_ORDER) && (
            <Button
                disabled={
                    status.code === ORDER_STATUSES.UNPROCESSED ||
                    status.code === ORDER_STATUSES.ON_HOLD
                        ? false
                        : true
                }
                className={'w-100'}
                primary
                onClick={() => {
                    toggleCancelModalOpened();
                }}
            >
                <span>Cancel Order </span>
            </Button>
        );

    const getShipmentView = () =>
        map(shipments, (shipment) => <ShipmentHeader shipment={shipment} />);

    let orderStatusByShipment = undefined;
    const totalShipments = _.size(shipments);
    if (totalShipments > 0) {
        if (
            totalShipments ===
            _.size(
                _.filter(
                    shipments,
                    (shipment) => shipment.status?.code === 'CANCL'
                )
            )
        ) {
            orderStatusByShipment = 'Canceled';
        } else if (
            totalShipments ===
            _.size(
                _.filter(
                    shipments,
                    (shipment) =>
                        shipment.received ||
                        shipment.inTransit ||
                        shipment.status?.code === 'CANCL'
                )
            )
        ) {
            orderStatusByShipment = 'Processed';
        } else {
            orderStatusByShipment = 'In Shipment';
        }
    }

    return (
        <>
            <div className="in-progress-order-card max-mobile pt-3 ">
                <Grid className={'text-align-s pt-2 ms-0 me-0 '}>
                    <Grid.Row className={'p-0 pt-2 pl-2 '}>
                        <Grid.Column width={7}>
                            <div className={''}>
                                <span className={'pr-1'}>Order No.:</span>{' '}
                                <b className={'text-align-s'}>
                                    #{externalOrderNumber || ''}
                                </b>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={9} className={'text-align-e pl-0'}>
                            <div className={'pr-8 '}>
                                <span className={'pr-1'}>Status:</span>{' '}
                                <b className={'text-align-s'}>
                                    {status.description}
                                </b>
                            </div>
                            <Dropdown
                                item
                                icon="ellipsis vertical"
                                floating
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '.75rem',
                                }}
                            >
                                <Dropdown.Menu className="left">
                                    <Dropdown.Item>
                                        {getViewOrderDetailButton()}
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        {getDownLoadOrderButton()}
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        {getEditButton()}
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        {getCancelButton()}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={'p-0 pl-2 pt-2'} columns={3}>
                        <Grid.Column>
                            <div>Date</div>
                        </Grid.Column>

                        <Grid.Column>
                            <div>Total Quantity</div>
                        </Grid.Column>
                        <Grid.Column>
                            <div>Total Cost</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={'p-0 pl-2 pb-2 bold'} columns={3}>
                        <Grid.Column>
                            <div>
                                {moment(orderDate).format(
                                    config.DISPLAY_DATE_FORMAT
                                )}
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div>{`${sumBy(orderDetails, 'quantity')}`}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <div>{`${formatMoney(totalCost)}`}</div>
                        </Grid.Column>
                    </Grid.Row>
                    {shipments && shipments.length > 0 && (
                        <Grid.Row
                            className={'p-0 pb-2 pl-2 shipment-row'}
                            columns={1}
                        >
                            <Grid.Column>
                                <Accordion
                                    panels={[
                                        {
                                            key: { externalOrderNumber },
                                            title: {
                                                content: `No. of Shipments: ${shipments.length}`,
                                            },
                                            content: {
                                                content: getShipmentView(),
                                            },
                                        },
                                    ]}
                                    className={'shipment-detail-accordion'}
                                ></Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </div>
            <div
                className="in-progress-order-card min-tablet"
                key={id}
                data-test="inProgressOrdersWithData"
            >
                <div className="summary-header">
                    <div className="summary-header-section">
                        <div>Order Placed On:</div>
                        <div className="subheader-text bold">
                            {formatToDisplayDate(orderDate)}
                        </div>
                    </div>
                    <div className="summary-header-section">
                        <div>{`Order No.: ${externalOrderNumber || ''}`}</div>
                        <div>{`Number of Games Ordered: ${orderDetails.length}`}</div>
                        <div>{`Total Quantity: ${sumBy(
                            orderDetails,
                            'quantity'
                        )}`}</div>
                        <div>{`Total Cost: ${formatMoney(totalCost)}`}</div>
                    </div>
                    <div className="summary-header-section">
                        <div>{`Status: ${
                            orderStatusByShipment
                                ? orderStatusByShipment
                                : status.description
                        }`}</div>
                        <div>{`No. of Shipments: ${shipments.length}`}</div>
                        {getViewOrderDetailButton()}
                    </div>
                    <div className="summary-header-section">
                        {getDownLoadOrderButton()}
                    </div>
                    <div className="summary-header-section">
                        {getEditButton()}
                    </div>
                    <div className="summary-header-section">
                        {getCancelButton()}
                    </div>
                </div>
                <div>{getShipmentView()}</div>
            </div>

            <OkCancelModal
                isOpen={cancelModalOpened}
                header={
                    <span style={{ fontWeight: 'bold', marginTop: '10px' }}>
                        Cancel order ?
                        <br />
                        <br />
                        Order Number: {externalOrderNumber}
                    </span>
                }
                okText="Yes, Cancel"
                cancelText="No"
                body={
                    <div className={'text-align-c pb-4'}>
                        Are you sure you want to cancel this order?
                    </div>
                }
                okFunction={handleCancel}
                cancelFunction={toggleCancelModalOpened}
            />
        </>
    );
};
