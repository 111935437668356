import React from 'react';

const UneditableLabel = ({ text }) => {
    const { labelText } = style;
    return (
        <label className="body-text secondary-color" style={labelText}>
            {text}
        </label>
    );
};

const style = {
    labelText: {
        fontWeight: 'normal',
        fontSize: '12px',
        marginBottom: '5px',
    },
};

export default UneditableLabel;
