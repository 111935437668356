import {
    GET_RETAILER_SUCCESS,
    RETAILER_LOADING,
    RETAILER_ASSOCIATION_FAILED,
    RETAILER_INIT_STATE,
} from '../actions/readRetailer';

const INIT_RETAILER = {
    account: {},
    additionalDetails: '',
    city: '',
    contacts: [],
    fax: '',
    id: 0,
    industry: {},
    language: {},
    mailingAddressLine1: '',
    mailingAddressLine2: '',
    mailingAddressLine3: '',
    mailingAddressLine4: '',
    name: '',
    notes: '',
    numberOfFacings: '',
    phone: '',
    phoneExtension: '',
    reference: '',
    salesRoute: {},
    tier: {},
    planogramGroup: {},
    schedule: {},
    state: '',
    status: '',
    storeHours: '',
    territory: {},
    type: {},
    zipCode: '',
    zones: [],
    serviceCallData: [],
    phoneFaxMask: {},
};

const INITIAL_STATE = {
    loading: false,
    retailer: { ...INIT_RETAILER },
};

const readRetailer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_LOADING:
            return { ...state, loading: true };
        case RETAILER_ASSOCIATION_FAILED:
            return { ...state, loading: false };
        case GET_RETAILER_SUCCESS:
            return {
                ...state,
                retailer: {
                    ...action.retailer,
                    phoneFaxMask: action.phoneFaxMask,
                    salesRoute: action.salesRoute,
                    tier: action.tier,
                    planogramGroup: action.planogramGroup,
                    serviceCallData: [action.serviceCallData],
                },
                loading: false,
            };
        case RETAILER_INIT_STATE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default readRetailer;
