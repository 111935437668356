import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Popup, Sidebar } from 'semantic-ui-react';
import { GlobalHotKeys } from 'react-hotkeys';
import UserAvatar from 'react-user-avatar';

import Routes from '../Routes';
import {
    clearActiveMenu,
    clearErrorHandler,
    clearNotification,
    clearReferrer,
    closeDrawer,
    getFrontendConfig,
    initActiveUser,
    initDailyTasks,
    initTarget,
    logout,
    setActiveMenu,
    setactiveName,
    setIsFormDirty,
    setPath,
    setupLogo,
    setupReportLogo,
    showConfirmationPopup,
    toggleDrawer,
    toggleFooter,
} from '../redux/actions';
import { resetFilters } from '../components/filter/actions';
import {
    clearAllTableSelection,
    clearSearch,
} from '../components/datatable/actions';
import { clearError } from '../services/error-handler';
import {
    isAdmin,
    isSvgLogo,
    isTokenExpired,
    toTitleCase,
} from '../constants/utils';
import SideMenu from './SideMenu';

import './AppLayout.css';

import SplashPage from '../containers/SplashPage';
import LoginPage from '../containers/LoginPage';

import RetailerLayout from '../containers/retailerLayout/RetailerLayout';
import defaultLogo from '../assets/logo.png';
import NotificationBar from '../components/notifications/NotificationBar';
import OkCancelModal from '../components/OkCancelModal';
import IdleTimerLogout from '../components/IdleTimerLogout';
import config from '../constants/config';
import CognitoLoadingScreen from '../containers/cognito/cognito-loading-screen';

const LogoImage = {
    alt: 'appLogo',
};

const { DASHBOARD_SETTINGS } = config.ROUTE_URLS;

const keyMap = {
    LOGOUT: 'alt+s',
    USER_SETTINGS: 'alt+u',
};

class AppLayout extends Component {
    componentDidMount() {
        this.props.setupLogo();
        this.props.setupReportLogo();
        this.props.getFrontendConfig();
    }

    logout = () => {
        this._cleanup();
        this.props.onLogout();
    };
    nav = (path, callback) => {
        //exit if path is not valid or if trying to nav to the same page
        if (!path || this.props.location.pathname === path) {
            return;
        }

        this._cleanup();
        this.props.history.push(path);

        if (callback) {
            callback();
        }
    };

    onClickCloseDrawer = () => {
        this.props.onCloseDrawer();
    };

    _cleanup = () => {
        this.props.onToggleFooter();
        this.props.onCloseDrawer();
        this.props.onResetFilters();
        this.props.clearSearch();
        this.props.clearAllTableSelection();
        this.props.onClearErrors();
        this.props.onResetSelectedUser();
        this.props.clearErrorHandler();
        this.props.clearReferrer();
        this.props.clearActiveMenu();
    };

    closeModal = () => {
        const { showConfirmationPopup } = this.props;
        showConfirmationPopup(false);
    };

    continueNavigation = () => {
        this.props.setIsFormDirty(false);
        const { showConfirmationPopup, name, onSetActiveMenu } = this.props;
        showConfirmationPopup(false);
        const { path } = this.props;
        this.nav(path);
        onSetActiveMenu(name);
        this.props.setPath(null);
        this.props.setactiveName(null);
    };

    viewDashboardSettings = () => this.nav(DASHBOARD_SETTINGS);

    render() {
        const {
            userInfo,
            drawerOpen,
            isAppReady,
            footer,
            authenticated,
            appLogoFile,
            openConfirmPopup,
        } = this.props;

        if (!isAppReady) {
            return <SplashPage />;
        }

        const handlers = {
            LOGOUT: this.logout,
            USER_SETTINGS: this.viewDashboardSettings,
        };

        if (!authenticated || isTokenExpired()) {
            this.props.location.state = { from: this.props.location };
            return config.COGNITO_ENABLED ? (
                <CognitoLoadingScreen />
            ) : (
                <LoginPage />
            );
        }

        if (!isAdmin()) {
            return <RetailerLayout logout={this.logout} />;
        }

        const { appLogo } = this.props.organization;
        LogoImage.src = isSvgLogo(appLogo, appLogoFile);

        if (!LogoImage.src) {
            LogoImage.src = defaultLogo;
        }

        const MENU = <SideMenu />;

        const showBuildVersion = config.BUILD !== 'PROD';

        const buildInfo = showBuildVersion && (
            <div className="build-info">
                <span>Release: {config.PACKAGE_VERSION}</span>
            </div>
        );

        const userProfileSection = (
            <div className="profile-section">
                <Popup
                    content="Alt + U"
                    size="tiny"
                    hoverable
                    basic
                    trigger={
                        <div
                            className="avatar"
                            onClick={this.viewDashboardSettings}
                        >
                            <UserAvatar
                                size="48"
                                name={toTitleCase(
                                    userInfo.full_name || userInfo.user_name
                                )}
                            />
                        </div>
                    }
                />
                <span className="fullName">
                    {userInfo && userInfo.full_name}
                </span>
                <span className="username">
                    {userInfo && userInfo.user_name}
                </span>
                <Popup
                    content="Alt + S"
                    size="tiny"
                    hoverable
                    basic
                    trigger={
                        <Button
                            size="small"
                            className="sign-out"
                            onClick={this.logout}
                        >
                            Sign Out
                        </Button>
                    }
                />
            </div>
        );

        const mobileDrawerContent = (
            <div className="mobile-sidebar">
                <div className="sidebar-actions">
                    <div className="close">
                        <Button
                            size="mini"
                            compact
                            className="sign-out"
                            onClick={this.onClickCloseDrawer}
                        >
                            <span className="icon-Close" />
                        </Button>
                    </div>
                    <Button
                        size="small"
                        compact
                        className="sign-out"
                        onClick={this.logout}
                    >
                        Sign Out
                    </Button>
                </div>

                <div className="mobile-profile-section">
                    <div
                        className="avatar"
                        onClick={this.viewDashboardSettings}
                    >
                        <UserAvatar
                            size="30"
                            name={toTitleCase(
                                userInfo.full_name || userInfo.user_name
                            )}
                        />
                    </div>
                    <span
                        className="fullName"
                        onClick={this.viewDashboardSettings}
                    >
                        {userInfo && userInfo.full_name}
                    </span>
                </div>
                {buildInfo}
                {MENU}
            </div>
        );

        return (
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
                <div className={`app ${authenticated ? 'authenticated' : ''}`}>
                    <OkCancelModal
                        isOpen={openConfirmPopup}
                        header="Warning"
                        body="You have unsaved data. Do you want to exit without saving?"
                        okText="Yes"
                        cancelText="No"
                        okFunction={this.continueNavigation}
                        cancelFunction={this.closeModal}
                    />
                    <div className="sfa-sideBar">
                        <Sidebar
                            animation="overlay"
                            width="wide"
                            visible={drawerOpen}
                            icon="labeled"
                        >
                            {mobileDrawerContent}
                        </Sidebar>
                    </div>
                    <div className="AppContainer">
                        <NotificationBar />
                        <Sidebar.Pushable className="AppHolder">
                            <div className="min-desktop sfa-sideBar">
                                {LogoImage.src && (
                                    <div className="sideBarImageWrapper">
                                        <img
                                            src={LogoImage.src}
                                            alt={LogoImage.alt}
                                            className="sideBarCenteredImage"
                                        />
                                        {buildInfo}
                                    </div>
                                )}

                                {userProfileSection}
                                {MENU}
                            </div>
                            <Sidebar.Pusher
                                dimmed={drawerOpen}
                                className="contentArea"
                            >
                                <div className="max-mobile">
                                    <div className="mobile-app-bar">
                                        <Button
                                            className="side-nav-btn"
                                            onClick={() =>
                                                this.props.onToggleDrawer(true)
                                            }
                                        >
                                            <span
                                                className="icon-Preferences"
                                                style={{
                                                    color: '#4686ce',
                                                    fontSize: 24,
                                                }}
                                            >
                                                {' '}
                                            </span>
                                        </Button>
                                        <div className="appBarImageWrapper">
                                            <img
                                                src={LogoImage.src}
                                                alt={LogoImage.alt}
                                                className="appBarImage"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Routes />
                                <div className="max-mobile">
                                    {footer.toggle && (
                                        <div
                                            className="mobile-footer"
                                            onClick={() =>
                                                this.nav(footer.path)
                                            }
                                        >
                                            <span className="caption-text bold">
                                                {footer.msg}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Sidebar.Pusher>
                        </Sidebar.Pushable>
                    </div>
                    <IdleTimerLogout />
                </div>
            </GlobalHotKeys>
        );
    }
}

const mapStateToProps = ({
    auth,
    drawer,
    readyApp,
    footer,
    organization,
    appLogo,
    formsMetadata,
}) => {
    return {
        userInfo: auth.info,
        authenticated: auth.isAuthenticated,
        drawerOpen: drawer.open,
        isAppReady: readyApp.appReadyAuthenication,
        footer,
        organization,
        appLogoFile: appLogo.appLogoFile,
        isFormDirty: formsMetadata.isFormDirty,
        openConfirmPopup: drawer.showConfirmationPopup,
        path: drawer.path,
        name: drawer.name,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logout()),
        onToggleDrawer: (toggle) => dispatch(toggleDrawer(toggle)),
        onSetActiveMenu: (name) => dispatch(setActiveMenu(name)),
        onToggleFooter: () => dispatch(toggleFooter(false)),
        onCloseDrawer: () => dispatch(closeDrawer()),
        onResetFilters: () => dispatch(resetFilters()),
        initTarget: () => dispatch(initTarget()),
        onClearErrors: () => {
            dispatch(clearError());
            dispatch(clearNotification());
        },
        clearSearch: () => dispatch(clearSearch()),
        clearAllTableSelection: () => dispatch(clearAllTableSelection()),
        onResetSelectedUser: () => dispatch(initActiveUser()),
        clearErrorHandler: () => dispatch(clearErrorHandler()),
        clearReferrer: () => dispatch(clearReferrer()),
        clearActiveMenu: () => dispatch(clearActiveMenu()),
        initDailyTasks: () => dispatch(initDailyTasks()),
        showConfirmationPopup: (value) =>
            dispatch(showConfirmationPopup(value)),
        setPath: (path) => dispatch(setPath(path)),
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
        setactiveName: (val) => dispatch(setactiveName(val)),
        setupLogo: () => dispatch(setupLogo()),
        setupReportLogo: () => dispatch(setupReportLogo()),
        getFrontendConfig: () => dispatch(getFrontendConfig()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppLayout)
);
