import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.css';

const FormFooter = ({ children }) => {
    return (
        <Grid celled="internally" stackable columns={2} verticalAlign="bottom">
            <Grid.Row className="nopadding separator form-footer">
                {children}
            </Grid.Row>
        </Grid>
    );
};

export default FormFooter;
