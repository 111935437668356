import {
    COMPLIANCE_LIST,
    COMPLIANCE_LIST_LOADING,
    COMPLIANCE_LIST_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
};

const compliance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPLIANCE_LIST_LOADING:
            return { ...state, loading: true };
        case COMPLIANCE_LIST:
            return {
                ...state,
                ...action.list,
            };
        case COMPLIANCE_LIST_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default compliance;
