import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    VERIFY_USER,
    VERIFY_USER_SUCCESS,
    INIT_CREATE_USER,
    CHANGE_USERNAME,
    CREATE_USER_LOADING_DONE,
} from '../../actions';

const INIT_CREATE_USER_FIELDS = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    officePhone: '',
    department: '',
    roles: [],
    enabled: '',
};

const INITIAL_STATE = {
    fields: { ...INIT_CREATE_USER_FIELDS },
    isUserVerified: false,
    isUsernameChanged: false,
    errors: [],
};

const createUser = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_USER:
            return { ...state };
        case CREATE_USER_SUCCESS:
            return { ...state, fields: { ...INIT_CREATE_USER_FIELDS } };
        case VERIFY_USER:
            return { ...state, loading: true, isUserVerified: false };
        case VERIFY_USER_SUCCESS:
            return {
                ...state,
                fields: { ...action.userData },
                isUserVerified: true,
                isUsernameChanged: false,
                loading: false,
                errors: [],
            };
        case INIT_CREATE_USER:
            return { ...INITIAL_STATE, fields: { ...INIT_CREATE_USER_FIELDS } };
        case CHANGE_USERNAME:
            return { ...state, isUserVerified: false, isUsernameChanged: true };
        case CREATE_USER_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default createUser;
