import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Grid,
    Header,
    Icon,
    Image,
    Button,
    Label,
    GridRow,
    GridColumn,
} from 'semantic-ui-react';
import { find } from 'lodash';
import { formatMoney } from '../../constants/utils';
import moneyImage from '../../assets/money.svg';
import NumberIncrementor from '../numberIncrementor/NumberIncrementor';
import {
    incrementQuantity,
    decrementQuantity,
    initOrderQuantity,
    saveOrderQuantity,
    addToCart,
    incrementGameQuantityForOrder,
    decrementGameQuantityForOrder,
    addToOrder,
} from '../../redux/actions';
import './styles.css';
import './text-center.css';

export class GameCatalogDetailModal extends Component {
    handleAddToCart = () => {
        const { addToCart, orderQty } = this.props;
        if (orderQty >= 0) {
            addToCart();
            this.handleClose();
        }
    };

    handleClose = () => {
        const { onClose, initOrderQuantity } = this.props;

        onClose();
        initOrderQuantity();
    };

    handleMount = () => {
        const { gameInCart, saveOrderQuantity } = this.props;
        if (!!gameInCart) {
            saveOrderQuantity(gameInCart);
        }
    };

    render() {
        const {
            game,
            gameInCart,
            isOpen,
            incrementQuantity,
            decrementQuantity,
            orderQty,
            localizeDetail,
            gameInCartQty,
            readOnly,
            isInstantTicket,
            isEditOrder,
        } = this.props;

        return (
            <Modal
                open={isOpen}
                onMount={this.handleMount}
                className="game-detail-modal"
                data-test="gameCatalogDetail"
            >
                <Modal.Content>
                    <span
                        className="icon-Close static-close-button"
                        onClick={this.handleClose}
                    />
                    <Grid className="game-detail-grid">
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            {game.image}
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Header className="title-text bold game-title mb-2">
                                {game.gameRefAndName}
                            </Header>
                            {isInstantTicket && (
                                <div className={'mb-2'}>
                                    <p>
                                        {`${localizeDetail.themeName}: `}
                                        <b>{game.themeName}</b>
                                    </p>
                                </div>
                            )}
                            <Grid columns={2} className={'pb-2 mb-0'}>
                                <GridRow children={2}>
                                    <GridColumn>
                                        <Icon className="icon-Ticket" />
                                        <span>
                                            {`${
                                                isInstantTicket
                                                    ? localizeDetail.ticketsPerPack
                                                    : localizeDetail.ticketsPerDeal
                                            }`}
                                        </span>
                                        <div className="bold ticket-price text-center">
                                            <span>{game.ticketPerDeal}</span>
                                        </div>
                                    </GridColumn>
                                    <GridColumn className={'text-center pt-2'}>
                                        <span>
                                            {`${localizeDetail.ticketPrice}`}
                                        </span>
                                        <div className="bold ticket-price text-center">
                                            <span className="bold">
                                                {formatMoney(game.ticketPrice)}
                                            </span>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                                <GridRow
                                    className={
                                        'game-info-inner-wrapper m-3 mt-0'
                                    }
                                >
                                    <GridColumn className={'text-align-s'}>
                                        <span>
                                            {isInstantTicket
                                                ? `${localizeDetail.packValue}`
                                                : `${localizeDetail.totalRevenue}`}
                                        </span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold">
                                            {formatMoney(
                                                game.ticketPerDeal *
                                                    game.ticketPrice
                                            )}
                                        </span>
                                    </GridColumn>

                                    {!isInstantTicket && (
                                        <GridColumn className={'text-align-s'}>
                                            <span>
                                                {`${localizeDetail.totalPayout}`}
                                            </span>
                                        </GridColumn>
                                    )}
                                    {!isInstantTicket && (
                                        <GridColumn className={'text-align-e'}>
                                            <span className="bold">
                                                {formatMoney(
                                                    game.ticketPerDeal *
                                                        game.ticketPrice *
                                                        game.payout
                                                )}
                                            </span>
                                        </GridColumn>
                                    )}
                                    {/* RE-1489  temporarily hiding commission calculation field  */}
                                    {/* <GridColumn className={'text-align-s'}>
                                        <span>
                                            {`${localizeDetail.commission}`}
                                        </span>
                                    </GridColumn>
                                    <GridColumn className={'text-align-e'}>
                                        <span className="bold">
                                            {formatMoney(game.commission)}
                                        </span>
                                    </GridColumn> */}
                                </GridRow>
                                <GridRow
                                    className={
                                        'game-info-inner-wrapper m-3 mt-1 p-1'
                                    }
                                >
                                    <GridColumn>
                                        <div className={'image-wrapper'}>
                                            <Image
                                                src={moneyImage}
                                                alt="money-image"
                                            />
                                        </div>
                                    </GridColumn>
                                    <GridColumn className={'display-table'}>
                                        <div
                                            className={
                                                'text-center  display-table-cell vertical-align-middle'
                                            }
                                        >
                                            <div
                                                className={'width-fit-content'}
                                            >
                                                <span>
                                                    {isInstantTicket
                                                        ? `${localizeDetail.maxPrize}`
                                                        : `${localizeDetail.retailCost}`}
                                                </span>
                                                <div className={'text-center'}>
                                                    <span className="bold  ">
                                                        {formatMoney(
                                                            isInstantTicket
                                                                ? game.maxPrize
                                                                : game.retailPrice
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            {!readOnly && (
                                <div>
                                    <div className="add-item-action-wrapper">
                                        <NumberIncrementor
                                            label={`${localizeDetail.quality}`}
                                            value={orderQty}
                                            onIncrease={incrementQuantity}
                                            onDecrease={decrementQuantity}
                                            disabled={orderQty < 1}
                                        />
                                        {!!gameInCart && (
                                            <Button
                                                positive
                                                className="update-cart-btn"
                                                content={`${localizeDetail.update}`}
                                                onClick={this.handleAddToCart}
                                                disabled={
                                                    gameInCartQty === orderQty
                                                }
                                            />
                                        )}
                                        <div className={'w-100 pb-6 pt-2'}>
                                            Min:{' '}
                                            {game.minimumOrderQuantity
                                                ? game.minimumOrderQuantity
                                                : '0'}{' '}
                                            {game.maximumOrderQuantity &&
                                            game.maximumOrderQuantity > 0
                                                ? `,Max: ${game.maximumOrderQuantity}`
                                                : ''}
                                        </div>
                                    </div>
                                    <Grid columns={2}>
                                        <GridRow>
                                            {!!gameInCart ? (
                                                <GridColumn
                                                    className={'d-flex pt-2'}
                                                >
                                                    <div className="in-cart-label">
                                                        <Icon className="icon-Cart" />
                                                    </div>
                                                    <div>
                                                        <Label
                                                            circular
                                                            color="yellow"
                                                        >
                                                            {gameInCart}
                                                        </Label>{' '}
                                                        {isEditOrder
                                                            ? 'in order'
                                                            : 'in Cart'}
                                                    </div>
                                                </GridColumn>
                                            ) : (
                                                <GridColumn className={'pr-0'}>
                                                    <Button
                                                        primary
                                                        icon={
                                                            <Icon className="icon-Cart" />
                                                        }
                                                        disabled={orderQty < 1}
                                                        content={
                                                            isEditOrder
                                                                ? `${localizeDetail.addToOrder}`
                                                                : `${localizeDetail.cartButton}`
                                                        }
                                                        className="add-to-cart-btn m-0"
                                                        onClick={
                                                            this.handleAddToCart
                                                        }
                                                    />
                                                </GridColumn>
                                            )}
                                            {isInstantTicket && (
                                                <GridColumn>
                                                    <div
                                                        className={'d-flex p-2'}
                                                    >
                                                        <Label
                                                            circular
                                                            color="suggested-color"
                                                        >
                                                            {game?.suggestedQuantity ??
                                                                0}
                                                        </Label>{' '}
                                                        <p class={'pt-1 pl-1'}>
                                                            Suggested Qty
                                                        </p>
                                                    </div>
                                                </GridColumn>
                                            )}
                                        </GridRow>
                                    </Grid>
                                </div>
                            )}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (
    {
        orderQuantity: { qty },
        cart: { orderedItems },
        organization: { configs },
        orderDetail: { orderedItems: orderDetailItems },
    },
    { gameInCartQty }
) => {
    return {
        orderQty: qty,
        gameInCart: gameInCartQty,
        orderDetailItems,
        isInstantTicket:
            find(configs, { name: 'instant-ticket-view' })?.value === 'true',
    };
};

const mapDispatchToProps = (dispatch, { game, isEditOrder }) => {
    return {
        incrementQuantity: () => dispatch(incrementQuantity(game)),
        decrementQuantity: () => dispatch(decrementQuantity(game)),
        initOrderQuantity: () => dispatch(initOrderQuantity(game)),
        addToCart: () =>
            dispatch(isEditOrder ? addToOrder(game) : addToCart(game)),
        saveOrderQuantity: (qty) => dispatch(saveOrderQuantity(qty)),
        incrementGameQuantityForOrder: () =>
            dispatch(incrementGameQuantityForOrder(game)),
        decrementGameQuantityForOrder: () =>
            dispatch(decrementGameQuantityForOrder(game)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GameCatalogDetailModal);
