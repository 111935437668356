import {
    TABLE_MOUSE_OUT,
    TABLE_MOUSE_OVER,
    TABLE_SORT,
    TABLE_SORT_CLEAR,
} from '../actions';

const INITIAL_STATE = {
    sortColumnName: null,
    ascending: true,
    hoverColumn: null,
};

export const tableSort = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TABLE_SORT:
            return { ...state, ...action.args };
        case TABLE_MOUSE_OVER:
            return { ...state, hoverColumn: action.args };
        case TABLE_MOUSE_OUT:
            return { ...state, hoverColumn: null };
        case TABLE_SORT_CLEAR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
