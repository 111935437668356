import { checkFilters } from './utils';
import { Backend } from './backend';

class UserApi {
    constructor() {
        this.path = '/users/';
    }

    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}version`,
        });
    }

    async getActiveSalesReps({ page, size, query }) {
        try {
            return await this.getActiveUsersByRole('SALES_REP', {
                page,
                size,
                query,
            });
        } catch (e) {
            throw e;
        }
    }

    async getActiveSalesManagers({ page, size, query }) {
        try {
            return await this.getActiveUsersByRole('SALES_MANAGER', {
                page,
                size,
                query,
            });
        } catch (e) {
            throw e;
        }
    }

    async getActiveAssignees() {
        try {
            const managers = await this.getActiveUsersByRole('SALES_MANAGER', {
                page: 0,
                size: 1000,
            });
            const reps = await this.getActiveUsersByRole('SALES_REP', {
                page: 0,
                size: 1000,
            });
            const admin = await this.getActiveUsersByRole('ADMIN', {
                page: 0,
                size: 1000,
            });

            return [...reps.content, ...managers.content, ...admin.content];
        } catch (e) {
            throw e;
        }
    }

    async getActiveUsersByRole(role, { page, size, query, filters }) {
        try {
            const _filters = filters || [];
            let newFilters = [
                ..._filters,
                {
                    property: 'roles',
                    data: [role],
                },
            ];

            return await Backend.send({
                method: 'POST',
                url: `${this.path}list`,
                body: {
                    page,
                    size,
                    filters: checkFilters(newFilters),
                    query,
                    sortColumn: 'firstName',
                    sortOrder: 'ASC',
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllUsers({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'username',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllUsersFilter({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}list`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'firstName',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async createUpdateUser(fields) {
        try {
            return await Backend.send({
                method: 'POST',
                url: this.path,
                body: fields,
            });
        } catch (e) {
            throw e;
        }
    }

    async verifyUser(username) {
        try {
            return await Backend.send({
                url: `${this.path}verify/retailer-portal?username=${username}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async getUserByUsername(username) {
        try {
            return await Backend.send({
                url: `${this.path}usernameOrId?usernameOrId=${username}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async getUserByID(id) {
        try {
            return await Backend.send({
                url: `${this.path}usernameOrId?usernameOrId=${id}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }
    async getUserRetailersByUsernameOrId(id) {
        try {
            return await Backend.send({
                url: `${this.path}user-retailers-by-username-or-id?usernameOrId=${id}`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }
    async getUserIdBySalesRepList(usernames) {
        try {
            return await Backend.send({
                url: `${this.path}by-username-list`,
                method: 'POST',
                body: usernames,
            });
        } catch (e) {
            throw e;
        }
    }

    async getUserRoles() {
        try {
            return await Backend.send({
                url: `${this.path}engagement/roles`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async getUserDashboardSettings() {
        try {
            return await Backend.send({
                url: `${this.path}dashboard/settings`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async saveDashboardSettings(settings) {
        try {
            return await Backend.send({
                url: `${this.path}dashboard/settings`,
                method: 'POST',
                body: settings,
            });
        } catch (e) {
            throw e;
        }
    }

    async resetDashboardSettings() {
        try {
            return await Backend.send({
                url: `${this.path}dashboard/settings/reset`,
                method: 'GET',
            });
        } catch (e) {
            throw e;
        }
    }

    async resetPasswordForRetailer(retailerReference) {
        try {
            return await Backend.sendNoAuth({
                url: `${this.path}reset-password`,
                method: 'POST',
                body: retailerReference,
                contentType: 'text/plain',
            });
        } catch (e) {
            throw e;
        }
    }

    async updateRolePermissions(request) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}engagement/privileges/update`,
                body: request,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { UserApi };
