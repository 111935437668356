import { REHYDRATE } from 'redux-persist/constants';

const INITIAL_STATE = {
    appReadyAuthenication: false,
};

const readyApp = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return { ...state, appReadyAuthenication: true };
        default:
            return state;
    }
};
export default readyApp;
