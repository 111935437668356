import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { filter, find, map, orderBy } from 'lodash';
import { Dimmer, Loader, Button, Header, Grid, Popup } from 'semantic-ui-react';
import Page from '../../layout/Page';
import {
    initInvoiceDetail,
    getInvoiceDetail,
    generateInvoice,
} from '../../redux/actions';
import { formatMoney } from '../../constants/utils';
import config from '../../constants/config';
import Localize from '../../constants/i18n-utils';
import './styles.css';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import classnames from 'classnames';

const { INVOICE } = config.ROUTE_URLS;

export class InvoiceDetailPage extends Component {
    componentDidMount() {
        this.props.getDetail(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.init();
    }

    gotoInvoiceList = () => this.props.history.push(INVOICE);

    downloadInvoice = () =>
        this.props.generateInvoice(this.props.match.params.id);

    render() {
        const {
            loading,
            invoice,
            retailerInfo,
            displayInvoiceBy,
            generatingInvoice,
        } = this.props;

        const invoiceStatus =
            invoice && invoice.status && invoice.status.description;
        const cancelled =
            invoiceStatus && invoiceStatus.toLowerCase() === 'cancelled';
        const displayByType =
            displayInvoiceBy && displayInvoiceBy.toLowerCase() === 'type';

        const regularInvoiceLineItems = filter(
            invoice.lineItems,
            (lineItem) => !!lineItem.type.displayOrder
        );
        const otherInvoiceLineItems = filter(
            invoice.lineItems,
            (lineItem) => !lineItem.type.displayOrder
        );

        return (
            <Page
                title={`Invoice #${invoice.reference}`}
                className="invoice-detail-page"
                data-test="invoiceDetailPage"
                breadCrumbComponent={
                    <BreadCrumb
                        location={'Invoices'}
                        onClick={this.gotoInvoiceList}
                    />
                }
            >
                <Dimmer page active={loading} inverted>
                    <Loader />
                </Dimmer>
                <div className="detail-wrapper">
                    <div className="invoice-info">
                        <div className="info">
                            <div className="info-cell">
                                <div>
                                    {Localize.text(
                                        'invoice.detail.retailerNo',
                                        'Retailer No.'
                                    )}
                                </div>
                                <div className="bold">
                                    {invoice.retailerReference}
                                </div>
                            </div>
                            <div className="info-cell">
                                <div>
                                    {Localize.text(
                                        'invoice.detail.retailer',
                                        'Retailer'
                                    )}
                                </div>
                                <div className="bold">{retailerInfo.name}</div>
                            </div>
                        </div>
                        <div className="info">
                            <div className="info-cell">
                                <div>
                                    {Localize.text(
                                        'invoice.detail.invoiceNo',
                                        'Invoice No.'
                                    )}
                                </div>
                                <div className="bold">{invoice.reference}</div>
                            </div>
                            <div className="info-cell">
                                <div>
                                    {Localize.text(
                                        'invoice.detail.amount',
                                        'Amount'
                                    )}
                                </div>
                                <div className="bold">
                                    {formatMoney(invoice.invoiceAmount || 0)}
                                </div>
                            </div>
                            <div className="info-cell">
                                <div>
                                    {Localize.text(
                                        'invoice.detail.status',
                                        'Status'
                                    )}
                                </div>
                                <div
                                    className={classnames('bold', {
                                        cancelled: cancelled,
                                    })}
                                >
                                    {invoiceStatus &&
                                        invoiceStatus.toUpperCase()}
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <Button
                                primary
                                onClick={this.downloadInvoice}
                                loading={generatingInvoice}
                            >
                                <span>
                                    {`${Localize.text(
                                        'invoice.detail.download',
                                        'Download'
                                    )} `}
                                    <span className="icon-Download" />
                                </span>
                            </Button>
                        </div>
                    </div>
                    {regularInvoiceLineItems.length > 0 && (
                        <div className="invoice-details">
                            <Header as="h4">
                                {Localize.text(
                                    'invoice.detail.invoiceItems',
                                    'Invoice Items'
                                )}
                            </Header>

                            <Grid className="detail-items">
                                <Grid.Row className="detail-item-row">
                                    <Grid.Column width={1} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.lineNo',
                                                'Line No.'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.description',
                                                'Description'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.item',
                                                'Item'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.pricePoint',
                                                'Price Point'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.TicketsPerPack',
                                                'Tickets / Pack'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.packs',
                                                'Packs'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.tickets',
                                                'Tickets'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={3} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.amount',
                                                'Amount'
                                            )}
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>

                                {map(
                                    orderBy(
                                        regularInvoiceLineItems,
                                        displayByType
                                            ? ['type.displayOrder', 'itemCode']
                                            : ['itemCode', 'type.displayOrder'],
                                        ['asc']
                                    ),
                                    (
                                        {
                                            lineNumber,
                                            type,
                                            itemCode,
                                            itemDescription,
                                            itemPrice,
                                            itemTicketsPerBook,
                                            books,
                                            tickets,
                                            amount,
                                        },
                                        i
                                    ) => (
                                        <Grid.Row
                                            key={lineNumber}
                                            className="detail-item-row"
                                        >
                                            <Grid.Column
                                                width={1}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {i + 1}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <span className="bold">
                                                    {type.description}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                                <span className="bold">
                                                    {itemCode
                                                        ? `${itemCode} - ${itemDescription}`
                                                        : ''}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={2}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {formatMoney(itemPrice)}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={2}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {itemTicketsPerBook}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={1}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {books}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={1}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {tickets}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={3}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {formatMoney(amount)}
                                                </span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                )}
                            </Grid>
                        </div>
                    )}

                    {otherInvoiceLineItems.length > 0 && (
                        <div className="invoice-details">
                            <Grid className="detail-items">
                                <Grid.Row className="detail-item-row">
                                    <Grid.Column width={1}>
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.lineNo',
                                                'Line No.'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.adjustment',
                                                'Adjustment'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.item',
                                                'Item'
                                            )}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={3} textAlign="right">
                                        <span className="bold secondary-color">
                                            {Localize.text(
                                                'invoice.detail.value',
                                                'Value'
                                            )}
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>

                                {map(
                                    orderBy(
                                        otherInvoiceLineItems,

                                        displayByType
                                            ? ['type.displayOrder', 'itemCode']
                                            : ['itemCode', 'type.displayOrder'],
                                        ['asc']
                                    ),
                                    ({
                                        lineNumber,
                                        type,
                                        itemCode,
                                        itemDescription,
                                        amount,
                                        note,
                                    }) => (
                                        <Grid.Row
                                            key={lineNumber}
                                            className="detail-item-row"
                                        >
                                            <Grid.Column width={1}>
                                                <span className="bold">
                                                    {lineNumber}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <span className="bold">
                                                    {type.description}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                <span className="bold">
                                                    {itemCode
                                                        ? `${itemCode} - ${itemDescription}`
                                                        : ''}
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column
                                                width={3}
                                                textAlign="right"
                                            >
                                                <span className="bold">
                                                    {formatMoney(amount)}
                                                    {note && (
                                                        <Popup
                                                            content={note}
                                                            on="click"
                                                            trigger={
                                                                <span className="icon-notes body-text secondary-color" />
                                                            }
                                                        />
                                                    )}
                                                </span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                )}
                            </Grid>
                        </div>
                    )}
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({
    invoiceDetail: { loading, invoice },
    userInfo: { retailerInfo },
    organization: { configs },
    generateInvoice,
}) => {
    const displayInvoiceByConfig = find(configs, {
        name: 'display-invoice-by',
    });

    return {
        loading,
        invoice,
        retailerInfo,
        displayInvoiceBy: displayInvoiceByConfig
            ? displayInvoiceByConfig.value
            : '', //don't know value and don't want to error on toLowerCase()
        generatingInvoice: generateInvoice.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (id) => dispatch(getInvoiceDetail(id)),
        init: () => dispatch(initInvoiceDetail),
        generateInvoice: (invoiceId) => dispatch(generateInvoice(invoiceId)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailPage)
);
