import {
    GET_RETAILER_GROUPS,
    UPDATE_LOADING_STATE,
    RetailerGroupActionTypes,
    RetailerGroupStateType,
    GET_RETAILER_GROUPS_BY_SEARCH,
} from '../../type/reatiler-group/reatiler-group-type';

const initialState: RetailerGroupStateType = {
    isLoading: false,
    retailerGroupList: [],
    retailerGroupPage: undefined,
};
const retailerGroupReducer = (
    state = initialState,
    action: RetailerGroupActionTypes
) => {
    switch (action.type) {
        case GET_RETAILER_GROUPS:
            return {
                ...state,
                isLoading: false,
                retailerGroupList: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        case GET_RETAILER_GROUPS_BY_SEARCH:
            return {
                ...state,
                isLoading: false,
                retailerGroupPage: action.payload,
            };
        default:
            return state;
    }
};
export default retailerGroupReducer;
