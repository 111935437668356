import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon, Input } from 'semantic-ui-react';
import { FormWrapper } from '../../form/FormWrapper';
import './styles.css';

class ResponsiveCardSearchForm extends Component {
    _handleSubmit = (values) => {
        const { onSubmit } = this.props;
        if (onSubmit) {
            onSubmit(values.searchInput);
        }
    };

    _onSearchClear = () => {
        this.props.reset();
        this._handleSubmit([]);
    };

    render() {
        const { handleSubmit, dirty } = this.props;
        const searchIcon = (
            <Icon name="close" link onClick={this._onSearchClear} />
        );

        return (
            <div className="search-input-container">
                <FormWrapper onSubmit={handleSubmit(this._handleSubmit)}>
                    <Field
                        name="searchInput"
                        component={Input}
                        fluid
                        placeholder="Search..."
                        icon={searchIcon}
                        maxLength={10}
                        className="bg-transparent search-input-field"
                    />

                    <Button
                        className="square-button"
                        primary={dirty}
                        disabled={!dirty}
                    >
                        <span className="icon-Search" />
                    </Button>
                </FormWrapper>
            </div>
        );
    }
}

export default withRouter(
    connect()(
        reduxForm({
            form: 'ResponsiveCardSearchForm',
            enableReinitialize: true,
            forceUnregisterOnUnmount: true,
        })(ResponsiveCardSearchForm)
    )
);
