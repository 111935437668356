import { Backend } from './backend';

class FaqApi {
    constructor() {
        this.path = '/organization/faq';
    }

    async getPublicFaq() {
        try {
            return await Backend.sendNoAuth({
                method: 'GET',
                url: `${this.path}/public`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPrivateFaq() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/private`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePublicFaq(html) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/update/public`,
                body: html,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePrivateFaq(html) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/update/private`,
                body: html,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default FaqApi;
