import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import { RetailerPosApi } from '../../../services/retailer-pos-service';
import {
    POS_ITEMS,
    POS_ITEMS_LOADING,
    POS_ITEMS_LOADING_DONE,
} from './retailerPos';
import { RetailerPosItemApi } from '../../../services/retailer-posItem-service';

export const RETAILER_POS_CREATE_SUCCESS = 'RETAILER_POS_CREATE_SUCCESS';
export const RETAILER_POS_CREATE_FAILURE = 'RETAILER_POS_CREATE_FAILURE';
export const RETAILER_POS_VALIDATE = 'RETAILER_POS_VALIDATE';
export const RETAILER_POS_INIT = 'RETAILER_POS_INIT';
export const CREATE_RETAILER_POS_LOADING = 'CREATE_RETAILER_POS_LOADING';
export const CREATE_RETAILER_POS_LOADING_DONE =
    'CREATE_RETAILER_POS_LOADING_DONE';
export const CREATE_RETAILER_POS_SELECT_ITEM =
    'CREATE_RETAILER_POS_SELECT_ITEM';

const retailerPosApi = new RetailerPosApi();
const retailerPosItemApi = new RetailerPosItemApi();

const createRetailerPosError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'ReadRetailer' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const createRetailerPos = (retailerPos) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: CREATE_RETAILER_POS_LOADING });
            const data = await retailerPosApi.createRetailerPos(retailerPos);
            dispatch({
                type: RETAILER_POS_CREATE_SUCCESS,
                data,
            });
        } catch (err) {
            dispatch(createRetailerPosError(err));
            success = false;
        } finally {
            dispatch({ type: CREATE_RETAILER_POS_LOADING_DONE });
        }
        return success;
    };
};

export const getPosItemsForCreate = (retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: POS_ITEMS_LOADING });
            const list = await retailerPosItemApi.getPosItemsForCreate(
                retailerReference
            );
            dispatch({
                type: POS_ITEMS,
                list,
            });
        } catch (err) {
            dispatch(createRetailerPosError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: POS_ITEMS_LOADING_DONE });
        }
    };
};

export const initRetailerPos = () => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_POS_INIT });
    };
};

export const selectRetailerItem = (retailerItem) => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_RETAILER_POS_SELECT_ITEM,
            item: retailerItem,
        });
    };
};
