import {
    RETURNS_LOADING,
    RETURNS_LIST_RECEIVED,
    RETURNS_LOADED,
    RETURNS_INIT,
} from '../../actions';

const REQUEST_PARAMS = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
};

const INITIAL_STATE = {
    ...REQUEST_PARAMS,
    loading: false,
};

const returns = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RETURNS_LOADED:
            return {
                ...state,
                loading: false,
            };

        case RETURNS_INIT:
            return { ...INITIAL_STATE };

        case RETURNS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case RETURNS_LIST_RECEIVED:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
export default returns;
