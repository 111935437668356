import { ORDER_ITEM_VALUE_UPDATE } from '../../actions';

const INITIAL_STATE = {
    prop: null,
    value: null,
};

const orderItem = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORDER_ITEM_VALUE_UPDATE:
            return { ...state, [action.prop]: action.value };
        default:
            return state;
    }
};
export default orderItem;
