import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './Widget.css';

class Widget extends Component {
    render() {
        const {
            title,
            loading,
            children,
            footer,
            footerClassName,
            contentClassName,
            show,
        } = this.props;
        if (!show) return null;
        return (
            <Segment
                style={{ height: '100%', padding: '0.5em' }}
                loading={loading}
                data-test="widgetWrapper"
            >
                <div className="widget">
                    <span className="bold widget-title" data-test="widgetTitle">
                        {title}
                    </span>
                    <div className={`widget-content ${contentClassName || ''}`}>
                        {children}
                    </div>
                    {footer && (
                        <div
                            className={`widget-footer ${footerClassName || ''}`}
                        >
                            {footer}
                        </div>
                    )}
                </div>
            </Segment>
        );
    }
}

Widget.defaultProps = {
    show: true,
};

Widget.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    show: PropTypes.bool,
};

export default Widget;
