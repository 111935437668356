import { MARK_TASK_LOADING, MARK_TASK_DONE } from '../../actions';

const INITIAL_STATE = {
    loading: false,
};

const updateUTR = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MARK_TASK_LOADING:
            return { ...state, loading: true };
        case MARK_TASK_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default updateUTR;
