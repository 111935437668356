import { Auth } from 'aws-amplify';
import { cognitoLogin } from '../auth';
import { Dispatch } from 'redux';

import {
    CognitoLoginActionTypes,
    ENABLE_LOADING,
} from '../../type/cognito/cognito-login';

export const cognitoLoginByProvider = async (provider) => {
    Auth.federatedSignIn({ provider });
};

export const checkUserLogin = () => {
    return async (dispatch: Dispatch<CognitoLoginActionTypes>) => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('provider') != null) {
            cognitoLoginByProvider(urlParams.get('provider'));
        }
        let currentUser;
        try {
            currentUser = await Auth.currentUserInfo();
        } catch (cognitoError) {
            console.error(cognitoError);
        }
        if (currentUser) {
            dispatch(cognitoLogin());
        } else {
            dispatch({
                type: ENABLE_LOADING,
                payload: false,
            });
        }
    };
};
