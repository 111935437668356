import { InventoryApi } from '../../../services/retailer-inventory-service';
import { GamesApi } from '../../../services/games-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import _ from 'lodash';
import moment from 'moment/moment';

export const RETAILER_INVENTORY_INIT_BOOKS_SUCCESS =
    'RETAILER_INVENTORY_INIT_BOOKS_SUCCESS';
export const RETAILER_INVENTORY_LIST = 'RETAILER_INVENTORY_LIST';
export const RETAILER_INVENTORY_LIST_LOADING =
    'RETAILER_INVENTORY_LIST_LOADING';
export const RETAILER_INVENTORY_LIST_LOADING_DONE =
    'RETAILER_INVENTORY_LIST_LOADING_DONE';
export const RETAILER_INVENTORY_UNRETURNED_BOOKS =
    'RETAILER_INVENTORY_UNRETURNED_BOOKS';

export const RETAILER_ORDER_DETAILS = 'RETAILER_ORDER_DETAILS';
export const RETAILER_ORDER_DETAILS_LOADING = 'RETAILER_ORDER_DETAILS_LOADING';
export const RETAILER_ORDER_DETAILS_LOADING_DONE =
    'RETAILER_ORDER_DETAILS_LOADING_DONE';
export const RETAILER_ORDER_DETAILS_SHOW_MODAL =
    'RETAILER_ORDER_DETAILS_SHOW_MODAL';
export const RETAILER_ORDER_DETAILS_CLOSE_MODAL =
    'RETAILER_ORDER_DETAILS_CLOSE_MODAL';

export const GAME_DETAILS = 'GAME_DETAILS';
export const GAME_DETAILS_LOADING = 'GAME_DETAILS_LOADING';
export const GAME_DETAILS_LOADING_DONE = 'GAME_DETAILS_LOADING_DONE';

export const RETAILER_ORDER_DETAILS_BY_REFERENCE =
    'RETAILER_ORDER_DETAILS_BY_REFERENCE';
export const RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING =
    'RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING';
export const RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING_DONE =
    'RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING_DONE';

const inventoryApi = new InventoryApi();
const gamesApi = new GamesApi();

const retailerInventoryListError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'RetailerInventoryList',
            })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const findLatestShipments = (args) => {
    //preload the shipment details to allow for caching.
    return async (dispatch) => {
        try {
            dispatch({ type: RETAILER_INVENTORY_LIST_LOADING });
            const list = await inventoryApi.findLatestShipments(args);
            const uniqGameNumbers = _.uniq(
                _.map(
                    _.flatMap(list, ({ details }) => details),
                    ({ gameNumber }) => gameNumber
                )
            );
            const games = await gamesApi.getGameDetailsByGameNumber(
                uniqGameNumbers
            );
            list.forEach((order) => {
                order.details.forEach((detail) => {
                    const game = _.find(
                        games,
                        ({ reference }) => reference === detail.gameNumber
                    );
                    detail.name = game ? game.name : '-';
                });
            });

            dispatch({
                type: RETAILER_INVENTORY_LIST,
                list,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerInventoryListError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: RETAILER_INVENTORY_LIST_LOADING_DONE });
        }
    };
};

export const getShipmentOrderInTransit = (reference) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING });
            const list = await inventoryApi.findLatestShipments(reference);
            dispatch({
                type: RETAILER_ORDER_DETAILS_BY_REFERENCE,
                list,
            });
        } catch (err) {
            dispatch(createErrorHandlerError(err));
            success = false;
        } finally {
            dispatch({
                type: RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING_DONE,
            });
        }
        return success;
    };
};

export const getRetailerOrderWithPOSItemsDetails = (shipmentId, posItems) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: RETAILER_ORDER_DETAILS_LOADING });

            dispatch({ type: RETAILER_ORDER_DETAILS_SHOW_MODAL });

            const { listRetailerInventory } = getState();
            let newDetails = [];
            let data = _.find(
                listRetailerInventory.content,
                ({ id }) => `${id}` === shipmentId
            );
            const gameDataDetails = _.filter(
                data.details,
                (item) => item.name !== '-'
            );

            _.map(data.details, (item) =>
                _.map(posItems, (pItem) => {
                    if (item.gameNumber === pItem.name) {
                        newDetails.push({
                            gameNumber: item.gameNumber,
                            lineNumber: item.lineNumber,
                            name: pItem.description,
                            posNumber: pItem.id,
                            quantity: item.quantity,
                            sfaOrderNumber: item.sfaOrderNumber,
                        });
                    }
                })
            );

            data.details = [...gameDataDetails, ...newDetails];

            dispatch({
                type: RETAILER_ORDER_DETAILS,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerInventoryListError(err));
            dispatch({ type: RETAILER_ORDER_DETAILS_LOADING_DONE });
        }
    };
};

export const getRetailerOrderDetails = (shipmentId) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: RETAILER_ORDER_DETAILS_LOADING });

            dispatch({ type: RETAILER_ORDER_DETAILS_SHOW_MODAL });

            const { listRetailerInventory } = getState();

            const data = _.find(
                listRetailerInventory.content,
                ({ id }) => `${id}` === shipmentId
            );

            dispatch({
                type: RETAILER_ORDER_DETAILS,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(retailerInventoryListError(err));
            dispatch({ type: RETAILER_ORDER_DETAILS_LOADING_DONE });
        }
    };
};

export const orderDetailsCloseModal = () => ({
    type: RETAILER_ORDER_DETAILS_CLOSE_MODAL,
});

export const initInactivatedBooks = (args) => {
    return async (dispatch) => {
        try {
            const inactivatedGameBooks = await gamesApi.getInactivatedGames(
                args
            );

            dispatch({
                type: RETAILER_INVENTORY_INIT_BOOKS_SUCCESS,
                inactivatedGameBooks,
            });
        } catch (err) {
            console.error(err);
        }
    };
};

export const fetchUnReturnedBooks = (retailerReferences) => {
    return async (dispatch, getState) => {
        try {
            if (!_.isEmpty(retailerReferences)) {
                const {
                    organization: { recallCreationPeriod, recallGracePeriod },
                } = getState();

                //get all the games where the grace period has ended as of today
                //gameStartDate + grace <= today === gameStartDate <= today - grace
                const gameRecallDateOnOrBefore = moment()
                    .subtract(recallGracePeriod, 'days')
                    .format('YYYY-MM-DD');

                //from end of grace period + task create period
                //and only the games where the task creation period is still open
                //gameStartDate + grace + taskCreatePeriod >= today === gameStartDate >= today - grace - taskCreatePeriod
                const gameRecallDateOnOrAfter = moment()
                    .subtract(recallGracePeriod, 'days')
                    .subtract(recallCreationPeriod, 'days')
                    .format('YYYY-MM-DD');

                const unReturnedGameBooks = await gamesApi.getUnReturnedGames([
                    createFilterProp('retailerReferences', retailerReferences),
                    createFilterProp('gameRecallDateOnOrBefore', [
                        gameRecallDateOnOrBefore,
                    ]),
                    createFilterProp('gameRecallDateOnOrAfter', [
                        gameRecallDateOnOrAfter,
                    ]),
                ]);

                dispatch({
                    type: RETAILER_INVENTORY_UNRETURNED_BOOKS,
                    unReturnedGameBooks,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };
};

const createFilterProp = (property, data) => ({
    property,
    data,
});
