import _ from 'lodash';
import moment from 'moment';
import { PrizeWinningsApi } from '../../services/prize-winnings-service';
import { OrganizationApi } from '../../services/organization-service';
import { RetailersApi } from '../../services/retailers-service';
import { SalesRoutesApi } from '../../services/sales-routes-service';

export const PRIZE_WINNINGS_INIT = 'PRIZE_WINNINGS_INIT';
export const PRIZE_WINNINGS_LOADING = 'PRIZE_WINNINGS_LOADING';
export const PRIZE_WINNINGS_LOADED = 'PRIZE_WINNINGS_LOADED';
export const PRIZE_WINNINGS_DASHBOARD_LOADING =
    'PRIZE_WINNINGS_DASHBOARD_LOADING';
export const PRIZE_WINNINGS_DASHBOARD_LOADED =
    'PRIZE_WINNINGS_DASHBOARD_LOADED';

const prizeWinningsApi = new PrizeWinningsApi();
const organizationApi = new OrganizationApi();
const retailersApi = new RetailersApi();
const salesRoutesApi = new SalesRoutesApi();

export const initPrizeWinnings = () => ({ type: PRIZE_WINNINGS_INIT });

export const getHighPrizeWinningsDashBoardData = (userInfo) => {
    return async (dispatch) => {
        dispatch({ type: PRIZE_WINNINGS_DASHBOARD_LOADING });

        const highTierPrizeThreshold =
            await organizationApi.getHighTierPrizeThreshold();
        const dashBoardHighTierWinsDays =
            await organizationApi.getDashBoardHighTierWinsDays();
        const dashBoardHighTierNumberOfWinsToDisplay =
            await organizationApi.getDashBoardHighTierWinsCount();

        //calculate date range as from today - dashBoardHighTierWinsDays to today - 1 day
        const dashBoardHighTierWinsFromDate = moment()
            .subtract(dashBoardHighTierWinsDays, 'days')
            .format('YYYY-MM-DD');
        const dashBoardHighTierWinsToDate = moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

        const args = new FormData();
        args.append('from', dashBoardHighTierWinsFromDate);
        args.append('to', dashBoardHighTierWinsToDate);
        args.append('threshold', highTierPrizeThreshold);

        const dashBoardHighTierWinsFullCount =
            await prizeWinningsApi.highTierPrizeWinningsCount(args);

        const RetailerIdsForSalesRoutesForSalesRep =
            await salesRoutesApi.getRetailerIdsForSalesRoutesForSalesRep(
                userInfo.user_name
            );
        const RetailerIdsForSalesRoutesForSalesManager =
            await salesRoutesApi.getRetailerIdsForSalesRoutesForSalesManager(
                userInfo.user_name
            );
        const retailerIds = _.concat(
            RetailerIdsForSalesRoutesForSalesRep,
            RetailerIdsForSalesRoutesForSalesManager
        );
        const retailerIdToReferenceMap = await retailersApi.getReferencesByIds(
            retailerIds
        );
        const retailerReferences = _.map(
            retailerIdToReferenceMap,
            (key, value) => key
        );
        args.append('retailers', _.join(retailerReferences, ','));

        const dashBoardHighTierWinsRetailersCount =
            retailerReferences.length === 0
                ? 0
                : await prizeWinningsApi.highTierPrizeWinningsCount(args);

        dispatch({
            type: PRIZE_WINNINGS_DASHBOARD_LOADED,
            highTierPrizeThreshold,
            dashBoardHighTierWinsDays,
            dashBoardHighTierNumberOfWinsToDisplay,
            dashBoardHighTierWinsFullCount,
            dashBoardHighTierWinsRetailersCount,
            dashBoardHighTierWinsFromDate,
            dashBoardHighTierWinsToDate,
            retailerReferences,
        });
    };
};

export const searchPrizeWinnings = (args) => {
    return async (dispatch) => {
        dispatch({ type: PRIZE_WINNINGS_LOADING });

        const highTierPrizeThreshold =
            await organizationApi.getHighTierPrizeThreshold();

        args.filters = handlePrizeTypeFilters(
            args.filters,
            highTierPrizeThreshold
        );

        const list = await prizeWinningsApi.searchPrizeWinnings(args);

        const retailerReferences = _.map(
            list.content,
            (prizeWinning) => prizeWinning.retailerReference
        );

        const retailers = await retailersApi.getRetailersByReferences(
            retailerReferences
        );

        _.each(list.content, (prizeWinning) => {
            prizeWinning.prizeType =
                prizeWinning.prizeAmount > highTierPrizeThreshold
                    ? 'High-Tier'
                    : 'Chatter';

            const retailer = _.find(retailers, [
                'reference',
                prizeWinning.retailerReference,
            ]);
            prizeWinning.retailerName =
                retailer && retailer.name
                    ? retailer.name
                    : prizeWinning.retailerReference;
        });

        dispatch({
            type: PRIZE_WINNINGS_LOADED,
            list,
            highTierPrizeThreshold,
        });
    };
};

function handlePrizeTypeFilters(filters, highTierPrizeThreshold) {
    const prizeTypeFilters = _.find(filters, ['property', 'prizeType']);
    if (
        prizeTypeFilters &&
        prizeTypeFilters.data &&
        prizeTypeFilters.data.length === 1
    ) {
        filters.push({
            property: prizeTypeFilters.data[0],
            data: [`${highTierPrizeThreshold}`],
        });
    }
    return filters;
}
