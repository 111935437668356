import React, { Component } from 'react';
import { Input, Button, Icon } from 'semantic-ui-react';
import './styles.css';

class NumberIncrementor extends Component {
    render() {
        const { label, onIncrease, onDecrease, value, disabled } = this.props;
        return (
            <div className="number-incrementor">
                <div className="label">
                    <span>{label}</span>
                </div>
                <div className="control-wrapper">
                    <Input readOnly value={value} />
                    <Button onClick={onIncrease}>
                        <Icon className="icon-Plus" />
                    </Button>
                    <Button onClick={onDecrease} disabled={disabled}>
                        <Icon className="icon-Minus" />
                    </Button>
                </div>
            </div>
        );
    }
}

export default NumberIncrementor;
