import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import _, { sortBy, uniqBy, map } from 'lodash';
import {
    clearNotification,
    getAllOrderableGameItems,
} from '../../redux/actions';
import { formatMoney } from '../../constants/utils';
import Page from '../../layout/Page';
import GameCatalog from '../../components/gameCatalog/GameCatalog';
import './styles.css';

export class GameCatalogPage extends Component {
    async componentDidMount() {
        await this.init();
    }

    init = async () => {
        const retailerType =
            this.props.retailerInfo && this.props.retailerInfo.type;
        const jurisdiction = this.props.retailerInfo?.jurisdiction?.code;
        await this.props.onGetGameData(
            retailerType && retailerType.reference,
            jurisdiction
        );
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }

    componentWillUnmount() {
        this.props.clearNotifications();
    }

    formatPrice = (price) => formatMoney(price);

    getHeaderList = () => {
        let data = uniqBy(
            map(sortBy(this.props.orderableGames, 'price'), (game) => {
                if (game.gameType === 'PS') {
                    return {
                        name: 'POS',
                        id: game.gameType,
                    };
                } else {
                    return {
                        name: this.formatPrice(game.price),
                        id: game.price,
                    };
                }
            }),
            'name'
        );
        try {
            const index = data.findIndex((item) => item.name === 'POS');
            if (index !== -1) {
                const posItem = data[index];
                data.splice(index, 1);
                data.splice(data.length, 0, posItem);
            }
        } catch (e) {}
        return data;
    };

    formatHeaderText = (game) => formatMoney(game.price);

    render() {
        const {
            loading,
            orderableGames,
            suggestedQuantity,
            games,
            isEditOrder,
            parentModalRef,
        } = this.props;
        const themes = _.uniqBy(games, function (game) {
            return game.themeName;
        });
        const gamesList = this.getHeaderList();
        return (
            <Page name="GameCatalogPage" data-test="GameCatalogPage">
                <Dimmer active={loading} page>
                    <Loader />
                </Dimmer>
                {
                    <GameCatalog
                        headerList={gamesList}
                        games={orderableGames}
                        themes={themes}
                        suggestedQuantity={suggestedQuantity}
                        groupByProp="formatedPrice"
                        groupByPropForPos="headerText"
                        sortByProp="gameRefAndName"
                        headerKey={this.formatHeaderText}
                        stickyHeader={true}
                        isEditOrder={isEditOrder}
                        parentModalRef={parentModalRef}
                    />
                }
            </Page>
        );
    }
}

const mapStateToProps = ({
    auth,
    gameItems: { filteredGames, content, loading },
    userInfo: { retailerInfo },
    suggestedQuantity,
}) => {
    return {
        auth,
        orderableGames: filteredGames,
        games: content,
        loading: loading,
        retailerInfo,
        suggestedQuantity: suggestedQuantity.suggestedQuantityMap,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGameData: (retailerType, jurisdiction) =>
            dispatch(getAllOrderableGameItems(retailerType, jurisdiction)),
        clearNotifications: () => dispatch(clearNotification()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameCatalogPage);
