import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { HotKeys } from 'react-hotkeys';
import { getPrivateFaqHtml, initPrivateFaq } from '../../redux/actions';
import Page from '../../layout/Page';

class FaqPage extends Component {
    componentDidMount() {
        this.props.getData();
        this._container.focus();
    }

    componentWillUnmount() {
        this.props.init();
    }

    render() {
        const { loading, faqHtml } = this.props;
        return (
            <HotKeys
                innerRef={(faqContainer) => (this._container = faqContainer)}
            >
                <Page name="FaqPage" title="Frequently Asked Questions">
                    <Dimmer page inverted active={loading}>
                        <Loader />
                    </Dimmer>
                    <div
                        className="data-section"
                        dangerouslySetInnerHTML={{
                            __html: faqHtml,
                        }}
                    />
                </Page>
            </HotKeys>
        );
    }
}

const mapStateToProps = ({ privateFaq }) => ({ ...privateFaq });

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => dispatch(getPrivateFaqHtml()),
        init: () => dispatch(initPrivateFaq()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FaqPage)
);
