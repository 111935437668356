import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';

class SidebarItem extends Component {
    state = {
        collapsed: false,
    };

    _renderSingleItem = () => {
        const { title, leftIcon, leftIconSize, navigateTo, subMenu } =
            this.props;
        const { containerStyle, titleStyle } = styles;

        const _titleStyle = {
            ...titleStyle,
            marginLeft: 10,
        };

        const paddingLeft = subMenu ? 30 : 0;
        const titleFontSize = subMenu ? '12px' : '14px';

        const iconColor = subMenu ? '#0C0033' : '#4686ce';
        let iconSize = leftIconSize || 24;
        if (subMenu) {
            iconSize -= 2;
        }

        return (
            <Menu.Item
                onClick={(e, { name }) => {
                    this.props.setActive(name);
                    if (navigateTo) {
                        navigateTo();
                    }
                }}
                name={this._createName(title)}
                active={this._createName(title) === this.props.activeItem}
            >
                <div
                    style={{ ...containerStyle, paddingLeft }}
                    className={`body-text ${
                        subMenu ? 'nav-sub-item' : 'nav-item'
                    }`}
                >
                    <i
                        className={leftIcon}
                        style={{ fontSize: iconSize, color: iconColor }}
                    >
                        {' '}
                    </i>
                    <span style={{ ..._titleStyle, fontSize: titleFontSize }}>
                        {title}
                    </span>
                </div>
            </Menu.Item>
        );
    };

    _createName = (name) => name.replace(/[^a-z]/gi, '');

    _doCollapse = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    _renderGroup = () => {
        const { title, leftIcon, leftIconSize, children, subMenu } = this.props;
        const rightIcon = this.state.collapsed
            ? 'icon-Arrow-Up-2'
            : 'icon-Arrow-Down';
        const { containerStyle, titleStyle } = styles;

        const paddingLeft = subMenu ? 30 : 0;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
            >
                <Menu.Item
                    onClick={() => {
                        this._doCollapse();
                    }}
                >
                    <div
                        style={{ ...containerStyle, paddingLeft }}
                        className={`body-text ${
                            subMenu ? 'nav-sub-item' : 'nav-item'
                        }`}
                    >
                        <span
                            className={leftIcon}
                            style={{
                                fontSize: leftIconSize || 24,
                                color: '#4686ce',
                            }}
                        >
                            {' '}
                        </span>
                        <span style={{ ...titleStyle, fontSize: 14 }}>
                            {title}
                        </span>
                        <span
                            className={rightIcon}
                            style={{ fontSize: 24, color: '#4686ce' }}
                        >
                            {' '}
                        </span>
                    </div>
                </Menu.Item>
                {this.state.collapsed && children}
            </div>
        );
    };

    render() {
        const { children } = this.props;

        return !children ? this._renderSingleItem() : this._renderGroup();
    }
}

const styles = {
    containerStyle: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 5px',
        minHeight: '24px',
        alignItems: 'center',
        textAlign: 'left',
    },
    leftIconStyle: {
        fontSize: 22,
    },
    titleStyle: {
        flex: 1,
        marginLeft: 10,
    },
};

export default connect(null, null)(SidebarItem);
