import { ReturnsApi } from '../../../services/returns-service';
import { error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

const returnsApi = new ReturnsApi();

export const RETURNS_LOADING = 'PENDING_RETURNS_LOADING';
export const RETURNS_LIST_RECEIVED = 'RETURNS_LIST_RECEIVED';
export const RETURNS_LOADED = 'RETURNS_LOADED';
export const RETURNS_INIT = 'RETURNS_INIT';

export const getAllReturns = (args) => async (dispatch) => {
    let returnsList;
    dispatch({ type: RETURNS_LOADING });
    try {
        returnsList = await returnsApi.getAllReturns(args);
        dispatch({
            type: RETURNS_LIST_RECEIVED,
            payload: returnsList,
        });
    } catch (e) {
        dispatch(error({ msg: getErrorMessage(e) }));
    }
    dispatch({ type: RETURNS_LOADED });
};

export const initReturns = () => ({
    type: RETURNS_INIT,
});
