import {
    ORGANIZATION_LOADING,
    ORGANIZATION_LOADING_DONE,
    ORGANIZATION_SETUP,
    ORGANIZATION_UPDATE_LOADING,
    ORGANIZATION_UPDATE_SUCCESS,
    ORGANIZATION_UPDATE_DONE,
    ORGANIZATION_UPDATE_INIT,
    ORGANIZATION_CONFIG_SELECTED,
    ORGANIZATION_DEFAULT_LANG,
    LOGO_UPLOAD_DONE,
    LOGO_UPLOAD_SUCCESS,
    LOGO_UPLOAD_PROCESSING,
    LOGO_UPLOAD_INIT,
    LOGO_KEY_SUCCESS,
    FAQ_FILE_UPLOAD_INIT,
    FAQ_FILE_UPLOAD_PROCESSING,
    FAQ_FILE_UPLOAD_SUCCESS,
    FAQ_FILE_UPLOAD_DONE,
    FAQ_FILE_DOWNLOAD_SUCCESS,
} from '../actions/organization';

const INITIAL_STATE_LOGO = {
    loading: false,
    processing: false,
};

const INITIAL_STATE_FAQ_FILE = {
    loading: false,
    processing: false,
};

const INITIAL_STATE = {
    loading: false,
    configs: [],
    configToUpdate: {},
};

const organization = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORGANIZATION_LOADING:
        case ORGANIZATION_UPDATE_LOADING:
            return { ...INITIAL_STATE, loading: true };
        case ORGANIZATION_LOADING_DONE:
        case ORGANIZATION_UPDATE_DONE:
        case LOGO_UPLOAD_DONE:
            return { ...state, loading: false };
        case ORGANIZATION_SETUP:
            return {
                ...state,
                configs: [...action.config],
            };
        case ORGANIZATION_UPDATE_SUCCESS:
            return {
                ...state,
                configs: [],
            };
        case ORGANIZATION_UPDATE_INIT:
            return {
                ...INITIAL_STATE,
                configs: [],
                loading: false,
                processing: false,
            };
        case ORGANIZATION_CONFIG_SELECTED:
            return { ...state, configToUpdate: { ...action.config } };
        case ORGANIZATION_DEFAULT_LANG:
            return { ...state, defaultLanguage: action.language };
        case LOGO_UPLOAD_PROCESSING:
            return { ...state, processing: true };
        case LOGO_UPLOAD_SUCCESS:
            return { ...state, processing: false };
        case LOGO_UPLOAD_INIT:
            return { ...state, ...INITIAL_STATE_LOGO };
        case LOGO_KEY_SUCCESS:
            return { ...state, appLogo: action.logoKey };
        case FAQ_FILE_UPLOAD_DONE:
            return { ...state, loading: false };
        case FAQ_FILE_UPLOAD_PROCESSING:
            return { ...state, processing: true };
        case FAQ_FILE_UPLOAD_SUCCESS:
            return { ...state, processing: false };
        case FAQ_FILE_UPLOAD_INIT:
            return { ...state, ...INITIAL_STATE_FAQ_FILE };
        case FAQ_FILE_DOWNLOAD_SUCCESS:
            return { ...state, faqFile: action.file };
        default:
            return state;
    }
};
export default organization;
