import { Backend } from './backend';
import { checkFilters } from './utils';

class PrizeWinningsApi {
    constructor() {
        this.path = '/games/prize-winners';
    }

    async searchPrizeWinnings({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'claimedDate',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async highTierPrizeWinningsCount(args) {
        //If retailerReferences in null then get for all retailers
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/count/`,
                body: args,
            });
        } catch (e) {
            throw e;
        }
    }

    async retailerPrizeWinningsAmount(
        retailerReference,
        fromDate,
        toDate,
        threshold,
        isBelowThreshold
    ) {
        try {
            //if has threshold and is belowThreshold then append to query string
            let path = `${this.path}/amount/?from=${fromDate}&to=${toDate}&retailerReference=${retailerReference}`;

            if (typeof threshold === 'number') {
                path += `&threshold=${threshold}`;
            }

            if (isBelowThreshold) {
                path += `&belowThreshold=${isBelowThreshold}`;
            }

            return await Backend.send({
                method: 'GET',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { PrizeWinningsApi };
