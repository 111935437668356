import {
    TIER_OPTIONS_LOADING,
    GET_TIER_OPTIONS_SUCCESS,
} from '../../actions/tier/tierOptions';

const INITIAL_STATE = {
    loading: false,
    options: [],
};

const tierOptions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TIER_OPTIONS_LOADING:
            return { ...state, loading: true };
        case GET_TIER_OPTIONS_SUCCESS:
            return { ...state, options: action.options, loading: false };
        default:
            return state;
    }
};
export default tierOptions;
