import {
    GENERATE_INVOICE_LOADING,
    GENERATE_INVOICE_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
};

const generateInvoice = (state = INITIAL_STATE, { type }) => {
    switch (type) {
        case GENERATE_INVOICE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GENERATE_INVOICE_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default generateInvoice;
