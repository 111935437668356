import {
    PRIVATE_CONTACT_LOADING,
    PRIVATE_CONTACT_LOADING_DONE,
    PRIVATE_CONTACT_INIT,
    PRIVATE_CONTACT_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    contact: null,
};

const privateContact = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PRIVATE_CONTACT_GET:
            return {
                ...state,
                contact: payload,
            };
        case PRIVATE_CONTACT_INIT:
            return { ...INITIAL_STATE };

        case PRIVATE_CONTACT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PRIVATE_CONTACT_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default privateContact;
