import { Backend } from './backend';

class PrivilegesApi {
    constructor() {
        this.path = '/users/privileges';
    }

    async getPrivilegesList() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/engagement/get-privilege-list`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { PrivilegesApi };
