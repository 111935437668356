import {
    REPORT_GENERATE_LOADING,
    REPORT_GET_LOADING,
    REPORT_GET_SUCCESS,
    REPORT_INIT,
} from '../../actions';

const INITIAL_STATE = {
    generating: false,
    loading: false,
    currentReport: {},
};

const generateReport = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REPORT_INIT:
            return {
                ...INITIAL_STATE,
            };
        case REPORT_GENERATE_LOADING:
            return { ...state, generating: action.generating };
        case REPORT_GET_LOADING:
            return { ...state, loading: action.loading };
        case REPORT_GET_SUCCESS:
            return { ...state, currentReport: { ...action.report } };
        default:
            return state;
    }
};
export default generateReport;
