import {
    JurisdictionActionTypes,
    JurisdictionStateType,
    GET_JURISDICTION_LIST,
    UPDATE_LOADING_STATE,
} from '../../type/Jurisdiction/Jurisdiction-type';

const initialState: JurisdictionStateType = {
    isLoading: false,
    jurisdictions: [],
};
const jurisdictionReducer = (
    state: JurisdictionStateType = initialState,
    action: JurisdictionActionTypes
) => {
    switch (action.type) {
        case GET_JURISDICTION_LIST:
            return {
                ...state,
                isLoading: false,
                jurisdictions: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export default jurisdictionReducer;
