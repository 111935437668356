import {
    RETAILER_PRIZE_WINNINGS_INIT,
    RETAILER_PRIZE_WINNINGS_LOADING,
    RETAILER_PRIZE_WINNINGS,
    RETAILER_PRIZE_MID_TIER_WINNINGS,
} from '../actions/retailerPrizeWinnings';

const INITIAL_STATE = {
    loading: false,
    prizeWinningsSinceLastVisit: 0,
    midTierWinnings: 0,
    lastVisitDate: null,
    retailerReference: null,
};

const retailerPrizeWinnings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_PRIZE_WINNINGS_INIT:
            return { ...INITIAL_STATE };

        case RETAILER_PRIZE_WINNINGS_LOADING:
            return { ...state, loading: true };

        case RETAILER_PRIZE_WINNINGS:
            return {
                ...state,
                prizeWinningsSinceLastVisit: action.prizeWinningsSinceLastVisit,
                lastVisitDate: action.lastVisitDate,
                retailerReference: action.retailerReference,
                loading: false,
            };
        case RETAILER_PRIZE_MID_TIER_WINNINGS:
            return {
                ...state,
                retailerReference: action.retailerReference,
                midTierWinnings: action.midTierWinnings,
                loading: false,
            };
        default:
            return state;
    }
};
export default retailerPrizeWinnings;
