import { TextNotesApi } from '../../../services/text-notes-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { NOTIFY_SUCCESS, NOTIFY_ERROR } from '../../actions';

export const TEXT_NOTE_CREATE_LOADING = 'TEXT_NOTE_CREATE_LOADING';
export const TEXT_NOTE_CREATE_LOADING_DONE = 'TEXT_NOTE_CREATE_LOADING_DONE';
export const CREATE_TEXT_NOTE = 'CREATE_TEXT_NOTE';

const textNotesApi = new TextNotesApi();

const textNotesError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'ReadRetailer' })
        );
    };
};

export const createTextNote = (
    noteText,
    referenceId,
    referenceType,
    retailerName,
    retailerReference
) => {
    return async (dispatch, getState) => {
        let success = true;
        try {
            dispatch({ type: TEXT_NOTE_CREATE_LOADING });
            const { offline } = getState();

            const args = {
                referenceType: referenceType,
                reference: referenceId,
                text: noteText,
                retailerReference: retailerReference ? retailerReference : '',
                retailerName: retailerName ? retailerName : '',
            };

            //TODO: do optimistic state update
            if (offline.online) {
                await textNotesApi.createTextNote(args);
            } else {
                dispatch({
                    type: CREATE_TEXT_NOTE,
                    payload: { args },
                    meta: {
                        offline: {
                            effect: {
                                method: 'POST',
                                url: '/notes/',
                                body: { ...args },
                            },
                            commit: {
                                type: NOTIFY_SUCCESS,
                                msg: 'Successfully added queued note',
                                target: 'ReadRetailer',
                            },
                            rollback: {
                                type: NOTIFY_ERROR,
                                msg: 'Queued Text Note was not created',
                                target: 'ReadRetailer',
                            },
                        },
                    },
                });
            }
        } catch (e) {
            console.error(e);

            success = false;
            dispatch(textNotesError(e));
        } finally {
            dispatch({ type: TEXT_NOTE_CREATE_LOADING_DONE });
        }

        return success;
    };
};
