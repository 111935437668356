import {
    CANCEL_RETURN__LOADING,
    CANCEL_RETURN__INIT,
    CANCEL_RETURN__LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
};

const cancelReturn = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CANCEL_RETURN__LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case CANCEL_RETURN__LOADING:
            return {
                ...state,
                loading: true,
            };

        case CANCEL_RETURN__INIT:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

export default cancelReturn;
