import {
    PENDING_RETURNS__DISPLAY_MODAL,
    PENDING_RETURNS__SET_PENDING_RETURN_ITEM,
    PENDING_RETURNS__CREATE_RETURN_RESET,
    PENDING_RETURNS__MODAL_LOADING,
    PENDING_RETURNS__MODAL_LOADED,
} from '../../actions';

const METADATA = {
    retailerInfo: null,
};

const PENDING_RETURN_DETAILS = {
    pendingReturnItems: [],
};

const INITIAL_STATE = {
    showCreateModal: false,
    loading: false,
    pendingReturnDetails: PENDING_RETURN_DETAILS,
    metadata: METADATA,
};

const returns = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PENDING_RETURNS__MODAL_LOADING:
            return { ...state, loading: true };

        case PENDING_RETURNS__MODAL_LOADED:
            return { ...state, loading: false };

        case PENDING_RETURNS__DISPLAY_MODAL:
            return {
                ...state,
                showCreateModal: payload,
            };
        case PENDING_RETURNS__SET_PENDING_RETURN_ITEM:
            return {
                ...state,
                pendingReturnDetails: {
                    pendingReturnItems: payload,
                },
            };
        case PENDING_RETURNS__CREATE_RETURN_RESET:
            return {
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
};
export default returns;
