import _ from 'lodash';
import moment from 'moment';

import { checkFilters } from './utils';
import { Backend } from './backend';
import Store from '../redux/store';

class TaskApi {
    constructor() {
        this.path = '/tasks';
    }

    enhanceFilters = (filters) => {
        const {
            auth: { info },
        } = Store.store.getState();
        const filterIndex = _.findIndex(
            filters,
            (f) =>
                f.property === 'assignmentType' &&
                _.findIndex(f.data, (d) => d === 'MYSELF') !== -1
        );
        if (filterIndex >= 0) {
            const tmp = _.cloneDeep(filters);
            const filter = tmp[filterIndex];
            _.remove(filter.data, (f) => f === 'MYSELF');
            //myself is actually assignment type=USER && assignee id=current login user id
            filter.data.push('USER');
            tmp.push({ property: 'assignee', data: [info.user_id] });
            return tmp;
        }

        return filters;
    };

    async getAllTasksCreatedByMe({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/me/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    filters: this.enhanceFilters(checkFilters(filters)),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllTasks({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    filters: this.enhanceFilters(checkFilters(filters)),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async createTask(args) {
        try {
            const taskDto = this._mapCreateViewToDto(args);

            return await Backend.send({
                method: 'post',
                url: `${this.path}/`,
                body: taskDto,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTaskOptions() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/initCreateTask`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTaskToUpdate(id) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}/`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateTask(id, taskToUpdate) {
        try {
            const taskDto = this._mapCreateViewToDto(taskToUpdate);
            taskDto.id = id;

            return await Backend.send({
                method: 'post',
                url: `${this.path}/${id}/`,
                body: taskDto,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteTask(id) {
        try {
            return await Backend.send({
                method: 'delete',
                url: `${this.path}/${id}/`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateTaskUTR(task) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/utr/`,
                body: task,
            });
        } catch (e) {
            throw e;
        }
    }

    async bulkUpdateUTRs(tasks) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/utr/bulkUpdate/`,
                body: tasks,
            });
        } catch (e) {
            throw e;
        }
    }

    async getMyUncompletedRetailerTasks() {
        try {
            const path = `${this.path}/utr/myUncompletedRetailerTasks/`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getMyUncompletedNonRetailerTasks() {
        try {
            const path = `${this.path}/utr/myUncompletedNonRetailerTasks/`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAdHocTasksDueToday(dateInWeek, userId) {
        try {
            const path = userId
                ? `${this.path}/utr/adHocTasksDueToday/${userId}/${dateInWeek}`
                : `${this.path}/utr/myAdHocTasksDueToday/${dateInWeek}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllMyRetailerTasks() {
        try {
            const path = `${this.path}/utr/myRetailerTasks/`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllMyNonRetailerTasks() {
        try {
            const path = `${this.path}/utr/myNonRetailerTasks/`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllMyRetailerTasksForWeek(date) {
        try {
            const path = `${this.path}/utr/myRetailerTasksForWeek/${date}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllRetailerTasksForWeek(date, userId) {
        try {
            const path = `${this.path}/utr/retailerTasksForWeek/${userId}/${date}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllRetailerTasksCountForWeek(date, userIdAggregate) {
        try {
            const path = `${this.path}/utr/retailerTaskCountForWeek/${date}`;
            return await Backend.send({
                method: 'post',
                url: path,
                body: userIdAggregate,
            });
        } catch (e) {
            throw e;
        }
    }

    async getMyAdHocTasksForWeek(dateInWeek) {
        try {
            const path = `${this.path}/utr/myAdHocTasksForWeek/${dateInWeek}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAdHocTasksForWeek(dateInWeek, userId) {
        try {
            const path = `${this.path}/utr/adHocTasksForWeek/${userId}/${dateInWeek}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getMyPastDueTasks(date) {
        try {
            const path = `${this.path}/utr/myPastDueTasks/${date}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getUTRsFilter(
        { page, size, sortOrder, sortColumn, filters },
        idQueryStr
    ) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/utr/search${idQueryStr || ''}`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'dueDate',
                    filters: checkFilters(filters),
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesManagerSummary(dateInWeek, salesReps) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/summary/sales-manager/${dateInWeek}`,
                body: salesReps,
            });
        } catch (e) {
            throw e;
        }
    }

    async getLastCompletedScheduledTask(retailerIds) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/utr/lastCompletedScheduledTask`,
                body: retailerIds,
            });
        } catch (e) {
            throw e;
        }
    }

    _mapCreateViewToDto(viewModel) {
        const { auth } = Store.store.getState();

        const dto = {
            title: '',
            description: '',
            priority: '',
            category: '',
            taskSchedule: { recurrenceType: 'ONE_TIME' },
        };

        dto.title = viewModel.title;
        dto.description = viewModel.description;
        dto.category = viewModel.taskCategory;
        dto.priority = viewModel.priority;
        dto.includePlanogram = viewModel.includePlanogram;
        dto.hotTopic = viewModel.hotTopic;

        if (
            viewModel.taskCategory === 'COMPLIANCE' ||
            viewModel.taskCategory === 'MARKETING'
        ) {
            dto.surveyId = viewModel.survey ? viewModel.survey.id : null;
        }

        if (viewModel.taskSchedule) {
            dto.taskSchedule = viewModel.taskSchedule;
        }

        dto.taskSchedule.recurrenceType = viewModel.schedule;

        const today = new Date();
        const todayStr = moment(today).format('YYYY-MM-DD');

        if (viewModel.schedule === 'ONE_TIME') {
            dto.taskSchedule.dueDate = moment(viewModel.responseDueDate).format(
                'YYYY-MM-DD'
            );
            dto.taskSchedule.startDate = viewModel.startDate
                ? moment(viewModel.startDate).format('YYYY-MM-DD')
                : todayStr;
            dto.taskSchedule.endDate = moment(viewModel.responseDueDate).format(
                'YYYY-MM-DD'
            );
        } else {
            dto.taskSchedule.dueDate = null;
            dto.taskSchedule.startDate = viewModel.startDate
                ? moment(viewModel.startDate).format('YYYY-MM-DD')
                : todayStr;
            dto.taskSchedule.endDate = viewModel.endDate
                ? moment(viewModel.endDate).format('YYYY-MM-DD')
                : null;
        }

        switch (viewModel.assignee) {
            case 'RETAILER': {
                dto.taskAssignments = _.map(viewModel.retailers, (t) => ({
                    assignmentType: viewModel.assignee,
                    assignmentId: t,
                }));
                break;
            }
            case 'MYSELF': {
                dto.taskAssignments = [
                    {
                        assignmentType: 'USER',
                        assignmentId: auth.info.user_id,
                    },
                ];
                //ensure correct value for include planogram
                dto.includePlanogram = false;
                break;
            }
            case 'USER': {
                dto.taskAssignments = _.map(viewModel.users, (t) => ({
                    assignmentType: viewModel.assignee,
                    assignmentId: t,
                }));
                //ensure correct value for include planogram
                dto.includePlanogram = false;
                break;
            }
            case 'SALES_ROUTE': {
                dto.taskAssignments = _.map(
                    viewModel.salesRoutes,
                    (salesRoute) => ({
                        assignmentType: viewModel.assignee,
                        assignmentId: salesRoute.id,
                    })
                );

                break;
            }
            case 'ZONE': {
                dto.taskAssignments = _.map(viewModel.zones, (zone) => ({
                    assignmentType: viewModel.assignee,
                    assignmentId: zone.id,
                }));
                break;
            }
            case 'ACCOUNT': {
                dto.taskAssignments = _.map(
                    viewModel.corpAccounts,
                    (corpAccount) => ({
                        assignmentType: viewModel.assignee,
                        assignmentId: corpAccount.id,
                    })
                );
                break;
            }
            case 'ALL_RETAILERS': {
                // make sure to use the same assignmentId when updating a task
                const assignmentId =
                    viewModel.taskAssignments &&
                    viewModel.taskAssignments.length > 0
                        ? viewModel.taskAssignments[0].assignmentId
                        : auth.info.user_id;
                dto.taskAssignments = [
                    {
                        assignmentType: viewModel.assignee,
                        assignmentId: assignmentId,
                    },
                ];
                break;
            }
            default:
                dto.taskAssignments = [
                    {
                        assignmentType: viewModel.assignee,
                        assignmentId: auth.info.user_id,
                    },
                ];
                break;
        }

        if (viewModel.status) {
            dto.status = viewModel.status;
            if (
                dto.status === 'SCHEDULED' &&
                dto.taskSchedule.startDate === todayStr
            ) {
                dto.status = 'ACTIVE';
            } else if (dto.status === 'DRAFT') {
                dto.taskSchedule.startDate = null;
                dto.taskSchedule.endDate = null;
            }
        }

        const fullName = `${auth.info.lastName}, ${auth.info.firstName}`;
        dto.createdByFullName = fullName;

        return dto;
    }

    _mapToAssignmentDto(retailers, salesRoutes, zones) {
        return _.map(retailers, (retailerId) => {
            const retailer = {
                assignmentType: 'RETAILER',
                assignmentId: retailerId,
                parentTaskAssignmentDtos: [],
            };

            const mySalesRoutes = _.filter(salesRoutes, ({ retailerIds }) =>
                retailerIds.includes(retailerId)
            );

            if (mySalesRoutes.length > 0) {
                _.each(mySalesRoutes, (s) => {
                    retailer.parentTaskAssignmentDtos.push({
                        assignmentType: 'SALES_ROUTE',
                        assignmentId: s.id,
                    });
                });
            }

            if (zones.length > 0) {
                _.each(zones, (z) => {
                    retailer.parentTaskAssignmentDtos.push({
                        assignmentType: 'ZONE',
                        assignmentId: z.id,
                    });
                });
            }

            return retailer;
        });
    }
}

export { TaskApi };
