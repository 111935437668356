import {
    INVOICE_RETAILER_WIDGET_COMMISSION,
    INVOICE_RETAILER_WIDGET_COMMISSION_LOADING,
    INVOICE_RETAILER_WIDGET_COMMISSION_ERROR,
} from '../../actions/invoice/ytdTotalCommissions';

const INITIAL_STATE = {
    loading: false,
    commissions: undefined,
    errorResponse: null,
};

const ytdTotalCommissions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INVOICE_RETAILER_WIDGET_COMMISSION_LOADING:
            return { ...state, loading: action.loading };
        case INVOICE_RETAILER_WIDGET_COMMISSION:
            return {
                ...state,
                commissions: { ...action.commissions },
                loading: false,
            };
        case INVOICE_RETAILER_WIDGET_COMMISSION_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default ytdTotalCommissions;
