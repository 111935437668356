import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Menu, Segment } from 'semantic-ui-react';
import _ from 'lodash';

import FilterItem from './item/FilterItem';
import Badge from '../Badge';
import {
    addSelectedItem,
    clearFilters,
    filterToggle,
    initializeFilter,
    removeAllSelectedItems,
    removeSelectedItem,
    shouldApplyFilters,
} from './actions';
import { fetchRetailers } from '../../redux/actions';

import './styles.css';
import classnames from 'classnames';

export class FilterSection extends Component {
    state = {
        showFilters: false,
        retailerDetails: [],
        emailList: [],
        initialFilters: [],
    };

    async componentDidMount() {
        this.props.email && (await this.fetchRetailers());
        await this.setState({ initialFilters: this.props.filters });
        this._onClearFilters();
    }

    _onApplyFilters = (email) => {
        const { selectedItems, searchValue, onApplyFilter } = this.props;
        this.props.shouldApplyFilters();
        onApplyFilter(
            { ...selectedItems, applied: true, email: email },
            searchValue
        );
        this.setState({ showFilters: !this.state.showFilters });
    };

    _onClearFilters = () => {
        const { onClearFilters } = this.props;

        this.props.clearFilters();

        if (onClearFilters) {
            onClearFilters();
        }

        this.state.initialFilters.forEach((f) => {
            const { name, defaultSelected } = f;
            if (defaultSelected && defaultSelected !== '*') {
                if (_.isArray(defaultSelected)) {
                    this.props.initializeFilter({
                        name,
                        values: defaultSelected,
                    });
                } else {
                    this.props.initializeFilter({
                        name,
                        values: [defaultSelected],
                    });
                }
            }
        });
    };

    fetchRetailers = async () => {
        await this.props.onFetchRetailers({
            page: 0,
            size: 100000,
            sortOrder: 'asc',
            sortColumn: 'reference',
        });
    };

    checkForEmailAccount = (reference) => {
        const { retailers, emailButtonText, email } = this.props;
        let emailList = [];
        let result = emailList.length > 0;

        if (reference && !emailButtonText && email) {
            _.map(
                _.filter(
                    retailers.content,
                    (props) =>
                        props.reference === reference.reference ||
                        props.reference === reference
                ),
                (retailer) => {
                    _.map(retailer.contacts, (contact) => {
                        if (contact.receives_email === 'YES') {
                            emailList.push(contact.email);
                        }
                    });
                }
            );
            result = !emailList.length > 0;
        } else if (reference && emailButtonText && email) {
            _.map(reference.contacts, (contact) => {
                if (contact.receives_email === 'YES') {
                    emailList.push(contact.email);
                }
            });
            result = !emailList.length > 0;
        }
        return result;
    };

    renderActionButtons = () => {
        const {
            mustSelectAllFilters,
            selectedItems,
            filters,
            disableResetFilter,
            email,
            emailButtonText,
        } = this.props;
        const applyButtonText = this.props.applyButtonText || 'Apply Filters';
        const clearButtonText = this.props.clearButtonText || 'Reset Filters';
        const buttonText = emailButtonText
            ? `Email report to ${emailButtonText}`
            : 'Email report to Retailer';

        //determine if to disable the button
        const numFilters = filters.length;
        const numSelectedFilters = _.filter(
            _.keys(selectedItems),
            (i) => i !== 'applied'
        ).length;
        const selectedAllFilters = numFilters === numSelectedFilters;
        const disable = mustSelectAllFilters ? !selectedAllFilters : false;
        const reference =
            selectedItems && selectedItems.retailers
                ? selectedItems.retailers[0]
                : selectedItems && selectedItems.corporateAccount
                ? selectedItems.corporateAccount[0]
                : null;
        const emailCheckResult =
            disable === false && reference
                ? this.checkForEmailAccount(reference)
                : true;

        return (
            <div
                className="filter-section-buttons"
                data-test="filtersectionbuttons"
            >
                <div className="max-tablet">
                    <Button
                        fluid
                        primary
                        size="small"
                        onClick={() => this._onApplyFilters(false)}
                        disabled={disable}
                    >
                        {applyButtonText}
                    </Button>
                    {
                        <div>
                            <br />
                        </div>
                    }
                    {email && (
                        <Button
                            fluid
                            primary
                            size="small"
                            onClick={() =>
                                !emailCheckResult && this._onApplyFilters(true)
                            }
                            disabled={emailCheckResult}
                        >
                            {buttonText}
                        </Button>
                    )}
                    {!disableResetFilter && (
                        <Button
                            fluid
                            className="transparent-btn clear-all"
                            size="small"
                            onClick={() => this._onClearFilters()}
                        >
                            {clearButtonText}
                        </Button>
                    )}
                </div>
                <div className="min-desktop">
                    <Button
                        primary
                        size="medium"
                        onClick={() => this._onApplyFilters(false)}
                        disabled={disable}
                    >
                        {applyButtonText}
                    </Button>
                    {
                        <div>
                            <br />
                        </div>
                    }
                    {email && (
                        <Button
                            primary
                            size="medium"
                            onClick={() =>
                                !emailCheckResult && this._onApplyFilters(true)
                            }
                            disabled={emailCheckResult}
                        >
                            {buttonText}
                        </Button>
                    )}
                    {!disableResetFilter && (
                        <Button
                            className="transparent-btn clear-all"
                            size="small"
                            onClick={() => this._onClearFilters()}
                        >
                            {clearButtonText}
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    renderMobileFilters = (filtersToRender) => {
        const { showFilters } = this.state;

        const filtersSelected = _.reduce(
            _.filter(_.keys(this.props.selectedItems), (k) => k !== 'applied'),
            (result, k) => {
                const selectedItemKeyValue = this.props.selectedItems[k];
                if (
                    !selectedItemKeyValue ||
                    !selectedItemKeyValue.length ||
                    selectedItemKeyValue.length <= 0
                ) {
                    return result;
                }

                return result + selectedItemKeyValue.length;
            },
            0
        );

        if (!showFilters) {
            return (
                <Menu compact borderless secondary data-test="mobilefilters">
                    <Menu.Item
                        onClick={() =>
                            this.setState({
                                showFilters: !this.state.showFilters,
                            })
                        }
                    >
                        <Icon name="filter" size={'large'} />
                        {filtersSelected > 0 && (
                            <Badge
                                value={filtersSelected}
                                isSelected
                                className={'total-badge-count'}
                            />
                        )}
                    </Menu.Item>
                </Menu>
            );
        }

        return [
            <div
                className="mobile-filter-section-heading"
                key={1}
                data-test="mobilefilters"
            >
                <span className="mobile-filter-heading-text">Filters</span>
                <span
                    className="icon-Close mobile-filter-heading-icon"
                    onClick={() =>
                        this.setState({ showFilters: !this.state.showFilters })
                    }
                >
                    {' '}
                </span>
            </div>,
            <div className="mobile-filter-list" key={2}>
                <Menu vertical secondary compact fluid size="large">
                    {[...filtersToRender]}
                </Menu>
                {this.renderActionButtons()}
            </div>,
        ];
    };

    renderFilterSpread = (filters) => {
        const { itemsCount } = this.props;

        if (!itemsCount || itemsCount <= 0) {
            return (
                <Menu secondary fluid size="mini">
                    {filters}
                </Menu>
            );
        }

        const menus = [];
        let count = 0;
        while (filters.length > 0) {
            menus.push(
                <Menu secondary fluid size="mini" key={`menu_filter_${count}`}>
                    {filters.splice(0, itemsCount)}
                </Menu>
            );
            ++count;
        }

        return menus;
    };

    render() {
        const { filters } = this.props;
        const { showFilters } = this.state;
        const filtersToRender = [];

        filters.forEach((f, index) => {
            const {
                label,
                name,
                items,
                multiple,
                disabled,
                searchable,
                toggleAll,
                isDate,
                defaultSelected,
                client,
                minDate,
                maxDate,
                maxSelection,
                isDateRange,
                autoComplete,
                isTextSearch,
                clearable,
            } = f;

            if (
                (!items || items.length <= 0) &&
                !isDate &&
                !isDateRange &&
                !isTextSearch
            ) {
                return;
            }

            filtersToRender.push(
                <FilterItem
                    client={client}
                    toggleAll={toggleAll}
                    name={name}
                    multiple={multiple || false}
                    items={!isDate && !isDateRange ? items : []}
                    disabled={disabled || false}
                    searchable={searchable}
                    key={index}
                    label={label}
                    isDate={isDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    defaultSelected={defaultSelected}
                    maxSelection={maxSelection}
                    isDateRange={isDateRange}
                    autoComplete={autoComplete}
                    isTextSearch={isTextSearch}
                    clearable={clearable}
                />
            );
        });

        return (
            <div className="filter-page" data-test="filtersection">
                <div className="max-tablet">
                    <Segment
                        vertical
                        raised
                        className={classnames('filterSection', {
                            filterSectionActive: showFilters,
                        })}
                    >
                        {this.renderMobileFilters(filtersToRender)}
                    </Segment>
                </div>
                <div className="min-desktop">
                    <Segment vertical raised className="filterSection">
                        {this.renderFilterSpread(filtersToRender)}
                        {this.renderActionButtons()}
                    </Segment>
                </div>
            </div>
        );
    }
}

export default connect(
    ({ tableFilter, tableSearch, retailers }) => {
        const { name, toggle, selectedItems, applied } = tableFilter;
        const { searchValue } = tableSearch;
        return {
            retailers,
            filterName: name,
            toggle,
            selectedItems,
            applied,
            searchValue,
        };
    },
    (dispatch) => ({
        onFetchRetailers: (args) => dispatch(fetchRetailers(args)),
        toggleFilter: (name, toggle) => dispatch(filterToggle(name, toggle)),
        clearFilters: () => dispatch(clearFilters()),
        selectItem: (name, item) => dispatch(addSelectedItem(name, item)),
        deselectItem: (name, item) => dispatch(removeSelectedItem(name, item)),
        deselectAllItems: (name) => dispatch(removeAllSelectedItems(name)),
        shouldApplyFilters: () => dispatch(shouldApplyFilters()),
        initializeFilter: (args) => dispatch(initializeFilter(args)),
    })
)(FilterSection);
