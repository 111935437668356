import { MediaApi } from '../../../services/media-service';

export const RETAILER_ATTACHMENT_LIST = 'RETAILER_ATTACHMENT_LIST';
export const RETAILER_ATTACHMENT_LOADING = 'RETAILER_ATTACHMENT_LOADING';
export const RETAILER_ATTACHMENT_LOADING_DONE =
    'RETAILER_ATTACHMENT_LOADING_DONE';

const mediaApi = new MediaApi();

export const fetchListOfAttachmentsForRetailer = (args, retailerId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RETAILER_ATTACHMENT_LOADING });
            const list = await mediaApi.getListOfAttachmentsForRetailer(
                args,
                retailerId
            );
            dispatch({
                type: RETAILER_ATTACHMENT_LIST,
                list,
            });
        } catch (err) {
            console.error(err);
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_LOADING_DONE });
        }
    };
};

export const getAttachmentInfo = (attachmentId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RETAILER_ATTACHMENT_LOADING });
            const attachment = await mediaApi.getAttachment(attachmentId);
            return attachment;
        } catch (err) {
            console.error(err);
        } finally {
            dispatch({ type: RETAILER_ATTACHMENT_LOADING_DONE });
        }
    };
};

export const downloadAttachment = (urlPath) => {
    return async (dispatch) => {
        try {
            //do not have loading here since it make the table look like its loading
            await mediaApi.downloadAttachment(urlPath, urlPath); //use media key as the filename
        } catch (err) {
            console.error(err);
        }
    };
};
