import {
    TOUR_CONFIGURATION_LOADING,
    TOUR_CONFIGURATION_LOADING_DONE,
    TOUR_CONFIGURATION_GET,
    TOUR_CONFIGURATION_INIT,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    config: {},
};

const tourConfiguration = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case TOUR_CONFIGURATION_INIT:
            return { ...INITIAL_STATE };

        case TOUR_CONFIGURATION_GET:
            return {
                ...state,
                config: { ...payload },
            };
        case TOUR_CONFIGURATION_LOADING:
            return {
                ...state,
                loading: true,
            };

        case TOUR_CONFIGURATION_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default tourConfiguration;
