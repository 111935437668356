import { ReportsApi } from '../../../../../services/reports-service';
export const TOP_GAMES_INIT = 'TOP_GAMES_INIT';
export const TOP_GAMES_BY_PRICE_POINT = 'RETAILER_TOP_GAMES_BY_PRICE_POINT';
export const TOP_GAMES_LOADING = 'TOP_GAMES_LOADING';
export const TOP_GAMES_ERROR = 'TOP_GAMES_ERROR';

const reportsApi = new ReportsApi();

export const initTopGames = () => {
    return async (dispatch) => {
        dispatch({ type: TOP_GAMES_INIT });
    };
};

export const getTopGamesByPricePoint = (
    retailerReference,
    jurisdictionCode,
    fromDate,
    toDate
) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const isPostItem =
                state.frontendConfig.configs.find(
                    (co) => co.name === 'enable-pos-item'
                )?.value === 'true';
            let gameType = 'LT';
            if (isPostItem) gameType = 'LT,PS';
            dispatch({ type: TOP_GAMES_LOADING });
            const topGamesByPricePoint =
                await reportsApi.getTopGamesByPricePointForRetailer(
                    retailerReference,
                    jurisdictionCode,
                    fromDate,
                    toDate,
                    gameType
                );
            dispatch({
                type: TOP_GAMES_BY_PRICE_POINT,
                topGamesByPricePoint,
            });
        } catch (e) {
            dispatch({
                type: TOP_GAMES_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
