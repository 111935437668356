import {
    RETURN_REASON_LOADING,
    RETURN_REASON_LOADING_DONE,
    RETURN_REASON_GET,
    RETURN_REASON_INIT,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    content: '',
};

const returnReasonSelect = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RETURN_REASON_INIT:
            return { ...INITIAL_STATE };

        case RETURN_REASON_LOADING:
            return {
                ...state,
                loading: true,
            };

        case RETURN_REASON_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case RETURN_REASON_GET:
            return {
                ...state,
                content: payload,
            };

        default:
            return state;
    }
};
export default returnReasonSelect;
