import {
    GAME_ITEM_LIST,
    GAME_ITEM_LIST_LOADING,
    GAME_ITEM_LIST_LOADING_DONE,
    GAME_ITEM_LIST_SORTED,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    content: [],
    filteredGames: [],
};

const gameItems = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GAME_ITEM_LIST:
            return {
                ...state,
                content: [...action.list],
                filteredGames: [...action.list],
            };
        case GAME_ITEM_LIST_LOADING:
            return { ...state, loading: true };
        case GAME_ITEM_LIST_LOADING_DONE:
            return { ...state, loading: false };
        case GAME_ITEM_LIST_SORTED:
            return { ...state, filteredGames: action.payload };
        default:
            return state;
    }
};
export default gameItems;
