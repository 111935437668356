import { updateQuantity } from '../../../constants/utils';
import { error } from '../notifications';

export const ORDER_QUANTITY_LOADING = 'ORDER_QUANTITY_LOADING';
export const ORDER_QUANTITY_LOADING_DONE = 'ORDER_QUANTITY_LOADING_DONE';
export const ORDER_QUANTITY_INIT = 'ORDER_QUANTITY_INIT';
export const ORDER_QUANTITY_SAVE = 'ORDER_QUANTITY_SAVE';

export const incrementQuantity = (game) => {
    return (dispatch, getState) => {
        dispatch({ type: ORDER_QUANTITY_LOADING });
        const {
            orderQuantity: { qty },
        } = getState();
        const newQuantityAmount = updateQuantity(
            qty,
            game?.maximumOrderQuantity
        );

        if (newQuantityAmount < game?.minimumOrderQuantity) {
            dispatch(
                error({
                    msg: `Min order limit for Game: ${game.gameRefAndName} is ${game.minimumOrderQuantity}`,
                })
            );
        }

        if (qty > 1 && qty === newQuantityAmount) {
            dispatch(
                error({
                    msg: `Maximum order limit reached for the Game ${game.gameRefAndName}.`,
                })
            );
        }

        dispatch({
            type: ORDER_QUANTITY_SAVE,
            payload: newQuantityAmount,
        });

        dispatch({ type: ORDER_QUANTITY_LOADING_DONE });
    };
};

export const decrementQuantity = (game) => {
    return (dispatch, getState) => {
        dispatch({ type: ORDER_QUANTITY_LOADING });
        const {
            orderQuantity: { qty },
        } = getState();

        const newQuantityAmount = updateQuantity(
            qty,
            game?.maximumOrderQuantity,
            -1
        );
        if (
            newQuantityAmount !== 0 &&
            newQuantityAmount < game?.minimumOrderQuantity
        ) {
            dispatch(
                error({
                    msg: `Min order limit for Game: ${game.gameRefAndName} is ${game.minimumOrderQuantity}`,
                })
            );
        }
        dispatch({
            type: ORDER_QUANTITY_SAVE,
            payload: newQuantityAmount,
        });

        dispatch({ type: ORDER_QUANTITY_LOADING_DONE });
    };
};

export const initOrderQuantity = () => ({
    type: ORDER_QUANTITY_INIT,
});

export const saveOrderQuantity = (qty) => ({
    type: ORDER_QUANTITY_SAVE,
    payload: qty,
});
