import OrderApi from '../../../services/order-service';
import { findTotalCostForEachOrder } from './orderDetail';

export const IN_PROGRESS_ORDERS_LOADING = 'IN_PROGRESS_ORDERS_LOADING';
export const IN_PROGRESS_ORDERS_LOADING_DONE =
    'IN_PROGRESS_ORDERS_LOADING_DONE';
export const IN_PROGRESS_ORDERS_INIT = 'IN_PROGRESS_ORDERS_INIT';
export const IN_PROGRESS_ORDERS_SEARCH = 'IN_PROGRESS_ORDERS_SEARCH';

const orderApi = new OrderApi();
export const searchInProgressOrders = (args) => {
    return async (dispatch) => {
        dispatch({ type: IN_PROGRESS_ORDERS_LOADING });

        try {
            const payload = await orderApi.search(args);

            await findTotalCostForEachOrder(payload);

            dispatch({ type: IN_PROGRESS_ORDERS_SEARCH, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: IN_PROGRESS_ORDERS_LOADING_DONE });
    };
};

export const initInProgressOrders = () => ({
    type: IN_PROGRESS_ORDERS_INIT,
});
