import { GamesApi } from '../../../services/games-service';
import OrderApi from '../../../services/order-service';

import { forEach, map, find, uniq, flatten, reduce } from 'lodash';
import { error } from '../notifications';
import {
    EDIT_ORDER_CHANGES_DONE,
    EDIT_ORDER_CHANGES_LOAD,
    EDIT_ORDER_CHANGES_SAVED,
    EDIT_ORDER_CHANGES_START,
    EDIT_ORDER_CLONE_CURRENT_ITEMS,
} from '../cart/cart';
import _ from 'lodash';

export const ORDER_DETAIL_LOADING = 'ORDER_DETAIL_LOADING';
export const ORDER_DETAIL_LOADING_DONE = 'ORDER_DETAIL_LOADING_DONE';
export const ORDER_DETAIL_INIT = 'ORDER_DETAIL_INIT';
export const ORDER_DETAIL_GET = 'ORDER_DETAIL_GET';
export const TOGGLE_EDIT_DETAIL_PAGE = 'TOGGLE_EDIT_DETAIL_PAGE';
const orderApi = new OrderApi();
const gameApi = new GamesApi();

export const openEditView = (isEdit) => {
    return async (dispatch) => {
        dispatch({
            type: TOGGLE_EDIT_DETAIL_PAGE,
            payload: isEdit,
        });
    };
};

export const resetEditOrderDetail = () => {
    return async (dispatch, getState) => {
        const { originalOrderItems } = getState().orderDetail;
        dispatch({ type: EDIT_ORDER_CHANGES_START });
        dispatch({
            type: EDIT_ORDER_CHANGES_SAVED,
            payload: JSON.parse(originalOrderItems),
        });
        dispatch({ type: EDIT_ORDER_CHANGES_DONE });
    };
};

export const cloneCurrentItems = () => {
    return async (dispatch, getState) => {
        const { orderedItems } = getState().orderDetail;
        orderedItems.forEach((orderedItem) => {
            orderedItem.game.image = [];
        });
        dispatch({
            type: EDIT_ORDER_CLONE_CURRENT_ITEMS,
            payload: orderedItems,
        });
    };
};

export const readCloneState = () => {
    return async (dispatch, getState) => {
        const { pendingOrderItems } = getState().orderDetail;
        dispatch({
            type: EDIT_ORDER_CHANGES_SAVED,
            payload: JSON.parse(pendingOrderItems),
        });
    };
};
export const getOrderById = (id) => {
    return async (dispatch) => {
        dispatch({ type: ORDER_DETAIL_LOADING });

        try {
            const payload = await orderApi.getOrder(id);
            const itemCodes = map(payload.orderDetails, 'itemNumber');
            const items = await gameApi.getGameDetailsByGameNumber(itemCodes);

            forEach(payload.orderDetails, (detail) => {
                const item = find(items, ['reference', detail.itemNumber]);
                detail.cost =
                    (item?.retailPrice ? item.retailPrice : 0) *
                    detail.quantity;
                detail.game = item;
                detail.game.gameId = item.id;
                detail.qty = detail.quantity;
                detail.game.gameRefAndName = `#${item.reference} - ${item.name}`;
            });
            dispatch({ type: EDIT_ORDER_CHANGES_START });
            dispatch({
                type: EDIT_ORDER_CHANGES_LOAD,
                payload: payload.orderDetails,
            });
            dispatch({ type: EDIT_ORDER_CHANGES_DONE });

            if (payload.shipments) {
                forEach(payload.shipments, (shipment) =>
                    forEach(shipment.details, (detail) => {
                        const item = find(items, [
                            'reference',
                            detail.itemNumber,
                        ]);
                        const retailPrice =
                            !!item && item?.retailPrice ? item.retailPrice : 0;
                        if (detail.quantity < 1) {
                            const oDetail = _.find(
                                payload.orderDetails,
                                (od) => od.itemNumber === item?.reference
                            );
                            // sample games won't be present in the order details
                            // and min shipped qty should be 1
                            detail.quantity = oDetail ? oDetail.quantity : 1;
                        }
                        detail.cost = retailPrice * detail.quantity;
                    })
                );
            }

            dispatch({ type: ORDER_DETAIL_GET, payload });
        } catch (err) {
            console.error(err);
            dispatch(updateError(err));
        }

        dispatch({ type: ORDER_DETAIL_LOADING_DONE });
    };
};

const updateError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: 'Trouble Fetching Order Detail, Please Contact Administrator',
                target: 'Order Detail',
            })
        );
    };
};
export const initOrderDetail = () => ({
    type: ORDER_DETAIL_INIT,
});

export const findTotalCostForEachOrder = async (payload) => {
    const itemCodes = uniq(
        flatten(
            map(payload.content, (order) =>
                map(order.orderDetails, 'itemNumber')
            )
        )
    );
    const items = await gameApi.getGameDetailsByGameNumber(itemCodes);

    forEach(payload.content, (order) => {
        order.totalCost = reduce(
            order.orderDetails,
            (total, detail) => {
                const item = find(items, ['reference', detail.itemNumber]);
                total += !!item
                    ? detail.quantity *
                      (item?.retailPrice ? item.retailPrice : 0)
                    : 0;

                return total;
            },
            0
        );
    });

    return payload;
};

export const editOrderedItems = (orderedItems, game) => {
    return async (dispatch) => {
        let item = find(orderedItems, ['game.gameId', game.gameId]);
        if (item !== null) {
            item.orderedSample = !item.orderedSample;
            dispatch({
                type: EDIT_ORDER_CHANGES_SAVED,
                payload: orderedItems,
            });
        }
    };
};
