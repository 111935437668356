import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Button, Icon, Input, List, Loader } from 'semantic-ui-react';
import _ from 'lodash';

import Page from '../../layout/Page';
import NoResult from '../../components/datatable/NoResult';
import NoItems from '../../components/NoItems';
import Localize from '../../constants/i18n-utils';
import {
    fetchReportList,
    searchReportList,
    clearNotification,
    pushReferrer,
} from '../../redux/actions';
import { resetFilters } from '../../components/filter/actions';
import {
    clearSearch,
    clearAllTableSelection,
} from '../../components/datatable/actions';
import { hasRole } from '../../constants/utils';
import config from '../../constants/config';

import './styles.css';

const { REPORTS } = config.ROUTE_URLS;

const PAGE_NAME = 'ReportsPage';

export class ReportsPage extends Component {
    state = {
        searchValue: '',
        searchButtonToggle: false,
        previousSearchValue: '',
    };

    componentWillMount() {
        this.props.fetchReportList();
        this.props.setReferrer({ page: 'Reports', path: REPORTS });
    }

    onSearchFieldChange = (e) => {
        const val = e.target.value;

        if (!val || val.trim().length === 0) {
            this._onSearchClear();
            return;
        }

        this.setState({
            searchValue: e.target.value,
            searchButtonToggle: val && val.trim() !== '',
        });
    };

    onSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._onSearch();
        }
    };

    _onSearch = () => {
        const { searchValue } = this.state;

        if (!searchValue || searchValue.trim() === '') {
            return;
        }

        this.props.searchReportList({
            query: searchValue,
            page: 0,
            size: 100,
        });

        this.setState({ previousSearchValue: searchValue });
    };

    _onSearchClear = () => {
        this.setState({ searchValue: '', searchButtonToggle: false });
        this.props.fetchReportList();
    };

    render() {
        const { content, loading, userInfo } = this.props;
        let noResult = false;
        let totalListItems = 0;
        if (!loading && (!content || content.length <= 0)) {
            noResult = true;
        }

        const { searchValue, searchButtonToggle, previousSearchValue } =
            this.state;
        const searchIcon = searchButtonToggle ? (
            <Icon name="close" link onClick={this._onSearchClear} />
        ) : null;

        return (
            <Page name={PAGE_NAME} title="All Reports">
                <Dimmer active={loading} page inverted>
                    <Loader />
                </Dimmer>
                <div className="search-section search-width">
                    <Input
                        placeholder="Search..."
                        value={searchValue}
                        onChange={this.onSearchFieldChange}
                        onKeyPress={this.onSearchKeyPress}
                        icon={searchIcon}
                        disabled={noResult}
                    />
                    <Button
                        className="search-btn"
                        disabled={
                            !searchValue || previousSearchValue === searchValue
                        }
                        primary={
                            searchValue && previousSearchValue !== searchValue
                        }
                        onClick={this._onSearch}
                        data-test="serachButton"
                    >
                        <span className="icon-Search"> </span>
                    </Button>
                </div>
                {content && content.length > 0 ? (
                    <div
                        className="reports-page"
                        data-test="reportsPageWrapper"
                    >
                        {loading && (
                            <Loader active={loading} inline="centered" />
                        )}
                        {noResult && !searchValue && (
                            <NoItems message="There are no reports" />
                        )}
                        {noResult && searchValue && <NoResult />}

                        {!noResult && !loading && (
                            <List selection verticalAlign="middle" size="large">
                                {_.map(content, (group) => {
                                    const groupName = _.keys(
                                        group,
                                        (k) => k
                                    )[0];
                                    const accessibleItems = _.filter(
                                        group[groupName],
                                        ({ role }) =>
                                            !role ||
                                            (_.startsWith(role, 'ROLE_RE_') &&
                                                hasRole(
                                                    userInfo.authorities,
                                                    role
                                                ))
                                    );

                                    if (accessibleItems.length > 0) {
                                        totalListItems +=
                                            accessibleItems.length;
                                        const items = _.map(
                                            accessibleItems,
                                            ({ id, name, displayName }) => {
                                                return (
                                                    <List.Item
                                                        key={`${groupName}-${id}`}
                                                        className="report-item action-menu-item"
                                                        onClick={() => {
                                                            this.props.resetFilters();
                                                            this.props.clearSearch();
                                                            this.props.clearAllTableSelection();
                                                            this.props.clearNotification();
                                                            this.props.history.push(
                                                                `${REPORTS}/${name}/${id}`
                                                            );
                                                        }}
                                                    >
                                                        {displayName || name}
                                                    </List.Item>
                                                );
                                            }
                                        );
                                        return [
                                            <List.Item
                                                key={groupName}
                                                className="group-name"
                                                disabled
                                            >
                                                <List.Header>
                                                    {groupName}
                                                </List.Header>
                                            </List.Item>,
                                            items,
                                        ];
                                    }
                                })}
                            </List>
                        )}
                        {totalListItems === 0 && (
                            <NoItems
                                style={{ marginTop: '150px' }}
                                message={Localize.text(
                                    'reportsPage.noReportsToDisplay',
                                    'There are no Reports'
                                )}
                            />
                        )}
                    </div>
                ) : (
                    <div className="reports-page">
                        <NoItems
                            style={{ marginTop: '150px' }}
                            message={Localize.text(
                                'reportsPage.noReportsToDisplay',
                                'There are no Reports'
                            )}
                        />
                    </div>
                )}
            </Page>
        );
    }
}

const mapStateToProps = ({ listReports, auth }) => {
    return { ...listReports, userInfo: auth.info };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchReportList: () => dispatch(fetchReportList()),
        searchReportList: (args) => dispatch(searchReportList(args)),
        clearSearch: () => dispatch(clearSearch()),
        clearAllTableSelection: () => dispatch(clearAllTableSelection()),
        resetFilters: () => dispatch(resetFilters()),
        clearNotification: () => dispatch(clearNotification()),
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportsPage)
);
