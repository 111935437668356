import { RetailersApi } from '../../../services/retailers-service';
import { PlanogramApi } from '../../../services/planogram-group-service';
import _ from 'lodash';

import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

export const RETAILER_TASK = 'RETAILER_TASK';
export const RETAILER_TASK_CLEAR = 'RETAILER_TASK_CLEAR';

const retailersApi = new RetailersApi();
const planogramApi = new PlanogramApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const findUTR = (dispatch, tasks, utrID, readOnly = false) => {
    let utr = _.find(tasks, (t) => t.id === utrID);
    if (utr) {
        if (utr.category === 'PLANOGRAM' && !utr.planogramGroupName) {
            getPlanogramGroupName(utr);
        }

        dispatch({
            type: RETAILER_TASK,
            content: utr,
            readOnly,
        });
        return true;
    }
    return false;
};

const getPlanogramGroupName = async (utr) => {
    if (utr.retailerId) {
        let retailer;
        try {
            retailer = await retailersApi.getRetailerById(utr.retailerId);
        } catch (e) {
            console.error(e);
        }

        if (retailer) {
            let planogramGroup;
            const planogramGroupId = retailer.planogramGroupId;
            if (planogramGroupId) {
                try {
                    planogramGroup = await planogramApi.getPlanogramGroupById(
                        planogramGroupId
                    );
                } catch (e) {
                    console.error(e);
                }
                utr.planogramGroupName = planogramGroup.name;
                utr.planogramGroupId = planogramGroupId;
            } else {
                utr.planogramGroupName = 'Not Assigned';
            }
        }
    }
};

export const fetchPastOrFutureUTR = (utrID, readOnly = false) => {
    return (dispatch, getState) => {
        let { dailyTasks, pastDueTasks, retailerPastVisitTasks } = getState();
        let tasks = dailyTasks.content
            ? _.flatten(dailyTasks.content.map((retailer) => retailer.tasks))
            : [];
        tasks =
            pastDueTasks.content && pastDueTasks.content.retailerTasks
                ? tasks.concat(
                      pastDueTasks.content.otherTasks,
                      _.flatten(
                          pastDueTasks.content.retailerTasks.map(
                              (retailer) => retailer.tasks
                          )
                      )
                  )
                : tasks;
        const pastVisitTasks = retailerPastVisitTasks.content
            ? retailerPastVisitTasks.content
            : [];
        return findUTR(
            dispatch,
            [...tasks, ...dailyTasks.otherContent, ...pastVisitTasks],
            utrID,
            readOnly
        );
    };
};

export const fetchUTR = (utrID) => {
    return (dispatch, getState) => {
        const {
            dailyTasks: { content, otherContent },
        } = getState();
        const tasks = _.flatten(content.map((retailer) => retailer.tasks));
        return findUTR(dispatch, [...tasks, ...otherContent], utrID);
    };
};

export const fetchPastVisitUTR = (utrID, readOnly = false) => {
    return (dispatch, getState) => {
        const {
            retailerPastVisitTasks: { content },
        } = getState();
        return findUTR(dispatch, content, utrID, readOnly);
    };
};

export const fetchPastDueUTR = (utrID) => {
    return (dispatch, getState) => {
        const {
            pastDueTasks: { content },
        } = getState();
        let tasks = _.flatten(
            content.retailerTasks.map((retailer) => retailer.tasks)
        );
        tasks = tasks.concat(content.otherTasks);
        try {
            return findUTR(dispatch, tasks, utrID);
        } catch (error) {
            dispatch(createErrorHandlerError(error));
        }
    };
};

export const updateRetailerUTR = (utr) => {
    return async (dispatch) => {
        if (utr) {
            if (utr.category === 'PLANOGRAM' && !utr.planogramGroupName) {
                await getPlanogramGroupName(utr);
            }
            dispatch({
                type: RETAILER_TASK,
                content: utr,
            });
            return true;
        }
        return false;
    };
};

export const clearRetailerTask = () => {
    return { type: RETAILER_TASK_CLEAR };
};
