import { SurveyApi } from '../../../services/survey-service';
import _ from 'lodash';

export const SURVEY_INIT = 'SURVEY_INIT';
export const SURVEY_GET_TYPES = 'SURVEY_GET_TYPES';
export const SURVEY_LOADING = 'SURVEY_LOADING';
export const SURVEY_LOADING_DONE = 'SURVEY_LOADING_DONE';

const surveyApi = new SurveyApi();

export const getSurveyTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: SURVEY_LOADING });

            const surveyTypes = await surveyApi.getSurveyTypes();

            dispatch({
                type: SURVEY_GET_TYPES,
                surveyTypes,
            });
        } catch (e) {
            console.error(e);
            throw e; //throw the error and let the caller handle the error
        } finally {
            dispatch({ type: SURVEY_LOADING_DONE });
        }
    };
};

export const fetchSurveysAsync = (args) => {
    return async (dispatch) => {
        return await surveyApi.searchSurveys(args);
    };
};

export const getComplianceSurveyType = () => {
    return async (dispatch, getState) => {
        await dispatch(getSurveyTypes());
        const { survey } = getState();
        return _.find(
            survey.types,
            (t) =>
                (t.name && t.name.toLowerCase() === 'compliance') ||
                (t.name && t.name.toLowerCase() === 'marketing')
        );
    };
};

export const getMarketingSurveyTypeFromRedux = (survey) => {
    const marketingSurveyType = _.find(
        survey.types,
        (t) => t.name && t.name.toLowerCase() === 'marketing'
    );

    if (!marketingSurveyType) {
        console.warn(
            'did not find a survey type for marketing. call getSurveyTypes first or use getMarketingSurveyType'
        );
    }

    return marketingSurveyType;
};
