import {
    RETAILER_INVENTORY_LIST,
    RETAILER_INVENTORY_LIST_LOADING,
    RETAILER_INVENTORY_LIST_LOADING_DONE,
    RETAILER_INVENTORY_INIT_BOOKS_SUCCESS,
    RETAILER_INVENTORY_UNRETURNED_BOOKS,
    RETAILER_ORDER_DETAILS_BY_REFERENCE,
    RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING,
    RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    loading: false,
    inactivatedGameBooks: [],
    unReturnedGameBooks: [],
    inTransitOrPending: [],
};

const retailerInventory = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_INVENTORY_LIST_LOADING:
            return { ...state, loading: true };
        case RETAILER_INVENTORY_LIST:
            return {
                ...state,
                content: [...action.list],
                totalElements: action.list.length,
            };
        case RETAILER_INVENTORY_LIST_LOADING_DONE:
            return { ...state, loading: false };
        case RETAILER_INVENTORY_INIT_BOOKS_SUCCESS:
            return {
                ...state,
                inactivatedGameBooks: action.inactivatedGameBooks,
            };
        case RETAILER_INVENTORY_UNRETURNED_BOOKS:
            return {
                ...state,
                unReturnedGameBooks: action.unReturnedGameBooks,
            };
        case RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING:
            return { ...state, loading: true };
        case RETAILER_ORDER_DETAILS_BY_REFERENCE:
            return {
                ...state,
                inTransitOrPending: [...action.list],
            };
        case RETAILER_ORDER_DETAILS_BY_REFERENCE_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default retailerInventory;
