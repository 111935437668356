import { error, warning, clearNotification } from '../notifications';
import { getDownloadError } from '../../../constants/utils';
import { ReportsApi } from '../../../services/reports-service';

export const REPORT_GENERATE_LOADING = 'REPORT_GENERATE_LOADING';
export const REPORT_GET_LOADING = 'REPORT_GET_LOADING';
export const REPORT_GET_SUCCESS = 'REPORT_GET_SUCCESS';
export const REPORT_INIT = 'REPORT_INIT';

const reportsApi = new ReportsApi();

export const initReportDetails = () => ({
    type: REPORT_INIT,
});

const reportError = (errors, target) => {
    return async (dispatch) => {
        let msg = await getDownloadError(errors);
        if (msg && msg.toLowerCase() === 'no data found') {
            msg = 'We were unable to find any data matching those criteria';
            dispatch(showWarning(msg, target));
        } else {
            dispatch(showError(msg, target));
        }
    };
};

const showWarning = (msg, target) => warning({ msg });
const showError = (msg, target) => error({ msg });

export const generateNoParameterReport = (args) => {
    return async (dispatch) => {
        const { id, userFullName, userName } = args;
        dispatch(clearNotification());
        dispatch({ type: REPORT_GENERATE_LOADING, generating: true });
        try {
            await reportsApi.generateReport({
                id,
                parameters: {
                    userFullName,
                    userName,
                },
            });
            dispatch({ type: REPORT_GENERATE_LOADING, generating: false });
        } catch (e) {
            dispatch({ type: REPORT_GENERATE_LOADING, generating: false });
            dispatch(reportError(e, 'ReportPage'));
        }
    };
};

export const generateReport = (args) => {
    return async (dispatch) => {
        const { id } = args;
        dispatch(clearNotification());
        dispatch({ type: REPORT_GENERATE_LOADING, generating: true });
        try {
            await reportsApi.generateReport({ id, parameters: { ...args } });
            dispatch({ type: REPORT_GENERATE_LOADING, generating: false });
        } catch (e) {
            dispatch({ type: REPORT_GENERATE_LOADING, generating: false });
            dispatch(reportError(e, 'ReportPage'));
        }
    };
};

export const getReport = (id) => {
    return async (dispatch) => {
        dispatch(clearNotification());
        dispatch({ type: REPORT_GET_LOADING, loading: true });
        try {
            const report = await reportsApi.getReport({ id });
            dispatch({ type: REPORT_GET_SUCCESS, report: report });
            dispatch({ type: REPORT_GET_LOADING, loading: false });
        } catch (e) {
            dispatch({ type: REPORT_GET_LOADING, loading: false });
            dispatch(reportError(e));
        }
    };
};
