import React from 'react';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import { getAttribute } from '../../../constants/utils';
import NoItems from '../../NoItems';

export const ResponsiveCard = (props) => {
    const columns = _.sortBy(
        _.filter(props.columns, (data) => data.mobileViewOrder),
        (data) => data.mobileViewOrder
    );
    const headerRow = columns.length === 1 ? columns : columns.slice(0, 2);
    const footerRow =
        columns.length > 2 ? columns.slice(2, columns.length) : undefined;

    const getData = (data, item) => {
        const val = getAttribute(item, data.name);
        if (data.mobileFormatter) {
            return data.mobileFormatter(val, item);
        } else if (data.formatter) {
            return data.formatter(val, item);
        }
        return val;
    };
    const getMenuItem = (actionsMenu, item) => {
        if (actionsMenu && actionsMenu.length > 0) {
            const filterMenuItem = _.filter(
                actionsMenu,
                (menuItem) => !menuItem.canShow || menuItem.canShow(item)
            );
            if (filterMenuItem && filterMenuItem.length > 0) {
                return (
                    <Dropdown
                        item
                        icon="ellipsis vertical"
                        floating
                        direction={'left'}
                    >
                        <Dropdown.Menu>
                            {filterMenuItem.map((menuItem, index) => (
                                <Dropdown.Item>
                                    <div
                                        onClick={() => {
                                            menuItem.handleClick(
                                                getAttribute(
                                                    item,
                                                    menuItem.handleClickParams
                                                )
                                            );
                                        }}
                                    >
                                        <Button
                                            active
                                            fluid
                                            className={'border-bottom'}
                                        >
                                            {menuItem?.text}
                                        </Button>
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            }
        }
        return <div />;
    };
    const getCardView = (item) => {
        return (
            <Grid className={'card-item pr-2 pl-2'}>
                <Grid.Row
                    className={'pt-2 pb-0 '}
                    columns={headerRow.length === 1 ? 1 : 2}
                >
                    {headerRow.map((data, index) => (
                        <Grid.Column className={'text-align-s pr-0 pl-0'}>
                            {data.title}:{' '}
                            <b
                                className={
                                    'text-align-s pr-2 white-space-nowrap'
                                }
                            >
                                {getData(data, item)}
                            </b>
                        </Grid.Column>
                    ))}
                    {getMenuItem(props.actionsMenu, item)}
                </Grid.Row>
                {footerRow && footerRow.length > 0 && (
                    <>
                        {' '}
                        <Grid.Row
                            className={'pt-2 pb-0 '}
                            columns={footerRow.length}
                        >
                            {footerRow.map((data, index) => (
                                <Grid.Column
                                    className={'text-align-s pr-0 pl-0'}
                                >
                                    {data.title}
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                        <Grid.Row
                            className={'pt-0 pb-2 '}
                            columns={footerRow.length}
                        >
                            {footerRow.map((data, index) => (
                                <Grid.Column
                                    className={
                                        'text-align-s pr-0 pl-0 bold overflow-hidden text-overflow-ellipsis word-break-break-all'
                                    }
                                >
                                    <b>{getData(data, item)}</b>
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    </>
                )}
            </Grid>
        );
    };

    return (
        <div className={'return-mobile-view pt-4'}>
            {props.content && props.content.length > 0 ? (
                props.content.map((item, index) => getCardView(item))
            ) : (
                <NoItems message={props.noDataMessage} />
            )}
        </div>
    );
};
