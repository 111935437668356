import React from 'react';
import Select from 'react-select';
import { Form, Transition } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import config from '../../constants/config';
import './rfReactSelect.css';

const animationLen = config.ANIMATION_LENGTH;

RFReactSelect.defaultProps = {
    multi: false,
    className: '',
};

RFReactSelect.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
            .isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool,
    className: PropTypes.string,
    meta: PropTypes.object,
};

export default function RFReactSelect({
    input,
    options,
    multi,
    className,
    meta: { touched, error, warning },
    as: As = Select,
    ...otherProps
}) {
    const { name, value, onBlur, onChange, onFocus } = input;
    const transformedValue = transformValue(value, options, multi); //value must be a string!

    const classes = `${className} ${touched && error ? 'invalid' : ''}`;
    return (
        <Form.Field>
            <As
                valueKey="value"
                name={name}
                value={transformedValue}
                multi={multi}
                options={options}
                onChange={
                    multi
                        ? multiChangeHandler(onChange)
                        : singleChangeHandler(onChange)
                }
                onBlur={() => onBlur(value)}
                onFocus={onFocus}
                className={classes}
                {...otherProps}
            />
            <Transition
                visible={touched && error}
                animation="fade"
                duration={animationLen}
            >
                <span className="error-text">
                    <i>{error}</i>
                </span>
            </Transition>
            <Transition
                visible={touched && warning}
                animation="fade"
                duration={animationLen}
            >
                <span className="warning-text">
                    <i>{warning}</i>
                </span>
            </Transition>
        </Form.Field>
    );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
    return function handleSingleChange(value) {
        func(value ? value.value : '');
    };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
        func(values.map((value) => value.value));
    };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
    if (multi && typeof value === 'string') return [];

    const filteredOptions = options.filter((option) => {
        return multi
            ? option.value.id > -1 //if there is a value with an id use the id
                ? _.findIndex(value, (val) => val.id === option.value.id) !== -1
                : value.indexOf(option.value) !== -1
            : typeof option.value === 'string'
            ? option.value.toLowerCase() === value.toLowerCase()
            : option.value === value;
    });

    return multi ? filteredOptions : filteredOptions[0];
}
