import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../Widget';
import { getClosingGamesForRetailerReference } from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import NoItems from '../../../../components/NoItems';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import numeral from 'numeral';
import './styles.css';
import config from '../../../../constants/config';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';
import { formatToDisplayDate } from '../../../../constants/utils';

export class ClosingGamesWidget extends Component {
    async componentDidMount() {
        await this.init();
    }

    init = async () => {
        const { getClosingGamesForRetailerReference } = this.props;
        await getClosingGamesForRetailerReference(
            this.props.retailerInfo?.type?.reference,
            this.props.retailerInfo?.jurisdiction?.code
        );
    };

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    goToGamesPage = () => {
        this.props.history.push(config.ROUTE_URLS.GAMES);
    };

    render() {
        const { loading, closingGamesForRetailer, errorResponse } = this.props;
        const footer = <MoreDetail onClick={this.goToGamesPage} />;

        return (
            <Widget loading={loading} footer={!errorResponse ? footer : null}>
                <div className="bold widget-heading">
                    Closing Games
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>
                {!closingGamesForRetailer && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : closingGamesForRetailer &&
                  closingGamesForRetailer.length > 0 ? (
                    <div
                        headClassName="progress-to-target-header summary-widget"
                        data-test="withData"
                    >
                        <Table
                            headClassName="progress-to-target-header summary-widget"
                            contentKey="item"
                            content={closingGamesForRetailer}
                            totalElements={closingGamesForRetailer.length}
                            supressSearch={true}
                            columns={[
                                {
                                    name: 'closingDate',
                                    title: 'Closing Date',
                                    formatter: (val) =>
                                        val ? formatToDisplayDate(val) : '-',
                                    columnClassName: () =>
                                        'closingGames-dir-col',
                                },
                                {
                                    name: 'pricePoint',
                                    title: 'Price Point',
                                    formatter: (val) =>
                                        val
                                            ? numeral(val).format('$0.00')
                                            : '$0',
                                    columnClassName: () =>
                                        'closingGames-dir-col',
                                },
                                {
                                    name: 'gameNumber',
                                    title: 'Game No.',
                                    columnClassName: () =>
                                        'closingGames-dir-col',
                                },
                                {
                                    name: 'gameName',
                                    title: 'Game Name',
                                    columnClassName: () =>
                                        'closingGames-right-col',
                                },
                            ]}
                        />
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no closing games!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    closingGamesWidget: { closingGamesForRetailer, loading, errorResponse },
}) => {
    return { loading, closingGamesForRetailer, retailerInfo, errorResponse };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClosingGamesForRetailerReference: (retailerType, jurisdictionCode) =>
            dispatch(
                getClosingGamesForRetailerReference(
                    retailerType,
                    jurisdictionCode
                )
            ),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ClosingGamesWidget)
);
