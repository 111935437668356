const permissions = {
    LIST_GAMES: 'ROLE_RE_GAMES_LIST',
    LIST_USERS: 'ROLE_RE_USERS_LIST',
    CREATE_USERS: 'ROLE_RE_USERS_CREATE',
    VIEW_USERS: 'ROLE_RE_USERS_GET',
    UPDATE_SYS_CONFIGS: 'ROLE_RE_SYSTEM_CONFIG_UPDATE',
    LOGO_CONFIG_UPLOAD: 'ROLE_RE_LOGO_UPLOAD',
    VIEW_SYS_CONFIGS: 'ROLE_RE_SYSTEM_CONFIG_VIEW',
    LIST_ORDER_HISTORY: 'ROLE_RE_ORDER_LIST',
    VIEW_ORDER: 'ROLE_RE_ORDER_VIEW',
    LIST_INVOICE: 'ROLE_RE_INVOICE_LIST',
    VIEW_INVOICE: 'ROLE_RE_INVOICE_VIEW',
    LIST_REPORT: 'ROLE_RE_REPORT_LIST',
    GENERATE_REPORT: 'ROLE_RE_REPORT_GENERATE',
    RETAILER_INVOICE: 'ROLE_RE_REPORT_SINGLE_INVOICE',
    CREATE_FAQ: 'ROLE_RE_CREATE_FAQ',
    VIEW_FAQ: 'ROLE_RE_VIEW_FAQ',
    VIEW_PERMISSIONS: 'ROLE_RE_PRIVILEGES_VIEW',
    UPDATE_CONTACT_US: 'ROLE_RE_CONTACT_US_UPDATE',
    VIEW_PRIVACY_POLICY: 'ROLE_RE_VIEW_PRIVACY_POLICY',
    CREATE_PRIVACY_POLICY: 'ROLE_RE_CREATE_PRIVACY_POLICY',
    CREATE_NEW_RETAILER_POLICY: 'ROLE_RE_CREATE_NEW_RETAILER_POLICY',
    CONTACT_US_VIEW: 'ROLE_RE_CONTACT_US_VIEW',
    MANAGE_UPLOAD: 'ROLE_RE_FAQ_FILE_UPLOAD',
    DOCUMENT_VIEW: 'ROLE_RE_DOCUMENT_VIEW',
    DOCUMENT_CREATE: 'ROLE_RE_DOCUMENT_CREATE',
    DOCUMENT_UPDATE: 'ROLE_RE_DOCUMENT_UPDATE',
    DOCUMENT_DELETE: 'ROLE_RE_DOCUMENT_DELETE',
    DOCUMENT_LIST: 'ROLE_RE_DOCUMENT_LIST',
    CREATE_ROLES: 'ROLE_RE_PRIVILEGES_CREATE',
    SALES_REPORT_COMMISSION: 'ROLE_RE_REPORT_COMMISSIONS',
    INVOICE_REPORT: 'ROLE_RE_INVOICE_REPORT',
    COMMISSION_WIDGET: 'ROLE_RE_DASHBOARD_RETAILER_WIDGET_COMMISSIONS',
    VIRTUAL_TOUR: 'ROLE_RE_DASHBOARD_WIDGET_VIRTUAL_TOUR',
    TAKE_A_TOUR: 'ROLE_RE_TAKE_A_TOUR',
    TOP_SELLING_GAME_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_TOP_GAMES_PP',
    LAST_ORDER_SUMMARY_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_LAST_ORDER_SUMMARY',
    TOTAL_PURCHASE_YTD_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_TOTAL_PURCHASE_YTD',
    TOTAL_PURCHASE_YTD_PP_WIDGET:
        'ROLE_RE_DASHBOARD_WIDGET_TOTAL_PURCHASE_YTD_PP',
    TOTAL_PURCHASE_YTD_NON_PRODUCTS_WIDGET:
        'ROLE_RE_DASHBOARD_WIDGET_TOTAL_PURCHASE_YTD_NON_PRODUCTS',
    SYSTEM_TIME_WIDGET: 'ROLE_RE_WIDGET_SYSTEM_TIME',
    GAME_PAGE: 'ROLE_RE_GAMES_LIST',
    DASHBOARD_PAGE: 'ROLE_RE_DASHBOARD_PAGE',
    IN_PROGRESS_ORDERS: 'ROLE_RE_IN_PROGRESS_ORDERS',
    PAST_ORDERS: 'ROLE_RE_PAST_ORDERS',
    ORDER_DETAILS: 'ROLE_RE_ORDER_DETAILS',
    DOWNLOAD_ORDERS: 'ROLE_RE_DOWNLOAD_ORDERS',
    CANCEL_ORDER: 'ROLE_RE_CANCEL_ORDER',
    EDIT_ORDER: 'ROLE_RE_EDIT_ORDER',
    CHECKOUT_PAGE: 'ROLE_RE_CHECKOUT_PAGE',
    INVOICE_PAGE: 'ROLE_RE_INVOICE_PAGE',
    DOWNLOAD_INVOICE: 'ROLE_RE_DOWNLOAD_INVOICE',
    INVOICE_DETAILS: 'ROLE_RE_INVOICE_DETAILS',
    FAQ: 'ROLE_RE_FAQ',
    CONTACT_US: 'ROLE_RE_CONTACT_US',
    PRIVACY_POLICY: 'ROLE_RE_PRIVACY_POLICY',
    SERVICES_VERSIONS: 'ROLE_RE_WIDGET_SERVICES_VERSIONS',
    DISPLAY_GAMES_LIST: 'ROLE_RE_DISPLAY_GAMES_LIST',
    PLANOGRAMS_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_PLANOGRAMS',
    DISPLAY_RETAILER_GROUP_LIST: 'ROLE_RE_RETAILER_GROUP_LIST',
    CREATE_RETAILER_GROUP_LIST: 'ROLE_RE_RETAILER_GROUP_CREATE',
    UPDATE_RETAILER_GROUP_LIST: 'ROLE_RE_RETAILER_GROUP_UPDATE',
    DELETE_RETAILER_GROUP_LIST: 'ROLE_RE_RETAILER_GROUP_DELETE',
    VIEW_RETAILER_GROUP_LIST: 'ROLE_RE_RETAILER_GROUP_VIEW',
    ROLE_PLANOGRAM_LIST: 'ROLE_RE_PLANOGRAM_LIST',
    ROLE_PLANOGRAM_CREATE: 'ROLE_RE_PLANOGRAM_CREATE',
    ROLE_PLANOGRAM_UPDATE: 'ROLE_RE_PLANOGRAM_UPDATE',
    ROLE_PLANOGRAM_DELETE: 'ROLE_RE_PLANOGRAM_DELETE',
    ROLE_PLANOGRAM_VIEW: 'ROLE_RE_PLANOGRAM_VIEW',
    UPCOMING_GAMES_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_UPCOMING_GAMES',
    CLOSING_GAMES_WIDGET: 'ROLE_RE_DASHBOARD_WIDGET_CLOSING_GAMES',
    ROLE_RETURN_LIST: 'ROLE_RE_RETURN_LIST',
    ROLE_RETURN_CREATE: 'ROLE_RE_CREATE_RETURN',
    ROLE_RETURN_CANCEL: 'ROLE_RE_CANCEL_RETURN',
    ROLE_RETURN_VIEW: 'ROLE_RE_VIEW_RETURN',
    ROLE_COURIER_LIST: 'ROLE_RE_LIST_COURIER',
    ROLE_COURIER_CREATE: 'ROLE_RE_CREATE_COURIER',
    ROLE_COURIER_UPDATE: 'ROLE_RE_UPDATE_COURIER',
    ROLE_COURIER_VIEW: 'ROLE_RE_VIEW_COURIER',
    ROLE_COURIER_DELETE: 'ROLE_RE_DELETE_COURIER',
};

export default permissions;
