import React from 'react';
import { Loader } from 'semantic-ui-react';

const ReportDataSection = ({ generating }) => (
    <div className="data-section sales-report">
        <span className="icon-Reports secondary-color" style={{ fontSize: 90 }}>
            {' '}
        </span>
        {!generating ? (
            <div className="textContainerCss">
                <span>Choose the criteria and click on</span>
                <span>
                    <span className="bold">Generate Report</span> button
                </span>
            </div>
        ) : (
            <div className="textContainerCss">
                <Loader active={generating} inline="centered" />
                <span style={{ marginTop: 20 }}>
                    We are generating the report. Your
                </span>
                <span>download will start in a few seconds</span>
            </div>
        )}
    </div>
);

export default ReportDataSection;
