import { Backend } from './backend';

class PrivacyPolicyApi {
    constructor() {
        this.path = '/organization/privacy-policy';
    }

    async getPrivacyPolicy() {
        try {
            return await Backend.send({
                method: 'GET',
                url: this.path,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePrivacyPolicy(html) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: this.path,
                body: html,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default PrivacyPolicyApi;
