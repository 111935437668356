import {
    DAILY_TASKS,
    DAILY_TASKS_LOADING,
    OTHER_TASKS_FOR_DAY,
    OTHER_TASKS_FOR_DAY_LOADING,
    OTHER_TASKS_FOR_DAY_COUNT,
    RETAILER_TASKS_FOR_DAY_COUNT,
    SET_DAILY_TASK_TAB,
    SET_IS_CURRENT_WEEK,
    DAILY_TASKS_SELECT_DATE,
    DAILY_TASKS_RESET_SELECTED_DATE,
    INIT_DAILY_TASKS,
    UPDATE_MODAL_VALUES,
} from '../../actions/tasks/dailyTasks';

import { tabIndices } from '../../../constants/dailyTasks';

const { RETAILER_VISIT } = tabIndices;

const INITIAL_STATE = {
    retailerVisitsLoading: false,
    content: [],
    otherContent: [],
    otherTasksLoading: false,
    otherTasksCount: 0,
    retailerTasksCount: 0,
    tabSelected: RETAILER_VISIT,
    isCurrentWeek: false,
    currentSelectedWeek: new Date(),
    dateSelected: new Date(),
    zoneIds: [],
};

const dailyTasks = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_DAILY_TASKS:
            return { ...INITIAL_STATE };
        case DAILY_TASKS_SELECT_DATE:
            return { ...state, dateSelected: action.date };
        case DAILY_TASKS_RESET_SELECTED_DATE:
            return { ...state, dateSelected: new Date() };
        case DAILY_TASKS_LOADING:
            return { ...state, retailerVisitsLoading: action.toggle };
        case DAILY_TASKS:
            return {
                ...state,
                content: [...action.content],
                zoneIds: [...action.zoneIds],
                retailerVisitsLoading: false,
            };
        case OTHER_TASKS_FOR_DAY:
            return {
                ...state,
                otherContent: [...action.content],
                otherTasksLoading: false,
            };
        case OTHER_TASKS_FOR_DAY_LOADING:
            return {
                ...state,
                otherTasksLoading: action.toggle,
                otherTasksCount: action.toggle ? 0 : state.otherTasksCount,
            };
        case OTHER_TASKS_FOR_DAY_COUNT:
            return { ...state, otherTasksCount: action.count };
        case UPDATE_MODAL_VALUES:
            return {
                ...state,
                retailerIdStart: action.retailerIdStart,
                retailerIdEnd: action.retailerIdEnd,
                locationStart: action.locationStart,
                locationEnd: action.locationEnd,
            };
        case RETAILER_TASKS_FOR_DAY_COUNT:
            return { ...state, retailerTasksCount: action.count };
        case SET_DAILY_TASK_TAB:
            return {
                ...state,
                tabSelected:
                    action.tabIndex > -1 ? action.tabIndex : RETAILER_VISIT,
            };
        case SET_IS_CURRENT_WEEK:
            return { ...state, isCurrentWeek: action.isCurrentWeek };
        default:
            return state;
    }
};
export default dailyTasks;
