import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { groupBy, isFunction, map, replace, sortBy, uniqBy } from 'lodash';
import {
    Header,
    Grid,
    Image,
    Dropdown,
    Icon,
    Checkbox,
    Label,
    Input,
} from 'semantic-ui-react';
import { HashLink as Link } from 'react-router-hash-link';
import Localize from '../../constants/i18n-utils';
import { formatMoney } from '../../constants/utils';
import GameCatalogCard from '../gameCatalogCard/GameCatalogCard';
import noImage from '../../assets/noImage.jpg';
import './styles.css';
import _ from 'lodash';
import { CustomPopUp } from '../custom-pop-up/custom-pop-up';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { setFilteredGames } from '../../redux/actions';
import NoItems from '../NoItems';
import FilterSection from '../filter/FilterSection';

const replaceRegex = /\s|\//g;

class GameCatalog extends Component {
    constructor(props) {
        super(props);
        this.fixedHeaderDiv = React.createRef();
    }
    state = {
        gameCatalogDetailModal: {
            startDate: Localize.text('game.details.startDate', 'Start Date'),
            ticketPrice: Localize.text(
                'game.details.ticketPrice',
                'Ticket Price'
            ),
            retailCost: Localize.text('game.details.retailCost', 'Retail Cost'),
            totalPayout: Localize.text(
                'game.details.totalPayout',
                'Total Payout'
            ),
            commission: Localize.text('game.details.commission', 'Commission'),
            quality: Localize.text('game.details.quantity', 'Quantity'),
            update: Localize.text('game.details.update', 'Update'),
            cartButton: Localize.text('game.details.addToCart', 'Add to cart'),
            addToOrder: Localize.text(
                'game.details.addToOrder',
                'Add to order'
            ),
            ticketsPerDeal: Localize.text(
                'game.catalogCard.ticketPerDeal',
                'Tickets Per Deal'
            ),
            ticketsPerPack: Localize.text(
                'game.catalogCard.ticketPerPack',
                'Tickets Per Pack'
            ),
            totalRevenue: Localize.text(
                'game.catalogCard.totalRevenue',
                'Total Revenue'
            ),
            themeName: Localize.text('game.catalogCard.themeName', 'Theme'),
            packValue: Localize.text(
                'game.catalogCard.packValue',
                'Pack Value'
            ),
            maxPrize: Localize.text('game.catalogCard.maxPrize', 'Top Prize'),
            minimumOrderQuantity: Localize.text(
                'game.details.minimumOrderQuantity',
                'Min Order Quantity'
            ),
            maximumOrderQuantity: Localize.text(
                'game.details.maximumOrderQuantity',
                'Max Order Quantity'
            ),
        },
        gameCatalogCard: {
            addToCart: Localize.text('game.details.addToCart', 'Add to cart'),
            ticketsPerDealLocalized: Localize.text(
                'game.catalogCard.ticketPerDeal',
                'Tickets Per Deal'
            ),
            ticketsPerPackLocalized: Localize.text(
                'game.catalogCard.ticketPerPack',
                'Tickets Per Pack'
            ),
            commissionLocalized: Localize.text(
                'game.catalogCard.commission',
                'Commission'
            ),
            themeName: Localize.text('game.catalogCard.themeName', 'Theme'),
            packValue: Localize.text(
                'game.catalogCard.packValue',
                'Pack Value'
            ),
            pricePoint: Localize.text(
                'game.details.ticketPrice',
                'Price Point'
            ),
        },
        posGameCatalogDetailModal: {
            gameType: Localize.text('game.details.gameType', 'Game Type'),
            name: Localize.text('game.details.name', 'Description'),
            reference: Localize.text('game.details.reference', 'Code'),
            cartButton: Localize.text('game.details.addToCart', 'Add to cart'),
            addToOrder: Localize.text(
                'game.details.addToOrder',
                'Add to order'
            ),
            themeName: Localize.text('game.catalogCard.themeName', 'Theme'),
            quantity: Localize.text('game.details.quantity', 'Quantity'),
            update: Localize.text('game.details.update', 'Update'),
            pricePoint: Localize.text(
                'game.details.ticketPrice',
                'Price Point'
            ),
            minimumOrderQuantity: Localize.text(
                'game.details.minimumOrderQuantity',
                'Min Order Quantity'
            ),
            maximumOrderQuantity: Localize.text(
                'game.details.maximumOrderQuantity',
                'Max Order Quantity'
            ),
        },
        themes: [],
        theme: '',
        searchText: '',
        listView: isMobile,
        showFilters: false,
        gotoDropDownValue: undefined,
    };

    async componentDidMount() {
        window.scrollTo(0, 10);
        window.addEventListener('scroll', this.scrollFn);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFn);
        window.scroll(0, 0);
    }

    headerPricePointRefs = [];

    setHeaderReferences = (ref) => {
        this.headerPricePointRefs.push(ref);
    };

    setGotoDropDownValue = (event, { value }) => {
        const { parentModalRef } = this.props;
        const selectLink = this.headerPricePointRefs.find(
            (ref) => ref.id === value
        );
        const clientBound = selectLink.getBoundingClientRect();
        const parent = this.getScrollParent(selectLink);
        const extraScroll =
            parentModalRef && parentModalRef.current
                ? (document.documentElement.clientHeight -
                      parentModalRef.current.clientHeight) /
                  2
                : 0;
        const marginDiv = parent.classList.contains('catalog-content')
            ? parent
            : parent.querySelector('.catalog-content');
        const style = marginDiv
            ? window.getComputedStyle(marginDiv)
            : { paddingTop: 0, marginTop: 0 };
        parent.scrollBy(
            clientBound.x,
            clientBound.y - parseInt(style.paddingTop) - extraScroll
        );
        this.setState({
            gotoDropDownValue: value,
        });
    };

    getScrollParent = (node) =>
        node && node.scrollHeight <= node.clientHeight
            ? this.getScrollParent(node.parentElement)
            : node;

    toggleListView = () => {
        this.setState((prevState) => ({ listView: !prevState.listView }));
    };
    toggleShowFilters = () => {
        this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
    };
    createImageJsx = (data, lastModified) => {
        return (
            <Image
                className="ticket-image object-fit-contain"
                alt=""
                src={data ? `${data}?${lastModified}` : noImage}
            />
        );
    };

    scrollFn = () => {
        const isAtTop = window.scrollY === 0;

        if (
            this.props.location.pathname === '/games' &&
            window.scrollY < 5 &&
            !isAtTop
        ) {
            window.scrollTo(0, 10);
        }
        if (this.state.fixHeader !== !isAtTop) {
            this.setState({ fixHeader: !isAtTop });
        }
    };

    formatPrice = (price) => formatMoney(price);

    getSortedHeaderList = () => {
        const { games } = this.props;
        return sortBy(
            uniqBy(
                map(games, (game) => {
                    if (game.gameType === 'PS') {
                        return {
                            name: 'POS',
                            id: game.gameType,
                        };
                    } else {
                        return {
                            name: this.formatPrice(game.price),
                            id: game.price,
                        };
                    }
                }),
                'name'
            ),
            'id'
        );
    };
    getHeaderList = () => {
        const { headerList: sortedData } = this.props;
        let { gotoDropDownValue } = this.state;
        const headers = map(sortedData, (data) => {
            if (data.name !== 'Future Games') {
                return replace(data.name, replaceRegex, '-');
            }
        });
        const dropdownMenu = map(sortedData, (data, i) => ({
            key: i,
            text: `${data.name}`,
            value: `${replace(data.name, replaceRegex, '-')}`,
        }));
        const li = map(dropdownMenu, (data, i) => {
            return (
                <div
                    key={i}
                    className="pill pill-list-item-wrapper"
                    onClick={(e) => {
                        this.setGotoDropDownValue(e, data);
                    }}
                >
                    <span>
                        <Link>{`${data.text}`}</Link>
                    </span>
                </div>
            );
        });
        return (
            sortedData && (
                <div className={'text-align-s p-4 pl-0'}>
                    <div
                        items={headers}
                        currentClassName="is-current"
                        className={'header-pill-item-scroll-spy min-tablet'}
                    >
                        {li}
                    </div>
                    <div className={'header-pill-item-dropdown max-mobile'}>
                        <Dropdown
                            compact
                            selection
                            text={gotoDropDownValue || dropdownMenu[0]?.value}
                            value={gotoDropDownValue}
                            options={dropdownMenu}
                            onChange={this.setGotoDropDownValue}
                        />
                    </div>
                </div>
            )
        );
    };

    getCardsToDisplay = () => {
        const {
            groupByProp,
            groupByPropForPos,
            sortByProp,
            headerKey,
            readOnly,
            headerList,
            suggestedQuantity,
            isEditOrder,
        } = this.props;
        const { games } = this.props;
        if (!games.length) {
            return;
        }

        const dataToDisplay = map(
            _.filter(games, (game) => game.gameType !== 'PS'),
            (game) => {
                const data = {
                    headerText: isFunction(headerKey)
                        ? headerKey(game)
                        : game[headerKey],
                    formatedPrice: formatMoney(game.price),
                    price: game.price,
                    gameDetails: {
                        gameId: game.id,
                        reference: game.reference,
                        name: game.name,
                        gameRefAndName: `#${game.reference} - ${game.name}`,
                        themeName: game.themeName,
                        ticketPerDeal: game.ticketsPerBook,
                        packValue: game.price * game.ticketsPerBook,
                        commission:
                            game.price * game.ticketsPerBook -
                            game.gleps -
                            game.retailPrice,
                        retailPrice: game.retailPrice,
                        ticketPrice: game.price,
                        startDate: game.activeDate,
                        payout: game.payout,
                        image: this.createImageJsx(
                            game.gameImageUrl,
                            game.modifiedDate
                        ),
                        maxPrize: game.maxPrize,
                        suggestedQuantity: suggestedQuantity?.get(
                            game.reference
                        ),
                        minimumOrderQuantity: game.minimumOrderQuantity,
                        maximumOrderQuantity: game.maximumOrderQuantity,
                    },
                };

                const extraAttr = !data[groupByProp]
                    ? { [groupByProp]: game[groupByProp] }
                    : {};

                return {
                    ...data,
                    ...extraAttr,
                };
            }
        );

        const dataToDisplayForPosGames = map(
            _.filter(games, (game) => game.gameType === 'PS'),
            (game) => {
                const data = {
                    headerText: 'POS',
                    gameDetails: {
                        gameId: game.id,
                        reference: game.reference,
                        name: game.name,
                        gameRefAndName: `#${game.reference} - ${game.name}`,
                        retailPrice: game.retailPrice,
                        ticketPrice: game.price,
                        themeName: game.themeName,
                        image: this.createImageJsx(
                            game.gameImageUrl,
                            game.modifiedDate
                        ),
                        gameType: game.gameType,
                        minimumOrderQuantity: game.minimumOrderQuantity,
                        maximumOrderQuantity: game.maximumOrderQuantity,
                    },
                };

                const extraAttr = !data[groupByProp]
                    ? { [groupByProp]: game[groupByProp] }
                    : {};

                return {
                    ...data,
                    ...extraAttr,
                };
            }
        );

        const { fixHeader } = this.state;
        let headerPaddingTop = {};

        //TODO: change this to use refs
        if (fixHeader) {
            const retailerNavHeight = !!document.querySelector('.retailer-nav')
                ? document.querySelector('.retailer-nav').offsetHeight
                : 0;

            const stickyHeader = !!document.querySelector(
                '.price-header-sticky'
            )
                ? document.querySelector('.price-header-sticky')
                : 0;

            headerPaddingTop = {
                paddingTop: `${retailerNavHeight + stickyHeader + 15} px`,
            };
        }

        const groupedDisplay = groupBy(dataToDisplay, groupByProp);

        const groupedDisplayForPos = groupBy(
            dataToDisplayForPosGames,
            groupByPropForPos
        );

        return map(headerList, (header) => {
            let data;

            if (header.name === 'POS') {
                data = groupedDisplayForPos[header.name];
            } else {
                data = groupedDisplay[header.name];
            }

            if (!data) return;

            const sortedGameDetails = sortBy(
                map(data, (d) => d.gameDetails),
                sortByProp
            );

            return (
                <div
                    id={replace(data[0].headerText, replaceRegex, '-')}
                    key={data[0].headerText}
                    className="section-data-wrapper"
                    ref={this.setHeaderReferences}
                >
                    <div className="section-data">
                        <div>
                            <Header
                                className="price-header"
                                style={headerPaddingTop}
                            >
                                <h1>{data[0].headerText}</h1>
                            </Header>
                        </div>
                        <Grid className="game-card-section">
                            {map(sortedGameDetails, (detail) => (
                                <Grid.Column
                                    computer={4}
                                    tablet={8}
                                    mobile={16}
                                    textAlign="center"
                                    key={detail.gameId}
                                    className="game-card-column"
                                >
                                    <GameCatalogCard
                                        game={detail}
                                        localizeCard={
                                            this.state.gameCatalogCard
                                        }
                                        localizeDetail={
                                            this.state.gameCatalogDetailModal
                                        }
                                        localizePosGameDetail={
                                            this.state.posGameCatalogDetailModal
                                        }
                                        readOnly={readOnly}
                                        isEditOrder={isEditOrder}
                                        listView={this.state.listView}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </div>
                </div>
            );
        });
    };

    getThemes = () => {
        const { themes: themeList } = this.props;
        const { themes } = this.state;
        const themesArray = [];

        if (themeList && themeList.length > 0) {
            map(themeList, ({ themeName }) => {
                if (themeName && themeName !== '') {
                    themesArray.push({
                        text: 'Themes',
                        value: themeName,
                        content: (
                            <Checkbox
                                checked={themes.indexOf(themeName) !== -1}
                                disabled
                                className={
                                    'opacity-1 w-100 pb-3 pt-3 ps-2 pe-2'
                                }
                                label={themeName}
                                readOnly
                                onClick={() => {
                                    this.setTheme(themeName);
                                }}
                            />
                        ),
                    });
                }
            });
        }

        return themesArray;
    };
    setGameText = async (searchText) => {
        await this.setState({ searchText });
        this.filterGames();
    };
    setTheme = async (theme: string) => {
        const { themes } = this.state;
        if (!theme) {
            themes.splice(0, themes.length);
        } else if (themes.indexOf(theme) === -1) {
            themes.push(theme);
        } else {
            themes.splice(themes.indexOf(theme), 1);
        }
        await this.setState({ themes });
        this.filterGames();
    };
    setThemes = async (themes) => {
        await this.setState({ themes });
        this.filterGames();
    };
    filterGames = () => {
        const { setFilteredGames } = this.props;
        const { themes, searchText } = this.state;
        setFilteredGames(themes, searchText);
    };

    mobileListGridViewButton = () => {
        const { listView } = this.state;
        return (
            <div className={'max-mobile'}>
                <div
                    className={'div-list-grid-option d-flex'}
                    onClick={this.toggleListView}
                >
                    <div>
                        <Icon
                            name={'list'}
                            size={'big'}
                            color={listView ? 'blue' : 'black'}
                        />
                    </div>
                    <div>
                        <Icon
                            name={'th'}
                            size={'big'}
                            color={listView ? 'black' : 'blue'}
                        />
                    </div>
                </div>
            </div>
        );
    };
    handleGameTextEnter = (e) => {
        if (e.key === 'Enter') {
            this.toggleShowFilters();
        }
    };
    getTextSearchFilters = () => {
        const { searchText } = this.state;
        return (
            <div className={'position-relative'}>
                <Input
                    className={'float-right '}
                    value={searchText}
                    placeholder={'Game Name / Code'}
                    onChange={(event) => {
                        this.setGameText(event.target.value);
                    }}
                >
                    <input type="text" onKeyDown={this.handleGameTextEnter} />
                </Input>
                {searchText && searchText.length > 0 && (
                    <div className={'clear-info-game-name'}>
                        <CustomPopUp content="Clear Filters">
                            <Icon
                                className={'clear-info clear-info-black'}
                                name={'cancel'}
                                onClick={() => {
                                    this.setGameText('');
                                }}
                            />
                        </CustomPopUp>
                    </div>
                )}
            </div>
        );
    };

    getThemeFilter = () => {
        const { theme, themes } = this.state;
        const themesOptions = this.getThemes();
        return (
            <div className={' position-relative'}>
                <Dropdown
                    className={' filter-dropdown w-100'}
                    placeholder="Themes"
                    value={theme}
                    selection
                    options={themesOptions}
                    clearable
                    closeOnChange={false}
                />
                {themes.length > 0 && (
                    <>
                        <Label className={'count-info'} circular color="black">
                            {themes.length}
                        </Label>
                        <div className={'clear-info-theme'}>
                            <CustomPopUp content="Clear Filters">
                                <Icon
                                    className={'clear-info clear-info-white'}
                                    name={'cancel'}
                                    onClick={() => {
                                        this.setTheme(undefined);
                                    }}
                                />
                            </CustomPopUp>
                        </div>
                    </>
                )}
            </div>
        );
    };
    render() {
        const { fixHeader, themes, searchText } = this.state;
        const { stickyHeader, games, themes: themeList } = this.props;

        return (
            <div>
                <div className="game-nav">
                    <div
                        ref={this.fixedHeaderDiv}
                        className={classnames('price-header-sticky', {
                            'fix-header': stickyHeader && fixHeader,
                        })}
                    >
                        <Grid className={'ms-0 me-0'}>
                            <Grid.Row className={'pb-0'}>
                                <Grid.Column>
                                    <Header className="game-nav-header">
                                        Games
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'pt-0 '} columns={2}>
                                <Grid.Column>
                                    {this.getHeaderList()}
                                </Grid.Column>
                                <Grid.Column className={'pt-4 color-black'}>
                                    <div
                                        className={
                                            'd-flex flex-direction-row-reverse'
                                        }
                                    >
                                        {this.mobileListGridViewButton()}

                                        <div className={'min-tablet'}>
                                            {' '}
                                            <div
                                                className={'div-filter-option '}
                                            >
                                                {this.getTextSearchFilters()}
                                                {this.getThemeFilter()}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'float-right position-relative me-4'
                                            }
                                        >
                                            <div className={'max-mobile '}>
                                                <FilterSection
                                                    filters={[
                                                        {
                                                            label: 'Themes',
                                                            name: 'theme',
                                                            multiple: true,
                                                            toggleAll: true,
                                                            client: true,
                                                            searchable: true,
                                                            items: map(
                                                                _.filter(
                                                                    themeList,
                                                                    ({
                                                                        themeName,
                                                                    }) =>
                                                                        themeName !==
                                                                            undefined &&
                                                                        themeName.length >
                                                                            0
                                                                ),
                                                                ({
                                                                    themeName,
                                                                }) => ({
                                                                    text: themeName,
                                                                    value: themeName,
                                                                })
                                                            ),
                                                        },
                                                        {
                                                            label: 'Game Name / Code',
                                                            name: 'gameName',
                                                            isTextSearch: true,
                                                        },
                                                    ]}
                                                    onApplyFilter={(data) => {
                                                        if (data.theme) {
                                                            this.setThemes(
                                                                data.theme
                                                            );
                                                        } else {
                                                            this.setThemes([]);
                                                        }
                                                        if (
                                                            data.gameName &&
                                                            data.gameName[0]
                                                                .length > 0
                                                        ) {
                                                            this.setGameText(
                                                                data.gameName[0]
                                                            );
                                                        } else {
                                                            this.setGameText(
                                                                undefined
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <Icon name="filter pt-2 min-tablet" />
                                            {(themes.length > 0 ||
                                                (searchText &&
                                                    searchText.length > 0)) && (
                                                <>
                                                    <div
                                                        className={
                                                            'clear-info-border p-2 min-tablet'
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            'clear-info-all'
                                                        }
                                                    >
                                                        {' '}
                                                        <CustomPopUp content="Clear Filters">
                                                            <Icon
                                                                className={
                                                                    'clear-info-cancel clear-info-white'
                                                                }
                                                                name={'cancel'}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.toggleShowFilters();
                                                                    this.setTheme(
                                                                        undefined
                                                                    );
                                                                    this.setGameText(
                                                                        ''
                                                                    );
                                                                    this.toggleShowFilters();
                                                                }}
                                                            />
                                                        </CustomPopUp>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column></Grid.Column>
                                <Grid.Column></Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
                <div
                    className={classnames('catalog-content', {
                        'fix-header': stickyHeader && fixHeader,
                    })}
                >
                    {games && games.length > 0 ? (
                        this.getCardsToDisplay()
                    ) : (
                        <NoItems
                            style={{ marginTop: '150px' }}
                            message={Localize.text(
                                'gameCataLog.noGamesToDisplay',
                                'There are no Games'
                            )}
                        />
                    )}
                </div>
            </div>
        );
    }
}

GameCatalog.propTypes = {
    games: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            price: PropTypes.number.isRequired,
            reference: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            ticketsPerBook: PropTypes.number.isRequired,
            gleps: PropTypes.number.isRequired,
            retailPrice: PropTypes.number.isRequired,
            activeDate: PropTypes.string.isRequired,
            payout: PropTypes.number.isRequired,
            gameImageUrl: PropTypes.string.isRequired,
            modifiedDate: PropTypes.string.isRequired,
            themeName: PropTypes.string.isRequired,
            maxPrize: PropTypes.number.isRequired,
        })
    ).isRequired,
    headerList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,

    headerKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    groupByProp: PropTypes.string,
    sortByProp: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilteredGames: (themes, searchText) =>
            dispatch(setFilteredGames(themes, searchText)),
    };
};

export default withRouter(connect(() => {}, mapDispatchToProps)(GameCatalog));
