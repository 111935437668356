import LottoContactInfoApi from '../../../services/lotto-contact-info-service';
import { success, error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

export const CONTACT_US_CONFIG_LOADING = 'CONTACT_US_CONFIG_LOADING';
export const CONTACT_US_CONFIG_LOADING_DONE = 'CONTACT_US_CONFIG_LOADING_DONE';
export const CONTACT_US_CONFIG_INIT = 'CONTACT_US_CONFIG_INIT';
export const CONTACT_US_CONFIG_GET = 'CONTACT_US_CONFIG_GET';

const lottoContactInfoApi = new LottoContactInfoApi();

export const getContactUsConfig = () => {
    return async (dispatch) => {
        dispatch({ type: CONTACT_US_CONFIG_LOADING });

        try {
            const payload = await lottoContactInfoApi.getAllContactUsData();

            dispatch({
                type: CONTACT_US_CONFIG_GET,
                payload,
            });
        } catch (err) {
            console.error(err);
            error({
                msg: 'Could not retrieve contact us information',
            });
        }

        dispatch({ type: CONTACT_US_CONFIG_LOADING_DONE });
    };
};

export const saveContactUsConfig = (v) => {
    return async (dispatch) => {
        dispatch({ type: CONTACT_US_CONFIG_LOADING });

        try {
            const payload = await lottoContactInfoApi.saveContactUsData(v);

            dispatch({
                type: CONTACT_US_CONFIG_GET,
                payload,
            });

            dispatch(
                success({ msg: 'Successfully saved contact us information' })
            );
        } catch (err) {
            console.error(err);
            dispatch(
                error({
                    msg: `Could not save contact us information: ${getErrorMessage(
                        err
                    )}`,
                })
            );
        }

        dispatch({ type: CONTACT_US_CONFIG_LOADING_DONE });
    };
};

export const initContactUsConfig = () => ({
    type: CONTACT_US_CONFIG_INIT,
});
