import { SURVEY_EXPORT_LOADING } from '../../actions/marketing/exportSurvey';

const INITIAL_STATE = {
    loading: false,
};

const exportSurvey = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SURVEY_EXPORT_LOADING:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};
export default exportSurvey;
