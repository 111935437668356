import React from 'react';

import { Icon, Checkbox, Table, Grid } from 'semantic-ui-react';
import { map, sortBy, sumBy, filter } from 'lodash';
import Localize from '../../constants/i18n-utils';

import './checkout-items.css';
import { formatMoney, calculateTotalCostForOrder } from '../../constants/utils';
import { CustomPopUp } from '../../components/custom-pop-up/custom-pop-up';
import classnames from 'classnames';

export const CheckoutItems = ({
    orderedItems,
    includeSampleGames,
    handleDecrementCartQuantityForGame,
    incrementCartQuantityForGame,
    handleRemoveGame,
    removeAllLinkJsx,
    showSgtQty,
    toggleOrderSample,
}) => {
    const getSuggetedQtyText = () => (
        <div className="  ">
            {showSgtQty
                ? `${Localize.text('cart.stgQty', 'Suggested Qty')}`
                : undefined}
        </div>
    );

    const getCartQtyText = () => (
        <div className="pr-3 ">{`${Localize.text('cart.qty', 'Qty')}`}</div>
    );

    const getCartCostText = () => (
        <div className=" ">{`${Localize.text('cart.cost', 'Cost')}`}</div>
    );

    const getCartTotalText = () => (
        <div className="label bold ">{`${Localize.text(
            'cart.total',
            'Total'
        )}:`}</div>
    );

    const getTotalQtyText = () => (
        <div className="label  max-mobile">{`${Localize.text(
            'cart.totalQty',
            'Total Qty'
        )}:`}</div>
    );
    const getTotalCostText = () => (
        <div className="label  max-mobile">{`${Localize.text(
            'cart.totalCost',
            'Total Cost'
        )}:`}</div>
    );

    const getGameName = (i, game, orderedSample) => (
        <div>
            <span>{`${i + 1}. ${game.gameRefAndName}`}</span>
            {includeSampleGames && (
                <div className="sample-checkbox-wrapper ms-0 text-align-s pl-4">
                    <Checkbox
                        onClick={() => toggleOrderSample(game)}
                        label={Localize.text(
                            'cart.includeSamples',
                            'Include Samples'
                        )}
                        checked={!!orderedSample}
                    />
                </div>
            )}
        </div>
    );

    const getGameQuanityView = (game, qty) => (
        <div
            className={classnames({
                'adjust-margin': incrementCartQuantityForGame,
            })}
        >
            {handleDecrementCartQuantityForGame && (
                <Icon
                    className="icon-Minus"
                    onClick={() =>
                        handleDecrementCartQuantityForGame(game, qty)
                    }
                />
            )}
            <span className=" ">{qty}</span>
            {incrementCartQuantityForGame && (
                <Icon
                    className="icon-Plus"
                    onClick={() => incrementCartQuantityForGame(game)}
                />
            )}
        </div>
    );

    const getCartCostView = (game, qty) => (
        <span>
            {' '}
            {formatMoney(game.retailPrice ? game.retailPrice * qty : game.cost)}
        </span>
    );

    const getSuggestedQtyView = (game, suggestedQuantity, qty) => {
        let message;
        let color;
        if (game.minimumOrderQuantity && game.minimumOrderQuantity > qty) {
            message = `Minimum order limit is ${game.minimumOrderQuantity}`;
            color = 'red';
        } else if (showSgtQty && suggestedQuantity && qty < suggestedQuantity) {
            message = 'Ordered quantity is less than the suggested quantity';
            color = 'yellow';
        } else {
            return <div className={'pr-6'}></div>;
        }
        return (
            <CustomPopUp content={message}>
                <>
                    <div className={'suggested-quantity-warning-wrapper'}>
                        <Icon
                            className="exclamation "
                            color={color}
                            aria-hidden={true}
                        />
                    </div>
                </>
            </CustomPopUp>
        );
    };

    const getRemoveGameView = (game) =>
        handleRemoveGame && (
            <CustomPopUp content={Localize.text('cart.remove', 'Remove')}>
                {getRemoveGameIcon(game)}
            </CustomPopUp>
        );

    const getRemoveGameIcon = (game) =>
        handleRemoveGame && (
            <div className="remove-game-wrapper">
                <Icon
                    className="icon-Close"
                    onClick={() => handleRemoveGame(game)}
                />
            </div>
        );

    const getCardTotalQty = () => sumBy(orderedItems, 'qty');
    const getCardTotalCost = () =>
        formatMoney(calculateTotalCostForOrder(orderedItems));
    return (
        <div className={'checkout-items-component'}>
            <div className={'max-mobile'}>
                {map(
                    sortBy(orderedItems, 'game.gameRefAndName'),
                    ({ game, qty, suggestedQuantity, orderedSample }, i) => (
                        <Grid className={'game-item-mobile pt-2 pb-2'}>
                            {' '}
                            <Grid.Row className={'p-1 bold'}>
                                <Grid.Column
                                    className={
                                        'd-flex justify-content-space-between'
                                    }
                                >
                                    {getGameName(i, game, orderedSample)}

                                    <div
                                        className={
                                            'remove-game-mobile-div text-align-e'
                                        }
                                    >
                                        {getRemoveGameIcon(game)}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} className={'p-1'}>
                                <Grid.Column
                                    width={6}
                                    className={'text-align-s pr-0'}
                                >
                                    {getSuggetedQtyText()}
                                </Grid.Column>
                                <Grid.Column width={4} className={''}>
                                    {getCartQtyText()}
                                </Grid.Column>
                                <Grid.Column
                                    width={6}
                                    className={'pl-0 text-align-e'}
                                >
                                    {getCartCostText()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'p-1 bold'}>
                                <Grid.Column
                                    width={5}
                                    className={'text-align-s '}
                                >
                                    {showSgtQty && (
                                        <span>{suggestedQuantity}</span>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <div
                                        className={
                                            'd-flex pl-4 justify-content-center'
                                        }
                                    >
                                        {getGameQuanityView(game, qty)}
                                        {getSuggestedQtyView(
                                            game,
                                            suggestedQuantity,
                                            qty
                                        )}
                                    </div>
                                </Grid.Column>
                                <Grid.Column
                                    width={5}
                                    className={'text-align-e'}
                                >
                                    {getCartCostView(game, qty)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )
                )}

                <Grid className={'mobile-total-wrapper m-2 mt-4'}>
                    <Grid.Row columns={2} className={'pb-0'}>
                        <Grid.Column className={'text-align-s'}>
                            {getTotalQtyText()}
                        </Grid.Column>
                        <Grid.Column className={'text-align-e bold'}>
                            {getCardTotalQty()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column className={'text-align-s '}>
                            <span>{getTotalCostText()}</span>
                        </Grid.Column>
                        <Grid.Column className={'text-align-e bold'}>
                            {getCardTotalCost()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {removeAllLinkJsx && (
                    <div className={'pl-2'}>{removeAllLinkJsx}</div>
                )}
            </div>
            <div className={'min-tablet'}>
                <Table singleLine basic className={'border-none mb-0 '}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>
                                {getSuggetedQtyText()}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {getCartQtyText()}{' '}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {getCartCostText()}
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {map(
                            sortBy(orderedItems, 'game.gameRefAndName'),
                            (
                                { game, qty, suggestedQuantity, orderedSample },
                                i
                            ) => (
                                <>
                                    {' '}
                                    <Table.Row className={'width-95'}>
                                        <Table.Cell className={'text-align-s'}>
                                            {getGameName(
                                                i,
                                                game,
                                                orderedSample
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {showSgtQty && (
                                                <div
                                                    className={
                                                        'd-flex justify-content-space-between'
                                                    }
                                                >
                                                    <span
                                                        className={'max-mobile'}
                                                    >
                                                        {getSuggetedQtyText()}
                                                    </span>{' '}
                                                    <span>
                                                        {suggestedQuantity}
                                                    </span>
                                                </div>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div
                                                className={
                                                    'd-flex justify-content-space-between'
                                                }
                                            >
                                                <span className={'max-mobile'}>
                                                    {getCartQtyText()}
                                                </span>
                                                {getGameQuanityView(game, qty)}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div
                                                className={
                                                    'd-flex justify-content-space-between'
                                                }
                                            >
                                                <span className={'max-mobile'}>
                                                    {getCartCostText()}
                                                </span>
                                                {getCartCostView(game, qty)}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell
                                            className={
                                                'background-transparent close-table-cell '
                                            }
                                        >
                                            <div
                                                className={
                                                    'd-flex flex-direction-row'
                                                }
                                            >
                                                {getRemoveGameView(game)}

                                                {getSuggestedQtyView(
                                                    game,
                                                    suggestedQuantity,
                                                    qty
                                                )}
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </>
                            )
                        )}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row className={'width-95'}>
                            <Table.Cell
                                className={classnames('text-align-s', {
                                    'min-tablet': !includeSampleGames,
                                })}
                            >
                                {includeSampleGames && (
                                    <div className="bold">{`${
                                        filter(orderedItems, 'orderedSample')
                                            .length
                                    } ${Localize.text(
                                        'cart.samplesIncluded',
                                        'Samples Included'
                                    )}`}</div>
                                )}
                            </Table.Cell>

                            <Table.Cell className={'min-tablet'}>
                                {getCartTotalText()}
                            </Table.Cell>
                            <Table.Cell
                                className={classnames('text-align-s', {
                                    'ptb-4': !includeSampleGames,
                                })}
                            >
                                <div
                                    className={
                                        'd-flex justify-content-space-between'
                                    }
                                >
                                    <span>{getTotalQtyText()}</span>{' '}
                                    <div
                                        className={classnames('bold  m-pr-0', {
                                            'pr-5': incrementCartQuantityForGame,
                                        })}
                                    >
                                        {getCardTotalQty()}
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div
                                    className={
                                        'd-flex justify-content-space-between'
                                    }
                                >
                                    <span>{getTotalCostText()}</span>
                                    <div className="bold">
                                        {getCardTotalCost()}
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell
                                className={
                                    'background-transparent close-table-cell'
                                }
                            ></Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                {removeAllLinkJsx && <div>{removeAllLinkJsx}</div>}
            </div>
        </div>
    );
};
