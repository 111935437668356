import {
    CHANGE_ACTIVE_USER_RETAILERS,
    UserRetailersActionTypes,
    UPDATE_USER_RETAILERS,
    UserRetailerStateType,
    UPDATE_LOADING_STATE,
    TOGGLE_SELECT_RETAILER_MODAL,
} from '../../type/user-retailers/user-reatlers';

const INITIAL_STATE: UserRetailerStateType = {
    isLoading: false,
    userRetailers: [],
    activeRetailer: undefined,
    isShowSelectRetailerModal: false,
};

const userRetailer = (
    state: UserRetailerStateType = INITIAL_STATE,
    action: UserRetailersActionTypes
) => {
    switch (action.type) {
        case CHANGE_ACTIVE_USER_RETAILERS:
            return { ...state, activeRetailer: action.payload };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        case UPDATE_USER_RETAILERS:
            return { ...state, userRetailer: action.payload };
        case TOGGLE_SELECT_RETAILER_MODAL:
            return { ...state, isShowSelectRetailerModal: action.payload };
        default:
            return state;
    }
};

export default userRetailer;
