import {
    SALES_ROUTES_WIDGET_LOADING,
    SALES_ROUTES_WIDGET_DATA,
} from '../../../actions/dashboard/widgets/weeklyTaskCompletion';

const INITIAL_STATE = {
    salesRouteTaskCompletion: [],
    loading: false,
};

const weeklyTaskCompletion = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_ROUTES_WIDGET_LOADING:
            return { ...state, loading: true };
        case SALES_ROUTES_WIDGET_DATA:
            return {
                ...state,
                salesRouteTaskCompletion: action.data,
                loading: false,
            };
        default:
            return state;
    }
};
export default weeklyTaskCompletion;
