import { TIERS_LIST, TIERS_LIST_LOADING } from '../actions';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
};

const tiers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TIERS_LIST_LOADING:
            return { ...state, loading: true };
        case TIERS_LIST:
            return {
                ...state,
                ...action.list,
                loading: false,
            };
        default:
            return state;
    }
};
export default tiers;
