import {
    TEXT_NOTE_CREATE_LOADING,
    TEXT_NOTE_CREATE_LOADING_DONE,
    CREATE_TEXT_NOTE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
};

const createTextNote = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_TEXT_NOTE:
            return { ...state, [action.payload.args]: true };
        case TEXT_NOTE_CREATE_LOADING:
            return { ...state, loading: true };
        case TEXT_NOTE_CREATE_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default createTextNote;
