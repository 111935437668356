import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import Page from '../../layout/Page';
import InProgressOrders from '../../components/inProgressOrders/InProgressOrders';
import PastOrders from '../../components/pastOrders/PastOrders';
import './styles.css';
import { connect } from 'react-redux';
import { pushReferrer } from '../../redux/actions';
import orderTabs from '../../constants/orderTabs';
import Permissions from '../../constants/permissions';
import { hasRole } from '../../constants/utils';
import { initPastOrders } from '../../redux/actions';

const IN_PROGRESS_MENU_ITEM = orderTabs.IN_PROGRESS;
const PAST_ORDERS_MENU_ITEM = orderTabs.PAST;

export class OrderHistoryPage extends Component {
    state = {
        activeItem: this.props.history.location.pathname,
    };

    handleItemClick = (e, { name }) => {
        const { initPastOrders } = this.props;
        this.props.setReferrer({
            path: name,
            page: 'Orders',
        });
        initPastOrders();
        this.props.history.push(name);
        this.setState({ activeItem: name });
    };

    render() {
        const { activeItem } = this.state;
        const { userInfo } = this.props;
        return (
            <Page
                name="Orders"
                title="Orders"
                className="order-history-page"
                data-test="orderHistoryPage"
            >
                <Menu pointing secondary className="order-history-menu">
                    {hasRole(
                        userInfo.authorities,
                        Permissions.IN_PROGRESS_ORDERS
                    ) && (
                        <Menu.Item
                            className="bold"
                            name={IN_PROGRESS_MENU_ITEM}
                            active={activeItem === IN_PROGRESS_MENU_ITEM}
                            onClick={this.handleItemClick}
                        />
                    )}
                    {hasRole(userInfo.authorities, Permissions.PAST_ORDERS) && (
                        <Menu.Item
                            className="bold"
                            name={PAST_ORDERS_MENU_ITEM}
                            active={activeItem === PAST_ORDERS_MENU_ITEM}
                            onClick={this.handleItemClick}
                        />
                    )}
                </Menu>

                {activeItem === IN_PROGRESS_MENU_ITEM ? (
                    <InProgressOrders />
                ) : (
                    <PastOrders />
                )}
            </Page>
        );
    }
}

const mapStateToProps = ({ referrer: { referrer }, auth: { info } }) => {
    const referringPage =
        referrer && referrer.length > 0
            ? referrer[referrer.length - 1].referringPage
            : null;
    const referringPath =
        referrer && referrer.length > 0
            ? referrer[referrer.length - 1].referringPath
            : null;
    return {
        referringPage,
        referringPath,
        userInfo: info,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
        initPastOrders: () => dispatch(initPastOrders()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
