import React from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import { logout, setActiveMenu } from '../redux/actions';
import { handleAuthError } from '../services/error-handler';
import Store from '../redux/store';
import config from '../constants/config';

class IdleTimerLogout extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.state = { timeout: config.INACTIVE_TIMEOUT };
    }

    componentDidMount() {
        window.addEventListener('storage', this._onOtherWindowActive, true);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this._onOtherWindowActive, true);
    }

    _onOtherWindowActive = (e) => {
        if (e && e.key === 'activity') {
            this.idleTimer && this.idleTimer.reset(); // idleTimer is null under some circumstances
        }
    };

    _onActive = () => {
        window.localStorage.setItem('activity', new Date());
    };

    _onIdle = () => {
        console.debug(
            "logging out - idle timer reached. Idle '" +
                config.INACTIVE_TIMEOUT / 1000 +
                "' seconds"
        );
        Store.store.dispatch(setActiveMenu('Dashboard'));
        Store.store.dispatch(logout());
        Store.store.dispatch(handleAuthError());
    };

    render() {
        return (
            <IdleTimer
                ref={(ref) => {
                    this.idleTimer = ref;
                }}
                element={document}
                onIdle={this._onIdle}
                onAction={this._onActive}
                debounce={500}
                timeout={this.state.timeout}
            />
        );
    }
}

export default withRouter(IdleTimerLogout);
