import {
    GET_PLANOGRAMS,
    UPDATE_LOADING_STATE,
    PlanogramActionTypes,
    PlanogramStateType,
    GET_PLANOGRAM_SEARCH,
} from '../../type/planogram/planogram-type';

const initialState: PlanogramStateType = {
    isLoading: false,
    planogramList: [],
    planogramPage: undefined,
};
const planogramReducer = (
    state = initialState,
    action: PlanogramActionTypes
) => {
    switch (action.type) {
        case GET_PLANOGRAMS:
            return {
                ...state,
                isLoading: false,
                planogramList: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        case GET_PLANOGRAM_SEARCH:
            return {
                ...state,
                isLoading: false,
                planogramPage: action.payload,
            };
        default:
            return state;
    }
};
export default planogramReducer;
