import {
    UserPreferenceActionTypes,
    UPDATE_USER_PREFERENCE,
    UPDATE_LOADING_STATE,
    UserPreferenceStateType,
} from '../../type/user-preference/user-preference-type';

const INITIAL_STATE: UserPreferenceStateType = {
    isLoading: false,
    userPreferenceMap: new Map(),
};

const userPreference = (
    state: UserPreferenceStateType = INITIAL_STATE,
    action: UserPreferenceActionTypes
) => {
    switch (action.type) {
        case UPDATE_USER_PREFERENCE:
            state.userPreferenceMap.set(
                action.payload.userPreferenceId.key,
                action.payload
            );
            return { ...state, userPreferenceMap: state.userPreferenceMap };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export default userPreference;
