import { Backend } from './backend';

class TaskComposerApi {
    constructor() {
        this.path = '/task-composer';
    }

    async generateUTRs(dateInWeek, userId) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/generate/${userId}/${dateInWeek}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { TaskComposerApi };
