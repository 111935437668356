import FaqApi from '../../../services/faq-service';
import { success, error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

export const PRIVATE_FAQ_LOADING = 'PRIVATE_FAQ_LOADING';
export const PRIVATE_FAQ_LOADING_DONE = 'PRIVATE_FAQ_LOADING_DONE';
export const PRIVATE_FAQ_INIT = 'PRIVATE_FAQ_INIT';
export const PRIVATE_FAQ_GET = 'PRIVATE_FAQ_GET';

const faqApi = new FaqApi();

export const getPrivateFaqHtml = () => {
    return async (dispatch) => {
        dispatch({ type: PRIVATE_FAQ_LOADING });

        try {
            const payload = await faqApi.getPrivateFaq();
            dispatch({ type: PRIVATE_FAQ_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PRIVATE_FAQ_LOADING_DONE });
    };
};

export const updatePrivateFaqHtml = (html) => {
    return async (dispatch) => {
        dispatch({ type: PRIVATE_FAQ_LOADING });

        try {
            await faqApi.updatePrivateFaq(html);

            dispatch(success({ msg: 'The FAQ was successfully updated.' }));
            dispatch({ type: PRIVATE_FAQ_GET, payload: html });
        } catch (err) {
            console.error(err);
            dispatch(
                error({ msg: `Could not save FAQ: ${getErrorMessage(err)}` })
            );
        }

        dispatch({ type: PRIVATE_FAQ_LOADING_DONE });
    };
};

export const initPrivateFaq = () => ({
    type: PRIVATE_FAQ_INIT,
});
