import { Backend } from './backend';
import type { RetailerGroup } from '../DTOs/retailer-group';
import type { SearchSpecificationModel } from '../interface/search-specification-model';

class RetailerGroupService {
    constructor() {
        this.path = '/planograms/retailer-group';
    }

    update = async (id: number, retailerGroup: RetailerGroup) => {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${id}`,
                body: retailerGroup,
            });
        } catch (e) {
            throw e;
        }
    };

    create = async (retailerGroup: RetailerGroup) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}`,
                body: retailerGroup,
            });
        } catch (e) {
            throw e;
        }
    };
    getBySearchSpecificationModel = async (ssf: SearchSpecificationModel) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: ssf,
            });
        } catch (e) {
            throw e;
        }
    };
    getById = async (id: number) => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    };

    get = async () => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/`,
            });
        } catch (e) {
            throw e;
        }
    };
}

export default RetailerGroupService;
