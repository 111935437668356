import { TiersApi } from '../../../services/tiers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error, clearNotification } from '../notifications';

export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const GET_SUCCESS = 'GET_SUCCESS';
export const UPDATE_TIERS_INIT = 'UPDATE_TIERS_INIT';
export const UPDATE_TIERS_LOADING = 'UPDATE_TIERS_LOADING';
export const UPDATE_TIERS_LOADING_DONE = 'UPDATE_TIERS_LOADING_DONE';

const tiersApi = new TiersApi();

const updateTierFailure = (e) => {
    return async (dispatch) => {
        dispatch(error({ msg: getErrorMessage(e), target: 'UpdateTier' }));
    };
};

export const updateTiers = (tier) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: UPDATE_TIERS_LOADING });

            await tiersApi.updateTier(tier);
            dispatch({
                type: UPDATE_SUCCESS,
            });
        } catch (err) {
            console.error(err);
            dispatch(updateTierFailure(err));
            success = false;
        } finally {
            dispatch({ type: UPDATE_TIERS_LOADING_DONE });
        }

        return success;
    };
};

export const getTierById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_TIERS_INIT });
            dispatch({ type: UPDATE_TIERS_LOADING });

            const data = await tiersApi.getTierById(id);
            dispatch({
                type: GET_SUCCESS,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(updateTierFailure(err));
        } finally {
            dispatch({ type: UPDATE_TIERS_LOADING_DONE });
        }
    };
};

export const initUpdateTiers = () => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_TIERS_INIT });
        dispatch(clearNotification());
    };
};
