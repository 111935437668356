import { ReportsApi } from '../../../services/reports-service';
import { formatDate } from '../../../constants/utils';
import config from '../../../constants/config';

export const INVOICE_RETAILER_WIDGET_COMMISSION_LOADING =
    'INVOICE_RETAILER_WIDGET_COMMISSION_LOADING';
export const INVOICE_RETAILER_WIDGET_COMMISSION =
    'INVOICE_RETAILER_WIDGET_COMMISSION';
export const INVOICE_RETAILER_WIDGET_COMMISSION_ERROR =
    'INVOICE_RETAILER_WIDGET_COMMISSION_ERROR';

const reportsApi = new ReportsApi();

export const fetchYtdTotalCommissions = (
    retailerReference,
    startOfThisMonth,
    startOfLastMonth,
    endOfLastMonth,
    isWidget
) => {
    return async (dispatch, getState) => {
        try {
            const organization = getState().organization;
            const date = organization.fiscalDate;
            const fiscalDate = formatDate(date, config.DATE_FORMAT_FOR_SERVER);

            dispatch({
                type: INVOICE_RETAILER_WIDGET_COMMISSION_LOADING,
                loading: true,
            });

            const commissions = await reportsApi.getYtdTotalCommissions({
                retailer: retailerReference,
                fiscal: fiscalDate,
                thisMonth: startOfThisMonth,
                lastMonth: startOfLastMonth,
                lastMonthEnd: endOfLastMonth,
                isWidget: isWidget,
            });
            dispatch({
                type: INVOICE_RETAILER_WIDGET_COMMISSION,
                commissions,
            });
        } catch (e) {
            dispatch({
                type: INVOICE_RETAILER_WIDGET_COMMISSION_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
