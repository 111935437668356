import { GET_USER_ROLES, GET_USER_ROLES_SUCCESS } from '../actions';

const INITIAL_STATE = {
    roleData: [],
};

const userRoles = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_ROLES:
            return { ...state, loading: false };
        case GET_USER_ROLES_SUCCESS:
            return { ...state, roleData: action.data, loading: false };
        default:
            return state;
    }
};
export default userRoles;
