import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Divider, Popup } from 'semantic-ui-react';
import './../style.css';

class PendingReturnItem extends Component {
    state = {
        showIndividualTickets: false,
    };

    handleTicketDetailsArrowClicked = () => {
        this.setShowIndividualTickets(!this.state.showIndividualTickets);
    };

    setShowIndividualTickets = (shouldShowTicketDetails) => {
        this.setState({ showIndividualTickets: shouldShowTicketDetails });
    };

    render() {
        const { returnItem, bookSerial, onRemoveItemClicked, readOnly } =
            this.props;
        const itemType = returnItem.itemType;
        const isPOSItem = itemType === 'ps';
        const isTicket = itemType === 'ticket';
        const isBook = itemType === 'book';

        let sortedReturnTickets = _.sortBy(returnItem.tickets, [
            'ticketNumber',
        ]);

        let ticketRanges = [];
        if (isTicket) {
            ticketRanges = _.transform(
                sortedReturnTickets,
                (accumulator, ticket) => {
                    let existingTicketRangeArrayKey;
                    let existingTicketRangeArray = _.find(
                        accumulator,
                        (accArray, accKey) => {
                            let firstItem = _.first(accArray);
                            if (
                                parseInt(firstItem.ticketNumber, 10) - 1 ===
                                parseInt(ticket.ticketNumber, 10)
                            ) {
                                existingTicketRangeArrayKey = accKey;
                                return firstItem;
                            }
                            let lastItem = _.last(accArray);
                            if (
                                parseInt(lastItem.ticketNumber, 10) + 1 ===
                                parseInt(ticket.ticketNumber, 10)
                            ) {
                                existingTicketRangeArrayKey = accKey;
                                return lastItem;
                            }
                        }
                    );
                    if (existingTicketRangeArray) {
                        existingTicketRangeArray.push(ticket);
                        let sortedArray = _.sortBy(existingTicketRangeArray, [
                            'ticketNumber',
                        ]);
                        accumulator[existingTicketRangeArrayKey] = sortedArray;
                    } else {
                        accumulator.push([{ ...ticket }]);
                    }
                    return accumulator;
                },
                []
            );
        }

        let ticketLabels = [];

        _.forEach(ticketRanges, (ticketRange) => {
            let rangeStart = _.first(ticketRange);
            let rangeEnd = _.last(ticketRange);
            if (
                parseInt(rangeStart.ticketNumber, 10) ===
                parseInt(rangeEnd.ticketNumber, 10)
            ) {
                ticketLabels.push(
                    <TicketLabel
                        key={rangeStart.ticketId}
                        startTicketNumber={rangeStart.ticketNumber}
                    />
                );
            } else {
                ticketLabels.push(
                    <TicketLabel
                        key={rangeStart.ticketId}
                        startTicketNumber={rangeStart.ticketNumber}
                        endTicketNumber={rangeEnd.ticketNumber}
                    />
                );
            }
        });

        let individualTickets = [];
        _.forEach(sortedReturnTickets, (returnTicket) => {
            individualTickets.push(
                <div
                    className="return-flex-row space-between individual-ticket-margin"
                    key={returnTicket.ticketId}
                >
                    <div>
                        <span className="icon-Ticket" />
                        <span className="bg-translucent-black container book-serial">
                            {bookSerial}
                        </span>
                    </div>
                    <span
                        data-qa="ticket-number"
                        className="bg-translucent-black container ticket-number-space"
                    >
                        {returnTicket.ticketNumber}
                    </span>
                    <span
                        className={classNames(
                            'close-icon-group-item icon-Close',
                            {
                                invisible: readOnly,
                            }
                        )}
                        onClick={() =>
                            onRemoveItemClicked(
                                'ticket',
                                {
                                    bookSerial,
                                    ticketId: returnTicket.ticketId,
                                },
                                returnItem
                            )
                        }
                    />
                </div>
            );
        });

        return (
            <div className=" m-3 bg-white p-3">
                <div className="d-flex justify-content-space-between">
                    <div>
                        <span
                            className={classNames(
                                { 'icon-Book': isBook },
                                {
                                    'icon-Ticket': isTicket || isPOSItem,
                                }
                            )}
                        />
                        {returnItem.errorMessage ? (
                            <Popup
                                inverted
                                position="top left"
                                wide="very"
                                size="tiny"
                                trigger={
                                    <span className="bg-translucent-black container book-serial warning">
                                        {bookSerial}
                                    </span>
                                }
                                content={returnItem.errorMessage}
                            />
                        ) : (
                            <span className="bg-translucent-black container book-serial">
                                {isPOSItem ? returnItem.itemCode : bookSerial}
                            </span>
                        )}
                        {isPOSItem && (
                            <div className="align-self-end pending-return-item-pos-item-count">
                                <span>Total: {returnItem.itemQuantity}</span>
                            </div>
                        )}
                        {returnItem.message && (
                            <Popup
                                inverted
                                position="top left"
                                wide="very"
                                size="tiny"
                                trigger={
                                    <span className="icon-Info red-text" />
                                }
                                content={returnItem.message}
                            />
                        )}
                    </div>
                    <span
                        className={classNames(
                            'close-icon-group icon-Close close-icon',
                            {
                                invisible: readOnly,
                            }
                        )}
                        onClick={() =>
                            onRemoveItemClicked(
                                'book',
                                { bookSerial },
                                returnItem
                            )
                        }
                    />
                </div>
                <div className="flex row space-between">
                    <div className="flex row">
                        <div className="flex-column text-left ticket-labels">
                            {ticketLabels}
                        </div>
                        {isTicket && (
                            <div className=" ticket-total align-self-end ticket-labels">
                                <span>Total: {_.size(returnItem.tickets)}</span>
                            </div>
                        )}
                    </div>
                    <div>
                        {isTicket && (
                            <span
                                className={classNames(
                                    'icon-Arrow-Down align-self-end show-ticket-details-arrow',
                                    {
                                        clicked:
                                            this.state.showIndividualTickets,
                                    }
                                )}
                                onClick={this.handleTicketDetailsArrowClicked}
                            />
                        )}
                    </div>
                </div>
                {this.state.showIndividualTickets && (
                    <div className="flex column ticket-details">
                        <Divider />
                        {individualTickets}
                    </div>
                )}
            </div>
        );
    }
}

PendingReturnItem.propTypes = {
    bookSerial: PropTypes.string.isRequired,
    onRemoveItemClicked: PropTypes.func.isRequired,
    returnItem: PropTypes.object.isRequired,
};

export default PendingReturnItem;

const TicketLabel = ({ startTicketNumber, endTicketNumber, ...props }) => {
    return (
        <div className="ticket-label" {...props}>
            Ticket{endTicketNumber ? 's' : ''}
            <span> {startTicketNumber} </span> {endTicketNumber && 'to'}{' '}
            {endTicketNumber && <span> {endTicketNumber} </span>}
        </div>
    );
};
