import { GamesApi } from '../../../services/games-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import _, { differenceBy, filter, flatten, map } from 'lodash';
import { RetailersApi } from '../../../services/retailers-service';

export const GAME_ITEM_LIST = 'GAME_ITEM_LIST';
export const GAME_ITEM_LIST_LOADING = 'GAME_ITEM_LIST_LOADING';
export const GAME_ITEM_LIST_LOADING_DONE = 'GAME_ITEM_LIST_LOADING_DONE';
export const GAME_ITEM_LIST_SORTED = 'GAME_ITEM_LIST_SORTED';
const gameItemsApi = new GamesApi();
const retailersApi = new RetailersApi();
export const getAllOrderableGameItems = (retailerType, jurisdiction) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: GAME_ITEM_LIST_LOADING });
            const state = getState();

            const isPostItem =
                state.frontendConfig.configs.find(
                    (co) => co.name === 'enable-pos-item',
                ).value === 'true';
            const list = await gameItemsApi.getAllOrderableGameItems(
                retailerType,
                jurisdiction,
                isPostItem,
            );

            dispatch({
                type: GAME_ITEM_LIST,
                list,
            });
        } catch (err) {
            dispatch(error({ msg: getErrorMessage(err) }));
            console.error(err);
        } finally {
            dispatch({ type: GAME_ITEM_LIST_LOADING_DONE });
        }
    };
};
export const getAllAdminGames = (isInstantTicket) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const isPostItem =
                state.frontendConfig.configs.find(
                    (co) => co.name === 'enable-pos-item',
                ).value === 'true';

            dispatch({ type: GAME_ITEM_LIST_LOADING });

            const games = await gameItemsApi.getAllActiveLottoTickets(
                !isInstantTicket,
                isPostItem,
            );

            if (isInstantTicket) {
                dispatch({
                    type: GAME_ITEM_LIST,
                    list: games,
                });
            } else {
                const retailerTypes = await retailersApi.getRetailerTypes();

                const gamesWithNoRetailerTypes = filter(
                    games,
                    (game) => !game.retailerType,
                );
                const fixedGames = flatten(
                    map(gamesWithNoRetailerTypes, (game) =>
                        map(retailerTypes, (retailerType) => ({
                            ...game,
                            retailerType: retailerType.name,
                        })),
                    ),
                );

                const gamesWithRetailerTypes = differenceBy(
                    games,
                    gamesWithNoRetailerTypes,
                    'id',
                );

                dispatch({
                    type: GAME_ITEM_LIST,
                    list: [...gamesWithRetailerTypes, ...fixedGames],
                });
            }
        } catch (err) {
            dispatch(error({ msg: getErrorMessage(err) }));
            console.error(err);
        } finally {
            dispatch({ type: GAME_ITEM_LIST_LOADING_DONE });
        }
    };
};
export const setFilteredGames = (themes, searchText) => {
    return async (dispatch, getState) => {
        const games = getState().gameItems.content;

        let selectedGames = [...games];
        if (themes.length !== 0) {
            selectedGames = _.filter(
                selectedGames,
                (game) => themes.indexOf(game.themeName) !== -1,
            );
        }

        if (searchText && searchText.length > 0) {
            selectedGames = _.filter(
                selectedGames,
                (game) =>
                    game.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) ||
                    game.reference
                        .toLowerCase()
                        .includes(searchText.toLowerCase()),
            );
        }
        dispatch({ type: GAME_ITEM_LIST_SORTED, payload: selectedGames });
    };
};
