import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Transition, Popup, Menu, Icon, Input } from 'semantic-ui-react';
import _ from 'lodash';

import {
    filterToggle,
    updateSearchValue,
    initializeFilter,
    removeAllSelectedItems,
    addSelectedItem,
} from '../actions';
import FilterContent from './FilterContent';
import DateFilterContent from './DateFilterContent';
import Badge from '../../Badge';
import config from '../../../constants/config';
import DateRangePicker from '../../../components/DateRangePicker';
import classnames from 'classnames';
import { formatToDisplayDate } from '../../../constants/utils';

const animationLen = config.ANIMATION_LENGTH;

class FilterItem extends Component {
    state = {
        toggle: false,
        doBadgeAnimation: true, //when this value updates will do the animation for the badge
        isDateRangeCalenderOpen: false,
    };

    componentWillReceiveProps(nextProps) {
        const nextSelected = nextProps.selectedItems[nextProps.name];
        const currSelected = this.props.selectedItems[this.props.name];

        if (
            (nextSelected && !currSelected) ||
            (nextSelected &&
                currSelected &&
                nextSelected.length !== currSelected.length)
        ) {
            this.setState({ ...this.state, doBadgeAnimation: true });
        }
    }

    handleDateRangeSelectItem = (e, range) => {
        const value = [range.from, range.to];
        this.handleSelectDateRangeItem(e, { value });
    };

    removeSelectedItem = () => {
        const { name, removeAllSelectedItems } = this.props;
        removeAllSelectedItems(name);
    };

    handleSelectDateRangeItem = (e, data) => {
        const { name, addSelectedItem, removeAllSelectedItems, onChange } =
            this.props;

        removeAllSelectedItems(name);
        const { value } = data;
        if (value) {
            _.isArray(value)
                ? _.forEach(value, (val) => addSelectedItem(name, val))
                : addSelectedItem(name, value);
        }

        if (onChange) {
            onChange(e, data);
        }
    };

    handleSelectItem = (isShow) => {
        this.setState({ toggle: isShow });
        this.props.updateSearchValue(this.props.name, '');
    };

    removeBadgeAnimation = () => {
        this.setState({ ...this.state, doBadgeAnimation: false });
    };

    openRangeCalendar = () => {
        this.setState({ isDateRangeCalenderOpen: true });
        this.handleSelectItem(true);
    };

    closeRangeCalendar = () =>
        this.setState({ isDateRangeCalenderOpen: false });

    getDataFromSelected = () => {
        const { selectedItems, name, isDateRange } = this.props;

        if (isDateRange) {
            if (
                selectedItems[name]?.length === 2 &&
                this.state.isDateRangeCalenderOpen &&
                this.state.values !== selectedItems[name]
            ) {
                this.setState({ values: selectedItems[name] });
                this.closeRangeCalendar();
                this.handleSelectItem(false);
            }
            return selectedItems[this.props.name] ? selectedItems[name] : null;
        }
    };

    render() {
        const {
            name,
            client,
            toggleAll,
            items,
            multiple,
            disabled,
            maxSelection,
            searchable,
            isDate,
            selectedItems,
            filterItems,
            maxDate,
            minDate,
            isDateRange,
            isTextSearch,
            autoComplete,
            key,
            clearable,
        } = this.props;
        const selectedValues = this.getDataFromSelected();

        const { doBadgeAnimation, toggle, isDateRangeCalenderOpen } =
            this.state;

        let badgeCount = 0;
        let showAllLabel = false;
        let dateFilterLabel = '';

        const totalElements =
            filterItems && filterItems[name]
                ? filterItems[name].totalElements
                : 0;

        if (
            selectedItems[name] &&
            selectedItems[name].length === totalElements &&
            multiple
        ) {
            showAllLabel = true;
        } else {
            if (selectedItems[name] && selectedItems[name].length > 0) {
                badgeCount = selectedItems[name].length;
                showAllLabel = false;
            }
        }

        let labelText = `${this.props.label} ${
            showAllLabel ? ' All' : badgeCount <= 0 ? '' : ' ...'
        }`;

        if (isDate) {
            const details = selectedItems[name];
            let label = showAllLabel ? ' All' : '';
            if (
                details &&
                details.length > 0 &&
                details[0] &&
                details[0] !== 'All' &&
                details[0] !== '*'
            ) {
                label = formatToDisplayDate(details[0]);
                dateFilterLabel = label;
            }
            labelText = `${this.props.label} ${label}`;
        }

        const showBadge = badgeCount > 0 && !showAllLabel && !isDate;

        let content;
        if (isDate) {
            content = (
                <DateFilterContent
                    name={name}
                    multiple={multiple || false}
                    items={items}
                    disabled={disabled || false}
                    maxDate={maxDate}
                    minDate={minDate}
                    containerStyle={{ width: '100%', height: '100%' }}
                    onValuesChange={() => {
                        this.handleSelectItem(false);
                    }}
                />
            );
        } else if (isDateRange) {
            content = (
                <DateRangePicker
                    name={name}
                    value={selectedValues} //array of date strings size 2, [from,to]
                    handleChange={this.handleDateRangeSelectItem}
                    isCalendarOpen={isDateRangeCalenderOpen}
                    onCalendarOpen={this.openRangeCalendar}
                    onCalendarClose={this.closeRangeCalendar}
                    minDate={minDate}
                    autoComplete={autoComplete}
                    removeItem={this.removeSelectedItem}
                    clearable={clearable}
                />
            );
        } else {
            content = (
                <FilterContent
                    client={client}
                    toggleAll={toggleAll}
                    name={name}
                    multiple={multiple || false}
                    items={items}
                    disabled={disabled || false}
                    searchable={searchable}
                    containerStyle={{ width: '100%', height: 190 }}
                    maxSelection={maxSelection}
                />
            );
        }

        if (isTextSearch) {
            let value = '';
            if (selectedItems[name] && selectedItems[name].length > 0) {
                value = selectedItems[name][0];
            }
            return (
                <Menu.Item
                    name={name}
                    active={toggle}
                    className={classnames({ 'disabled-filterItem': disabled })}
                >
                    <Input
                        name={name}
                        placeholder={labelText}
                        value={value}
                        onChange={(e) => {
                            this.props.removeAllSelectedItems(name);
                            this.props.addSelectedItem(
                                name,
                                e.currentTarget.value
                            );
                        }}
                    />
                </Menu.Item>
            );
        }
        return disabled ? (
            <Menu.Item
                key={key}
                name={name}
                active={toggle}
                className="disabled-filterItem"
            >
                <div className="filter-item">
                    <span>{labelText}</span>
                    <Icon
                        name={isDate ? 'calendar outline' : 'chevron down'}
                        style={{ margin: 0, paddingLeft: 5 }}
                    />
                </div>
            </Menu.Item>
        ) : (
            <Popup
                key={key}
                on="click"
                position="bottom left"
                basic
                className={isDate ? 'filterPopupOther' : 'filterPopup'}
                onClose={() => this.handleSelectItem(isDateRangeCalenderOpen)}
                onOpen={() => this.handleSelectItem(true)}
                open={toggle}
                trigger={
                    <Menu.Item
                        name={name}
                        active={this.state.toggle}
                        className="filterItem"
                    >
                        <div className="filter-item">
                            <span>{labelText}</span>
                            <Icon
                                name={
                                    isDate ? 'calendar outline' : 'chevron down'
                                }
                                style={{ margin: 0, paddingLeft: 5 }}
                            />
                            {isDate && clearable && dateFilterLabel && (
                                <Icon
                                    name="icon close"
                                    style={{ margin: 0, paddingLeft: 10 }}
                                    onClick={this.removeSelectedItem}
                                />
                            )}
                        </div>
                        {showBadge && (
                            <Transition
                                visible={doBadgeAnimation}
                                transitionOnMount
                                animation="bounce"
                                duration={animationLen}
                                onComplete={this.removeBadgeAnimation}
                            >
                                <Badge
                                    value={badgeCount}
                                    isSelected={this.state.toggle}
                                />
                            </Transition>
                        )}
                    </Menu.Item>
                }
            >
                {content}
            </Popup>
        );
    }
}

export default connect(
    ({ tableFilter, filters }) => {
        const { name, filterCounts, selectedItems, clearAll } = tableFilter;
        const { filterItems } = filters;
        return {
            filterName: name,
            filterCounts,
            selectedItems,
            filterItems,
            clearAll,
        };
    },
    (dispatch) => ({
        toggleFilter: (name, toggle) => dispatch(filterToggle(name, toggle)),
        updateSearchValue: (name, value) =>
            dispatch(updateSearchValue(name, value)),
        initializeFilter: (args) => dispatch(initializeFilter(args)),
        removeAllSelectedItems: (filterName) =>
            dispatch(removeAllSelectedItems(filterName)),
        addSelectedItem: (name, value) =>
            dispatch(addSelectedItem(name, value)),
    })
)(FilterItem);
