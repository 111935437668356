// eslint-disable-next-line
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

const Secured = ({ hasRole, hasAnyRole, children, userInfo }) => {
    if (!userInfo || (!hasRole && !hasAnyRole)) {
        return null;
    }

    const roles = userInfo.authorities;

    if (hasRole) {
        if (
            typeof hasRole === 'string' &&
            (hasRole === '*' || _.find(roles, (r) => r === hasRole))
        ) {
            return children;
        } else if (_.isArray(hasRole)) {
            if (
                _.some(hasRole, (role) => {
                    return _.find(roles, (r) => r === role);
                })
            ) {
                return children;
            }
        }

        return null;
    }

    if (
        hasAnyRole &&
        _.some(hasAnyRole, (role) => {
            return _.find(roles, (r) => r === role);
        })
    ) {
        return children;
    }

    return null;
};

export default connect(({ auth }) => {
    return {
        userInfo: auth.info,
    };
})(Secured);
