import {
    TOTAL_PURCHASE_YTD_INIT,
    TOTAL_PURCHASE_YTD_LOADING,
    TOTAL_PURCHASE_YTD,
    TOTAL_PURCHASE_YTD_ERROR,
} from '../../../../actions/retailerDashboard/widgets/totalPurchaseYTD/totalpurchaseYTD';

const INITIAL_STATE = {
    totalPurchaseYTDs: undefined,
    loading: false,
    errorResponse: null,
};

const totalPurchaseYTD = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOTAL_PURCHASE_YTD_INIT:
            return { ...INITIAL_STATE };
        case TOTAL_PURCHASE_YTD_LOADING:
            return { ...state, loading: true };
        case TOTAL_PURCHASE_YTD:
            return {
                ...state,
                totalPurchaseYTDs: action.totalPurchaseYTDs,
                loading: false,
                errorResponse: null,
            };
        case TOTAL_PURCHASE_YTD_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default totalPurchaseYTD;
