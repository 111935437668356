import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Dimmer,
    Form,
    Grid,
    Loader,
    Modal,
    Radio,
    Table,
} from 'semantic-ui-react';
import OkCancelModal from '../../components/OkCancelModal';
import { PNotificationModal } from '../../components/notifications/notificationModal';
import ErrorSound from '../../assets/audios/Error.mp3';
import ReturnBookScanForm from './components/ReturnBookScanForm';
import SingleTicketScanForm from './components/SingleTicketScanForm';
import TicketRangeScanForm from './components/TicketRangeScanForm';
import { ReturnsApi } from '../../services/returns-service';
import { success } from '../../redux/actions/notifications';
import PendingReturnItemsList from './components/PendingReturnItemsList';
import ReturnReasonSelect from './components/ReturnReasonSelect';
import _ from 'lodash';
import './style.css';
import {
    displayCreateReturnModal,
    validateBook,
    validateTicketRange,
    validatePosItem,
    checkTicketDuplicate,
    checkBookDuplicate,
    addBookToPendingReturn,
    addTicketsToPendingReturns,
    addPosItemToPendingReturn,
    removeBookFromPendingReturn,
    removeTicketFromPendingReturn,
    removePosItemFromReturn,
    savePendingReturn,
    resetCreateReturnScreen,
    isBookSplit,
    checkIfOnlyOnePackIsAllowed,
} from '../../redux/actions/returns/createReturn';

import {
    NOTIFICATION_BUTTON_ALIGNMENT,
    NOTIFICATION_SIZE,
    NOTIFICATION_TYPE,
} from '../../components/notifications';
import ReturnPOSItemScanForm from './components/ReturnPosItemScanForm';
import config from '../../constants/config';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const AUDIO_ICON_Style = {
    verticalAlign: 'sub',
    marginLeft: '10px',
};

const SCAN_TYPE = {
    single: 'single',
    range: 'range',
};

const RETURN_TYPE = {
    books: 'packs',
    tickets: 'tickets',
    posItems: 'ps',
};

const returnsService = new ReturnsApi();

class CreateReturn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnType: RETURN_TYPE.books,
            validationError: null,
            validationErrorHeader: null,
            showCancelModal: false,
            showBookSplitConfirmation: false,
            scanType: SCAN_TYPE.single,
            returnReasonCode: null,
        };
    }

    setReturnType = (returnType) => {
        this.setState({ returnType: returnType });
    };

    handleReturnTypeChange = (e, data) => {
        this.setReturnType(data.value);
    };

    handleScanTypeChange = (e, data) => {
        this.setScanType(data.value);
    };

    clearValidationError = () => {
        this.setState({ validationError: null, validationErrorHeader: null });
    };

    setScanType = (scanType) => {
        this.setState({ scanType: scanType });
    };

    confirmReturnModalClosed = () => {
        const { pendingReturnItems } = this.props;
        if (!!_.size(pendingReturnItems)) {
            this.setState({ showCancelModal: true });
        } else {
            this.handleReturnModalClosed();
        }
    };

    handleReturnModalClosed = () => {
        this.props.history.push(config.ROUTE_URLS.RETAILER_RETURN_LIST);
        this.closeCancelModal();
        this.setState({
            scanType: SCAN_TYPE.single,
            returnType: RETURN_TYPE.books,
        });
    };

    handleReturnModalUnmount = () => {
        this.props.resetCreateReturnScreen();
    };

    closeCancelModal = () => {
        this.setState({ showCancelModal: false });
    };

    closeSplitBookConfirmationModal = () => {
        this.setState({ showBookSplitConfirmation: false });
    };

    openSplitBookConfirmationModal = () => {
        this.setState({ showBookSplitConfirmation: true });
    };

    handleSplitBookConfirmation = () => {
        const { startBarcode, endBarcode, bookSerial } = this.state;
        this.addTicketsToPendingReturns(startBarcode, endBarcode, bookSerial);
        this.closeSplitBookConfirmationModal();
    };

    handleBookSerialScan = async (values) => {
        if (await this.props.checkIfOnlyOnePackIsAllowed()) {
            this.setValidationError('Only one pack can be added to a Return');
            return;
        }

        let result = await this.props.validateBook(
            values.returnBookSerial,
            this.props.activeRetailer?.userRetailerId?.retailerReference
        );

        if (result.status === 'SUCCESS' && result.payload.status === 'VALID') {
            let existsInList = await this.props.checkBookDuplicate(
                result.payload.serial
            );

            if (existsInList) {
                this.setState({
                    validationError: 'Pack is already present in the list',
                    validationErrorHeader: `Pack No:${result.payload.serial}`,
                });
                return;
            }
            this.props.addBookToPendingReturn(
                result.payload.serial,
                result.payload.id
            );
        } else {
            this.setState({
                validationError:
                    result.status === 'ERROR'
                        ? result.errorMessage
                        : result?.payload
                        ? result.payload.message
                        : `Pack with serial ${values.returnBookSerial} could not be found`,
                validationErrorHeader:
                    result.status === 'ERROR'
                        ? null
                        : result?.payload
                        ? `${values.returnBookSerial} - ${result?.payload?.itemDisplayName}`
                        : '',
            });
        }
    };

    getBarcodeOrSerialDetails = async (barcodeOrSerial) => {
        try {
            return await returnsService.getBarcodeOrSerialDetails(
                barcodeOrSerial
            );
        } catch (e) {
            this.setValidationError(
                'Invalid barcode or serial number',
                barcodeOrSerial
            );
            return null;
        }
    };

    handleTicketScan = (values) => {
        if (this.state.scanType === SCAN_TYPE.single) {
            this.processTicketScan(
                values.returnTicketSerial,
                values.returnTicketSerial
            );
        } else {
            this.processTicketScan(
                values.returnTicketFrom,
                values.returnTicketTo
            );
        }
    };

    handlePosItemScan = async (values) => {
        let result = await this.props.validatePosItem(
            values.returnItemCode,
            this.props.activeRetailer?.userRetailerId?.retailerReference
        );

        if (result.status === 'SUCCESS' && result.payload.status === 'VALID') {
            this.props.addPosItemToPendingReturn(
                values.returnItemCode,
                values.returnItemQuantity
            );
        } else {
            if (result.status === 'SUCCESS') {
                this.setState({
                    validationError:
                        result.status === 'FAILED'
                            ? result.errorMessage
                            : result.payload.message,
                    validationErrorHeader:
                        result.status === 'FAILED'
                            ? null
                            : `${result.payload.itemCode}`,
                });
            } else {
                this.setState({
                    validationError: result.errorMessage,
                });
            }
        }
    };

    handleSelectReason = (value) => {
        this.setState({ returnReasonCode: value });
    };

    processTicketScan = async (startBarcode, endBarcode) => {
        let barcodeDetails = await this.getBarcodeOrSerialDetails(startBarcode);

        if (barcodeDetails == null) {
            return;
        }

        if (this.state.scanType === SCAN_TYPE.range) {
            let endBarcodeDetails = await this.getBarcodeOrSerialDetails(
                endBarcode
            );
            if (endBarcodeDetails == null) {
                return;
            }
        }

        if (barcodeDetails.ticket === undefined) {
            this.setValidationError(
                'The scanned barcode is not a valid ticket barcode.'
            );
            return;
        }

        //check if the book has already been scanned as a complete book.
        let existsInList = await this.props.checkBookDuplicate(
            barcodeDetails.bookSerial
        );
        if (existsInList) {
            this.setState({
                validationError: 'Pack is already present in the list.',
            });
            return;
        }

        // Check if the book is split
        let isBookSplit = await this.isBookSplit(
            this.getTicketSerialNumber(barcodeDetails)
        );
        if (isBookSplit === null) {
            return;
        }

        if (isBookSplit) {
            await this.addTicketsToPendingReturns(
                startBarcode,
                endBarcode,
                barcodeDetails.bookSerial
            );
        } else {
            this.setState({
                startBarcode,
                endBarcode,
                bookSerial: barcodeDetails.bookSerial,
            });
            this.openSplitBookConfirmationModal();
        }
    };

    isBookSplit = async (bookSerial) => {
        let isBookSplit = await this.props.isBookSplit(bookSerial);
        if (isBookSplit.status === 'SUCCESS') {
            return isBookSplit.result;
        } else {
            this.setValidationError(
                isBookSplit.errorMessage,
                `Pack Serial: ${bookSerial}`
            );
            return null;
        }
    };

    getTicketSerialNumber = (barcodeOrSerial) => {
        const path = this.isTicketBarcode(barcodeOrSerial)
            ? 'ticketSerialNumber.serialNumber'
            : 'serialNumber';

        return _.get(barcodeOrSerial, path, null);
    };

    isTicketBarcode = (barcodeOrSerial) =>
        !!barcodeOrSerial.ticketSerialNumber &&
        _.isObject(barcodeOrSerial.ticketSerialNumber);

    addTicketsToPendingReturns = async (
        startBarcode,
        endBarcode,
        bookSerial
    ) => {
        let validatedTickets = await this.validateTickets(
            startBarcode,
            endBarcode,
            this.props.activeRetailer?.userRetailerId?.retailerReference
        );
        if (!validatedTickets) {
            return null;
        } else {
            if (_.some(validatedTickets, ['status', 'INVALID'])) {
                let invalidTicketsMessages = _.reduce(
                    _.filter(validatedTickets, ['status', 'INVALID']),
                    (errorMessageArray, invalidTicket) => {
                        errorMessageArray.push(
                            `Ticket No: ${invalidTicket.ticketNumber} -> ${invalidTicket.message}`
                        );
                        return errorMessageArray;
                    },
                    []
                );
                this.setValidationError(
                    'One or more ticket validations failed. \n' +
                        _.join(invalidTicketsMessages, '\n')
                );
                return null;
            } else {
                let existsInList = await this.props.checkTicketDuplicate(
                    bookSerial,
                    validatedTickets
                );
                if (existsInList) {
                    this.setState({
                        validationError: `One or more tickets from the same pack are already present in the list.`,
                    });
                    return null;
                }
                await this.props.addTicketsToPendingReturns(
                    bookSerial,
                    validatedTickets
                );
                return true;
            }
        }
    };

    validateTickets = async (startBarcode, endBarcode) => {
        let validatedTicketsResponse = await this.props.validateTicketRange(
            startBarcode,
            endBarcode,
            this.props.activeRetailer?.userRetailerId?.retailerReference
        );
        if (validatedTicketsResponse.status === 'SUCCESS') {
            return validatedTicketsResponse.payload;
        } else {
            this.setValidationError(validatedTicketsResponse.errorMessage);
            return null;
        }
    };

    handleCreateReturn = async () => {
        const { pendingReturnItems, activeRetailer } = this.props;

        const { returnReasonCode } = this.state;
        let tickets = [];
        let books = [];
        let posItems = [];

        _.forEach(pendingReturnItems, (pendingReturnItem) => {
            if (pendingReturnItem.itemType === 'book') {
                books.push(pendingReturnItem.bookSerial);
            } else if (pendingReturnItem.itemType === 'ps') {
                posItems.push({
                    itemCode: pendingReturnItem.itemCode,
                    itemQuantity: pendingReturnItem.itemQuantity,
                });
            } else {
                if (pendingReturnItem.tickets.length > 1) {
                    tickets.push({
                        rangeFirst:
                            pendingReturnItem.bookSerial +
                            pendingReturnItem.tickets[0].ticketNumber.slice(
                                2,
                                5
                            ),
                        rangeLast:
                            pendingReturnItem.bookSerial +
                            pendingReturnItem.tickets[
                                pendingReturnItem.tickets.length - 1
                            ].ticketNumber.slice(2, 5),
                    });
                } else {
                    tickets.push({
                        single:
                            pendingReturnItem.bookSerial +
                            pendingReturnItem.tickets[0].ticketNumber.slice(
                                2,
                                5
                            ),
                    });
                }
            }
        });

        let createReturnResponse = await this.props.savePendingReturn({
            transactionNumber: new Date(),
            retailerCode: activeRetailer?.userRetailerId?.retailerReference,
            books,
            tickets,
            posItems,
            returnReasonCode: returnReasonCode ? returnReasonCode.value : '',
        });

        if (
            createReturnResponse &&
            createReturnResponse.status &&
            createReturnResponse.status === 'SUCCESS'
        ) {
            this.handleReturnModalClosed();
            this.props.success({
                msg: 'Return(s) created successfully.',
            });
        } else {
            this.setValidationError(
                createReturnResponse.errorMessage ?? createReturnResponse
            );
        }
    };

    setValidationError = (validationError, validationErrorHeader) => {
        this.setState({
            validationError: validationError,
            validationErrorHeader: validationErrorHeader,
        });
    };

    render() {
        const {
            pendingReturnItems,
            loading,
            activeRetailer,
            enableTicketReturn,
            enablePosItemReturn,
        } = this.props;

        const {
            validationError,
            validationErrorHeader,
            returnType,
            showCancelModal,
            showBookSplitConfirmation,
            returnReasonCode,
        } = this.state;
        let totalBookCount = 0;
        let totalTicketCount = 0;
        let totalPosItemCount = 0;

        _.forEach(pendingReturnItems, (pendingReturnItem) => {
            if (pendingReturnItem.itemType === 'ticket') {
                totalTicketCount += _.size(pendingReturnItem.tickets);
            } else if (pendingReturnItem.itemType === 'ps') {
                totalPosItemCount += ~~pendingReturnItem.itemQuantity;
            } else {
                ++totalBookCount;
            }
        });

        return (
            <Modal
                basic
                size="fullscreen"
                open={true}
                className={classNames('create-return', {
                    'create-return-mobile': isMobile,
                })}
                centered="false"
                onUnmount={this.handleReturnModalUnmount}>
                <Dimmer active={loading} page>
                    <Loader />
                </Dimmer>
                <PNotificationModal
                    notificationIsOpen={!!validationError}
                    notificationType={NOTIFICATION_TYPE.ERROR}
                    titleText="Validation Failed"
                    size={NOTIFICATION_SIZE.SMALL}
                    audioNotification={ErrorSound}
                    notificationHeader={validationErrorHeader}
                    notificationText={validationError}
                    onPrimaryButtonClick={this.clearValidationError}
                    actionButtonAlignment={NOTIFICATION_BUTTON_ALIGNMENT.CENTER}
                    suppressEnterKeySubmit={true}
                    audioNotificationOnEnterKey={true}
                    autoFocusPrimary={true}
                />
                <OkCancelModal
                    isOpen={showCancelModal}
                    header="Cancel Create Return?"
                    okText="Yes"
                    cancelText="No"
                    body="You will lose all the scanned items in the list. Do you want to proceed?"
                    okFunction={this.handleReturnModalClosed}
                    cancelFunction={this.closeCancelModal}
                />
                <OkCancelModal
                    isOpen={showBookSplitConfirmation}
                    header={'Split pack?'}
                    okText="Yes"
                    cancelText="No"
                    body={`The pack is not split yet. Validating the tickets will split the pack. Are you sure you want to continue?`}
                    okFunction={this.handleSplitBookConfirmation}
                    cancelFunction={this.closeSplitBookConfirmationModal}
                />
                <Modal.Content>
                    <Table className="justify-content-center background-transparent">
                        <Table.Row verticalAlign="middle" columns={2}>
                            <Table.Cell>
                                <span className="title-text bold white-text">{`${'Create Pending Return'}`}</span>
                                <span
                                    className="icon-Audio headline-text white-text"
                                    style={AUDIO_ICON_Style}
                                />
                            </Table.Cell>
                            <Table.Cell className="retailer-info-text white-text">
                                <span>
                                    {`Retailer Name: ${activeRetailer?.userRetailerId?.retailerReference} - ${this.props.activeRetailer?.retailerName}`}
                                    <br></br>
                                </span>
                                <span
                                    className="icon-Close close-button-text white-text static-close-button"
                                    onClick={this.confirmReturnModalClosed}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className={'pb-0'}>
                            <Table.Cell width="5">
                                <div className="bg-white container data-column flex-column">
                                    <div className="container padded padding-15">
                                        <Grid.Column>
                                            <div className="return-reason-label">
                                                <Grid.Row>
                                                    Return Reason*
                                                </Grid.Row>
                                            </div>
                                            <Grid.Row>
                                                <ReturnReasonSelect
                                                    onSubmit={
                                                        this.handleSelectReason
                                                    }
                                                />
                                            </Grid.Row>
                                        </Grid.Column>
                                        <div className="subheader-text bold scan-item-text  text-center width-fill-parent">
                                            <span>Enter Items</span>
                                            <div className=" float-right">
                                                <span className="icon-Arrow-Right" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Form className="pending-return-input">
                                            <Form.Field>
                                                <Radio
                                                    label="Packs"
                                                    name="returnType"
                                                    value={RETURN_TYPE.books}
                                                    checked={
                                                        this.state
                                                            .returnType ===
                                                        RETURN_TYPE.books
                                                    }
                                                    onChange={
                                                        this
                                                            .handleReturnTypeChange
                                                    }
                                                />{' '}
                                                &nbsp;
                                                {enableTicketReturn && (
                                                    <Radio
                                                        label="Tickets"
                                                        name="returnType"
                                                        value={
                                                            RETURN_TYPE.tickets
                                                        }
                                                        checked={
                                                            this.state
                                                                .returnType ===
                                                            RETURN_TYPE.tickets
                                                        }
                                                        onChange={
                                                            this
                                                                .handleReturnTypeChange
                                                        }
                                                    />
                                                )}{' '}
                                                &nbsp;
                                                {enablePosItemReturn && (
                                                    <Radio
                                                        label="POS Items"
                                                        name="returnType"
                                                        value={
                                                            RETURN_TYPE.posItems
                                                        }
                                                        checked={
                                                            this.state
                                                                .returnType ===
                                                            RETURN_TYPE.posItems
                                                        }
                                                        onChange={
                                                            this
                                                                .handleReturnTypeChange
                                                        }
                                                    />
                                                )}
                                            </Form.Field>
                                        </Form>
                                    </div>
                                    <div className="container padded bg-translucent-black height-fill-parent">
                                        <div>
                                            {returnType ===
                                                RETURN_TYPE.tickets && (
                                                <Form className="radio-content">
                                                    <Form.Field>
                                                        <Radio
                                                            label="Single"
                                                            name="scanType"
                                                            value={
                                                                SCAN_TYPE.single
                                                            }
                                                            checked={
                                                                this.state
                                                                    .scanType ===
                                                                SCAN_TYPE.single
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleScanTypeChange
                                                            }
                                                        />{' '}
                                                        &nbsp;
                                                        <Radio
                                                            label="Range"
                                                            name="scanType"
                                                            value={
                                                                SCAN_TYPE.range
                                                            }
                                                            checked={
                                                                this.state
                                                                    .scanType ===
                                                                SCAN_TYPE.range
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleScanTypeChange
                                                            }
                                                        />
                                                    </Form.Field>
                                                </Form>
                                            )}
                                            <div className="text-center">
                                                {returnType ===
                                                    RETURN_TYPE.books && (
                                                    <ReturnBookScanForm
                                                        onSubmit={
                                                            this
                                                                .handleBookSerialScan
                                                        }
                                                    />
                                                )}
                                                {returnType ===
                                                    RETURN_TYPE.tickets &&
                                                    (this.state.scanType ===
                                                    SCAN_TYPE.single ? (
                                                        <SingleTicketScanForm
                                                            onSubmit={
                                                                this
                                                                    .handleTicketScan
                                                            }
                                                        />
                                                    ) : (
                                                        <TicketRangeScanForm
                                                            onSubmit={
                                                                this
                                                                    .handleTicketScan
                                                            }
                                                        />
                                                    ))}
                                                {returnType ===
                                                    RETURN_TYPE.posItems && (
                                                    <ReturnPOSItemScanForm
                                                        onSubmit={
                                                            this
                                                                .handlePosItemScan
                                                        }
                                                    />
                                                )}
                                                <p className="scan-item-text min-tablet">
                                                    Enter Pack No.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell width="5">
                                <div className="bg-white container data-column flex-column">
                                    <div className="subheader-text bold container padded padding-10">
                                        <div className="text-center width-fill-parent">
                                            <span>Items</span>
                                        </div>
                                    </div>

                                    <div className="text-center pack-ticket-count">
                                        <span>Packs: {totalBookCount}</span>
                                        &nbsp;
                                        {enableTicketReturn && (
                                            <span>
                                                Tickets: {totalTicketCount}
                                            </span>
                                        )}
                                        &nbsp;
                                        {enablePosItemReturn && (
                                            <span
                                                style={{ paddingLeft: '5px' }}>
                                                POS Item: {totalPosItemCount}
                                            </span>
                                        )}
                                    </div>

                                    <div className="text-center container  bg-translucent-black height-fill-parent scroll-vertical scroll-bars">
                                        <PendingReturnItemsList
                                            returnItems={pendingReturnItems}
                                            removeBookFromReturnItems={
                                                this.props
                                                    .removeBookFromPendingReturn
                                            }
                                            removePosItemFromReturnItems={
                                                this.props
                                                    .removePosItemFromReturn
                                            }
                                            removeTicketFromReturnItems={
                                                this.props
                                                    .removeTicketFromPendingReturn
                                            }
                                            readOnly={false}
                                        />
                                    </div>
                                </div>
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row className={'pt-0'}>
                            <Table.Cell
                                colspan={'2'}
                                verticalAlign="middle"
                                textAlign="center">
                                <div className="data-section">
                                    <Button
                                        content="Create Pending Return"
                                        primary
                                        disabled={
                                            !_.size(pendingReturnItems) ||
                                            !returnReasonCode
                                        }
                                        onClick={this.handleCreateReturn}
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = ({
    createReturn,
    userRetailer: { activeRetailer },
    organization: { configs },
}) => {
    const {
        loading,
        pendingReturnDetails: { pendingReturnItems },
    } = createReturn;
    return {
        loading,
        pendingReturnItems,
        activeRetailer,
        enableTicketReturn:
            _.find(configs, { name: 'enable-ticket-return' })?.value === 'true',
        enablePosItemReturn:
            _.find(configs, { name: 'enable-pos-item-return' })?.value ===
            'true',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayCreateReturnModal: (shouldDisplay) =>
            dispatch(displayCreateReturnModal(shouldDisplay)),
        validateBook: (returnBookSerial, userRetailerReference) =>
            dispatch(validateBook(returnBookSerial, userRetailerReference)),
        validateTicketRange: (startBarcode, endBarcode, retailer) =>
            dispatch(validateTicketRange(startBarcode, endBarcode, retailer)),
        validatePosItem: (itemCode, userRetailerReference) =>
            dispatch(validatePosItem(itemCode, userRetailerReference)),
        isBookSplit: (barCode) => dispatch(isBookSplit(barCode)),
        resetCreateReturnScreen: () => dispatch(resetCreateReturnScreen()),
        checkBookDuplicate: (returnBookSerial) =>
            dispatch(checkBookDuplicate(returnBookSerial)),
        checkTicketDuplicate: (returnBookSerial, tickets) =>
            dispatch(checkTicketDuplicate(returnBookSerial, tickets)),
        addBookToPendingReturn: (returnBookSerial, bookId) =>
            dispatch(addBookToPendingReturn(returnBookSerial, bookId)),
        addTicketsToPendingReturns: (returnBookSerial, tickets) =>
            dispatch(addTicketsToPendingReturns(returnBookSerial, tickets)),
        addPosItemToPendingReturn: (itemCode, itemQuantity) =>
            dispatch(addPosItemToPendingReturn(itemCode, itemQuantity)),
        removeBookFromPendingReturn: (bookSerial) =>
            dispatch(removeBookFromPendingReturn(bookSerial)),
        removeTicketFromPendingReturn: (bookSerial, ticketId) =>
            dispatch(removeTicketFromPendingReturn(bookSerial, ticketId)),
        removePosItemFromReturn: (itemCode) =>
            dispatch(removePosItemFromReturn(itemCode)),
        savePendingReturn: (args) => dispatch(savePendingReturn(args)),
        success: (args) => dispatch(success(args)),
        checkIfOnlyOnePackIsAllowed: () =>
            dispatch(checkIfOnlyOnePackIsAllowed()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateReturn)
);
