import PrivacyPolicyApi from '../../../services/privacy-policy-service';
import { success, error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

export const PRIVACY_POLICY_LOADING = 'PRIVACY_POLICY_LOADING';
export const PRIVACY_POLICY_LOADING_DONE = 'PRIVACY_POLICY_LOADING_DONE';
export const PRIVACY_POLICY_INIT = 'PRIVACY_POLICY_INIT';
export const PRIVACY_POLICY_GET = 'PRIVACY_POLICY_GET';

const privacyPolicyApi = new PrivacyPolicyApi();

export const getPrivacyPolicyHtml = () => {
    return async (dispatch) => {
        dispatch({ type: PRIVACY_POLICY_LOADING });

        try {
            const payload = await privacyPolicyApi.getPrivacyPolicy();
            dispatch({ type: PRIVACY_POLICY_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PRIVACY_POLICY_LOADING_DONE });
    };
};

export const updatePrivacyPolicyHtml = (html) => {
    return async (dispatch) => {
        dispatch({ type: PRIVACY_POLICY_LOADING });

        try {
            await privacyPolicyApi.updatePrivacyPolicy(html);

            dispatch(
                success({ msg: 'The Privacy Policy was successfully updated.' })
            );
        } catch (err) {
            console.error(err);
            dispatch(
                error({
                    msg: `Could not save Privacy Policy: ${getErrorMessage(
                        err
                    )}`,
                })
            );
        }

        dispatch({ type: PRIVACY_POLICY_LOADING_DONE });
    };
};

export const initPrivacyPolicy = () => ({
    type: PRIVACY_POLICY_INIT,
});
