import _ from 'lodash';
import i18n from 'react-intl-universal';

const text = (key, defaultValue, variables) => {
    const options = i18n.getInitOptions();
    if (options && (!options.currentLocale || _.isEmpty(options.locales))) {
        return defaultValue;
    }

    if (variables) {
        return defaultValue
            ? i18n.get(key, variables).defaultMessage(defaultValue)
            : i18n.get(key, variables);
    }

    return defaultValue
        ? i18n.get(key).defaultMessage(defaultValue)
        : i18n.get(key);
};

const html = (key, defaultValue, variables) => {
    const options = i18n.getInitOptions();
    if (options && (!options.currentLocale || _.isEmpty(options.locales))) {
        return defaultValue;
    }

    if (variables) {
        return defaultValue
            ? i18n.getHTML(key, variables).defaultMessage(defaultValue)
            : i18n.getHTML(key, variables);
    }

    return defaultValue
        ? i18n.getHTML(key).defaultMessage(defaultValue)
        : i18n.getHTML(key);
};

const i18nUtils = {
    text,
    html,
};
export default i18nUtils;
