import { OrganizationApi } from '../../../services/organization-service';

export const FRONTEND_CONFIG_LOADING = 'FRONTEND_CONFIG_LOADING';
export const FRONTEND_CONFIG_LOADING_DONE = 'FRONTEND_CONFIG_LOADING_DONE';
export const FRONTEND_CONFIG_GET = 'FRONTEND_CONFIG_GET';

const organizationService = new OrganizationApi();

export const getFrontendConfig = () => async (dispatch) => {
    dispatch({ type: FRONTEND_CONFIG_LOADING });

    try {
        const portalConfigs =
            await organizationService.getPortalFrontendConfigurations();
        await dispatch({
            type: FRONTEND_CONFIG_GET,
            payload: portalConfigs,
        });
    } catch (err) {
        console.error(err);
    }

    dispatch({ type: FRONTEND_CONFIG_LOADING_DONE });
};
