import _ from 'lodash';

import { TaskApi } from '../../../services/task-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import { NOTIFY_SUCCESS, NOTIFY_ERROR } from '../../actions';

export const TASKS_PROCESSING = 'TASKS_PROCESSING';
export const TASKS_GET_OPTIONS = 'TASKS_GET_OPTIONS';
export const TASKS_CREATE_SUCCESS = 'TASKS_CREATE_SUCCESS';
export const TASKS_CREATE_FAILURE = 'TASKS_CREATE_FAILURE';
export const CREATE_TASK_VALIDATE = 'CREATE_TASK_VALIDATE';
export const TASK_INIT = 'TASK_INIT';
export const CREATE_TASK_LOADING = 'CREATE_TASK_LOADING';
export const CREATE_TASK_LOADING_DONE = 'CREATE_TASK_LOADING_DONE';

const taskApi = new TaskApi();

const createTaskError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'CreateTask' }));
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const createTask = (task) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: TASKS_PROCESSING });
        try {
            const taskDto = taskApi._mapCreateViewToDto(task);
            dispatch({
                type: TASKS_CREATE_SUCCESS,
                payload: { taskDto },
                meta: {
                    offline: {
                        effect: {
                            method: 'post',
                            url: `/tasks/`,
                            body: taskDto,
                        },
                        commit: {
                            type: NOTIFY_SUCCESS,
                            msg: 'Task was successfully created.',
                            target: 'TasksPage',
                        },
                        rollback: {
                            type: NOTIFY_ERROR,
                            msg: 'Task was not created',
                            target: 'DashboardPage',
                        },
                    },
                },
            });
        } catch (e) {
            success = false;
            dispatch(createTaskError(e));
        } finally {
            dispatch({ type: CREATE_TASK_LOADING_DONE });
        }

        return success;
    };
};

export const initTask = () => {
    return (dispatch) => {
        dispatch({ type: TASK_INIT });
    };
};

export const getTaskOptions = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_TASK_LOADING });
            const initOptions = await taskApi.getTaskOptions();

            //modify verbage for ACTIVE and scheduled to match design
            const statusOptions = _.map(initOptions.statuses, (i) => {
                if (i.id === 'ACTIVE') {
                    i.description = 'Activate Now';
                } else if (i.id === 'SCHEDULED') {
                    i.description = 'Scheduled Activation';
                }
                return i;
            });

            dispatch({
                type: TASKS_GET_OPTIONS,
                initOptions: {
                    ...initOptions,
                    statuses: statusOptions,
                },
            });
        } catch (e) {
            dispatch(createTaskError(e));
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: CREATE_TASK_LOADING_DONE });
        }
    };
};

export const setCreateTaskPageLoading = () => {
    return async (dispatch) => {
        dispatch({ type: CREATE_TASK_LOADING });
    };
};

export const setCreateTaskPageLoadingDone = () => {
    return async (dispatch) => {
        dispatch({ type: CREATE_TASK_LOADING_DONE });
    };
};
