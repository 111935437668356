import { TiersApi } from '../../../services/tiers-service';

export const TIER_OPTIONS_LOADING = 'TIER_OPTIONS_LOADING';
export const GET_TIER_OPTIONS_SUCCESS = 'GET_TIER_OPTIONS_SUCCESS';

const tiersApi = new TiersApi();

export const getAllTiers = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: TIER_OPTIONS_LOADING });

            const options = await tiersApi.getTierOptions();

            dispatch({
                type: GET_TIER_OPTIONS_SUCCESS,
                options,
            });
        } catch (e) {
            console.error(e);
        }
    };
};
