import React, { useRef, useState } from 'react';
import './style.css';
export const CustomPopUp = ({ children, content }) => {
    const refDiv = useRef(null);
    const dataDiv = useRef(null);
    const [isShown, setIsShown] = useState(false);

    return (
        <div
            className={'custom-pop-up'}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            ref={refDiv}
        >
            <>{children}</>{' '}
            {isShown && (
                <div
                    ref={dataDiv}
                    className={
                        'ui bottom right popup transition visible text-align-c'
                    }
                >
                    {content}
                </div>
            )}
        </div>
    );
};
