import { Backend } from './backend';

class InventoryApi {
    constructor() {
        this.path = 'retailers/shipments';
    }

    async findLatestShipments(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/retailer/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerOrderDetails(shipmentId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${shipmentId}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { InventoryApi };
