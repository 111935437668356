import { push } from 'react-router-redux';

import { setAppIsReady } from './readyApp';
import { AuthApi } from '../../services/auth-service';
import { getErrorMessage } from '../../constants/utils';
import config from '../../constants/config';
import { clearError, handleError } from '../../services/error-handler';
import { clearErrorHandler, clearSettings, initCart } from '../actions';
import { some } from 'lodash';
import { Auth } from 'aws-amplify';

import { setUserRetailers } from './user/user-retailer';
import { ENABLE_LOADING } from '../type/cognito/cognito-login';
import { hasRole } from '../../constants/utils';

const authApi = new AuthApi();

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const STORE_NEW_CHECKED_TIME = 'STORE_NEW_CHECKED_TIME';

const { LOGIN_PAGE, DASHBOARD, CONFIGURATION_MANAGEMENT } = config.ROUTE_URLS;
const { COGNITO_ENABLED } = config;
const loginSuccess = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_SUCCESS,
            token: data.token,
            info: data.userInfo,
        });

        if (some(data.userInfo.roles, (role) => role === 'RE_RETAILER')) {
            dispatch(setUserRetailers(data.userInfo?.user_id));
        } else if (some(data.userInfo.roles, (role) => role === 'RE_ADMIN')) {
            if (hasRole(data.userInfo.authorities, 'ROLE_RE_DASHBOARD_PAGE')) {
                dispatch(push(`#${DASHBOARD}`));
            } else {
                dispatch(push(`#${CONFIGURATION_MANAGEMENT}`));
            }
        }
    };
};

const loginError = (errors) => {
    return {
        type: LOGIN_ERROR,
        errors: getErrorMessage(errors),
    };
};

export const login = (args) => {
    return async (dispatch) => {
        dispatch({ type: LOGIN });
        dispatch(clearError());
        dispatch(clearErrorHandler());
        try {
            const token = await authApi.authenticate(args);
            const userInfo = await AuthApi.getUserInfo(token);

            return dispatch(
                loginSuccess({
                    token,
                    userInfo,
                }),
            );
        } catch (err) {
            dispatch({
                type: ENABLE_LOADING,
                payload: false,
            });
            return dispatch(loginError(err));
        }
    };
};

export const cognitoLogin = () => {
    return async (dispatch) => {
        dispatch(clearError());
        try {
            const session = await Auth.currentSession();

            const token = session.accessToken.jwtToken;
            dispatch(login({ username: `${token}`, grant_type: 'password' }));
        } catch (err) {
            return dispatch(handleError(err));
        }
    };
};

export const logout = () => {
    return async (dispatch) => {
        dispatch(setAppIsReady(true));
        dispatch({ type: LOGOUT });
        if (!COGNITO_ENABLED) {
            dispatch(push(`#${LOGIN_PAGE}`));
        }
        dispatch(clearSettings());
        dispatch(initCart());
        dispatch(clearError());
        if (COGNITO_ENABLED) {
            await Auth.signOut();
        }
    };
};

export const saveNewUserValidTime = () => {
    return (dispatch) => {
        dispatch({ type: STORE_NEW_CHECKED_TIME });
    };
};
