import {
    I18N_LOAD_RESOURCES_SUCCESS,
    I18N_LOAD_SUPPORTED_LOCALES,
} from '../actions/i18n';

const INITIAL_STATE = {
    currentLocale: null,
    localResources: [],
    supportedLocales: [],
};

const i18n = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case I18N_LOAD_RESOURCES_SUCCESS:
            return {
                ...state,
                localResources: action.resources,
                currentLocale: action.currentLocale,
            };
        case I18N_LOAD_SUPPORTED_LOCALES:
            return { ...state, supportedLocales: action.locales };
        default:
            return state;
    }
};
export default i18n;
