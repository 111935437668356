import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Dimmer,
    Loader,
    Button,
    Icon,
    Modal,
    Card,
    Dropdown,
} from 'semantic-ui-react';
import _, { map, find } from 'lodash';
import Page from '../../layout/Page';
import {
    generateOrderByExternalOrderNumber,
    getOrderById,
    initOrderDetail,
    popReferrer,
    setActiveItem,
    cancelOrder,
    openEditView,
    incrementGameQuantityForOrder,
    decrementGameQuantityForOrder,
    removeFromOrder,
    updateOrder,
    resetEditOrderDetail,
    getUserRetailerSuggestedOrders,
    cloneCurrentItems,
    readCloneState,
    success,
    editOrderedItems,
    getIncludeSampleGames,
} from '../../redux/actions';
import OrderDetailWithShipments from '../../components/orderDetailsWithShipments/OrderDetailsWithShipments';
import './styles.css';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import retailerNavMenuItemNames from '../../constants/retailerNav';
import { formatToDisplayDate, userHasPermission } from '../../constants/utils';
import OkCancelModal from '../../components/OkCancelModal';
import GameCatalogPage from '../game-catalog/GameCatalogPage';
import { CheckoutItems } from '../checkout/CheckoutItems';
import Localize from '../../constants/i18n-utils';
import permissions from '../../constants/permissions';
import { ORDER_STATUSES } from '../../constants/orderStatus';
import { RetailerInfo } from '../../components/restailer-info/retailer-info';

const { CANCEL_ORDER, EDIT_ORDER } = permissions;

class OrderDetailPage extends Component {
    constructor(props) {
        super(props);
        this.addGamesModalRef = React.createRef();
        this.state = {
            cancelModalOpened: false,
            openGameModel: false,
            isOpen: false,
            modalHeader: '',
            modalBody: '',
            modalOkFcn: null,
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.props.getOrder(this.props.match.params.id);
        this.props.setActiveItem(retailerNavMenuItemNames.ORDER_NAME);
        this.props.getIncludeSampleGames();
    };

    componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            this.init();
        }
    }

    componentWillUnmount() {
        this.props.init();
    }

    gotoOrderHistory = () => {
        this.props.history.goBack();
    };

    downloadOrder = () =>
        this.props.generateOrder(
            this.props.order.sfaOrderNumber,
            this.props.order.externalOrderNumber
        );

    showCancelModal = () => {
        this.setState({
            cancelModalOpened: true,
        });
    };

    closeCancelModal = () => {
        this.setState({
            cancelModalOpened: false,
        });
    };
    toggleEditView = () => {
        const { openEditView, resetEditOrderDetail, editView } = this.props;
        openEditView(!editView);
        resetEditOrderDetail();
    };

    saveOrder = async () => {
        const {
            getUserRetailerSuggestedOrders,
            updateOrder,
            order: { id, version },
        } = this.props;
        const result = await updateOrder(id, version);
        if (result === true) {
            await this.props.getOrder(id);
            this.toggleEditView();
            getUserRetailerSuggestedOrders();
        }
    };

    cloneAndOpenGameModal = () => {
        this.props.cloneCurrentItems();
        this.toggleGameModel();
    };

    toggleGameModel = () => {
        this.setState({ openGameModel: !this.state.openGameModel });
    };

    handleAddClick = () => {
        this.updateSuccess('Order list has been updated successfully');
        this.toggleGameModel();
    };

    updateSuccess = (msg) => {
        // the timeout helps in delaying the propagation so that the
        // componentWillUnmount does not clear it out
        setTimeout(() => {
            this.props.updateSuccess(msg);
        }, 100);
    };

    cancelAddGames = async () => {
        await this.props.readCloneState();
        this.toggleGameModel();
    };
    handleCancel = async () => {
        await this.props.cancelOrder(
            this.props.order.id,
            this.props.order.externalOrderNumber,
            this.props.order.version
        );

        this.setState({
            cancelModalOpened: false,
        });

        this.gotoOrderHistory();
    };
    incrementCartQuantity = (game) => {
        this.props.incrementCartQuantityForGame(game);
    };
    handleDecrementCartQuantityForGame = (game, qty) => {
        if (qty <= 1) {
            this.handleRemoveGame(game);
        } else {
            this.props.decrementCartQuantityForGame(game);
        }
    };

    handleRemoveGame = (game) => {
        this.setState({
            isOpen: true,
            modalHeader: Localize.text('cart.removeGame.header', 'Remove Game'),
            modalBody: `Game ${game.gameRefAndName} will be removed from order`,
            modalOkFcn: () => {
                this.props.removeFromCart(game);
                this.setState({ isOpen: false });
            },
        });
    };
    closeModal = () => {
        this.setState({ isOpen: false });
    };

    getDownloadButton = () => {
        return (
            <Button
                primary
                onClick={this.downloadOrder}
                className={'mobile-full-width'}>
                <span>
                    Download Order Items <span className="icon-Download" />
                </span>
            </Button>
        );
    };

    getEditButton = (status) => {
        return (
            <Button
                primary
                disabled={
                    status.code === ORDER_STATUSES.UNPROCESSED ||
                    status.code === ORDER_STATUSES.ON_HOLD
                        ? false
                        : true
                }
                className={'mobile-full-width'}
                onClick={this.toggleEditView}>
                <span>Edit Order </span>
            </Button>
        );
    };

    getCancelButton = (status) => {
        return (
            <Button
                primary
                disabled={
                    status.code === ORDER_STATUSES.UNPROCESSED ||
                    status.code === ORDER_STATUSES.ON_HOLD
                        ? false
                        : true
                }
                className={'mobile-full-width'}
                onClick={this.showCancelModal}>
                <span>Cancel Order </span>
            </Button>
        );
    };

    getAddItemsButton = () => (
        <Button
            primary
            className={'mobile-full-width'}
            onClick={this.cloneAndOpenGameModal}>
            <span>
                Add Items <Icon className={'pt-2'} name={'plus'} />
            </span>
        </Button>
    );

    noItemsAdded = () => {
        const { orderedItems, pendingOrderItems } = this.props;

        const newItems = map(orderedItems, (item) =>
            _.pick(item, ['game.gameId', 'qty'])
        );
        const existingItems =
            pendingOrderItems?.length === 0
                ? []
                : map(JSON.parse(pendingOrderItems), (item) =>
                      _.pick(item, ['game.gameId', 'qty'])
                  );
        const isOrderValid = orderedItems.find(
            (orderedItem) =>
                orderedItem.game.minimumOrderQuantity &&
                orderedItem.qty < orderedItem.game.minimumOrderQuantity
        );

        return (
            isOrderValid ||
            (orderedItems && pendingOrderItems?.length === 0
                ? orderedItems?.length
                : _.isEqual(_.sortBy(newItems), _.sortBy(existingItems)))
        );
    };

    render() {
        const {
            order,
            loading,
            includeSampleGames,
            cancelRequestLoading,
            toggleOrderSample,
        } = this.props;
        const isPendingOrder = order.statusCode === ORDER_STATUSES.UNPROCESSED;

        const { cancelModalOpened } = this.state;

        if (cancelModalOpened && !cancelRequestLoading) {
            const modalMessage = (
                <div style={{ fontWeight: 'bold', marginTop: '10px' }}>
                    Cancel order ?
                    <br />
                    <br />
                    <br />
                    Order Number: {order.externalOrderNumber}
                </div>
            );
            const modalContent = (
                <div>
                    Are you sure you want to cancel this order?
                    <br />
                </div>
            );
            return (
                <OkCancelModal
                    isOpen={cancelModalOpened}
                    header={modalMessage}
                    okText="Yes, Cancel"
                    cancelText="No"
                    body={modalContent}
                    okFunction={this.handleCancel}
                    cancelFunction={this.closeCancelModal}
                />
            );
        }

        if (!isPendingOrder && order.shipments && order.shipments.length > 0) {
            return (
                <OrderDetailWithShipments
                    downloadOrder={this.downloadOrder}
                    gotoOrderHistory={this.gotoOrderHistory}
                />
            );
        }

        const { orderDate, status, externalOrderNumber } = order;
        const { orderedItems, enableCancelOrder } = this.props;
        const { isOpen, modalHeader, modalBody, modalOkFcn, parentModalRef } =
            this.state;
        const noItemsAdded = this.noItemsAdded();

        return (
            <Page
                title={`Order #${externalOrderNumber || ' Pending'}`}
                breadCrumbComponent={
                    <BreadCrumb
                        location={'Orders'}
                        onClick={this.gotoOrderHistory}
                    />
                }
                keepNotification
                className="order-detail-page">
                <Dimmer inverted page active={loading || cancelRequestLoading}>
                    <Loader />
                </Dimmer>
                <OkCancelModal
                    isOpen={isOpen}
                    header={modalHeader}
                    okText={Localize.text('cart.removeAll.confirm', 'Confirm')}
                    body={modalBody}
                    okFunction={modalOkFcn}
                    cancelFunction={this.closeModal}
                />
                <div className="content">
                    <Dimmer
                        page
                        inverted
                        active={loading || cancelRequestLoading}>
                        <Loader />
                    </Dimmer>
                    <Card
                        className={
                            'w-100  d-flex flex-direction-row order-info-wrapper'
                        }>
                        <div className={'p-2 white-space-nowrap'}>
                            Retailer Detail :
                        </div>
                        <div className={'p-2'}>
                            <RetailerInfo preference={'S'} />
                        </div>
                    </Card>
                    <div className="order-info-wrapper">
                        <div className="order-info">
                            <div className="info">
                                <div className="info-cell">
                                    <div>Order Placed On</div>
                                    <div className="bold">
                                        {formatToDisplayDate(orderDate)}
                                    </div>
                                </div>
                                <div className="info-cell">
                                    <div>Status</div>
                                    <div className="bold">
                                        {status.description}
                                    </div>
                                </div>
                                <div className="info-cell">
                                    <div>Order No #</div>
                                    <div className="bold">
                                        {externalOrderNumber || ''}
                                    </div>
                                </div>

                                <div className="info-cell min-tablet">
                                    {this.getDownloadButton()}
                                </div>

                                {this.props.editView === false &&
                                    userHasPermission(EDIT_ORDER) && (
                                        <div className="info-cell min-tablet">
                                            {this.getEditButton(status)}
                                        </div>
                                    )}
                                {enableCancelOrder &&
                                    userHasPermission(CANCEL_ORDER) && (
                                        <div className="info-cell min-tablet">
                                            {this.getCancelButton(status)}
                                        </div>
                                    )}

                                {userHasPermission(EDIT_ORDER) &&
                                    this.props.editView && (
                                        <div className="info-cell min-tablet">
                                            {this.getAddItemsButton()}
                                        </div>
                                    )}

                                <div className={'max-mobile static-options'}>
                                    <Dropdown
                                        item
                                        icon="ellipsis vertical"
                                        floating>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                {this.getDownloadButton()}
                                            </Dropdown.Item>
                                            {this.props.editView === false &&
                                                userHasPermission(
                                                    EDIT_ORDER
                                                ) && (
                                                    <Dropdown.Item>
                                                        {this.getEditButton(
                                                            status
                                                        )}
                                                    </Dropdown.Item>
                                                )}
                                            {enableCancelOrder &&
                                                userHasPermission(
                                                    CANCEL_ORDER
                                                ) && (
                                                    <Dropdown.Item>
                                                        {this.getCancelButton(
                                                            status
                                                        )}
                                                    </Dropdown.Item>
                                                )}
                                            {userHasPermission(EDIT_ORDER) &&
                                                this.props.editView && (
                                                    <Dropdown.Item>
                                                        {this.getAddItemsButton()}
                                                    </Dropdown.Item>
                                                )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        {this.props.editView ? (
                            <div className={''}>
                                <CheckoutItems
                                    orderedItems={orderedItems}
                                    includeSampleGames={includeSampleGames}
                                    handleDecrementCartQuantityForGame={
                                        this.handleDecrementCartQuantityForGame
                                    }
                                    incrementCartQuantityForGame={
                                        this.incrementCartQuantity
                                    }
                                    handleRemoveGame={this.handleRemoveGame}
                                    showSgtQty={true}
                                    toggleOrderSample={(game) => {
                                        return toggleOrderSample(
                                            orderedItems,
                                            game
                                        );
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={'pb-1'}>
                                <CheckoutItems
                                    orderedItems={orderedItems}
                                    includeSampleGames={includeSampleGames}
                                />
                            </div>
                        )}
                        {this.props.editView !== false && (
                            <div className={'order-info'}>
                                <div className={'info'}>
                                    <div className={'info-cell'}>
                                        <Button
                                            primary
                                            disabled={
                                                status.code ===
                                                    ORDER_STATUSES.UNPROCESSED ||
                                                status.code ===
                                                    ORDER_STATUSES.ON_HOLD
                                                    ? false
                                                    : true
                                            }
                                            onClick={this.toggleEditView}>
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            primary
                                            disabled={
                                                status.code ===
                                                    ORDER_STATUSES.UNPROCESSED ||
                                                status.code ===
                                                    ORDER_STATUSES.ON_HOLD
                                                    ? false
                                                    : true
                                            }
                                            onClick={this.saveOrder}>
                                            <span>Save</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    open={this.state.openGameModel}
                    closeOnDimmerClick={false}
                    className={'order-detail-page-modal'}
                    ref={(context) => {
                        if (context != null && context.ref && !parentModalRef) {
                            this.setState({ parentModalRef: context.ref });
                        }
                    }}
                    dimmer={'blurring'}>
                    <Modal.Content
                        className={
                            'height-100 overflow-x-scroll order-edit-model '
                        }>
                        <div className={'edit-order-game-list'}>
                            <GameCatalogPage
                                isEditOrder
                                parentModalRef={parentModalRef}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions className={'menu-items'}>
                        <Button
                            primary
                            className={'me-4'}
                            onClick={this.cancelAddGames}>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            disabled={noItemsAdded}
                            primary
                            className={'ms-4'}
                            onClick={this.handleAddClick}>
                            <span>Add</span>
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Page>
        );
    }
}

const mapStateToProps = ({
    referrer: { referrer },
    orderDetail: { loading, order, editView, orderedItems, pendingOrderItems },
    checkout,
    organization: { configs },
    userRetailer: { activeRetailer },
    suggestedQuantity,
    cancelOrder: { loading: cancelRequestLoading },
    userInfo: { retailerInfo },
}) => {
    const referringPage =
        referrer && referrer.length > 0
            ? referrer[referrer.length - 1].referringPage
            : null;
    const referringPath =
        referrer && referrer.length > 0
            ? referrer[referrer.length - 1].referringPath
            : null;
    if (
        suggestedQuantity &&
        suggestedQuantity.suggestedQuantityMap &&
        orderedItems &&
        orderedItems.length > 0
    ) {
        map(orderedItems, (orderedItem) => {
            orderedItem.suggestedQuantity =
                suggestedQuantity.suggestedQuantityMap?.get(
                    orderedItem?.game?.reference
                );
            orderedItem.game.suggestedQuantity = orderedItem.suggestedQuantity;
        });
    }
    return {
        loading,
        order,
        referringPage,
        referringPath,
        editView,
        includeSampleGames: checkout.includeSampleGames,
        orderedItems,
        enableCancelOrder:
            find(configs, { name: 'enable-cancel-order' })?.value === 'true',
        activeRetailer,
        cancelRequestLoading,
        pendingOrderItems,
    };
};

const mapDispatchToProps = (dispatch) => ({
    openEditView: (edit) => dispatch(openEditView(edit)),
    getOrder: (id) => dispatch(getOrderById(id)),
    updateOrder: (id, version) => dispatch(updateOrder(id, version)),
    popReferrer: () => dispatch(popReferrer()),
    init: () => dispatch(initOrderDetail()),
    setActiveItem: (activeItem) => dispatch(setActiveItem(activeItem)),
    generateOrder: (sfaOrderNumber, externalOrderNumber) =>
        dispatch(
            generateOrderByExternalOrderNumber(
                sfaOrderNumber,
                externalOrderNumber
            )
        ),
    cancelOrder: (id, externalOrderNumber, version) =>
        dispatch(cancelOrder(id, externalOrderNumber, version)),
    incrementCartQuantityForGame: (game) =>
        dispatch(incrementGameQuantityForOrder(game)),
    decrementCartQuantityForGame: (game) =>
        dispatch(decrementGameQuantityForOrder(game)),
    removeFromCart: (game) => dispatch(removeFromOrder(game)),
    resetEditOrderDetail: () => dispatch(resetEditOrderDetail()),
    getUserRetailerSuggestedOrders: () =>
        dispatch(getUserRetailerSuggestedOrders()),
    cloneCurrentItems: () => dispatch(cloneCurrentItems()),
    readCloneState: () => dispatch(readCloneState()),
    updateSuccess: (msg) => dispatch(success({ msg: msg })),
    toggleOrderSample: (orderedItems, game) =>
        dispatch(editOrderedItems(orderedItems, game)),
    getIncludeSampleGames: () => dispatch(getIncludeSampleGames()),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage)
);
