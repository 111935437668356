import { RetailersApi } from '../../../services/retailers-service';

export const RETAILER_SELECT_LOADING = 'RETAILER_SELECT_LOADING';
export const RETAILER_SELECT_LOADING_DONE = 'RETAILER_SELECT_LOADING_DONE';
export const RETAILER_SELECT_GET = 'RETAILER_SELECT_GET';
export const RETAILER_SELECT_INIT = 'RETAILER_SELECT_INIT';
export const RETAILER_UPDATE_SELECTED_VALUES =
    'RETAILER_UPDATE_SELECTED_VALUES';
export const RETAILER_CLEAR_QUERY_DATA = 'RETAILER_CLEAR_QUERY_DATA';

const retailersApi = new RetailersApi();

export const getRetailerSelectData = (searchModel) => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_SELECT_LOADING });

        const payload = await retailersApi.getAllRetailers(searchModel);
        payload.searchQuery = searchModel.query;

        try {
            dispatch({
                type: RETAILER_SELECT_GET,
                payload,
            });
        } catch (err) {
            console.error(err);
            return err;
        }

        dispatch({ type: RETAILER_SELECT_LOADING_DONE });
        return payload;
    };
};

export const updateSelectedRetailersValues = (selectedRetailers) => ({
    type: RETAILER_UPDATE_SELECTED_VALUES,
    payload: selectedRetailers,
});

export const clearRetailerQueryData = () => ({
    type: RETAILER_CLEAR_QUERY_DATA,
});
export const initRetailerSelect = () => ({
    type: RETAILER_SELECT_INIT,
});
