import { ReportsApi } from '../../../../../services/reports-service';
export const TOTAL_PURCHASE_YTD_NON_PRODUCT = 'TOTAL_PURCHASE_YTD_NON_PRODUCT';
export const TOTAL_PURCHASE_YTD_NON_PRODUCT_LOADING =
    'TOTAL_PURCHASE_YTD_NON_PRODUCT_LOADING';
export const TOTAL_PURCHASE_YTD_NON_PRODUCT_ERROR =
    'TOTAL_PURCHASE_YTD_NON_PRODUCT_ERROR';

const reportsApi = new ReportsApi();

export const getTotalpurchaseYtdForNonProductItems = (retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TOTAL_PURCHASE_YTD_NON_PRODUCT_LOADING });
            const totalPurchaseYtdNonProducts =
                await reportsApi.getTotalpurchaseYTDForNonProductItems(
                    retailerReference
                );
            dispatch({
                type: TOTAL_PURCHASE_YTD_NON_PRODUCT,
                totalPurchaseYtdNonProducts,
            });
        } catch (e) {
            dispatch({
                type: TOTAL_PURCHASE_YTD_NON_PRODUCT_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
