import {
    PAST_ORDERS_LOADING,
    PAST_ORDERS_LOADING_DONE,
    PAST_ORDERS_INIT,
    PAST_ORDERS_SEARCH,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 0,
    number: 0,
};

const pastOrders = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PAST_ORDERS_SEARCH:
            return {
                ...state,
                ...payload,
            };

        case PAST_ORDERS_INIT:
            return { ...INITIAL_STATE };

        case PAST_ORDERS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PAST_ORDERS_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default pastOrders;
