import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../Widget';
import { Icon } from 'semantic-ui-react';
import { getServiceVersions } from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import NoItems from '../../../../components/NoItems';
import classNames from 'classnames';
import './styles.css';
import { formatToDisplayDateTime } from '../../../../constants/utils';
import _ from 'lodash';

export class ServiceVersionWidget extends Component {
    async componentDidMount() {
        this.getVersions();
    }

    getVersions = () => {
        const { getServiceVersions } = this.props;
        getServiceVersions();
    };

    render() {
        const { loading, serviceVersionsData } = this.props;

        let content = null;
        if (!serviceVersionsData) {
            content = (
                <div className="no-sales-targets" data-test="noData">
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no latest order!"
                    />
                </div>
            );
        } else {
            const widgetContent = _.map(serviceVersionsData, (value, key) => ({
                serviceName: key + ':',
                version: value.version,
                time: value.time,
            }));
            content = (
                <div className="services-versions-widget-content">
                    <Table
                        headClassName="progress-to-target-header summary-widget"
                        contentKey="serviceName"
                        content={widgetContent}
                        totalElements={widgetContent.length}
                        supressSearch={true}
                        columns={[
                            {
                                name: 'serviceName',
                                title: 'Service',
                                columnClassName: () =>
                                    'services-versions-dir-col',
                            },
                            {
                                name: 'version',
                                title: 'Build Version',
                                columnClassName: (value) =>
                                    classNames(
                                        { error: value === 'NOT AVAILABLE' },
                                        'services-versions-middle-col'
                                    ),
                            },
                            {
                                name: 'time',
                                title: 'Build Date',
                                columnClassName: (value) =>
                                    classNames(
                                        { error: value === '-' },
                                        'services-versions-right-col'
                                    ),
                                formatter: (value) =>
                                    value
                                        ? formatToDisplayDateTime(value)
                                        : '-',
                            },
                        ]}
                    />
                </div>
            );
        }

        return (
            <Widget loading={loading} data-test="serviceVersionWidget">
                <div className="bold widget-heading">
                    Service Version
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.getVersions} />
                    </div>
                </div>
                {content}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    serviceVersions: { serviceVersionsData, loading },
}) => {
    // const { lastOrderSummaries } = ;
    return { loading, serviceVersionsData, retailerInfo };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceVersions: () => dispatch(getServiceVersions()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ServiceVersionWidget)
);
