import React from 'react';
import { Label } from 'semantic-ui-react';

const Badge = ({ value, isSelected, className, style, ...otherProps }) => {
    const thisStyle = isSelected ? styles.on : styles.off;
    return (
        <Label
            className={`${className} badge`}
            floating
            circular
            style={{ ...thisStyle, ...style }}
            {...otherProps}
        >
            {value}
        </Label>
    );
};

const styles = {
    on: {
        color: '#FFFFFF',
        backgroundColor: '#0C0033',
    },
    off: {
        color: '#0C0033',
        backgroundColor: 'transparent',
    },
};

export default Badge;
