import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import { RetailerShipmentOrderApi } from '../../../services/retailer-shipment-order-service';

export const ORDER_ITEM_VALUE_UPDATE = 'ORDER_ITEM_VALUE_UPDATE';
export const RETAILER_ORDER_CREATE_SUCCESS = 'RETAILER_ORDER_CREATE_SUCCESS';
export const RETAILER_ORDER_CREATE_LOADING = 'RETAILER_ORDER_CREATE_LOADING';

const retailerShipmentOrderApi = new RetailerShipmentOrderApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const updateOrderInput = (prop, value) => ({
    type: ORDER_ITEM_VALUE_UPDATE,
    value,
    prop,
});

export const createShipmentOrder = (order) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: RETAILER_ORDER_CREATE_LOADING });
            const data = await retailerShipmentOrderApi.createShipmentOrder(
                order
            );
            dispatch({
                type: RETAILER_ORDER_CREATE_SUCCESS,
                data,
            });
        } catch (err) {
            dispatch(createErrorHandlerError(err));
            success = false;
        }
        return success;
    };
};
