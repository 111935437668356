import { ReportsApi } from '../../../services/reports-service';
import reportNames from '../../../constants/reportNames';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const GENERATE_PRINT_ORDER_LOADING = 'GENERATE_PRINT_ORDER_LOADING';
export const GENERATE_PRINT_ORDER_LOADING_DONE =
    'GENERATE_PRINT_ORDER_LOADING_DONE';

const reportsApi = new ReportsApi();

export const generateOrder = (orderId) => {
    return async (dispatch) => {
        dispatch({ type: GENERATE_PRINT_ORDER_LOADING });
        try {
            await reportsApi.generateReportByName(
                reportNames.SINGLE_ORDER_REPORT,
                { id: orderId }
            );
        } catch (err) {
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err) }));
        }
        dispatch({ type: GENERATE_PRINT_ORDER_LOADING_DONE });
    };
};

export const generateOrderByExternalOrderNumber = (
    sfaOrderNumber,
    externalOrderNumber
) => {
    return async (dispatch) => {
        dispatch({ type: GENERATE_PRINT_ORDER_LOADING });
        try {
            await reportsApi.generateReportByName(
                reportNames.SINGLE_ORDER_REPORT,
                {
                    sfaOrderNumber: sfaOrderNumber,
                    externalOrderNumber: externalOrderNumber,
                }
            );
        } catch (err) {
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err) }));
        }
        dispatch({ type: GENERATE_PRINT_ORDER_LOADING_DONE });
    };
};
