import {
    FILTER_LIST_SALES_MANAGER,
    FILTER_LIST_SALES_MANAGER_LOADING,
    FILTER_LIST_SALES_REP,
    FILTER_LIST_SALES_REP_LOADING,
    FILTER_LIST_RETAILER_TYPES,
    FILTER_LIST_RETAILER_TYPES_LOADING,
    FILTER_RETAILER,
    FILTER_RETAILER_LOADING,
    FILTER_ASSIGNEE,
    FILTER_ASSIGNEE_LOADING,
    FILTER_ROLES,
    FILTER_ROLES_LOADING,
    FILTER_LIST_INDUSTRY_TYPES,
    FILTER_LIST_INDUSTRY_TYPES_LOADING,
    FILTER_TERRITORIES_LOADING,
    FILTER_TERRITORIES,
} from '../actions';

const INITIAL_STATE = {
    loadingReps: false,
    reps: [],
    loadingManagers: false,
    managers: [],
    loadingRetailerTypes: false,
    retailerTypes: [],
    loadingRetailers: false,
    retailers: [],
    loadingAssignees: false,
    assignees: [],
    loadingRoles: false,
    roles: [],
    loadingIndustryCodes: false,
    industryCodes: [],
    loadingTerritories: false,
    territories: [],
};

const filterOptions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILTER_LIST_SALES_REP_LOADING:
            return { ...state, loadingReps: true };
        case FILTER_LIST_SALES_REP:
            return { ...state, reps: [...action.payload], loadingReps: false };
        case FILTER_LIST_SALES_MANAGER_LOADING:
            return { ...state, loadingManagers: true };
        case FILTER_LIST_SALES_MANAGER:
            return {
                ...state,
                managers: [...action.payload],
                loadingManagers: false,
            };
        case FILTER_LIST_RETAILER_TYPES_LOADING:
            return { ...state, loadingRetailerTypes: true };
        case FILTER_LIST_RETAILER_TYPES:
            return {
                ...state,
                retailerTypes: [...action.payload],
                loadingRetailerTypes: false,
            };
        case FILTER_RETAILER_LOADING:
            return { ...state, loadingRetailers: true };
        case FILTER_RETAILER:
            return {
                ...state,
                retailers: [...action.payload],
                loadingRetailers: false,
            };
        case FILTER_ASSIGNEE_LOADING:
            return { ...state, loadingAssignees: true };
        case FILTER_ASSIGNEE:
            return {
                ...state,
                assignees: [...action.payload],
                loadingAssignees: false,
            };
        case FILTER_ROLES_LOADING:
            return { ...state, loadingRoles: true };
        case FILTER_ROLES:
            return {
                ...state,
                roles: [...action.payload],
                loadingRoles: false,
            };
        case FILTER_LIST_INDUSTRY_TYPES_LOADING:
            return { ...state, loadingIndustryCodes: true };
        case FILTER_LIST_INDUSTRY_TYPES:
            return {
                ...state,
                industryCodes: [...action.payload],
                loadingIndustryCodes: false,
            };
        case FILTER_TERRITORIES_LOADING:
            return { ...state, loadingTerritories: true };
        case FILTER_TERRITORIES:
            return {
                ...state,
                territories: [...action.payload],
                loadingTerritories: false,
            };
        default:
            return state;
    }
};
export default filterOptions;
