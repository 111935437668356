import { TiersApi } from '../../services/tiers-service';
import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

export const TIERS_LIST = 'TIERS_LIST';
export const TIERS_LIST_LOADING = 'TIERS_LIST_LOADING';

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const tiersApi = new TiersApi();

export const fetchTiers = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TIERS_LIST_LOADING });
            const list = await tiersApi.getAllTiers(args);
            dispatch({
                type: TIERS_LIST,
                list,
            });
        } catch (err) {
            //console.error(err);
            dispatch(createErrorHandlerError(err));
        }
    };
};
