import {
    TASKS_PROCESSING,
    TASKS_CREATE,
    TASKS_GET_OPTIONS,
    TASKS_CREATE_SUCCESS,
    TASKS_CREATE_FAILURE,
} from '../actions';

const INIT_TASK_CREATE = {
    taskCategory: '',
    title: '',
    description: '',
    priority: '',
    assignee: '',
    schedule: '',
    responseDueDate: new Date().toISOString(),
};

const INITIAL_STATE = {
    processing: false,
    taskToCreate: JSON.parse(JSON.stringify(INIT_TASK_CREATE)),
    createTaskOptions: {
        taskCategories: [],
        assignees: [],
        schedules: [],
        priorities: [],
    },
};

const tasks = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TASKS_PROCESSING:
            return { ...state, processing: true };
        case TASKS_CREATE:
            return { ...state, taskToCreate: action.task, processing: true };
        case TASKS_CREATE_SUCCESS:
            return {
                ...state,
                taskToCreate: JSON.parse(JSON.stringify(INIT_TASK_CREATE)),
                processing: false,
            };
        case TASKS_CREATE_FAILURE:
            return { ...state, processing: false };
        case TASKS_GET_OPTIONS:
            return {
                ...INITIAL_STATE,
                taskToCreate: JSON.parse(JSON.stringify(INIT_TASK_CREATE)),
                createTaskOptions: action.initOptions,
                processing: false,
            };
        default:
            return state;
    }
};
export default tasks;
