import React from 'react';
import Table from '../Table';
import Pagination from '../Pagination';
import { ResponsiveCard } from './responsive-card';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import ResponsiveCardSearchForm from './ResponsiveCardSearchForm';

export const ResponsiveTable = (props) => {
    const { sortColumnName, ascending } = useSelector(
        (state) => state.tableSort
    );
    const columns = filter(props.columns, (column) => {
        return !column.mobileOnly;
    });
    return (
        <>
            <div className={'min-desktop data-section'}>
                <Table {...props} columns={columns} showTotalEntries />
            </div>
            <div className={'max-tablet'}>
                {!props.supressSearch && (
                    <ResponsiveCardSearchForm
                        onSubmit={(value) => props.onMobileSearch(value)}
                    />
                )}
                <ResponsiveCard {...props} />
            </div>
            <div className={'pt-4'}>
                {props.totalPages && props.totalPages > 1 ? (
                    <Pagination
                        prev
                        next
                        first
                        last
                        totalPages={props.totalPages}
                        activePage={props.page <= 0 ? 1 : props.page + 1}
                        onPageChanged={(page) => {
                            page = page - 1 < 0 ? 0 : page - 1;
                            props.fetchData({
                                page,
                                size: props.size,
                                sortColumn: sortColumnName,
                                sortOrder: ascending ? 'ASC' : 'DESC',
                            });
                        }}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
};
