import {
    TASKS_PROCESSING,
    TASKS_CREATE,
    TASKS_GET_OPTIONS,
    TASKS_CREATE_SUCCESS,
    TASKS_CREATE_FAILURE,
    CREATE_TASK_VALIDATE,
    TASK_INIT,
    CREATE_TASK_LOADING,
    CREATE_TASK_LOADING_DONE,
} from '../../actions';

import { TASK_OPTIONS } from '../../../DTOs/taskOptions';

const INIT_TASK_CREATE = {
    taskCategory: '',
    title: '',
    description: '',
    priority: 'LOW',
    assignee: '',
    schedule: '',
    status: 'DRAFT',
    responseDueDate: new Date().toISOString(),
    startDate: new Date().toISOString(),
    endDate: null,
    survey: null,
    includePlanogram: false,
    hotTopic: false,
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    taskToCreate: { ...INIT_TASK_CREATE },
    createTaskOptions: { ...TASK_OPTIONS },
    isTaskValid: {
        status: false,
        errors: [],
    },
    createTaskSucess: false,
};

const createTask = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_TASK_LOADING:
            return { ...state, loading: true };
        case CREATE_TASK_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case TASKS_PROCESSING:
            return { ...state, processing: true };
        case TASKS_CREATE:
            return { ...state, taskToCreate: action.task, processing: true };
        case TASKS_CREATE_SUCCESS:
            return {
                ...state,
                taskToCreate: { ...INIT_TASK_CREATE },
                createTaskSucess: true,
                processing: false,
            };
        case TASKS_CREATE_FAILURE:
            return {
                ...state,
                isTaskValid: { status: false, errors: [action.error] },
                createTaskSucess: false,
                processing: false,
                loading: false,
            };
        case TASKS_GET_OPTIONS:
            return { ...state, createTaskOptions: action.initOptions };
        case CREATE_TASK_VALIDATE:
            return {
                ...state,
                isTaskValid: {
                    status: action.isTaskValid,
                    errors: action.validateTaskErrors,
                },
            };
        case TASK_INIT:
            return { ...INITIAL_STATE, taskToCreate: { ...INIT_TASK_CREATE } };
        default:
            return state;
    }
};
export default createTask;
