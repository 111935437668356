import React from 'react';
import { Segment } from 'semantic-ui-react';

import './NotFound.css';

const NotFound = () => {
    return (
        <div data-test="notfound">
            <Segment>
                <div className="error-layout">
                    <span
                        className="icon-Error secondary-color"
                        style={{ fontSize: 90 }}
                    >
                        {' '}
                    </span>
                    <span className="error-style1">Oops!</span>
                    <span className="error-decription">
                        We couldn't find that page.
                    </span>
                </div>
            </Segment>
        </div>
    );
};
export default NotFound;
