import RetailerGroupService from '../../../services/retailer-group-service';
import { Dispatch } from 'redux';
import {
    GET_RETAILER_GROUPS,
    GET_RETAILER_GROUPS_BY_SEARCH,
    RetailerGroupActionTypes,
    UPDATE_LOADING_STATE as LIST_UPDATE_LOADING_STATE,
} from '../../type/reatiler-group/reatiler-group-type';
import { RetailerGroup } from '../../../DTOs/retailer-group';
import {
    GET_RETAILER_GROUP,
    EditRetailerGroupActionTypes,
    UPDATE_LOADING_STATE,
} from '../../type/reatiler-group/edit-reatiler-group-type';
import { error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';
import type { SearchSpecificationModel } from '../../../interface/search-specification-model';
import { Page } from '../../../layout/Page';

const retailerGroupService = new RetailerGroupService();

export const getRetailerGroupList = () => {
    return async (dispatch: Dispatch<RetailerGroupActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const retailerGroups: RetailerGroup[] =
                await retailerGroupService.get();
            dispatch({ type: GET_RETAILER_GROUPS, payload: retailerGroups });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};

export const getRetailerGroupPage = (
    searchSpecificationModel: SearchSpecificationModel
) => {
    return async (dispatch: Dispatch<RetailerGroupActionTypes>) => {
        dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: true });
        try {
            const retailerGroups: Page<RetailerGroup> =
                await retailerGroupService.getBySearchSpecificationModel(
                    searchSpecificationModel
                );
            dispatch({
                type: GET_RETAILER_GROUPS_BY_SEARCH,
                payload: retailerGroups,
            });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: LIST_UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const getRetailerGroup = (id: number) => {
    return async (dispatch: Dispatch<EditRetailerGroupActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        try {
            const retailerGroup: RetailerGroup =
                await retailerGroupService.getById(id);
            dispatch({ type: GET_RETAILER_GROUP, payload: retailerGroup });
        } catch (e) {
            dispatch(updateError(e));
        } finally {
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
        }
    };
};
export const createEmptyRetailerGroup = () => {
    return async (dispatch: Dispatch<EditRetailerGroupActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });
        dispatch({ type: GET_RETAILER_GROUP, payload: { isActive: true } });
    };
};
export const saveRetailerGroup = (id: number, retailerGroup: RetailerGroup) => {
    return async (dispatch: Dispatch<EditRetailerGroupActionTypes>) => {
        dispatch({ type: UPDATE_LOADING_STATE, payload: true });

        try {
            if (id) {
                retailerGroup = await retailerGroupService.update(
                    id,
                    retailerGroup
                );
            } else {
                retailerGroup = await retailerGroupService.create(
                    retailerGroup
                );
            }
            dispatch({ type: GET_RETAILER_GROUP, payload: retailerGroup });
        } catch (e) {
            if (e.response.data) {
                dispatch(updateMessageError(e.response.data));
            } else {
                dispatch(updateError(e));
            }
            dispatch({ type: UPDATE_LOADING_STATE, payload: false });
            throw e;
        }
        return retailerGroup;
    };
};

const updateMessageError = (msg) => {
    return (dispatch) => {
        dispatch(error({ msg: msg, target: 'Retailer Group' }));
    };
};

const updateError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'Retailer Group' })
        );
    };
};

export const clearRetailerGroup = () => {
    return async (dispatch: Dispatch<EditRetailerGroupActionTypes>) => {
        dispatch({ type: GET_RETAILER_GROUP, payload: undefined });
    };
};
export const clearRetailerGroups = () => {
    return async (dispatch: Dispatch<EditRetailerGroupActionTypes>) => {
        dispatch({ type: GET_RETAILER_GROUPS_BY_SEARCH, payload: undefined });
        dispatch({ type: GET_RETAILER_GROUPS, payload: undefined });
    };
};
