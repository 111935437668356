import { RetailerGroup } from '../../../DTOs/retailer-group';
import type { Page } from '../../../interface/page';

export const GET_RETAILER_GROUPS = 'RetailerGroup/Get';
export const GET_RETAILER_GROUPS_BY_SEARCH = 'RetailerGroup/Search';

export const UPDATE_LOADING_STATE = 'RetailerGroup/loading';

export interface RetailerGroupStateType {
    isLoading: boolean;
    retailerGroupList: RetailerGroup[];
    retailerGroupPage: Page<RetailerGroup>;
}

interface GetRetailerGroupsType {
    type: typeof GET_RETAILER_GROUPS;
    payload: RetailerGroup[];
}

interface GetRetailerGroupsTypeBySearch {
    type: typeof GET_RETAILER_GROUPS_BY_SEARCH;
    payload: Page<RetailerGroup>;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type RetailerGroupActionTypes =
    | GetRetailerGroupsType
    | UpdateLoadingState
    | GetRetailerGroupsTypeBySearch;
