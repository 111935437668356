import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../Widget';
import { getPlanogramsForRetailerReference } from '../../../../redux/actions';
import Table from '../../../../components/datatable/Table';
import './styles.css';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import NoItems from '../../../../components/NoItems';
import _ from 'lodash';

import config from '../../../../constants/config';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';
import { formatToDisplayDate } from '../../../../constants/utils';

export class PlanogramsWidget extends Component {
    async componentDidMount() {
        await this.init();
    }
    init = async () => {
        const { getPlanogramsForRetailerReference } = this.props;

        if (this.props.retailerInfo) {
            await getPlanogramsForRetailerReference(this.props.retailerInfo);
        }
    };

    async componentDidUpdate(prevProps: Readonly<P>) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }

    goToPlanograms = () => {
        this.props.history.push(config.ROUTE_URLS.RETAILER_PLANOGRAM_LIST);
    };

    showTable(planograms) {
        return (
            <div>
                {planograms?.content.length > 0 && (
                    <Table
                        headClassName="progress-to-target-header summary-widget"
                        contentKey="item"
                        content={planograms.content}
                        totalElements={planograms.content.length}
                        supressSearch={true}
                        noDataMessage="No current planograms available."
                        columns={[
                            {
                                name: 'name',
                                title: 'Name',
                                columnClassName: () => 'planogram-dir-col',
                            },
                            {
                                name: 'description',
                                title: 'Description',
                                columnClassName: () => 'planogram-dir-col',
                            },
                            {
                                name: 'startDate',
                                title: 'Start Date',
                                columnClassName: () => 'planogram-dir-col',
                                formatter: (val) => formatToDisplayDate(val),
                            },
                            {
                                name: 'endDate',
                                title: 'End Date',
                                columnClassName: () => 'planogram-dir-col',
                                formatter: (val) => formatToDisplayDate(val),
                            },
                            {
                                name: 'retailerGroup.name',
                                title: 'Retailer Group',
                                columnClassName: () => 'planogram-dir-col',
                            },
                        ]}
                    />
                )}
            </div>
        );
    }
    render() {
        const {
            loading,
            upcomingPlanogramsForRetailer,
            currentPlanogramsForRetailer,
            errorResponse,
        } = this.props;

        const footer = <MoreDetail onClick={this.goToPlanograms} />;

        return (
            <Widget loading={loading} footer={!errorResponse ? footer : null}>
                <div className="bold widget-heading">
                    Planograms
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {(!upcomingPlanogramsForRetailer ||
                    !currentPlanogramsForRetailer) &&
                errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : (
                    <div>
                        {
                            <div className="bold price-point planogram-section">
                                Current:
                            </div>
                        }
                        {_.size(currentPlanogramsForRetailer?.content) > 0 ? (
                            <span>
                                {this.showTable(currentPlanogramsForRetailer)}
                            </span>
                        ) : (
                            <NoItems
                                message="No current planograms available."
                                hideNoItemsMessage
                                small
                            />
                        )}
                        {
                            <div className="bold price-point planogram-section">
                                Upcoming:
                            </div>
                        }
                        {_.size(upcomingPlanogramsForRetailer?.content) > 0 ? (
                            <>
                                <span>
                                    {this.showTable(
                                        upcomingPlanogramsForRetailer
                                    )}
                                </span>
                            </>
                        ) : (
                            <NoItems
                                message="No upcoming planograms available."
                                hideNoItemsMessage
                                small
                            />
                        )}
                    </div>
                )}
            </Widget>
        );
    }
}

const mapStateToProps = ({
    userInfo: { retailerInfo },
    planogramsWidget: {
        currentPlanogramsForRetailer,
        upcomingPlanogramsForRetailer,
        loading,
        errorResponse,
    },
}) => {
    return {
        loading,
        currentPlanogramsForRetailer,
        upcomingPlanogramsForRetailer,
        retailerInfo,
        errorResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPlanogramsForRetailerReference: (retailerReference) =>
            dispatch(getPlanogramsForRetailerReference(retailerReference)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PlanogramsWidget)
);
