import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';

import semanticFormField from './form/semanticFormField';
import UneditableLabel from './UneditableLabel';

const SfaTextArea = ({
    labelText,
    textAreaPlaceholder,
    textAreaValue,
    textAreaName,
    validations,
    ...otherProps
}) => {
    return (
        <Form.Field>
            <div className="readonly">
                <UneditableLabel text={labelText} />
            </div>
            <Field
                name={textAreaName}
                component={semanticFormField}
                as={Form.TextArea}
                placeholder={textAreaPlaceholder}
                validate={validations}
                style={style.textAreaStyle}
                maxLength={255}
                value={textAreaValue}
                {...otherProps}
            />
            <div className="caption-text secondary-color">
                <i className="icon-Info" /> Max 255 Characters
            </div>
        </Form.Field>
    );
};

const style = {
    textAreaStyle: {
        resize: 'none',
    },
};

export default SfaTextArea;
