import React, { Component } from 'react';
import { Field } from 'redux-form';
import FileSelector from '../FileSelector';
import {
    required,
    maxFileSize,
    validLogoFileExtension,
    maxLength,
} from '../../constants/validate';
import SfaTextArea from '../SfaTextArea';
import SfaReadView from '../SfaReadView';

class LogoUploadForm extends Component {
    render() {
        const { className, description, descriptionType } = this.props;
        const validators = [required, maxFileSize, validLogoFileExtension];
        const optimalSize =
            descriptionType === 'retailerPortalReportLogo'
                ? 'Optimal dimensions for the logo are 80px x 74px'
                : descriptionType === 'retailerPortalAppLogo'
                ? 'Optimal dimensions for the logo are 30px x 180px'
                : 'Contact your administrator for the optimal size';

        return (
            <div className={className}>
                <div>
                    <SfaReadView
                        elements={[
                            {
                                label: 'Configuration',
                                value:
                                    descriptionType ===
                                    'retailerPortalReportLogo'
                                        ? 'Logo - Reports'
                                        : descriptionType ===
                                          'retailerPortalAppLogo'
                                        ? 'Logo - App'
                                        : 'Logo',
                            },
                        ]}
                    />
                </div>
                <div>
                    <br />
                </div>
                <Field
                    component={FileSelector}
                    type="file"
                    name="file"
                    labelText="Logo *"
                    accept="image/jpeg, image/png, image/svg+xml"
                    helpText="File types allowed: .jpeg .jpg .png .svg Maximum file size: 100MB"
                    helpText2={optimalSize}
                    validate={validators}
                />
                <div>
                    <br />
                </div>
                <SfaTextArea
                    labelText="Description *"
                    textAreaName="description"
                    textAreaValue={description}
                    textAreaPlaceholder={
                        'Fill Description (Limit 255 Characters)'
                    }
                    validations={[required, maxLength]}
                />
            </div>
        );
    }
}

export default LogoUploadForm;
