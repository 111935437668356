import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { clearErrorHandler } from '../../redux/actions';
import config from '../../constants/config';
import './ErrorHandler.css';

const { DASHBOARD } = config.ROUTE_URLS;

class ErrorHandlerPage extends Component {
    componentWillUnmount() {
        this.props.clearErrorHandler();
    }

    gotoDashboard = () => {
        this.props.history.push(DASHBOARD);
    };

    goBackHistory = () => {
        window.history.back();
    };

    render() {
        const { msg, children } = this.props;
        let msgHeader, msgTag, msgDescription;

        if (!msg) {
            return <div>{children}</div>;
        }

        switch (msg) {
            case 401:
            case 403:
                msgHeader = 'Access Denied!';
                msgTag = '';
                msgDescription =
                    'The page you were trying to reach is absolutely forbidden for some reason.';
                break;
            default:
                msgHeader = 'Uh...';
                msgTag = 'Something went wrong!';
                msgDescription = 'Don’t worry, it’s not you, it is us.';
                break;
        }

        return (
            <div>
                <Segment>
                    <div className="error-layout">
                        <span
                            className="icon-Error secondary-color"
                            style={{ fontSize: 90 }}
                        >
                            {' '}
                        </span>
                        <span className="error-style1">{msgHeader}</span>
                        <span className="error-style2">{msgTag}</span>
                        <span className="error-decription">
                            {msgDescription}
                        </span>
                    </div>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = ({ errorMessage }) => {
    const { msg } = errorMessage;
    return { msg };
};

const mapDispatchToProps = (dispatch) => ({
    clearErrorHandler: () => dispatch(clearErrorHandler),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ErrorHandlerPage)
);
