export const RETAILER_DASHBOARD_MODAL_OPEN = 'RETAILER_DASHBOARD_MODAL_OPEN';
export const RETAILER_DASHBOARD_MODAL_CLOSE = 'RETAILER_DASHBOARD_MODAL_CLOSE';

export const openRetailerDashboardModal = () => ({
    type: RETAILER_DASHBOARD_MODAL_OPEN,
});

export const closeRetailerDashboardModal = () => ({
    type: RETAILER_DASHBOARD_MODAL_CLOSE,
});
