import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Header } from 'semantic-ui-react';
import Page from '../../layout/Page';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import { getPublicContactUsData, initPublicContact } from '../../redux/actions';
import ContactDetails from '../../components/contact/ContactDetails';

class ContactUsPublic extends Component {
    componentDidMount() {
        this.props.getData();
    }

    componentWillUnmount() {
        this.props.init();
    }

    render() {
        const { logoImage, hideContact, loading, contact } = this.props;
        return (
            <Page>
                <Dimmer page inverted active={loading}>
                    <Loader />
                </Dimmer>
                <div className="faq-wrapper">
                    <div className="login-logo-wrapper">
                        <img src={logoImage.src} alt={logoImage.alt} />
                    </div>
                    <SfaBreadCrumb
                        onBreadCrumb={hideContact}
                        breadCrumbText="sign-in"
                        animation="fade right"
                    />
                    <Header className="faq-title">Contact Us</Header>
                    <ContactDetails contact={contact} />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ publicContact }) => ({ ...publicContact });

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => dispatch(getPublicContactUsData()),
        init: () => dispatch(initPublicContact()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPublic);
