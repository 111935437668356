import { map, orderBy } from 'lodash';
import { RetailersApi } from '../../../../services/retailers-service';
import { filter } from 'lodash/collection';

export const RETAILER_TYPE_OPTIONS_LOADING = 'RETAILER_TYPE_OPTIONS_LOADING';
export const RETAILER_TYPE_OPTIONS_LOADING_DONE =
    'RETAILER_TYPE_OPTIONS_LOADING_DONE';
export const RETAILER_TYPE_OPTIONS_INIT = 'RETAILER_TYPE_OPTIONS_INIT';
export const RETAILER_TYPE_OPTIONS_GET = 'RETAILER_TYPE_OPTIONS_GET';

const retailerApi = new RetailersApi();

export const getRetailerTypeOptions = () => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_TYPE_OPTIONS_LOADING });

        try {
            const retailerTypeOptions = await retailerApi.getRetailerTypes();

            dispatch({
                type: RETAILER_TYPE_OPTIONS_GET,
                payload: map(
                    orderBy(
                        filter(retailerTypeOptions, { isActive: true }),
                        'reference'
                    ),
                    ({ id, name, reference }) => ({
                        id,
                        label: name,
                        value: reference,
                    })
                ),
            });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: RETAILER_TYPE_OPTIONS_LOADING_DONE });
    };
};

export const initRetailerTypeOptions = () => ({
    type: RETAILER_TYPE_OPTIONS_INIT,
});
