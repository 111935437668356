import {
    TOGGLE_DRAWER,
    CLOSE_DRAWER,
    SET_ACTIVE_MENU,
    SHOW_CONFIRMATION_POPUP,
    SET_PATH,
    SET_ACTIVE_NAME,
    CLEAR_ACTIVE_MENU,
} from '../actions/drawer';

const INITIAL_STATE = {
    open: false,
    activeMenu: 'Dashboard',
    showConfirmationPopup: false,
    path: null,
    name: null,
};

const drawer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return { ...state, open: action.payload || false };
        case CLOSE_DRAWER:
            return { ...state, open: false };
        case SET_ACTIVE_MENU:
            return { ...state, activeMenu: action.payload };
        case SHOW_CONFIRMATION_POPUP:
            return { ...state, showConfirmationPopup: action.payload };
        case SET_PATH:
            return { ...state, path: action.payload };
        case SET_ACTIVE_NAME:
            return { ...state, name: action.payload };
        case CLEAR_ACTIVE_MENU:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default drawer;
