import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filter, map } from 'lodash';
import {
    getReturnReasonData,
    initReturnReasonSelect,
} from '../../../redux/actions';

import { Field, Form, reduxForm } from 'redux-form';
import { PSelect } from '../../../components/form';
import { withRouter } from 'react-router-dom';

class ReturnReasonSelect extends Component {
    componentDidMount() {
        this.props.getReturnReasons();
    }
    componentWillUnmount() {
        this.props.init();
    }

    handleReturnReasonChange = async (value) => {
        const { onSubmit } = this.props;

        if (value) {
            if (onSubmit) {
                await onSubmit({ value });
            }
        }
    };

    render() {
        const { loading, content } = this.props;
        return (
            <Form loading={loading}>
                <Field
                    name="returnReasonId"
                    component={PSelect}
                    options={map(
                        filter(content, (reason) => reason.active),
                        (reason) => ({
                            text: reason.returnReasonDesc,
                            value: reason.returnReasonCode,
                        })
                    )}
                    onChange={this.handleReturnReasonChange}
                />
            </Form>
        );
    }
}

const mapStateToProps = ({ returnReasonSelect }) => {
    const { content } = returnReasonSelect;

    return {
        content,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReturnReasons: () => dispatch(getReturnReasonData()),
        init: () => dispatch(initReturnReasonSelect()),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({ form: 'ReturnReasonSelectForm', enableReinitialize: true })(
            ReturnReasonSelect
        )
    )
);
