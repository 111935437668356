import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { GlobalHotKeys } from 'react-hotkeys';
import RetailerNav from '../../components/retailerNav/RetailerNav';
import IdleTimerLogout from '../../components/IdleTimerLogout';
import Routes from '../../Routes';
import NotificationBar from '../../components/notifications/NotificationBar';
import config from '../../constants/config';
import { showCart, createOrder } from '../../redux/actions';
import { toggleSelectRetailerModal } from '../../redux/actions/user/user-retailer';
import { userHasPermission } from '../../constants/utils';
import permissions from '../../constants/permissions';

const { TAKE_A_TOUR } = permissions;

const {
    DASHBOARD_SETTINGS,
    GAMES,
    DASHBOARD,
    ORDER_TAB_PROGRESS,
    ORDER_TAB_PAST,
    REPORTS,
    INVOICE,
    FAQ_PAGE,
    PRIVACY_POLICY_PAGE,
    CONTACT_US_PAGE,
    RETAILER_PLANOGRAM_LIST,
    GETTING_STARTED,
    RETAILER_RETURN_LIST,
} = config.ROUTE_URLS;

const keyMap = {
    LOGOUT: 'alt+s',
    USER_SETTINGS: 'alt+u',
    GAMES: 'alt+g',
    DASHBOARD: 'alt+d',
    ORDER_TAB_PROGRESS: 'alt+o',
    REPORTS: 'alt+r',
    INVOICE: 'alt+i',
    FAQ_PAGE: 'alt+f',
    PRIVACY_POLICY_PAGE: 'alt+v',
    CONTACT_US_PAGE: 'alt+t',
    VIEW_CART: 'alt+c',
    PLACE_ORDER: 'alt+p',
    PLANOGRAMS: 'alt+l',
    GETTING_STARTED: 'alt+w',
    RETURNS: 'alt+b',
    CHANGE_RETAILERS: 'alt+q',
};

class RetailerLayout extends Component {
    componentDidMount() {
        // this._container.focus();
    }
    nav = (path) => {
        this.props.history.push(path);
        if (this.props.toggleCart) {
            this.props.showCart(!this.props.toggleCart);
        }
    };

    placeOrder = async () => {
        const success = await this.props.placeOrder();
        if (success) {
            setTimeout(() => {
                this.nav(ORDER_TAB_PROGRESS);
            }, 1500);
        }
    };

    isDimmerOn = (element) => includes(element.classList, 'dimmed');

    render() {
        const handlers = {
            LOGOUT: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.props.logout();
                }
            },
            USER_SETTINGS: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(DASHBOARD_SETTINGS);
                }
            },
            PLANOGRAMS: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(RETAILER_PLANOGRAM_LIST);
                }
            },
            GAMES: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(GAMES);
                }
            },
            DASHBOARD: (event) => {
                event.preventDefault();
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(DASHBOARD);
                }
            },
            ORDER_TAB_PROGRESS: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(ORDER_TAB_PROGRESS);
                }
            },
            ORDER_TAB_PAST: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(ORDER_TAB_PAST);
                }
            },
            REPORTS: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(REPORTS);
                }
            },
            INVOICE: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(INVOICE);
                }
            },
            FAQ_PAGE: (event) => {
                event.preventDefault();
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(FAQ_PAGE);
                }
            },
            PRIVACY_POLICY_PAGE: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(PRIVACY_POLICY_PAGE);
                }
            },
            CONTACT_US_PAGE: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(CONTACT_US_PAGE);
                }
            },
            VIEW_CART: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.props.showCart(!this.props.toggleCart);
                }
            },
            PLACE_ORDER: () => {
                if (
                    !this.isDimmerOn(document.activeElement) &&
                    this.props.orderedItems.length > 0
                ) {
                    this.placeOrder();
                }
            },
            GETTING_STARTED: () => {
                if (
                    !this.isDimmerOn(document.activeElement) &&
                    userHasPermission(TAKE_A_TOUR)
                ) {
                    this.nav(GETTING_STARTED);
                }
            },
            RETURNS: () => {
                if (!this.isDimmerOn(document.activeElement)) {
                    this.nav(RETAILER_RETURN_LIST);
                }
            },
            CHANGE_RETAILERS: () => {
                this.props.toggleSelectRetailerModal();
            },
        };
        return (
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
                <div>
                    <NotificationBar />
                    <div className="retailer-view AppContainer">
                        <RetailerNav logout={this.props.logout} />
                        <Routes />
                    </div>
                    <IdleTimerLogout />
                </div>
            </GlobalHotKeys>
        );
    }
}

const mapStateToProps = ({ cart: { toggleCart, orderedItems } }) => ({
    toggleCart,
    orderedItems,
});

const mapDispatchToProps = (dispatch) => ({
    showCart: (isShow) => dispatch(showCart(isShow)),
    placeOrder: () => dispatch(createOrder()),
    toggleSelectRetailerModal: () => dispatch(toggleSelectRetailerModal()),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RetailerLayout)
);
