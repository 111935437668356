import React, { Component } from 'react';
import SfaInput from '../SfaInput';
import { required } from '../../constants/validate';

import { Form } from 'semantic-ui-react';
import './styles.css';
import UneditableLabel from '../UneditableLabel';
import SfaSelect from '../SfaSelect';

class ConfigurationManagementForm extends Component {
    handleDateChange = (value, formattedValue, field) => {
        const { configObject } = this.props;
        configObject[field] = formattedValue;
    };

    maskToRender = (config) => {
        const hasOptions =
            config &&
            config.parameterOptions &&
            !!config.parameterOptions.length;

        if (hasOptions) {
            return (
                <SfaSelect
                    labelText="Value *"
                    fieldName="value"
                    options={config.parameterOptions.map((option) => ({
                        label: option.parameterOptionName,
                        value: option.parameterOptionValue,
                    }))}
                    isMulti={false}
                    validations={required}
                    searchable={false}
                />
            );
        }
        return (
            <SfaInput
                inputName="value"
                inputValue={config.value}
                labelText="Value *"
                validations={[required]}
            />
        );
    };

    render() {
        const { configObject } = this.props;

        return (
            <Form.Field className="readonly">
                <UneditableLabel text="Configuration" />
                <div className="config-field-edit">
                    <div className="config-label">
                        <span>{configObject.description} : </span>
                    </div>
                    <div className="value-field">
                        {this.maskToRender(configObject)}
                    </div>
                </div>
            </Form.Field>
        );
    }
}

export default ConfigurationManagementForm;
