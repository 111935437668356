import {
    USER_INFO_LOADING,
    USER_INFO_LOADING_DONE,
    USER_INFO_INIT,
    USER_INFO_GET_RETAILER_INFO,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    retailerInfo: null,
};

const userInfo = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case USER_INFO_INIT:
            return { ...INITIAL_STATE };

        case USER_INFO_LOADING:
            return {
                ...state,
                loading: true,
            };

        case USER_INFO_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case USER_INFO_GET_RETAILER_INFO:
            return {
                ...state,
                retailerInfo: payload,
            };

        default:
            return state;
    }
};
export default userInfo;
