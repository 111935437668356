import {
    RETAILER_ATTACHMENT_INIT,
    RETAILER_ATTACHMENT_INIT_INIT_STATE,
    RETAILER_ATTACHMENT_UPLOAD_LOADING,
    RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE,
    RETAILER_ATTACHMENT_CREATE,
} from '../../actions/retailerAttachments/uploadRetailerAttachment';

const INITIAL_STATE = {
    file: null,
    description: '',
    category: {
        id: 0,
        name: '',
    },
    categoryOptions: [],
    loading: false,
};

const uploadRetailerAttachment = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_ATTACHMENT_UPLOAD_LOADING:
            return { ...state, loading: true };
        case RETAILER_ATTACHMENT_CREATE:
            return { ...state, [action.payload.args]: true };
        case RETAILER_ATTACHMENT_INIT:
            return {
                ...(action.attachment || INITIAL_STATE),
                categoryOptions: action.options,
            };
        case RETAILER_ATTACHMENT_UPLOAD_LOADING_DONE:
            return { ...state, loading: false };
        case RETAILER_ATTACHMENT_INIT_INIT_STATE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default uploadRetailerAttachment;
