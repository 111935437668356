import { Backend } from './backend';

class MapApi {
    constructor() {
        this.path = '/tasks/utr';
    }

    async getOptimizedRoute(apiKey, addressesToOptimize) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/getOptimizedRetailerRoute/${apiKey}`,
                body: addressesToOptimize,
            });
        } catch (e) {
            throw e;
        }
    }

    async getGeoAddresses(apiKey, latitudeLongitude) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/getGeoCode/${apiKey}`,
                body: latitudeLongitude,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateRetailerLastOptimized(retailerIds, date, type) {
        try {
            return await Backend.send({
                method: 'put',
                url: `/retailers/optimize-retailer-by-id/${date}/${type}`,
                body: retailerIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllRetailersLastOptimizedDate(retailerIds) {
        try {
            return await Backend.send({
                method: 'post',
                url: `/retailers/retailer-optimized-date-by-id`,
                body: retailerIds,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { MapApi };
