import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Loader } from 'semantic-ui-react';
import _ from 'lodash';

import {
    addSelectedItem,
    addSingleSelectedItem,
    removeSelectedItem,
    removeAllSelectedItems,
    loadList,
    loadMore,
    updateSearchValue,
    searching,
    postClientSearch,
    searchFilters,
} from '../actions';
import SearchField from '../../SearchField';

class FilterContent extends Component {
    async componentWillMount() {
        await this.initList();
    }

    initList = async () => {
        const { name, items } = this.props;
        if (!_.isArray(items)) {
            await this.props.loadList(items.loader, items.transformer, {
                name,
            });
        } else {
            await this.props.loadList(items, null, { name });
        }
    };

    onCheckboxSelected = (e, name, value) => {
        const action = e.target.checked
            ? this.props.selectItem
            : this.props.deselectItem;

        const { filterItems, selectedItems, maxSelection } = this.props;

        const checkedItems = selectedItems ? selectedItems[name] || [] : [];

        if (
            maxSelection &&
            checkedItems.length >= maxSelection &&
            e.target.checked
        ) {
            return; //do not proceed if the max items are already selected
        }

        const filterContent = filterItems[name];
        if (!filterContent) {
            return; // shouldn't reach here but make check anyway
        }
        action(name, value);
    };

    onRadioSelected = (name, value) => {
        const { filterItems } = this.props;
        const filterContent = filterItems[name];
        if (!filterContent) {
            return; // shouldn't reach here but make check anyway
        }
        this.props.selectSingleItem(name, value);
    };

    onClearAll = () => {
        const { name } = this.props;
        this.props.deselectAllItems(name);
    };

    onSearch = async (value) => {
        this.props.updateSearchValue(this.props.name, value);

        const text = value.toLowerCase();

        if (this.props.client) {
            await this.initList();
        }

        const { client, name, filterItems, items } = this.props;
        const filterContent = filterItems[name];

        if (filterContent) {
            this.props.searching(name);
            setTimeout(() => {
                if (client) {
                    const newList = _.filter(
                        filterContent.content,
                        (c) =>
                            c.text &&
                            c.text.toLowerCase().indexOf(text.toLowerCase()) >=
                                0
                    );
                    const result = [...newList];

                    this.props.clientSearchResults(name, result);
                } else {
                    this.props.searchFilters(items.loader, items.transformer, {
                        name,
                        query: text,
                    });
                }
            }, 2000);
        }
    };

    onLoadMore = (e) => {
        const { client, items, name, filterItems } = this.props;

        if (client) {
            return;
        }

        const scrollTop = e.currentTarget.scrollTop;
        const scrollHeight = e.currentTarget.scrollHeight;
        const clientHeight = e.currentTarget.clientHeight;
        const scrolledToBottom =
            Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        const filterContent = filterItems[name];
        if (filterContent && scrolledToBottom) {
            const { page, totalPages, loading, loadingMore, query } =
                filterContent;

            if (page >= totalPages) {
                return;
            }

            if (!loading && !loadingMore) {
                this.props.loadMore(items.loader, items.transformer, {
                    defaultSelected: null,
                    name,
                    page: filterContent.page + 1,
                    query,
                });
            }
        }
    };

    renderItems = () => {
        const { disabled, name, selectedItems, filterItems } = this.props;

        const filterContent = filterItems[name];

        const itemsToCheck = selectedItems[name] || [];

        const list = filterContent ? filterContent.content || [] : [];

        if (
            filterContent &&
            (filterContent.loading || filterContent.searching)
        ) {
            return (
                <Loader
                    key="loader"
                    active={filterContent.loading || filterContent.searching}
                    inline="centered"
                >
                    {filterContent.searching ? 'Searching...' : 'Loading...'}
                </Loader>
            );
        }

        if (list.length <= 0) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 15,
                    }}
                >
                    No Result Found
                </div>
            );
        }

        const data = _.map(list, ({ text, subText, value, isChecked }) => {
            let checked = isChecked;
            if (!checked) {
                if (typeof value === 'object') {
                    checked = _.some(itemsToCheck, value);
                } else {
                    if (typeof value === 'string') {
                        checked = _.includes(
                            _.map(itemsToCheck, (i) => i.toLowerCase()),
                            value.toLowerCase()
                        );
                    } else {
                        checked = _.includes(
                            _.map(itemsToCheck, (i) => i),
                            value
                        );
                    }
                }
            }

            let className = checked ? 'selected' : '';
            if (subText) {
                className += ' double';
            }

            if (this.props.multiple) {
                return (
                    <div
                        className={`md-checkbox ${className}`}
                        key={`check-${name}-${value}`}
                    >
                        <input
                            id={`check-${name}-${value}`}
                            type="checkbox"
                            value={value}
                            disabled={disabled || false}
                            checked={checked}
                            onChange={(e) =>
                                this.onCheckboxSelected(e, name, value)
                            }
                        />
                        <label htmlFor={`check-${name}-${value}`}>
                            <span>{_.truncate(text, { length: 50 })}</span>
                            {subText && (
                                <span className="caption-text bold secondary-color">
                                    {_.truncate(subText, { length: 50 })}
                                </span>
                            )}
                        </label>
                    </div>
                );
            } else {
                const valueForId =
                    value && typeof value === 'object' && value.id
                        ? value.id
                        : value;

                const uniqueId = `radio-${name}-${valueForId}`;

                return (
                    <div className={`md-radio ${className}`} key={uniqueId}>
                        <input
                            id={uniqueId}
                            name={name}
                            type="radio"
                            value={value}
                            disabled={disabled || false}
                            checked={checked}
                            onChange={() => this.onRadioSelected(name, value)}
                        />
                        <label htmlFor={uniqueId}>
                            <span>{_.truncate(text, { length: 30 })}</span>
                            {subText && (
                                <span className="caption-text bold secondary-color">
                                    {_.truncate(subText, { length: 14 })}
                                </span>
                            )}
                        </label>
                    </div>
                );
            }
        });

        if (filterContent.loadingMore) {
            data.push(
                <Loader
                    key="loader"
                    active={filterContent.loadingMore}
                    inline="centered"
                />
            );
        }

        return data;
    };

    render() {
        const { searchable, containerStyle, toggleAll, name } = this.props;

        let contentHeight = !searchable || !toggleAll ? '80%' : '60%';

        return (
            <div className="filter-content" style={{ ...containerStyle }}>
                {searchable && (
                    <div className="searchable-section">
                        <SearchField
                            value={this.props.searchValues[name]}
                            icon="search"
                            placeholder="Search..."
                            onChange={this.onSearch}
                            onSearchValueChange={(v) =>
                                this.props.updateSearchValue(name, v)
                            }
                            style={{ width: '100%', height: 40 }}
                        />
                    </div>
                )}

                {toggleAll && (
                    <div className="selection-actions">
                        {/*<Button className="transparent-btn selection-actions-all" size="tiny"*/}
                        {/*onClick={this.onSelectAll}>*/}
                        {/*Select All*/}
                        {/*</Button>*/}
                        {/*<span>|</span>*/}
                        <Button
                            className="transparent-btn selection-actions-clear"
                            size="tiny"
                            onClick={this.onClearAll}
                        >
                            Clear
                        </Button>
                    </div>
                )}

                <div
                    style={{
                        marginTop: 5,
                        height: contentHeight,
                        overflowY: 'auto',
                    }}
                    onScroll={this.onLoadMore}
                >
                    {this.renderItems()}
                </div>
            </div>
        );
    }
}

export default connect(
    ({ tableFilter, filters }) => {
        const { name, toggle, selectedItems, clearAll } = tableFilter;
        const { filterItems, searchValues } = filters;
        return {
            filterName: name,
            toggle,
            selectedItems,
            clearAll,
            filterItems,
            searchValues,
        };
    },
    (dispatch) => ({
        selectItem: (name, item) => dispatch(addSelectedItem(name, item)),
        selectSingleItem: (name, item) =>
            dispatch(addSingleSelectedItem(name, item)),
        deselectItem: (name, item) => dispatch(removeSelectedItem(name, item)),
        deselectAllItems: (name) => dispatch(removeAllSelectedItems(name)),
        loadList: (loader, transformer, args) =>
            dispatch(loadList(loader, transformer, args)),
        loadMore: (loader, transformer, args) =>
            dispatch(loadMore(loader, transformer, args)),
        searching: (name) => dispatch(searching(name)),
        clientSearchResults: (name, list, defaultSelected) =>
            dispatch(postClientSearch(name, list, defaultSelected)),
        searchFilters: (loader, transformer, args) =>
            dispatch(searchFilters(loader, transformer, args)),
        updateSearchValue: (name, value) =>
            dispatch(updateSearchValue(name, value)),
    })
)(FilterContent);
