import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';
import config from '../constants/config';

class SfaTransition extends Component {
    state = {
        visible: false,
    };

    componentDidMount() {
        this.setState({ visible: true });
    }

    render() {
        const { animation, duration, children, ...otherProps } = this.props;
        return (
            <Transition
                visible={this.state.visible}
                animation={animation ? animation : 'fade left'}
                duration={
                    duration !== null ? duration : config.ANIMATION_LENGTH
                }
                {...otherProps}
            >
                <div className="word-wrap-with-word-break">{children}</div>
            </Transition>
        );
    }
}

export default SfaTransition;
