import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { MessagesApi } from '../../../services/message-upload-service';

export const MULTIPLE_DOCUMENT_LOADING = 'MULTIPLE_DOCUMENT_LOADING';
export const MULTIPLE_DOCUMENT_LOADING_DONE = 'MULTIPLE_DOCUMENT_LOADING_DONE';
export const ACTIVE_MESSAGE_FILES = 'ACTIVE_MESSAGE_FILES';
export const MULTIPLE_DOCUMENT_INIT_INIT_STATE =
    'MULTIPLE_DOCUMENT_INIT_INIT_STATE';
export const MULTIPLE_DOCUMENT_LIST = 'MULTIPLE_DOCUMENT_LIST';
export const MULTIPLE_DOCUMENT_ATTACHMENT_INIT =
    'MULTIPLE_DOCUMENT_ATTACHMENT_INIT';
export const MULTIPLE_DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE =
    'MULTIPLE_DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE';
export const MULTIPLE_DOCUMENT_ATTACHMENT_UPLOAD_LOADING =
    'MULTIPLE_DOCUMENT_ATTACHMENT_UPLOAD_LOADING';
export const ALL_MESSAGE_FILE = 'ALL_MESSAGE_FILE';
export const GET_EDIT_MESSAGE_FILE = 'GET_EDIT_MESSAGE_FILE';
export const UPDATE_DESCREPTION = 'UPDATE_DESCREPTION';

const messagesApi = new MessagesApi();

const MultipleUploaddocumentError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'MessageListPage',
            })
        );
    };
};

export const uploadMultipleDocument = (
    formData,
    startDate,
    endDate,
    message,
    retailerTypes
) => {
    const files = [];
    [].forEach.call(formData, (file) => {
        files.push(file);
    });
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            success = await messagesApi.uploadPortalDocument(
                files,
                startDate,
                endDate,
                message,
                retailerTypes
            );
        } catch (err) {
            dispatch(MultipleUploaddocumentError(err));
            success = false;
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const updateMultipleDocument = (data) => {
    const { id, description, category } = data;
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            await messagesApi.updateDocument(id, description, category);
        } catch (err) {
            dispatch(MultipleUploaddocumentError(err));
            success = false;
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const deleteMultipleDocument = (attachmentId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            await messagesApi.deleteDocument(attachmentId);
        } catch (err) {
            dispatch(MultipleUploaddocumentError(err));
            success = false;
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const getAllMultipleDocuments = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            const data = await messagesApi.getAllDocuments(args);
            dispatch({
                type: MULTIPLE_DOCUMENT_LIST,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(MultipleUploaddocumentError(err));
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }
    };
};

export const initMultipleDocumentUploadState = () => ({
    type: MULTIPLE_DOCUMENT_INIT_INIT_STATE,
});

export const updateEditedDocument = (
    filesToAdd,
    fileRemoveArray,
    selectedDocument,
    startDate,
    endDate,
    message,
    retailerTypes
) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            await messagesApi.updateDocument(
                filesToAdd,
                fileRemoveArray,
                selectedDocument,
                startDate,
                endDate,
                message,
                retailerTypes
            );
        } catch (err) {
            dispatch(MultipleUploaddocumentError(err));
            success = false;
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const getActiveMessageFilesForRetailerType = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });

            const { userInfo } = getState();

            const reference =
                !!userInfo.retailerInfo && !!userInfo.retailerInfo.type
                    ? userInfo.retailerInfo.type.reference
                    : null;

            if (!!reference) {
                const data =
                    await messagesApi.getActiveMessageFilesForRetailerType(
                        reference
                    );

                dispatch({
                    type: ACTIVE_MESSAGE_FILES,
                    data,
                });
            }
        } catch (err) {
            console.error(err);
            dispatch(MultipleUploaddocumentError(err));
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }
    };
};

export const getAllActiveMessageFiles = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            const data = await messagesApi.getAllActiveMessageFiles();
            dispatch({
                type: ACTIVE_MESSAGE_FILES,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(MultipleUploaddocumentError(err));
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }
    };
};

export const getAllMessageFile = (messageId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            const data = await messagesApi.getAllMessageFile(messageId);
            dispatch({
                type: ALL_MESSAGE_FILE,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(MultipleUploaddocumentError(err));
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }
    };
};

export const getMessageFiles = (fileRemoveArray, messageId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING });
            const data = await messagesApi.getMessageFiles(
                fileRemoveArray,
                messageId
            );
            dispatch({
                type: GET_EDIT_MESSAGE_FILE,
                data,
            });
        } catch (err) {
            dispatch(MultipleUploaddocumentError(err));
            success = false;
        } finally {
            dispatch({ type: MULTIPLE_DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const updateDescreption = (data) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_DESCREPTION,
            data,
        });
    };
};
