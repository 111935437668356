export const ERROR_HANDLER_ERROR = 'ERROR_HANDLER_ERROR';
export const ERROR_HANDLER_CLEAR = 'ERROR_HANDLER_CLEAR';

export const errorHandler = ({ msg, target }) => ({
    type: ERROR_HANDLER_ERROR,
    msg,
    target,
});

export const clearErrorHandler = () => ({
    type: ERROR_HANDLER_CLEAR,
});
