import { ZONE_CREATE_LOADING, ZONE_CREATE_LOADING_DONE } from '../../actions';

const INITIAL_STATE = {
    loading: false,
};

const createZones = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ZONE_CREATE_LOADING:
            return { ...state, loading: true };
        case ZONE_CREATE_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default createZones;
