import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { forEach } from 'lodash';
import Page from '../../layout/Page';
import SfaForm from '../../components/SfaForm';
import SfaTextArea from '../../components/SfaTextArea';
import SfaInput from '../../components/SfaInput';
import SfaCheckbox from '../../components/sfaCheckbox/SfaCheckbox';
import { isValidEmail } from '../../constants/validate';
import contactUs from '../../constants/contact-us';
import {
    initContactUsConfig,
    getContactUsConfig,
    saveContactUsConfig,
    success,
} from '../../redux/actions';
import OkCancelModal from '../../components/OkCancelModal';
import ReduxFormMaskInput from '../../components/reduxFormMaskInput/ReduxFormMaskInput';
import { getPhoneFaxMask } from '../../constants/utils';

const { MAIL_ADDRESS, PHONE_1, PHONE_2, FAX, EMAIL } = contactUs;

export class ContactUsConfigurationPage extends Component {
    state = {
        showModal: false,
        showConfirmation: false,
    };

    componentDidMount() {
        this.props.getData();
    }

    componentWillUnmount() {
        this.props.init();
    }

    handleSubmit = (values) => {
        const { saveData } = this.props;
        this.closeConfirmationModal();
        let formattedValues = [];
        if (values[MAIL_ADDRESS]) {
            formattedValues.push({
                name: MAIL_ADDRESS,
                value: values[MAIL_ADDRESS],
                isPublic: !!values[`${MAIL_ADDRESS}-public`],
            });
        }

        if (values[PHONE_1]) {
            formattedValues.push({
                name: PHONE_1,
                value: values[PHONE_1],
                isPublic: !!values[`${PHONE_1}-public`],
            });
        }

        if (values[PHONE_2]) {
            formattedValues.push({
                name: PHONE_2,
                value: values[PHONE_2],
                isPublic: !!values[`${PHONE_2}-public`],
            });
        }

        if (values[FAX]) {
            formattedValues.push({
                name: FAX,
                value: values[FAX],
                isPublic: !!values[`${FAX}-public`],
            });
        }

        if (values[EMAIL]) {
            formattedValues.push({
                name: EMAIL,
                value: values[EMAIL],
                isPublic: !!values[`${EMAIL}-public`],
            });
        }

        saveData(formattedValues);
    };

    openResetModal = () => this.setState({ showModal: true });
    closeResetModal = () => this.setState({ showModal: false });
    showConfirmationModal = () => this.setState({ showConfirmation: true });
    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    resetform = () => {
        this.props.resetForm();
        this.props.notifySuccess(
            'Successfully reset contact us configuration form'
        );
        this.closeResetModal();
    };

    render() {
        const { handleSubmit, pristine, loading, formData } = this.props;
        const phoneFaxMask = getPhoneFaxMask();
        const { showConfirmation } = this.state;

        return (
            <Page
                title="Contact Us Configuration"
                data-test="contactusConfiguration"
            >
                <OkCancelModal
                    isOpen={this.state.showModal}
                    header="Reset Form"
                    body="Do you want to undo all contact us configuration changes?"
                    okText="Yes"
                    cancelText="No"
                    okFunction={this.resetform}
                    cancelFunction={this.closeResetModal}
                />
                <SfaForm
                    name="UpdateContactUsInfo"
                    onSubmit={handleSubmit(this.showConfirmationModal)}
                    data-test="sfaform"
                    onCancel={this.openResetModal}
                    showSaveCancel={true}
                    submitButtonText="Save"
                    cancelButtonText="Reset"
                    submitIsDisabled={pristine}
                    loading={loading}
                >
                    <div>
                        <OkCancelModal
                            header={`Save Contact Us?`}
                            body={`Are you sure you want to save the changes?`}
                            cancelFunction={this.closeConfirmationModal}
                            okFunction={handleSubmit(this.handleSubmit)}
                            isOpen={showConfirmation}
                            okText={'Save'}
                        />
                        <Form.Group widths="equal">
                            <Form.Field>
                                <SfaTextArea
                                    textAreaName={MAIL_ADDRESS}
                                    labelText="Mailing Address"
                                />
                                <SfaCheckbox
                                    name={`${MAIL_ADDRESS}-public`}
                                    label="Is Public"
                                    checked={formData[`${MAIL_ADDRESS}-public`]}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Field>
                                <ReduxFormMaskInput
                                    name={PHONE_1}
                                    mask={phoneFaxMask}
                                    label="Phone 1"
                                />
                                <SfaCheckbox
                                    name={`${PHONE_1}-public`}
                                    label="Is Public"
                                    checked={formData[`${PHONE_1}-public`]}
                                />
                            </Form.Field>
                            <Form.Field>
                                <ReduxFormMaskInput
                                    name={PHONE_2}
                                    mask={phoneFaxMask}
                                    label="Phone 2"
                                />
                                <SfaCheckbox
                                    name={`${PHONE_2}-public`}
                                    label="Is Public"
                                    checked={formData[`${PHONE_2}-public`]}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="two">
                            <Form.Field>
                                <ReduxFormMaskInput
                                    name={FAX}
                                    mask={phoneFaxMask}
                                    label="Fax"
                                />
                                <SfaCheckbox
                                    name={`${FAX}-public`}
                                    label="Is Public"
                                    checked={formData[`${FAX}-public`]}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <SfaInput
                                    inputName={EMAIL}
                                    labelText="Email"
                                    validations={isValidEmail}
                                />
                                <SfaCheckbox
                                    name={`${EMAIL}-public`}
                                    label="Is Public"
                                    checked={formData[`${EMAIL}-public`]}
                                />
                            </Form.Field>
                        </Form.Group>
                    </div>
                </SfaForm>
            </Page>
        );
    }
}

const selector = formValueSelector('UpdateContactUsInfo'); // <-- same as form name
ContactUsConfigurationPage = connect((state) => {
    const values = selector(
        state,
        `${MAIL_ADDRESS}-public`,
        `${PHONE_1}-public`,
        `${PHONE_2}-public`,
        `${FAX}-public`,
        `${EMAIL}-public`
    );
    return {
        formData: { ...values },
    };
})(ContactUsConfigurationPage);

const mapStateToProps = ({ contactUsConfiguration: { loading, config } }) => {
    let initialValues = {};
    forEach(config, ({ name, value, isPublic }) => {
        initialValues[name] = value;
        initialValues[`${name}-public`] = !!isPublic;
    });

    return {
        loading,
        initialValues,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(initContactUsConfig()),
        getData: () => dispatch(getContactUsConfig()),
        saveData: (values) => dispatch(saveContactUsConfig(values)),
        resetForm: () => dispatch(reset('UpdateContactUsInfo')),
        notifySuccess: (msg) => dispatch(success({ msg })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({ form: 'UpdateContactUsInfo', enableReinitialize: true })(
        ContactUsConfigurationPage
    )
);
