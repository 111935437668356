import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { indexOf } from 'lodash';

import {
    updateDocument,
    uploadDocument,
    initDocumentAttachmentUpload,
    initDocumentUploadState,
    success,
    error,
    clearNotification,
    updateDescreption,
    getRetailerTypeOptions,
} from '../../redux/actions';
import SfaForm from '../SfaForm';

import FileSelector from '../FileSelector';
import CalendarPicker from '../CalendarPicker';
import {
    required,
    maxLength,
    maxUploadFileSize,
    validFileExtensionForMessage,
} from '../../constants/validate';
import { Checkbox } from 'semantic-ui-react';
import SfaInput from '../SfaInput';
import moment from 'moment';
import CheckboxGroup from '../form/checkboxGroup/checkboxGroup';
import permissions from '../../constants/permissions';

export class UploadMultipleDocuments extends Component {
    state = {
        startDate: null,
        endDate: null,
        startDateError: false,
        endDateError: false,
        message: null,
        fileRemoveArray: [],
        undoCancel: false,
        retailerTypes: [],
    };

    constructor(props) {
        super(props);
        const {
            documentInfo,
            fileRemoveArray,
            startingDate,
            endingDate,
            docMessage,
        } = props;

        if (documentInfo) {
            this.state = {
                startDate: startingDate ? startingDate : documentInfo.startDate,
                endDate: endingDate ? endingDate : documentInfo.endDate,
                fileRemoveArray: fileRemoveArray ? fileRemoveArray : [],
                startDateError: false,
                endDateError: false,
                message: docMessage ? docMessage : null,
                undoCancel: false,
                retailerTypes: documentInfo.retailerTypes,
            };

            if (!docMessage) {
                props.initDocumentUpload(documentInfo);
            }
        } else {
            props.initDocumentUpload();
        }
        this.props.updateDescreption(this.state.message);
    }

    componentWillUnmount = () => {
        this.props.initDocumentUploadState();
    };
    _endDatebeforeStartDate = (startDate, endDate) => {
        return moment(startDate) > moment(endDate);
    };

    handleDateChange = (value, formattedValue, field) => {
        if (field === 'startDate') {
            this.setState({
                startDate: formattedValue,
                startDateError: this._endDatebeforeStartDate(
                    formattedValue,
                    this.state.endDate
                ),
                endDateError: false,
            });
        }
        if (field === 'endDate') {
            this.setState({
                endDate: formattedValue,
                startDateError: false,
                endDateError: this._endDatebeforeStartDate(
                    this.state.startDate,
                    formattedValue
                ),
            });
        }
    };

    uploadDocument = (values) => {
        const { description, ShowImageCarouselPreview } = this.props;
        this.setState({
            message: values.message,
        });
        const { endDate, startDate, fileRemoveArray } = this.state;

        ShowImageCarouselPreview(
            values,
            startDate,
            endDate,
            description,
            fileRemoveArray
        );
    };
    handleremovefile = (value, formattedValue, e, data) => {
        if (formattedValue.checked) {
            this.setState({
                fileRemoveArray: this.state.fileRemoveArray.concat(
                    formattedValue.children
                ),
            });
        } else {
            this.setState({
                fileRemoveArray: this.state.fileRemoveArray.filter(
                    (e) => e !== formattedValue.children
                ),
            });
        }
    };
    DocumentSaveConfirmation = (values) => {
        const { DocumentUpdateSaveConfirmation } = this.props;

        this.setState({
            message: values.description,
            undoCancel: values.undoCancel,
        });
        const { endDate, startDate, fileRemoveArray, undoCancel } = this.state;

        DocumentUpdateSaveConfirmation(
            values,
            startDate,
            endDate,
            values.description,
            fileRemoveArray,
            undoCancel
        );
    };
    editAttachment = (values) => {
        const { DocumentUpdateConfirmation } = this.props;

        this.setState({
            message: values.description,
            undoCancel: values.undoCancel,
        });
        const { endDate, startDate, fileRemoveArray, undoCancel } = this.state;

        DocumentUpdateConfirmation(
            values,
            startDate,
            endDate,
            values.description,
            fileRemoveArray,
            undoCancel
        );
    };

    render() {
        const {
            loading,
            description,
            handleSubmit,
            returnToList,
            documentInfo,
            onDelete,
            retailerTypeOptions,
        } = this.props;

        const { startDate, endDate, fileRemoveArray, message } = this.state;

        let fileUploadValidations = [
            maxUploadFileSize,
            validFileExtensionForMessage,
        ];
        if (!documentInfo) {
            fileUploadValidations.push(required);
        }

        return (
            <SfaForm
                name="UploadAttachmentForm"
                className="horizontal-line"
                onSubmit={
                    onDelete
                        ? handleSubmit(this.DocumentSaveConfirmation)
                        : handleSubmit(this.uploadDocument)
                } //if have delete must be edit
                headerText={documentInfo ? 'Update Message' : 'Create Message'}
                onCancel={returnToList}
                showSaveCancel={true}
                submitIsDisabled={
                    !(this.state.startDate && this.state.endDate) ||
                    this.state.endDateError ||
                    this.state.startDateError
                }
                deleteButtonRoles={[permissions.DOCUMENT_CREATE]}
                deleteActionButtonText={'Preview'}
                submitButtonText={'Save'}
                deleteAction={
                    documentInfo ? handleSubmit(this.editAttachment) : ''
                }
                deleteActionButtonClass={'transparent-btn preview-btn'}
                loading={loading}
                data-test="uploadMultipleDocument"
            >
                <Field
                    component={FileSelector}
                    type="file"
                    name="file"
                    labelText="Files *"
                    accept="image/jpeg, image/png"
                    multiple={true}
                    helpText="File types allowed: image/jpeg, image/png. Maximum file size: 10MB"
                    validate={fileUploadValidations}
                />

                <SfaInput
                    inputValue={message ? message : description}
                    inputName="description"
                    labelText="Message Name *"
                    type="text"
                    validations={[required, maxLength]}
                />

                <CalendarPicker
                    value={startDate}
                    name="startDate"
                    handleChange={this.handleDateChange}
                    labelText="Start Date *"
                />
                {this.state.startDateError && (
                    <span className="error-text">
                        <i>Start Date cannot be after End Date</i>
                    </span>
                )}

                <CalendarPicker
                    value={endDate}
                    name="endDate"
                    handleChange={this.handleDateChange}
                    labelText="End Date *"
                />
                {this.state.endDateError && (
                    <span className="error-text">
                        <i>End Date cannot be before Start Date</i>
                    </span>
                )}

                <CheckboxGroup
                    groupLabelText="Retailer Types"
                    name="retailerTypes"
                    options={retailerTypeOptions}
                />

                {documentInfo &&
                    documentInfo.messageDocuments &&
                    documentInfo.messageDocuments.length > 0 && (
                        <p>Select files to remove:</p>
                    )}
                {documentInfo &&
                    documentInfo.messageDocuments &&
                    documentInfo.messageDocuments.map((messageDocument) => {
                        return (
                            <div key={messageDocument.id}>
                                <ul style={{ paddingLeft: 0 }}>
                                    <li style={{ listStyleType: 'none' }}>
                                        <Checkbox
                                            key={messageDocument.id}
                                            label={messageDocument.fileName}
                                            onChange={this.handleremovefile}
                                            checked={
                                                indexOf(
                                                    fileRemoveArray,
                                                    messageDocument.id
                                                ) > -1
                                            }
                                        >
                                            {messageDocument.id}
                                        </Checkbox>
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
            </SfaForm>
        );
    }
}

const mapStateToProps = (
    { documentUpload, retailerTypeOptions: { options } },
    { documentInfo }
) => {
    return {
        ...documentUpload,
        retailerTypeOptions: options,
        initialValues: {
            file: documentUpload.file || documentInfo?.file,
            description:
                documentUpload.description || documentInfo?.description,
            startDate: documentUpload.startDate || documentInfo?.startDate,
            endDate: documentUpload.endDate || documentInfo?.endDate,
            retailerTypes:
                documentUpload.retailerTypes || documentInfo?.retailerTypes,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadDocument: (formData, documentType) =>
            dispatch(uploadDocument(formData, documentType)),
        updateDocument: (attachmentData) =>
            dispatch(updateDocument(attachmentData)),
        initDocumentUploadState: () => dispatch(initDocumentUploadState()),
        initDocumentUpload: (documentInfo) => {
            dispatch(initDocumentAttachmentUpload(documentInfo));
            dispatch(getRetailerTypeOptions());
        },
        updateDescreption: (data) => dispatch(updateDescreption(data)),
        successMsg: ({ msg, target }) => dispatch(success({ msg, target })),
        errorMsg: ({ msg, target }) => dispatch(error({ msg, target })),
        clearNotification: () => dispatch(clearNotification()),
    };
};

UploadMultipleDocuments.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({ form: 'UploadAttachmentForm', enableReinitialize: true })(
            UploadMultipleDocuments
        )
    )
);
