import {
    GET_RETAILER_GROUP,
    EditRetailerGroupActionTypes,
    EditRetailerGroupStateType,
    UPDATE_LOADING_STATE,
} from '../../type/reatiler-group/edit-reatiler-group-type';

const initialState: EditRetailerGroupStateType = {
    isLoading: false,
    retailerGroup: undefined,
};
const editRetailerGroupReducer = (
    state = initialState,
    action: EditRetailerGroupActionTypes
) => {
    switch (action.type) {
        case GET_RETAILER_GROUP:
            return {
                ...state,
                isLoading: false,
                retailerGroup: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};
export default editRetailerGroupReducer;
