import React, { Component } from 'react';
import { Checkbox, Table } from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames';

const RolesAndPermissionsTableCell = ({
    permissionId,
    roleId,
    readOnly,
    onCheckedChange,
    checked,
    ...props
}) => {
    if (readOnly) {
        return (
            <span
                className={classNames('blue-text', { 'icon-Success': checked })}
            />
        );
    } else {
        return (
            <Checkbox
                checked={checked}
                {...props}
                onChange={(e, { checked }) =>
                    onCheckedChange(permissionId, roleId, checked)
                }
            />
        );
    }
};

class RolesAndPermissionsTable extends Component {
    handlePermissionCheckedChanged = (permissionId, roleId, checked) => {
        this.props.handlePermissionCheckedChanged({
            permissionId,
            roleId,
            checked,
        });
    };

    handlePermissionGroupCheckedChanged = (permissionId, roleId, checked) => {
        this.props.handlePermissionGroupCheckedChanged({
            groupId: permissionId,
            roleId,
            checked,
        });
    };

    onRoleHeaderClicked = (roleId) => {
        this.props.onRoleHeaderClicked(roleId);
    };

    render() {
        let { roles, permissions, readOnly } = this.props;
        let tableHeaderCells = [];
        let tableDataRows = [];

        let permissionGroups = _.uniqBy(
            _.map(permissions, (permission) => {
                return permission.group;
            }),
            'id'
        );
        // Prepare Header cells
        _.forEach(roles, (role) => {
            tableHeaderCells.push(
                <Table.HeaderCell key={role.name} className="blue-text">
                    <span>
                        {role.displayName}
                        <span>{!role.isEnabled && ' (inactive)'}</span>
                    </span>
                </Table.HeaderCell>
            );
        });

        const sortedGroups = _.sortBy(
            _.filter(permissionGroups, (permission) => {
                return permission.id !== 0;
            }),
            (props) => props.id
        );

        //Prepare Body rows
        _.forEach(sortedGroups, (permissionGroup) => {
            let groupPermissions = _.filter(permissions, (permission) => {
                return permission.group.id === permissionGroup.id;
            });

            let permissionGroupRowCells = [];
            permissionGroupRowCells.push(
                <Table.Cell key={'permissionGroup-' + permissionGroup.name}>
                    {permissionGroup.name}
                </Table.Cell>
            );

            _.forEach(roles, (role) => {
                let permissionsAlreadyPresent = _.filter(role.permissions, [
                    'group.id',
                    permissionGroup.id,
                ]);
                permissionGroupRowCells.push(
                    <Table.Cell key={permissionGroup.name + '-' + role.name}>
                        <RolesAndPermissionsTableCell
                            permissionId={permissionGroup.id}
                            data-test="rolesTable"
                            roleId={role.id}
                            readOnly={readOnly}
                            checked={_.size(permissionsAlreadyPresent) !== 0}
                            indeterminate={
                                !!_.size(permissionsAlreadyPresent) &&
                                _.size(groupPermissions) !==
                                    _.size(permissionsAlreadyPresent)
                            }
                            onCheckedChange={
                                this.handlePermissionGroupCheckedChanged
                            }
                        />
                    </Table.Cell>
                );
            });

            tableDataRows.push(
                <Table.Row
                    className="permission-group"
                    key={permissionGroup.name}
                >
                    {permissionGroupRowCells}
                </Table.Row>
            );

            _.forEach(
                _.sortBy(groupPermissions, 'displayName'),
                (permission) => {
                    //Prepare row cells
                    let tableDataRowCells = [];
                    tableDataRowCells.push(
                        <Table.Cell key={'permission-' + permission.name}>
                            {permission.displayName}
                        </Table.Cell>
                    );

                    _.forEach(roles, (role) => {
                        tableDataRowCells.push(
                            <Table.Cell key={permission.name + '-' + role.name}>
                                <RolesAndPermissionsTableCell
                                    permissionId={permission.id}
                                    roleId={role.id}
                                    readOnly={readOnly}
                                    checked={
                                        !!_.find(role.permissions, [
                                            'id',
                                            permission.id,
                                        ])
                                    }
                                    onCheckedChange={
                                        this.handlePermissionCheckedChanged
                                    }
                                />
                            </Table.Cell>
                        );
                    });

                    tableDataRows.push(
                        <Table.Row key={permission.name}>
                            {tableDataRowCells}
                        </Table.Row>
                    );
                }
            );
        });

        return (
            <div data-test="RolesAndPermissionsTable">
                <Table
                    singleLine
                    unstackable
                    compact
                    className="roles-permissions-table"
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Permissions</Table.HeaderCell>
                            {tableHeaderCells}
                        </Table.Row>
                    </Table.Header>
                </Table>
                <div className="table-body-div">
                    <Table
                        singleLine
                        unstackable
                        compact
                        className="roles-permissions-table"
                    >
                        <Table.Header>
                            <Table.Row className="collapsed ">
                                <Table.HeaderCell>Permissions</Table.HeaderCell>
                                {tableHeaderCells}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{tableDataRows}</Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

export default RolesAndPermissionsTable;
