import {
    UPDATE_RETAILER_POS_LOADING,
    UPDATE_RETAILER_POS_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    processing: false,
};

const updateRetailerPos = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_RETAILER_POS_LOADING:
            return { ...state, loading: true };
        case UPDATE_RETAILER_POS_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        default:
            return state;
    }
};
export default updateRetailerPos;
