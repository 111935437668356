import Page from '../../layout/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import permissions from '../../constants/permissions';
import PendingReturnsSearchForm from './components/PendingReturnsSearchForm';
import { formatToDisplayDate, hasRole } from '../../constants/utils';
import _ from 'lodash';
import FilterSection from '../../components/filter/FilterSection';
import {
    updateSortDetails,
    clearSearch,
} from '../../components/datatable/actions';
import {
    getAllReturns,
    initReturns,
} from '../../redux/actions/returns/returns';
import { cancelReturn } from '../../redux/actions/returns/cancelReturn';
import { displayCreateReturnModal } from '../../redux/actions/returns/createReturn';
import { ConfirmationModal } from '../../components/notifications/confirmationModal';
import { printReturnByExternalReturnNumberNumber } from '../../redux/actions/returns/print-return';
import { ResponsiveTable } from '../../components/datatable/responsive-table/responsive-table';
import config from '../../constants/config';

const TOTAL_PER_PAGE = 10;
const DEFAULT_START_PAGE = 0;
const DEFAULT_SORT_COLUMN = 'externalReturnNumber';
const SORT_ORDER = 'DESC';

const DEFAULT_SEARCH_MODEL = {
    page: DEFAULT_START_PAGE,
    size: TOTAL_PER_PAGE,
    sortColumn: DEFAULT_SORT_COLUMN,
    sortOrder: SORT_ORDER,
    filters: [],
};

class ReturnsListPage extends Component {
    state = {
        searchModel: DEFAULT_SEARCH_MODEL,
        isFiltered: false,
        showCancelModal: false,
        pendingReturn: null,
        appliedFilters: {},
        searchValue: {},
        sortColumn: DEFAULT_SORT_COLUMN,
        sortOrder: SORT_ORDER,
        page: DEFAULT_START_PAGE,
    };
    componentDidMount() {
        this.initReturns();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            this.initReturns();
        }
    }

    componentWillUnmount() {
        this.props.init();
    }

    initReturns() {
        this.props.init();

        this.search();
        this.props.updateSortDetails({
            sortColumnName: DEFAULT_SORT_COLUMN,
            ascending: false,
        });
    }

    handleSearch = async (searchValue) => {
        await this.setState({ searchValue, page: 0 });
        this.search();
    };

    search = () => {
        const { activeRetailer } = this.props;
        const { appliedFilters, searchValue, page, sortColumn, sortOrder } =
            this.state;
        let searchModel = _.cloneDeep(DEFAULT_SEARCH_MODEL);

        searchModel.filters.push({
            property: 'retailerReferences',
            data: [activeRetailer?.userRetailerId?.retailerReference],
        });
        if (appliedFilters.uiStatus) {
            searchModel.filters.push({
                property: 'uiStatus',
                data: appliedFilters.uiStatus,
            });
        }
        if (appliedFilters.completedDateRange) {
            searchModel.filters.push({
                property: 'completedDateRange',
                data: appliedFilters.completedDateRange,
            });
        }
        if (appliedFilters.returnDateRange) {
            searchModel.filters.push({
                property: 'returnDateRange',
                data: appliedFilters.returnDateRange,
            });
        }
        if (searchValue) {
            Object.keys(searchValue).forEach((key) => {
                if (key === 'returnDate') {
                    searchModel.filters.push({
                        property: 'from',
                        data: [searchValue.returnDate.from],
                    });
                    searchModel.filters.push({
                        property: 'to',
                        data: [searchValue.returnDate.to],
                    });
                } else {
                    searchModel.filters.push({
                        property: key,
                        data: [searchValue[key]],
                    });
                }
            });
        }
        searchModel.page = page;
        searchModel.sortColumn = sortColumn;
        searchModel.sortOrder = sortOrder;

        this.props.search(searchModel);
    };

    handleSelectTablePage = async ({ page, sortOrder, sortColumn }) => {
        await this.setState({ page, sortOrder, sortColumn });
        this.search();
    };
    handlePageChange = async (page) => {
        page = page < 1 ? 0 : page - 1;
        await this.setState({ page });
        this.search();
    };
    onFilterTabChanged = async () => {
        await this.setState({ searchValue: {}, page: 0 });
        this.search();
    };

    handleFilterApply = async (appliedFilters) => {
        await this.setState({ appliedFilters, page: 0 });
        this.search();
    };

    handleClearFilters = async () => {
        await this.setState({ appliedFilters: {}, page: 0 });
        this.search();
    };

    handleCancel = async () => {
        const { pendingReturn } = this.state;
        this.closeCancelModal();
        await this.props.cancelReturn(pendingReturn);
        this.handleSearch();
    };

    showCancelModal = (returnId) => {
        const pendingReturn = _.find(this.props.content, { id: returnId });
        this.setState({
            showCancelModal: true,
            pendingReturn: pendingReturn,
        });
    };
    createReturn = () => {
        this.props.history.push(config.ROUTE_URLS.RETAILER_RETURN_CREATE);
    };
    closeCancelModal = () => {
        this.setState({
            showCancelModal: false,
            return: '',
        });
    };

    openViewReturnModal = (returnId) => {
        this.props.history.push(
            `${config.ROUTE_URLS.RETAILER_RETURN_CREATE}/${returnId}`
        );
    };
    printReturn = (returnId) => {
        const { printReturn } = this.props;
        printReturn(returnId);
    };
    render() {
        const {
            loading,
            content,
            totalPages,
            totalElements,
            size,
            number,
            userInfo,
            cancelReturnInProgress,
        } = this.props;

        const { showCancelModal, pendingReturn } = this.state;

        let actionsMenu = [];
        actionsMenu.push({
            text: 'Print',
            handleClickParams: 'externalReturnNumber',
            handleClick: this.printReturn,
            canShow: (data) =>
                data?.uiStatus?.code === 'U' || data?.uiStatus?.code === 'A',
        });
        if (hasRole(userInfo.authorities, permissions.ROLE_RETURN_CANCEL))
            actionsMenu.push({
                text: 'Cancel',
                handleClickParams: 'id',
                handleClick: this.showCancelModal,
                canShow: (data) => data?.uiStatus?.code === 'U',
            });

        if (hasRole(userInfo.authorities, permissions.ROLE_RETURN_VIEW))
            actionsMenu.push({
                text: 'View Detail',
                handleClickParams: 'externalReturnNumber',
                handleClick: this.openViewReturnModal,
            });

        if (showCancelModal) {
            const modalActions = [
                { onClick: this.closeCancelModal, text: 'No' },
                { onClick: this.handleCancel, text: 'Yes' },
            ];
            const modalMessage = (
                <span className="cancel-modal-message">
                    Return Number:{' '}
                    {_.padStart(pendingReturn.externalReturnNumber, 6, '0')}
                </span>
            );
            const modalContent = (
                <div className="cancel-modal-content">
                    Are you sure you want to cancel this return?
                </div>
            );
            return (
                <ConfirmationModal
                    isOpen={true}
                    headerContent="Cancel Return?"
                    modalMessage={modalMessage}
                    actions={modalActions}
                    bodyContent={modalContent}
                    size={'small'}
                />
            );
        }

        return (
            <Page
                name="Returns"
                title="Returns"
                addButtonRoles={[permissions.ROLE_RETURN_CREATE]}
                onAddButtonClick={this.createReturn}
                class={'return-list-page'}
            >
                <FilterSection
                    filters={[
                        {
                            label: 'Status: ',
                            name: 'uiStatus',
                            multiple: true,
                            toggleAll: true,
                            client: true,
                            searchable: true,
                            items: [
                                { text: 'Pending Approval', value: 'U' },
                                { text: 'Approved', value: 'A' },
                                { text: 'Processed', value: 'P' },
                                { text: 'Canceled', value: 'C' },
                            ],
                        },
                        {
                            label: 'Return Date Range: ',
                            name: 'returnDateRange',
                            isDateRange: true,
                            clearable: true,
                            autoComplete: 'off',
                        },
                        {
                            label: 'Completed Date Range: ',
                            name: 'completedDateRange',
                            isDateRange: true,
                            clearable: true,
                            autoComplete: 'off',
                        },
                    ]}
                    onApplyFilter={this.handleFilterApply}
                    onClearFilters={this.handleClearFilters}
                />
                <PendingReturnsSearchForm
                    onSubmit={this.handleSearch}
                    onFilterTabChanged={this.onFilterTabChanged}
                />
                <div>
                    <ResponsiveTable
                        contentKey="id"
                        supressSearch={true}
                        tableEntityName="pending return"
                        loading={loading || cancelReturnInProgress}
                        content={content}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        noDataMessage={'There are no returns.'}
                        size={size}
                        page={number}
                        columns={[
                            {
                                name: 'externalReturnNumber',
                                title: 'Return No.',
                                sortable: true,
                                formatter: (value) => _.padStart(value, 6, '0'),
                                mobileViewOrder: 1,
                            },
                            {
                                name: 'uiStatus.description',
                                title: 'Status',
                                sortable: true,
                                mobileViewOrder: 2,
                            },
                            {
                                name: 'orderDate',
                                title: 'Return Date',
                                sortable: true,
                                formatter: (value) =>
                                    value ? formatToDisplayDate(value) : '',
                                mobileViewOrder: 3,
                            },
                            {
                                name: 'completedDate',
                                title: 'Completed Date',
                                sortable: true,
                                formatter: (value) =>
                                    value ? formatToDisplayDate(value) : '',
                                mobileViewOrder: 4,
                            },
                            {
                                name: 'returnReason.returnReasonDesc',
                                title: 'Return Reason',
                                sortable: true,
                                mobileViewOrder: 5,
                            },
                        ]}
                        selector={
                            hasRole(
                                userInfo.authorities,
                                permissions.ROLE_RETURN_VIEW
                            ) && {
                                type: 'allowwithactionsmenu',
                                variables: ['externalReturnNumber'],
                                callback: (path) => {
                                    this.openViewReturnModal(
                                        path.split('/')[1]
                                    );
                                },
                            }
                        }
                        actionsMenu={actionsMenu}
                        fetchData={this.handleSelectTablePage}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({
    returns,
    auth: { info },
    userRetailer: { activeRetailer },
    tableFilter,
    cancelReturn: { loading: cancelReturnInProgress },
}) => {
    const { content, loading, totalPages, totalElements, size, number } =
        returns;
    return {
        content,
        loading,
        totalPages,
        totalElements,
        size,
        number,
        selectedItems: tableFilter,
        userInfo: info,
        activeRetailer,
        cancelReturnInProgress,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        search: (args) => dispatch(getAllReturns(args)),
        init: () => dispatch(initReturns()),
        updateSortDetails: (args) => dispatch(updateSortDetails(args)),
        clearSearch: () => dispatch(clearSearch()),
        displayCreateReturnModal: (shouldDisplay) =>
            dispatch(displayCreateReturnModal(shouldDisplay)),
        cancelReturn: (returnOrder) => dispatch(cancelReturn(returnOrder)),
        printReturn: (exteranlReturnId) =>
            dispatch(printReturnByExternalReturnNumberNumber(exteranlReturnId)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReturnsListPage)
);
