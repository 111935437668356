import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message, Icon } from 'semantic-ui-react';

import { clearNotification } from '../../redux/actions/index';
import './NotificationBar.css';
import { isArray } from 'lodash';
import _ from 'lodash';

const MESSAGE_TYPE = {
    warning: {
        icon: 'info circle',
    },
    error: {
        icon: 'warning sign',
    },
    success: {
        icon: 'checkmark',
    },
    queueInfo: {
        icon: 'info circle',
    },
};

class NotificationBar extends Component {
    componentWillReceiveProps(nextProps) {
        const { msg, notificationDisplayTime } = this.props;
        const notificationTimeOut = notificationDisplayTime
            ? Number(notificationDisplayTime) * 1000
            : 3000;
        if (
            nextProps.type !== null &&
            nextProps.type !== 'error' &&
            nextProps.msg !== msg
        ) {
            clearTimeout(this.notificationTimer);
            this.notificationTimer = setTimeout(() => {
                this.props.dismiss();
            }, notificationTimeOut);
        } else if (nextProps.type === 'error') {
            clearTimeout(this.notificationTimer);
        }
    }

    onDismiss = () => {
        clearTimeout(this.notificationTimer);
        this.props.dismiss();
    };

    render() {
        const { type, msg } = this.props;

        if (!msg && !type) {
            return null;
        }

        let icon;
        let messageProps = {};
        if (type && type !== 'multiNotification') {
            const message = MESSAGE_TYPE[type];
            icon = message.icon;

            if (type !== 'queueInfo') {
                messageProps = { [type]: true };
            }
        }

        let isList = isArray(msg);

        return (
            <div className="notification-bar word-wrap-with-word-break">
                {isList ? (
                    msg.map((message) => {
                        let messageProps = { [message.type]: true };
                        let icon = MESSAGE_TYPE[message.type].icon;
                        return (
                            <Message
                                icon
                                {...messageProps}
                                onDismiss={this.onDismiss}
                            >
                                <Icon name={icon} />
                                <Message.Content>
                                    {message.message}
                                </Message.Content>
                            </Message>
                        );
                    })
                ) : (
                    <Message icon {...messageProps} onDismiss={this.onDismiss}>
                        <Icon name={icon} />
                        <Message.Content>{msg}</Message.Content>
                    </Message>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ notifications, organization: { configs } }) => {
    const { target, type, msg } = notifications;
    return {
        target,
        type,
        msg,
        notificationDisplayTime: _.find(configs, {
            name: 'notification_display_time',
        })?.value,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dismiss: () => dispatch(clearNotification()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
