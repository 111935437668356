import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Transition } from 'semantic-ui-react';
import config from '../../constants/config';
import './semanticFormField.css';

const animationLen = config.ANIMATION_LENGTH;

export default function semanticFormField({
    input,
    type,
    label,
    placeholder,
    meta: { touched, error, warning, pristine },
    as: As = Input,
    ...props
}) {
    return (
        <Form.Field>
            <As
                {...props}
                {...input}
                type={type}
                label={label}
                placeholder={placeholder}
                className={touched && error ? 'invalid' : ''}
            />
            <Transition
                visible={touched && !!error}
                animation="fade"
                duration={animationLen}
            >
                <span className="error-text">
                    <i>{error}</i>
                </span>
            </Transition>
            <Transition
                visible={touched && !!warning}
                animation="fade"
                duration={animationLen}
            >
                <span className="warning-text">
                    <i>{warning}</i>
                </span>
            </Transition>
        </Form.Field>
    );
}

semanticFormField.propTypes = {
    as: PropTypes.any,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
};
