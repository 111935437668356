/*
 * PLabelCalendar should be used as the render component of a Redux Form Input component.
 *
 * Example:  <Field name="dateCreated" component={PLabelCalendar} label="Created Date *" validate={required}/>
 */

import React, { Component } from 'react';
import Calendar from '../calendar/Calendar';
import { Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';
import './forms.css';
import config from '../../constants/config';

export class PLabelCalendar extends Component {
    state = { open: false };

    openCalendar = () => this.setState({ open: !this.state.open });

    closeCalendar = () => this.setState({ open: false });

    render() {
        const { input, meta, minDate } = this.props;
        const { open } = this.state;

        return (
            <div className="field">
                <Popup
                    trigger={
                        <div
                            {...input}
                            className="p-label-calendar"
                            onClick={this.openCalendar}
                        >
                            <Icon
                                className="p-label-calendar-icon"
                                name="calendar outline"
                            />
                            <span>
                                {moment(input.value)
                                    .utc()
                                    .format(config.DISPLAY_DATE_FORMAT)}
                            </span>
                        </div>
                    }
                    open={open}
                    onClose={() => {
                        this.closeCalendar();
                    }}
                    on="click"
                    style={{ height: '290px' }}
                >
                    <Calendar
                        minDate={minDate}
                        onValuesChange={(value) => {
                            input.value = value;
                            input.onChange(value);
                            this.closeCalendar();
                        }}
                    />
                </Popup>
                {meta.touched &&
                    ((meta.error && (
                        <div className="error-text">
                            <i>{meta.error}</i>
                        </div>
                    )) ||
                        (meta.warning && (
                            <div className="warning-text">
                                <i>{meta.warning}</i>
                            </div>
                        )))}
            </div>
        );
    }
}
