import {
    MARKETING_DELETE_LOADING,
    MARKETING_DELETE_FAIL,
    MARKETING_DELETE_SUCCESS,
    MARKETING_DELETE_MODAL,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    showDeleteModal: false,
};

const deleteMarketing = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MARKETING_DELETE_LOADING:
            return { ...state, loading: true };
        case MARKETING_DELETE_FAIL:
            return { ...state, loading: false, showDeleteModal: false };
        case MARKETING_DELETE_SUCCESS:
            return { ...state, loading: false, showDeleteModal: false };
        case MARKETING_DELETE_MODAL:
            return { ...state, showDeleteModal: action.showModal };
        default:
            return state;
    }
};
export default deleteMarketing;
