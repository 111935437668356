export const PUSH_REFERRER = 'PUSH_REFERRER';
export const POP_REFERRER = 'POP_REFERRER';
export const REPLACE_REFERRER = 'REPLACE_REFERRER';
export const CLEAR_REFERRER = 'CLEAR_REFERRER';

export const pushReferrer = (ref) => ({
    type: PUSH_REFERRER,
    ...ref,
});

export const popReferrer = () => ({
    type: POP_REFERRER,
});

export const replaceReferrer = (ref) => ({
    type: REPLACE_REFERRER,
    ...ref,
});

export const clearReferrer = () => ({
    type: CLEAR_REFERRER,
});
