import querystring from 'querystring';
import jwtDecode from 'jwt-decode';

import config from '../constants/config';
import { Backend } from '../services/backend';

class AuthApi {
    constructor() {
        this.path = '/uaa';
    }

    async authenticate(args) {
        try {
            const credentials = btoa(
                `${config.CLIENT_ID}:${config.CLIENT_SECRET}`
            );
            const authHeader = `Basic ${credentials}`;

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/oauth/token?${querystring.stringify(args)}`, //TODO: UPDATE THIS URL WITH CORRECT RETAILER PORTAL ENDPOINT
                headers: { Authorization: authHeader },
            });
        } catch (e) {
            throw e;
        }
    }

    static async getUserInfo(auth) {
        return jwtDecode(auth.access_token);
    }
}

export { AuthApi };
