import {
    GET_COURIER,
    EditCourierActionTypes,
    EditCourierStateType,
    UPDATE_LOADING_STATE,
} from '../../type/courier/edit-courier-type';

const initialState: EditCourierStateType = {
    isLoading: false,
    courier: undefined,
};
const editCourierReducer = (
    state = initialState,
    action: EditCourierActionTypes
) => {
    switch (action.type) {
        case GET_COURIER:
            return {
                ...state,
                isLoading: false,
                courier: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};
export default editCourierReducer;
