import { ReportsApi } from '../../../../../services/reports-service';
export const LAST_ORDER_SUMMARY_INIT = 'LAST_ORDER_SUMMARY_INIT';
export const LAST_ORDER_SUMMARY = 'LAST_ORDER_SUMMARY';
export const LAST_ORDER_SUMMARY_LOADING = 'LAST_ORDER_SUMMARY_LOADING';
export const LAST_ORDER_SUMMARY_ERROR = 'LAST_ORDER_SUMMARY_ERROR';

const reportsApi = new ReportsApi();

export const initLastOrderSummaryForRetailer = () => {
    return async (dispatch) => {
        dispatch({ type: LAST_ORDER_SUMMARY_INIT });
    };
};

export const getLastOrderSummaryForRetailer = (retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LAST_ORDER_SUMMARY_LOADING });
            const lastOrderSummaries =
                await reportsApi.getLastOrderSummaryForRetailer(
                    retailerReference
                );
            dispatch({
                type: LAST_ORDER_SUMMARY,
                lastOrderSummaries,
            });
        } catch (e) {
            dispatch({
                type: LAST_ORDER_SUMMARY_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
