import React from 'react';
import './styles.css';

const Pill = ({ text, onClick }) => (
    <span className="pill" onClick={onClick}>
        {text}
    </span>
);

export default Pill;
