import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import '../style.css';
import { Button, Icon, Input } from 'semantic-ui-react';
import { Field, Form } from 'redux-form';
import PDateRange from '../../../components/PDateRange';
import classnames from 'classnames';
import Localize from '../../../constants/i18n-utils';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

const FILTERS_PROPERTIES = {
    RETURN_NO: 'externalReturnNumber',
    TICKET: 'ticket',
    BOOK: 'bookSerial',
    RETURN_DATE: 'returnDate',
};

class PendingReturnsSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchCriteria: FILTERS_PROPERTIES.RETURN_NO,
        };
    }

    selectSearchCriterion = (searchCriterion) => {
        const { onFilterTabChanged } = this.props;
        this.setState({ searchCriteria: searchCriterion });
        this.props.reset();
        onFilterTabChanged && onFilterTabChanged();
    };

    _handleSubmit = (values) => {
        const { onSubmit } = this.props;
        if (onSubmit) {
            onSubmit(values);
        }
    };

    _onSearchClear = () => {
        this.props.reset();
        this._handleSubmit([]);
    };

    render() {
        const { handleSubmit, dirty, enableTicketSearch } = this.props;

        const { searchCriteria } = this.state;

        const isFilterReturnNo =
            searchCriteria === FILTERS_PROPERTIES.RETURN_NO;
        const isFilterTicket = searchCriteria === FILTERS_PROPERTIES.TICKET;
        const isFilterBook = searchCriteria === FILTERS_PROPERTIES.BOOK;
        const isFilterReturnDate =
            searchCriteria === FILTERS_PROPERTIES.RETURN_DATE;

        const searchIcon = (
            <Icon name="close" link onClick={this._onSearchClear} />
        );

        return (
            <div className="bg-white">
                <div className="padding-20 bg-translucent-black">
                    <h3>Find a Pending Returns By:</h3>
                    <div className="flex-row">
                        <Button
                            size="small"
                            className={classnames({
                                primary: isFilterReturnNo,
                                white: !isFilterReturnNo,
                            })}
                            content="Return No."
                            onClick={
                                !isFilterReturnNo
                                    ? () =>
                                          this.selectSearchCriterion(
                                              FILTERS_PROPERTIES.RETURN_NO
                                          )
                                    : undefined
                            }
                        />
                        {enableTicketSearch && (
                            <Button
                                size="small"
                                className={classnames({
                                    primary: isFilterTicket,
                                    white: !isFilterTicket,
                                })}
                                content="Ticket"
                                onClick={
                                    !isFilterTicket
                                        ? () =>
                                              this.selectSearchCriterion(
                                                  FILTERS_PROPERTIES.TICKET
                                              )
                                        : undefined
                                }
                            />
                        )}

                        <Button
                            size="small"
                            className={classnames({
                                primary: isFilterBook,
                                white: !isFilterBook,
                            })}
                            content="Pack"
                            onClick={
                                !isFilterBook
                                    ? () =>
                                          this.selectSearchCriterion(
                                              FILTERS_PROPERTIES.BOOK
                                          )
                                    : undefined
                            }
                        />
                        <Button
                            size="small"
                            className={classnames({
                                primary: isFilterReturnDate,
                                white: !isFilterReturnDate,
                            })}
                            content="Return Date"
                            onClick={
                                !isFilterReturnDate
                                    ? () =>
                                          this.selectSearchCriterion(
                                              FILTERS_PROPERTIES.RETURN_DATE
                                          )
                                    : undefined
                            }
                        />
                    </div>
                    <div className="input-container w-100">
                        <Form onSubmit={handleSubmit(this._handleSubmit)}>
                            {isFilterReturnNo && (
                                <Field
                                    name={FILTERS_PROPERTIES.RETURN_NO}
                                    component={Input}
                                    fluid
                                    placeholder={Localize.text(
                                        'pendingReturnPage.search.enterReturnNumber',
                                        'Enter Pending Return Number'
                                    )}
                                    className="bg-transparent search-criterion-input"
                                    icon={searchIcon}
                                    maxLength={9}
                                    autoComplete="off"
                                />
                            )}
                            {isFilterTicket && (
                                <Field
                                    name={FILTERS_PROPERTIES.TICKET}
                                    component={Input}
                                    fluid
                                    placeholder={Localize.text(
                                        'pendingReturnPage.search.enterTicketNumber',
                                        'Enter ticket Number'
                                    )}
                                    className="bg-transparent search-criterion-input"
                                    icon={searchIcon}
                                    autoComplete="off"
                                />
                            )}
                            {isFilterBook && (
                                <Field
                                    name={FILTERS_PROPERTIES.BOOK}
                                    component={Input}
                                    fluid
                                    placeholder={Localize.text(
                                        'pendingReturnPage.search.enterPackNumber',
                                        'Enter Pack Number'
                                    )}
                                    className="bg-transparent search-criterion-input"
                                    icon={searchIcon}
                                    autoComplete="off"
                                />
                            )}
                            {isFilterReturnDate && (
                                <div
                                    className={
                                        'd-flex search-input-date-range  '
                                    }
                                >
                                    <div>
                                        <Field
                                            name={
                                                FILTERS_PROPERTIES.RETURN_DATE
                                            }
                                            component={PDateRange}
                                            className="bg-transparent search-criterion-input-2"
                                            openOnMount={false}
                                            clearable={true}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            className="square-button"
                                            primary={dirty}
                                            disabled={!dirty}
                                        >
                                            <span className="icon-Search" />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ organization: { configs } }) => {
    return {
        enableTicketSearch:
            _.find(configs, { name: 'enable-ticket-search' })?.value === 'true',
    };
};

export default withRouter(
    connect(mapStateToProps)(
        reduxForm({
            form: 'PendingReturnsSearchForm',
            enableReinitialize: true,
            forceUnregisterOnUnmount: true,
        })(PendingReturnsSearchForm)
    )
);
