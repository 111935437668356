import {
    PUBLIC_FAQ_LOADING,
    PUBLIC_FAQ_LOADING_DONE,
    PUBLIC_FAQ_INIT,
    PUBLIC_FAQ_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    faqHtml: null,
};

const publicFaq = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PUBLIC_FAQ_GET:
            return {
                ...state,
                faqHtml: payload,
            };
        case PUBLIC_FAQ_INIT:
            return { ...INITIAL_STATE };

        case PUBLIC_FAQ_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PUBLIC_FAQ_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default publicFaq;
