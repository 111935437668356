import { PlanogramApi } from '../../../services/planogram-group-service';
import { RetailersApi } from '../../../services/retailers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const CREATE_PLANOGRAMGROUP_PROCESSING =
    'CREATE_PLANOGRAMGROUP_PROCESSING';
export const PLANOGRAMGROUP_CREATE_SUCCESS = 'PLANOGRAMGROUP_CREATE_SUCCESS';
export const CREATE_PLANOGRAMGROUP_INIT = 'CREATE_PLANOGRAMGROUP_INIT';
export const CREATE_PLANOGRAMGROUP_GET_SUCCESS =
    'CREATE_PLANOGRAMGROUP_GET_SUCCESS';
export const CREATE_PLANOGRAM_LOADING = 'CREATE_PLANOGRAM_LOADING';
export const CREATE_PLANOGRAM_LOADING_DONE = 'CREATE_PLANOGRAM_LOADING_DONE';

const planogramApi = new PlanogramApi();
const retailersApi = new RetailersApi();

const createPlanogramError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'CreatePlanogramGroup',
            })
        );
    };
};

export const createPlanogram = (createPlanogramForm) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: CREATE_PLANOGRAMGROUP_PROCESSING });
        try {
            const planogram = await planogramApi.createPlanogramGroup(
                createPlanogramForm
            );
            await retailersApi.updateAllRetailersWithPlanogramGroup(
                createPlanogramForm.retailerIds,
                planogram.id
            );

            dispatch({
                type: PLANOGRAMGROUP_CREATE_SUCCESS,
                planogram,
            });
        } catch (e) {
            success = false;
            dispatch(createPlanogramError(e));
        } finally {
            dispatch({ type: CREATE_PLANOGRAM_LOADING_DONE });
        }

        return success;
    };
};

export const initPlanogram = () => {
    return async (dispatch) => {
        dispatch({ type: CREATE_PLANOGRAMGROUP_INIT });
    };
};
