import React, { Component } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import {
    onlyAllowNumericAndLettersInput,
    onlyAllowNumericInput,
} from '../../../constants/keyPressEvents';

class ReturnPosItemScanForm extends Component {
    state = {
        returnItemCode: '',
        returnItemQuantity: 1,
    };

    constructor(props) {
        super(props);

        this.scannerItemCodeField = React.createRef();
        this.scannerItemQuantityField = React.createRef();
    }

    handleItemCodeScanChange = (event, { value }) => {
        this.setState({ returnItemCode: value });
    };

    handleItemCodeKeyDown = (event) => {
        if (event.key === 'Enter') {
            const { returnItemCode } = this.state;

            if (returnItemCode) {
                this.scannerItemQuantityField &&
                    this.scannerItemQuantityField.current.select();
            }
        }
    };

    handleItemQuantityScanChange = (event, { value }) => {
        this.setState({ returnItemQuantity: value });
    };

    handleItemQuantityKeyDown = async (event) => {
        if (event.key === 'Enter') {
            const { onSubmit } = this.props;
            const { returnItemCode, returnItemQuantity } = this.state;

            if (returnItemCode && ~~returnItemQuantity > 0) {
                if (onSubmit) {
                    await onSubmit({
                        returnItemCode,
                        returnItemQuantity,
                    });
                    this.clearScannerField();
                }
            }
        }
    };

    handleClearSearchClick = () => {
        this.clearScannerField();
    };

    clearScannerField() {
        this.setState({ returnItemCode: '', returnItemQuantity: 1 });
        this.scannerItemCodeField && this.scannerItemCodeField.current.select();
    }

    render() {
        const { processing } = this.props;
        const { returnItemCode, returnItemQuantity } = this.state;

        const searchIcon = (
            <Icon name="close" link onClick={this.handleClearSearchClick} />
        );

        return (
            <Form loading={processing}>
                <Form.Field className="return-flex-row space-between">
                    <Input
                        name="returnItemCode"
                        fluid
                        placeholder="POS Item Code"
                        className="bg-transparent pending-return-input"
                        onKeyPress={onlyAllowNumericAndLettersInput}
                        onKeyDown={(event) => this.handleItemCodeKeyDown(event)}
                        onChange={this.handleItemCodeScanChange}
                        ref={this.scannerItemCodeField}
                        value={returnItemCode}
                        autoFocus
                        disabled={processing}
                    />
                    &nbsp;
                    <Input
                        name="returnItemQuantity"
                        fluid
                        placeholder="Quantity"
                        className="bg-transparent pending-return-input"
                        onKeyPress={onlyAllowNumericInput}
                        onKeyDown={(event) =>
                            this.handleItemQuantityKeyDown(event)
                        }
                        onChange={this.handleItemQuantityScanChange}
                        disabled={processing}
                        ref={this.scannerItemQuantityField}
                        value={returnItemQuantity}
                        icon={searchIcon}
                    />
                </Form.Field>
            </Form>
        );
    }
}

export default ReturnPosItemScanForm;
