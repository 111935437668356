import { SalesRoutesApi } from '../../../services/sales-routes-service';
import { UserApi } from '../../../services/user-service';

import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';
import _ from 'lodash';

export const CREATE_SALES_ROUTE_LOADING = 'CREATE_SALES_ROUTE_LOADING';
export const CREATE_SALES_ROUTE_LOADING_DONE =
    'CREATE_SALES_ROUTE_LOADING_DONE';
export const CREATE_SALES_ROUTE_PROCESSING = 'CREATE_SALES_ROUTE_PROCESSING';
export const SALES_ROUTE_CREATE_INIT = 'SALES_ROUTE_CREATE_INIT';
export const CREATE_SALES_ROUTE_CREATE_SUCCESS =
    'CREATE_SALES_ROUTE_CREATE_SUCCESS';
export const FETCH_SALES_ROUTE_SUCCESS = 'FETCH_SALES_ROUTE_SUCCESS';
export const CREATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS =
    'CREATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS';

const salesRouteApi = new SalesRoutesApi();
const userApi = new UserApi();

const createSalesRouteError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'CreateSalesRoute' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchSalesRouteOptions = (args) => {
    return async (dispatch) => {
        try {
            const defaultPagingRequest = { page: 0, size: 9999 };
            let salesManagers;
            let salesReps;

            if (args && args.inactiveRep) {
                const allSalesReps = await userApi.getAllUsersFilter({
                    page: 0,
                    size: 9999,
                    sortColumn: 'firstName',
                    sortOrder: 'ASC',
                    filters: [
                        { property: 'status', data: ['ACTIVE', 'INACTIVE'] },
                        { property: 'roles', data: ['SALES_REP'] },
                    ],
                });
                // remove all inactive users except inactiveRep passed in. (the currently assigned rep)
                salesReps = {
                    content: _.filter(
                        allSalesReps.content,
                        (rep) =>
                            rep.enabled || rep.username === args.inactiveRep
                    ),
                };
            } else {
                // current rep is not inactive, just return active reps
                salesReps = await userApi.getActiveSalesReps(
                    defaultPagingRequest
                );
            }

            if (args && args.inactiveMgr) {
                const allSalesManagers = await userApi.getAllUsersFilter({
                    page: 0,
                    size: 9999,
                    sortColumn: 'firstName',
                    sortOrder: 'ASC',
                    filters: [
                        { property: 'status', data: ['ACTIVE', 'INACTIVE'] },
                        { property: 'roles', data: ['SALES_MANAGER'] },
                    ],
                });
                // remove all inactive users except inactiveMgr passed in. (the currently assigned manager)
                salesManagers = {
                    content: _.filter(
                        allSalesManagers.content,
                        (mgr) =>
                            mgr.enabled || mgr.username === args.inactiveMgr
                    ),
                };
            } else {
                // current mgr is not inactive, just return all active mgrs
                salesManagers = await userApi.getActiveSalesManagers(
                    defaultPagingRequest
                );
            }

            dispatch({
                type: FETCH_SALES_ROUTE_SUCCESS,
                salesManagers: salesManagers.content,
                salesReps: salesReps.content,
            });
        } catch (e) {
            dispatch(createSalesRouteError(e));
        }
    };
};

export const cancelCreateSalesRoute = () => {
    return async (dispatch) => {
        dispatch({
            type: SALES_ROUTE_CREATE_INIT,
            salesManagers: [],
            salesReps: [],
        });
    };
};

export const initCreateSalesRoute = () => {
    return async (dispatch, getState) => {
        dispatch({ type: CREATE_SALES_ROUTE_LOADING });
        try {
            const { auth } = getState();
            const defaultPagingRequest = { page: 0, size: 9999 };

            const salesManagers = await userApi.getActiveSalesManagers(
                defaultPagingRequest
            );
            const salesReps = await userApi.getActiveSalesReps(
                defaultPagingRequest
            );

            let currSalesManager = '';
            if (
                _.some(
                    salesManagers.content,
                    (sm) =>
                        sm.username.toLowerCase() ===
                        auth.info.user_name.toLowerCase()
                )
            ) {
                currSalesManager = auth.info.user_name;
            }

            dispatch({
                type: SALES_ROUTE_CREATE_INIT,
                salesManagers: salesManagers.content,
                salesReps: salesReps.content,
                currUser: currSalesManager,
            });
        } catch (e) {
            dispatch(createSalesRouteError(e));
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: CREATE_SALES_ROUTE_LOADING_DONE });
        }
    };
};

export const initSalesRouteState = () => {
    return async (dispatch) => {
        dispatch({
            type: SALES_ROUTE_CREATE_INIT,
            salesManagers: [],
            salesReps: [],
        });
    };
};

export const createSalesRoute = (salesRoute) => {
    return async (dispatch) => {
        let success = true;

        dispatch({ type: CREATE_SALES_ROUTE_PROCESSING });
        try {
            await salesRouteApi.createSalesRoute(salesRoute);
            dispatch({
                type: CREATE_SALES_ROUTE_CREATE_SUCCESS,
            });
        } catch (e) {
            success = false;
            dispatch(createSalesRouteError(e));
        } finally {
            dispatch({ type: CREATE_SALES_ROUTE_LOADING_DONE });
        }

        return success;
    };
};
