import { SET_FORM_DIRTY } from '../../actions';

const INITIAL_STATE = {
    isFormDirty: false,
};

const formsMetadata = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FORM_DIRTY:
            return { ...state, isFormDirty: action.isFormDirty };
        default:
            return state;
    }
};
export default formsMetadata;
