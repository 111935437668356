import {
    GAME_DETAILS,
    GAME_DETAILS_LOADING,
    GAME_DETAILS_LOADING_DONE,
} from '../../actions';

const INITIAL_STATE = {
    gameDetails: [],
    loading: false,
};

const retailerOrderGameDetails = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GAME_DETAILS_LOADING:
            return { ...state, loading: true };
        case GAME_DETAILS:
            return {
                ...state,
                gameDetails: [...action.list],
            };
        case GAME_DETAILS_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default retailerOrderGameDetails;
