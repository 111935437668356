import moment from 'moment';
import { PrizeWinningsApi } from '../../services/prize-winnings-service';
import { OrganizationApi } from '../../services/organization-service';

export const RETAILER_PRIZE_WINNINGS_INIT = 'RETAILER_PRIZE_WINNINGS_INIT';
export const RETAILER_PRIZE_WINNINGS_LOADING =
    'RETAILER_PRIZE_WINNINGS_LOADING';
export const RETAILER_PRIZE_WINNINGS = 'RETAILER_PRIZE_WINNINGS';
export const RETAILER_PRIZE_MID_TIER_WINNINGS =
    'RETAILER_PRIZE_MID_TIER_WINNINGS';

const prizeWinningsApi = new PrizeWinningsApi();
const organizationApi = new OrganizationApi();

export const initRetailerPrizeWinningsAmount = () => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_PRIZE_WINNINGS_INIT });
    };
};

export const getRetailerPrizeWinningsAmount = (
    retailerReference,
    lastVisitDate
) => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_PRIZE_WINNINGS_LOADING });

        //get from lastVisitDate (inclusive) to current date exclusive. Backend query is inclusive on both dates
        const toDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        const prizeWinningsSinceLastVisit = lastVisitDate
            ? await prizeWinningsApi.retailerPrizeWinningsAmount(
                  retailerReference,
                  lastVisitDate,
                  toDate
              )
            : 0;

        dispatch({
            type: RETAILER_PRIZE_WINNINGS,
            prizeWinningsSinceLastVisit,
            lastVisitDate,
            retailerReference,
        });
    };
};

export const getRetailerMidTierPrizeWinningsAmount = (
    retailerReference,
    lastWeekStartDate,
    lastWeekEndDate
) => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_PRIZE_WINNINGS_LOADING });

        const highTierPrizeThreshold =
            await organizationApi.getHighTierPrizeThreshold();
        //(backend query is inclusive on both dates)
        const midTierWinnings =
            await prizeWinningsApi.retailerPrizeWinningsAmount(
                retailerReference,
                lastWeekStartDate,
                lastWeekEndDate,
                highTierPrizeThreshold,
                true
            );

        dispatch({
            type: RETAILER_PRIZE_MID_TIER_WINNINGS,
            midTierWinnings,
            retailerReference,
        });
    };
};
