import { Courier } from '../../../DTOs/courier';
import type { Page } from '../../../interface/page';

export const GET_COURIER = 'Courier/Get';
export const GET_COURIER_BY_SEARCH = 'Courier/Search';

export const UPDATE_LOADING_STATE = 'Courier/loading';

export interface CourierStateType {
    isLoading: boolean;
    courierList: Courier[];
    courierPage: Page<Courier>;
}

interface GetCouriersType {
    type: typeof GET_COURIER;
    payload: Courier[];
}

interface GetCouriersTypeBySearch {
    type: typeof GET_COURIER_BY_SEARCH;
    payload: Page<Courier>;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

export type CourierActionTypes =
    | GetCouriersType
    | UpdateLoadingState
    | GetCouriersTypeBySearch;
