const retailerNavMenuItemNames = {
    DASHBOARD_NAME: 'dashboard',
    GAME_NAME: 'games',
    PLANOGRAMS: 'planogram',
    ORDER_NAME: 'orders',
    INVOICE_NAME: 'invoices',
    REPORTS_NAME: 'reports',
    FAQ_NAME: 'faq',
    CONTACT_US_NAME: 'contactUs',
    ACCOUNT_NAME: 'account',
    GETTING_STARTED_NAME: 'gettingStarted',
    RETURNS: 'returns',
};

export default retailerNavMenuItemNames;
