import React, { useEffect } from 'react';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './cognito-loading-screen.css';
import defaultLogo from '../../assets/logo.png';
import { hasPermission, isSvgLogo } from '../../constants/utils';
import { setupLogo } from '../../redux/actions';
import config from '../../constants/config';
import { cognitoLoginByProvider } from '../../redux/actions/cognito/cognito-login';

const CognitoLoadingScreen = () => {
    const providers = JSON.parse(config.COGNITO_PROVIDERS);
    const authenticated = useSelector((state) => state.auth.isAuthenticated);
    const errors = useSelector((state) => state.auth.errors);
    const isLoading = useSelector(
        (state) => state.cognitoLoginReducer.isLoading
    );

    const appLogo = useSelector((state) => state.organization.appLogo);
    const appLogoFile = useSelector((state) => state.appLogo.appLogoFile);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setupLogo());
    }, [dispatch]);

    const logoImage = {
        alt: 'appLogo',
        src: isSvgLogo(appLogo, appLogoFile),
    };

    if (!logoImage.src) {
        logoImage.src = defaultLogo;
    }

    const loginWithCognito = async (provider) => {
        cognitoLoginByProvider(provider);
    };
    if (authenticated) {
        return <Redirect to={hasPermission()} />;
    } else if (isLoading) {
        return (
            <div className={'cognito-center loading'}>
                <Loader active inline="centered">
                    Loading
                </Loader>
            </div>
        );
    } else {
        return (
            <div className={'cognito-parent'}>
                <Grid className={'cognito-center login-container'}>
                    <Grid.Row>
                        <Grid.Column>
                            <img
                                className={'cognito-logo-image'}
                                src={logoImage.src}
                                alt={logoImage.alt}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {errors && errors.message && (
                        <Grid.Row>
                            <Grid.Column>
                                <span className={'error-text'}>
                                    {errors?.message}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {providers &&
                        providers.map((provider) => {
                            return (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button
                                            className={'cognito-login-button'}
                                            content={`${provider.desc} Login`}
                                            primary
                                            onClick={() =>
                                                loginWithCognito(provider.idp)
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                </Grid>
            </div>
        );
    }
};

export default CognitoLoadingScreen;
