import { RetailersApi } from '../../../services/retailers-service';
import OrderApi from '../../../services/order-service';
import * as _ from 'lodash';
import { addSuggestedQuantityOnLoad } from '../cart/cart';
import { UPDATE_SUGGESTED_QUANTITY } from '../../type/items/suggested-quanitity';
import { GamesApi } from '../../../services/games-service';
import DraftOrderApi from '../../../services/draft-order-service';
import type { DraftOrder } from '../../../DTOs/draft-order';
import type { DraftOrderDetail } from '../../../DTOs/draft-order-detail';
import moment from 'moment';
import { error } from '../../../redux/actions';
import { getErrorMessage } from '../../../constants/utils';

export const USER_INFO_LOADING = 'USER_INFO_LOADING';
export const USER_INFO_LOADING_DONE = 'USER_INFO_LOADING_DONE';
export const USER_INFO_INIT = 'USER_INFO_INIT';
export const USER_INFO_GET_RETAILER_INFO = 'USER_INFO_GET_RETAILER_INFO';

const retailersApi = new RetailersApi();
const orderApi = new OrderApi();
const gamesApi = new GamesApi();
const draftOrderApi = new DraftOrderApi();

export const getUserRetailerInfo = (reference) => {
    return async (dispatch) => {
        dispatch({ type: USER_INFO_LOADING });

        try {
            const payload = await retailersApi.getRetailerInfoByReference(
                reference
            );
            dispatch({ type: USER_INFO_GET_RETAILER_INFO, payload });
        } catch (err) {
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err), target: 'Dashboard' }));
        }

        dispatch({ type: USER_INFO_LOADING_DONE });
    };
};

export const getUserRetailerSuggestedOrders = () => {
    return async (dispatch, getState) => {
        const { userRetailer } = getState();
        const reference =
            userRetailer?.activeRetailer.userRetailerId.retailerReference;
        dispatch({ type: USER_INFO_LOADING });
        const gamesMap = new Map();

        try {
            const payload = await retailersApi.getUserRetailerSuggestedOrders(
                reference
            );

            if (payload && payload.length > 0) {
                _.forEach(payload, (game) => {
                    if (game.suggestedQuantity > 0) {
                        gamesMap.set(game.itemReference, {
                            qty: game.suggestedQuantity,
                            suggestedQuantity: game.suggestedQuantity,
                        });
                    }
                });
                const todayOrder = await orderApi.getOrderByOrderDate(
                    reference,
                    moment().format('YYYY-MM-DD')
                );
                if (todayOrder && todayOrder.length > 0) {
                    _.forEach(todayOrder, (order) => {
                        _.forEach(order.orderDetails, (orderDetails) => {
                            if (
                                gamesMap.has(orderDetails.itemNumber) &&
                                gamesMap.get(orderDetails.itemNumber).qty > 0
                            ) {
                                const remainingSuggestedCount =
                                    gamesMap.get(orderDetails.itemNumber)
                                        .suggestedQuantity -
                                    orderDetails.quantity;
                                remainingSuggestedCount > 0
                                    ? gamesMap.set(orderDetails.itemNumber, {
                                          qty: remainingSuggestedCount,
                                          suggestedQuantity: gamesMap.get(
                                              orderDetails.itemNumber
                                          ).suggestedQuantity,
                                      })
                                    : gamesMap.delete(orderDetails.itemNumber);
                            }
                        });
                    });
                }
            }
            const orderedItems = [];

            const posDraftOrders: DraftOrder = await draftOrderApi.get(
                reference
            );
            if (
                posDraftOrders &&
                posDraftOrders.draftOrderDetails &&
                posDraftOrders.draftOrderDetails.length > 0
            ) {
                _.forEach(
                    posDraftOrders.draftOrderDetails,
                    (posDraftOrderDetail: DraftOrderDetail) => {
                        gamesMap.set(posDraftOrderDetail.gameReference, {
                            qty: posDraftOrderDetail.quantity,
                            suggestedQuantity:
                                posDraftOrderDetail.suggestedQuantity,
                        });
                    }
                );
            }
            var suggestedQuantityMap = new Map();
            for (const [gameRef, value] of gamesMap) {
                const gameVal = await gamesApi.getGameDetailsByGameNumber([
                    gameRef,
                ]);

                orderedItems.push({
                    game: {
                        ...gameVal[0],
                        gameId: gameVal[0].id,
                        gameRefAndName: `${gameVal[0].reference} - ${gameVal[0].name}`,
                        suggestedQuantity: value.suggestedQuantity,
                    },
                    qty: value.qty,
                    suggestedQuantity: value.suggestedQuantity,
                });
                suggestedQuantityMap.set(gameRef, value.suggestedQuantity);
            }
            dispatch({
                type: UPDATE_SUGGESTED_QUANTITY,
                payload: suggestedQuantityMap,
            });
            addSuggestedQuantityOnLoad(orderedItems, dispatch);
        } catch (err) {
            console.error(err);
        }
    };
};
export const initUserInfo = () => ({
    type: USER_INFO_INIT,
});
