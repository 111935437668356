import {
    RETAILER_DASHBOARD_MODAL_OPEN,
    RETAILER_DASHBOARD_MODAL_CLOSE,
} from './actions';

const INITIAL_STATE = {
    open: false,
};

const retailerDashboardModal = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_DASHBOARD_MODAL_OPEN:
            return { ...state, open: true };
        case RETAILER_DASHBOARD_MODAL_CLOSE:
            return { ...state, open: false };
        default:
            return state;
    }
};

export default retailerDashboardModal;
