import DateRange from 'react-date-range/dist/components/DateRange';
import React, { PureComponent } from 'react';

class DateRangeCalender extends PureComponent {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { ranges: nextRange } = nextProps;
        const { ranges } = this.props;

        if (nextRange.length !== ranges.length) {
            return true;
        }
        for (let i in nextRange) {
            const { startDate: nextStartDate } = nextRange[i];
            const { endDate: nextEndDate } = nextRange[i];
            const { startDate } = ranges[i];
            const { endDate } = ranges[i];
            if (
                nextStartDate.getTime() !== startDate.getTime() ||
                nextEndDate.getTime() !== endDate.getTime()
            ) {
                return true;
            }
        }
        return false;
    }

    render() {
        return <DateRange {...this.props} />;
    }
}
export default DateRangeCalender;
