import _ from 'lodash';
import moment from 'moment';

import { OrganizationApi } from '../../services/organization-service';
import { ReportsApi } from '../../services/reports-service';

export const SHORT_TERM_SALES_LOADING = 'SHORT_TERM_SALES_LOADING';
export const SHORT_TERM_SALES_UPDATE_PERIOD = 'SHORT_TERM_SALES_UPDATE_PERIOD';
export const SHORT_TERM_SALES_SUCCESS = 'SHORT_TERM_SALES_SUCCESS';
export const SHORT_TERM_SALES_FAIL = 'SHORT_TERM_SALES_FAIL';

const organizationApi = new OrganizationApi();
const reportApi = new ReportsApi();

export const fetchShortTermSales = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SHORT_TERM_SALES_LOADING });

            const {
                organization: { accountingWeek },
            } = getState();

            const weekStart = !accountingWeek
                ? await organizationApi.getAccountingWeek()
                : accountingWeek;

            const today = moment().isoWeekday(); //1 - Monday, 7 - Sunday

            let daysAgo;
            if (weekStart < today) {
                daysAgo = today + 6;
            } else if (weekStart > today) {
                daysAgo = 14 - (weekStart - today);
            } else {
                daysAgo = 7;
            }

            const currentStartDate = moment().subtract(daysAgo, 'days');
            const currentEndDate = moment(currentStartDate).add(6, 'days');

            const weekInYear = currentStartDate.weeks();

            const previousStartDate = moment(currentStartDate)
                .subtract(1, 'year')
                .day(weekStart)
                .week(weekInYear);
            const previousEndDate = moment(previousStartDate).add(6, 'days');

            const period = {
                from: currentStartDate.format('MMM DD, YYYY'),
                to: currentEndDate.format('MMM DD, YYYY'),
            };

            dispatch({
                type: SHORT_TERM_SALES_UPDATE_PERIOD,
                weekInYear,
                period,
            });

            const data = await reportApi.getShortTermSales({
                currentStartDate: currentStartDate.format('YYYY-MM-DD'),
                currentEndDate: currentEndDate.format('YYYY-MM-DD'),
                previousStartDate: previousStartDate.format('YYYY-MM-DD'),
                previousEndDate: previousEndDate.format('YYYY-MM-DD'),
            });

            dispatch({
                type: SHORT_TERM_SALES_SUCCESS,
                data: _.sortBy(data || [], ['gameTypeText']),
            });
        } catch (e) {
            dispatch({ type: SHORT_TERM_SALES_FAIL, msg: e.message });
        }
    };
};
