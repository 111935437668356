import {
    CHECKOUT_LOADING,
    CHECKOUT_LOADING_DONE,
    CHECKOUT_INIT,
    CHECKOUT_SAVE,
    INCLUDE_SAMPLE_GAME_SAVE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    lastOrder: null,
    includeSampleGames: false,
};

const checkout = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case CHECKOUT_SAVE:
            return {
                ...state,
                lastOrder: payload,
            };
        case INCLUDE_SAMPLE_GAME_SAVE:
            return {
                ...state,
                includeSampleGames: payload,
            };
        case CHECKOUT_INIT:
            return { ...INITIAL_STATE };

        case CHECKOUT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CHECKOUT_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default checkout;
