import reportNames from '../../../constants/reportNames';
import { error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';
import { ReportsApi } from '../../../services/reports-service';
const reportsApi = new ReportsApi();

export const PRINT_RETURN_LOADING = 'Returns/PRINT_RETURN_LOADING';
export const PRINT_RETURN_LOADING_DONE = 'Returns/PRINT_RETURN_LOADING_DONE';
export const printReturnByExternalReturnNumberNumber = (
    externalReturnNumber
) => {
    return async (dispatch) => {
        dispatch({ type: PRINT_RETURN_LOADING });
        try {
            await reportsApi.generateReportByName(
                reportNames.RE_PENDING_RETRUN,
                {
                    externalReturnNumber: externalReturnNumber,
                }
            );
        } catch (err) {
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err) }));
        }
        dispatch({ type: PRINT_RETURN_LOADING_DONE });
    };
};
