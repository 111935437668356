import { Backend } from './backend';

class ServiceCallApi {
    constructor() {
        this.path = 'retailers/service-call';
    }

    async getAllOpenServiceCallByRetailerReference(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/retailer/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllServiceCallList(
        { page, size, sortOrder, sortColumn },
        retailerReference
    ) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/list/${retailerReference}`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'id',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                },
            });
        } catch (e) {
            throw e;
        }
    }
}

export { ServiceCallApi };
