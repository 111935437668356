import { SalesRoutesApi } from '../../../services/sales-routes-service';

import { RetailersApi } from '../../../services/retailers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const UPDATE_SALES_ROUTE_LOADING = 'UPDATE_SALES_ROUTE_LOADING';
export const UPDATE_SALES_ROUTE_LOADING_DONE =
    'UPDATE_SALES_ROUTE_LOADING_DONE';
export const UPDATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS =
    'UPDATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS';
export const GET_SALES_ROUTE_SUCCESS = 'GET_SALES_ROUTE_SUCCESS';
export const SALES_ROUTE_UPDATE_INIT = 'SALES_ROUTE_UPDATE_INIT';

const salesRouteApi = new SalesRoutesApi();
const retailerService = new RetailersApi();

const updateSalesRouteError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'UpdateUser' }));
    };
};

export const getSalesRouteById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_SALES_ROUTE_LOADING });

            const data = await salesRouteApi.getSalesRouteById(id);

            dispatch({
                type: GET_SALES_ROUTE_SUCCESS,
                data,
            });

            //get retailer data for read
            if (data.retailerIds.length > 0) {
                const retailerInfo = await retailerService.getRetailersByIds(
                    data.retailerIds
                );
                dispatch({
                    type: UPDATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS,
                    info: retailerInfo,
                });
            }
        } catch (e) {
            dispatch(updateSalesRouteError(e));
        } finally {
            dispatch({ type: UPDATE_SALES_ROUTE_LOADING_DONE });
        }
    };
};

export const updateSalesRoute = (id, salesRoute) => {
    return async (dispatch) => {
        let success = true;

        dispatch({ type: UPDATE_SALES_ROUTE_LOADING });
        try {
            await salesRouteApi.updateSalesRoute(salesRoute);
        } catch (e) {
            success = false;
            dispatch(updateSalesRouteError(e));
        } finally {
            dispatch({ type: UPDATE_SALES_ROUTE_LOADING_DONE });
        }
        return success;
    };
};
