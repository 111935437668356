import React, { useEffect, useState } from 'react';
import { Grid, Icon, Modal } from 'semantic-ui-react';
import { getDownloadUrl, downloadDocument } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import Status from './status';
import { formatToDisplayDate } from '../../constants/utils';

const RetailerPlanogramDetailModel = ({
    isOpen,
    planogram,
    onCloseCallback,
}) => {
    const dispatch = useDispatch();
    const [bloburl, setBloburl] = useState();
    useEffect(() => {
        (async () => {
            const urlPath = planogram?.mediaKey;
            if (urlPath) {
                const formattedUrlPath =
                    urlPath[0] === '/' ? urlPath.substr(1) : urlPath;
                console.debug(`downloading file for path ${formattedUrlPath}`);
                let blob = await dispatch(getDownloadUrl(formattedUrlPath));
                setBloburl(blob);
            }
        })();
    }, [planogram?.mediaKey, dispatch]);

    const download = () => {
        const urlPath = planogram?.mediaKey;
        const formattedUrlPath = checkDownloadPath(urlPath);
        dispatch(downloadDocument(formattedUrlPath));
    };

    const checkDownloadPath = (urlPath) => {
        return urlPath[0] === '/' ? urlPath.substr(1) : urlPath;
    };

    return (
        <Modal
            open={isOpen}
            closeOnDimmerClick={true}
            onClose={onCloseCallback}
            closeIcon={
                <div className={'p-2'}>
                    <Icon className="icon-Close p-1 float-right" />
                </div>
            }
            className="retailer-planogram-detail-modal"
            data-test="RetailerPlanogramDetailModel"
        >
            <Modal.Content>
                {planogram && (
                    <>
                        <Grid columns={2} className={'pb-3'}>
                            <Grid.Row className={'p-1'}>
                                <Grid.Column>
                                    <label>Group : </label>{' '}
                                    <b>{planogram.retailerGroup.name}</b>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>No. Of Facings : </label>
                                    <b>
                                        {' '}
                                        {planogram.retailerGroup.maxFacings
                                            ? `${planogram.retailerGroup.minFacings} - ${planogram.retailerGroup.maxFacings}`
                                            : `${planogram.retailerGroup.minFacings}+`}
                                    </b>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'p-1'}>
                                <Grid.Column>
                                    <label>Start Date : </label>{' '}
                                    <b>
                                        {formatToDisplayDate(
                                            planogram.startDate
                                        )}
                                    </b>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Status : </label>{' '}
                                    <b>
                                        <Status
                                            endDate={planogram.endDate}
                                            startDate={planogram.startDate}
                                            text={true}
                                            icon={true}
                                        />
                                    </b>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'p-1 pb-3'}>
                                <Grid.Column>
                                    <label>End Date : </label>{' '}
                                    <b>
                                        {formatToDisplayDate(planogram.endDate)}
                                    </b>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Description : </label>{' '}
                                    <b>{planogram.description}</b>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'max-mobile pl-4 pt-10'}>
                                <span
                                    className="pointer blue-text"
                                    onClick={download}
                                >
                                    Please click here to download pdf
                                </span>
                            </Grid.Row>
                        </Grid>
                        <embed
                            className="min-tablet"
                            src={bloburl}
                            width="100%"
                            height={
                                document.documentElement.offsetHeight * 0.75
                            }
                            type="application/pdf"
                        />
                    </>
                )}
            </Modal.Content>
        </Modal>
    );
};

export default RetailerPlanogramDetailModel;
