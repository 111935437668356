import { Backend } from './backend';

class NewRetailerApi {
    constructor() {
        this.path = '/organization/new-retailer-policy';
    }

    async getPublicNewRetailer() {
        try {
            return await Backend.sendNoAuth({
                method: 'GET',
                url: `${this.path}/public`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePublicNewRetailer(html) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}`,
                body: html,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default NewRetailerApi;
