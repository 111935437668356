import React, { Component } from 'react';
import { Loader, Button, Modal } from 'semantic-ui-react';

class OkCancelModal extends Component {
    render() {
        const {
            header,
            body,
            okText = 'Ok',
            cancelText = 'Cancel',
            okFunction,
            cancelFunction,
            cssHeader,
            isOpen,
            loading,
        } = this.props;

        if (loading) {
            return <Loader active={loading} inline="centered" />;
        }

        return (
            <Modal
                open={typeof isOpen === typeof true ? isOpen : true}
                size="small"
            >
                <Modal.Header className={cssHeader}>{header}</Modal.Header>
                <Modal.Content className={'text-align-c'}>{body}</Modal.Content>
                <Modal.Actions>
                    {cancelFunction && (
                        <Button
                            onClick={cancelFunction}
                            className="transparent-btn"
                            content={cancelText}
                            data-test="cancelButton"
                        />
                    )}
                    <Button
                        content={okText}
                        onClick={okFunction}
                        data-test="okButton"
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default OkCancelModal;
