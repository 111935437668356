import { ReportsApi } from '../../../../../services/reports-service';
export const TOTAL_PURCHASE_YTD_PP_INIT = 'TOTAL_PURCHASE_YTD_PP_INIT';
export const TOTAL_PURCHASE_YTD_PP = 'TOTAL_PURCHASE_YTD_PP';
export const TOTAL_PURCHASE_YTD_PP_LOADING = 'TOTAL_PURCHASE_YTD_PP_LOADING';
export const TOTAL_PURCHASE_YTD_PP_ERROR = 'TOTAL_PURCHASE_YTD_PP_ERROR';

const reportsApi = new ReportsApi();

export const initTotalpurchaseYTDByPP = () => {
    return async (dispatch) => {
        dispatch({ type: TOTAL_PURCHASE_YTD_PP_INIT });
    };
};

export const getTotalpurchaseYTDByPP = (retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TOTAL_PURCHASE_YTD_PP_LOADING });
            const totalPurchaseYTDByPPs =
                await reportsApi.getTotalpurchaseYTDByPP(retailerReference);
            dispatch({
                type: TOTAL_PURCHASE_YTD_PP,
                totalPurchaseYTDByPPs,
            });
        } catch (e) {
            dispatch({
                type: TOTAL_PURCHASE_YTD_PP_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
