import {
    PUBLIC_NEW_RETAILER_LOADING,
    PUBLIC_NEW_RETAILER_LOADING_DONE,
    PUBLIC_NEW_RETAILER_INIT,
    PUBLIC_NEW_RETAILER_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    newRetailerHtml: null,
};

const publicNewRetailer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case PUBLIC_NEW_RETAILER_GET:
            return {
                ...state,
                newRetailerHtml: payload,
            };
        case PUBLIC_NEW_RETAILER_INIT:
            return { ...INITIAL_STATE };

        case PUBLIC_NEW_RETAILER_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PUBLIC_NEW_RETAILER_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default publicNewRetailer;
