import React from 'react';
import _ from 'lodash';
import { required } from '../../../constants/validate';
import UneditableLabel from '../../../components/UneditableLabel';
import { Field } from 'redux-form';
import { PSelect } from '../../../components/form';
import { Form } from 'semantic-ui-react';

const SettingsRow = ({ section, onWidgetSelect }) => {
    const { position, widgets, availableWidgets, sectionId } = section;
    const getInitOptions = (availableWidgets, widgets, widget) => {
        let options = _.filter(
            availableWidgets,
            (item) =>
                _.findIndex(widgets, ['name', item.name]) < 0 ||
                item.name === widget.name
        );
        if (_.findIndex(options, ['name', 'PLACEHOLDER']) === -1) {
            options = _.concat(options, {
                id: null,
                name: 'PLACEHOLDER',
                displayName: 'Placeholder',
                position: null,
            });
        }
        return options;
    };
    return (
        <div className="settings-row-content" data-test="SettingsRow">
            {_.map(widgets, (widget, index) => (
                <Form.Field inline={true}>
                    <div className="readonly">
                        <UneditableLabel text={`Widget ${index + 1}`} />
                    </div>
                    <Field
                        multi={false}
                        name={`${position}-${widget.position}`}
                        options={_.map(
                            getInitOptions(availableWidgets, widgets, widget),
                            (optionWidget) => ({
                                text:
                                    optionWidget.displayName === 'Placeholder'
                                        ? 'None'
                                        : optionWidget.displayName,
                                value: optionWidget.name,
                            })
                        )}
                        component={PSelect}
                        validate={required}
                        onChange={(e, selectedName) =>
                            onWidgetSelect(
                                widget.name,
                                selectedName,
                                sectionId,
                                index
                            )
                        }
                        className="sfa-input-control"
                        selection
                    />
                </Form.Field>
            ))}
        </div>
    );
};

export default SettingsRow;
