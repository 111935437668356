import { RETAILER_NAV_SET, RETAILER_NAV_INIT } from '../../actions';

const INITIAL_STATE = {
    activeItem: null,
};

const retailerNav = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RETAILER_NAV_INIT:
            return { ...INITIAL_STATE };

        case RETAILER_NAV_SET:
            return { activeItem: payload };

        default:
            return state;
    }
};
export default retailerNav;
