import React from 'react';
import { Switch, Route, HashRouter as Router } from 'react-router-dom';

import LoginPage from './containers/LoginPage';
import Dashboard from './containers/dashboard/Dashboard';
import NotFound from './containers/NotFound';
import Unauthorized from './containers/Unauthorized';
import ProtectedRoute from './components/security/ProtectedRoute';
import config from './constants/config';
import permissions from './constants/permissions';
import orderTabs from './constants/orderTabs';
import ConfigurationManagementPage from './containers/configuration-management/ConfigurationManagementPage';
import PermissionsManagementPage from './containers/permissions-management/PermissionsManagementPage';
import UpdateConfigurationManagementPage from './containers/configuration-management/UpdateConfigurationManagementPage';
import UsersPage from './containers/users/UsersPage';
import CreateUser from './containers/users/CreateUserPage';
import UpdateUser from './containers/users/UpdateUserPage';
import GameCatalogPage from './containers/game-catalog/GameCatalogPage';
import CheckoutPage from './containers/checkout/CheckoutPage';
import OrderDetailPage from './containers/orderDetailPage/OrderDetailPage';
import OrderHistoryPage from './containers/orderHistoryPage/OrderHistoryPage';
import InvoicesPage from './containers/invoicesPage/InvoicesPage';
import ReportPage from './containers/reports/ReportPage';
import ReportsPage from './containers/reports/ReportsPage';
import InvoiceDetailPage from './containers/invoiceDetailPage/InvoiceDetailPage';
import AdminFaqPage from './containers/faq/AdminFaqPage';
import FaqPage from './containers/faq/FaqPage';
import ContactUsConfigurationPage from './containers/contactUsConfigurationPage/ContactUsConfigurationPage';
import ContactUsPage from './containers/contactUsPrivate/ContactUsPage';
import AdminPrivacyPolicyPage from './containers/privacy-policy/AdminPrivacyPolicyPage';
import PrivacyPolicyPage from './containers/privacy-policy/PrivacyPolicyPage';
import AdminNewRetailerPage from './containers/new-retailer/AdminNewRetailerPage';
import DocumentListPage from './containers/PortalDocuments/DocumentListPage';
import CreateRolePage from './containers/permissions-management/CreateRolePage';
import MessageListPage from './containers/portalMessages/MessageListPage';
// import VirtualTourConfigPage from './containers/virtualTourConfiguration/VirtualTourConfigurationPage';
import GettingStartedPage from './containers/gettingStartedPage/GettingStartedPage';
import DashboardSettings from './containers/dashboard/settings/DashboardSettings';
import GamesListPage from './containers/gamesListPage/GamesListPage';
import { Redirect } from 'react-router';
import RetailerGroupListPage from './containers/retailer-groups/retailer-group-list-page';
import EditRetailerGroupPage from './containers/retailer-groups/edit-retailer-group';
import PlanogramListPage from './containers/planograms/planogram-list-page';
import EditPlanogramPage from './containers/planograms/edit-planogram';
import RetailerPlanogramListPage from './containers/retailer-planogram-view/retailer-planogram-list-page';
import CognitoLoadingScreen from './containers/cognito/cognito-loading-screen';
import ReturnsListPage from './containers/returns/ReturnsListPage';
import EditCourierPage from './containers/courier/edit-courier-page';
import CourierListPage from './containers/courier/courier-list-page';
import CreateReturn from './containers/returns/CreateReturn';
import ViewReturn from './containers/returns/ViewReturn';
import { hasPermission } from './constants/utils';

const {
    LOGIN_PAGE,
    DASHBOARD,
    UNAUTHORIZED,
    NOT_FOUND,
    CONFIGURATION_MANAGEMENT,
    UPDATE_CONFIGURATION_MANAGEMENT,
    PERMISSIONS_CONFIGURATION_MANAGEMENT,
    CREATE_USER,
    UPDATE_USER,
    USER_MANAGEMENT,
    GAMES,
    RETAILER_PLANOGRAM_LIST,
    CHECKOUT,
    ORDER,
    INVOICE,
    REPORTS,
    FAQ_PAGE,
    CONTACT_US_PAGE,
    CONTACT_US_CONFIGURATION,
    FAQ_MANAGEMENT_PRIVATE,
    FAQ_MANAGEMENT_PUBLIC,
    PRIVACY_POLICY_PAGE,
    PRIVACY_POLICY_MANAGEMENT,
    NEW_RETAILER_POLICY,
    UPLOAD_MANAGEMENT,
    CREATE_ROLE,
    MESSAGE_MANAGEMENT,
    // VIRTUAL_TOUR_CONFIGURATION,
    GETTING_STARTED,
    DASHBOARD_SETTINGS,
    GAMES_LIST,
    RETAILER_GROUP_LIST,
    RETAILER_GROUP_UPDATE,
    RETAILER_GROUP_CREATE,
    PLANOGRAM_UPDATE,
    PLANOGRAM_CREATE,
    PLANOGRAM_LIST,
    RETAILER_RETURN_LIST,
    RETAILER_RETURN_CREATE,
    COURIER_UPDATE,
    COURIER_CREATE,
    COURIER_LIST,
} = config.ROUTE_URLS;
const { COGNITO_ENABLED } = config;
const {
    LIST_USERS,
    CREATE_USERS,
    VIEW_USERS,
    UPDATE_SYS_CONFIGS,
    LOGO_CONFIG_UPLOAD,
    VIEW_SYS_CONFIGS,
    DASHBOARD_PAGE,
    CREATE_FAQ,
    UPDATE_CONTACT_US,
    CREATE_PRIVACY_POLICY,
    CREATE_ROLES,
    GAME_PAGE,
    IN_PROGRESS_ORDERS,
    PAST_ORDERS,
    ORDER_DETAILS,
    INVOICE_PAGE,
    INVOICE_DETAILS,
    LIST_REPORT,
    FAQ,
    CONTACT_US,
    PRIVACY_POLICY,
    CREATE_NEW_RETAILER_POLICY,
    DISPLAY_GAMES_LIST,
    DISPLAY_RETAILER_GROUP_LIST,
    CREATE_RETAILER_GROUP_LIST,
    VIEW_RETAILER_GROUP_LIST,
    UPDATE_RETAILER_GROUP_LIST,
    ROLE_PLANOGRAM_CREATE,
    ROLE_PLANOGRAM_UPDATE,
    ROLE_PLANOGRAM_LIST,
    ROLE_PLANOGRAM_VIEW,
    ROLE_RETURN_LIST,
    ROLE_RETURN_CREATE,
    ROLE_RETURN_VIEW,
    ROLE_COURIER_UPDATE,
    ROLE_COURIER_CREATE,
    ROLE_COURIER_VIEW,
    ROLE_COURIER_LIST,
} = permissions;

const Routes = () => (
    <Router>
        <Switch>
            {!COGNITO_ENABLED && (
                <Route path={LOGIN_PAGE} component={LoginPage} />
            )}

            <ProtectedRoute
                path={DASHBOARD}
                component={Dashboard}
                hasRole={DASHBOARD_PAGE}
            />
            <ProtectedRoute
                path={CONFIGURATION_MANAGEMENT}
                component={ConfigurationManagementPage}
                hasRole={VIEW_SYS_CONFIGS}
            />

            <ProtectedRoute
                path={PERMISSIONS_CONFIGURATION_MANAGEMENT}
                component={PermissionsManagementPage}
                hasRole={VIEW_SYS_CONFIGS}
            />

            <ProtectedRoute
                path={CREATE_ROLE}
                component={CreateRolePage}
                hasRole={CREATE_ROLES}
            />

            <ProtectedRoute
                path={CONTACT_US_CONFIGURATION}
                component={ContactUsConfigurationPage}
                hasRole={UPDATE_CONTACT_US}
            />
            <ProtectedRoute
                path={`${UPDATE_CONFIGURATION_MANAGEMENT}/:key`}
                component={UpdateConfigurationManagementPage}
                hasAnyRole={[UPDATE_SYS_CONFIGS, LOGO_CONFIG_UPLOAD]}
            />
            <ProtectedRoute
                path={CONTACT_US_PAGE}
                component={ContactUsPage}
                hasRole={CONTACT_US}
            />
            <ProtectedRoute
                path={CREATE_USER}
                component={CreateUser}
                hasRole={CREATE_USERS}
            />
            <ProtectedRoute
                path={`${UPDATE_USER}/:username`}
                component={UpdateUser}
                hasAnyRole={[CREATE_USERS, VIEW_USERS]}
            />
            <ProtectedRoute
                path={USER_MANAGEMENT}
                component={UsersPage}
                hasRole={LIST_USERS}
            />
            <ProtectedRoute
                path={GAMES}
                component={GameCatalogPage}
                hasRole={GAME_PAGE}
            />

            <ProtectedRoute
                path={RETAILER_PLANOGRAM_LIST}
                component={RetailerPlanogramListPage}
                hasRole={ROLE_PLANOGRAM_LIST}
            />

            <ProtectedRoute
                path={`${ORDER}/:id`}
                component={OrderDetailPage}
                hasRole={ORDER_DETAILS}
            />

            <ProtectedRoute
                path={orderTabs.IN_PROGRESS}
                component={OrderHistoryPage}
                hasRole={IN_PROGRESS_ORDERS}
            />

            <ProtectedRoute
                path={orderTabs.PAST}
                component={OrderHistoryPage}
                hasRole={PAST_ORDERS}
            />

            <ProtectedRoute
                path={`${INVOICE}/:id`}
                component={InvoiceDetailPage}
                hasRole={INVOICE_DETAILS}
            />

            <ProtectedRoute
                path={INVOICE}
                component={InvoicesPage}
                hasRole={INVOICE_PAGE}
            />

            <ProtectedRoute
                path={`${REPORTS}/:name/:id`}
                component={ReportPage}
                hasRole="*"
            />

            <ProtectedRoute
                path={REPORTS}
                component={ReportsPage}
                hasRole={LIST_REPORT}
            />

            <ProtectedRoute
                path={FAQ_MANAGEMENT_PRIVATE}
                component={AdminFaqPage}
                hasRole={CREATE_FAQ}
            />

            <ProtectedRoute
                path={FAQ_MANAGEMENT_PUBLIC}
                component={AdminFaqPage}
                hasRole={CREATE_FAQ}
            />

            <ProtectedRoute path={FAQ_PAGE} component={FaqPage} hasRole={FAQ} />

            <ProtectedRoute
                path={PRIVACY_POLICY_PAGE}
                component={PrivacyPolicyPage}
                hasRole={PRIVACY_POLICY}
            />

            <ProtectedRoute
                path={PRIVACY_POLICY_MANAGEMENT}
                component={AdminPrivacyPolicyPage}
                hasRole={CREATE_PRIVACY_POLICY}
            />
            <ProtectedRoute
                path={NEW_RETAILER_POLICY}
                component={AdminNewRetailerPage}
                hasRole={CREATE_NEW_RETAILER_POLICY}
            />

            <ProtectedRoute
                path={UPLOAD_MANAGEMENT}
                component={DocumentListPage}
                hasRole={CREATE_PRIVACY_POLICY}
            />

            <ProtectedRoute
                path={GAMES_LIST}
                component={GamesListPage}
                hasRole={DISPLAY_GAMES_LIST}
            />
            {
                // TO BE COMPLETED IN A FUTURE STORY
                // <ProtectedRoute
                //     path={VIRTUAL_TOUR_CONFIGURATION}
                //     component={VirtualTourConfigPage}
                //     hasRole={UPDATE_SYS_CONFIGS}
                // />
            }
            <ProtectedRoute
                path={MESSAGE_MANAGEMENT}
                component={MessageListPage}
                hasRole={UPDATE_SYS_CONFIGS}
            />

            <ProtectedRoute
                path={CHECKOUT}
                component={CheckoutPage}
                hasRole={GAME_PAGE}
            />
            <ProtectedRoute
                path={DASHBOARD_SETTINGS}
                component={DashboardSettings}
                hasRole={DASHBOARD_PAGE}
            />
            <ProtectedRoute
                path={RETAILER_GROUP_CREATE}
                component={EditRetailerGroupPage}
                hasRole={[CREATE_RETAILER_GROUP_LIST]}
            />
            <ProtectedRoute
                path={`${RETAILER_GROUP_UPDATE}/:id`}
                component={EditRetailerGroupPage}
                hasRole={[UPDATE_RETAILER_GROUP_LIST, VIEW_RETAILER_GROUP_LIST]}
            />
            <ProtectedRoute
                path={RETAILER_GROUP_LIST}
                component={RetailerGroupListPage}
                hasRole={DISPLAY_RETAILER_GROUP_LIST}
            />

            <ProtectedRoute
                path={COURIER_CREATE}
                component={EditCourierPage}
                hasRole={[ROLE_COURIER_CREATE]}
            />
            <ProtectedRoute
                path={`${COURIER_UPDATE}/:id`}
                component={EditCourierPage}
                hasRole={[ROLE_COURIER_UPDATE, ROLE_COURIER_VIEW]}
            />
            <ProtectedRoute
                path={COURIER_LIST}
                component={CourierListPage}
                hasRole={ROLE_COURIER_LIST}
            />

            <ProtectedRoute
                path={PLANOGRAM_CREATE}
                component={EditPlanogramPage}
                hasRole={[ROLE_PLANOGRAM_CREATE]}
            />
            <ProtectedRoute
                path={`${PLANOGRAM_UPDATE}/:id`}
                component={EditPlanogramPage}
                hasRole={[ROLE_PLANOGRAM_UPDATE, ROLE_PLANOGRAM_VIEW]}
            />
            <ProtectedRoute
                path={PLANOGRAM_LIST}
                component={PlanogramListPage}
                hasRole={ROLE_PLANOGRAM_LIST}
            />
            <ProtectedRoute
                path={`${RETAILER_RETURN_CREATE}/:id`}
                component={ViewReturn}
                hasRole={ROLE_RETURN_VIEW}
            />
            <ProtectedRoute
                path={RETAILER_RETURN_CREATE}
                component={CreateReturn}
                hasRole={ROLE_RETURN_CREATE}
            />
            <ProtectedRoute
                path={RETAILER_RETURN_LIST}
                component={ReturnsListPage}
                hasRole={ROLE_RETURN_LIST}
            />

            <Route path={GETTING_STARTED} component={GettingStartedPage} />
            <Route path={UNAUTHORIZED} component={Unauthorized} />
            <Route path={NOT_FOUND} component={NotFound} />
            {COGNITO_ENABLED && (
                <Route exact path="/" component={CognitoLoadingScreen} />
            )}
            {!COGNITO_ENABLED && (
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to={hasPermission()} />}
                />
            )}

            <Route render={() => <Redirect to={NOT_FOUND} />} />
        </Switch>
    </Router>
);

export default Routes;
