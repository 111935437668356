import React from 'react';
import UneditableLabel from './UneditableLabel';
import { Form } from 'semantic-ui-react';

const UneditableInput = ({
    label,
    data,
    dataClassName,
    callback,
    additionalTextStyle,
}) => {
    const { dataText } = style;
    return (
        <Form.Field className="readonly">
            <UneditableLabel text={label} />
            {dataClassName &&
            dataClassName.includes('button') &&
            !dataClassName.includes('button-like') ? (
                <Form.Button secondary onClick={callback}>
                    {data}
                </Form.Button>
            ) : (
                <div style={dataText} className={dataClassName}>
                    <span onClick={callback} style={additionalTextStyle}>
                        {data}
                    </span>
                </div>
            )}
        </Form.Field>
    );
};

const style = {
    dataText: {
        opacity: 1,
    },
};

export default UneditableInput;
