import type { UserRetailer } from '../../../DTOs/user-retailer';

export const UPDATE_USER_RETAILERS = 'UserRetailer/update';
export const CHANGE_ACTIVE_USER_RETAILERS = 'UserRetailer/change-active';
export const UPDATE_LOADING_STATE = 'UserRetailer/loading';
export const TOGGLE_SELECT_RETAILER_MODAL =
    'UserRetailer/toggleSelectRetailerModal';

export interface UserRetailerStateType {
    isLoading: boolean;
    userRetailer: UserRetailer[];
    activeRetailer: UserRetailer;
    isShowSelectRetailerModal: boolean;
}

interface UpdateUserRetailersType {
    type: typeof UPDATE_USER_RETAILERS;
    payload: UserRetailer[];
}

interface ChangeActiveUserRetailersType {
    type: typeof CHANGE_ACTIVE_USER_RETAILERS;
    payload: UserRetailer;
}

interface UpdateLoadingState {
    type: typeof UPDATE_LOADING_STATE;
    payload: boolean;
}

interface ToggleShowSelectRetailerModal {
    tye: ToggleShowSelectRetailerModal;
    payload: boolean;
}

export type UserRetailersActionTypes =
    | ChangeActiveUserRetailersType
    | UpdateLoadingState
    | UpdateUserRetailersType
    | ToggleShowSelectRetailerModal;
