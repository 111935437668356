import { UserApi } from '../../../services/user-service';
import { getErrorMessage } from '../../../constants/utils';
import { error, clearNotification } from '../notifications';
import _, { find } from 'lodash';

const userApi = new UserApi();

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const INIT_CREATE_USER = 'INIT_CREATE_USER';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CREATE_USER_LOADING_DONE = 'CREATE_USER_LOADING_DONE';

const createUserSuccess = () => {
    return (dispatch) => {
        dispatch({
            type: CREATE_USER_SUCCESS,
        });
    };
};

const createUserError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'CreateUser' }));
    };
};

const verifyUserSuccess = (data) => {
    return (dispatch) => {
        dispatch({
            type: VERIFY_USER_SUCCESS,
            userData: data,
        });
    };
};

export const createUser = (fields) => {
    return async (dispatch, getState) => {
        let success = true;

        dispatch({ type: CREATE_USER });
        try {
            const { userRoles, retailerSelect } = getState();

            find(retailerSelect.content, {
                reference: fields.retailer,
            });
            const userRetailers = [];
            _.map(fields.retailer, (retailerReference) => {
                userRetailers.push({
                    userRetailerId: { retailerReference: retailerReference },
                    retailerName: retailerSelect.selectedValues.find(
                        (retailer) => retailer.reference === retailerReference
                    ).name,
                });
            });
            //format roles
            fields = {
                ...fields,
                roles: [find(userRoles.roleData, { name: fields.roles })],
                userRetailers: userRetailers,
            };

            await userApi.createUpdateUser(fields);

            dispatch({ type: INIT_CREATE_USER });
            dispatch(createUserSuccess());
        } catch (err) {
            success = false;
            dispatch(createUserError(err));
        } finally {
            dispatch({ type: CREATE_USER_LOADING_DONE });
        }

        return success;
    };
};

export const verifyUser = (fields) => {
    return async (dispatch, getState) => {
        const username = fields.username.trim();
        dispatch(clearNotification());
        dispatch({ type: VERIFY_USER });
        try {
            const result = await userApi.verifyUser(username);
            dispatch(verifyUserSuccess(result));
            return result;
        } catch (err) {
            dispatch(createUserError(err));
            return err;
        } finally {
            dispatch({ type: CREATE_USER_LOADING_DONE });
        }
    };
};

export const initCreateUser = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_CREATE_USER });
    };
};

export const changeUsername = (fields) => {
    return async (dispatch) => {
        const prefilledText = 'Pre-filled';
        fields.firstName = prefilledText;
        fields.lastName = prefilledText;
        fields.email = prefilledText;

        dispatch({ type: CHANGE_USERNAME });
    };
};
