import { Backend } from './backend';
import type { DraftOrder } from '../DTOs/draft-order';

class DraftOrderApi {
    constructor() {
        this.path = '/retailers/order/draft';
    }

    create = async (draftOrder: DraftOrder) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}`,
                body: draftOrder,
            });
        } catch (e) {
            throw e;
        }
    };

    get = async (retailerReference: string) => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    };
}
export default DraftOrderApi;
