import { checkFilters } from './utils';
import { Backend } from './backend';

class TiersApi {
    constructor() {
        this.path = '/tiers';
    }

    async getAllTiers({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'modifiedDate',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getTierOptions() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTiersByTierIds(tierIds) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/retailers/by-tiers`,
                body: tierIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async createTier(tierToCreate) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/`,
                body: tierToCreate,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTierById(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTierByRetailerId(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/retailer/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTiersByRetailerIds(retailers) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/retailers?id=${JSON.stringify(retailers)}`,
                body: retailers,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateTier(tierToUpdate) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/${tierToUpdate.id}/`,
                body: tierToUpdate,
            });
        } catch (e) {
            throw e;
        }
    }

    async saveTierToRetailer(retailerId, tierObj) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/retailer/${retailerId}/`,
                body: tierObj,
            });
        } catch (e) {
            throw e;
        }
    }

    async getRetailerVisitsForWeek(week, retailers) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/visits/week/${week}`,
                body: retailers,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { TiersApi };
