import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { DocumentsApi } from '../../../services/document-upload-service';

export const DOCUMENT_LOADING = 'DOCUMENT_LOADING';
export const DOCUMENT_LOADING_DONE = 'DOCUMENT_LOADING_DONE';
export const DOCUMENT_INIT_INIT_STATE = 'DOCUMENT_INIT_INIT_STATE';
export const DOCUMENT_LIST = 'DOCUMENT_LIST';
export const DOCUMENT_ATTACHMENT_INIT = 'DOCUMENT_ATTACHMENT_INIT';
export const DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE =
    'DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE';
export const DOCUMENT_ATTACHMENT_UPLOAD_LOADING =
    'DOCUMENT_ATTACHMENT_UPLOAD_LOADING';

const documentsApi = new DocumentsApi();

const documentError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'DocumentListPage',
            })
        );
    };
};

export const uploadDocument = (formData) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: DOCUMENT_LOADING });
            await documentsApi.uploadPortalDocument(formData);
        } catch (err) {
            dispatch(documentError(err));
            success = false;
        } finally {
            dispatch({ type: DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const updateDocument = (data) => {
    const { id, description, category } = data;
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: DOCUMENT_LOADING });
            await documentsApi.updateDocument(id, description, category);
        } catch (err) {
            dispatch(documentError(err));
            success = false;
        } finally {
            dispatch({ type: DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const downloadDocument = (urlPath) => {
    return async (dispatch) => {
        try {
            //do not have loading here since it make the table look like its loading
            await documentsApi.downloadDocument(urlPath, urlPath); //use media key as the filename
        } catch (err) {
            console.error(err);
        }
    };
};

export const getDownloadUrl = (urlPath) => {
    return async (dispatch) => {
        try {
            //do not have loading here since it make the table look like its loading
            return await documentsApi.getDownloadUrl(urlPath, urlPath); //use media key as the filename
        } catch (err) {
            console.error(err);
        }
    };
};

export const deleteDocument = (attachmentId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: DOCUMENT_LOADING });
            await documentsApi.deleteDocument(attachmentId);
        } catch (err) {
            dispatch(documentError(err));
            success = false;
        } finally {
            dispatch({ type: DOCUMENT_LOADING_DONE });
        }

        return success;
    };
};

export const getAllDocuments = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DOCUMENT_LOADING });
            const data = await documentsApi.getAllDocuments(args);
            dispatch({
                type: DOCUMENT_LIST,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(documentError(err));
        } finally {
            dispatch({ type: DOCUMENT_LOADING_DONE });
        }
    };
};

export const initDocumentAttachmentUpload = (attachmentInfo) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: DOCUMENT_ATTACHMENT_UPLOAD_LOADING });

            const options = await documentsApi.getDocumentCategories();

            //if have attachmentInfo its an update else its create
            dispatch({
                type: DOCUMENT_ATTACHMENT_INIT,
                options,

                attachment: attachmentInfo,
            });
        } catch (err) {
            console.error(err);
            dispatch(documentError(err));
        } finally {
            dispatch({ type: DOCUMENT_ATTACHMENT_UPLOAD_LOADING_DONE });
        }
    };
};

export const initDocumentUploadState = () => ({
    type: DOCUMENT_ATTACHMENT_INIT,
    options: [],
    attachment: undefined,
});
