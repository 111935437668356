import { Backend } from './backend';
import { checkFilters } from './utils';

class SurveyApi {
    constructor() {
        this.path = '/surveys';
    }

    async searchSurveys({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'name',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurveyTypes() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/types`,
            });
        } catch (e) {
            throw e;
        }
    }

    async createSurvey(survey) {
        try {
            let formData = new FormData();
            formData.append('surveyType', survey.surveyType);
            formData.append('name', survey.name);
            formData.append('description', survey.description);
            formData.append('status', survey.status);

            return await Backend.sendReturnWithCompleteResponse({
                method: 'POST',
                url: `${this.path}/`,
                body: formData,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    /****
     * sample request body
     * [
     *     {
     *         "text": "This is a test question",
     *             "questionType": "yes_no"
     *     },
     *     {
     *         "text": "This is another test question",
     *             "questionType": "range",
     *             "options": "{ \n\t\"min\": 0,\n\t\"max\": 10\n}"
     *     }
     * ]
     * Please note: Values for questionType and options are completely up
     * to front end development and are to be used to store and recreate questions of any conceivable configuration.
     * options must be submitted as an escaped JSON string.
     ****/
    async addQuestionsToSurvey(surveyUrl, questions) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${surveyUrl}/questions`,
                body: questions,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurvey(surveyId) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${surveyId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getQuestionsForSurvey(surveyId) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${surveyId}/questions`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSurveyQuestions(surveyQuestions) {
        try {
            let formData = new FormData();
            formData.append('questionType', surveyQuestions.questionType);
            formData.append('text', surveyQuestions.text);
            formData.append('options', surveyQuestions.options);
            formData.append('subText', surveyQuestions.subText || null);
            formData.append('sortOrder', surveyQuestions.sortOrder);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/questions/${surveyQuestions.id}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateBulkSurveyQuestions(surveyId, questions) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${surveyId}/questions/update-all`,
                body: questions,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSurvey(survey) {
        try {
            let formData = new FormData();
            formData.append('name', survey.name);
            formData.append('description', survey.description);
            formData.append('status', survey.status);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${survey.id}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async createSurveyResponse(surveyId) {
        try {
            return await Backend.sendReturnWithCompleteResponse({
                method: 'POST',
                url: `${this.path}/${surveyId}/responses`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSurveyAnswersByUrl(responseUrl, answers) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}${responseUrl}/answers`,
                body: answers,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSurveyAnswersById(responseId, answers) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/responses/${responseId}/answers`,
                body: answers,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurveyAnswers(responseId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/responses/${responseId}/answers`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurveyAnswersCondensed(responseId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/responses/${responseId}/answers/condensed`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurveysForIds(surveyIds) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/by-ids?ids=${JSON.stringify(surveyIds)}`,
                body: surveyIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSurveyAnswersForIds(responseIds) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/responses/by-ids`,
                body: responseIds,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteMarketingSurvey(surveyId) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/surveys/${surveyId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async exportSurvey(surveyId) {
        try {
            return await Backend.download(
                {
                    method: 'POST',
                    url: `${this.path}/export/${surveyId}`,
                },
                null,
                'text/csv'
            );
        } catch (e) {
            throw e;
        }
    }
}

export { SurveyApi };
