import { getErrorMessage } from '../../../constants/utils';
import TourServiceApi from '../../../services/tour-service/tour-service';
import { error } from '../notifications';

export const TOUR_CONFIGURATION_LOADING = 'TOUR_CONFIGURATION_LOADING';
export const TOUR_CONFIGURATION_LOADING_DONE =
    'TOUR_CONFIGURATION_LOADING_DONE';
export const TOUR_CONFIGURATION_GET = 'TOUR_CONFIGURATION_GET';
export const TOUR_CONFIGURATION_INIT = 'TOUR_CONFIGURATION_INIT';

const tourServiceApi = new TourServiceApi();

export const getTourConfiguration = () => {
    return async (dispatch) => {
        dispatch({ type: TOUR_CONFIGURATION_LOADING });

        try {
            dispatch({
                type: TOUR_CONFIGURATION_GET,
                payload: await tourServiceApi.getTourData(),
            });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: TOUR_CONFIGURATION_LOADING_DONE });
    };
};

export const setTourConfiguration = (tourData) => {
    return async (dispatch) => {
        let success = true;

        dispatch({ type: TOUR_CONFIGURATION_LOADING });

        try {
            await tourServiceApi.setTourData(tourData);
        } catch (err) {
            success = false;
            console.error(err);
            dispatch(error({ msg: getErrorMessage(err) }));
        }

        dispatch({ type: TOUR_CONFIGURATION_LOADING_DONE });

        return success;
    };
};

export const initTourConfiguration = () => ({
    type: TOUR_CONFIGURATION_INIT,
});
