import { checkFilters } from './utils';
import { Backend } from './backend';

class PlanogramApi {
    constructor() {
        this.path = '/planograms';
    }

    async createPlanogramGroup(planogram) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/groups`,
                body: planogram,
            });
        } catch (e) {
            throw e;
        }
    }

    async uploadPlanogram(planogram, id) {
        try {
            let formData = new FormData();
            formData.append('name', planogram.name);
            formData.append('file', planogram.file);
            formData.append('description', planogram.description);
            formData.append('start', planogram.start);
            formData.append('end', planogram.end);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/groups/${id}/planograms`,
                body: formData,
                timeout: 120000,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    async getPlanogramById(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPlanogramsByRetailerReference(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/groups/retailer/group/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePlanogramGroup(planogram) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/groups/${planogram.id}`,
                body: planogram,
            });
        } catch (e) {
            throw e;
        }
    }

    async updatePlanogramMetadata(planogram) {
        let formData = new FormData();
        formData.append('description', planogram.description);
        formData.append('start', planogram.startDate);
        formData.append('end', planogram.endDate);
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/${planogram.id}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async deletePlanogram(id) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getPlanogramGroupById(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/groups/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllPlanogramGroups({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/groups/search`,
                body: {
                    page,
                    size: size < 1 ? 10 : size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'modifiedDate',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllPlanograms(
        { page, size, sortOrder, sortColumn, filters, query },
        planogramGroupId
    ) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search${
                    planogramGroupId ? `?gid=${planogramGroupId}` : ''
                }`,
                body: {
                    page,
                    size: size < 1 ? 10 : size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'modifiedDate',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }
}

export { PlanogramApi };
