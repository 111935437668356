import { PlanogramApi } from '../../../services/planogram-group-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const PLANOGRAM_PROCESSING = 'PLANOGRAM_PROCESSING';
export const PLANOGRAM_CREATE_SUCCESS = 'PLANOGRAM_CREATE_SUCCESS';
export const PLANOGRAM_INIT = 'PLANOGRAM_INIT';
export const PLANOGRAM_GET_SUCCESS = 'PLANOGRAM_GET_SUCCESS';
export const UPLOAD_PLANOGRAM_LOADING = 'UPLOAD_PLANOGRAM_LOADING';
export const UPLOAD_PLANOGRAM_LOADING_DONE = 'UPLOAD_PLANOGRAM_LOADING_DONE';

const planogramApi = new PlanogramApi();

const uploadPlanogramError = (errorMsg) => {
    return (dispatch) => {
        dispatch(error({ msg: errorMsg, target: 'ReadPlanogramGroup' }));
    };
};

export const uploadPlanogram = (uploadPlanogramForm) => {
    return async (dispatch, getState) => {
        let success = true;
        dispatch({ type: PLANOGRAM_PROCESSING });
        try {
            const { uploadPlanogram } = getState();
            const planogram = await planogramApi.uploadPlanogram(
                uploadPlanogramForm,
                uploadPlanogram.planogramGroupId
            );
            dispatch({
                type: PLANOGRAM_CREATE_SUCCESS,
                planogram,
            });
        } catch (e) {
            success = false;
            console.error(e);
            const errorMsg = getErrorMessage(e);
            const errorTimeOutMsg =
                'Upload could not be completed due to slow network and large file size. Please try to upload a smaller file.';
            dispatch(
                uploadPlanogramError(
                    errorMsg.includes('timeout') ? errorTimeOutMsg : errorMsg
                )
            );
        } finally {
            dispatch({ type: UPLOAD_PLANOGRAM_LOADING_DONE });
        }

        return success;
    };
};

export const initUploadPlanogram = (planogramGroup) => {
    return async (dispatch) => {
        dispatch({ type: PLANOGRAM_INIT, id: planogramGroup });
    };
};

export const deletePlanogram = (planogramId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: PLANOGRAM_PROCESSING });
            await planogramApi.deletePlanogram(planogramId);
        } catch (err) {
            dispatch(uploadPlanogramError(err));
            success = false;
        } finally {
            dispatch({ type: UPLOAD_PLANOGRAM_LOADING_DONE });
        }

        return success;
    };
};
