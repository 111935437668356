import { UserApi } from '../../services/user-service';

const userApi = new UserApi();

export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';

const getUserRolesSuccess = (data) => {
    return (dispatch) => {
        dispatch({
            type: GET_USER_ROLES_SUCCESS,
            data,
        });
    };
};

export const getUserRoles = () => {
    return async (dispatch, getState) => {
        dispatch({ type: GET_USER_ROLES });
        try {
            const result = await userApi.getUserRoles();
            return dispatch(getUserRolesSuccess(result));
        } catch (err) {
            return null;
        }
    };
};
