import { GamesApi } from '../../../../../services/games-service';
export const CLOSING_GAMES_LOADING = 'CLOSING_GAMES_LOADING';
export const CLOSING_GAMES = 'CLOSING_GAMES';
export const CLOSING_GAMES_INIT = 'CLOSING_GAMES_INIT';
export const CLOSING_GAMES_ERROR = 'CLOSING_GAMES_ERROR';

const gamesApi = new GamesApi();

export const initClosingGamesForRetailerReference = () => {
    return async (dispatch) => {
        dispatch({ type: CLOSING_GAMES_INIT });
    };
};

export const getClosingGamesForRetailerReference = (
    retailerType,
    jurisdictionCode
) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CLOSING_GAMES_LOADING });
            const closingGamesForRetailer = await gamesApi.getClosingGames(
                retailerType,
                jurisdictionCode
            );
            dispatch({
                type: CLOSING_GAMES,
                closingGamesForRetailer,
            });
        } catch (e) {
            dispatch({
                type: CLOSING_GAMES_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
