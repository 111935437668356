import {
    ERROR_HANDLER_ERROR,
    ERROR_HANDLER_CLEAR,
} from '../../actions/errorHandler/errorHandler';

const INITIAL_STATE = {
    msg: null,
};

const errorHandler = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ERROR_HANDLER_ERROR:
            return { ...INITIAL_STATE, msg: action.msg };
        case ERROR_HANDLER_CLEAR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default errorHandler;
