import { Backend } from './backend';

class I18nApi {
    constructor() {
        this.path = '/i18n';
    }

    async getSupportedLocales() {
        try {
            return await Backend.sendNoAuth({
                method: 'get',
                url: `${this.path}/locales`,
                noAuth: true,
            });
        } catch (e) {
            throw e;
        }
    }

    async getLocalizedResources(locale) {
        try {
            return await Backend.sendNoAuth({
                method: 'get',
                url: `${this.path}/locales/${locale}`,
                noAuth: true,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { I18nApi };
