import { SalesRoutesApi } from '../../services/sales-routes-service';

export const SALES_ROUTE_OPTIONS_LOADING = 'SALES_ROUTE_OPTIONS_LOADING';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';

const salesRoutesApi = new SalesRoutesApi();

export const getAllActiveSalesRoutes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: SALES_ROUTE_OPTIONS_LOADING });

            const options = await salesRoutesApi.getAllActiveSalesRoutes();

            dispatch({
                type: GET_OPTIONS_SUCCESS,
                options,
            });
        } catch (e) {
            console.error(e);
        }
    };
};
