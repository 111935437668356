import { TaskApi } from '../../services/task-service';

import { getErrorStatus } from '../../constants/utils';
import { errorHandler } from './errorHandler/errorHandler';

export const TASKS_LIST = 'TASKS_LIST';
export const TASKS_LIST_ME = 'TASKS_LIST_ME';
export const TASKS_DRAFTS_LIST = 'TASKS_DRAFTS_LIST';
export const TASKS_LIST_LOADING = 'TASKS_LIST_LOADING';
export const TASKS_LIST_ME_LOADING = 'TASKS_LIST_ME_LOADING';
export const TASKS_DRAFTS_LIST_LOADING = 'TASKS_DRAFTS_LIST_LOADING';
export const TASKS_CREATE = 'TASKS_CREATE';

const taskApi = new TaskApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

const draftErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchTasksCreatedByMe = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TASKS_LIST_ME_LOADING });
            const list = await taskApi.getAllTasksCreatedByMe(args);
            dispatch({
                type: TASKS_LIST_ME,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchTaskDrafts = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TASKS_DRAFTS_LIST_LOADING });
            const list = await taskApi.getAllTasks(args);
            dispatch({
                type: TASKS_DRAFTS_LIST,
                list,
            });
        } catch (e) {
            dispatch(draftErrorHandlerError(e));
        }
    };
};

export const fetchAllTasks = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TASKS_LIST_LOADING });
            const list = await taskApi.getAllTasks(args);
            dispatch({
                type: TASKS_LIST,
                list,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};
