import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { onlyAllowNumericAndLettersInput } from '../../../constants/keyPressEvents';

class TicketRangeScanForm extends Component {
    state = {
        returnTicketFrom: '',
        returnTicketTo: '',
    };

    constructor(props) {
        super(props);

        this.scannerFromField = React.createRef();
        this.scannerToField = React.createRef();
    }

    handleTicketFromChange = (event, { value }) => {
        this.setState({ returnTicketFrom: value });
    };

    handleTicketFromKeyDown = (event) => {
        if (event.key === 'Enter') {
            const { returnTicketFrom } = this.state;

            if (returnTicketFrom) {
                this.scannerToField && this.scannerToField.current.select();
            }
        }
    };

    handleTicketToChange = (event, { value }) => {
        this.setState({ returnTicketTo: value });
    };

    handleTicketToKeyDown = async (event) => {
        if (event.key === 'Enter') {
            const { onSubmit } = this.props;
            const { returnTicketFrom, returnTicketTo } = this.state;

            if (returnTicketTo) {
                if (onSubmit) {
                    await onSubmit({ returnTicketFrom, returnTicketTo });
                    this.clearScannerFields();
                }
            }
        }
    };

    clearScannerFields() {
        this.setState({ returnTicketFrom: '', returnTicketTo: '' });
        this.scannerFromField && this.scannerFromField.current.select();
    }

    render() {
        const { returnTicketFrom, returnTicketTo } = this.state;
        const { processing } = this.props;

        return (
            <Form loading={processing}>
                <Form.Field className="return-flex-row space-between">
                    <Input
                        name="returnTicketFrom"
                        fluid
                        placeholder="From"
                        className="bg-transparent pending-return-input"
                        onKeyPress={onlyAllowNumericAndLettersInput}
                        onKeyDown={(event) =>
                            this.handleTicketFromKeyDown(event)
                        }
                        onChange={this.handleTicketFromChange}
                        autoFocus
                        ref={this.scannerFromField}
                        value={returnTicketFrom}
                        disabled={processing}
                    />
                    &nbsp;
                    <Input
                        name="returnTicketTo"
                        fluid
                        placeholder="To"
                        className="bg-transparent pending-return-input"
                        //   onKeyPress={onlyAllowNumericAndLettersInput}
                        onKeyDown={(event) => this.handleTicketToKeyDown(event)}
                        onChange={this.handleTicketToChange}
                        ref={this.scannerToField}
                        value={returnTicketTo}
                        disabled={processing}
                    />
                </Form.Field>
            </Form>
        );
    }
}

export default TicketRangeScanForm;
