import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import type {
    Query,
    SearchSpecificationModel,
    Sort,
} from '../../interface/search-specification-model';
import {
    clearPlanograms,
    getPlanogramPage,
} from '../../redux/actions/planogram/planogram-actions';
import type { Planogram } from '../../DTOs/planogram';
import { getRetailerGroupList } from '../../redux/actions/retailer-group/retailer-group-actions';
import type { RetailerGroup } from '../../DTOs/retailer-group';
import FilterSection from '../../components/filter/FilterSection';
import { map } from 'lodash';
import _ from 'lodash';
import { downloadDocument } from '../../redux/actions';
import RetailerPlanogramDetailModel from './retailer-planogram-detail-model';
import Status from './status';
import {
    createDateRangeQuery,
    getCurrentStatusQuery,
    getIsActiveQuery,
    getPreviousQuery,
    getUpcomingQuery,
    myPlanogramQuery,
} from '../../utils/planogram/planograme-query-helper';
import { clearFilters } from '../../components/filter/actions';
import { ResponsiveTable } from '../../components/datatable/responsive-table/responsive-table';
import './style.css';
import { formatToDisplayDate } from '../../constants/utils';
const RetailerPlanogramListPage = () => {
    const dispatch = useDispatch();
    const retailer = useSelector((state) => state.userInfo.retailerInfo);
    const intialQuery = {
        page: 0,
        size: 10,
        sort: [
            {
                direction: 'DESC',
                property: 'modifiedDate',
            },
        ],
        query: [
            myPlanogramQuery(retailer?.numberOfFacings, retailer?.jurisdiction),
            getCurrentStatusQuery(),
        ],
    };

    const [searchQuery: SearchSpecificationModel, setSearchQuery] =
        useState(intialQuery);
    useEffect(() => {
        dispatch(getPlanogramPage(searchQuery));
        return () => {
            dispatch(clearPlanograms());
        };
    }, [searchQuery, dispatch, retailer]);
    useEffect(() => {
        dispatch(getRetailerGroupList());
        return () => {
            dispatch(clearPlanograms());
            dispatch(clearFilters());
        };
    }, [dispatch]);

    const [detailPlanogram: Planogram, setDetailPlanogram] =
        useState(undefined);
    const planogramPage: Page<Planogram> = useSelector(
        (state) => state.planogram.planogramPage
    );

    const isLoading: Page<Planogram> = useSelector(
        (state) => state.planogram.isLoading
    );

    const retailerGroups: RetailerGroup[] = useSelector(
        (state) => state.retailerGroup.retailerGroupList
    );

    const handleSelectTablePage = ({
        size,
        page,
        sortOrder,
        sortColumn,
        query,
    }) => {
        searchData(size, page, sortOrder, sortColumn, searchQuery.query);
    };

    const searchData = (
        size,
        page,
        sortOrder,
        sortColumn,
        queryObject: Query
    ) => {
        const sort: Sort[] =
            sortOrder && sortColumn
                ? [
                      {
                          direction: sortOrder.toUpperCase(),
                          property: sortColumn,
                      },
                  ]
                : [
                      {
                          direction: 'DESC',
                          property: 'modifiedDate',
                      },
                  ];
        setSearchQuery({
            page: page < 0 ? 0 : page,
            size,
            sort: sort,
            query: queryObject,
        });
    };

    const handleFilterApply = (selectedItems, query) => {
        const queryObject: Query[] = [getIsActiveQuery()];

        if (selectedItems.planograms && selectedItems.planograms.length > 0) {
            if (
                selectedItems.planograms.length === 1 &&
                selectedItems.planograms[0] === 'My Planograms'
            ) {
                queryObject.push(
                    myPlanogramQuery(
                        retailer.numberOfFacings,
                        retailer.jurisdiction
                    )
                );
            }
        }
        if (selectedItems.group && selectedItems.group.length > 0) {
            queryObject.push(
                createQueryForObject(selectedItems.group, 'retailerGroup.id')
            );
        }

        if (selectedItems.range && selectedItems.range.length > 0) {
            queryObject.push(
                createDateRangeQuery(
                    selectedItems.range[0],
                    selectedItems.range.length === 2
                        ? selectedItems.range[1]
                        : selectedItems.range[0]
                )
            );
        }

        if (selectedItems.status && selectedItems.status.length > 0) {
            let statusQuery: Query[] = [];
            _.each(selectedItems.status, (status) => {
                if (status === 'Current') {
                    statusQuery.push(getCurrentStatusQuery());
                } else if (status === 'Upcoming') {
                    statusQuery.push(getUpcomingQuery());
                } else if (status === 'Previous') {
                    statusQuery.push(getPreviousQuery());
                }
            });
            if (statusQuery.length === 1) {
                queryObject.push(statusQuery[0]);
            } else {
                queryObject.push({
                    value: statusQuery,
                    operator: 'or',
                });
            }
        }

        let finalQuery: Query = undefined;
        if (queryObject.length === 1) {
            finalQuery = queryObject;
        } else if (queryObject.length > 1) {
            finalQuery = [
                {
                    operator: 'and',
                    value: queryObject,
                },
            ];
        }

        searchData(
            planogramPage?.size,
            0,
            planogramPage?.sort ? [0].direction : undefined,
            planogramPage?.sort ? [0].property : undefined,
            finalQuery
        );
    };

    const createQueryForObject = (query: [], property: string) => {
        if (query.length === 1) {
            const innerQuery: Query = {
                value: `${query[0]}`,
                operator: 'eq',
                property: property,
            };
            return innerQuery;
        } else {
            let values = [];
            _.each(query, (group) => {
                const innerQuery: Query = {
                    value: `${group}`,
                    operator: 'eq',
                    property: property,
                };
                values.push(innerQuery);
            });
            const orQuery: Query = {
                operator: 'or',
                value: values,
            };
            return orQuery;
        }
    };

    const handleClearFilters = () => {
        setSearchQuery(intialQuery);
    };

    const download = (urlPath) => {
        console.debug(`received urlPath ${urlPath}`);

        //need to strip out the beginning '/' if exists
        const formattedUrlPath = checkDownloadPath(urlPath);

        console.debug(`downloading file for path ${formattedUrlPath}`);
        dispatch(downloadDocument(formattedUrlPath));
    };

    const checkDownloadPath = (urlPath) => {
        return urlPath[0] === '/' ? urlPath.substr(1) : urlPath;
    };
    const actions = [
        {
            text: `Download PDF`,
            handleClick: (data) => download(data),
            handleClickParams: 'mediaKey',
        },
        {
            text: `View Details`,
            handleClick: (p) => {
                setDetailPlanogram(
                    planogramPage?.content.find(
                        (planogram) => planogram.id === p
                    )
                );
            },
            handleClickParams: 'id',
        },
    ];
    return (
        <Page
            name="RetailerPlanogramListPage"
            title="Planograms"
            data-test="RetailerPlanogramListPage"
            className={'retailer-planogram-list-page'}
        >
            <FilterSection
                filters={[
                    {
                        label: 'Groups: ',
                        name: 'group',
                        multiple: true,
                        toggleAll: true,
                        client: true,
                        searchable: true,
                        items: map(retailerGroups, ({ name, id }) => ({
                            text: name,
                            value: id,
                        })),
                        defaultSelected: '',
                    },
                    {
                        label: 'Range: ',
                        name: 'range',

                        defaultSelected: '',
                        clearable: true,
                        isDateRange: true,
                    },
                    {
                        label: 'Status: ',
                        name: 'status',
                        multiple: true,
                        toggleAll: true,
                        client: true,
                        searchable: true,
                        items: map(
                            [
                                { name: 'Current' },
                                { name: 'Upcoming' },
                                { name: 'Previous' },
                            ],
                            ({ name }) => ({
                                text: name,
                                value: name,
                            })
                        ),
                        defaultSelected: 'Current',
                    },
                    {
                        label: 'Planograms: ',
                        name: 'planograms',
                        multiple: true,
                        toggleAll: true,
                        client: true,
                        searchable: true,
                        items: map(
                            [
                                { name: 'My Planograms' },
                                { name: 'All Planogram' },
                            ],
                            ({ name }) => ({
                                text: name,
                                value: name,
                            })
                        ),
                        defaultSelected: 'My Planograms',
                    },
                ]}
                onApplyFilter={handleFilterApply}
                onClearFilters={handleClearFilters}
            />

            <ResponsiveTable
                contentKey="id"
                actionsMenu={actions}
                loading={isLoading}
                content={planogramPage?.content}
                page={planogramPage?.number}
                size={planogramPage?.size}
                totalElements={planogramPage?.totalElements}
                totalPages={planogramPage?.totalPages}
                supressSearch={true}
                noDataMessage={'There are no Planograms'}
                selector={{
                    variables: ['id'],
                    type: 'callbackWithId',
                    callback: (p) => {
                        setDetailPlanogram(
                            planogramPage?.content.find(
                                (planogram) => planogram.id === p
                            )
                        );
                    },
                }}
                columns={[
                    {
                        name: 'status',
                        title: 'Status',
                        sortable: false,
                        searchable: false,
                        markup: true,
                        tooltip: (value, { startDate, endDate }) => {
                            return (
                                <Status
                                    text={true}
                                    icon={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            );
                        },
                        mobileFormatter: (value, { startDate, endDate }) => {
                            return (
                                <Status
                                    text={true}
                                    icon={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            );
                        },
                        formatter: (val, { startDate, endDate }) => {
                            return (
                                <Status
                                    icon={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            );
                        },
                        mobileViewOrder: 2,
                    },
                    {
                        name: 'name',
                        title: 'Name',
                        sortable: true,
                        searchable: true,
                        mobileViewOrder: 1,
                    },
                    {
                        name: 'description',
                        title: 'Description',
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'startDate',
                        title: 'Start Date',
                        sortable: true,
                        searchable: true,
                        mobileViewOrder: 3,
                        formatter: (val) => formatToDisplayDate(val),
                    },

                    {
                        name: 'endDate',
                        title: 'End Date',
                        sortable: true,
                        searchable: true,
                        mobileViewOrder: 4,
                        formatter: (val) => formatToDisplayDate(val),
                    },

                    {
                        name: 'retailerGroup.minFacings',
                        title: 'No. of Facings',
                        sortable: true,
                        searchable: true,
                        formatter: (val, { retailerGroup }) => {
                            return retailerGroup.maxFacings
                                ? `${retailerGroup.minFacings} - ${retailerGroup.maxFacings}`
                                : `${retailerGroup.minFacings}+`;
                        },
                        mobileViewOrder: 6,
                    },
                    {
                        name: 'retailerGroup.name',
                        title: 'Retailer Groups',
                        sortable: true,
                        searchable: true,
                        mobileViewOrder: 5,
                    },
                ]}
                fetchData={handleSelectTablePage}
            />

            <RetailerPlanogramDetailModel
                isOpen={detailPlanogram !== undefined}
                planogram={detailPlanogram}
                onCloseCallback={() => {
                    setDetailPlanogram(undefined);
                }}
            />
        </Page>
    );
};
export default RetailerPlanogramListPage;
