import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, max, truncate } from 'lodash';
import Widget from '../Widget';
import { formatMoney } from '../../../../constants/utils';
import { getTotalpurchaseYTD, pushReferrer } from '../../../../redux/actions';
import NoItems from '../../../../components/NoItems';

import './styles.css';
import ErrorPage from '../../../../components/ErrorPage/ErrorPage';
import { Icon } from 'semantic-ui-react';

export class TotalPurchaseYTDWidget extends Component {
    async componentWillMount() {
        await this.init();
    }

    init = async () => {
        const { getTotalpurchaseYTD } = this.props;
        await getTotalpurchaseYTD(this.props.retailerInfo?.reference);
    };
    async componentDidUpdate(prevProps, prevState) {
        if (this.props.retailerInfo !== prevProps.retailerInfo) {
            await this.init();
        }
    }
    renderWidgetBar = (max, value) => {
        return (
            <div
                className="total-percentage-bar text-right"
                style={{ width: `${(value / max) * 100}%`, minWidth: '7%' }}
            >
                <div className="bar-text">
                    <span>{value}</span>
                </div>
            </div>
        );
    };

    render() {
        const { loading, totalPurchaseYTDs, errorResponse } = this.props;

        const noContent = !totalPurchaseYTDs && totalPurchaseYTDs?.length > 0;

        let maxTotal = 0;

        if (!noContent) {
            maxTotal = max(map(totalPurchaseYTDs, 'itemCount'));
        }
        return (
            <Widget loading={loading} data-test="totalPurchaseYTD">
                <div className="bold widget-heading">
                    Total Purchase YTD
                    <div className="widget-title-refresh-button">
                        <Icon name="refresh" onClick={this.init} />
                    </div>
                </div>

                {!totalPurchaseYTDs && errorResponse !== null ? (
                    <ErrorPage msg={errorResponse?.status} />
                ) : totalPurchaseYTDs && totalPurchaseYTDs.length > 0 ? (
                    <div
                        className="total-purchase-YTD-widget-content widget-container-height"
                        data-test="withData"
                    >
                        <table>
                            <tbody>
                                {map(totalPurchaseYTDs, (TotalPurchaseYTD) => (
                                    <tr
                                        className="task-completion-bar"
                                        key={TotalPurchaseYTD.itemName}
                                    >
                                        <td>
                                            <div className="game-info">
                                                <div className="bold price-point">
                                                    {formatMoney(
                                                        TotalPurchaseYTD.itemPrice
                                                    )}
                                                </div>
                                                <div className="name">
                                                    {truncate(
                                                        `${TotalPurchaseYTD.itemReferenceName} - ${TotalPurchaseYTD.itemName}`,
                                                        { length: 30 }
                                                    )}
                                                </div>
                                                <div>
                                                    {this.renderWidgetBar(
                                                        maxTotal,
                                                        TotalPurchaseYTD.itemCount
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <NoItems
                        messageClassName=""
                        style={{ marginTop: '37px', marginBottom: '30px' }}
                        message="There are no delivered order!"
                        data-test="noData"
                    />
                )}
            </Widget>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, totalPurchaseYTDs, errorResponse } =
        state.totalPurchaseYTD;
    const { retailerInfo } = state.userInfo;
    return { loading, totalPurchaseYTDs, retailerInfo, errorResponse };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
        getTotalpurchaseYTD: (retailerReference) =>
            dispatch(getTotalpurchaseYTD(retailerReference)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalPurchaseYTDWidget);
