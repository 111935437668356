export const onlyAllowNumericInput = (event) => {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
        event.preventDefault();
    }
};

export const onlyAllowNumericAndLettersInput = (event) => {
    if (
        !(
            (event.charCode >= 48 && event.charCode <= 57) ||
            (event.charCode >= 97 && event.charCode <= 122) ||
            (event.charCode >= 65 && event.charCode <= 90)
        )
    ) {
        event.preventDefault();
    }
};
