import {
    CLOSING_GAMES_LOADING,
    CLOSING_GAMES,
    CLOSING_GAMES_INIT,
    CLOSING_GAMES_ERROR,
} from '../../../../actions/retailerDashboard/widgets/closingGames/closingGames';

const INITIAL_STATE = {
    closingGamesForRetailer: undefined,
    loading: false,
    errorResponse: null,
};

const closingGamesWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLOSING_GAMES_INIT:
            return { ...INITIAL_STATE };
        case CLOSING_GAMES_LOADING:
            return { ...state, loading: true };
        case CLOSING_GAMES:
            return {
                ...state,
                closingGamesForRetailer: action.closingGamesForRetailer,
                loading: false,
                errorResponse: null,
            };
        case CLOSING_GAMES_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default closingGamesWidget;
