import _ from 'lodash';

export const FILTER_INIT = 'FILTER_INIT';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const FILTER_ADD_SELECTION = 'FILTER_ADD_SELECTION';
export const FILTER_REPLACE_SELECTION = 'FILTER_REPLACE_SELECTION';
export const FILTER_REMOVE_SELECTION = 'FILTER_REMOVE_SELECTION';
export const FILTER_ADD_ALL_SELECTION = 'FILTER_ADD_ALL_SELECTION';
export const FILTER_UPDATE_ALL_SELECTION = 'FILTER_UPDATE_ALL_SELECTION';
export const FILTER_REMOVE_ALL_SELECTION = 'FILTER_REMOVE_ALL_SELECTION';
export const FILTER_CLEAR_ALL = 'FILTER_CLEAR_ALL';
export const FILTER_RESET = 'FILTER_RESET';
export const FILTER_APPLY = 'FILTER_APPLY';

export const FILTER_LIST_LOADING = 'FILTER_LIST_LOADING';
export const FILTER_LIST = 'FILTER_LIST';
export const FILTER_LIST_MORE = 'FILTER_LIST_MORE';
export const FILTER_LIST_MORE_LOADING = 'FILTER_LIST_MORE_LOADING';
export const FILTER_LIST_SEARCH_LOADING = 'FILTER_LIST_SEARCH_LOADING';
export const FILTER_LIST_SEARCH = 'FILTER_LIST_SEARCH';
export const FILTER_LIST_SEARCH_CLIENT = 'FILTER_LIST_SEARCH_CLIENT';

export const FILTER_LIST_UPDATE_SEARCH = 'FILTER_LIST_UPDATE_SEARCH';

export const initializeFilter = ({ name, values }) => ({
    type: FILTER_INIT,
    name,
    values,
});

export const filterToggle = (name, toggle) => ({
    type: TOGGLE_FILTER,
    payload: {
        name,
        toggle,
    },
});

export const addAllSelectedItems = (filterName, items) => ({
    type: FILTER_ADD_ALL_SELECTION,
    payload: {
        filterName,
        items,
    },
});

export const addSingleSelectedItem = (filterName, item) => ({
    type: FILTER_REPLACE_SELECTION,
    payload: {
        filterName,
        item,
    },
});

export const updateAllSelectedItems = (filterName, items) => ({
    type: FILTER_UPDATE_ALL_SELECTION,
    payload: {
        filterName,
        items,
    },
});

export const removeAllSelectedItems = (filterName) => ({
    type: FILTER_REMOVE_ALL_SELECTION,
    payload: {
        filterName,
    },
});

export const addSelectedItem = (filterName, item) => ({
    type: FILTER_ADD_SELECTION,
    payload: {
        filterName,
        item,
    },
});

export const updateSearchValue = (name, value) => ({
    type: FILTER_LIST_UPDATE_SEARCH,
    name,
    value,
});

export const removeSelectedItem = (filterName, item) => ({
    type: FILTER_REMOVE_SELECTION,
    payload: {
        filterName,
        item,
    },
});

export const shouldApplyFilters = () => ({
    type: FILTER_APPLY,
});

export const clearFilters = () => {
    return (dispatch) => {
        dispatch({ type: FILTER_CLEAR_ALL });
    };
};

export const resetFilters = () => {
    return (dispatch) => {
        dispatch({ type: FILTER_RESET });
    };
};

export const loadList = (loader, transformer, args) => {
    return async (dispatch) => {
        dispatch({ type: FILTER_LIST_LOADING, name: args.name });
        if (loader && _.isFunction(loader)) {
            try {
                const results = await loader(args);

                const list = _.map(results.content, (result) =>
                    transformer(result)
                );

                dispatch({
                    type: FILTER_LIST,
                    name: args.name,
                    list,
                    page: results.number,
                    totalElements: results.totalElements,
                    totalPages: results.totalPages,
                });
            } catch (e) {
                throw e;
            }
        } else {
            dispatch({
                type: FILTER_LIST,
                name: args.name,
                list: loader,
                page: 0,
                totalElements: loader.length,
                totalPages: 1,
            });
        }
    };
};

export const loadMore = (loader, transformer, args) => {
    return async (dispatch) => {
        dispatch({ type: FILTER_LIST_MORE_LOADING, name: args.name });
        if (loader && _.isFunction(loader)) {
            try {
                const results = await loader(args);

                let list = _.map(results.content, (result) =>
                    transformer(result)
                );

                dispatch({
                    type: FILTER_LIST_MORE,
                    name: args.name,
                    list,
                    page: results.number,
                    totalElements: results.totalElements,
                    totalPages: results.totalPages,
                });
            } catch (e) {
                throw e;
            }
        }
    };
};

export const searching = (name) => ({ type: FILTER_LIST_SEARCH_LOADING, name });

export const postClientSearch = (name, list, defaultSelected) => {
    return (dispatch, getState) => {
        setupDefaultSelections(name, defaultSelected, list, dispatch, getState);
        dispatch({
            type: FILTER_LIST_SEARCH_CLIENT,
            name: name,
            list: list,
            page: 0,
            totalElements: list.length,
            totalPages: 1,
        });
    };
};

export const searchFilters = (loader, transformer, args) => {
    return async (dispatch, getState) => {
        if (loader && _.isFunction(loader)) {
            try {
                const results = await loader(args);

                let list = _.map(results.content, (result) =>
                    transformer(result)
                );

                setupDefaultSelections(
                    args.name,
                    args.defaultSelected,
                    list,
                    dispatch,
                    getState
                );

                dispatch({
                    type: FILTER_LIST_SEARCH,
                    name: args.name,
                    query: args.query,
                    list,
                    page: results.number,
                    totalElements: results.totalElements,
                    totalPages: results.totalPages,
                });
            } catch (e) {
                throw e;
            }
        }
    };
};

const setupDefaultSelections = (
    name,
    defaultSelection,
    list,
    dispatch,
    getState
) => {
    const { tableFilter } = getState();
    const { clearAll, selectedItems } = tableFilter;

    if (!clearAll) {
        if (defaultSelection) {
            if (defaultSelection !== '*') {
                if (_.isArray(defaultSelection)) {
                    _.each(defaultSelection, (d) =>
                        dispatch(addSelectedItem(name, d))
                    );
                } else {
                    dispatch(addSelectedItem(name, defaultSelection));
                }
            } else {
                if (!selectedItems[name]) {
                    dispatch(
                        addAllSelectedItems(
                            name,
                            _.map(list, (i) => i.value)
                        )
                    );
                } else {
                    dispatch(
                        updateAllSelectedItems(
                            name,
                            _.map(list, (i) => i.value)
                        )
                    );
                }
            }
        }
    }
};
