export const RETAILER_NAV_INIT = 'RETAILER_NAV_INIT';
export const RETAILER_NAV_SET = 'RETAILER_NAV_SET';

export const setActiveItem = (itemName) => ({
    type: RETAILER_NAV_SET,
    payload: itemName,
});

export const initRetailerNav = (itemName) => ({
    type: RETAILER_NAV_INIT,
    payload: itemName,
});
