import {
    VISIT_PLANNER_RETAILERS_LIST,
    VISIT_PLANNER_RETAILERS_LIST_LOADING,
    VISIT_PLANNER_RETAILERS_LIST_MORE_LOADING,
    VISIT_PLANNER_TIERS,
    VISIT_PLANNER_CLEAR,
    VISIT_PLANNER_WEEKS_LOAD,
    VISIT_PLANNER_WEEKS_UPDATE,
    VISIT_PLANNER_DRAG_ITEM,
    VISIT_PLANNER_DRAGGING,
    VISIT_PLANNER_CYCLE_UPDATING,
    VISIT_PLANNER_SET_COLLAPSED,
} from '../../actions/visits/planner';

const INITIAL_STATE = {
    firstDayOfWeek: null,
    startup: true,
    loading: false,
    loadingMore: false,
    weeks: [],
    loadingUpdate: false,
    visits: [],
    tiers: [],
    totalPages: 0,
    page: 0,
    lastPage: false,
    maxFrequency: 4,
    totalsPerWeek: {},
    draggedItem: null,
    dragging: false,
    filterSalesRoutes: [],
    filterTiers: [],
    plannerCollapsed: false,
};

const planner = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VISIT_PLANNER_TIERS:
            return {
                ...state,
                tiers: [...action.tiers],
                maxFrequency: action.maxFrequency,
                totalsPerWeek: { ...action.totalsPerWeek },
                filterSalesRoutes: [...action.salesRoutes],
                filterTiers: [...action.retailerTiers],
            };
        case VISIT_PLANNER_RETAILERS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                startup: false,
                visits: [],
                page: 0,
                totalPages: 0,
                lastPage: true,
            };
        case VISIT_PLANNER_RETAILERS_LIST_MORE_LOADING:
            return { ...state, loadingMore: true };
        case VISIT_PLANNER_RETAILERS_LIST: {
            const { content, number, totalPages, last } = action.data;
            return {
                ...state,
                loading: false,
                loadingMore: false,
                visits: [...state.visits, ...content],
                page: number,
                totalPages,
                lastPage: last,
            };
        }
        case VISIT_PLANNER_CLEAR:
            return { ...INITIAL_STATE };
        case VISIT_PLANNER_WEEKS_LOAD:
            return { ...state, weeks: [...action.weeks] };
        case VISIT_PLANNER_DRAG_ITEM:
            return { ...state, draggedItem: action.item, dragging: true };
        case VISIT_PLANNER_DRAGGING:
            return { ...state, dragging: action.dragging };
        case VISIT_PLANNER_WEEKS_UPDATE:
            return {
                ...state,
                weeks: [...action.weeks],
                totalsPerWeek: [...action.totalsPerWeek],
                loadingUpdate: false,
                draggedItem: null,
            };
        case VISIT_PLANNER_CYCLE_UPDATING:
            return { ...state, loadingUpdate: action.loading };
        case VISIT_PLANNER_SET_COLLAPSED:
            return { ...state, plannerCollapsed: action.plannerCollapsed };
        default:
            return state;
    }
};
export default planner;
