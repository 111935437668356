import { TiersApi } from '../../../services/tiers-service';
import { getErrorMessage } from '../../../constants/utils';
import { error, clearNotification } from '../notifications';

export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_TIERS_INIT = 'CREATE_TIERS_INIT';
export const CREATE_TIERS_LOADING = 'CREATE_TIERS_LOADING';
export const CREATE_TIERS_LOADING_DONE = 'CREATE_TIERS_LOADING_DONE';

const tiersApi = new TiersApi();

const createTierFailure = (e) => {
    return async (dispatch) => {
        dispatch(error({ msg: getErrorMessage(e), target: 'CreateTier' }));
    };
};

export const createTiers = (tier) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: CREATE_TIERS_LOADING });
            const data = await tiersApi.createTier(tier);
            dispatch({
                type: CREATE_SUCCESS,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(createTierFailure(err));
            success = false;
        } finally {
            dispatch({ type: CREATE_TIERS_LOADING_DONE });
        }

        return success;
    };
};

export const initCreateTiers = () => {
    return async (dispatch) => {
        dispatch({ type: CREATE_TIERS_INIT });
        dispatch(clearNotification());
    };
};
