import {
    surveyStatuses,
    questionTypes,
} from '../../../constants/surveyOptions';
import {
    MARKETING_UPDATE_SUCCESS,
    MARKETING_GET_SUCCESS,
    MARKETING_UPDATE_INIT,
    MARKETING_UPDATE_PAGE_LOADING,
    MARKETING_UPDATE_PAGE_DONE,
} from '../../actions';

const INIT_MARKETING_UPDATE = {
    id: '',
    name: '',
    description: '',
    question: '',
    questions: [],
    questionType: null,
    status: 'DRAFT',
};

const INITIAL_STATE = {
    loading: false,
    marketingSurvey: { ...INIT_MARKETING_UPDATE },
    marketingSurveyOptions: {
        questionTypes: [...questionTypes],
        statuses: [...surveyStatuses],
    },
};

const updateMarketing = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MARKETING_UPDATE_PAGE_LOADING:
            return { ...state, loading: true };
        case MARKETING_UPDATE_PAGE_DONE:
            return { ...state, loading: false };
        case MARKETING_UPDATE_SUCCESS:
            return { ...state, marketingSurvey: { ...INIT_MARKETING_UPDATE } };
        case MARKETING_GET_SUCCESS:
            return { ...state, marketingSurvey: { ...action.marketingObject } };
        case MARKETING_UPDATE_INIT:
            return {
                ...INITIAL_STATE,
                marketingSurvey: { ...INIT_MARKETING_UPDATE },
            };
        default:
            return state;
    }
};
export default updateMarketing;
