import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    success,
    initPortalFileUpload,
    portalFileUpload,
    downloadPrivacyFile,
    updatePrivacyPolicyHtml,
    getPrivacyPolicyHtml,
    initPrivacyPolicy,
    setIsFormDirty,
} from '../../redux/actions';
import { Dimmer, Loader } from 'semantic-ui-react';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import Page from '../../layout/Page';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button } from 'semantic-ui-react';
import OkCancelModal from '../../components/OkCancelModal';
import './styles.css';
import WysiwygEditor from '../../components/wysiwygEditor/WysiwygEditor';

export class AdminPrivacyPolicyPage extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        showSave: false,
        showCheckBoxModal: false,
        dataChange: false,
        showReset: false,
        initialContent: null,
    };

    async componentDidMount() {
        const { getData } = this.props;
        await getData();

        this.initEditorState();
    }

    componentWillUnmount() {
        this.props.onInitPortalFileUpload();
        this.props.init();
    }

    initEditorState = () => {
        const editorState = EditorState.createWithContent(
            stateFromHTML(this.props.html || '')
        );
        this.setState({
            editorState: editorState,
            initialContent: convertToRaw(editorState.getCurrentContent())
                .blocks,
        });
    };

    toggleSaveModal = (e, v) => {
        this.state.dataChange &&
            this.setState({
                showSave: v.content !== null && v.content !== 'Cancel',
            });
        this.props.setIsFormDirty(false);
    };

    toggleResetModal = (e, v) => {
        this.state.dataChange &&
            this.setState({
                showReset: v.content !== null && v.content !== 'Cancel',
            });
        this.props.setIsFormDirty(false);
    };

    onEditorStateChange = (editorState) => {
        const prev = this.state.initialContent;
        const current = convertToRaw(editorState.getCurrentContent()).blocks;
        const currrentValue = current
            .map((block) => (!block.text.trim() && '\n') || block.text)
            .join('\n');
        const prevValue = prev
            .map((block) => (!block.text.trim() && '\n') || block.text)
            .join('\n');

        if (prevValue !== currrentValue) {
            this.props.setIsFormDirty(true);
            this.setState({
                dataChange: true,
            });
        } else {
            this.props.setIsFormDirty(false);
            this.setState({
                dataChange: false,
            });
        }
        this.setState({
            editorState,
        });
    };

    handleSave = () => {
        const { savePrivacyPolicy } = this.props;

        savePrivacyPolicy(
            draftToHtml(
                convertToRaw(this.state.editorState.getCurrentContent())
            )
        );

        this.setState({
            dataChange: false,
            showSave: false,
        });
    };

    handleReset = () => {
        this.setState({
            editorState: EditorState.createWithContent(
                stateFromHTML(this.props.html || '')
            ),
            dataChange: false,
            showReset: false,
        });
    };

    uploadFile = async (values) => {
        const { onPortalFileUpload, updateSuccess } = this.props;
        const upload = {
            name: values.name,
            file: values,
            key: 'privacyPolicy',
        };

        const success = await onPortalFileUpload(upload);
        if (success) {
            updateSuccess({
                msg: `The Privacy Policy was successfully uploaded.`,
                target: 'AdminPrivacyPolicyPage',
            });
        }
    };

    downloadFile = async () => {
        await this.props.onDownloadPrivacyFile();
    };

    render() {
        const { showSave, showReset } = this.state;

        return (
            <Page
                name="AdminPrivacyPolicyPage"
                title={'Privacy Policy'}
                data-test="AdminPrivacyPolicyPage"
            >
                <Dimmer page inverted active={this.props.loading}>
                    <Loader />
                </Dimmer>
                <div className="data-section">
                    <WysiwygEditor
                        onEditorStateChange={this.onEditorStateChange}
                        editorState={this.state.editorState}
                    />
                    <div style={{ marginTop: 5, textAlign: 'right' }}>
                        <Button
                            type="button"
                            className="transparent-btn"
                            onClick={this.toggleResetModal}
                            disabled={!this.state.dataChange}
                            size="mini"
                        >
                            Reset
                        </Button>
                        <Button
                            type="button"
                            name="verify-user1"
                            onClick={this.toggleSaveModal}
                            size="mini"
                            primary
                            disabled={!this.state.dataChange}
                        >
                            Save
                        </Button>
                    </div>
                    <OkCancelModal
                        header={`Save the Privacy Policy?`}
                        body={`Are you sure you want to save the changes?`}
                        cancelFunction={this.toggleSaveModal}
                        okFunction={this.handleSave}
                        isOpen={showSave}
                        okText={'Save'}
                    />
                    <OkCancelModal
                        header="Reset Privacy Policy"
                        body={`Do you want to undo changes?`}
                        cancelFunction={this.toggleResetModal}
                        okFunction={this.handleReset}
                        isOpen={showReset}
                        okText={'Reset'}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ privacyPolicy }) => ({ ...privacyPolicy });

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => dispatch(getPrivacyPolicyHtml()),
        init: () => dispatch(initPrivacyPolicy()),
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
        onInitPortalFileUpload: () => dispatch(initPortalFileUpload()),
        onPortalFileUpload: (args) => dispatch(portalFileUpload(args)),
        onDownloadPrivacyFile: () => dispatch(downloadPrivacyFile()),
        savePrivacyPolicy: (html) => dispatch(updatePrivacyPolicyHtml(html)),
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminPrivacyPolicyPage)
);
