import { ReportsApi } from '../../../../../services/reports-service';
export const TOTAL_PURCHASE_YTD_INIT = 'TOTAL_PURCHASE_YTD_INIT';
export const TOTAL_PURCHASE_YTD = 'TOTAL_PURCHASE_YTD';
export const TOTAL_PURCHASE_YTD_LOADING = 'TOTAL_PURCHASE_YTD_LOADING';
export const TOTAL_PURCHASE_YTD_ERROR = 'TOTAL_PURCHASE_YTD_ERROR';

const reportsApi = new ReportsApi();

export const initTotalpurchaseYTD = () => {
    return async (dispatch) => {
        dispatch({ type: TOTAL_PURCHASE_YTD_INIT });
    };
};

export const getTotalpurchaseYTD = (retailerReference) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TOTAL_PURCHASE_YTD_LOADING });
            const totalPurchaseYTDs = await reportsApi.getTotalpurchaseYTD(
                retailerReference
            );
            dispatch({
                type: TOTAL_PURCHASE_YTD,
                totalPurchaseYTDs,
            });
        } catch (e) {
            dispatch({
                type: TOTAL_PURCHASE_YTD_ERROR,
                loading: false,
                errorResponse: e.response.data,
            });
        }
    };
};
