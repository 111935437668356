import { checkFilters } from './utils';
import { Backend } from './backend';

class ReportsApi {
    constructor() {
        this.path = '/reports';
    }
    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/version`,
        });
    }

    async getAllReports() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/list`,
            });
        } catch (e) {
            throw e;
        }
    }

    async searchAllReports({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'sortOrder',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async generateReport({ id, parameters }) {
        try {
            return await Backend.download(
                {
                    method: 'POST',
                    url: `${this.path}/generate/${id}`,
                    body: parameters,
                },
                null,
                'application/pdf'
            );
        } catch (e) {
            throw e;
        }
    }

    async generateReportByName(name, parameters) {
        try {
            return await Backend.download(
                {
                    method: 'POST',
                    url: `${this.path}/generate/by-name/${name}`,
                    body: parameters,
                },
                null,
                'application/pdf'
            );
        } catch (e) {
            throw e;
        }
    }

    async getYtdSales(username) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/ytd/${username}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getShortTermSales(args) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/sales/short-term-sales`,
                body: { ...args },
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesPerformanceSummary(args) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/sales/sales-performance/summary`,
                body: { ...args },
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesPerformanceForSalesManagers(args) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/sales-targets/ytd-sales-director`,
                body: { ...args },
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesPerformanceByRoute(args) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/sales-targets/ytd-route-targets`,
                body: { ...args },
            });
        } catch (e) {
            throw e;
        }
    }

    async getTopGamesByPricePointForRetailer(
        retailerReference,
        jurisdictionCode,
        startDate,
        endDate,
        gameType
    ) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/engagement/top-games-by-price-point/${retailerReference}/${jurisdictionCode}/${startDate}/${endDate}/${gameType}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getLastOrderSummaryForRetailer(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/engagement/last-order-summary/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTotalpurchaseYTD(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/engagement/total-purchase-YTD/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTotalpurchaseYTDForNonProductItems(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/engagement/total-purchase-YTD/non-product-items/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getTotalpurchaseYTDByPP(retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/sales/engagement/total-purchase-YTD-PP/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getYtdTotalCommissions(args) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/invoices/engagement/commissions`,
                body: args,
            });
        } catch (e) {
            throw e;
        }
    }

    async searchInvoices({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/invoices/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'retailerReference',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: filters,
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getLatestInvoiceForRetailers({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/invoices/search-latest`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'retailerReference',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: filters,
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getLatestCommissionForRetailers(retailerReferences) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/invoices/get-latest-commission-for-retailers`,
                body: retailerReferences,
            });
        } catch (e) {
            throw e;
        }
    }

    async getReport({ id }) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getInvoice(id) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/invoices/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { ReportsApi };
