import { TOGGLE_FOOTER } from '../actions/footer';

const INITIAL_STATE = {
    toggle: false,
    msg: '',
    path: null,
};

const footer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_FOOTER:
            return {
                ...state,
                toggle: action.val || false,
                msg: !action.val ? '' : action.msg,
                path: action.path,
            };
        default:
            return state;
    }
};
export default footer;
