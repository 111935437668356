import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';

import UserInfo from '../../components/UserInfo';
import UserRole from '../../components/UserRole';
import PillList from '../../components/pillList/PillList';
import {
    updateUser,
    initUpdateUser,
    getUser,
    success,
} from '../../redux/actions';
import { hasRole } from '../../constants/utils';
import SfaForm from '../../components/SfaForm';
import Page from '../../layout/Page';
import SfaReadView from '../../components/SfaReadView';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import UneditablePill from '../../components/UneditablePill';
import SfaToggle from '../../components/sfaToggle/SfaToggle';
import config from '../../constants/config';
import RetailerSelect from '../../components/retailerSelect/RetailerSelect';
import OkCancelModal from '../../components/OkCancelModal';
import permissions from '../../constants/permissions';
import { role_names } from '../../constants/roles';
import SfaInput from '../../components/SfaInput';
import { isValidEmail, isValidLength } from '../../constants/validate';
const { USER_MANAGEMENT } = config.ROUTE_URLS;

class UpdateUser extends Component {
    state = {
        isEditable: false,
        showConfirmation: false,
        enableCheckBox: false,
    };

    componentWillMount() {
        const username = this.props.match.params.username;
        this.props.getUser(username);
    }

    showConfirmationModal = () => this.setState({ showConfirmation: true });

    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    updateUser = async (values) => {
        this.closeConfirmationModal();
        const { updateUser, updateSuccess, fields, selectedRetailers } =
            this.props;

        //save values not captured by redux forms
        values.enabled = fields.enabled;
        values.userRetailers = [];
        _.map(values.retailer, (retailerReference) => {
            values.userRetailers.push({
                userRetailerId: { retailerReference: retailerReference },
                retailerName: selectedRetailers.find(
                    (retailer) => retailer.reference === retailerReference,
                ).name,
            });
        });

        const success = await updateUser(values);

        if (success) {
            this.gotoListPage();
            updateSuccess({
                msg: 'User was successfully updated.',
                target: 'UsersPage',
            });
        }
    };

    gotoListPage = () => {
        this.props.initUpdateUser();
        this.props.history.push(USER_MANAGEMENT);
    };

    handleCancelUser = () => {
        this.gotoListPage();
    };

    editUser = () => {
        this.setState({ isEditable: true });
    };

    saveField = (name, value) => {
        const fields = this.props.fields;
        fields[name] = value;
        this.setState({ fields });
    };

    activeOnchange = (event, { name, checked }) => {
        const { fields } = this.props;
        var { enableCheckBox } = this.state;

        fields[name] = checked;
        this.setState({
            fields,
            enableCheckBox: !enableCheckBox,
        });
    };

    render() {
        const { isEditable, showConfirmation, enableCheckBox } = this.state;
        const { fields, loading, userInfo, handleSubmit, pristine } =
            this.props;
        return (
            <Page name="UpdateUserPage" data-test="updateUserPage">
                <SfaBreadCrumb
                    onBreadCrumb={this.gotoListPage}
                    breadCrumbText="User Management"
                    animation="fade right"
                />
                <SfaForm
                    name="UpdateUser"
                    onSubmit={handleSubmit(this.showConfirmationModal)}
                    headerText={
                        isEditable
                            ? `Update ${fields.username}`
                            : fields.username
                    }
                    onCancel={this.handleCancelUser}
                    showSaveCancel={isEditable}
                    showEdit={
                        !isEditable &&
                        hasRole(userInfo.authorities, 'ROLE_RE_USERS_CREATE')
                    }
                    onEdit={this.editUser}
                    loading={loading}
                    submitIsDisabled={pristine && !enableCheckBox}
                    editButtonRoles={[permissions.CREATE_USERS]}
                    animation="fade left"
                >
                    <Form.Field>
                        <OkCancelModal
                            header={`Update User`}
                            body={`Are you sure you want to save the changes?`}
                            cancelFunction={this.closeConfirmationModal}
                            okFunction={handleSubmit(this.updateUser)}
                            isOpen={showConfirmation}
                            okText={'Save'}
                        />
                        {isEditable ? (
                            <div>
                                <div className="field">
                                    <div
                                        className="readonly"
                                        style={{ marginBottom: 16 }}
                                    >
                                        <SfaInput
                                            label="Username"
                                            data={fields.username}
                                            name={'username'}
                                        />
                                    </div>
                                </div>
                                <UserInfo
                                    onChange={this.onChange}
                                    fields={fields}
                                />
                                <UserRole
                                    saveField={this.saveField}
                                    fields={fields}
                                />

                                <RetailerSelect
                                    selectedRetailers={fields.userRetailers}
                                />
                                <SfaToggle
                                    labelText="Status"
                                    toggleName="enabled"
                                    handleChange={this.activeOnchange}
                                    checked={fields.enabled}
                                />
                            </div>
                        ) : (
                            <div>
                                <SfaReadView
                                    elements={[
                                        {
                                            label: 'Username',
                                            value: fields.username,
                                        },
                                        {
                                            label: 'First Name',
                                            value: fields.firstName,
                                        },
                                        {
                                            label: 'Last Name',
                                            value: fields.lastName,
                                        },
                                        { label: 'Email', value: fields.email },
                                    ]}
                                />
                                <PillList
                                    label=" User Roles"
                                    list={_.sortBy(fields.roles, [
                                        'displayName',
                                    ])}
                                    displayAttr="displayName"
                                />
                                {fields.userRetailers && (
                                    <PillList
                                        label="Retailers"
                                        list={fields.userRetailers}
                                        displayFunction={(userRetailer) => {
                                            return `${userRetailer.userRetailerId.retailerReference} - ${userRetailer.retailerName}`;
                                        }}
                                    />
                                )}
                                <UneditablePill
                                    label="Status"
                                    data={
                                        fields.enabled ? 'Active' : 'Inactive'
                                    }
                                />
                            </div>
                        )}
                    </Form.Field>
                </SfaForm>
            </Page>
        );
    }
}

const mapStateToProps = ({
    updateUser: { fields, loading },
    auth: { info },
    organization: { phoneFaxMask },
    retailerSelect: { content: retailers, selectedValues },
}) => ({
    fields,
    loading,
    userInfo: info,
    phoneFaxMask,
    retailers,
    selectedRetailers: selectedValues,
    initialValues: {
        ...fields,
        roles: fields.roles && fields.roles[0] ? fields.roles[0].name : '',
        retailer: fields.userRetailers?.map(
            (userRetailer) => userRetailer.userRetailerId.retailerReference,
        ),
    },
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (args) => dispatch(updateUser(args)),
        getUser: (username) => dispatch(getUser(username)),
        initUpdateUser: () => dispatch(initUpdateUser()),
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
    };
};

UpdateUser.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};
const validate = (values) => {
    let error = {};
    if (
        values &&
        (!values.username ||
            !(
                values.username.match('^[a-zA-Z0-9-_]{6,25}$') ||
                !isValidEmail(values.username, true)
            ))
    ) {
        error.username =
            "Choose a username 6–25 characters long. Your username can be an email ID or any combination of letters, numbers, or symbols('-','_')";
    }
    if (values) {
        error.firstName = isValidLength(
            values.firstName,
            1,
            25,
            true,
            'Choose a firstName 1–25 characters long.',
        );
        error.lastName = isValidLength(
            values.lastName,
            1,
            25,
            true,
            'Choose a lastname 1–25 characters long.',
        );
        error.email = isValidEmail(values.email, true, 'Choose a valid email.');
    }
    if (values && (!values.roles || values.roles.length < 1)) {
        error.roles = 'Please select role.';
    }
    if (values && (!values.retailer || values.retailer.length < 1)) {
        if (
            !values.roles ||
            values.roles.length < 1 ||
            values.roles === role_names.RETAILER
        ) {
            error.retailer = 'Please select retailer.';
        }
    }
    return error;
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        reduxForm({
            form: 'UpdateUser',
            validate: validate,
            enableReinitialize: true,
        })(UpdateUser),
    ),
);
