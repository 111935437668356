import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import UneditableLabel from '../../UneditableLabel';
import './styles.css';

export default class CheckboxGroup extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    field = ({ input, meta, options, groupLabelText }) => {
        const { name, onChange } = input;
        const { touched, error } = meta;
        const inputValue = input.value;

        const checkboxes = options.map(({ label, value }, index) => {
            const handleChange = (checked) => {
                const arr = [...inputValue];
                if (checked) {
                    arr.push(value);
                } else {
                    arr.splice(arr.indexOf(value), 1);
                }
                return onChange(arr);
            };
            const checked = inputValue.includes(value);
            return (
                <div key={`checkbox-${index}`}>
                    <Checkbox
                        label={label}
                        name={`${name}[${index}]`}
                        value={value}
                        checked={checked}
                        onChange={() => handleChange(!checked)}
                    />
                </div>
            );
        });

        return (
            <div>
                {groupLabelText && (
                    <div className="readonly">
                        <UneditableLabel text={groupLabelText} />
                    </div>
                )}
                <div className="checkbox-group-wrapper">{checkboxes}</div>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field} />;
    }
}
