import { find, remove, map, join } from 'lodash';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { success, error, multiNotification, warning } from '../notifications';
import OrderApi from '../../../services/order-service';
import i18nUtils from '../../../constants/i18n-utils';
import DraftOrderApi from '../../../services/draft-order-service';
import type { DraftOrder } from '../../../DTOs/draft-order';

export const CART_LOADING = 'CART_LOADING';
export const CART_LOADING_DONE = 'CART_LOADING_DONE';
export const CART_INIT = 'CART_INIT';
export const CART_SAVE = 'CART_SAVE';
export const TOGGLE_CART = 'TOGGLE_CART';
export const EDIT_ORDER_INIT = 'editOrders/changesINIT';

export const EDIT_ORDER_CHANGES_START = 'editOrders/changesStart';
export const EDIT_ORDER_CHANGES_SAVED = 'editOrders/changesSaved';

export const EDIT_ORDER_CHANGES_DONE = 'editOrders/changesDone';
export const EDIT_ORDER_CHANGES_LOAD = 'editOrders/changesLoad';
export const EDIT_ORDER_CLONE_CURRENT_ITEMS = 'editOrders/cloneCurrentItems';
const orderEvent = {
    start: EDIT_ORDER_CHANGES_START,
    save: EDIT_ORDER_CHANGES_SAVED,
    end: EDIT_ORDER_CHANGES_DONE,
};
const cartEvent = {
    start: CART_LOADING,
    save: CART_SAVE,
    end: CART_LOADING_DONE,
};

const orderApi = new OrderApi();
const draftOrderApi = new DraftOrderApi();

export const showCart = (isShow) => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_CART,
            payload: { isShow },
        });
    };
};
export const incrementCartQuantityForGame = (game) => {
    return (dispatch, getState) => {
        const {
            cart: { orderedItems },
            userRetailer: {
                activeRetailer: {
                    userRetailerId: { retailerReference },
                },
            },
        } = getState();
        let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
        let qty = 1;
        if (gameInCart) {
            qty = gameInCart.qty + 1;
        }

        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            cartEvent,
            false,
            null,
            true,
            retailerReference
        );
    };
};

export const incrementGameQuantityForOrder = (game, disableNotification) => {
    return (dispatch, getState) => {
        const {
            orderDetail: { orderedItems, order, editView },
        } = getState();

        let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
        let qty = 1;
        if (gameInCart) {
            qty = gameInCart.qty + 1;
        }
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            orderEvent,
            editView,
            order?.externalOrderNumber,
            null,
            null,
            disableNotification
        );
    };
};
export const decrementCartQuantityForGame = (game) => {
    return (dispatch, getState) => {
        const {
            cart: { orderedItems },
            userRetailer: {
                activeRetailer: {
                    userRetailerId: { retailerReference },
                },
            },
        } = getState();
        let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
        let qty = 0;
        if (gameInCart) {
            qty = gameInCart.qty - 1;
        }
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            cartEvent,
            false,
            null,
            true,
            retailerReference
        );
    };
};

export const decrementGameQuantityForOrder = (game, disableNotification) => {
    return (dispatch, getState) => {
        const {
            orderDetail: { orderedItems, order, editView },
        } = getState();
        let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
        let qty = 0;
        if (gameInCart) {
            qty = gameInCart.qty - 1;
        }
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            orderEvent,
            editView,
            order?.externalOrderNumber,
            null,
            null,
            disableNotification
        );
    };
};
export const addToOrder = (game) => {
    return (dispatch, getState) => {
        const {
            orderDetail: { orderedItems, order, editView },
            orderQuantity: { qty },
        } = getState();
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            orderEvent,
            editView,
            order?.externalOrderNumber,
            null,
            null,
            true
        );
    };
};
export const addToCart = (game) => {
    return (dispatch, getState) => {
        const {
            cart: { orderedItems },
            orderQuantity: { qty },
            userRetailer: {
                activeRetailer: {
                    userRetailerId: { retailerReference },
                },
            },
        } = getState();
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            cartEvent,
            false,
            null,
            true,
            retailerReference
        );
    };
};

export const removeFromCart = (game) => {
    return (dispatch, getState) => {
        dispatch({ type: CART_LOADING });
        const {
            cart: { orderedItems },
            userRetailer: {
                activeRetailer: {
                    userRetailerId: { retailerReference },
                },
            },
        } = getState();
        let qty = 0;
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            cartEvent,
            false,
            null,
            true,
            retailerReference
        );
    };
};

export const removeFromOrder = (game) => {
    return (dispatch, getState) => {
        const {
            orderDetail: { orderedItems, order, editView },
        } = getState();
        let qty = 0;
        addItems(
            game,
            dispatch,
            orderedItems,
            qty,
            orderEvent,
            editView,
            order?.externalOrderNumber
        );
    };
};

export const createOrder = () => {
    return async (dispatch, getState) => {
        dispatch({ type: CART_LOADING });

        let successResponse = true;

        try {
            const {
                cart: { orderedItems },
                userRetailer: { activeRetailer },
            } = getState();

            const orderDto = {
                retailerReference:
                    activeRetailer.userRetailerId.retailerReference,
                details: map(orderedItems, (item) => ({
                    gameNumber: item.game.reference,
                    gameName: item.game.name,
                    quantity: item.qty,
                    orderedSample: item.orderedSample,
                })),
            };

            const response = await orderApi.createOrder(orderDto);

            if (response) {
                let msg = response?.isMergedOrder
                    ? 'This order was merged with order'
                    : 'Successfully created order';
                const messageArray = [];

                let warningMsg = response?.maxLimitItems
                    ? `Maximum Order limit reached for the following:
                      ${join(
                          map(
                              response?.maxLimitItems,
                              (itQty) =>
                                  `Game #${itQty.itemCode} - ${itQty.itemName} (${itQty.maxQty})`
                          ),
                          '\n'
                      )}`
                    : null;

                if (warningMsg) {
                    messageArray.push({
                        type: 'warning',
                        message: warningMsg,
                    });
                }

                messageArray.push({
                    type: 'success',
                    message: ` ${msg} ${response.externalOrderNumber || ''}`,
                });
                dispatch(
                    multiNotification({
                        msg: messageArray,
                    })
                );

                dispatch(initCart());
                postDraftOrders(
                    [],
                    activeRetailer.userRetailerId.retailerReference
                );
            } else {
                successResponse = false;
                dispatch(
                    error({
                        msg: 'Failed to create order. Please try again later or contact customer service to place your order',
                    })
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(
                error({
                    msg: getErrorMessage(
                        e,
                        'We could not create your order; please try again in a few minutes.'
                    ),
                })
            );
            successResponse = false;
        } finally {
            dispatch({ type: CART_LOADING_DONE });
        }

        return successResponse;
    };
};

export const updateOrder = (orderId, version) => {
    return async (dispatch, getState) => {
        dispatch({ type: EDIT_ORDER_CHANGES_START });

        let successResponse = true;

        try {
            const {
                orderDetail: { orderedItems },
                userRetailer: { activeRetailer },
            } = getState();

            let maxLimitItems = [];
            const orderDto = {
                retailerReference:
                    activeRetailer.userRetailerId.retailerReference,
                details: map(orderedItems, (item) => {
                    if (item.qty > item.game.maximumOrderQuantity) {
                        maxLimitItems.push({
                            itemCode: item.itemNumber,
                            itemName: item.itemName,
                            maxQty: item.game.maximumOrderQuantity,
                        });
                    }
                    return {
                        gameNumber: item.game.reference,
                        gameName: item.game.name,
                        quantity: item.qty,
                        orderedSample: item.orderedSample,
                    };
                }),
                version,
            };

            if (maxLimitItems?.length) {
                let errorMsg = `Maximum Order limit reached for the following:
                      ${join(
                          map(
                              maxLimitItems,
                              (itQty) =>
                                  `Game #${itQty.itemCode} - ${itQty.itemName} (${itQty.maxQty})`
                          ),
                          '\n'
                      )}`;

                throw Object.assign(new Error(errorMsg), { code: 500 });
            }
            const response = await orderApi.updateOrder(orderId, orderDto);

            if (response) {
                dispatch(
                    success({
                        msg: `Successfully updated order ${
                            response.externalOrderNumber || ''
                        }`,
                    })
                );

                dispatch(initCart());
            } else {
                successResponse = false;
                dispatch(
                    error({
                        msg: 'Failed to update order. Please try again later or contact customer service to place your order',
                    })
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(error({ msg: getErrorMessage(e) }));
            successResponse = false;
        } finally {
            dispatch({ type: EDIT_ORDER_CHANGES_DONE });
        }

        return successResponse;
    };
};

export const cartAddedNotification = (
    dispatch,
    gameInCart,
    qty,
    sugQty,
    isEditOrder,
    externalOrderNumber,
    isDisabledNotification
) => {
    const game = gameInCart.game;
    const messageArray = [];
    let msg = isEditOrder ? `order ${externalOrderNumber}` : 'cart';

    if (gameInCart.qty < game?.minimumOrderQuantity) {
        messageArray.push({
            type: 'error',
            message: i18nUtils.text(
                'game.add.minOrderError',
                `Min order limit for Game: ${game.gameRefAndName} is ${game.minimumOrderQuantity}`,
                { gameRefAndName: game.gameRefAndName }
            ),
        });
    }
    if (isDisabledNotification) {
        if (qty === 1) {
            messageArray.push({
                type: 'success',
                message: i18nUtils.text(
                    'game.add.message',
                    `Game ${game.gameRefAndName} added to the ${msg}.`,
                    { gameRefAndName: game.gameRefAndName }
                ),
            });
        } else {
            messageArray.push({
                type: 'success',
                message: i18nUtils.text(
                    'game.add.multipleAddMessage',
                    `${qty} of Game ${game.gameRefAndName}  added to the ${msg}.`,
                    { gameRefAndName: game.gameRefAndName, qty: qty }
                ),
            });
        }
        if (sugQty && sugQty > 0 && gameInCart.qty < sugQty) {
            messageArray.push({
                type: 'warning',
                message: i18nUtils.text(
                    'game.add.alreadyAddedMsg',
                    `Based on average weekly sales of Game ${game.gameRefAndName}, the Suggested Quantity is ${sugQty}. You may run out of stock if you don’t order the Suggested Quantity.`,
                    { gameRefAndName: game.gameRefAndName, sugQty: sugQty }
                ),
            });
        }
    }
    dispatch(
        multiNotification({
            msg: messageArray,
        })
    );
};

export const cartRemovedNotification = (
    dispatch,
    gameInCart,
    qty,
    sugQty,
    isEditOrder,
    externalOrderNumber,
    isDisabledNotification
) => {
    const game = gameInCart.game;
    let messageArray = [];
    let msg = isEditOrder ? `order ${externalOrderNumber}` : 'cart';
    if (gameInCart.qty !== 0 && gameInCart.qty < game?.minimumOrderQuantity) {
        messageArray.push({
            type: 'error',
            message: i18nUtils.text(
                'game.removed.successMessage',
                `Min order limit for Game: ${game.gameRefAndName} is ${game.minimumOrderQuantity}`,
                { gameRefAndName: game.gameRefAndName }
            ),
        });
    }
    if (isDisabledNotification) {
        if (qty === -1) {
            messageArray.push({
                type: 'success',
                message: i18nUtils.text(
                    'game.removed.successMessage',
                    `1 of Game ${game.gameRefAndName} was removed from ${msg}.`,
                    { gameRefAndName: game.gameRefAndName }
                ),
            });
        } else {
            messageArray.push({
                type: 'success',
                message: i18nUtils.text(
                    'game.removed.multipleRemoveMessage',
                    `${-qty} of Game ${
                        game.gameRefAndName
                    } was removed from ${msg}.`,
                    { gameRefAndName: game.gameRefAndName, qty: qty }
                ),
            });
        }
        if (sugQty && sugQty > 0 && gameInCart.qty < sugQty) {
            messageArray.push({
                type: 'warning',
                message: i18nUtils.text(
                    'game.warning.suggestedQty',
                    `Based on average weekly sales of Game ${game.gameRefAndName}, the Suggested Quantity is ${sugQty}. You may run out of stock if you don’t order the Suggested Quantity.`,
                    { gameRefAndName: game.gameRefAndName, sugQty: sugQty }
                ),
            });
        }
    }
    dispatch(
        multiNotification({
            msg: messageArray,
        })
    );
};
const addItems = (
    game,
    dispatch,
    orderedItems,
    qty,
    event,
    isEditOrder,
    externalOrderNumber,
    updatePOSGames,
    retailerReference,
    disableNotification
) => {
    dispatch({ type: event.start });

    let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
    const quantityChange = gameInCart ? qty - gameInCart.qty : qty;
    let suggestedQuantity = game?.suggestedQuantity;

    if (quantityChange > 0) {
        const maxQty = game?.maximumOrderQuantity;

        if (maxQty !== 0 && qty > maxQty) {
            dispatch(
                error({
                    msg: i18nUtils.text(
                        'game.warning.suggestedQty',
                        `Maximum order limit reached for the Game ${game.gameRefAndName}.`,
                        { gameRefAndName: game.gameRefAndName, maxQty: maxQty }
                    ),
                })
            );
            dispatch({ type: event.end });
            return;
        }
        // case 1 adder order and game is not already not in cart
        if (!gameInCart) {
            gameInCart = { game, qty, suggestedQuantity };
            orderedItems.push(gameInCart);
        } else {
            gameInCart.qty = qty;
        }

        cartAddedNotification(
            dispatch,
            gameInCart,
            quantityChange,
            suggestedQuantity,
            isEditOrder,
            externalOrderNumber,
            !disableNotification
        );
    } else if (gameInCart && quantityChange < 0) {
        // removed from cart

        if (qty === 0) {
            remove(orderedItems, ['game.gameId', game.gameId]);
        }
        gameInCart.qty = qty;

        cartRemovedNotification(
            dispatch,
            gameInCart,
            quantityChange,
            suggestedQuantity,
            isEditOrder,
            externalOrderNumber,
            !disableNotification
        );
    }
    if (updatePOSGames) {
        postDraftOrders(orderedItems, retailerReference);
    }
    dispatch({
        type: event.save,
        payload: orderedItems,
    });

    dispatch({ type: event.end });
};

export const initCart = () => ({
    type: CART_INIT,
});

const postDraftOrders = (orderedItems, retailerReference) => {
    const posGames = orderedItems.filter(
        (orderedItem) =>
            orderedItem.game.gameType === 'PS' ||
            orderedItem.game.itemType?.code === 'PS'
    );
    const draftOrder: DraftOrder = {
        retailerReference: retailerReference,
        draftOrderDetails: posGames.map((posGame) => ({
            gameReference: posGame.game.reference,
            quantity: posGame.qty,
            suggestedQuantity: posGame.suggestedQuantity,
        })),
    };
    draftOrderApi.create(draftOrder);
};
export const removeAllFromCart = () => {
    return (dispatch, getState) => {
        const {
            userRetailer: {
                activeRetailer: {
                    userRetailerId: { retailerReference },
                },
            },
        } = getState();
        dispatch({
            type: CART_SAVE,
            payload: [],
        });

        postDraftOrders([], retailerReference);
        dispatch({ type: CART_LOADING_DONE });
    };
};

export const toggleOrderSample = (game) => {
    return (dispatch, getState) => {
        const {
            cart: { orderedItems },
        } = getState();
        const gameInCart = find(orderedItems, ['game.gameId', game.gameId]);

        if (gameInCart) {
            gameInCart.orderedSample = !gameInCart.orderedSample;

            dispatch({
                type: CART_SAVE,
                payload: orderedItems,
            });
        }

        dispatch({ type: CART_LOADING_DONE });
    };
};

export const addSuggestedQuantityOnLoad = (orderedItems, dispatch) => {
    dispatch({ type: CART_LOADING });
    dispatch({
        type: CART_SAVE,
        payload: orderedItems,
    });
    dispatch({ type: CART_LOADING_DONE });
};
