import {
    INVOICES_LOADING,
    INVOICES_LOADING_DONE,
    INVOICES_INIT,
    INVOICES_SEARCH,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 0,
    number: 0,
};

const invoices = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case INVOICES_SEARCH:
            return {
                ...state,
                ...payload,
            };

        case INVOICES_INIT:
            return { ...INITIAL_STATE };

        case INVOICES_LOADING:
            return {
                ...state,
                loading: true,
            };

        case INVOICES_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default invoices;
