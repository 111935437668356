import { ServiceCallApi } from '../../../../../services/service-call-service';
import { errorHandler } from '../../../errorHandler/errorHandler';
import { getErrorStatus } from '../../../../../constants/utils';

export const SERVICE_CALL_INIT = 'SERVICE_CALL_INIT';
export const SERVICE_CALL = 'SERVICE_CALL';
export const SERVICE_CALL_LOADING = 'SERVICE_CALL_LOADING';
export const SERVICE_CALL_LIST = 'SERVICE_CALL_LIST';
export const SERVICE_CALL_LIST_LOADING = 'SERVICE_CALL_LIST_LOADING';

const serviceCallApi = new ServiceCallApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const initServiceCall = () => {
    return async (dispatch) => {
        dispatch({ type: SERVICE_CALL_INIT });
    };
};

export const getServiceCallByRetailerReference = (retailerReference) => {
    return async (dispatch) => {
        dispatch({ type: SERVICE_CALL_LOADING });
        try {
            const serviceCallData =
                await serviceCallApi.getAllOpenServiceCallByRetailerReference(
                    retailerReference
                );
            dispatch({
                type: SERVICE_CALL,
                serviceCallData,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchAllServiceCallList = (args, retailerReference) => {
    return async (dispatch) => {
        dispatch({ type: SERVICE_CALL_LIST_LOADING });
        try {
            const serviceCallList = await serviceCallApi.getAllServiceCallList(
                args,
                retailerReference
            );
            dispatch({
                type: SERVICE_CALL_LIST,
                serviceCallList,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};
