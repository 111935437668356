import { Hub } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import config from '../../constants/config';
import store from '../../redux/store';
import { cognitoLogin } from '../../redux/actions';
import { checkUserLogin } from '../../redux/actions/cognito/cognito-login';

export const setupCognito = async () => {
    AWS.config.region = config.AWS_REGION;
    Amplify.configure({
        Auth: {
            region: config.AWS_REGION,
            identityPoolRegion: config.AWS_REGION,
            userPoolId: config.COGNITO_USER_POOL_ID,
            userPoolWebClientId: config.COGNITO_CLIENT_ID,
            oauth: {
                domain: config.COGNITO_CLIENT_DOMAIN_NAME,
                scope: config.COGNITO_OAUTH_CLAIMS,
                redirectSignIn: config.COGNITO_SIGNIN_URL,
                redirectSignOut: config.COGNITO_SIGNOUT_URL,
                responseType: config.COGNITO_GRANT_FLOW,
            },
        },
    });

    const listener = (data) => {
        switch (data.payload.event) {
            case 'signIn':
                console.log('user sign in');
                store.store.dispatch(cognitoLogin());
                break;
            case 'signUp':
                console.log('user signed up');
                break;
            case 'signOut':
                console.log('user signed out');
                break;
            case 'signIn_failure':
                console.log('user sign in failed');
                break;
            case 'configured':
                console.log('the Auth module is configured');
                break;
            default:
                break;
        }
    };
    Hub.listen('auth', listener);

    // Added to refresh token from spring in case of logged into AWS cognito
    store.store.dispatch(checkUserLogin());
};
export const withCognitoAuth = (component) => {
    if (!config.COGNITO_ENABLED) {
        return component;
    }

    return withAuthenticator(component, {
        signInConfig: {
            signInFields: [
                {
                    label: 'Email',
                    key: 'email',
                    required: true,
                    displayOrder: 1,
                    type: 'string',
                },
                {
                    label: 'Password',
                    key: 'password',
                    required: true,
                    displayOrder: 2,
                    type: 'password',
                },
            ],
        },
        userPoolId: config.COGNITO_USER_POOL_ID,
    });
};

export const getAuthenticatedUserId = () => {
    return localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.COGNITO_CLIENT_ID}.LastAuthUser`
    );
};

export const getAuthenticatedUserData = (username) => {
    const userDataKey = `CognitoIdentityServiceProvider.${config.COGNITO_CLIENT_ID}.${username}.userData`;
    const userAttributes = JSON.parse(
        localStorage.getItem(userDataKey)
    ).UserAttributes;
    const userData = {};
    userAttributes.forEach((attribute) => {
        userData[attribute.Name] = attribute.Value;
    });
    return userData;
};
