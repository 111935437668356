import { ReportsApi } from '../../../services/reports-service';

export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADING_DONE = 'INVOICES_LOADING_DONE';
export const INVOICES_INIT = 'INVOICES_INIT';
export const INVOICES_SEARCH = 'INVOICES_SEARCH';

const reportsApi = new ReportsApi();

export const searchInvoices = (args) => {
    return async (dispatch) => {
        dispatch({ type: INVOICES_LOADING });

        try {
            const payload = await reportsApi.searchInvoices(args);

            dispatch({ type: INVOICES_SEARCH, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: INVOICES_LOADING_DONE });
    };
};

export const initInvoices = () => ({
    type: INVOICES_INIT,
});
