import {
    SALES_TARGET_PROCESSING,
    SALES_TARGET_CREATE_SUCCESS,
    SALES_TARGET_PAGE_DONE,
    SALES_TARGET_CREATE_PAGE_LOADING,
    SALES_TARGETS_LOADING,
    GET_TARGET_CREATE_PAGE_LOADING,
    SALES_TARGET_CREATE_INIT,
    SALES_TARGET_UPDATE_INIT,
    SALES_TARGETS_GET_RECORD_LIST,
} from '../../actions';

const INIT_SALES_TARGET_CREATE = {
    rowId: null,
    drawBaseTarget: '',
    instantTarget: '',
    passiveTarget: '',
    vltTarget: '',
    pulltabTarget: '',
    schedule: '',
    status: '',
    salesRouteId: [],
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    targetToCreate: { ...INIT_SALES_TARGET_CREATE },
    createTargetSuccess: false,
    salesTargetSuccess: false,
    targetAction: '',
    fiscalYearOptions: [],
};

const createSalesTarget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_TARGET_CREATE_PAGE_LOADING:
            return { ...state, loading: true };
        case SALES_TARGETS_GET_RECORD_LIST:
            return {
                ...state,
                targetToCreate: { ...action.data },
                fiscalYearOptions: action.fiscalYearOptions,
                loading: false,
            };
        case SALES_TARGETS_LOADING:
            return { ...state, loading: true };
        case GET_TARGET_CREATE_PAGE_LOADING:
            return { ...state, loading: true };
        case SALES_TARGET_CREATE_INIT:
            return {
                ...INITIAL_STATE,
                targetToCreate: {
                    ...INIT_SALES_TARGET_CREATE,
                    fiscalYear:
                        action.fiscalYearOptions.length > 1
                            ? action.fiscalYearOptions[1]
                            : null,
                },
                fiscalYearOptions: action.fiscalYearOptions,
                salesRouteSuccess: true,
                processing: false,
                loading: false,
                targetAction: 'TARGET_CREATE',
            };
        case SALES_TARGET_UPDATE_INIT:
            return {
                ...state,
                processing: false,
                loading: false,
                targetAction: 'TARGET_UPDATE',
            };
        case SALES_TARGET_PAGE_DONE:
            return { ...state, loading: false, processing: false };
        case SALES_TARGET_PROCESSING:
            return { ...state, processing: true };
        case SALES_TARGET_CREATE_SUCCESS:
            return {
                ...state,
                targetToCreate: { ...INIT_SALES_TARGET_CREATE },
                createTargetSuccess: true,
                processing: false,
            };
        default:
            return state;
    }
};
export default createSalesTarget;
