import { Backend } from './backend';
import { checkFilters } from './utils';

class ZonesApi {
    constructor() {
        this.path = '/retailers/zones';
    }

    async getZoneById(id) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async createZone(zoneToCreate) {
        try {
            return await Backend.send({
                method: 'post',
                url: this.path,
                body: zoneToCreate,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateZone(zoneToUpdate) {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${zoneToUpdate.id}`,
                body: zoneToUpdate,
            });
        } catch (e) {
            throw e;
        }
    }

    async searchZones({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortColumn: sortColumn || 'name',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getZonesByRetailerIds(retailerIdList) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/by-retailer-ids?ids=${retailerIdList}`,
                body: retailerIdList,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { ZonesApi };
