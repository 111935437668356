import {
    ORDER_DETAIL_LOADING,
    ORDER_DETAIL_LOADING_DONE,
    ORDER_DETAIL_INIT,
    ORDER_DETAIL_GET,
    TOGGLE_EDIT_DETAIL_PAGE,
    EDIT_ORDER_CHANGES_DONE,
    EDIT_ORDER_CHANGES_SAVED,
    EDIT_ORDER_CHANGES_START,
    EDIT_ORDER_INIT,
    EDIT_ORDER_CHANGES_LOAD,
    EDIT_ORDER_CLONE_CURRENT_ITEMS,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    order: { status: {} },
    editView: false,
    orderedItems: [],
    originalOrderItems: [],
    pendingOrderItems: [],
};

const orderDetail = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ORDER_DETAIL_GET:
            return {
                ...state,
                order: payload,
            };

        case ORDER_DETAIL_INIT:
            return { ...INITIAL_STATE };

        case ORDER_DETAIL_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ORDER_DETAIL_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };
        case TOGGLE_EDIT_DETAIL_PAGE:
            return {
                ...state,
                editView: payload,
            };
        case EDIT_ORDER_INIT:
            return { ...state, orderedItems: [] };

        case EDIT_ORDER_CHANGES_START:
            return {
                ...state,
                loading: true,
            };

        case EDIT_ORDER_CHANGES_DONE:
            return {
                ...state,
                loading: false,
            };

        case EDIT_ORDER_CHANGES_SAVED:
            return {
                ...state,
                orderedItems: [...payload],
            };
        case EDIT_ORDER_CHANGES_LOAD:
            return {
                ...state,
                orderedItems: [...payload],
                originalOrderItems: JSON.stringify(payload),
            };
        case EDIT_ORDER_CLONE_CURRENT_ITEMS:
            return { ...state, pendingOrderItems: JSON.stringify(payload) };

        default:
            return state;
    }
};
export default orderDetail;
