import {
    TASKS_LIST_ME_LOADING,
    TASKS_LIST_ME,
    TASKS_GET_OPTIONS,
} from '../../actions';

import { TASK_OPTIONS } from '../../../DTOs/taskOptions';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
    listTaskOptions: { ...TASK_OPTIONS },
};

const tasksCreatedByMe = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TASKS_LIST_ME_LOADING:
            return { ...state, loading: true };
        case TASKS_LIST_ME:
            return {
                ...state,
                ...action.list,
                loading: false,
            };
        case TASKS_GET_OPTIONS:
            return { ...state, listTaskOptions: action.initOptions };
        default:
            return state;
    }
};
export default tasksCreatedByMe;
