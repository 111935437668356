import FaqApi from '../../../services/faq-service';
import { success, error } from '../notifications';
import { getErrorMessage } from '../../../constants/utils';

export const PUBLIC_FAQ_LOADING = 'PUBLIC_FAQ_LOADING';
export const PUBLIC_FAQ_LOADING_DONE = 'PUBLIC_FAQ_LOADING_DONE';
export const PUBLIC_FAQ_INIT = 'PUBLIC_FAQ_INIT';
export const PUBLIC_FAQ_GET = 'PUBLIC_FAQ_GET';

const faqApi = new FaqApi();

export const getPublicFaqHtml = () => {
    return async (dispatch) => {
        dispatch({ type: PUBLIC_FAQ_LOADING });

        try {
            const payload = await faqApi.getPublicFaq();
            dispatch({ type: PUBLIC_FAQ_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PUBLIC_FAQ_LOADING_DONE });
    };
};

export const updatePublicFaqHtml = (html) => {
    return async (dispatch) => {
        dispatch({ type: PUBLIC_FAQ_LOADING });

        try {
            await faqApi.updatePublicFaq(html);

            dispatch(success({ msg: 'The FAQ was successfully updated.' }));
            dispatch({ type: PUBLIC_FAQ_GET, payload: html });
        } catch (err) {
            console.error(err);
            dispatch(
                error({ msg: `Could not save FAQ: ${getErrorMessage(err)}` })
            );
        }

        dispatch({ type: PUBLIC_FAQ_LOADING_DONE });
    };
};

export const initPublicFaq = () => ({
    type: PUBLIC_FAQ_INIT,
});
