import { TargetApi } from '../../../../services/sales-target-service';

export const SALES_TARGETS_YTD_LOADING = 'SALES_TARGETS_YTD_LOADING';
export const SALES_TARGETS_YTD = 'SALES_TARGETS_YTD';
export const SALES_TARGETS_YTD_FAIL = 'SALES_TARGETS_YTD_FAIL';

const targetApi = new TargetApi();

export const getYtdSalesTargetPercent = (username) => {
    return async (dispatch) => {
        dispatch({ type: SALES_TARGETS_YTD_LOADING });

        try {
            const data = await targetApi.getSalesTargetYtd(username);
            dispatch({
                type: SALES_TARGETS_YTD,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch({ type: SALES_TARGETS_YTD_FAIL, error: e.message });
        }
    };
};
