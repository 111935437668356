import _ from 'lodash';

export const checkFilters = (filters) => {
    const _filters = filters || [];

    return _.reduce(
        _filters,
        (result, filter) => {
            if (!filter) {
                return result;
            }

            const { property, data } = filter;
            if (data && data.length > 0) {
                if (data[0] !== null && data[0] !== undefined) {
                    result.push({
                        property,
                        data,
                    });
                }
            }

            return result;
        },
        []
    );
};

export const getMimeType = (mediaKey, defaultType = 'application/pdf') => {
    //get extension of file
    const tokens = mediaKey.split('.');

    //if cannot get extension just return file type pdf
    if (tokens.length < 1) {
        return defaultType;
    }
    //extension should be in the last index
    const fileExt = tokens[tokens.length - 1];

    //application/pdf, .jpg, .jpeg, .png, .mp4, .3gp, .mov, .doc, .docx
    switch (fileExt.toLowerCase()) {
        case 'jpg':
            return 'image/jpg';
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/jpeg';
        case 'mp4':
            return 'video/mp4';
        case '3gp':
            return 'video/3gpp';
        case 'mov':
            return 'video/quicktime';
        case 'doc':
            return 'application/doc';
        case 'docx':
            return 'application/docx';
        case 'xls':
            return 'application/xls';
        case 'xlsx':
            return 'application/xlsx';
        default:
            return defaultType;
    }
};
