import React, { Component } from 'react';
import { Table, Checkbox, Popup, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getAttribute } from '../../constants/utils';
import { selectTableRow } from './actions';
import './style.css';

class Row extends Component {
    state = { isActionMenuOpen: false };

    handleActionMenuOpen = () => {
        this.setState({ isActionMenuOpen: true });
    };

    handleActionMenuClose = () => {
        this.setState({ isActionMenuOpen: false });
    };

    render() {
        const {
            item,
            columns,
            selector,
            contentKey,
            itemsSelected,
            selectTableRow,
            actionsMenu,
            rowClassName,
        } = this.props;

        let rowDetails = [];
        const _key = getAttribute(item, contentKey);

        if (selector && selector.type === 'checkbox') {
            rowDetails.push(
                <Table.Cell key={`${_key}_selector`}>
                    <Checkbox
                        checked={!!itemsSelected && !!itemsSelected[_key]}
                        onChange={() => selectTableRow(_key, item)}
                    />
                </Table.Cell>
            );
        }

        rowDetails = [
            ...rowDetails,
            ..._.map(columns, (col, index) => {
                const {
                    name,
                    formatter,
                    link,
                    content,
                    tooltip,
                    className,
                    columnClassName,
                    textAlign,
                    capitalize,
                    truncate,
                    markup,
                } = col;
                let classNameToUse = 'body-text';
                let hasMarkUp = !!markup;

                if (rowClassName) {
                    classNameToUse = rowClassName(item);
                }

                let value = getAttribute(item, name);
                let toolTipValue =
                    typeof tooltip !== 'undefined' &&
                    typeof tooltip === 'function'
                        ? tooltip(value, item)
                        : tooltip;
                if ((value !== undefined || value !== null) && className) {
                    classNameToUse = className(value, item);
                }

                if ((value !== undefined || value !== null) && formatter) {
                    value = formatter(value, item);
                } else if (
                    (value !== undefined || value !== null) &&
                    hasMarkUp
                ) {
                    value = markup(value, item);
                } else if (!value && value !== 0) {
                    value = '';
                }

                let contentInfo = value;
                if (!hasMarkUp) {
                    contentInfo = _.truncate(
                        capitalize ? _.capitalize(value) : value,
                        { length: truncate || 25 }
                    );
                }

                let info;
                if (content) {
                    info = content;
                } else {
                    if (!link) {
                        info = (
                            <span className={classNameToUse}>
                                {contentInfo}
                            </span>
                        );
                    } else {
                        info = (
                            <Button
                                style={{ cursor: 'pointer' }}
                                className="link-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const { variables, path, callback } = link;
                                    let _path = path;
                                    variables.forEach(
                                        (v) =>
                                            (_path +=
                                                '/' + getAttribute(item, v))
                                    );
                                    if (callback) {
                                        callback(_path);
                                    }
                                }}
                            >
                                <span
                                    className={`${classNameToUse} secondary-text`}
                                >
                                    {contentInfo}
                                </span>
                            </Button>
                        );
                    }
                }

                let cellData = (
                    <Popup
                        className="sfaPopup"
                        trigger={info}
                        content={
                            capitalize && !hasMarkUp
                                ? _.capitalize(value)
                                : value
                        }
                        on="hover"
                        basic
                        position="bottom left"
                    />
                );

                if (tooltip) {
                    const cell = (
                        <Table.Cell
                            className={`${
                                columnClassName
                                    ? columnClassName(value, item)
                                    : 'text-nowrap'
                            }`}
                            textAlign={textAlign}
                        >
                            {info}
                        </Table.Cell>
                    );

                    return (
                        <Popup
                            key={_key + index}
                            trigger={cell}
                            position="top center"
                            on="hover"
                            size="small"
                        >
                            <Popup.Content className="popup-style">
                                <div>{toolTipValue}</div>
                            </Popup.Content>
                        </Popup>
                    );
                }

                return (
                    <Table.Cell
                        key={_key + index}
                        className={`${
                            columnClassName
                                ? columnClassName(value, item)
                                : 'text-nowrap'
                        }`}
                        textAlign={textAlign}
                    >
                        {cellData}
                    </Table.Cell>
                );
            }),
        ];

        if (actionsMenu && actionsMenu.length > 0) {
            const filterMenuItem = _.filter(
                actionsMenu,
                (menuItem) => !menuItem.canShow || menuItem.canShow(item)
            );

            rowDetails.push(
                <Table.Cell
                    key={`${_key}_actions`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {' '}
                    {filterMenuItem && filterMenuItem.length > 0 && (
                        <Popup
                            trigger={
                                <div className="actions-wrapper">
                                    <span className="icon-Overflow secondary-color">
                                        {' '}
                                    </span>
                                </div>
                            }
                            basic
                            position="bottom right"
                            on="click"
                            open={this.state.isActionMenuOpen}
                            onClose={this.handleActionMenuClose}
                            onOpen={this.handleActionMenuOpen}
                        >
                            <Popup.Content>
                                <div className="action-menu">
                                    {_.compact(
                                        _.map(filterMenuItem, (menuItem, i) => {
                                            if (
                                                menuItem.canShow &&
                                                !menuItem.canShow(item)
                                            ) {
                                                return null;
                                            }

                                            if (menuItem.linebreak) {
                                                return (
                                                    <hr
                                                        key={`${_key}_action_item_${i}_linebreak`}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        key={`${_key}_action_item_${menuItem.text}`}
                                                        className={`action-menu action-menu-item clickable ${menuItem.class}`}
                                                        onClick={() => {
                                                            menuItem.handleClick(
                                                                getAttribute(
                                                                    item,
                                                                    menuItem.handleClickParams
                                                                )
                                                            );
                                                            this.handleActionMenuClose();
                                                        }}
                                                    >
                                                        {menuItem.iconClass && (
                                                            <span
                                                                className={
                                                                    menuItem.iconClass
                                                                }
                                                            >
                                                                {' '}
                                                            </span>
                                                        )}
                                                        {menuItem.externalIconClass && (
                                                            <span className="row-icon-span">
                                                                <i
                                                                    className={`action-menu-icon ${menuItem.externalIconClass}`}
                                                                />{' '}
                                                            </span>
                                                        )}
                                                        <span
                                                            className={`subheader-text ${menuItem.class}`}
                                                        >
                                                            {menuItem.text}
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        })
                                    )}
                                </div>
                            </Popup.Content>
                        </Popup>
                    )}
                </Table.Cell>
            );
        }

        return (
            <Table.Row
                onClick={() => {
                    if (selector && selector.type === 'callbackWithId') {
                        const { callback } = selector;
                        if (callback) {
                            callback(getAttribute(item, 'id'));
                        }
                    } else if (
                        (selector &&
                            selector.type !== 'checkbox' &&
                            !actionsMenu) ||
                        (selector && selector.type === 'allowwithactionsmenu')
                    ) {
                        const { variables, path, callback } = selector;
                        let _path = path;
                        variables.forEach(
                            (v) => (_path += '/' + getAttribute(item, v))
                        );
                        if (callback) {
                            callback(_path);
                        }
                    }
                }}
            >
                {rowDetails}
            </Table.Row>
        );
    }
}

export default connect(
    ({ tableSelect: { itemsSelected } }) => ({ itemsSelected }),
    (dispatch) => ({
        selectTableRow: (id, item) => dispatch(selectTableRow(id, item)),
    })
)(Row);
