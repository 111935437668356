import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCourier,
    clearCourier,
    saveCourier,
    createEmptyCourier,
} from '../../redux/actions/courier/courier-actions';
import type { Courier } from '../../DTOs/courier';
import {
    clearNotification,
    setIsFormDirty,
    success,
} from '../../redux/actions';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import SfaForm from '../../components/SfaForm';
import SfaInput from '../../components/SfaInput';
import UneditablePill from '../../components/UneditablePill';
import UneditableInput from '../../components/UneditableInput';
import OkCancelModal from '../../components/OkCancelModal';
import { required } from '../../constants/validate';
import SfaReduxToggle from '../../components/sfaToggle/sfaReduxToggle';
import UnsavedFromDataAlertModel from '../../components/UnsavedFromDataAlertModel';
import permissions from '../../constants/permissions';

let EditCourierPage = ({
    dirty,
    history,
    match: {
        params: { id },
    },
    handleSubmit,
}) => {
    const dispatch = useDispatch();
    const [isEditView, setIsEditView] = useState(!id);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(getCourier(id));
        } else {
            dispatch(createEmptyCourier());
        }

        return () => {
            dispatch(clearNotification());
            dispatch(clearCourier());
        };
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(setIsFormDirty(dirty));
    }, [dirty, dispatch]);

    const courier: Courier = useSelector((state) => state.editCourier.courier);

    const loading: boolean = useSelector(
        (state) => state.editCourier.isLoading
    );
    const toggleEditView = () => {
        id ? setIsEditView(!isEditView) : goBack();
    };

    const save = async (values) => {
        try {
            const response = await dispatch(saveCourier(id, values));
            if (response) {
                updateSuccess(
                    `The courier was successfully ${!id ? 'saved' : 'updated'}.`
                );
            }
        } catch (err) {
            setIsShowConfirmation(false);
            return { name: err.response.data };
        }

        goBack();
    };
    const goBack = () => {
        history.replace('/courier');
    };
    const toggleConfirmationDialog = () => {
        setIsShowConfirmation(!isShowConfirmation);
    };

    const updateSuccess = (msg) => {
        setTimeout(() => {
            dispatch(
                success({
                    msg: msg,
                    target: 'CourierListPage',
                })
            );
        }, 100);
    };

    return (
        <Page name="EditCourierPage" data-test="EditCourierPage">
            <SfaBreadCrumb
                onBreadCrumb={goBack}
                breadCrumbText="Courier"
                animation="fade right"
            />
            <SfaForm
                name="UpdateCourierGroup"
                loading={loading}
                showEdit={!isEditView}
                showSaveCancel={isEditView}
                onEdit={toggleEditView}
                editButtonRoles={[permissions.ROLE_COURIER_UPDATE]}
                onCancel={goBack}
                headerText={
                    id
                        ? (isEditView ? `Update` : '') + ` ${courier?.code}`
                        : 'Create Courier'
                }
                onSubmit={handleSubmit(toggleConfirmationDialog)}
                animation="fade left"
                showDelete={!isEditView}
                deleteButtonText="Cancel"
                onDelete={goBack}
                deleteButtonRoles={[permissions.ROLE_COURIER_UPDATE]}
            >
                <Form.Field>
                    <OkCancelModal
                        header={id ? `Update Courier` : `Create Courier`}
                        body={`Are you sure you want to save the courier?`}
                        cancelFunction={toggleConfirmationDialog}
                        okFunction={handleSubmit(save)}
                        isOpen={isShowConfirmation}
                        okText={'Save'}
                    />

                    {isEditView && (
                        <>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Code *'}
                                        inputName={'code'}
                                        validations={required}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Description *'}
                                        inputName={'description'}
                                        validations={required}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Tracking Url *'}
                                        inputName={'trackingUrl'}
                                        validations={required}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="two">
                                <Form.Field>
                                    <SfaReduxToggle
                                        toggleName={'isActive'}
                                        labelText="Status *"
                                        activeLabel={'ACTIVE'}
                                        inactiveLabel={'INACTIVE'}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                    )}
                    {!isEditView && (
                        <>
                            <UneditableInput
                                label="Code"
                                data={courier?.code}
                            />

                            <UneditableInput
                                label="Description"
                                data={courier?.description}
                            />

                            <UneditableInput
                                label="Tracking Url"
                                data={courier?.trackingUrl}
                            />
                            <UneditablePill
                                label="Status"
                                data={courier?.isActive ? 'ACTIVE' : 'INACTIVE'}
                            />
                        </>
                    )}
                </Form.Field>
            </SfaForm>
            <UnsavedFromDataAlertModel when={dirty} history={history} />
        </Page>
    );
};

const validate = (values) => {
    const error = {};
    if (values.name?.length > 10) {
        error.name =
            'The length of the code is too long. The maximum length is 10';
    }
    if (!values.description) {
        error.description = 'Required';
    }

    if (!values.trackingUrl) {
        error.trackingUrl = 'Required';
    }
    return error;
};
EditCourierPage = reduxForm({
    form: 'EditCourierPage',
    validate,
    enableReinitialize: true,
    pure: true,
})(EditCourierPage);

EditCourierPage = connect((state) => {
    const initialValues = state.editCourier.courier;
    return {
        initialValues: initialValues,
    };
})(EditCourierPage);

export default EditCourierPage;
