/*
 * PCalendar should be used as the render component of a Redux Form Input conpoment.
 *
 * Example:  <Field name="dateCreated" component={PCalendar} label="Created Date *" validate={required}/>
 */

import React, { Component } from 'react';
import Calendar from '../calendar/Calendar';
import { Popup, Input } from 'semantic-ui-react';
import moment from 'moment';
import config from '../../constants/config';
import { PLabel } from '.';

export class PCalendar extends Component {
    state = { open: false };

    openCalendar = () => this.setState({ open: true });

    closeCalendar = () => this.setState({ open: false });

    formatToDisplayDate = (date) =>
        this.formatDate(date, config.DISPLAY_DATE_FORMAT);

    formatToServerDate = (date) =>
        this.formatDate(date, config.DATE_FORMAT_FOR_SERVER);

    formatDate = (date, formatToDate) => {
        let momentDate = moment(date);
        if (!momentDate.isValid()) {
            momentDate = moment(date, config.DISPLAY_DATE_FORMAT);
        }

        if (!momentDate.isValid()) {
            momentDate = moment(date, config.DATE_FORMAT_FOR_SERVER);
        }

        if (!momentDate.isValid()) {
            console.error(
                `date: ${date}, is not valid. change to a formattable date, ${config.DISPLAY_DATE_FORMAT}, ${config.DATE_FORMAT_FOR_SERVER} or known ISO 8601 format`
            );
        }

        return momentDate.format(formatToDate);
    };

    render() {
        const { input, meta, label, style, minDate, maxDate } = this.props;
        const { open } = this.state;

        return (
            <div className="field">
                <PLabel text={label} />
                <div>
                    <Popup
                        trigger={
                            <Input
                                {...input}
                                icon="calendar"
                                value={
                                    input.value
                                        ? this.formatToDisplayDate(input.value)
                                        : ''
                                }
                                disabled={meta.disabled}
                                readOnly
                                style={style || { width: '100%' }}
                                onClick={this.openCalendar}
                            />
                        }
                        content={
                            <Calendar
                                minDate={minDate}
                                maxDate={maxDate}
                                selectedDate={
                                    input.value
                                        ? this.formatToServerDate(input.value)
                                        : ''
                                }
                                onValuesChange={(value) => {
                                    input.value = value;
                                    input.onChange(value);
                                    this.closeCalendar();
                                }}
                            />
                        }
                        on="click"
                        open={open}
                        onClose={() => {
                            //ensure always have a valid date for the server when closing the input
                            const value = input.value
                                ? this.formatToServerDate(input.value)
                                : null;
                            input.onChange(value);

                            this.closeCalendar();
                        }}
                        style={{ height: '290px' }}
                    />
                    {meta.touched &&
                        ((meta.error && (
                            <div className="error-text">
                                <i>{meta.error}</i>
                            </div>
                        )) ||
                            (meta.warning && (
                                <div className="warning-text">
                                    <i>{meta.warning}</i>
                                </div>
                            )))}
                </div>
            </div>
        );
    }
}
