import React from 'react';

const NoItems = ({
    iconClass,
    iconImage,
    small,
    hideNoItemsMessage,
    messageClassName,
    style,
    message,
    iconFontSize,
    header,
}) => {
    const _iconClass = iconClass || 'icon-Empty-state-2';
    const _iconImage = iconImage || null;
    const height = small ? 40 : 90;
    const topMargin = small ? 0 : 80;
    const noItemsHeader = header !== null ? header : 'No items found';

    return (
        <div
            style={{
                width: '100%',
                marginTop: { topMargin },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...style,
            }}
        >
            {iconImage ? (
                <img
                    style={{ marginBottom: 10 }}
                    height={height}
                    src={_iconImage}
                    alt="no-data icon"
                />
            ) : (
                <span
                    className={`${_iconClass} secondary-color`}
                    style={{
                        fontSize: iconFontSize || height,
                        marginBottom: 10,
                    }}
                >
                    {' '}
                </span>
            )}
            {!hideNoItemsMessage && (
                <span
                    style={{
                        color: '#fdc63b',
                        fontSize: 30,
                        marginTop: 10,
                        marginBottom: 20,
                    }}
                >
                    No items!
                </span>
            )}
            <span
                className={messageClassName}
                style={{ fontSize: 13, width: '100%', textAlign: 'center' }}
            >
                {message ? `${message}` : `${noItemsHeader}`}
            </span>
        </div>
    );
};

export default NoItems;
