import {
    TEXT_NOTE_LOADING,
    TEXT_NOTE_INFO_UPDATE,
    TEXT_NOTE_LOADING_DONE,
    INIT_TEXT_NOTE_INFO,
} from '../../actions/textNotes/readTextNote';

const INITIAL_STATE = {
    loading: false,
    taskIsEditable: true,
    textNoteInfo: {},
};

const readTextNote = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEXT_NOTE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case TEXT_NOTE_INFO_UPDATE:
            return {
                ...state,
                textNoteInfo: action.data,
                loading: false,
                taskIsEditable: false,
            };
        case TEXT_NOTE_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };
        case INIT_TEXT_NOTE_INFO:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
export default readTextNote;
