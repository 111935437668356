import { Backend } from './backend';

import { checkFilters } from './utils';

class GamesApi {
    constructor() {
        this.path = '/games';
    }

    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/version`,
        });
    }

    async getPricePoints() {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/prices`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllGames({ page, size, sortOrder, sortColumn, filters, query }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'name',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    //note that this returns a list of retailerInventory (re-)using standard search interface
    async getInactivatedGames({
        gameActivationDateOnOrBefore,
        gameActivationDateOnOrAfter,
        retailerReferences,
    }) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search-with-references`,
                body: {
                    sortOrder: 'ASC',
                    sortColumn: 'reference',
                    filters: [
                        {
                            property: 'launchTypeNot',
                            data: ['S'],
                        },
                        {
                            property: 'status',
                            data: ['ACTIVE'],
                        },
                        {
                            property: 'gameActivationDateOnOrBefore',
                            data: [gameActivationDateOnOrBefore],
                        },
                        {
                            property: 'gameActivationDateOnOrAfter',
                            data: [gameActivationDateOnOrAfter],
                        },
                        {
                            property: 'retailerReferences',
                            data: retailerReferences,
                        },
                    ],
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getUnReturnedGames(filters) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/unreturned-games`,
                body: {
                    sortOrder: 'ASC',
                    sortColumn: 'reference',
                    filters,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getGameDetailsByGameNumber(gameList) {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/by-references`,
                body: gameList,
            });
        } catch (e) {
            throw e;
        }
    }

    async getInstantInventoryByRetailerReference(reference) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/instant-inventory/${reference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getLatestImportByImportName(importName) {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/latest-import/${importName}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllOrderableGameItems(retailerType, jurisdiction, isPostItem) {
        let gameType = 'LT';

        if (isPostItem) gameType = 'LT,PS';

        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/get-orderable-pull-tab-games/${gameType}?retailerType=${retailerType}&jurisdictionCode=${jurisdiction}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllActiveLottoTickets(isGroupByRetailerType, isPostItem) {
        let gameType = 'LT';
        if (isPostItem) gameType = 'LT,PS';
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/ACTIVE/${gameType}?isGroupByRetailerType=${isGroupByRetailerType}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getUpcomingGames(
        retailerType,
        retailerReference,
        noOfDaysForUpcomingGames,
    ) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/upcoming-games/${retailerType}/${retailerReference}/${noOfDaysForUpcomingGames}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getClosingGames(retailerType, retailerReference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/closing-games/${retailerType}/${retailerReference}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { GamesApi };
