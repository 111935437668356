import {
    LAST_ORDER_SUMMARY_INIT,
    LAST_ORDER_SUMMARY_LOADING,
    LAST_ORDER_SUMMARY,
    LAST_ORDER_SUMMARY_ERROR,
} from '../../../../actions/retailerDashboard/widgets/lastOrderSummary/lastOrderSummary';

const INITIAL_STATE = {
    lastOrderSummaries: undefined,
    loading: false,
    errorResponse: null,
};

const lastOrderSummary = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LAST_ORDER_SUMMARY_INIT:
            return { ...INITIAL_STATE };
        case LAST_ORDER_SUMMARY_LOADING:
            return { ...state, loading: true };
        case LAST_ORDER_SUMMARY:
            return {
                ...state,
                lastOrderSummaries: action.lastOrderSummaries,
                loading: false,
                errorResponse: null,
            };
        case LAST_ORDER_SUMMARY_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default lastOrderSummary;
