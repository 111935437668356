import _ from 'lodash';

import { TaskApi } from '../../../../services/task-service';
import moment from 'moment';

export const TODAY_PLAN_WIDGET_LOADING = 'TODAY_PLAN_WIDGET_LOADING';
export const TODAY_PLAN_WIDGET_SUCCESS = 'TODAY_PLAN_WIDGET_SUCCESS';

const taskApi = new TaskApi();

export const loadTodayPlan = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: TODAY_PLAN_WIDGET_LOADING, loading: true });

            const date = moment().format('YYYY-MM-DD');
            const pastDueTasks = await taskApi.getMyPastDueTasks(date);

            const startOfWeek = moment(date)
                .startOf('isoWeek')
                .format('YYYY-MM-DD');

            const tasksForWeek = await taskApi.getAllMyRetailerTasksForWeek(
                startOfWeek
            );
            const adHocTasksForWeek = await taskApi.getMyAdHocTasksForWeek(
                startOfWeek
            );

            const todayTasks = _.filter(
                tasksForWeek || [],
                ({ dueDate }) => dueDate && moment(dueDate).isSame(moment(date))
            );

            const scheduledTasksCount = todayTasks.length;
            const retailerVisitsCount = _.uniqBy(
                todayTasks,
                'retailerId'
            ).length;

            const todayAdHocTasks = _.filter(
                adHocTasksForWeek,
                ({ scheduleType, retailerId, dueDate }) =>
                    scheduleType === 'ONE_TIME' &&
                    dueDate &&
                    moment(dueDate).isSame(moment(date))
            );

            const adHocTasksCount =
                !todayAdHocTasks || todayAdHocTasks.length <= 0
                    ? 0
                    : _.uniqBy(todayAdHocTasks, 'retailerId').length;

            dispatch({
                type: TODAY_PLAN_WIDGET_SUCCESS,
                pastDueTasksCount: pastDueTasks.length,
                scheduledTasksCount,
                retailerVisitsCount,
                adHocTasksCount,
            });
        } catch (e) {
            console.error(e);
            dispatch({ type: TODAY_PLAN_WIDGET_LOADING, loading: false });
        }
    };
};
