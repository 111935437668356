import React, { Component } from 'react';
import { Input, Popup, Icon } from 'semantic-ui-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatToDisplayDate, formatToServerDate } from '../constants/utils';

class PDateRange extends Component {
    initDateRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'dateRange',
    };

    state = {
        isOpen: false,
        dateRange: {
            ...this.initDateRange,
        },
        display: {
            startDate: '',
            endDate: '',
        },
    };

    clearValue = () => {
        const { input } = this.props;
        this.setState({
            display: { startDate: undefined, endDate: undefined },
            dateRange: { ...this.initDateRange },
            isOpen: false,
        });
        input.onChange({
            from: undefined,
            to: undefined,
        });
    };

    openDateRangeModal = () => {
        this.setState({ isOpen: true });
    };

    closeDateRangeModal = () => {
        this.setState({ isOpen: false });
    };

    handleSelect = (ranges) => {
        const { input } = this.props;
        this.setState({
            dateRange: ranges.dateRange,
            display: ranges.dateRange,
        });
        let newValue = {
            from: formatToServerDate(ranges.dateRange.startDate),
            to: formatToServerDate(ranges.dateRange.endDate),
        };
        input.onChange(newValue);
    };

    render() {
        const {
            className,
            meta,
            input,
            openOnMount,
            label,
            clearable,
            ...otherProps
        } = this.props;

        const {
            display: { endDate, startDate },
            dateRange,
            isOpen,
        } = this.state;

        return (
            <div>
                <Popup
                    trigger={
                        <div>
                            <Input
                                fluid
                                placeholder="From and To Dates"
                                value={
                                    startDate && endDate
                                        ? formatToDisplayDate(startDate) +
                                          ' - ' +
                                          formatToDisplayDate(endDate)
                                        : ''
                                }
                                className={className}
                                autoFocus={openOnMount}
                                readOnly
                                onClick={this.openDateRangeModal}
                                icon={
                                    clearable && startDate && endDate ? (
                                        <Icon
                                            name="close"
                                            link
                                            onClick={this.clearValue}
                                        />
                                    ) : (
                                        <Icon
                                            name="calendar"
                                            link
                                            onClick={this.openDateRangeModal}
                                        />
                                    )
                                }
                            />
                        </div>
                    }
                    on="focus"
                    open={isOpen}
                    onClose={this.closeDateRangeModal}>
                    <Popup.Content>
                        <DateRange
                            {...otherProps}
                            onChange={this.handleSelect}
                            ranges={[dateRange]}
                        />
                    </Popup.Content>
                </Popup>
                {meta.touched &&
                    ((meta.error && (
                        <div className="error-text">
                            <i>{meta.error}</i>
                        </div>
                    )) ||
                        (meta.warning && (
                            <div className="warning-text">
                                <i>{meta.warning}</i>
                            </div>
                        )))}
            </div>
        );
    }
}

export default PDateRange;
