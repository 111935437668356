import {
    SALES_TARGETS_YTD,
    SALES_TARGETS_YTD_LOADING,
    SALES_TARGETS_YTD_FAIL,
} from '../../../actions/dashboard/widgets/ytdSalesTargetWidget';

const INITIAL_STATE = {
    loading: false,
    percentage: 0,
    salesTarget: 0,
    error: '',
};

const ytdSalesTargetWidget = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALES_TARGETS_YTD_LOADING:
            return { ...state, loading: true };
        case SALES_TARGETS_YTD:
            return { ...state, ...action.data, loading: false, error: '' };
        case SALES_TARGETS_YTD_FAIL:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};
export default ytdSalesTargetWidget;
