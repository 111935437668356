import { Jurisdiction } from './jurisdiction';

export interface RetailerGroup {
    id: number;
    name: string;
    isActive: boolean;
    description: string;
    minFacings: number;
    maxFacings: number;
    jurisdiction: Jurisdiction;
}
