import { SurveyApi } from '../../../services/survey-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';

import { getComplianceSurveyType } from '../survey/survey';

export const COMPLIANCE_CREATE_SUCCESS = 'COMPLIANCE_CREATE_SUCCESS';
export const COMPLIANCE_CREATE_INIT = 'COMPLIANCE_CREATE_INIT';
export const COMPLIANCE_CREATE_PAGE_LOADING = 'COMPLIANCE_CREATE_PAGE_LOADING';
export const COMPLIANCE_CREATE_PAGE_DONE = 'COMPLIANCE_CREATE_PAGE_DONE';

const surveyApi = new SurveyApi();

const createComplianceError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'CreateComplianceTemplate',
            })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const createComplianceTemplate = (createComplianceTemplate) => {
    return async (dispatch, getState) => {
        let success = true;
        dispatch({ type: COMPLIANCE_CREATE_PAGE_LOADING });
        try {
            //get survey type id for compliance
            const complianceType = await dispatch(getComplianceSurveyType());

            //create compliance survey payload
            const complianceSurvey = {
                surveyType: complianceType.id,
                name: createComplianceTemplate.name.trim(),
                description: createComplianceTemplate.description,
                status: createComplianceTemplate.status,
            };

            //create survey
            const response = await surveyApi.createSurvey(complianceSurvey);

            //create question request payload
            const complianceQuestions = [
                {
                    text: createComplianceTemplate.question,
                    questionType: createComplianceTemplate.questionType,
                },
            ];
            await surveyApi.addQuestionsToSurvey(
                response.headers.location,
                complianceQuestions
            );

            dispatch({
                type: COMPLIANCE_CREATE_SUCCESS,
            });
        } catch (e) {
            success = false;
            dispatch(createComplianceError(e));
        } finally {
            dispatch({ type: COMPLIANCE_CREATE_PAGE_DONE });
        }

        return success;
    };
};

export const initComplianceTemplate = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: COMPLIANCE_CREATE_INIT });
        } catch (e) {
            dispatch(createComplianceError(e));
            dispatch(createErrorHandlerError(e));
        }
    };
};
