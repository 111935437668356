import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    Dimmer,
    Dropdown,
    Loader,
    Modal,
    Table,
    Accordion,
} from 'semantic-ui-react';
import './style.css';
import {
    initViewReturn,
    resetViewReturnModal,
    viewReturnsLoading,
    viewReturnsLoaded,
} from '../../redux/actions/returns/viewReturn';
import classNames from 'classnames';
import _ from 'lodash';
import PendingReturnItemsList from './components/PendingReturnItemsList';
import permissions from '../../constants/permissions';
import { cancelReturn } from '../../redux/actions/returns/cancelReturn';
import { success } from '../../redux/actions/notifications';
import { error } from '../../redux/actions';
import Secured from '../../components/security/Secured';
import { ConfirmationModal } from '../../components/notifications/confirmationModal';
import { printReturnByExternalReturnNumberNumber } from '../../redux/actions/returns/print-return';
import { withRouter } from 'react-router-dom';
import config from '../../constants/config';
import { formatToDisplayDate } from '../../constants/utils';

const RETURN_TYPE = {
    all: 'all',
    book: 'book',
    ticket: 'ticket',
    posItem: 'ps',
};

class ViewReturn extends Component {
    state = {
        returnType: RETURN_TYPE.all,
        showCancelModal: false,
        showCancelReturnModal: false,
    };

    handleScanTypeChange = (e, data) => {
        this.setScanType(data.value);
    };

    setScanType = (scanType) => {
        this.setState({ scanType: scanType });
    };

    setReturnType = (returnType) => {
        this.setState({ returnType: returnType });
    };

    handleAllButtonClicked = () => {
        this.setReturnType(RETURN_TYPE.all);
    };

    handleBooksButtonClicked = () => {
        this.setReturnType(RETURN_TYPE.book);
    };

    handleTicketsButtonClicked = () => {
        this.setReturnType(RETURN_TYPE.ticket);
    };

    handlePosItemButtonClicked = () => {
        this.setReturnType(RETURN_TYPE.posItem);
    };

    componentDidMount() {
        this.props.initViewReturn(this.props.match.params.id);
    }

    closeViewReturnModal = () => {
        this.props.history.push(config.ROUTE_URLS.RETAILER_RETURN_LIST);
        this.closeCancelModal();
        this.setState({
            returnType: RETURN_TYPE.all,
        });
        this.props.resetViewReturnModal();
    };

    prepareFilteredItemsList = (items) => {
        if (this.state.returnType === RETURN_TYPE.all) {
            return items;
        } else if (this.state.returnType === RETURN_TYPE.book) {
            return _.filter(items, (item) => {
                return item.itemType === RETURN_TYPE.book;
            });
        } else if (this.state.returnType === RETURN_TYPE.posItem) {
            return _.filter(items, (item) => {
                return item.itemType === RETURN_TYPE.posItem;
            });
        } else {
            return _.filter(items, (item) => {
                return item.itemType === RETURN_TYPE.ticket;
            });
        }
    };

    closeCancelModal = () => {
        this.setState({ showCancelModal: false });
    };

    showCancelReturnModal = () => {
        this.setState({
            showCancelReturnModal: true,
        });
    };

    closeCancelReturnModal = () => {
        this.setState({ showCancelReturnModal: false });
    };

    handleCancelReturn = async () => {
        const { pendingReturn } = this.props;
        this.closeCancelReturnModal();
        await this.props.cancelReturn(pendingReturn);
    };

    printReturn = (returnId) => {
        const { printReturn } = this.props;
        printReturn(returnId);
    };

    getDropDownMenu = (unprocessed, pendingReturn) => (
        <Dropdown.Menu direction="left">
            <Secured hasRole={permissions.ROLE_RETURN_CANCEL}>
                {unprocessed && (
                    <Dropdown.Item
                        text="Cancel Return"
                        onClick={this.showCancelReturnModal}
                    />
                )}
            </Secured>
            <Dropdown.Item
                text="Print Return"
                onClick={() =>
                    this.printReturn(pendingReturn?.externalReturnNumber)
                }
            />
        </Dropdown.Menu>
    );

    getCountForExpectedItems = (
        totalExpectedBookCount,
        totalExpectedTicketCount,
        totalExpectedPosItemCount
    ) => (
        <div className="text-center container padded padding-5">
            {(this.state.returnType === RETURN_TYPE.book ||
                this.state.returnType === RETURN_TYPE.all) && (
                <span>Packs: {totalExpectedBookCount}</span>
            )}
            &nbsp;
            {(this.state.returnType === RETURN_TYPE.ticket ||
                this.state.returnType === RETURN_TYPE.all) && (
                <span>Tickets: {totalExpectedTicketCount}</span>
            )}
            &nbsp;
            {(this.state.returnType === RETURN_TYPE.posItem ||
                this.state.returnType === RETURN_TYPE.all) && (
                <span>POS Items: {totalExpectedPosItemCount}</span>
            )}
        </div>
    );

    getCountForScannedOrUnexpectedItems = (
        isProcessed,
        totalBookCount,
        totalTicketCount,
        totalPosItemCount
    ) => (
        <div className="text-center container padded padding-5">
            {isProcessed &&
                (this.state.returnType === RETURN_TYPE.book ||
                    this.state.returnType === RETURN_TYPE.all) && (
                    <span>Packs: {totalBookCount}</span>
                )}
            &nbsp;
            {isProcessed &&
                (this.state.returnType === RETURN_TYPE.ticket ||
                    this.state.returnType === RETURN_TYPE.all) && (
                    <span>Tickets: {totalTicketCount}</span>
                )}
            &nbsp;
            {isProcessed &&
                (this.state.returnType === RETURN_TYPE.posItem ||
                    this.state.returnType === RETURN_TYPE.all) && (
                    <span>POS Items: {totalPosItemCount}</span>
                )}
        </div>
    );

    getExpectedListData = (filteredExpectedItems, returnType) => (
        <div
            className="container 
                    padded 
                    bg-translucent-black 
                    height-fill-parent 
                    view-return-list 
                    pending-return-items-list"
        >
            <div className="text-center">
                {_.size(filteredExpectedItems) ? (
                    <PendingReturnItemsList
                        returnItems={filteredExpectedItems}
                        readOnly={true}
                    />
                ) : (
                    <div>
                        <span className="icon-Success-2 all-items-scanned" />
                        <div>
                            All Expected{' '}
                            {(returnType === RETURN_TYPE.book ||
                                returnType === RETURN_TYPE.all) &&
                                'Packs'}
                            {returnType === RETURN_TYPE.all && ', '}
                            {(returnType === RETURN_TYPE.posItem ||
                                returnType === RETURN_TYPE.all) &&
                                'POS items'}
                            {returnType === RETURN_TYPE.all && ' and '}
                            {(returnType === RETURN_TYPE.ticket ||
                                returnType === RETURN_TYPE.all) &&
                                'Tickets'}{' '}
                            were Scanned/Verified
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    getScannedListData = (isProcessed, filteredScannedItems, returnType) => (
        <div
            className="container 
                        padded 
                        height-fill-parent 
                        bg-translucent-black 
                        view-return-list 
                        pending-return-items-list"
        >
            <div className="text-center">
                {isProcessed && _.size(filteredScannedItems) ? (
                    <PendingReturnItemsList
                        returnItems={filteredScannedItems}
                        readOnly={true}
                    />
                ) : (
                    isProcessed && (
                        <div>
                            <span className="icon-Success-2 all-items-scanned" />
                            <div>
                                There are no scanned/verified{' '}
                                {(returnType === RETURN_TYPE.book ||
                                    returnType === RETURN_TYPE.all) &&
                                    'Packs'}
                                {returnType === RETURN_TYPE.all && ', '}
                                {(returnType === RETURN_TYPE.posItem ||
                                    returnType === RETURN_TYPE.all) &&
                                    'POS items'}
                                {returnType === RETURN_TYPE.all && ' and '}
                                {(returnType === RETURN_TYPE.ticket ||
                                    returnType === RETURN_TYPE.all) &&
                                    'Tickets'}{' '}
                                returns
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );

    getUnexpectedListData = (
        isProcessed,
        filteredUnexpectedItems,
        returnType
    ) => (
        <div
            className="container 
                        padded 
                        bg-translucent-black 
                        height-fill-parent  
                        view-return-list 
                        pending-return-items-list"
        >
            <div className="text-center">
                {isProcessed && _.size(filteredUnexpectedItems) ? (
                    <PendingReturnItemsList
                        returnItems={filteredUnexpectedItems}
                        readOnly={true}
                    />
                ) : (
                    isProcessed && (
                        <div>
                            <span className="icon-Success-2 all-items-scanned" />
                            <div>
                                There are no unexpected{' '}
                                {(returnType === RETURN_TYPE.book ||
                                    returnType === RETURN_TYPE.all) &&
                                    'Packs'}
                                {returnType === RETURN_TYPE.all && ', '}
                                {(returnType === RETURN_TYPE.posItem ||
                                    returnType === RETURN_TYPE.all) &&
                                    'POS items'}
                                {returnType === RETURN_TYPE.all && ' and '}
                                {(returnType === RETURN_TYPE.ticket ||
                                    returnType === RETURN_TYPE.all) &&
                                    'Tickets'}{' '}
                                returns
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );

    render() {
        const {
            loading,
            expectedItems,
            unexpectedItems,
            scannedItems,
            pendingReturn,
            retailerInfo,
            cancelReturnInProgress,
        } = this.props;
        const viewReturnId = this.props.match.params.id;
        const { returnType, showCancelReturnModal } = this.state;

        let totalExpectedBookCount = 0;
        let totalExpectedTicketCount = 0;
        let totalExpectedPosItemCount = 0;

        const unprocessed = pendingReturn?.uiStatus?.code === 'U';
        const approved = pendingReturn?.uiStatus?.code === 'A';

        _.forEach(expectedItems, (expectedItem) => {
            if (expectedItem.itemType === RETURN_TYPE.ticket) {
                totalExpectedTicketCount += _.size(expectedItem.tickets);
            } else if (expectedItem.itemType === RETURN_TYPE.posItem) {
                totalExpectedPosItemCount += ~~expectedItem.itemQuantity;
            } else {
                ++totalExpectedBookCount;
            }
        });

        let totalScannedBookCount = 0;
        let totalScannedTicketCount = 0;
        let totalScannedPosItemCount = 0;

        _.forEach(scannedItems, (scannedItem) => {
            if (scannedItem.itemType === RETURN_TYPE.ticket) {
                totalScannedTicketCount += _.size(scannedItem.tickets);
            } else if (scannedItem.itemType === RETURN_TYPE.posItem) {
                totalScannedPosItemCount += ~~scannedItem.itemQuantity;
            } else {
                ++totalScannedBookCount;
            }
        });

        let totalUnexpectedBookCount = 0;
        let totalUnexpectedTicketCount = 0;
        let totalUnexpectedPosItemCount = 0;

        const isProcessed = pendingReturn?.uiStatus?.code === 'P';

        _.forEach(unexpectedItems, (unexpectedItem) => {
            if (unexpectedItem.itemType === RETURN_TYPE.ticket) {
                totalUnexpectedTicketCount += _.size(unexpectedItem.tickets);
            } else if (unexpectedItem.itemType === RETURN_TYPE.posItem) {
                totalUnexpectedPosItemCount += ~~unexpectedItem.itemQuantity;
            } else {
                ++totalUnexpectedBookCount;
            }
        });

        let filteredExpectedItems =
            this.prepareFilteredItemsList(expectedItems);

        let filteredUnexpectedItems =
            this.prepareFilteredItemsList(unexpectedItems);

        let filteredScannedItems = this.prepareFilteredItemsList(scannedItems);

        const cancelReturnModalActions = [
            { onClick: this.closeCancelReturnModal, text: 'No' },
            { onClick: this.handleCancelReturn, text: 'Yes' },
        ];

        const cancelReturnModalMessage = (
            <span className="cancel-modal-message">
                Return Number: {_.padStart(viewReturnId, 6, '0')}
            </span>
        );

        const cancelReturnModalContent = (
            <div className="cancel-modal-content">
                Are you sure you want to cancel this return?
            </div>
        );

        return (
            <Modal
                basic
                size="fullscreen"
                centered={false}
                open={true}
                onUnmount={this.handleReturnModalUnmount}
                className="view-return-modal"
            >
                <Dimmer active={loading || cancelReturnInProgress} page>
                    <Loader />
                </Dimmer>

                <ConfirmationModal
                    isOpen={showCancelReturnModal}
                    headerContent="Cancel Return?"
                    modalMessage={cancelReturnModalMessage}
                    actions={cancelReturnModalActions}
                    bodyContent={cancelReturnModalContent}
                    size={'small'}
                />

                <Modal.Content>
                    <Table className="justify-content-center background-transparent">
                        <Table.Row verticalAlign="middle" columns={1}>
                            <Table.Cell>
                                <div>
                                    <span>
                                        <span className="title-text bold white-text">
                                            View Return :{' '}
                                            {_.padStart(viewReturnId, 6, '0')}
                                        </span>
                                    </span>
                                    {(unprocessed || approved) && (
                                        <span>
                                            <span className="min-tablet">
                                                <Dropdown
                                                    floating
                                                    selectOnBlur={false}
                                                    button
                                                    text="Options"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 40,
                                                        right: '0.75rem',
                                                        color: 'white',
                                                        background:
                                                            'transparent',
                                                    }}
                                                >
                                                    {this.getDropDownMenu(
                                                        unprocessed,
                                                        pendingReturn
                                                    )}
                                                </Dropdown>
                                            </span>
                                            <span className="max-mobile">
                                                <Dropdown
                                                    floating
                                                    selectOnBlur={false}
                                                    icon="ellipsis vertical"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 40,
                                                        right: '0.75rem',
                                                        color: 'white',
                                                        background:
                                                            'transparent',
                                                    }}
                                                >
                                                    {this.getDropDownMenu(
                                                        unprocessed,
                                                        pendingReturn
                                                    )}
                                                </Dropdown>
                                            </span>
                                        </span>
                                    )}
                                    <span
                                        className="icon-Close white-text close-button-text static-close-button"
                                        onClick={this.closeViewReturnModal}
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="middle"
                            className={'pt-0 pb-0'}
                        >
                            <Table.Cell
                                width={5}
                                textAlign="left"
                                className={'pt-0 pb-0'}
                                colspan="3"
                            >
                                <span className="view-return-info-text white-text">
                                    Retailer Name : {retailerInfo?.reference} -{' '}
                                    {retailerInfo?.name}
                                </span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="middle"
                            className={'pt-0 pb-0'}
                        >
                            <Table.Cell
                                width={5}
                                textAlign="left"
                                className={'pt-0 pb-0'}
                                colspan="3"
                            >
                                <span className="view-return-info-text white-text">
                                    Return Reason :{' '}
                                    {
                                        pendingReturn?.returnReason
                                            ?.returnReasonDesc
                                    }
                                </span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="middle"
                            className={'pt-0 pb-0'}
                        >
                            <Table.Cell
                                width={5}
                                textAlign="left"
                                className={'pt-0 pb-0'}
                                colspan="3"
                            >
                                <span className="view-return-info-text white-text">
                                    Return Status :{' '}
                                    {pendingReturn?.uiStatus?.description}
                                </span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="middle"
                            className={'pt-0 pb-0'}
                        >
                            <Table.Cell
                                width={5}
                                textAlign="left"
                                className={'pt-0 pb-0'}
                                colspan="3"
                            >
                                <span className="view-return-info-text white-text">
                                    Return Date :{' '}
                                    {formatToDisplayDate(
                                        pendingReturn?.orderDate
                                    )}
                                </span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="middle"
                            className={'pt-0 pb-0'}
                        >
                            <Table.Cell
                                width={5}
                                textAlign="left"
                                className={'pt-0 pb-0'}
                                colspan="3"
                            >
                                <span className="view-return-info-text white-text">
                                    Completed Date :{' '}
                                    {formatToDisplayDate(
                                        pendingReturn?.completedDate
                                    )}
                                </span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="middle">
                            <Table.Cell width={5} textAlign="left" colspan="3">
                                <Button
                                    size="small"
                                    className={classNames(
                                        'view-button-padding',
                                        this.state.returnType ===
                                            RETURN_TYPE.all
                                            ? 'primary'
                                            : 'returnType'
                                    )}
                                    onClick={this.handleAllButtonClicked}
                                >
                                    All
                                    <span
                                        className={classNames({
                                            'icon-Success-2 accent-1': !(
                                                totalExpectedBookCount ||
                                                totalExpectedTicketCount
                                            ),
                                        })}
                                    />
                                </Button>
                                <Button
                                    size="small"
                                    className={classNames(
                                        'view-button-padding',
                                        this.state.returnType ===
                                            RETURN_TYPE.book
                                            ? 'primary'
                                            : 'returnType'
                                    )}
                                    onClick={this.handleBooksButtonClicked}
                                >
                                    Packs
                                    <span
                                        className={classNames({
                                            'icon-Success-2 accent-1':
                                                !totalExpectedBookCount,
                                        })}
                                    />
                                </Button>
                                <Button
                                    size="small"
                                    className={classNames(
                                        'view-button-padding',
                                        this.state.returnType ===
                                            RETURN_TYPE.ticket
                                            ? 'primary'
                                            : 'returnType'
                                    )}
                                    onClick={this.handleTicketsButtonClicked}
                                >
                                    Tickets
                                    <span
                                        className={classNames({
                                            'icon-Success-2 accent-1':
                                                !totalExpectedTicketCount,
                                        })}
                                    />
                                </Button>
                                <Button
                                    size="small"
                                    className={classNames(
                                        'view-button-padding',
                                        this.state.returnType ===
                                            RETURN_TYPE.posItem
                                            ? 'primary'
                                            : 'returnType'
                                    )}
                                    onClick={this.handlePosItemButtonClicked}
                                >
                                    POS Items
                                    <span
                                        className={classNames({
                                            'icon-Success-2 accent-1':
                                                !totalExpectedPosItemCount,
                                        })}
                                    />
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="middle" columns={3}>
                            <Table.Cell width={5} textAlign="left">
                                <div className="bg-white container shadow-2 flex-column max-mobile">
                                    <Accordion
                                        panels={[
                                            {
                                                key: { viewReturnId },
                                                title: {
                                                    content: 'Expected',
                                                },
                                                content: {
                                                    content: (
                                                        <div>
                                                            {this.getCountForExpectedItems(
                                                                totalExpectedBookCount,
                                                                totalExpectedTicketCount,
                                                                totalExpectedPosItemCount
                                                            )}
                                                            {this.getExpectedListData(
                                                                filteredExpectedItems,
                                                                returnType
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                            },
                                        ]}
                                        className="return-detail-accordian"
                                    ></Accordion>
                                </div>
                                <div className="bg-white container shadow-2 flex-column min-tablet">
                                    <div className="subheader-text bold">
                                        <div className="text-center margin-hr-5 width-fill-parent">
                                            <span>Expected</span>
                                        </div>
                                    </div>
                                    {this.getCountForExpectedItems(
                                        totalExpectedBookCount,
                                        totalExpectedTicketCount,
                                        totalExpectedPosItemCount
                                    )}
                                    {this.getExpectedListData(
                                        filteredExpectedItems,
                                        returnType
                                    )}
                                </div>
                            </Table.Cell>
                            <Table.Cell width={5} textAlign="left">
                                <div className="bg-white container shadow-2 flex-column max-mobile">
                                    <Accordion
                                        panels={[
                                            {
                                                key: { viewReturnId },
                                                title: {
                                                    content: 'Scanned/Verified',
                                                },
                                                content: {
                                                    content: isProcessed ? (
                                                        <div>
                                                            {this.getCountForScannedOrUnexpectedItems(
                                                                isProcessed,
                                                                totalScannedBookCount,
                                                                totalScannedTicketCount,
                                                                totalScannedPosItemCount
                                                            )}
                                                            {this.getScannedListData(
                                                                isProcessed,
                                                                filteredScannedItems,
                                                                returnType
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    ),
                                                },
                                            },
                                        ]}
                                        className="return-detail-accordian"
                                    ></Accordion>
                                </div>
                                <div
                                    className={classNames(
                                        isProcessed
                                            ? 'bg-white'
                                            : 'translucent scanned-or-verified-items-container',
                                        'container',
                                        'shadow-2',
                                        'flex-column',
                                        'min-tablet'
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            !isProcessed
                                                ? 'subheader-text'
                                                : 'subheader-font-size',
                                            'bold'
                                        )}
                                    >
                                        <div className="text-center margin-hr-5 width-fill-parent">
                                            <span>Scanned/Verified</span>
                                        </div>
                                    </div>
                                    {this.getCountForScannedOrUnexpectedItems(
                                        isProcessed,
                                        totalScannedBookCount,
                                        totalScannedTicketCount,
                                        totalScannedPosItemCount
                                    )}
                                    {this.getScannedListData(
                                        isProcessed,
                                        filteredScannedItems,
                                        returnType
                                    )}
                                </div>
                            </Table.Cell>
                            <Table.Cell width={5} textAlign="left">
                                <div className="bg-white container shadow-2 flex-column max-mobile">
                                    <Accordion
                                        panels={[
                                            {
                                                key: { viewReturnId },
                                                title: {
                                                    content: 'Unexpected',
                                                },
                                                content: {
                                                    content: isProcessed ? (
                                                        <div>
                                                            {this.getCountForScannedOrUnexpectedItems(
                                                                isProcessed,
                                                                totalUnexpectedBookCount,
                                                                totalUnexpectedTicketCount,
                                                                totalUnexpectedPosItemCount
                                                            )}
                                                            {this.getUnexpectedListData(
                                                                isProcessed,
                                                                filteredUnexpectedItems,
                                                                returnType
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    ),
                                                },
                                            },
                                        ]}
                                        className="return-detail-accordian"
                                    ></Accordion>
                                </div>
                                <div
                                    className={classNames(
                                        isProcessed
                                            ? 'bg-white'
                                            : 'translucent scanned-or-verified-items-container',
                                        'container',
                                        'shadow-2',
                                        'flex-column',
                                        'min-tablet'
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            'bold',
                                            !isProcessed
                                                ? 'subheader-text'
                                                : 'subheader-font-size'
                                        )}
                                    >
                                        <div className="text-center margin-hr-5 width-fill-parent">
                                            <span>Unexpected</span>
                                        </div>
                                    </div>
                                    {this.getCountForScannedOrUnexpectedItems(
                                        isProcessed,
                                        totalUnexpectedBookCount,
                                        totalUnexpectedTicketCount,
                                        totalUnexpectedPosItemCount
                                    )}
                                    {this.getUnexpectedListData(
                                        isProcessed,
                                        filteredUnexpectedItems,
                                        returnType
                                    )}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                </Modal.Content>
            </Modal>
        );
    }
}

ViewReturn.propTypes = {
    viewReturnId: PropTypes.string,
    showViewReturnModal: PropTypes.bool,
};

const mapStateToProps = ({
    viewReturn,
    userInfo: { retailerInfo },
    cancelReturn: { loading: cancelReturnInProgress },
}) => {
    const {
        loading,
        pendingReturn,
        expectedItems,
        scannedItems,
        unexpectedItems,
    } = viewReturn;
    return {
        pendingReturn,
        loading,
        expectedItems,
        scannedItems,
        unexpectedItems,
        retailerInfo,
        cancelReturnInProgress,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initViewReturn: (id) => dispatch(initViewReturn(id)),
        cancelReturn: (orderReturn) => dispatch(cancelReturn(orderReturn)),
        resetViewReturnModal: () => dispatch(resetViewReturnModal()),
        success: (message) => dispatch(success(message)),
        error: (args) => dispatch(error(args)),
        viewReturnsLoading: () => dispatch(viewReturnsLoading()),
        viewReturnsLoaded: () => dispatch(viewReturnsLoaded()),
        printReturn: (exteranlReturnId) =>
            dispatch(printReturnByExternalReturnNumberNumber(exteranlReturnId)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ViewReturn)
);
