import { Backend } from './backend';
import type { Planogram } from '../DTOs/planogram';
import type { SearchSpecificationModel } from '../interface/search-specification-model';

class PlanogramService {
    constructor() {
        this.path = '/planograms/retailer-portal';
    }

    update = async (id: number, planogram: Planogram) => {
        try {
            return await Backend.send({
                method: 'put',
                url: `${this.path}/${id}`,
                body: planogram,
            });
        } catch (e) {
            throw e;
        }
    };

    create = async (planogram: Planogram) => {
        try {
            let formData = new FormData();
            formData.append('file', planogram.file[0]);
            formData.append('name', planogram.name);
            formData.append('startDate', planogram.startDate);
            formData.append('endDate', planogram.endDate);
            formData.append('description', planogram.description);
            formData.append('retailerGroupId', planogram.retailerGroup.id);
            formData.append('isActive', planogram.isActive);

            return await Backend.send({
                method: 'post',
                url: `${this.path}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    };
    getBySearchSpecificationModel = async (ssf: SearchSpecificationModel) => {
        try {
            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: ssf,
            });
        } catch (e) {
            throw e;
        }
    };
    getById = async (id: number) => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    };

    deleteById = async (id: number) => {
        try {
            return await Backend.send({
                method: 'delete',
                url: `${this.path}/${id}`,
            });
        } catch (e) {
            throw e;
        }
    };

    get = async () => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}/`,
            });
        } catch (e) {
            throw e;
        }
    };

    getPlanogramsByRetailerReference = async (retailerRefrence) => {
        try {
            console.log(retailerRefrence);
        } catch (e) {
            throw e;
        }
    };
}

export default PlanogramService;
