import { CorpAccountsApi } from '../../services/corp-accounts-service';
import { RetailersApi } from '../../services/retailers-service';
import { errorHandler } from './errorHandler/errorHandler';
import { getErrorStatus } from '../../constants/utils';

export const CORP_ACCOUNT_LOADING = 'CORP_ACCOUNT_LOADING';
export const CORP_ACCOUNT_LOADED = 'CORP_ACCOUNT_LOADED';
export const CORP_ACCOUNT_INIT_STATE = 'CORP_ACCOUNT_INIT_STATE';
export const UPDATE_ACCOUNT_CONTACTS_PROCESSING =
    'UPDATE_ACCOUNT_CONTACTS_PROCESSING';
export const UPDATE_ACCOUNT_CONTACTS_DONE = 'UPDATE_ACCOUNT_CONTACTS_DONE';
export const CORP_ACCOUNT_RETAILER_LOADING = 'CORP_ACCOUNT_RETAILER_LOADING';
export const CORP_ACCOUNT_RETAILER = 'CORP_ACCOUNT_RETAILER';

const corpAccountsApi = new CorpAccountsApi();
const retailersApi = new RetailersApi();

const readAccountErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getCorpAccountById = (args) => {
    return async (dispatch, getState) => {
        const {
            organization: { phoneFaxMask },
        } = getState();
        dispatch({ type: CORP_ACCOUNT_LOADING });

        const content = await corpAccountsApi.getCorpAccountById(args);

        dispatch({
            type: CORP_ACCOUNT_LOADED,
            content,
            phoneFaxMask,
        });
    };
};

export const getRetailersByAccountId = (accountId) => {
    return async (dispatch) => {
        dispatch({ type: CORP_ACCOUNT_RETAILER_LOADING });
        try {
            const retailerInfo = await retailersApi.getRetailersByAccountId(
                accountId
            );
            dispatch({
                type: CORP_ACCOUNT_RETAILER,
                retailerInfo,
            });
        } catch (e) {
            dispatch(readAccountErrorHandlerError(e));
        }
    };
};

export const clearCorpAccountState = () => {
    return async (dispatch) => {
        dispatch({ type: CORP_ACCOUNT_INIT_STATE });
    };
};

export const saveAccountsContacts = (accountId, accountContactObject) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: UPDATE_ACCOUNT_CONTACTS_PROCESSING });
        try {
            await corpAccountsApi.saveAccountContacts(
                accountId,
                accountContactObject
            );
        } catch (err) {
            success = false;
            dispatch(readAccountErrorHandlerError(err));
        } finally {
            dispatch({ type: UPDATE_ACCOUNT_CONTACTS_DONE });
        }
        return success;
    };
};
