import moment from 'moment';

import { error } from '../../notifications';
import { getErrorMessage } from '../../../../constants/utils';

import { SalesRoutesApi } from '../../../../services/sales-routes-service';
import { TaskApi } from '../../../../services/task-service';
import { UserApi } from '../../../../services/user-service';
import _ from 'lodash';

export const SALES_MANAGER_SALES_ROUTES_WIDGET_LOADING =
    'SALES_MANAGER_SALES_ROUTES_WIDGET_LOADING';
export const SALES_MANAGER_SALES_ROUTES_WIDGET_DATA =
    'SALES_MANAGER_SALES_ROUTES_WIDGET_DATA';

const salesRoutesApi = new SalesRoutesApi();
const taskApi = new TaskApi();
const userApi = new UserApi();

const createTaskWidgetError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'Dashboard' }));
    };
};

export const getSalesRoutesTaskDataForSalesManagers = (userInfo) => {
    return async (dispatch) => {
        dispatch({ type: SALES_MANAGER_SALES_ROUTES_WIDGET_LOADING });
        try {
            const salesRoutes =
                await salesRoutesApi.getSalesRouteByAllSalesManager();
            if (salesRoutes !== null && salesRoutes.length > 0) {
                const today = new Date();
                const start = moment(today).isoWeek();
                const firstDayOfWeek = moment(today)
                    .isoWeek(start)
                    .startOf('isoWeek')
                    .clone()
                    .format('YYYY-MM-DD');
                const salesRepList = _.uniq(
                    salesRoutes.map((salesRoute) => salesRoute.salesRep)
                );
                const salesRepUserAndIdList =
                    await userApi.getUserIdBySalesRepList(salesRepList);

                const repRouteRetailerAggregate = _.map(
                    salesRepUserAndIdList,
                    ({ id, username }) => ({
                        salesRepId: id,
                        salesRoutes: _.map(
                            _.filter(
                                salesRoutes,
                                ({ salesRep }) => salesRep === username
                            ),
                            ({ id, retailerIds }) => ({ id, retailerIds })
                        ),
                    })
                );

                const taskPerSalesRouteCount =
                    await taskApi.getAllRetailerTasksCountForWeek(
                        firstDayOfWeek,
                        repRouteRetailerAggregate
                    );
                const salesRouteAggregate = _.orderBy(
                    _.reduce(
                        salesRoutes,
                        (result, data) => {
                            const count = taskPerSalesRouteCount[data.id] || {};
                            let salesManagerData = _.find(
                                result,
                                ({ salesManagerName }) =>
                                    salesManagerName === data.salesManagerName
                            );

                            if (salesManagerData) {
                                salesManagerData.total += count.total || 0;
                                salesManagerData.completed +=
                                    count.totalComplete || 0;
                                _.remove(
                                    result,
                                    ({ salesManagerName }) =>
                                        salesManagerName ===
                                        data.salesManagerName
                                );
                            } else {
                                salesManagerData = {
                                    total: count.total,
                                    completed: count.totalComplete,
                                    salesManagerName: data.salesManagerName,
                                };
                            }
                            if (salesManagerData.total > 0) {
                                //only add it if there are tasks this week
                                result.push(salesManagerData);
                            }

                            salesManagerData.percentage =
                                salesManagerData.total === 0 ||
                                salesManagerData.completed === 0
                                    ? 0
                                    : salesManagerData.completed /
                                      salesManagerData.total;
                            return result;
                        },
                        []
                    ),
                    ['percentage', 'salesManagerName'],
                    ['asc', 'asc']
                );

                dispatch({
                    type: SALES_MANAGER_SALES_ROUTES_WIDGET_DATA,
                    data: salesRouteAggregate,
                });
            } else {
                dispatch({
                    type: SALES_MANAGER_SALES_ROUTES_WIDGET_DATA,
                    data: [],
                });
            }
        } catch (e) {
            console.error(e);
            dispatch(createTaskWidgetError(e));
        }
    };
};
