import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Image } from 'semantic-ui-react';
import config from '../../../../constants/config';
import MoreDetail from '../../../../components/moreDetail/MoreDetail';
import { pushReferrer } from '../../../../redux/actions';
import Widget from '../Widget';
import Localize from '../../../../constants/i18n-utils';
import TakeTourBackgroundImage from '../../../../assets/take-tour-background.svg';
import videoPlayerImage from '../../../../assets/video-player.svg';
import './styles.css';
import permissions from '../../../../constants/permissions';
import { userHasPermission } from '../../../../constants/utils';

const { GETTING_STARTED, DASHBOARD, UNAUTHORIZED } = config.ROUTE_URLS;
const { TAKE_A_TOUR } = permissions;

class GettingStartedWidget extends PureComponent {
    gotoGettingStartedPage = async () => {
        const { history, setReferrer } = this.props;
        if (!userHasPermission(TAKE_A_TOUR)) {
            history.push(UNAUTHORIZED);
        } else {
            history.push(GETTING_STARTED);
            setReferrer({ page: 'Dashboard', path: DASHBOARD });
        }
    };
    render() {
        return (
            <Widget
                footer={<MoreDetail onClick={this.gotoGettingStartedPage} />}
            >
                <div
                    onClick={this.gotoGettingStartedPage}
                    className="getting-started-content"
                    style={{
                        backgroundImage: `url(${TakeTourBackgroundImage})`,
                    }}
                >
                    <div className="getting-started-info-wrapper">
                        <Image src={videoPlayerImage} alt="Take A Tour" />
                        <div className="text-wrapper">
                            <h6 className="header">
                                {Localize.text(
                                    'widget.tour.header',
                                    'Take the video tours'
                                )}
                            </h6>
                            <span className="text">
                                {Localize.text(
                                    'widget.tour.text',
                                    'Learn how to place orders, review orders, shipments and invoices'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </Widget>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReferrer: (ref) => dispatch(pushReferrer(ref)),
    };
};

export default withRouter(
    connect(null, mapDispatchToProps)(GettingStartedWidget)
);
