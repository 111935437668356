import { ReportsApi } from '../../../services/reports-service';
import { GamesApi } from '../../../services/games-service';
import { compact, find, forEach, map } from 'lodash';

export const INVOICE_DETAIL_LOADING = 'INVOICE_DETAIL_LOADING';
export const INVOICE_DETAIL_LOADING_DONE = 'INVOICE_DETAIL_LOADING_DONE';
export const INVOICE_DETAIL_INIT = 'INVOICE_DETAIL_INIT';
export const INVOICE_DETAIL_GET = 'INVOICE_DETAIL_GET';

const reportsApi = new ReportsApi();
const gamesApi = new GamesApi();

export const getInvoiceDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: INVOICE_DETAIL_LOADING });

        try {
            const payload = await reportsApi.getInvoice(id);

            const itemCodes = compact(map(payload.lineItems, 'itemCode'));
            const items = await gamesApi.getGameDetailsByGameNumber(itemCodes);

            forEach(payload.lineItems, (lineItem) => {
                const item = find(items, ['reference', lineItem.itemCode]);

                const itemFound = !!item;

                lineItem.itemPrice = itemFound ? item.price : 'N/A';
                lineItem.itemTicketsPerBook = itemFound
                    ? item.ticketsPerBook
                    : 0;
            });

            dispatch({ type: INVOICE_DETAIL_GET, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: INVOICE_DETAIL_LOADING_DONE });
    };
};

export const initInvoiceDetail = () => ({
    type: INVOICE_DETAIL_INIT,
});
