import React, { Component } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { PLabel } from '.';
import './forms.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const DEFAULT_PAGE_SIZE = 10;

class PAsyncDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            filters: [],
            size: DEFAULT_PAGE_SIZE,
            page: 0,
            sortColumn: 'id',
            sortOrder: 'ASC',
            sortColumns: [], // Additional sort columns if needed. Syntax: {direction: <ASC | DESC>, property: <columnName>}
            query: '',
        };
    }

    async componentDidMount() {
        const { filters, sortOrder, sortColumn, sortColumns, query } =
            this.props.requestParams;
        await this.setState({
            filters: filters || [],
            size: DEFAULT_PAGE_SIZE,
            page: 0,
            sortOrder: sortOrder || 'ASC',
            sortColumn: sortColumn || 'id',
            sortColumns: sortColumns,
            query: query || '',
        });

        await this.getOptions();
    }

    getOptions = async () => {
        const {
            options,
            filters,
            size,
            page,
            sortOrder,
            sortColumn,
            sortColumns,
            query,
        } = this.state;
        const {
            input: { value },
        } = this.props;
        const response = await this.props.getOptions({
            filters,
            size,
            page: page,
            sortColumn,
            sortOrder,
            sortColumns,
            query,
        });
        if (response && !_.isEmpty(response)) {
            this.setState({
                options: [...options, ...response.content],
                size: response.size,
                page: response.number + 1,
                sortColumn,
                query,
            });
        }
        const match = _.find(options, { id: value });
        if (!!value) {
            if (!match) {
                await this.getOptions();
            }
        }
    };

    handleOpen = async (e, data) => {
        const { options } = this.state;
        _.isEmpty(options) && (await this.getOptions());
    };

    onSearchChange = (query) => {
        clearTimeout(this.asyncTimer);
        this.setState({ query, page: 0, options: [] });
        this.asyncTimer = setTimeout(this.getOptions, 500);
    };

    componentDidUpdate() {
        let selectMenuList = ReactDOM.findDOMNode(
            this.asyncSelect
        ).getElementsByClassName('Select-menu');

        if (selectMenuList.length > 0) {
            const selectMenu = selectMenuList[0];
            const reactSelectMenu = ReactDOM.findDOMNode(selectMenu);

            reactSelectMenu.addEventListener(
                'scroll',
                this.onMenuScrollToBottom
            );
        }
    }

    onMenuScrollToBottom = async (event) => {
        const { target } = event;

        if (
            target.scrollHeight > target.offsetHeight &&
            target.scrollHeight - target.offsetHeight - target.scrollTop < 1
        ) {
            await this.getOptions();
        }
    };

    render() {
        const {
            input: { value, ...input },
            meta: { touched, warning, error },
            label,
            style,
            search,
            multiple = false,
            textField = 'text',
            valueField = 'value',
            textFormatter,
            allowBlank = false,
            blankText = '',
            helpText,
            ...otherProps
        } = this.props;

        const { options = [] } = this.state;

        const optionsList = options.map((item) => ({
            text: textFormatter
                ? textFormatter(item[textField])
                : item[textField],
            key: item[valueField],
            value: item[valueField],
        }));

        if (allowBlank && !multiple) {
            optionsList.unshift({
                text: blankText,
                key: '--BLANK--',
                value: null,
            });
        }

        const getValue = () => {
            let selectedValue = null;

            // Single selection
            if (!multiple) {
                if (value && value.id) {
                    selectedValue = value.id;
                } else if (Array.isArray(value)) {
                    // Value is contained in an array, only grab the first value
                    selectedValue = value[0];

                    if (typeof selectedValue === 'object') {
                        selectedValue = selectedValue[valueField];
                    }
                } else if (typeof value === 'object') {
                    // Value is contained in a key-value pair object
                    selectedValue = value[valueField];
                } else if (value) {
                    selectedValue = value;
                }
            } else {
                selectedValue = [];
                if (value && value.split && value.length) {
                    selectedValue = value.split(',');
                } else if (Array.isArray(value)) {
                    _.forEach(value, (valueElement) => {
                        if (typeof valueElement === 'object') {
                            selectedValue.push(valueElement[valueField]);
                        } else {
                            selectedValue.push(valueElement);
                        }
                    });
                } else if (value) {
                    selectedValue = value;
                }
            }

            return selectedValue;
        };

        const selectedValues = getValue();

        const exclusionProps = ['getOptions', 'requestParams'];
        const dropDownProps = _.pickBy(
            otherProps,
            (item, keyVal) => !_.includes(exclusionProps, keyVal)
        );

        return (
            <div className="field select">
                <PLabel text={label} />
                <Form.Field>
                    <Dropdown
                        ref={(select) => (this.asyncSelect = select)}
                        selection
                        multiple={multiple}
                        fluid
                        {...dropDownProps}
                        value={selectedValues}
                        onChange={(event, data) => {
                            input.onChange(data.value);
                        }}
                        search={search || true}
                        onSearchChange={(e, { searchQuery }) =>
                            this.onSearchChange(searchQuery)
                        }
                        style={style}
                        options={optionsList}
                        onOpen={this.handleOpen}
                        onScroll={this.onMenuScrollToBottom}
                    />
                    {helpText && (
                        <div className="caption-text secondary-color">
                            <i className="icon-Info" /> {helpText}
                        </div>
                    )}
                    {touched &&
                        ((error && (
                            <div className="error-text">
                                <i>{error}</i>
                            </div>
                        )) ||
                            (warning && (
                                <div className="warning-text">
                                    <i>{warning}</i>
                                </div>
                            )))}
                </Form.Field>
            </div>
        );
    }
}

PAsyncDropdown.propTypes = {
    getOptions: PropTypes.func.isRequired,
    requestParams: PropTypes.object,
};

export default PAsyncDropdown;
