import React from 'react';
import PropTypes from 'prop-types';
import { Form, Transition } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import config from '../../constants/config';
import './styles.css';

const animationLen = config.ANIMATION_LENGTH;

export default function MaskInputFormField({
    input,
    meta: { touched, error, warning },
    ...props
}) {
    return (
        <Form.Field>
            <InputMask
                {...props}
                {...input}
                className={classnames('mask-input-form-field', {
                    invalid: touched && !!error,
                })}
            />
            <Transition
                visible={touched && !!error}
                animation="fade"
                duration={animationLen}
            >
                <span className="error-text">
                    <i>{error}</i>
                </span>
            </Transition>
            <Transition
                visible={touched && !!warning}
                animation="fade"
                duration={animationLen}
            >
                <span className="warning-text">
                    <i>{warning}</i>
                </span>
            </Transition>
        </Form.Field>
    );
}

MaskInputFormField.propTypes = {
    as: PropTypes.any,
    input: PropTypes.object,
    maskPattern: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
};
