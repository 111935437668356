import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import { setIsFormDirty } from '../../redux/actions';
import './forms.css';

export const FORM_MODE = {
    CREATE: 'CREATE',
    VIEW: 'VIEW',
    UPDATE: 'UPDATE',
};

class PollardForm extends Component {
    componentWillReceiveProps(newProps) {
        const {
            dirty = false,
            reduxCarouselFormDirty,
            setIsFormDirty,
        } = newProps;
        if (reduxCarouselFormDirty !== dirty) {
            setIsFormDirty(dirty);
        }
    }

    componentWillUnmount() {
        this.props.setIsFormDirty(false);
    }

    handleSubmit = () => {
        const { onSubmit, setIsFormDirty } = this.props;
        setIsFormDirty(false);
        onSubmit();
    };

    handleCancel = () => {
        const { onCancel, setIsFormDirty } = this.props;
        setIsFormDirty(false);
        onCancel();
    };

    handleDelete = () => {
        const { onDelete, setIsFormDirty } = this.props;
        setIsFormDirty(false);
        onDelete();
    };

    render() {
        const {
            className,
            onSubmit,
            onCancel,
            onDelete,
            processing,
            submitText = 'Submit',
            cancelText = 'Cancel',
            deleteText = 'Delete',
            loading,
            children,
            submitDisabled = false,
            formContentStyle,
            showEditButton = true,
        } = this.props;

        return (
            <Form
                className={`vertical-form p-form${
                    className ? ` ${className}` : ''
                }`}
                loading={loading || processing}
            >
                <div
                    className="form-content"
                    style={{ padding: '20px', ...formContentStyle }}
                >
                    {children}
                </div>

                <div className="form-footer" style={{ textAlign: 'right' }}>
                    {onDelete && (
                        <Button
                            type="button"
                            className="delete-btn transparent-btn"
                            onClick={this.handleDelete}
                        >
                            {deleteText}
                        </Button>
                    )}
                    {onCancel && (
                        <Button
                            type="button"
                            className="transparent-btn"
                            onClick={this.handleCancel}
                        >
                            {cancelText}
                        </Button>
                    )}
                    {onSubmit && showEditButton && (
                        <Button
                            type="button"
                            primary
                            disabled={submitDisabled}
                            onClick={this.handleSubmit}
                        >
                            {submitText}
                        </Button>
                    )}
                </div>
            </Form>
        );
    }
}

const mapStateToProps = ({ formsMetadata }) => ({
    reduxCarouselFormDirty: formsMetadata.isFormDirty,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setIsFormDirty: (val) => dispatch(setIsFormDirty(val)),
    };
};

export const PForm = connect(mapStateToProps, mapDispatchToProps)(PollardForm);
