import {
    SERVICE_CALL_INIT,
    SERVICE_CALL_LOADING,
    SERVICE_CALL,
    SERVICE_CALL_LIST_LOADING,
    SERVICE_CALL_LIST,
} from '../../../../actions/retailerDashboard/widgets/serviceCall/serviceCall';

const INITIAL_STATE = {
    serviceCallData: [],
    serviceCallList: {},
    loading: false,
    totalElements: 0,
    totalPages: 0,
    size: 10,
    number: 0,
};

const serviceCall = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SERVICE_CALL_INIT:
            return { ...INITIAL_STATE };
        case SERVICE_CALL_LOADING:
            return { ...state, loading: true };
        case SERVICE_CALL_LIST_LOADING:
            return { ...state, loading: true };
        case SERVICE_CALL:
            return {
                ...state,
                serviceCallData: [...action.serviceCallData],
                loading: false,
            };
        case SERVICE_CALL_LIST:
            return {
                ...state,
                serviceCallList: { ...action.serviceCallList },
                loading: false,
            };
        default:
            return state;
    }
};
export default serviceCall;
