import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import permissions from '../../constants/permissions';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearPlanograms,
    getPlanogramPage,
} from '../../redux/actions/planogram/planogram-actions';
import type { Planogram } from '../../DTOs/planogram';
import Table from '../../components/datatable/Table';
import config from '../../constants/config';
import type {
    Query,
    SearchSpecificationModel,
    Sort,
} from '../../interface/search-specification-model';
import { clearSortDetails } from '../../components/datatable/actions';
import { formatToDisplayDate } from '../../constants/utils';

const { PLANOGRAM_UPDATE, PLANOGRAM_CREATE } = config.ROUTE_URLS;
const PlanogramListPage = ({ history }) => {
    const dispatch = useDispatch();
    const [searchQuery: SearchSpecificationModel, setSearchQuery] = useState({
        page: 0,
        size: 15,
        sort: [
            {
                direction: 'DESC',
                property: 'modifiedDate',
            },
        ],
        query: undefined,
    });
    useEffect(() => {
        return () => {
            dispatch(clearSortDetails());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPlanogramPage(searchQuery));
        return () => {
            dispatch(clearPlanograms());
        };
    }, [searchQuery, dispatch]);
    const planogramPage: Page<Planogram> = useSelector(
        (state) => state.planogram.planogramPage
    );

    const isLoading: Page<Planogram> = useSelector(
        (state) => state.planogram.isLoading
    );
    const handleSelectTablePage = ({
        size,
        page,
        sortOrder,
        sortColumn,
        query,
    }) => {
        searchData(size, page, sortOrder, sortColumn, query);
    };

    const searchData = (size, page, sortOrder, sortColumn, query) => {
        const queryObject: Query = query
            ? [
                  {
                      operator: 'or',
                      value: [
                          {
                              value: `%${query}%`,
                              operator: 'like',
                              property: 'name',
                          },
                          {
                              value: `%${query}%`,
                              operator: 'like',
                              property: 'retailerGroup.name',
                          },
                      ],
                  },
              ]
            : undefined;

        const sort: Sort[] =
            sortOrder && sortColumn
                ? [
                      {
                          direction: sortOrder.toUpperCase(),
                          property: sortColumn,
                      },
                  ]
                : [
                      {
                          direction: 'DESC',
                          property: 'modifiedDate',
                      },
                  ];

        setSearchQuery({
            page: page < 1 ? 0 : page - 1,
            size,
            sort: sort,
            query: queryObject,
        });
    };

    const handleSearch = (query) => {
        searchData(
            planogramPage?.size,
            0,
            planogramPage?.sort ? [0].direction : undefined,
            planogramPage?.sort ? [0].property : undefined,
            query
        );
    };
    return (
        <Page
            name="PlanogramListPage"
            title="Planograms"
            data-test="PlanogramListPage"
            addButtonRoles={[permissions.ROLE_PLANOGRAM_CREATE]}
            onAddButtonClick={() => {
                history.push(PLANOGRAM_CREATE);
            }}
        >
            <Container className={'data-section'}>
                <Table
                    pagination
                    contentKey="id"
                    loading={isLoading}
                    content={planogramPage?.content}
                    page={planogramPage?.number}
                    size={planogramPage?.size}
                    totalElements={planogramPage?.totalElements}
                    totalPages={planogramPage?.totalPages}
                    noDataMessage={'There are no Planograms.'}
                    selector={{
                        variables: ['id'],
                        path: PLANOGRAM_UPDATE,
                        callback: (p) => history.push(p),
                    }}
                    columns={[
                        {
                            name: 'name',
                            title: 'Name',
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'description',
                            title: 'Description',
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'startDate',
                            title: 'Start Date',
                            sortable: true,
                            searchable: true,
                            formatter: formatToDisplayDate,
                        },
                        {
                            name: 'endDate',
                            title: 'End Date',
                            sortable: true,
                            searchable: true,
                            formatter: formatToDisplayDate,
                        },
                        {
                            name: 'retailerGroup.name',
                            title: 'Group Name',
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'isActive',
                            title: 'Status',
                            sortable: true,
                            searchable: true,
                            formatter: (val) => {
                                return val ? 'ACTIVE' : 'INACTIVE';
                            },
                        },
                    ]}
                    fetchData={handleSelectTablePage}
                    onSearch={handleSearch}
                />
            </Container>
        </Page>
    );
};
export default PlanogramListPage;
