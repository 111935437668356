import { OrganizationApi } from '../../services/organization-service';
import { error } from './notifications';
import { getErrorMessage } from '../../constants/utils';

export const ORGANIZATION_LOADING = 'ORGANIZATION_LOADING';
export const ORGANIZATION_LOADING_DONE = 'ORGANIZATION_LOADING_DONE';
export const ORGANIZATION_SETUP = 'ORGANIZATION_SETUP';

export const ORGANIZATION_UPDATE_LOADING = 'ORGANIZATION_UPDATE_LOADING';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_DONE = 'ORGANIZATION_UPDATE_DONE';
export const ORGANIZATION_UPDATE_INIT = 'ORGANIZATION_UPDATE_INIT';

export const ORGANIZATION_CONFIG_SELECTED = 'ORGANIZATION_CONFIG_SELECTED';

export const LOGO_UPLOAD_DONE = 'LOGO_UPLOAD_DONE';
export const LOGO_UPLOAD_SUCCESS = 'LOGO_UPLOAD_SUCCESS';
export const LOGO_UPLOAD_PROCESSING = 'LOGO_UPLOAD_PROCESSING';
export const LOGO_UPLOAD_INIT = 'LOGO_UPLOAD_INIT';

export const LOGO_KEY_SUCCESS = 'LOGO_KEY_SUCCESS';
export const ORGANIZATION_DEFAULT_LANG = 'ORGANIZATION_DEFAULT_LANG';

export const FAQ_FILE_UPLOAD_INIT = 'FAQ_FILE_UPLOAD_INIT';
export const FAQ_FILE_UPLOAD_PROCESSING = 'FAQ_FILE_UPLOAD_PROCESSING';
export const FAQ_FILE_UPLOAD_SUCCESS = 'FAQ_FILE_UPLOAD_SUCCESS';
export const FAQ_FILE_UPLOAD_DONE = 'FAQ_FILE_UPLOAD_DONE';
export const FAQ_FILE_DOWNLOAD_SUCCESS = 'FAQ_FILE_DOWNLOAD_SUCCESS';

export const PRIVACY_POLICY_FILE_UPLOAD_INIT =
    'PRIVACY_POLICY_FILE_UPLOAD_INIT';
export const PRIVACY_POLICY_FILE_UPLOAD_PROCESSING =
    'PRIVACY_POLICY_FILE_UPLOAD_PROCESSING';
export const PRIVACY_POLICY_FILE_UPLOAD_SUCCESS =
    'PRIVACY_POLICY_FILE_UPLOAD_SUCCESS';
export const PRIVACY_POLICY_FILE_UPLOAD_DONE =
    'PRIVACY_POLICY_FILE_UPLOAD_DONE';
export const PRIVACY_POLICY_FILE_DOWNLOAD_SUCCESS =
    'PRIVACY_POLICY_FILE_DOWNLOAD_SUCCESS';

const organizationApi = new OrganizationApi();

const organizationError = (e) => {
    return async (dispatch) => {
        dispatch(error({ msg: getErrorMessage(e), target: 'Dashboard' }));
    };
};

const configManagementFailure = (e) => {
    return async (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(e),
                target: 'UpdateConfigurationManagement',
            })
        );
    };
};

export const setupOrganizationInfo = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ORGANIZATION_LOADING });

            const config = await organizationApi.getSetupDetails();

            dispatch({
                type: ORGANIZATION_SETUP,
                config,
            });
        } catch (err) {
            console.error(err);
            organizationError(err);
        } finally {
            dispatch({ type: ORGANIZATION_LOADING_DONE });
        }
    };
};

export const getLogoKey = () => {
    return async (dispatch) => {
        try {
            const logoKey = await organizationApi.getLogoKey();

            dispatch({
                type: LOGO_KEY_SUCCESS,
                logoKey,
            });
        } catch (err) {
            console.error(err);
            organizationError(err);
        }
    };
};

export const updateConfigurationByKeyValue = (config) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: ORGANIZATION_UPDATE_LOADING });

            await organizationApi.updateConfigurationByConfigName({
                key: config.name,
                value: config.value,
            });
            dispatch({
                type: ORGANIZATION_UPDATE_SUCCESS,
            });
        } catch (err) {
            console.error(err);
            dispatch(configManagementFailure(err));
            success = false;
        } finally {
            dispatch({ type: ORGANIZATION_UPDATE_DONE });
        }

        return success;
    };
};

export const onSelectedConfig = (config) => ({
    type: ORGANIZATION_CONFIG_SELECTED,
    config,
});

export const logoUpload = (logoUploadForm) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: LOGO_UPLOAD_PROCESSING });
        try {
            const logo = await organizationApi.logoUpload(logoUploadForm);
            dispatch({
                type: LOGO_UPLOAD_SUCCESS,
                logo,
            });
        } catch (e) {
            success = false;
            console.error(e);
            const errorMsg = getErrorMessage(e);
            const errorTimeOutMsg =
                'Upload could not be completed due to slow network and large file size. Please try to upload a smaller file.';
            dispatch(
                configManagementFailure(
                    errorMsg.includes('timeout') ? errorTimeOutMsg : errorMsg
                )
            );
        } finally {
            dispatch({ type: LOGO_UPLOAD_DONE });
        }

        return success;
    };
};

export const portalFileUpload = (fileUploadForm) => {
    return async (dispatch) => {
        let success = true;
        dispatch({ type: PRIVACY_POLICY_FILE_UPLOAD_PROCESSING });
        try {
            const logo = await organizationApi.portalFileUpload(fileUploadForm);
            dispatch({
                type: PRIVACY_POLICY_FILE_UPLOAD_SUCCESS,
                logo,
            });
        } catch (e) {
            success = false;
            console.error(e);
            const errorMsg = getErrorMessage(e);
            const errorTimeOutMsg =
                'Upload could not be completed due to slow network and large file size. Please try to upload a smaller file.';
            dispatch(
                configManagementFailure(
                    errorMsg.includes('timeout') ? errorTimeOutMsg : errorMsg
                )
            );
        } finally {
            dispatch({ type: PRIVACY_POLICY_FILE_UPLOAD_DONE });
        }

        return success;
    };
};

export const initLogoUpload = () => {
    return async (dispatch) => {
        dispatch({ type: LOGO_UPLOAD_INIT });
    };
};

export const initPortalFileUpload = () => {
    return async (dispatch) => {
        dispatch({ type: PRIVACY_POLICY_FILE_UPLOAD_INIT });
    };
};

export const initConfig = () => {
    return async (dispatch) => {
        dispatch({ type: ORGANIZATION_UPDATE_LOADING });
        dispatch({
            type: ORGANIZATION_UPDATE_INIT,
            organizationDataMap: false,
        });
        dispatch({ type: ORGANIZATION_UPDATE_DONE });
    };
};

export const getDefaultLanguage = () => {
    return async (dispatch) => {
        try {
            const language = await organizationApi.getDefaultLanguage();
            dispatch({
                type: ORGANIZATION_DEFAULT_LANG,
                language,
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const downloadFaqFile = () => {
    return async (dispatch) => {
        try {
            const file = await organizationApi.downloadFaqFile();

            dispatch({
                type: FAQ_FILE_DOWNLOAD_SUCCESS,
                file,
            });
        } catch (err) {
            console.error(err);
            organizationError(err);
        }
    };
};

export const downloadPrivacyFile = () => {
    return async (dispatch) => {
        try {
            const file = await organizationApi.downloadPrivacyFile();

            dispatch({
                type: FAQ_FILE_DOWNLOAD_SUCCESS,
                file,
            });
        } catch (err) {
            console.error(err);
            organizationError(err);
        }
    };
};
