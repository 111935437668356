import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

const WAIT_INTERVAL = 1000 * 2; //wait 2 seconds between keystroke
const ENTER_KEY = 13;

class SearchField extends Component {
    componentWillMount() {
        this.timer = null;
    }

    handleChange = (e) => {
        clearTimeout(this.timer);

        this.props.onSearchValueChange(e.target.value);

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    };

    handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            document.activeElement.blur();
            clearTimeout(this.timer);
            this.triggerChange();
        }
    };

    triggerChange = () => {
        const { value } = this.props;

        this.props.onChange(value);
    };

    render() {
        const { className, placeholder, style, icon } = this.props;

        return (
            <Input
                className={className}
                style={style}
                icon={icon}
                placeholder={placeholder}
                value={this.props.value}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
            />
        );
    }
}

export default SearchField;
