import {
    RNP__SET_PERMISSIONS,
    RNP__SET_EDIT_PERMISSIONS,
    RNP__SET_ROLES,
    RNP__SET_ACTIVE_ROLES,
    RNP__LOADING,
    RNP__LOADED,
    RNP__SET_FILTER_PERMISSIONS,
} from '../../actions/privileges/types';

const INITIAL_STATE = {
    permissionsLoading: false,
    privilegesList: [],
    filteredPrivileges: [],
    editPermissions: false,
    rolesList: [],
    tempRoles: [],
};

const privileges = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RNP__LOADING:
            return {
                ...state,
                permissionsLoading: true,
            };
        case RNP__LOADED:
            return {
                ...state,
                permissionsLoading: false,
            };
        case RNP__SET_PERMISSIONS:
            return {
                ...state,
                privilegesList: action.payload,
                filteredPrivileges: action.payload,
            };
        case RNP__SET_EDIT_PERMISSIONS:
            return {
                ...state,
                editPermissions: action.payload,
            };
        case RNP__SET_FILTER_PERMISSIONS:
            return {
                ...state,
                filteredPrivileges: action.payload,
            };
        case RNP__SET_ROLES:
            return {
                ...state,
                rolesList: action.payload,
            };
        case RNP__SET_ACTIVE_ROLES:
            return {
                ...state,
                tempRoles: action.payload,
            };
        default:
            return state;
    }
};
export default privileges;
