import {
    PLANOGRAMGROUP_UPDATE_SUCCESS,
    UPDATE_PLANOGRAMGROUP_INIT,
    UPDATE_PLANOGRAMGROUP_GET_SUCCESS,
    UPDATE_PLANOGRAM_LOADING,
    UPDATE_PLANOGRAM_LOADING_DONE,
} from '../../actions';

const INIT_PLANOGRAMGROUP_CREATE = {
    id: '',
    name: '',
    description: '',
    facings: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    planogramToUpdate: { ...INIT_PLANOGRAMGROUP_CREATE },
};

const updatePlanogram = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PLANOGRAM_LOADING:
            return { ...state, loading: true };
        case UPDATE_PLANOGRAM_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case UPDATE_PLANOGRAMGROUP_GET_SUCCESS:
            return {
                ...state,
                planogramToUpdate: action.planogram,
                processing: false,
                loading: false,
            };
        case UPDATE_PLANOGRAMGROUP_INIT:
            return {
                ...INITIAL_STATE,
                planogramToUpdate: { ...INIT_PLANOGRAMGROUP_CREATE },
            };
        case PLANOGRAMGROUP_UPDATE_SUCCESS:
            return {
                ...state,
                planogramToUpdate: action.planogram,
                processing: false,
            };
        default:
            return state;
    }
};
export default updatePlanogram;
