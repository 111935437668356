import {
    INVOICE_DETAIL_LOADING,
    INVOICE_DETAIL_LOADING_DONE,
    INVOICE_DETAIL_INIT,
    INVOICE_DETAIL_GET,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    invoice: {},
};

const invoiceDetail = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case INVOICE_DETAIL_GET:
            return {
                ...state,
                invoice: payload,
            };

        case INVOICE_DETAIL_INIT:
            return { ...INITIAL_STATE };

        case INVOICE_DETAIL_LOADING:
            return {
                ...state,
                loading: true,
            };

        case INVOICE_DETAIL_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default invoiceDetail;
