import {
    CREATE_SALES_ROUTE_PROCESSING,
    CREATE_SALES_ROUTE_LOADING,
    CREATE_SALES_ROUTE_LOADING_DONE,
    SALES_ROUTE_CREATE_INIT,
    CREATE_SALES_ROUTE_CREATE_SUCCESS,
    FETCH_SALES_ROUTE_SUCCESS,
    CREATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS,
} from '../../actions';

const INIT_SALES_ROUTE_CREATE = {
    id: '',
    name: '',
    description: '',
    status: 'INACTIVE',
    retailerIds: [],
    salesManager: '',
    salesRep: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    salesRouteToCreate: { ...INIT_SALES_ROUTE_CREATE },
    retailersInfo: [],
    createSalesRouteOptions: { salesManagers: [], salesReps: [] },
    salesRouteSuccess: false,
};

const createSalesRoute = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_SALES_ROUTE_LOADING:
            return { ...state, loading: true };
        case CREATE_SALES_ROUTE_LOADING_DONE:
            return { ...state, loading: false, processing: false };
        case CREATE_SALES_ROUTE_PROCESSING:
            return { ...state, processing: true };
        case CREATE_SALES_ROUTE_CREATE_SUCCESS:
            return {
                ...state,
                salesRouteSuccess: true,
                processing: false,
                loading: false,
            };
        case SALES_ROUTE_CREATE_INIT:
            return {
                ...INITIAL_STATE,
                salesRouteToCreate: {
                    ...INIT_SALES_ROUTE_CREATE,
                    salesManager: action.currUser,
                },
                createSalesRouteOptions: {
                    salesManagers: action.salesManagers,
                    salesReps: action.salesReps,
                },
            };

        case FETCH_SALES_ROUTE_SUCCESS:
            return {
                ...state,
                createSalesRouteOptions: {
                    salesManagers: action.salesManagers,
                    salesReps: action.salesReps,
                },
            };
        case CREATE_SALES_ROUTE_GET_RETAILER_DATA_SUCCESS:
            return { ...state, retailersInfo: action.info };
        default:
            return state;
    }
};
export default createSalesRoute;
