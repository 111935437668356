import React, { Component } from 'react';
import { map } from 'lodash';

import './styles.css';

class ContactDetails extends Component {
    render() {
        const { contact } = this.props;

        return (
            <div>
                {map(contact, ({ value, label }) => {
                    if (!value) return;

                    return (
                        <div className="contact-body" key={label}>
                            {
                                <span>
                                    <div className="contact">{label}</div>
                                    <div className="contact-value">
                                        {value || ''}
                                    </div>
                                </span>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ContactDetails;
