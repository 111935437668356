const shortCutKeys = {
    dashboard: 'Alt + D',
    games: 'Alt + G',
    orders: 'Alt + O',
    invoices: 'Alt + I',
    reports: 'Alt + R',
    faq: 'Alt + F',
    contactUs: 'Alt + T',
    account: 'Alt + U - User Settings',
    cart: 'Alt + C',
    planogram: 'ALT + L',
    gettingStarted: 'ALT + W',
    returns: 'ALT + B',
    changeRetailer: ' Alt + Q',
};

export default shortCutKeys;
