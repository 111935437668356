import { ZonesApi } from '../../../services/zones-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const ZONE_LOADING = 'ZONE_LOADING';
export const ZONE_LOADING_DONE = 'ZONE_LOADING_DONE';
export const ZONE_INFO_UPDATE = 'ZONE_INFO_UPDATE';
export const INIT_ZONE_INFO = 'INIT_ZONE_INFO';
export const ZONE_RETAILER_LIST_LOADING = 'ZONE_RETAILER_LIST_LOADING';
export const ZONE_RETAILER_LIST = 'ZONE_RETAILER_LIST';

const zonesApi = new ZonesApi();

const errorHandler = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'ReadZones' }));
    };
};

export const getZoneById = (retailerId) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_LOADING });

        try {
            const data = await zonesApi.getZoneById(retailerId);
            dispatch({
                type: ZONE_INFO_UPDATE,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch(errorHandler(e));
        }
    };
};

export const getZonesByRetailerIds = (retailerId) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_RETAILER_LIST_LOADING });

        try {
            const zoneList = await zonesApi.getZonesByRetailerIds(retailerId);
            dispatch({
                type: ZONE_RETAILER_LIST,
                zoneList,
            });
        } catch (e) {
            console.error(e);
            dispatch(errorHandler(e));
        }
    };
};

export const updateZone = (zoneToUpdate) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_LOADING });
        let success = true;

        try {
            await zonesApi.updateZone(zoneToUpdate);
        } catch (e) {
            success = false;
            console.error(e);
            dispatch(errorHandler(e));
        } finally {
            dispatch({ type: ZONE_LOADING_DONE });
        }

        return success;
    };
};

export const initZoneData = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_ZONE_INFO });
    };
};
