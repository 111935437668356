import { ReportsApi } from '../../../../../services/reports-service';
import { GamesApi } from '../../../../../services/games-service';
import { MediaApi } from '../../../../../services/media-service';
import { OrganizationApi } from '../../../../../services/organization-service';
import { RetailersApi } from '../../../../../services/retailers-service';
import { UserApi } from '../../../../../services/user-service';

export const SERVER_TIME_INIT = 'SERVER_TIME_INIT';
export const SERVER_TIME = 'SERVER_TIME';
export const SERVER_TIME_LOADING = 'SERVER_TIME_LOADING';

const gameApi = new GamesApi();
const mediaApi = new MediaApi();
const organizationApi = new OrganizationApi();
const retailerApi = new RetailersApi();
const reportsApi = new ReportsApi();
const userApi = new UserApi();

export const initServerTime = () => {
    return async (dispatch) => {
        dispatch({ type: SERVER_TIME_INIT });
    };
};

const setServerTimeLoading = (isLoading) => ({
    type: SERVER_TIME_LOADING,
    payload: isLoading,
});

const setServerTime = (serviceName, time) => ({
    type: SERVER_TIME,
    payload: {
        serviceName: serviceName,
        value: time,
    },
});

export const getServerTime = () => async (dispatch) => {
    dispatch(setServerTimeLoading(true));

    const gameServiceTime = dispatch(getGameServiceTime());
    const mediaServiceTime = dispatch(getMediaServiceTime());
    const organizationServiceTime = dispatch(getOrganizationServiceTime());
    const retailerServiceTime = dispatch(getRetailerServiceTime());
    const reportServiceTime = dispatch(getReportServiceTime());
    const userServiceTime = dispatch(getUserServiceTime());
    await Promise.all([
        gameServiceTime,
        mediaServiceTime,
        organizationServiceTime,
        retailerServiceTime,
        reportServiceTime,
        userServiceTime,
    ]);

    dispatch(setServerTimeLoading(false));
};

const getGameServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await gameApi.getServerTime();
        dispatch(setServerTime('Game', serverTime));
    } catch (err) {
        dispatch(setServerTime('Game', 'NOT AVAILABLE'));
    }
};
const getMediaServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await mediaApi.getServerTime();
        dispatch(setServerTime('Media', serverTime));
    } catch (err) {
        dispatch(setServerTime('Media', 'NOT AVAILABLE'));
    }
};
const getOrganizationServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await organizationApi.getServerTime();
        dispatch(setServerTime('Organization', serverTime));
    } catch (err) {
        dispatch(setServerTime('Organization', 'NOT AVAILABLE'));
    }
};
const getRetailerServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await retailerApi.getServerTime();
        dispatch(setServerTime('Retailer', serverTime));
    } catch (err) {
        dispatch(setServerTime('Retailer', 'NOT AVAILABLE'));
    }
};

const getReportServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await reportsApi.getServerTime();
        dispatch(setServerTime('Report', serverTime));
    } catch (err) {
        dispatch(setServerTime('Report', 'NOT AVAILABLE'));
    }
};
const getUserServiceTime = () => async (dispatch) => {
    try {
        const serverTime = await userApi.getServerTime();
        dispatch(setServerTime('User', serverTime));
    } catch (err) {
        dispatch(setServerTime('User', 'NOT AVAILABLE'));
    }
};
