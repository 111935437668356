import { Backend } from './backend';

class JurisdictionService {
    constructor() {
        this.path = '/retailers/jurisdiction';
    }

    get = async () => {
        try {
            return await Backend.send({
                method: 'get',
                url: `${this.path}`,
            });
        } catch (e) {
            throw e;
        }
    };
}

export default JurisdictionService;
