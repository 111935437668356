import {
    PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS,
    PENDING_RETURNS__VIEW_RETURN_UNEXPECTED_ITEMS,
    PENDING_RETURNS__VIEW_RETURN_SCANNED_ITEMS,
    PENDING_RETURNS__VIEW_RETURN_LOADED,
    PENDING_RETURNS__VIEW_RETURN_LOADING,
    PENDING_RETURNS__VIEW_RETURN_RESET,
    PENDING_RETURNS__VIEW_RETURN_RECEIVED,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    pendingReturn: undefined,
    expectedItems: [],
    unexpectedItems: [],
    scannedItems: [],
};

const viewReturn = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PENDING_RETURNS__VIEW_RETURN_LOADED:
            return {
                ...state,
                loading: false,
            };
        case PENDING_RETURNS__VIEW_RETURN_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PENDING_RETURNS__VIEW_RETURN_RECEIVED:
            return {
                ...state,
                pendingReturn: action.payload,
            };

        case PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS:
            return {
                ...state,
                expectedItems: action.payload,
            };

        case PENDING_RETURNS__VIEW_RETURN_UNEXPECTED_ITEMS:
            return {
                ...state,
                unexpectedItems: action.payload,
            };

        case PENDING_RETURNS__VIEW_RETURN_SCANNED_ITEMS:
            return {
                ...state,
                scannedItems: action.payload,
            };

        case PENDING_RETURNS__VIEW_RETURN_RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default viewReturn;
