import React from 'react';

const NoResult = () => {
    return (
        <div
            style={{
                width: '100%',
                marginTop: 80,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <span
                className="icon-Search-no-results secondary-color"
                style={{ fontSize: 90 }}
            >
                {' '}
            </span>
            <span
                style={{
                    color: '#fdc63b',
                    fontSize: 30,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                No Results!
            </span>
            <span style={{ marginBottom: 5 }}>
                We couldn't find the item you are searching for,
            </span>
            <span style={{ marginBottom: 30 }}>
                maybe a little spelling mistake?
            </span>
        </div>
    );
};

export default NoResult;
