import {
    GET_COURIER,
    UPDATE_LOADING_STATE,
    CourierActionTypes,
    CourierStateType,
    GET_COURIER_BY_SEARCH,
} from '../../type/courier/courier-type';

const initialState: CourierStateType = {
    isLoading: false,
    courierList: [],
    courierPage: undefined,
};
const courierReducer = (state = initialState, action: CourierActionTypes) => {
    switch (action.type) {
        case GET_COURIER:
            return {
                ...state,
                isLoading: false,
                courierList: action.payload,
            };
        case UPDATE_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        case GET_COURIER_BY_SEARCH:
            return {
                ...state,
                isLoading: false,
                courierPage: action.payload,
            };
        default:
            return state;
    }
};
export default courierReducer;
