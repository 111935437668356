import axios from 'axios';
import _ from 'lodash';

import Store from '../redux/store';
import { logout } from '../redux/actions';
import { getErrorMessage } from '../constants/utils';
import config from '../constants/config';

export const API_ERROR_HANDLE = 'API_ERROR_HANDLE';
export const API_AUTH_ERROR_HANDLE = 'API_AUTH_ERROR_HANDLE';
export const API_AUTH_WARNING_HANDLE = 'API_AUTH_WARNING_HANDLE';
export const API_ERROR_CLEAR = 'API_ERROR_CLEAR';

export const initErrorHandler = () => {
    Store.store.dispatch(clearError());

    axios.interceptors.response.use(
        (res) => res,
        (error) => {
            if (!error.response) {
                return Promise.reject(error);
            }

            const {
                response: { status },
            } = error;
            switch (status) {
                case 401: {
                    const { apiError } = Store.store.getState();
                    if (!config.COGNITO_ENABLED && !apiError.hasAuthError) {
                        Store.store.dispatch(logout());
                        Store.store.dispatch(handleAuthError(error));
                    }
                    break;
                }
                case 500: {
                    const req = error.response.request;
                    const url = req.responseURL;
                    if (!url.toLowerCase().includes('logo')) {
                        //ignore error thrown when logo fails
                        Store.store.dispatch(handleError(error));
                    }
                    break;
                }
                default:
                    Store.store.dispatch(handleError(error));
                    break;
            }
            return Promise.reject(error);
        }
    );
};

export const handleError = (error) => ({
    type: API_ERROR_HANDLE,
    error: getErrorMessage(error),
});

export const handleAuthError = () => ({
    type: API_AUTH_WARNING_HANDLE,
    warning:
        'For your security, you have been logged out due to inactivity. Please login again.',
});

export const clearError = () => ({
    type: API_ERROR_CLEAR,
});

const INITIAL_STATE = {
    errors: [],
    warnings: [],
    hasAuthError: false,
};

export const errorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case API_ERROR_CLEAR:
            return INITIAL_STATE;
        case API_AUTH_WARNING_HANDLE:
            return {
                warnings: [action.warning],
                hasAuthError: true,
            };
        case API_AUTH_ERROR_HANDLE:
            return {
                errors: [action.warning],
                hasAuthError: true,
            };
        case API_ERROR_HANDLE: {
            const found = _.find(state.errors, (e) => {
                if (
                    !e ||
                    !e.response ||
                    !action.error ||
                    !action.error.response
                ) {
                    return false;
                }

                return e.response.status === action.error.response.status;
            });

            if (found) {
                return {
                    errors: state.errors,
                    hasAuthError: false,
                };
            }

            return {
                errors: [...state.errors, action.error],
                hasAuthError: false,
            };
        }
        default:
            return state;
    }
};
