import React, { useEffect, useRef, useState } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

import './forms.css';
import _ from 'lodash';
import { PLabel } from './PLabel';

export const PSelect = ({
    input: { value, ...input },
    meta: { touched, warning, error },
    options = [],
    label,
    style,
    search = true,
    multiple = false,
    textField = 'text',
    valueField = 'value',
    allowBlank = false,
    blankText = '',
    helpText,
    onScrollToLast,
    ...otherProps
}) => {
    const dropDownElement = useRef(null);
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const dropDownRef = dropDownElement.current.ref;
        const dropDown = dropDownRef.current.querySelector('[role="listbox"]');
        if (dropDown && onScrollToLast) {
            dropDown.removeEventListener('scroll', handleScroll);
            try {
                dropDown.addEventListener('scroll', handleScroll);
            } catch (e) {
                console.log(e);
            }
        }
        return () => {
            if (dropDown & onScrollToLast) {
                dropDown.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        setIsFetching(false);
    }, [options]);

    useEffect(() => {
        if (isFetching && onScrollToLast) {
            onScrollToLast();
        }
    }, [isFetching]);

    const handleScroll = (event) => {
        const dropDown = event.currentTarget;
        if (
            dropDown.scrollTop + dropDown.offsetHeight >=
            dropDown.scrollHeight - 1
        ) {
            setIsFetching(true);
        }
    };
    const optionsList = options.map((item) => ({
        text: item[textField],
        key: item[valueField],
        value: item[valueField],
    }));

    if (allowBlank && !multiple) {
        optionsList.unshift({ text: blankText, key: '--BLANK--', value: null });
    }

    const getValue = () => {
        let selectedValue = null;

        // Single selection
        if (!multiple) {
            if (value && value.id) {
                selectedValue = value.id;
            } else if (Array.isArray(value)) {
                // Value is contained in an array, only grab the first value
                selectedValue = value[0];

                if (typeof selectedValue === 'object') {
                    selectedValue = selectedValue[valueField];
                }
            } else if (typeof value === 'object' && value !== null) {
                // Value is contained in a key-value pair object
                selectedValue = value[valueField];
            } else if (value) {
                selectedValue = value;
            }
        } else {
            selectedValue = [];
            if (value && value.split && value.length) {
                selectedValue = value.split(',');
            } else if (Array.isArray(value)) {
                _.forEach(value, (valueElement) => {
                    if (typeof valueElement === 'object') {
                        selectedValue.push(valueElement[valueField]);
                    } else {
                        selectedValue.push(valueElement);
                    }
                });
            } else if (value) {
                selectedValue = value;
            }
        }

        return selectedValue;
    };

    const selectedValues = getValue();

    return (
        <div className="field select">
            <PLabel text={label} />
            <Form.Field>
                <Dropdown
                    selection
                    multiple={multiple}
                    fluid
                    {...otherProps}
                    value={selectedValues}
                    onChange={(event, data) => {
                        input.onChange(data.value);
                    }}
                    search={search}
                    style={style}
                    options={optionsList}
                    ref={dropDownElement}
                />
                {helpText && (
                    <div className="caption-text secondary-color">
                        <i className="icon-Info" /> {helpText}
                    </div>
                )}
                {touched &&
                    ((error && (
                        <div className="error-text">
                            <i>{error}</i>
                        </div>
                    )) ||
                        (warning && (
                            <div className="warning-text">
                                <i>{warning}</i>
                            </div>
                        )))}
            </Form.Field>
        </div>
    );
};
