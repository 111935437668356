import { UserApi } from '../../../services/user-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { find } from 'lodash';

const userApi = new UserApi();

export const INIT_UPDATE_USER = 'INIT_UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPDATE_USER_LOADING_DONE = 'UPDATE_USER_LOADING_DONE';
export const GET_USER_TO_UPDATE_SUCCESS = 'GET_USER_TO_UPDATE_SUCCESS';

const updateUserError = (errors) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(errors), target: 'UpdateUser' }));
    };
};

export const updateUser = (fields) => {
    return async (dispatch, getState) => {
        const { userRoles, retailerSelect } = getState();

        let success = true;
        try {
            const retailer = find(retailerSelect.content, {
                reference: fields.retailer,
            });
            //format roles and retailer
            fields = {
                ...fields,
                roles: [find(userRoles.roleData, { name: fields.roles })],
                retailerName: retailer ? retailer.name : null,
                retailerReference: retailer ? retailer.reference : null,
            };

            dispatch({ type: UPDATE_USER_LOADING });
            await userApi.createUpdateUser(fields);

            dispatch({
                type: UPDATE_USER_SUCCESS,
                username: fields.username,
            });
        } catch (err) {
            success = false;
            dispatch(updateUserError(err));
        } finally {
            dispatch({ type: UPDATE_USER_LOADING_DONE });
        }
        return success;
    };
};

export const getUser = (username) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_USER_LOADING });
            const userData = await userApi.getUserByUsername(username);

            dispatch({
                type: GET_USER_TO_UPDATE_SUCCESS,
                userData,
            });
        } catch (err) {
            return dispatch(updateUserError(err));
        } finally {
            dispatch({ type: UPDATE_USER_LOADING_DONE });
        }
    };
};

export const initUpdateUser = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_UPDATE_USER });
    };
};
