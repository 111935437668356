import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import upload1 from '../../assets/upload1.png';
import deviceAlt from '../../assets/deviceAlt.jpg';
import DocumentWysiwygModal from './DocumentWysiwygModal';
import { getAllDocuments } from '../../redux/actions';
import { connect } from 'react-redux';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { DocumentsApi } from '../../services/document-upload-service';
const TOTAL_PER_PAGE = 14;

const documentsApi = new DocumentsApi();

class PortalFileUploadButton extends Component {
    state = {
        isModalOpen: false,
    };

    getUrl = async (value) => {
        const urlPath = value.mediaKey;
        //need to strip out the beginning '/' if exists
        const formattedUrlPath =
            urlPath[0] === '/' ? urlPath.substr(1) : urlPath;
        const fileUrl = await documentsApi.getPublicUrl(
            formattedUrlPath,
            formattedUrlPath
        );

        const link =
            '<a target="_parent" href="' +
            fileUrl +
            '">' +
            value.description +
            '';

        await this.insertLink(link);
    };

    insertLink = (link) => {
        const { editorState, onChange } = this.props;
        EditorState.moveFocusToEnd(editorState);
        let currentHTML = stateToHTML(editorState.getCurrentContent());
        currentHTML = currentHTML + link;
        let contentState = stateFromHTML(currentHTML);
        onChange(
            EditorState.push(editorState, contentState, 'insert-fragment')
        );
    };

    hideModal = () => {
        this.setState({ isModalOpen: false });
    };

    showModal = async () => {
        await this.initDocumentList();
        this.setState({ isModalOpen: true });
    };

    initDocumentList = async () => {
        await this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            sortColumn: 'modifiedBy',
            sortOrder: 'DESC',
            filters: [],
        });
    };

    handleSearch = (query) => {
        this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters: [],
            query,
        });
    };

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        this.props.fetchListOfDocuments({
            page: page <= 0 ? 0 : page - 1,
            size,
            sortOrder,
            sortColumn,
            filters: [],
            query,
        });
    };

    render() {
        const { loading, documentUpload } = this.props;
        return (
            <div>
                <DocumentWysiwygModal
                    isModalOpen={this.state.isModalOpen}
                    hideModal={this.hideModal}
                    documentUpload={documentUpload}
                    loading={loading}
                    handleSearch={this.handleSearch}
                    handleSelectTablePage={this.handleSelectTablePage}
                    getUrl={this.getUrl}
                />
                <div
                    style={{ textAlign: 'center' }}
                    onClick={this.showModal}
                    aria-haspopup="true"
                >
                    <img
                        className="modal-pic-style"
                        src={upload1}
                        alt={deviceAlt}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ documentUpload, loading }) => ({
    loading,
    documentUpload,
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListOfDocuments: (args) => dispatch(getAllDocuments(args)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortalFileUploadButton);
