import { RolePermissionApi } from '../../../services/role-permission-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

const rolePermissionApi = new RolePermissionApi();

export const INIT_CREATE_ROLE = 'INIT_CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_LOADING = 'CREATE_ROLE_LOADING';
export const CREATE_ROLE_LOADING_DONE = 'CREATE_ROLE_LOADING_DONE';

export const createRole = (fields) => {
    return async (dispatch, getState) => {
        let success = true;
        try {
            dispatch({ type: CREATE_ROLE_LOADING });
            const roleData = await rolePermissionApi.createRole(fields);

            dispatch({
                type: CREATE_ROLE_SUCCESS,
                roleData,
            });
        } catch (err) {
            success = false;
            dispatch(error({ msg: getErrorMessage(err) }));
        } finally {
            dispatch({ type: CREATE_ROLE_LOADING_DONE });
        }
        return success;
    };
};

export const initCreateRole = () => {
    return async (dispatch) => {
        dispatch({ type: INIT_CREATE_ROLE });
    };
};
