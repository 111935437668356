import { OrganizationApi } from '../../services/organization-service';

export const LOGO_LOADING = 'LOGO_LOADING';
export const LOGO_SETUP = 'LOGO_SETUP';
export const LOGO_LOADING_DONE = 'LOGO_LOADING_DONE';
export const REPORT_LOGO_SETUP = 'REPORT_LOGO_SETUP';

const organizationApi = new OrganizationApi();

export const setupLogo = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGO_LOADING });

            const appLogoFile = await organizationApi.downloadAppLogo();

            dispatch({
                type: LOGO_SETUP,
                appLogoFile,
            });
        } catch (err) {
            console.error(err);
        } finally {
            dispatch({ type: LOGO_LOADING_DONE });
        }
    };
};

export const setupReportLogo = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGO_LOADING });

            const reportLogoFile = await organizationApi.downloadReportLogo();

            dispatch({
                type: REPORT_LOGO_SETUP,
                reportLogoFile,
            });
        } catch (err) {
            console.error(err);
        } finally {
            dispatch({ type: LOGO_LOADING_DONE });
        }
    };
};
