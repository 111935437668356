import {
    TEXT_NOTES_RETAILER_ACCOUNTS_LOADING,
    TEXT_NOTES_RETAILER_ACCOUNTS_LIST,
    TEXT_NOTES_RETAILER_ACCOUNT_LOADING_DONE,
} from '../../actions/textNotes/textNotes';

const INITIAL_STATE = {
    totalElements: 0,
    totalPages: 0,
    size: 10000,
    number: 0,
    loading: false,
    retailerAccountText: [],
};

const textNotesRetailerAccounts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEXT_NOTES_RETAILER_ACCOUNTS_LOADING:
            return { ...state, loading: true };
        case TEXT_NOTES_RETAILER_ACCOUNTS_LIST:
            return {
                ...state,
                retailerAccountText: action.retailerAccountText,
            };
        case TEXT_NOTES_RETAILER_ACCOUNT_LOADING_DONE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default textNotesRetailerAccounts;
