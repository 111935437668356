import {
    TOTAL_PURCHASE_YTD_NON_PRODUCT_LOADING,
    TOTAL_PURCHASE_YTD_NON_PRODUCT,
    TOTAL_PURCHASE_YTD_NON_PRODUCT_ERROR,
} from '../../../../actions/retailerDashboard/widgets/totalPurchaseYtdNonProducts/totalPurchaseYtdNonProducts';

const INITIAL_STATE = {
    totalPurchaseYtdNonProducts: undefined,
    loading: false,
    errorResponse: null,
};

const totalPurchaseYtdNonProducts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOTAL_PURCHASE_YTD_NON_PRODUCT_LOADING:
            return { ...state, loading: true };
        case TOTAL_PURCHASE_YTD_NON_PRODUCT:
            return {
                ...state,
                totalPurchaseYtdNonProducts: action.totalPurchaseYtdNonProducts,
                loading: false,
                errorResponse: null,
            };
        case TOTAL_PURCHASE_YTD_NON_PRODUCT_ERROR:
            return {
                ...state,
                loading: action.loading,
                errorResponse: action.errorResponse,
            };
        default:
            return state;
    }
};
export default totalPurchaseYtdNonProducts;
