import { Component } from 'react';
import React from 'react';
import './styles.css';
import SfaInput from '../SfaInput';
import SfaToggle from '../sfaToggle/SfaToggle';
import { required } from '../../constants/validate';

class CreateRoleForm extends Component {
    handleChange = (event, { name, checked }) => {
        const { createRoleObject } = this.props;
        const status = checked ? checked : false;
        createRoleObject[name] = status;

        this.setState({ createRoleObject });
    };
    render() {
        const { createRoleObject } = this.props;
        return (
            <div>
                <SfaInput
                    inputName="displayName"
                    inputValue={createRoleObject.displayName}
                    labelText="Role Name *"
                    validations={required}
                    placeholder="Enter Role Name"
                />
                <SfaToggle
                    labelText="Status *"
                    toggleValue={createRoleObject.isEnabled}
                    toggleName="isEnabled"
                    handleChange={this.handleChange}
                    checked={createRoleObject.isEnabled}
                />
            </div>
        );
    }
}
export default CreateRoleForm;
