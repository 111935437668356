import { checkFilters, getMimeType } from './utils';
import { Backend } from './backend';

class MediaApi {
    constructor() {
        this.path = '/media';
    }

    async getServerTime() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/server-date-time`,
        });
    }

    async getServiceVersion() {
        return await Backend.send({
            method: 'GET',
            url: `${this.path}/version`,
        });
    }

    async getListOfAttachmentsForRetailer(
        { page, size, filters, sortColumn, sortOrder, query },
        retailerId
    ) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/attachments/search?id=${retailerId}`,
                body: {
                    page,
                    size,
                    filters: checkFilters(filters),
                    query,
                    sortColumn: sortColumn || 'description',
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAttachment(attachmentId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/attachments/${attachmentId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async downloadAttachment(mediaKey, fileName) {
        try {
            //mediaKey should always has the file extension
            const fileType = getMimeType(mediaKey);

            return await Backend.download(
                {
                    method: 'GET',
                    url: `${this.path}/?key=${mediaKey}`,
                },
                fileName,
                fileType
            );
        } catch (e) {
            throw e;
        }
    }

    async downloadImage(mediaKey) {
        try {
            return await Backend.downloadImg({
                method: 'GET',
                url: `${this.path}/?key=${mediaKey}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAttachmentCategories() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/attachments/categories`,
            });
        } catch (e) {
            throw e;
        }
    }

    async uploadRetailerAttachment(attachmentData, retailerId) {
        try {
            const { file, description, category } = attachmentData;

            let formData = new FormData();
            formData.append('file', file);
            formData.append('description', description);
            formData.append('referenceType', 'retailer');
            formData.append('reference', retailerId);
            formData.append('category', category);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/attachments`,
                body: formData,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    async updateAttachment({ id, category, description }) {
        try {
            let formData = new FormData();
            formData.append('description', description);
            formData.append('category', category);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/attachments/${id}`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteAttachment(id) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/attachments/${id}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { MediaApi };
