import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Icon, Menu, Popup } from 'semantic-ui-react';
import { find, isFunction, map, some } from 'lodash';
import classnames from 'classnames';
import Localize from '../../constants/i18n-utils';
import {
    getToolTIpCOntent,
    hasPermission,
    isBoolean,
    isSvgLogo,
    userHasPermission,
} from '../../constants/utils';
import config from '../../constants/config';
import permissions from '../../constants/permissions';
import retailerNavMenuItemNames from '../../constants/retailerNav';
import defaultImage from '../../assets/logo.png';
import {
    getUserRetailerInfo,
    initRetailerNav,
    initUserInfo,
    setActiveItem,
    setupOrganizationInfo,
    showCart,
    getUserRetailerSuggestedOrders,
    initCart,
} from '../../redux/actions';
import MobileMenu from '../mobileMenu/MobileMenu';
import './styles.css';
import CartDropdown from '../cartDropdown/CartDropdown';
import { isMobile } from 'react-device-detect';

import {
    changeUserRetailer,
    toggleSelectRetailerModal,
} from '../../redux/actions/user/user-retailer';
import type { UserRetailer } from '../../DTOs/user-retailer';
import OkCancelModal from '../OkCancelModal';
import { RetailerInfo } from '../restailer-info/retailer-info';

const LogoImage = {
    alt: 'appLogo',
};

const {
    GAMES,
    DASHBOARD,
    ORDER_TAB_PROGRESS,
    ORDER_TAB_PAST,
    REPORTS,
    INVOICE,
    FAQ_PAGE,
    PRIVACY_POLICY_PAGE,
    CONTACT_US_PAGE,
    DASHBOARD_SETTINGS,
    GETTING_STARTED,
    RETAILER_PLANOGRAM_LIST,
    RETAILER_RETURN_LIST,
} = config.ROUTE_URLS;

const {
    GAME_PAGE,
    IN_PROGRESS_ORDERS,
    PAST_ORDERS,
    INVOICE_PAGE,
    LIST_REPORT,
    FAQ,
    CONTACT_US,
    TAKE_A_TOUR,
    PRIVACY_POLICY,
    ROLE_PLANOGRAM_LIST,
    ROLE_RETURN_LIST,
    DASHBOARD_PAGE,
} = permissions;

const {
    DASHBOARD_NAME,
    GAME_NAME,
    ORDER_NAME,
    INVOICE_NAME,
    REPORTS_NAME,
    FAQ_NAME,
    CONTACT_US_NAME,
    ACCOUNT_NAME,
    GETTING_STARTED_NAME,
    PLANOGRAMS,
    RETURNS,
} = retailerNavMenuItemNames;

export class RetailerNav extends Component {
    state = {
        showAccountMenu: false,
        showMobileMenu: true,
        mobileMenuItems: [],
        privacyPolicy: `${Localize.text(
            'retailer.navigation.privacyPolicy',
            'Privacy Policy'
        )}`,
        logout: Localize.text('retailer.navigation.logout', 'Logout'),
        dashboard: Localize.text('retailer.navigation.dashboard', 'Dashboard'),
        games: Localize.text('retailer.navigation.games', 'Games'),
        planogram: Localize.text('retailer.navigation.planogram', 'Planograms'),
        return: Localize.text('retailer.navigation.return', 'Returns'),

        orders: Localize.text('retailer.navigation.orders', 'Orders'),
        invoices: Localize.text('retailer.navigation.invoices', 'Invoices'),
        reports: Localize.text('retailer.navigation.reports', 'Reports'),
        faq: Localize.text('retailer.navigation.faq', 'FAQs'),
        contactUs: Localize.text('retailer.navigation.contactus', 'Contact Us'),
        gettingStarted: Localize.text(
            'retailer.navigation.gettingStarted',
            'Take A Tour'
        ),
        settings: Localize.text('retiler.navigation.settings', 'User Settings'),
        changeRetailer: Localize.text(
            'retiler.navigation.settings',
            'Change Retailer'
        ),
        cartLocalized: {
            cart: Localize.text('cart.cart', 'Cart'),
            items: Localize.text('cart.items', 'items'),
            totalQuantity: Localize.text(
                'cart.totalQuantity',
                'Total Quantity'
            ),
            totalCost: Localize.text('cart.totalCost', 'Total Cost'),
            proceedToCart: Localize.text(
                'cart.proceedToCart',
                'Proceed to Checkout'
            ),
            close: Localize.text('cart.close', 'Cancel'),
            noItems: Localize.text('cart.noItems', 'No Items In Cart'),
        },

        selectedRetailer: undefined,
    };

    toggleSelectRetailerModal = () => {
        const { toggleSelectRetailerModal, activeRetailer } = this.props;
        this.setState({
            selectedRetailer: activeRetailer?.userRetailerId?.retailerReference,
        });

        toggleSelectRetailerModal();
    };
    scrollFn = () => {
        const isAtTop = window.scrollY === 0;

        if (this.state.fixHeader !== !isAtTop)
            this.setState({ fixHeader: !isAtTop });
    };

    componentDidMount() {
        //get retailer info
        const { getConfigurations } = this.props;

        getConfigurations();
        this.setActiveMenuItem();
        window.addEventListener('scroll', this.scrollFn);
        this.getMobileItems('mobile');
        this.init();
        this.historyChangeUnlistener = this.props.history.listen(
            this.historyChangeListener
        );
    }

    historyChangeListener = (location, action) => {
        const {
            toggleSelectRetailerModal,
            isShowSelectRetailerModal,
            showCart,
        } = this.props;
        if (isShowSelectRetailerModal) {
            toggleSelectRetailerModal();
        }
        showCart(false);
    };

    componentWillUnmount() {
        this.props.initUserInfo();
        window.removeEventListener('scroll', this.scrollFn);
        this.historyChangeUnlistener();
    }
    init = () => {
        const {
            getUserRetailerInfo,
            getUserRetailerSuggestedOrders,
            activeRetailer,
            initCart,
        } = this.props;
        initCart();

        if (activeRetailer?.userRetailerId?.retailerReference) {
            getUserRetailerInfo(
                activeRetailer?.userRetailerId?.retailerReference
            );
            getUserRetailerSuggestedOrders();
        }
    };
    componentDidUpdate(prevProps) {
        const {
            activeRetailer,
            location: { pathname },
        } = this.props;

        const {
            location: { pathname: prevPathname },
            activeRetailer: prevActiveRetailer,
        } = prevProps;
        if (prevPathname !== pathname) {
            this.setActiveMenuItem();
        }
        if (prevActiveRetailer !== activeRetailer) {
            this.init();
        }
    }

    showMobileMenu = () => {
        this.setState({
            showAccountMenu: false,
            showMobileMenu: true,
        });

        this.setActiveMenuItem();
        this.getMobileItems('mobile');
    };

    showAccountMenu = () => {
        this.setState({
            showMobileMenu: false,
            showAccountMenu: true,
        });

        //this.setActiveMenuItem();
        this.getMobileItems('account');
    };

    setActiveMenuItem = () => {
        const routeList = [
            { name: DASHBOARD_NAME, routes: [DASHBOARD] },
            { name: GAME_NAME, routes: [GAMES] },
            { name: PLANOGRAMS, routes: [RETAILER_PLANOGRAM_LIST] },
            { name: ORDER_NAME, routes: [ORDER_TAB_PROGRESS, ORDER_TAB_PAST] },
            { name: RETURNS, routes: [RETAILER_RETURN_LIST] },
            { name: INVOICE_NAME, routes: [INVOICE] },
            { name: REPORTS_NAME, routes: [REPORTS] },
            { name: FAQ_NAME, routes: [FAQ_PAGE] },
            { name: CONTACT_US_NAME, routes: [CONTACT_US_PAGE] },
            { name: ACCOUNT_NAME, routes: [PRIVACY_POLICY_PAGE] },
            { name: GETTING_STARTED_NAME, routes: [GETTING_STARTED] },
        ];

        const foundRoute = find(routeList, (item) =>
            some(
                item.routes,
                (route) => this.props.location.pathname.indexOf(route) > -1
            )
        );

        if (foundRoute) {
            this.props.setActiveItem(foundRoute.name);
        }
    };

    handleItemClick = ({ name }, route) => {
        this.props.setActiveItem(name);
        this.props.showCart(false);
        if (!route || this.props.location.pathname === route) {
            return;
        }

        if (
            route === ORDER_TAB_PROGRESS &&
            !userHasPermission(IN_PROGRESS_ORDERS)
        ) {
            route = ORDER_TAB_PAST;
        }

        this.props.history.push(route);
        window.scrollTo(0, 0);
    };

    renderRetailerInfo = () => {
        return (
            <div className={'pt-3'}>
                {' '}
                <RetailerInfo preference={'M'} />
            </div>
        );
    };

    getMobileItems = (type) => {
        const {
            dashboard,
            games,
            planogram,
            returns,
            invoices,
            reports,
            orders,
        } = this.state;

        let result = [];
        if (type === 'mobile') {
            result = [
                {
                    name: DASHBOARD_NAME,
                    display: dashboard,
                    route: DASHBOARD,
                    condition: userHasPermission(DASHBOARD_PAGE),
                },
                {
                    name: GAME_NAME,
                    display: games,
                    route: GAMES,
                    condition: userHasPermission(GAME_PAGE),
                },
                {
                    name: PLANOGRAMS,
                    display: planogram,
                    route: RETAILER_PLANOGRAM_LIST,
                    condition: userHasPermission(ROLE_PLANOGRAM_LIST),
                },
                {
                    name: ORDER_NAME,
                    display: orders,
                    route: ORDER_TAB_PROGRESS,
                    condition: userHasPermission([
                        IN_PROGRESS_ORDERS,
                        PAST_ORDERS,
                    ]),
                },
                {
                    name: RETURNS,
                    display: returns,
                    route: RETAILER_RETURN_LIST,
                    condition: userHasPermission([ROLE_RETURN_LIST]),
                },
                {
                    name: INVOICE_NAME,
                    display: invoices,
                    route: INVOICE,
                    condition: userHasPermission([INVOICE_PAGE]),
                },
                {
                    name: REPORTS_NAME,
                    display: reports,
                    route: REPORTS,
                    condition: userHasPermission([LIST_REPORT]),
                },
                {
                    name: ACCOUNT_NAME,
                    dropdown: (direction, isUpward, toggleFunction) =>
                        this.accountDropdown(
                            direction,
                            isUpward,
                            toggleFunction
                        ),
                },
            ];
        } else {
            result = [
                {
                    name: ACCOUNT_NAME,
                    dropdown: (direction, isUpward, toggleFunction) => {
                        return this.accountDropdown(
                            direction,
                            isUpward,
                            toggleFunction
                        );
                    },
                },
            ];
        }

        this.setState({
            mobileMenuItems: result,
        });
    };

    accountDropdown = (direction, isUpwards = false, toggleFunction) => {
        const { user, retailerInfo, userRetailer } = this.props;
        const {
            settings,
            logout,
            privacyPolicy,
            changeRetailer,
            faq,
            contactUs,
            gettingStarted,
        } = this.state;

        const accountInfo = (
            <div className="account-info-content">
                <div>
                    <span className="bold">{user.full_name}</span>
                </div>
                {retailerInfo && this.renderRetailerInfo()}
            </div>
        );
        return (
            <Dropdown
                upward={isUpwards}
                item
                className={'user-account-dropdown'}
                icon={
                    <div className="user-icon-wrapper">
                        <Icon className="icon-User" />
                        {direction ? (
                            <Icon className={`icon-Arrow-${direction}`} />
                        ) : (
                            <Icon className="icon-Arrow-Down" />
                        )}
                    </div>
                }
                onClose={(event, data) => {
                    if (toggleFunction) {
                        toggleFunction();
                    }
                    if (isMobile) {
                        this.showMobileMenu();
                    }
                }}
                onOpen={() => {
                    if (isMobile) {
                        this.showAccountMenu();
                    }
                }}
            >
                <Dropdown.Menu className="account-menu">
                    {isMobile && (
                        <Dropdown.Item
                            id={'back-button'}
                            text={'< Back'}
                            className="background-hover-colour"
                            onClick={() =>
                                isMobile &&
                                !this.state.showMobileMenu &&
                                this.showMobileMenu('mobile')
                            }
                        />
                    )}
                    <Dropdown.Item
                        content={accountInfo}
                        className="account-info"
                    />
                    {userHasPermission(PRIVACY_POLICY) && (
                        <Popup
                            content="Alt + V"
                            hoverable
                            basic
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={privacyPolicy}
                                    className="background-hover-colour"
                                    onClick={() =>
                                        this.goToPage(
                                            PRIVACY_POLICY_PAGE,
                                            ACCOUNT_NAME
                                        )
                                    }
                                />
                            }
                        />
                    )}
                    {userHasPermission(DASHBOARD_PAGE) && (
                        <Popup
                            content="Alt + U"
                            hoverable
                            basic
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={settings}
                                    className="background-hover-colour"
                                    onClick={() =>
                                        this.goToPage(
                                            DASHBOARD_SETTINGS,
                                            ACCOUNT_NAME
                                        )
                                    }
                                />
                            }
                        />
                    )}
                    {userRetailer && userRetailer.length > 1 && (
                        <Popup
                            hoverable
                            basic
                            content={'Alt + Q'}
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={changeRetailer}
                                    className="background-hover-colour"
                                    onClick={() =>
                                        this.toggleSelectRetailerModal()
                                    }
                                />
                            }
                        />
                    )}
                    {userHasPermission(FAQ) && (
                        <Popup
                            content="Alt + F"
                            hoverable
                            basic
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={faq}
                                    className="background-hover-colour"
                                    onClick={() => {
                                        this.goToPage(FAQ_PAGE);
                                    }}
                                />
                            }
                        />
                    )}

                    {userHasPermission(CONTACT_US) && (
                        <Popup
                            content="Alt + T"
                            hoverable
                            basic
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={contactUs}
                                    className="background-hover-colour"
                                    onClick={() => {
                                        this.goToPage(CONTACT_US_PAGE);
                                    }}
                                />
                            }
                        />
                    )}
                    {userHasPermission(TAKE_A_TOUR) && (
                        <Popup
                            content="Alt + W"
                            hoverable
                            basic
                            size="tiny"
                            trigger={
                                <Dropdown.Item
                                    text={gettingStarted}
                                    className="background-hover-colour"
                                    onClick={() => {
                                        this.goToPage(GETTING_STARTED);
                                    }}
                                />
                            }
                        />
                    )}
                    <Popup
                        content="Alt + S"
                        hoverable
                        basic
                        size="tiny"
                        trigger={
                            <Dropdown.Item
                                text={logout}
                                className="background-hover-colour"
                                onClick={this.props.logout}
                            />
                        }
                    />
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    goToPage = (page, name) => {
        this.props.setActiveItem(name);
        this.props.showCart(false);
        this.props.history.push(page);
    };

    switchRetailer = async () => {
        const { userRetailer, changeUserRetailer } = this.props;
        const { selectedRetailer } = this.state;
        const activeRetailer = userRetailer.find(
            (ur) => ur.userRetailerId.retailerReference === selectedRetailer
        );
        await changeUserRetailer(activeRetailer);
        this.toggleSelectRetailerModal();
        this.goToPage(hasPermission());
    };
    changeRetailerModal = () => {
        const { selectedRetailer } = this.state;
        const { userRetailer, isShowSelectRetailerModal, activeRetailer } =
            this.props;
        if (!selectedRetailer) {
            this.setState({
                selectedRetailer:
                    activeRetailer?.userRetailerId?.retailerReference,
            });
        }

        return (
            <OkCancelModal
                header={`Change Retailer`}
                body={
                    <div className={'text-align-c'}>
                        <Dropdown
                            options={map(userRetailer, (retailer) => ({
                                text: `${retailer.userRetailerId.retailerReference} - ${retailer.retailerName}`,
                                value: retailer.userRetailerId
                                    .retailerReference,
                            }))}
                            value={selectedRetailer}
                            onChange={(value, data) => {
                                this.setState({ selectedRetailer: data.value });
                            }}
                            search
                            scrolling
                            placeholder={'Please select Retailer'}
                        />
                    </div>
                }
                isOpen={isShowSelectRetailerModal}
                cancelFunction={this.toggleSelectRetailerModal}
                okFunction={this.switchRetailer}
                okText={'Change'}
                cancelTect={'Cancel'}
            ></OkCancelModal>
        );
    };
    render() {
        const { fixHeader } = this.state;
        const {
            appLogo,
            appLogoFile,
            activeItem,
            activeRetailer,
            isShowSelectRetailerModal,
        } = this.props;
        LogoImage.src = isSvgLogo(appLogo, appLogoFile);
        if (!LogoImage.src) {
            LogoImage.src = defaultImage;
        }

        const mobileMenuItems = this.state.mobileMenuItems;

        const menuItems = [
            ...mobileMenuItems,
            {
                name: 'cart',
                display: (
                    <CartDropdown cartLocalized={this.state.cartLocalized} />
                ),
            },
        ];

        return (
            <div
                className={classnames('retailer-nav', {
                    'fix-header': fixHeader,
                })}
            >
                <div data-test="retailerNav" className="inner-div">
                    <div className="max-tablet mobile-icon-wrapper">
                        <MobileMenu menuItems={mobileMenuItems} />
                    </div>
                    <div className="app-logo-wrapper  d-flex">
                        {LogoImage.src && (
                            <img
                                src={LogoImage.src}
                                alt={LogoImage.alt}
                                className="retailer-app-logo"
                            />
                        )}
                        <div className="min-desktop pl-4 align-self-center bold white-space-nowrap retailer-name-info">
                            {`${activeRetailer?.userRetailerId?.retailerReference} - ${activeRetailer?.retailerName}`}
                        </div>
                    </div>

                    <div className="max-tablet mobile-icon-wrapper">
                        <CartDropdown
                            cartLocalized={this.state.cartLocalized}
                        />
                    </div>
                    <div className="min-desktop">
                        <Menu borderless className="desktop-menu">
                            {map(
                                menuItems,
                                ({
                                    name,
                                    display,
                                    dropdown,
                                    route,
                                    condition,
                                }) => {
                                    const toolTipContent =
                                        getToolTIpCOntent(name);
                                    if (isBoolean(condition) && !condition) {
                                        return;
                                    }
                                    const menuJsx = (
                                        <Menu.Item
                                            key={name}
                                            className={classnames(
                                                'clickable',
                                                `${name}-item`
                                            )}
                                            name={name}
                                            active={activeItem === name}
                                            onClick={(e, v) =>
                                                this.handleItemClick(v, route)
                                            }
                                        >
                                            {(dropdown &&
                                                isFunction(dropdown) &&
                                                dropdown()) ||
                                                display}
                                        </Menu.Item>
                                    );

                                    if (!toolTipContent) {
                                        return menuJsx;
                                    }

                                    return (
                                        <Popup
                                            key={name}
                                            content={toolTipContent}
                                            basic
                                            size="tiny"
                                            mouseEnterDelay={
                                                name === ACCOUNT_NAME
                                                    ? 50000
                                                    : 0
                                            }
                                            trigger={menuJsx}
                                        />
                                    );
                                }
                            )}
                        </Menu>
                    </div>
                    {isShowSelectRetailerModal && this.changeRetailerModal()}
                </div>
                <div
                    className={
                        ' max-tablet retailer-info-mobile white-space-nowrap '
                    }
                >
                    {`${activeRetailer?.userRetailerId?.retailerReference} - ${activeRetailer?.retailerName}`}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    organization: { appLogo },
    appLogo: { appLogoFile },
    auth: { info },
    userInfo: { retailerInfo },
    userRetailer: { activeRetailer, userRetailer, isShowSelectRetailerModal },
    retailerNav: { activeItem },
    cart: { toggleCart },
}) => {
    return {
        appLogo,
        appLogoFile,
        user: info,
        retailerInfo,
        activeItem,
        toggleCart,
        activeRetailer,
        userRetailer,
        isShowSelectRetailerModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserRetailerInfo: (reference) =>
            dispatch(getUserRetailerInfo(reference)),
        getUserRetailerSuggestedOrders: () =>
            dispatch(getUserRetailerSuggestedOrders()),
        initUserInfo: () => dispatch(initUserInfo()),
        getConfigurations: () => dispatch(setupOrganizationInfo()),
        init: () => dispatch(initRetailerNav()),
        setActiveItem: (activeItem) => dispatch(setActiveItem(activeItem)),
        showCart: (isShow) => dispatch(showCart(isShow)),
        changeUserRetailer: (activeRetailer: UserRetailer) =>
            dispatch(changeUserRetailer(activeRetailer)),
        toggleSelectRetailerModal: () => dispatch(toggleSelectRetailerModal()),
        initCart: () => dispatch(initCart()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RetailerNav)
);
