import {
    UPDATE_SUCCESS,
    GET_SUCCESS,
    UPDATE_TIERS_INIT,
    UPDATE_TIERS_LOADING,
    UPDATE_TIERS_LOADING_DONE,
} from '../../actions';

const INIT_TIER = {
    id: '',
    name: '',
    description: '',
    frequency: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    tierToUpdate: { ...INIT_TIER },
};

const updateTiers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_TIERS_LOADING:
            return { ...state, loading: true };
        case UPDATE_TIERS_LOADING_DONE:
            return { ...state, loading: false };
        case GET_SUCCESS:
            return {
                ...state,
                tierToUpdate: { ...action.data },
                processing: false,
            };
        case UPDATE_SUCCESS:
            return {
                ...state,
                tierToUpdate: { ...INIT_TIER },
                processing: false,
            };
        case UPDATE_TIERS_INIT:
            return {
                ...INITIAL_STATE,
                tierToUpdate: { ...INIT_TIER },
                loading: false,
                processing: false,
            };
        default:
            return state;
    }
};
export default updateTiers;
