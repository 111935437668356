export const ENABLE_LOADING = 'cognitoLogin/enable-loading';

export interface CognitoLoginStateType {
    isLoading: boolean;
}

interface UpdateLoadingCognitoLoginType {
    type: typeof ENABLE_LOADING;
    payload: boolean;
}
export type CognitoLoginActionTypes = UpdateLoadingCognitoLoginType;
