import React, { Component } from 'react';
import { Input, Icon, Button, Table, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Head from './Head';
import Row from './Row';
import Pagination from './Pagination';
import NoResult from './NoResult';
import NoItems from '../NoItems';
import { searchValueChange, clearSearch, prevSearchValue } from './actions';
import { getAttribute } from '../../constants/utils';

import './style.css';

export class SfaTable extends Component {
    state = {
        currentPage: 0,
        wasPrevSearched: false,
    };

    componentDidMount() {
        this.createTableData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.createTableData(nextProps);

        //determine was previously searched to keep the search bar showing
        //double !! to convert to boolean
        this.setState({
            ...this.state,
            wasPrevSearched: !!this.props.searchValue,
        });
    }

    componentWillUnmount() {
        this.props.clearSearch();
    }

    createTableData = (props) => {
        const { page } = props;

        this.setState({ currentPage: page });
    };

    _onSort = (args) => {
        const { fetchData, searchValue } = this.props;
        if (fetchData) {
            fetchData({
                ...args,
                page: 0,
                query: searchValue,
            });
        }
    };

    pageChanged = (pageNumber) => {
        const { fetchData, size, searchValue } = this.props;
        if (fetchData) {
            const { ascending, sortColumn } = this.props;
            fetchData({
                size,
                page: pageNumber,
                sortOrder: ascending ? 'asc' : 'desc',
                sortColumn,
                query: searchValue,
            });
        }
    };

    onSearchFieldChange = (e) => {
        this.props.searchValueChange(e.target.value);
        if (e.target.value === '') {
            this._onSearchClear();
        }
    };

    onSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._onSearch();
        }
    };

    _onSearch = () => {
        const {
            onSearch,
            filteredSelections,
            searchValue,
            ascending,
            sortColumn,
        } = this.props;

        this.props.prevSearchValue(searchValue);

        if (onSearch) {
            const sortOrder = ascending ? 'asc' : 'desc';
            onSearch(searchValue, filteredSelections, sortOrder, sortColumn);
        }
    };

    _onSearchClear = () => {
        this.props.clearSearch();
        const { fetchData, size, ascending, sortColumn } = this.props;
        if (fetchData) {
            fetchData({
                size,
                page: 0,
                sortOrder: ascending ? 'asc' : 'desc',
                sortColumn,
            });
        }
    };

    render() {
        const {
            content,
            contentKey,
            totalPages,
            totalElements,
            page,
            size,
            pagination,
            columns,
            selector,
            loading,
            searchValue,
            searchButtonToggle,
            noDataMessage,
            actionsMenu,
            supressSearch,
            rowClassName,
            headClassName,
            tableSize,
            collapsing,
            singleLine,
            fixed,
            prevSearchVal,
            showTotalEntries,
        } = this.props;

        const includeSearch = !supressSearch;

        if (loading) {
            return <Loader active={loading} inline="centered" />;
        }

        const hasElements = !!totalElements && totalElements > 0;
        if (!searchValue && !hasElements && !this.state.wasPrevSearched) {
            return <NoItems message={noDataMessage} />;
        }

        let totalText;
        let _totalPages = totalPages;
        const canPage = pagination && totalElements > 0;

        if (canPage || showTotalEntries) {
            const startElement = page * size + 1;
            let endElement = (page + 1) * size;

            if (endElement > totalElements) {
                endElement = totalElements;
            }

            totalText = `Showing ${startElement} to ${endElement} of ${totalElements} entries`;
        }

        const rows = [];
        if (hasElements) {
            _.each(content, (item) => {
                rows.push(
                    <Row
                        key={getAttribute(item, contentKey)}
                        item={item}
                        columns={columns}
                        selector={selector}
                        contentKey={contentKey}
                        actionsMenu={actionsMenu}
                        rowClassName={rowClassName}
                    />
                );
            });
        }

        const searchIcon = searchButtonToggle ? (
            <Icon name="close" link onClick={this._onSearchClear} />
        ) : null;

        return (
            <div data-test="sfaTable">
                {includeSearch ? (
                    <div className="search-section">
                        <Input
                            placeholder="Search..."
                            value={searchValue}
                            onChange={this.onSearchFieldChange}
                            onKeyPress={this.onSearchKeyPress}
                            icon={searchIcon}
                        />
                        <Button
                            className="search-btn"
                            disabled={prevSearchVal === searchValue}
                            primary={prevSearchVal !== searchValue}
                            onClick={this._onSearch}
                        >
                            <span className="icon-Search"> </span>
                        </Button>
                    </div>
                ) : (
                    ''
                )}
                {!hasElements && <NoResult />}

                {hasElements && (
                    <div style={{ overflow: 'auto' }}>
                        <div className="table-container-outer">
                            <div className="table-container">
                                <div className="max-tablet">
                                    <Table
                                        unstackable
                                        singleLine={
                                            singleLine !== undefined &&
                                            singleLine !== null
                                                ? singleLine
                                                : true
                                        }
                                        selectable
                                        collapsing={collapsing}
                                        basic="very"
                                        size={tableSize}
                                    >
                                        <Head
                                            headClassName={headClassName}
                                            useCheckBox={
                                                selector &&
                                                selector.type === 'checkbox'
                                            }
                                            content={content}
                                            contentKey={contentKey}
                                            columns={columns}
                                            sortData={(args) =>
                                                this._onSort(args)
                                            }
                                            size={size}
                                            showActionHead={
                                                actionsMenu &&
                                                actionsMenu.length > 0
                                            }
                                        />
                                        <Table.Body>{rows}</Table.Body>
                                    </Table>
                                </div>
                                <div className="min-desktop">
                                    <Table
                                        singleLine={
                                            singleLine !== undefined &&
                                            singleLine !== null
                                                ? singleLine
                                                : true
                                        }
                                        fixed={
                                            fixed !== undefined &&
                                            fixed !== null
                                                ? fixed
                                                : true
                                        }
                                        selectable
                                        collapsing={collapsing}
                                        basic="very"
                                        size={tableSize}
                                    >
                                        <Head
                                            headClassName={headClassName}
                                            useCheckBox={
                                                selector &&
                                                selector.type === 'checkbox'
                                            }
                                            content={content}
                                            contentKey={contentKey}
                                            columns={columns}
                                            sortData={(args) =>
                                                this._onSort(args)
                                            }
                                            size={size}
                                            showActionHead={
                                                actionsMenu &&
                                                actionsMenu.length > 0
                                            }
                                        />
                                        <Table.Body>{rows}</Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    {hasElements && canPage && (
                        <div className="max-tablet">
                            <div style={{ textAlign: 'center' }}>
                                <div className="mobile-paging-items">
                                    <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        totalPages={_totalPages}
                                        activePage={page + 1}
                                        onPageChanged={this.pageChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="min-desktop">
                        <div className="paging-section">
                            {hasElements && (canPage || showTotalEntries) && (
                                <span className="paging-text">
                                    {totalText || ''}
                                </span>
                            )}
                            {hasElements && canPage && (
                                <div className="paging-items">
                                    <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        totalPages={_totalPages}
                                        activePage={page + 1}
                                        onPageChanged={this.pageChanged}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    ({ tableSort, tableFilter, tableSearch }) => {
        const { searchValue, searchButtonToggle, prevSearchVal } = tableSearch;
        const { sortColumnName, ascending } = tableSort;
        const { selectedItems } = tableFilter;
        return {
            searchValue,
            searchButtonToggle,
            sortColumn: sortColumnName,
            ascending,
            filteredSelections: selectedItems,
            prevSearchVal,
        };
    },
    (dispatch) => ({
        searchValueChange: (value) => dispatch(searchValueChange(value)),
        clearSearch: () => dispatch(clearSearch()),
        prevSearchValue: (value) => dispatch(prevSearchValue(value)),
    })
)(SfaTable);
