import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popup, Button, Dimmer, Loader, Card } from 'semantic-ui-react';
import {
    createOrder,
    toggleOrderSample,
    incrementCartQuantityForGame,
    decrementCartQuantityForGame,
    removeFromCart,
    getLastOrder,
    initCheckout,
    setActiveItem,
    logout,
    getIncludeSampleGames,
    getUserRetailerSuggestedOrders,
    removeAllFromCart,
} from '../../redux/actions';
import Page from '../../layout/Page';
import Localize from '../../constants/i18n-utils';
import config from '../../constants/config';
import NoItems from '../../components/NoItems';
import OkCancelModal from '../../components/OkCancelModal';
import './styles.css';
import { CheckoutItems } from './CheckoutItems';
import { RetailerInfo } from '../../components/restailer-info/retailer-info';
import { TelSellCallApi } from '../../services/tel-sell-call-service';
import { weekdays } from '../../constants/weekdays';
import _ from 'lodash';

const { GAMES, ORDER_TAB_PROGRESS } = config.ROUTE_URLS;

class CheckoutPage extends Component {
    state = {
        isOpen: false,
        modalHeader: '',
        modalBody: '',
        modalOkFcn: null,
        orderCutOffDayModalOpened: false,
        preferredDayOne: '',
        preferredDayTwo: '',
        preferredDayThree: '',
    };

    async componentDidMount() {
        // this._container.focus();
        await this.init();
    }
    init = async () => {
        // this._container.focus();
        this.props.initCheckout();
        await this.props.getLastOrder();
        await this.props.getIncludeSampleGames();
        this.props.setActiveItem('games');
    };
    async componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ) {
        if (this.props.activeRetailer !== prevProps.activeRetailer) {
            await this.init();
        }
    }

    handleNavToGames = () => this.props.history.push(GAMES);

    closeModal = () =>
        this.setState({
            isOpen: false,
            modalHeader: '',
            modalBody: '',
            modalOkFcn: null,
        });

    removeAllGames = () => {
        this.props.initCart();
        this.closeModal();
    };

    handleGotoOrderHistory = () => this.props.history.push(ORDER_TAB_PROGRESS);

    handleRemoveAllGames = () =>
        this.setState({
            isOpen: true,
            modalHeader: Localize.text(
                'cart.removeAll.header',
                'Remove All Games'
            ),
            modalBody: Localize.text(
                'cart.removeAll.confirmMsg',
                'All games will be removed from cart.'
            ),
            modalOkFcn: this.removeAllGames,
        });

    handleRemoveGame = (game) =>
        this.setState({
            isOpen: true,
            modalHeader: Localize.text('cart.removeGame.header', 'Remove Game'),
            modalBody: `Game ${game.gameRefAndName} will be removed from cart`,
            modalOkFcn: () => {
                this.props.removeFromCart(game);
                this.closeModal();
            },
        });

    handleDecrementCartQuantityForGame = (game, qty) => {
        if (qty <= 1) {
            this.handleRemoveGame(game);
        } else {
            this.props.decrementCartQuantityForGame(game);
        }
    };

    checkOrderCutOffDays = async () => {
        const { activeRetailer } = this.props;
        const telSellCallService = new TelSellCallApi();
        const telSellCallDetail =
            await telSellCallService.getTelSellCallDetailsByRetailerReference(
                activeRetailer?.userRetailerId.retailerReference
            );
        const preferredDayOne = telSellCallDetail?.preferredDayWeek1;
        const preferredDayTwo = telSellCallDetail?.preferredDayWeek2;
        const preferredDayThree = telSellCallDetail?.preferredDayWeek3;
        const currentDate = new Date();
        let currentDay = weekdays[currentDate.getDay()];

        if (!preferredDayOne && !preferredDayTwo && !preferredDayThree) {
            this.redirectAfterOrder();
        } else if (
            preferredDayOne !== currentDay &&
            preferredDayTwo !== currentDay &&
            preferredDayThree !== currentDay
        ) {
            this.setState({
                preferredDayOne: preferredDayOne,
                preferredDayTwo: preferredDayTwo,
                preferredDayThree: preferredDayThree,
                orderCutOffDayModalOpened: true,
            });
        } else {
            this.redirectAfterOrder();
        }
    };

    closeOrderCutOffDayModal = () => {
        this.setState({
            orderCutOffDayModalOpened: false,
        });
    };

    redirectAfterOrder = async () => {
        const { getUserRetailerSuggestedOrders, placeOrder, setActiveItem } =
            this.props;

        if (this.state.orderCutOffDayModalOpened) {
            this.closeOrderCutOffDayModal();
        }

        const success = await placeOrder();
        if (success) {
            getUserRetailerSuggestedOrders();
            setActiveItem('orders');
            setTimeout(() => {
                this.handleGotoOrderHistory();
            }, 1500);
        }
    };

    render() {
        const {
            orderedItems,
            incrementCartQuantityForGame,
            loading,
            pageLoading,
            lastOrder,
            includeSampleGames,
            toggleOrderSample,
            enableOrderOutOfCycleWarning,
        } = this.props;

        const {
            preferredDayOne,
            preferredDayTwo,
            preferredDayThree,
            orderCutOffDayModalOpened,
        } = this.state;

        if (orderCutOffDayModalOpened) {
            const modalMessage = (
                <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                    Your preferred {preferredDayTwo ? ' days ' : ' day '}
                    for placing an order
                    {preferredDayTwo
                        ? preferredDayThree
                            ? ` are ${preferredDayOne}, ${preferredDayTwo} and ${preferredDayThree}`
                            : ` are ${preferredDayOne} and ${preferredDayTwo}.`
                        : ` is ${preferredDayOne}.`}
                    <br />
                    Extra charges may apply if order is placed apart from the
                    preferred order day.
                    <br />
                    Please contact administrator for further details.
                </span>
            );
            const modalContent = (
                <div style={{ marginLeft: '270px' }}>
                    Do you wish to proceed?
                </div>
            );
            return (
                <OkCancelModal
                    isOpen={orderCutOffDayModalOpened}
                    header={modalMessage}
                    body={modalContent}
                    okText="Confirm"
                    okFunction={this.redirectAfterOrder}
                    cancelFunction={this.closeOrderCutOffDayModal}
                />
            );
        }

        const backShoppingBtn = (
            <Button
                onClick={this.handleNavToGames}
                className="transparent-btn bold"
                content={Localize.text(
                    'cart.continueShopping',
                    '<  Continue Shopping'
                )}
            />
        );

        const removeAllLinkJsx = (
            <div className="remove-all-link-wrapper">
                <div
                    className="remove-all-link bold clickable"
                    onClick={this.handleRemoveAllGames}
                >
                    {Localize.text(
                        'cart.removeAllInCart',
                        'Remove all games in cart'
                    )}
                </div>
            </div>
        );

        const getPlaceOrderButton = () => {
            const isOrderValid = orderedItems.find(
                (orderedItem) =>
                    orderedItem.game.minimumOrderQuantity &&
                    orderedItem.qty < orderedItem.game.minimumOrderQuantity
            );
            return (
                <Button
                    primary
                    content={Localize.text('cart.placeOrder', 'Place Order')}
                    onClick={
                        enableOrderOutOfCycleWarning
                            ? this.checkOrderCutOffDays
                            : this.redirectAfterOrder
                    }
                    className={'place-order-button'}
                    disabled={
                        loading || isOrderValid || orderedItems.length < 1
                    }
                    loading={loading}
                />
            );
        };

        const { isOpen, modalHeader, modalBody, modalOkFcn } = this.state;

        return (
            <Page
                className="checkout-page"
                title="Review Order"
                keepNotification>
                <Dimmer page active={pageLoading} inverted>
                    <Loader />
                </Dimmer>
                <Card className={'w-100  d-flex flex-direction-row'}>
                    <div className={'p-2 white-space-nowrap'}>
                        Retailer Detail :
                    </div>
                    <div className={'p-2'}>
                        <RetailerInfo preference={'S'} />
                    </div>
                </Card>
                <OkCancelModal
                    isOpen={isOpen}
                    header={modalHeader}
                    okText={Localize.text('cart.removeAll.confirm', 'Confirm')}
                    body={modalBody}
                    okFunction={modalOkFcn}
                    cancelFunction={this.closeModal}
                />
                <div className="content">
                    {lastOrder && (
                        <div className="last-order-wrapper">
                            <div className="active-text clickable">
                                <span
                                    onClick={this.handleGotoOrderHistory}
                                    className="order-number"
                                >
                                    {Localize.text(
                                        'cart.inProgressOrder',
                                        'In Progess Orders'
                                    )}
                                </span>
                                <span className="icon-Open-in-new-tab"> </span>
                            </div>
                        </div>
                    )}
                    {orderedItems.length ? (
                        <CheckoutItems
                            orderedItems={orderedItems}
                            includeSampleGames={includeSampleGames}
                            handleDecrementCartQuantityForGame={
                                this.handleDecrementCartQuantityForGame
                            }
                            incrementCartQuantityForGame={
                                incrementCartQuantityForGame
                            }
                            handleRemoveGame={this.handleRemoveGame}
                            removeAllLinkJsx={removeAllLinkJsx}
                            showSgtQty={true}
                            toggleOrderSample={toggleOrderSample}
                        />
                    ) : (
                        <div className="no-items-wrapper">
                            <NoItems
                                message={Localize.text(
                                    'cart.noItems',
                                    'No Items In Cart'
                                )}
                            />
                        </div>
                    )}
                    <div className="actions min-tablet">
                        {backShoppingBtn}
                        <Popup
                            content="Alt + P"
                            basic
                            size="tiny"
                            hoverable
                            trigger={getPlaceOrderButton()}
                        />
                    </div>
                    <div className="actions max-mobile ">
                        <div className={'d-flex justify-content-space-between'}>
                            {backShoppingBtn}
                            <div className={'w'}> {getPlaceOrderButton()}</div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({
    userRetailer: { activeRetailer },
    cart: { orderedItems, loading },
    checkout,
    organization: { configs },
}) => ({
    orderedItems,
    loading,
    pageLoading: checkout.loading,
    lastOrder: checkout.lastOrder,
    includeSampleGames: checkout.includeSampleGames,
    activeRetailer,
    enableOrderOutOfCycleWarning:
        _.find(configs, { name: 'enable-order-out-of-cycle-warning' })
            ?.value === 'true',
});

const mapDispatchToProps = (dispatch) => ({
    initCart: () => dispatch(removeAllFromCart()),
    placeOrder: () => dispatch(createOrder()),
    getIncludeSampleGames: () => dispatch(getIncludeSampleGames()),
    toggleOrderSample: (game) => dispatch(toggleOrderSample(game)),
    incrementCartQuantityForGame: (game) =>
        dispatch(incrementCartQuantityForGame(game)),
    decrementCartQuantityForGame: (game) =>
        dispatch(decrementCartQuantityForGame(game)),
    removeFromCart: (game) => dispatch(removeFromCart(game)),
    getLastOrder: () => dispatch(getLastOrder()),
    setActiveItem: (activeItem) => dispatch(setActiveItem(activeItem)),
    initCheckout: () => dispatch(initCheckout()),
    logout: () => dispatch(logout()),
    getUserRetailerSuggestedOrders: () =>
        dispatch(getUserRetailerSuggestedOrders()),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CheckoutPage)
);
