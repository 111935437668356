import _ from 'lodash';
import moment from 'moment';

import { RetailersApi } from '../../../services/retailers-service';
import { TiersApi } from '../../../services/tiers-service';
import { SalesRoutesApi } from '../../../services/sales-routes-service';

import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';
import {
    addAllSelectedItems,
    loadList,
} from '../../../components/filter/actions';

export const VISIT_PLANNER_RETAILERS_LIST = 'VISIT_PLANNER_RETAILERS_LIST';
export const VISIT_PLANNER_RETAILERS_LIST_LOADING =
    'VISIT_PLANNER_RETAILERS_LIST_LOADING';
export const VISIT_PLANNER_RETAILERS_LIST_MORE_LOADING =
    'VISIT_PLANNER_RETAILERS_LIST_MORE_LOADING';
export const VISIT_PLANNER_CLEAR = 'VISIT_PLANNER_CLEAR';
export const VISIT_PLANNER_TIERS = 'VISIT_PLANNER_TIERS';
export const VISIT_PLANNER_WEEKS_UPDATE = 'VISIT_PLANNER_WEEKS_UPDATE';
export const VISIT_PLANNER_WEEKS_LOAD = 'VISIT_PLANNER_WEEKS_LOAD';
export const VISIT_PLANNER_DRAG_ITEM = 'VISIT_PLANNER_DRAG_ITEM';
export const VISIT_PLANNER_DRAGGING = 'VISIT_PLANNER_DRAGGING';
export const VISIT_PLANNER_CYCLE_UPDATING = 'VISIT_PLANNER_CYCLE_UPDATING';
export const VISIT_PLANNER_SET_COLLAPSED = 'VISIT_PLANNER_SET_COLLAPSED';

const tiersApi = new TiersApi();
const retailersApi = new RetailersApi();
const salesRouteApi = new SalesRoutesApi();

const TOTAL_PER_PAGE = 8;

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const clearPlanner = () => ({
    type: VISIT_PLANNER_CLEAR,
});

export const fetchRetailersForPlanner = (args) => {
    return async (dispatch, getState) => {
        dispatch({ type: VISIT_PLANNER_RETAILERS_LIST_LOADING });
        try {
            const { visitPlanner } = getState();
            let tiers;
            if (
                !visitPlanner ||
                !visitPlanner.tiers ||
                visitPlanner.tiers.length <= 0
            ) {
                tiers = (await tiersApi.getTierOptions()) || [];

                dispatch({
                    type: VISIT_PLANNER_TIERS,
                    tiers,
                });
            } else {
                tiers = visitPlanner.tiers;
            }

            const data = await retailersApi.getAllRetailers(args);

            if (data && data.content && data.content.length > 0) {
                data.content = _.map(
                    _.filter(
                        data.content,
                        ({ tierFrequency }) => tierFrequency > 0
                    ),
                    ({
                        id,
                        tierFrequency,
                        tierId,
                        name,
                        cycleWeek,
                        reference,
                    }) => {
                        let tierName = 'Unknown';
                        if (tiers) {
                            const tier = _.find(
                                tiers,
                                ({ id }) => id === tierId
                            );
                            tierName = tier ? tier.name : tierName;
                        }

                        return {
                            id,
                            reference,
                            name,
                            cycleWeek,
                            frequency: tierFrequency,
                            tierName,
                            tierId,
                        };
                    }
                );
            }

            dispatch({
                type: VISIT_PLANNER_RETAILERS_LIST,
                data,
            });
            dispatch(loadWeeksColumns());
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const fetchMoreRetailersForPlanner = (args) => {
    return async (dispatch, getState) => {
        dispatch({ type: VISIT_PLANNER_RETAILERS_LIST_MORE_LOADING });
        try {
            const { visitPlanner } = getState();
            const tiers = visitPlanner.tiers;

            const data = await retailersApi.getAllRetailers(args);

            if (data && data.content && data.content.length > 0) {
                data.content = _.map(
                    data.content,
                    ({
                        id,
                        tierFrequency,
                        tierId,
                        name,
                        cycleWeek,
                        reference,
                    }) => {
                        let tierName = 'Unknown';
                        if (tiers) {
                            const tier = _.find(
                                tiers,
                                ({ id }) => id === tierId
                            );
                            tierName = tier ? tier.name : tierName;
                        }

                        return {
                            id,
                            reference,
                            name,
                            cycleWeek,
                            frequency: tierFrequency,
                            tierName,
                            tierId,
                        };
                    }
                );
            }

            dispatch({
                type: VISIT_PLANNER_RETAILERS_LIST,
                data,
            });
            dispatch(loadWeeksColumns());
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};

export const setUpPlannerData = (salesRep) => {
    return async (dispatch, getState) => {
        try {
            const tiers = await tiersApi.getTierOptions();
            const salesRoutes = await salesRouteApi.getSalesRouteBySalesRep(
                salesRep
            );
            dispatch(loadList(salesRoutes, null, { name: 'salesRoute' })); //setup filters in redux
            dispatch(
                addAllSelectedItems(
                    'salesRoute',
                    _.map(salesRoutes, ({ id }) => id)
                )
            );

            const {
                organization: { businessWeek },
            } = getState();
            const { maxFrequency, totalsPerWeek, retailerTiers } =
                await retailersApi.visitPlannerSetup(salesRep, businessWeek);

            const filterTiers = _.map(
                _.filter(
                    retailerTiers || [],
                    ({ tierFrequency }) => tierFrequency > 0
                ),
                ({ tierId, tierFrequency }) => {
                    let tierName = 'Unknown';
                    if (tiers) {
                        const tier = _.find(tiers, ({ id }) => id === tierId);
                        tierName = tier ? tier.name : tierName;
                    }

                    return {
                        text: tierName,
                        value: tierFrequency,
                    };
                }
            );
            dispatch(loadList(filterTiers, null, { name: 'tier' })); //setup filters in redux
            dispatch(
                addAllSelectedItems(
                    'tier',
                    _.map(
                        _.filter(filterTiers, ({ value }) => value > 1),
                        ({ value }) => value
                    )
                )
            );

            dispatch({
                type: VISIT_PLANNER_TIERS,
                tiers,
                maxFrequency,
                totalsPerWeek,
                salesRoutes: _.map(salesRoutes, ({ id, name }) => ({
                    value: id,
                    text: name,
                })),
                retailerTiers: _.sortBy(filterTiers, 'value'),
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const updateWeeksColumns = (targetItem) => {
    return async (dispatch, getState) => {
        const { visitPlanner } = getState();
        dispatch({
            type: VISIT_PLANNER_CYCLE_UPDATING,
            loading: true,
        });

        //remove highlights while processing
        dispatch(dragging(false));

        try {
            const { weeks, draggedItem, totalsPerWeek } = visitPlanner;

            const targetWeekIndex = _.findIndex(
                weeks,
                ({ businessWeek }) => targetItem.businessWeek === businessWeek
            );
            const sourceWeekIndex = _.findIndex(
                weeks,
                ({ businessWeek }) => draggedItem.businessWeek === businessWeek
            );

            const sourceVisits = weeks[sourceWeekIndex].visits;
            const targetVisits = weeks[targetWeekIndex].visits;

            const sourceVisitIndex = _.findIndex(
                sourceVisits,
                ({ reference }) => reference === draggedItem.reference
            );
            const targetVisitIndex = _.findIndex(
                targetVisits,
                ({ reference }) => reference === targetItem.reference
            );

            const tmp = sourceVisits[sourceVisitIndex];
            sourceVisits[sourceVisitIndex] = targetVisits[targetVisitIndex];
            targetVisits[targetVisitIndex] = tmp;

            const tmpBusinessWeek = targetVisits[targetVisitIndex].businessWeek;
            targetVisits[targetVisitIndex].businessWeek =
                sourceVisits[sourceVisitIndex].businessWeek;
            sourceVisits[sourceVisitIndex].businessWeek = tmpBusinessWeek;

            targetVisits[targetVisitIndex].cycleWeek =
                targetVisits[targetVisitIndex].businessWeek %
                    targetVisits[targetVisitIndex].frequency ||
                targetVisits[targetVisitIndex].frequency;

            weeks[sourceWeekIndex].visits = sourceVisits;
            weeks[targetWeekIndex].visits = targetVisits;

            totalsPerWeek[targetVisits[targetVisitIndex].businessWeek] +=
                targetVisits[targetVisitIndex].empty ? -1 : 1;
            totalsPerWeek[sourceVisits[sourceVisitIndex].businessWeek] +=
                sourceVisits[sourceVisitIndex].empty ? -1 : 1;

            //target is now the original visit with updated values
            const newSourceWeek = weeks[targetWeekIndex];
            const newSourceVisit = targetVisits[targetVisitIndex];
            _.each(
                _.filter(
                    weeks,
                    ({ businessWeek }) =>
                        businessWeek !== newSourceWeek.businessWeek
                ),
                (otherWeek) => {
                    const otherIndex = _.findIndex(
                        otherWeek.visits,
                        (otherVisit) =>
                            otherVisit.reference === newSourceVisit.reference
                    );

                    let otherVisit = otherWeek.visits[otherIndex];
                    if (
                        otherVisit.businessWeek % newSourceVisit.frequency ===
                        newSourceVisit.cycleWeek % newSourceVisit.frequency
                    ) {
                        otherVisit = {
                            ..._.cloneDeep(newSourceVisit),
                            businessWeek: otherWeek.businessWeek,
                        };
                    } else {
                        otherVisit = {
                            reference: newSourceVisit.reference,
                            frequency: newSourceVisit.frequency,
                            businessWeek: otherWeek.businessWeek,
                            empty: true,
                        };
                    }
                    otherWeek.visits[otherIndex] = otherVisit;
                }
            );

            const { id, tierId, cycleWeek } = newSourceVisit;
            const tierCycleObj = {
                tier: { id: tierId },
                cycleWeek,
            };

            // update backend with new cycle week for retailer
            await tiersApi.saveTierToRetailer(id, tierCycleObj);

            dispatch({
                type: VISIT_PLANNER_WEEKS_UPDATE,
                weeks,
                totalsPerWeek,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: VISIT_PLANNER_CYCLE_UPDATING,
                loading: false,
            });
        }
    };
};

export const setPlannerCollapsed = (collapsed) => {
    return (dispatch) => {
        dispatch({
            type: VISIT_PLANNER_SET_COLLAPSED,
            plannerCollapsed: collapsed,
        });
        dispatch(loadWeeksColumns());
    };
};

const loadWeeksColumns = () => {
    return (dispatch, getState) => {
        const {
            organization: { businessWeek: currentBusinessWeek },
            visitPlanner,
        } = getState();
        try {
            const { visits, maxFrequency, plannerCollapsed } = visitPlanner;
            const weeks = [];

            const today = new Date();
            const start = moment(today).isoWeek();
            const firstDayOfWeek = moment(today)
                .isoWeek(start)
                .startOf('isoWeek')
                .clone();

            for (let i = 0; i < _.max([maxFrequency || 4, 4]); i++) {
                const currentFrequency = i + 1;
                const startDate = moment(firstDayOfWeek)
                    .add(currentFrequency, 'weeks')
                    .startOf('isoWeek');
                const businessWeek = currentBusinessWeek + currentFrequency;

                const retailerVisits = _.filter(
                    visits,
                    ({ cycleWeek, frequency }) =>
                        (frequency === 1 && cycleWeek === 1) ||
                        businessWeek % frequency === cycleWeek % frequency
                );

                weeks.push({
                    startDate,
                    endDate: moment(startDate).add(6, 'days'),
                    businessWeek,
                    visits: _.map(retailerVisits, (v) => ({
                        ...v,
                        businessWeek,
                        canDrag: v.frequency !== 1,
                    })),
                });
            }

            let paddedWeeks = weeks;
            if (!plannerCollapsed) {
                paddedWeeks = _.map(weeks, (week) => {
                    try {
                        for (let i = 0; i < week.visits.length; i++) {
                            const visit = week.visits[i];
                            if (!visit.empty) {
                                _.each(
                                    _.filter(
                                        weeks,
                                        ({ businessWeek }) =>
                                            businessWeek !== week.businessWeek
                                    ),
                                    (otherWeek) => {
                                        const otherIndex = _.findIndex(
                                            otherWeek.visits,
                                            (otherVisit) =>
                                                otherVisit.reference ===
                                                visit.reference
                                        );

                                        if (otherIndex < 0) {
                                            otherWeek.visits.splice(i, 0, {
                                                reference: visit.reference,
                                                frequency: visit.frequency,
                                                businessWeek:
                                                    otherWeek.businessWeek,
                                                empty: true,
                                            });
                                        } else if (otherIndex !== i) {
                                            const tmp = otherWeek.visits[i];
                                            otherWeek.visits[i] =
                                                otherWeek.visits[otherIndex];
                                            otherWeek.visits[otherIndex] = tmp;
                                        }
                                    }
                                );
                            }
                        }
                    } catch (e) {
                        console.error(e);
                    }

                    return week;
                });
            }

            let maxRowCount = 0;

            paddedWeeks = _.map(paddedWeeks, (week) => {
                maxRowCount = Math.max(maxRowCount, week.visits.length);
                week.visits = _.sortBy(week.visits, ['frequency']);
                return week;
            });

            //add padding visits to the bottom of the each columns if column not full
            //if compressed, don't pad empty rows to PAGE-total?
            dispatch({
                type: VISIT_PLANNER_WEEKS_LOAD,
                weeks: _.map(paddedWeeks, (week) => {
                    const visitsCount = TOTAL_PER_PAGE - week.visits.length;
                    //const visitsCount = maxRowCount - week.visits.length; //scroll still going for 8
                    for (let i = 0; i < visitsCount; i++) {
                        week.visits.push({
                            reference: `${week.businessWeek}-${i}-padding`,
                            empty: true,
                        });
                    }

                    return week;
                }),
            });
        } catch (e) {
            console.log(e);
        }
    };
};

export const dragItem = (reference) => ({
    type: VISIT_PLANNER_DRAG_ITEM,
    item: reference,
});

export const dragging = (isDragging) => ({
    type: VISIT_PLANNER_DRAGGING,
    dragging: isDragging,
});
