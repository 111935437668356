import {
    CORP_ACCOUNTS_LIST,
    CORP_ACCOUNTS_LOADING,
} from '../actions/corpAccounts';

const INITIAL_STATE = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 20,
    number: 0,
    loading: false,
};

const corpAccounts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CORP_ACCOUNTS_LOADING:
            return { ...state, loading: true };
        case CORP_ACCOUNTS_LIST:
            return { ...state, ...action.list, loading: false };
        default:
            return state;
    }
};
export default corpAccounts;
