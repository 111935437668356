import {
    INIT_CREATE_ROLE,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_LOADING,
    CREATE_ROLE_LOADING_DONE,
} from '../../actions';

const INIT_CREATE_ROLE_FIELDS = {
    displayName: '',
    isEnabled: false,
};

const INITIAL_STATE = {
    createRoleObject: { ...INIT_CREATE_ROLE_FIELDS },
    errors: [],
    roleSuccessMessage: null,
    loading: false,
};

const createRole = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ROLE_LOADING:
            return { ...state, loading: true };

        case CREATE_ROLE_SUCCESS:
            return {
                ...state,
                createRoleObject: { ...INIT_CREATE_ROLE_FIELDS },
                roleData: action.roleData,
                roleSuccessMessage: `${action.displayName} role was created successfully`,
            };

        case INIT_CREATE_ROLE:
            return {
                ...INITIAL_STATE,
                createRoleObject: { ...INIT_CREATE_ROLE_FIELDS },
            };

        case CREATE_ROLE_LOADING_DONE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
export default createRole;
