import {
    RETAILER_TYPE_OPTIONS_LOADING,
    RETAILER_TYPE_OPTIONS_LOADING_DONE,
    RETAILER_TYPE_OPTIONS_INIT,
    RETAILER_TYPE_OPTIONS_GET,
} from '../../../actions';

const INITIAL_STATE = {
    loading: false,
    options: [],
};

const retailerTypeOptions = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RETAILER_TYPE_OPTIONS_INIT:
            return { ...INITIAL_STATE };

        case RETAILER_TYPE_OPTIONS_GET:
            return {
                ...state,
                options: payload,
            };

        case RETAILER_TYPE_OPTIONS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case RETAILER_TYPE_OPTIONS_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default retailerTypeOptions;
