import type {
    SuggestedQuantityActionTypes,
    SuggestedQuantityStateType,
} from '../../type/items/suggested-quanitity';
import { UPDATE_SUGGESTED_QUANTITY } from '../../type/items/suggested-quanitity';

const initialState: SuggestedQuantityStateType = {
    isLoading: false,
    suggestedQuantityMap: new Map(),
};
const suggestedQuantityReducer = (
    state = initialState,
    action: SuggestedQuantityActionTypes
) => {
    switch (action.type) {
        case UPDATE_SUGGESTED_QUANTITY:
            return {
                ...state,
                isLoading: false,
                suggestedQuantityMap: action.payload,
            };
        default:
            return state;
    }
};
export default suggestedQuantityReducer;
