import {
    each,
    find,
    includes,
    isEmpty,
    isArray,
    keys,
    map,
    size,
    some,
    sortBy,
    isObject,
    cloneDeep,
    mergeWith,
    reduce,
} from 'lodash';
import Store from '../redux/store';
import Localize from './i18n-utils';
import moment from 'moment';
import { role_names } from './roles';
import config from './config';
import Permissions from './permissions';
import shortCutKeys from './toolTipContent';

export const getPhoneFaxMask = () => {
    const phoneFaxMaskConfig = find(
        Store.store.getState().organization.configs,
        { name: 'phone-fax-mask' }
    );

    return !!phoneFaxMaskConfig ? phoneFaxMaskConfig.value : null;
};

export const getAttribute = (obj, name) => {
    if (!name || !obj) {
        return undefined;
    }

    const dotNotation = name.split('.');

    let value;
    if (dotNotation.length > 1) {
        value = {};
        each(dotNotation, (attr) => {
            value = value ? value[attr] ?? obj[attr] : obj[attr];
        });
        return value;
    } else {
        value = obj[name];
        if (isArray(value)) {
            return JSON.stringify(value);
        }
    }

    return obj[name];
};

export const getErrorMessage = (err, defaultErrorMessage) => {
    let ret = err
        ? defaultErrorMessage ?? err.message
        : Localize.text(
              'error.system',
              'Unexpected error occurred while performing request'
          );
    try {
        ret =
            err && err.error_description
                ? Localize.text(err.error_description, 'Unknown error')
                : ret;

        const errMsg =
            err.response &&
            err.response.data.error &&
            err.response.data.error.message
                ? err.response.data.error.message
                : '';

        if (
            err &&
            err.response &&
            err.response.data &&
            (err.response.data.message ||
                err.response.data.error_description ||
                err.response.data)
        ) {
            if (err.response.data.error) {
                if (
                    err.response.data.message &&
                    err.response.data.message === 'GENERAL'
                ) {
                    return Localize.text(
                        'error.system',
                        'Unexpected error occurred while performing request'
                    );
                } else if (
                    err.response.data.error &&
                    err.response.data.error.message
                ) {
                    return Localize.text(
                        err.response.data.error.message,
                        errMsg.charAt(0).toUpperCase() + errMsg.slice(1)
                    );
                }
            }

            if (typeof err.response.data !== 'string') {
                let responseMsg = err.response.data.error_description
                    ? err.response.data.error_description
                    : err.response.data.message;
                if (responseMsg) {
                    return Localize.text(
                        responseMsg,
                        'Unexpected error occurred while performing request'
                    );
                }
            } else {
                return Localize.text(err.response.data, `${err.response.data}`);
            }
        }
    } catch (e) {
        console.error(e);
    }
    return ret;
};

export const getDownloadError = async (err) => {
    if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data instanceof Blob
    ) {
        const data = (await blobToJson(err.response.data)) || {};

        let message;
        if (data.error) {
            message = data.error.message;
        }

        return Localize.text(
            message || 'error.system',
            message || 'Unexpected error occurred while performing request'
        );
    }

    return getErrorMessage(err);
};

export const blobToJson = async (blob) => {
    try {
        const resText = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('abort', reject);
            reader.addEventListener('error', reject);
            reader.addEventListener('loadend', () => {
                resolve(reader.result);
            });
            reader.readAsText(blob);
        });
        return JSON.parse(resText);
    } catch (err) {
        return null;
    }
};

export const getErrorStatus = (err) => {
    if (err && !err.response) {
        return 500;
    }

    if (
        err.response.data &&
        (err.response.data.message ||
            err.response.data.error_description ||
            err.response.data)
    ) {
        return err.response.status;
    }
    return null;
};

export const userHasPermission = (permissionToAuthenicate) => {
    const {
        auth: {
            info: { authorities },
        },
    } = Store.store.getState();

    if (isArray(permissionToAuthenicate)) {
        return some(permissionToAuthenicate, (permission) => {
            return find(authorities, (r) => r === permission);
        });
    } else {
        return some(authorities, (a) => a === permissionToAuthenicate);
    }
};

export const hasRole = (userAuthorities, roleToAuthenticate) => {
    return some(
        userAuthorities,
        (r) => r.toLowerCase() === roleToAuthenticate.toLowerCase()
    );
};

const hasAuthority = (permission) => {
    const {
        auth: {
            info: { authorities },
        },
    } = Store.store.getState();
    return some(
        authorities,
        (r) => r.toLowerCase() === permission.toLowerCase()
    );
};

export const hasPermission = () => {
    if (hasAuthority(Permissions.DASHBOARD_PAGE)) {
        return config.ROUTE_URLS.DASHBOARD;
    } else if (hasAuthority(Permissions.GAME_PAGE)) {
        return config.ROUTE_URLS.GAMES;
    } else if (hasAuthority(Permissions.IN_PROGRESS_ORDERS)) {
        return config.ROUTE_URLS.ORDER_TAB_PROGRESS;
    } else if (hasAuthority(Permissions.PAST_ORDERS)) {
        return config.ROUTE_URLS.ORDER_TAB_PAST;
    } else if (
        hasAuthority(Permissions.INVOICE_PAGE) ||
        hasAuthority(Permissions.DOWNLOAD_INVOICE) ||
        hasAuthority(Permissions.INVOICE_DETAILS)
    ) {
        return config.ROUTE_URLS.INVOICE;
    } else if (hasAuthority(Permissions.LIST_REPORT)) {
        return config.ROUTE_URLS.REPORTS;
    } else if (hasAuthority(Permissions.FAQ)) {
        return config.ROUTE_URLS.FAQ_PAGE;
    } else if (hasAuthority(Permissions.CONTACT_US)) {
        return config.ROUTE_URLS.CONTACT_US_PAGE;
    } else if (hasAuthority(Permissions.PRIVACY_POLICY)) {
        return config.ROUTE_URLS.PRIVACY_POLICY_PAGE;
    } else {
        return config.ROUTE_URLS.UNAUTHORIZED;
    }
};

export const selectItemsToFilter = (selectedItems) => {
    const { applied, ...others } = selectedItems;
    if (!applied) {
        return [];
    }

    let filters = [];
    if (others && keys(others).length > 0) {
        each(keys(others), (key) => {
            const data = others[key];
            if (size(data) > 0) {
                filters.push({
                    property: key,
                    data: others[key],
                });
            }
        });
    }
    return filters;
};

export const selectItemsToFilterForReturns = (selectedItems) => {
    const { ...others } = selectedItems;

    let filters = [];
    if (others && keys(others).length > 0) {
        each(keys(others), (key) => {
            const data = others[key];
            if (size(data) > 0) {
                filters.push({
                    property: key,
                    data: others[key],
                });
            }
        });
    }
    return filters;
};

export const formatCents = (amount, symbol, separator, fixed = 0) => {
    if (isNaN(amount)) {
        return amount;
    }

    if (amount === 0) {
        return `0${symbol}`;
    }

    return (
        amount
            .toFixed(fixed)
            .substring(2)
            .replace(/(\d)(?=(\d{3})+(,|$))/g, `$1${separator}`) + symbol
    );
};

export const formatMoney = (number) => {
    const value = isNaN(number) ? 0 : number;

    return value < 1 && value >= 0 && enableCentDisplay()
        ? formatCents(value, getCentSymbol(), '', 2)
        : new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
          }).format(value);
};

export const toTitleCase = (str) => {
    if (!str) {
        return '';
    }

    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            // eslint-disable-next-line
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

export const currentUserOnlySearchResult = ({
    user_id,
    user_name,
    firstName,
    lastName,
    full_name,
}) => {
    return Promise.resolve({
        content: [
            {
                id: user_id,
                username: user_name,
                firstName,
                lastName,
                fullName: full_name,
            },
        ],
        first: true,
        last: true,
        number: 0,
        size: 1,
        totalElements: 1,
        totalPages: 1,
    });
};

export const formatPhoneFax = (number, mask) => {
    if (!number || !mask) {
        return '';
    }

    let newNumber = `${number}`.replace(/\D/g, '');
    if (newNumber.length > 10) {
        newNumber = newNumber.substr(0, 10); //if more than 11 take the 1st 11 characters
    }

    let phoneFaxNumber = '';

    if (!mask.isEmpty) {
        let counter = 0;
        for (let i = 0; i < mask.length; i++) {
            if (mask[i] === 'x') {
                phoneFaxNumber = phoneFaxNumber + newNumber.charAt(counter);
                counter++;
            } else {
                phoneFaxNumber = phoneFaxNumber + mask[i];
            }
        }
    }
    return phoneFaxNumber;
};

export const enableCentDisplay = () => {
    const state = Store.store.getState();

    if (!state || !state.organization) return false;

    const enableCentConfig = find(state.organization.configs, {
        name: 'enable-cent-symbol',
    });

    return enableCentConfig && enableCentConfig.value.toLowerCase() === 'true';
};

export const getCentSymbol = () => {
    const { organization } = Store.store.getState();

    const displayCentSymbol = find(organization.configs, {
        name: 'display-cent-symbol',
    });

    if (!displayCentSymbol) return '';

    return displayCentSymbol.value;
};

export const getCompletedTaskCardContents = (aggregate) => {
    let result = [];

    for (let i = 0; i < aggregate.length; i++) {
        if (includes(aggregate[i].completed, true)) {
            result.push(aggregate[i]);
        }
    }
    sortBy(result, ({ visitOrder }) => visitOrder);

    return result;
};

export const getInCompletedTaskCardContents = (aggregate, result) => {
    const incompleteResult = [];

    for (let i = 0; i < aggregate.length; i++) {
        if (!includes(aggregate[i].completed, true)) {
            incompleteResult.push(aggregate[i]);
        }
    }

    const newResult = map(
        sortBy(incompleteResult, ({ visitOrder }) => visitOrder),
        (c, i) => ({
            ...c,
            first: i === 0,
            last: i === incompleteResult.length - 1,
        })
    );

    return [...result, ...newResult];
};

export const formatOrderStatus = (status) => {
    switch (status.toUpperCase()) {
        case 'X':
            return 'Canceled';
        case 'P':
            return 'Pending';
        case 'T':
            return 'In Transit';
        case 'R':
            return 'Received';
        default:
            return 'Unknown';
    }
};

export const isSafari = () => {
    return /^((?!chrome|android|crios).)*safari/i.test(navigator.userAgent);
};

export const isTokenExpired = () => {
    const { auth } = Store.store.getState();
    const { info } = auth;

    return info.exp ? new Date().getTime() > info.exp * 1000 : true;
};

export const isAdmin = () => {
    const {
        auth: { info },
    } = Store.store.getState();

    return (
        info &&
        info.roles &&
        info.roles.length > 0 &&
        some(info.roles, (role) =>
            includes([role_names.ADMIN, role_names.LOTTO_ADMIN], role)
        )
    );
};

export const isSvgLogo = (appLogo, appLogoFile) => {
    if (!appLogoFile) {
        return null;
    }

    const ext = appLogo ? appLogo.substr(appLogo.lastIndexOf('.') + 1) : '';
    if (ext.toLowerCase() === 'svg') {
        return `data:image/svg+xml;base64,${appLogoFile}`;
    } else {
        return `data:image/png;base64,${appLogoFile}`;
    }
};

export const isBoolean = (valueToCheck) => typeof valueToCheck === typeof true;

export const getMaxQty = () => {
    const { organization } = Store.store.getState();
    const { configs } = organization;
    const maxOrderPulltabGames = find(configs, {
        name: 'max-orderable-games',
    })?.value;

    let maxQty = 4;
    const configMaxQty = parseInt(maxOrderPulltabGames, 10);
    if (!isNaN(configMaxQty) && configMaxQty > 0) {
        maxQty = configMaxQty;
    }
    return maxQty;
};

export const updateQuantity = (qty, maxOrderQty, offset = 1) => {
    const updatedQty = qty + offset;

    //disallow negative or greater than max qty if defined
    if (maxOrderQty !== 0 && (updatedQty < 0 || updatedQty > maxOrderQty)) {
        return qty;
    }

    return updatedQty;
};

export const formatDate = (date, formatToDate) => {
    if (date === undefined || date === null || date === '') {
        return '';
    }
    // By default, moment parses and displays in local time. Added utc() to moment date to
    // avoid any conversion of date to local time.
    let momentDate = moment(date);
    if (!momentDate.isValid()) {
        momentDate = moment.utc(date, getDisplayDateFormat());
    }

    if (!momentDate.isValid()) {
        momentDate = moment(
            new Date(date).toISOString(),
            getDisplayDateFormat()
        );
    }

    if (!momentDate.isValid()) {
        momentDate = moment(
            new Date(date).toISOString(),
            config.DATE_FORMAT_FOR_SERVER
        );
    }

    if (!momentDate.isValid()) {
        console.error(
            `date: ${date}, is not valid. change to a formattable date, ${config.DISPLAY_DATE_FORMAT}, ${config.DATE_FORMAT_FOR_SERVER} or known ISO 8601 format`
        );
        return '';
    }
    return momentDate.format(formatToDate);
};

export const getToolTIpCOntent = (name) => {
    return shortCutKeys[name];
};

export const dateFromServerDateToDisplayDateFormat = (datString: string) => {
    return datString
        ? moment(datString, config.DATE_FORMAT_FOR_SERVER).format(
              getDisplayDateFormat()
          )
        : '';
};
export const dateFromDisplayDateFormatToServerDate = (datString: string) => {
    return datString
        ? moment(datString, getDisplayDateFormat()).format(
              config.DATE_FORMAT_FOR_SERVER
          )
        : '';
};
export const formatToServerDate = (date) =>
    formatDate(date, config.DATE_FORMAT_FOR_SERVER);

export const formatToDisplayDate = (date) => {
    try {
        return formatDate(date, getDisplayDateFormat());
    } catch (e) {
        // Format to default date format of application if the date-format
        // is not configured properly
        console.warn(
            'Date format not configured properly. ' +
                'Switching to default data format.'
        );
        return formatDate(date, config.DISPLAY_DATE_FORMAT);
    }
};

export const formatToDisplayDateTime = (date) => {
    try {
        return formatDate(date, getDisplayDateTimeFormat());
    } catch (e) {
        // Format to default date time format of application if the
        // datetime-format is not configured properly
        console.warn(
            'Date time format not configured properly. ' +
                'Switching to default data time format.'
        );
        return formatDate(date, config.DISPLAY_DATE_TIME_FORMAT);
    }
};

export const getDisplayDateFormat = () => {
    const state = Store.store.getState();

    if (!state || isEmpty(state.frontendConfig.configs)) {
        return config.DISPLAY_DATE_FORMAT;
    }
    const dateFormatParameter = find(state.frontendConfig.configs, [
        'name',
        'date-format',
    ]);

    if (!dateFormatParameter) {
        console.warn('Display date format parameter not configured.');
        return config.DISPLAY_DATE_FORMAT;
    }
    return dateFormatParameter.value;
};

const getDisplayDateTimeFormat = () => {
    const state = Store.store.getState();

    if (!state || isEmpty(state.frontendConfig.configs)) {
        return config.DISPLAY_DATE_TIME_FORMAT;
    }
    const dateTimeFormatParameter = find(state.frontendConfig.configs, [
        'name',
        'datetime-format',
    ]);
    if (!dateTimeFormatParameter) {
        console.warn('Display date time format parameter not configured.');
        return config.DISPLAY_DATE_TIME_FORMAT;
    }
    return dateTimeFormatParameter.value;
};

export const getNoOfDaysForUpcomingGames = () => {
    const state = Store.store.getState();

    if (!state || isEmpty(state.frontendConfig.configs)) {
        return config.NO_OF_DAYS_FOR_UPCOMING_GAMES;
    }
    const noOfDaysForUpcomingGames = find(
        state.frontendConfig.configs,
        (config) => config.name === 'no-of-days-for-upcoming-games'
    );
    if (!noOfDaysForUpcomingGames) {
        console.warn(
            'No of days for upcoming games date time format parameter not configured.'
        );
        return config.NO_OF_DAYS_FOR_UPCOMING_GAMES;
    }
    return noOfDaysForUpcomingGames.value;
};

export const flattenValues = (values) => {
    let newValues = {};
    map(values, (value, key) => {
        !isObject(value)
            ? (newValues[key] = value)
            : map(value, (val, k) => (newValues[k] = val));
    });
    return newValues;
};

// Merge one or more object's properties recursively into the first object. This will update properties if they do not
// exist yet, or replace them if they do exist. This function will return a deeply cloned object, so you can safely
// pass it a prop without it being modified. Since the object parameter is not mutated, you must assign the result.
export const mergeObjectProperties = (object, ...objectsToMerge) =>
    mergeWith(cloneDeep(object), ...objectsToMerge, (value, valueToMerge) => {
        // lodash default behaviour is to ignore empty arrays and objects, hence the need for this customizer function.

        // If the new value is an empty array, then return an empty array
        if (isArray(valueToMerge) && valueToMerge.length === 0) {
            return [];
        }

        // If the new value is an empty object, then return an empty object
        if (isObject(valueToMerge) && valueToMerge === {}) {
            return {};
        }

        return valueToMerge;
    });

export const replaceWhiteSpace = (value, replacementValue) =>
    value.trim().replace(/\s/g, replacementValue);

export const calculateTotalCostForOrder = (orderedItems) => {
    return reduce(
        orderedItems,
        (total, curr) =>
            curr?.game?.cost
                ? (total += curr.game.cost)
                : (total +=
                      curr.qty *
                      (curr.game?.retailPrice ? curr.game.retailPrice : 0)),
        0
    );
};

export const getFrontendConfigValue = (name, defaultValue, errorMsg) => {
    const state = Store.store.getState();

    if (!state || isEmpty(state.frontendConfig.configs)) {
        return defaultValue;
    }
    const param = find(
        state.frontendConfig.configs,
        (config) => config.name === name
    );
    if (param) {
        return param.value;
    } else {
        console.warn(
            errorMsg ??
                `Parameter - ${name} could not be found. Please check the configuration.`
        );
        return defaultValue;
    }
};

export const getMessageBannerDisplayTime = () => {
    return getFrontendConfigValue('banner-notification-timeout', 5000);
};
