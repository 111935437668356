import { ReportsApi } from '../../../services/reports-service';
import { GamesApi } from '../../../services/games-service';
import { MediaApi } from '../../../services/media-service';
import { OrganizationApi } from '../../../services/organization-service';
import { RetailersApi } from '../../../services/retailers-service';
import { UserApi } from '../../../services/user-service';

export const SERVICE_VERSIONS_INIT = 'SERVICE_VERSIONS_INIT';
export const SERVICE_VERSIONS_DATA = 'SERVICE_VERSIONS_DATA';
export const SERVICE_VERSIONS_LOADING = 'SERVICE_VERSIONS_LOADING';

const gameApi = new GamesApi();
const mediaApi = new MediaApi();
const organizationApi = new OrganizationApi();
const retailerApi = new RetailersApi();
const reportsApi = new ReportsApi();
const userApi = new UserApi();

const setServiceVersionLoading = (isLoading) => ({
    type: SERVICE_VERSIONS_LOADING,
    payload: isLoading,
});

const setServiceVersion = (serviceName, version, time) => ({
    type: SERVICE_VERSIONS_DATA,
    payload: {
        serviceName: serviceName,
        version: version,
        time: time,
    },
});

export const initServiceVersions = () => {
    return async (dispatch) => {
        dispatch({ type: SERVICE_VERSIONS_INIT });
    };
};

export const getServiceVersions = () => async (dispatch) => {
    dispatch(setServiceVersionLoading(true));

    const gameServiceVersion = dispatch(getGameServiceVersion());
    const mediaServiceVersion = dispatch(getMediaServiceVersion());
    const organizationServiceVersion = dispatch(
        getOrganizationServiceVersion()
    );
    const retailerServiceVersion = dispatch(getRetailerServiceVersion());
    const reportServiceVersion = dispatch(getReportServiceVersion());
    const userServiceVersion = dispatch(getUserServiceVersion());
    await Promise.all([
        gameServiceVersion,
        mediaServiceVersion,
        organizationServiceVersion,
        retailerServiceVersion,
        reportServiceVersion,
        userServiceVersion,
    ]);

    dispatch(setServiceVersionLoading(false));
};

const getGameServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await gameApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'Game',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('Game', 'NOT AVAILABLE', null));
    }
};

const getMediaServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await mediaApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'Media',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('Media', 'NOT AVAILABLE', null));
    }
};

const getOrganizationServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await organizationApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'Organization',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('Organization', 'NOT AVAILABLE', null));
    }
};

const getRetailerServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await retailerApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'Retailer',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('Retailer', 'NOT AVAILABLE', null));
    }
};

const getReportServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await reportsApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'Report',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('Report', 'NOT AVAILABLE', null));
    }
};

const getUserServiceVersion = () => async (dispatch) => {
    try {
        const versionDetails = await userApi.getServiceVersion();
        dispatch(
            setServiceVersion(
                'User',
                versionDetails.version,
                versionDetails.time
            )
        );
    } catch (err) {
        dispatch(setServiceVersion('User', 'NOT AVAILABLE', null));
    }
};
