export const surveyStatuses = [
    { id: 'DRAFT', description: 'Draft' },
    { id: 'ACTIVE', description: 'Active' },
    { id: 'INACTIVE', description: 'Inactive' },
];

export const questionTypes = [
    { id: 'YES_NO', description: 'Yes No' },
    { id: 'SLIDER', description: 'Slider' },
    { id: 'NUMBER', description: 'Number' },
    {
        id: 'RADIO',
        options: [
            { value: 'TRUE', description: 'True' },
            { value: 'FALSE', description: 'False' },
        ],
        description: 'True False',
    },
    { id: 'TEXT', description: 'Text' },
    { id: 'COMBO', description: 'Combo' },
];
