import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Popup } from 'semantic-ui-react';
import Calendar from './calendar/Calendar';
import UneditableLabel from './UneditableLabel';
import { Field } from 'redux-form';
import semanticFormField from './form/semanticFormField';

class ReduxFormCalendarPicker extends Component {
    state = {
        dateValue: '',
        isPopUpOpen: false,
    };

    dateChange = (value) => {
        const { handleChange, name, change } = this.props;

        this.setState({
            dateValue: value,
        });
        handleChange && handleChange(name, value);
        change && change(name, value);
    };

    togglePopUp = (isShow) => {
        this.setState({ isPopUpOpen: isShow });
    };

    render() {
        const {
            disabled,
            labelText,
            minDate,
            maxDate,
            name,
            disabledText,
            format,
            showYear,
            validate,
        } = this.props;
        const { isPopUpOpen, dateValue } = this.state;
        return (
            <Form.Field>
                <div className="readonly">
                    <UneditableLabel text={labelText} />
                </div>
                <Popup
                    trigger={
                        <div>
                            <Field
                                name={name}
                                component={semanticFormField}
                                as={Form.Input}
                                icon="calendar"
                                type={'text'}
                                validate={validate}
                                maxLength={255}
                                value={dateValue}
                                disabled={disabled}
                                readOnly
                            />
                        </div>
                    }
                    content={
                        disabled && disabledText ? null : (
                            <Calendar
                                selectedDate={dateValue}
                                onValuesChange={(value) => {
                                    this.dateChange(value);
                                    this.togglePopUp(false);
                                }}
                                minDate={minDate}
                                format={format}
                                maxDate={maxDate}
                                showYear={showYear}
                            />
                        )
                    }
                    on="click"
                    style={{ height: '290px' }}
                    open={isPopUpOpen}
                    onOpen={() => {
                        this.togglePopUp(true);
                    }}
                    onClose={() => {
                        this.togglePopUp(false);
                    }}
                />
            </Form.Field>
        );
    }
}

ReduxFormCalendarPicker.propTypes = {
    disabled: PropTypes.bool,
    minDate: PropTypes.instanceOf(moment),
    labelText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default ReduxFormCalendarPicker;
