import { getErrorStatus } from '../../../constants/utils';
import { errorHandler } from '../errorHandler/errorHandler';

import { MapApi } from '../../../services/route-mapping-service';

export const ROUTE_MODAL_MAPPING_DONE = 'ROUTE_MODAL_MAPPING_DONE';
export const ROUTE_MODAL_MAPPING_LOADING = 'ROUTE_MODAL_MAPPING_LOADING';
export const ROUTE_MODAL_MAPPING_SUCCESS = 'ROUTE_MODAL_MAPPING_SUCCESS';

export const ROUTE_MODAL_MAPPING_VALUE_UPDATE =
    'ROUTE_MODAL_MAPPING_VALUE_UPDATE';

export const OPTIMIZED_ROUTE_SUCCESS = 'OPTIMIZED_ROUTE_SUCCESS';
export const OPTIMIZED_ROUTE_LOADING = 'OPTIMIZED_ROUTE_LOADING';
export const OPTIMIZED_ROUTE_DONE = 'OPTIMIZED_ROUTE_DONE';

export const LAST_OPTIMIZED_LOADING = 'LAST_OPTIMIZED_LOADING';
export const LAST_OPTIMIZED_SUCCESS = 'LAST_OPTIMIZED_SUCCESS';
export const LAST_OPTIMIZED_DONE = 'LAST_OPTIMIZED_SUCCESS';
export const ROUTE_BUTTONS_ENABLED = 'ROUTE_BUTTONS_ENABLED';

const mapApi = new MapApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getOptimizedRoute = (apiKey, addressesToOptimize) => {
    return async (dispatch) => {
        try {
            dispatch({ type: OPTIMIZED_ROUTE_LOADING });

            const optimized = await mapApi.getOptimizedRoute(
                apiKey,
                addressesToOptimize
            );
            dispatch({
                type: OPTIMIZED_ROUTE_SUCCESS,
                optimized,
            });
        } catch (err) {
            console.error(err);
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: OPTIMIZED_ROUTE_DONE });
        }
    };
};

export const getDeviceLongitudeLatitude = (api, latitudeLongitude) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ROUTE_MODAL_MAPPING_LOADING });

            const routing = await mapApi.getGeoAddresses(
                api,
                latitudeLongitude
            );
            dispatch({
                type: ROUTE_MODAL_MAPPING_SUCCESS,
                routing,
            });
        } catch (err) {
            console.error(err);
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: ROUTE_MODAL_MAPPING_DONE });
        }
    };
};

export const updateRetailerLastOptimized = (retailerIds, date, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LAST_OPTIMIZED_LOADING });

            const lastOptimized = await mapApi.updateRetailerLastOptimized(
                retailerIds,
                date,
                type
            );
            dispatch({
                type: LAST_OPTIMIZED_SUCCESS,
                lastOptimized,
            });
        } catch (err) {
            console.error(err);
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: LAST_OPTIMIZED_DONE });
        }
    };
};

export const updateInput = (prop, value) => ({
    type: ROUTE_MODAL_MAPPING_VALUE_UPDATE,
    value,
    prop,
});

export const updateButtonsEnabled = (val) => ({
    type: ROUTE_BUTTONS_ENABLED,
    buttonsEnabled: val,
});
