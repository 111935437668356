import {
    RETAILER_INVOICES_INIT,
    RETAILER_INVOICES,
    RETAILER_INVOICES_LOADING,
} from '../../actions/invoice/lastPeriodCommissions';

const INITIAL_STATE = {
    loading: false,
};

const lastPeriodCommissions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETAILER_INVOICES_INIT:
            return { ...INITIAL_STATE };
        case RETAILER_INVOICES_LOADING:
            return { ...state, loading: true };
        case RETAILER_INVOICES:
            return { ...state, ...action.retailerInvoices, loading: false };
        default:
            return state;
    }
};
export default lastPeriodCommissions;
