import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Popup, Input, Icon } from 'semantic-ui-react';
import { formatToDisplayDate, formatToServerDate } from '../constants/utils';
import _ from 'lodash';
import UneditableLabel from './UneditableLabel';
import DateRangeCalender from './DateRangeCalender';
import ReactDOM from 'react-dom';
class DateRangePicker extends Component {
    constructor() {
        super();
        this.state = {
            dateRange: {
                startDate: moment().toDate(),
                endDate: moment().toDate(),
                key: 'dateRange',
            },
            display: {
                startDate: '',
                endDate: '',
            },
            isMobile: false,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount = () => {
        this.setDateRange(this.props.value);
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({ isMobile: window.innerWidth <= 768 });
    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }
    componentDidUpdate = (prevProps) => {
        const { value, isCalendarOpen } = this.props;
        if (isCalendarOpen && value !== prevProps.value) {
            this.setDateRange(value);
        }
    };

    setDateRange = (range) => {
        if (_.isArray(range)) {
            const fromDate = range[0];
            const toDate = range.length === 2 ? range[1] : range[0];
            this.setDateRangeState(fromDate, toDate);
        } else {
            this.setDateRangeState(null, null);
        }
    };

    setDateRangeState = (fromDate, toDate) => {
        if (fromDate && toDate) {
            this.setState({
                dateRange: {
                    startDate: moment(fromDate).toDate(),
                    endDate: moment(toDate).toDate(),
                    key: 'dateRange',
                },
                display: {
                    startDate: fromDate,
                    endDate: toDate,
                },
            });
        } else {
            this.setState({
                dateRange: {
                    startDate: moment().toDate(),
                    endDate: moment().toDate(),
                    key: 'dateRange',
                },
                display: {
                    startDate: '',
                    endDate: '',
                },
            });
        }
        this.forceUpdate();
    };

    dateChange = (range) => {
        let dateRange = range.dateRange ? range.dateRange : range.selection;
        this.setState({ dateRange: dateRange, display: dateRange });
        this.forceUpdate();
        let newRange = {
            from: formatToServerDate(dateRange.startDate),
            to: formatToServerDate(dateRange.endDate),
        };
        this.props.handleChange(this.props.name, newRange);
    };

    removeItem = () => {
        this.props.removeItem();
        this.setDateRange();
        this.openCalendar(false, true);
    };

    openCalendar = (isCalendarOpen, onCalendarClose) => {
        this.setState({ open: isCalendarOpen, onClose: true });
    };
    getInputField = () => {
        const {
            disabled,
            name,

            className,

            autoComplete,
            clearable,
        } = this.props;
        const {
            display: { endDate, startDate },
        } = this.state;
        return (
            <Input
                fluid
                placeholder="From and To Dates"
                name={name}
                value={
                    startDate && endDate
                        ? formatToDisplayDate(startDate) +
                          ' - ' +
                          formatToDisplayDate(endDate)
                        : ''
                }
                disabled={disabled}
                className={className}
                readOnly
                autoFocus={true}
                autoComplete={autoComplete}
                icon={
                    clearable && startDate && endDate ? (
                        <Icon name="close" link onClick={this.removeItem} />
                    ) : (
                        <Icon name="calendar" />
                    )
                }
            />
        );
    };

    getMobileCalender = () => {
        const { dateRange } = this.state;
        return ReactDOM.createPortal(
            <div className={'custom-modal-background'}>
                <div className={'custom-modal-body'}>
                    <DateRangeCalender
                        onChange={this.dateChange}
                        ranges={[dateRange]}
                        editableDateInputs={true}
                    />
                </div>
            </div>,
            document.body
        );
    };
    render() {
        const {
            disabled,
            labelText,
            disabledText,
            helpText,
            errorText,
            onCalendarOpen,
            onCalendarClose,
            isCalendarOpen,
        } = this.props;

        const { dateRange, isMobile } = this.state;

        return (
            <Form.Field>
                {labelText && (
                    <div className="readonly">
                        <UneditableLabel text={labelText} />
                    </div>
                )}

                {!isMobile ? (
                    <Popup
                        trigger={this.getInputField()}
                        content={
                            disabled && disabledText ? null : (
                                <div className={'min-tablet'}>
                                    <DateRangeCalender
                                        onChange={this.dateChange}
                                        ranges={[dateRange]}
                                        editableDateInputs={true}
                                    />
                                </div>
                            )
                        }
                        on="click"
                        open={isCalendarOpen}
                        onOpen={onCalendarOpen}
                        onClose={onCalendarClose}
                        className={'min-tablet'}
                    />
                ) : (
                    <div className={'max-mobile'} onClick={onCalendarOpen}>
                        {this.getInputField()}
                        {isCalendarOpen && (
                            <div className={'max-mobile'}>
                                {this.getMobileCalender()}
                            </div>
                        )}
                    </div>
                )}
                {helpText && (
                    <div className="caption-text secondary-color">
                        <i className="icon-Info" /> {helpText}
                    </div>
                )}
                {errorText && (
                    <div className="error-text">
                        <i>{errorText}</i>
                    </div>
                )}
            </Form.Field>
        );
    }
}

DateRangePicker.propTypes = {
    disabled: PropTypes.bool,
    minDate: PropTypes.string,
    labelText: PropTypes.string,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    errorText: PropTypes.string,
};

export default DateRangePicker;
