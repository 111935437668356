import {
    CREATE_SUCCESS,
    CREATE_TIERS_INIT,
    CREATE_TIERS_LOADING,
    CREATE_TIERS_LOADING_DONE,
} from '../../actions';

const INIT_TIER = {
    id: '',
    name: '',
    description: '',
    frequency: '',
};

const INITIAL_STATE = {
    loading: false,
    processing: false,
    tierToCreate: { ...INIT_TIER },
};

const createTiers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_TIERS_LOADING:
            return { ...state, loading: true };
        case CREATE_TIERS_LOADING_DONE:
            return { ...state, loading: false };
        case CREATE_SUCCESS:
            return {
                ...state,
                tierToCreate: { ...INIT_TIER },
                processing: false,
            };
        case CREATE_TIERS_INIT:
            return {
                ...INITIAL_STATE,
                tierToCreate: { ...INIT_TIER },
                loading: false,
                processing: false,
                createSucess: false,
            };
        default:
            return state;
    }
};
export default createTiers;
