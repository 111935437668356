import { Backend } from './backend';
import { checkFilters } from './utils';

class OrderApi {
    constructor() {
        this.path = '/retailers/order';
    }

    async search({
        page,
        size,
        sortOrder,
        sortColumn,
        filters,
        query,
        sortColumns,
    }) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'ASC',
                    sortColumn: sortColumn || 'id',
                    sortColumns: sortColumns,
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async createOrder(order) {
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/create-order`,
                body: order,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateOrder(orderId, order) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/${orderId}`,
                body: order,
            });
        } catch (e) {
            throw e;
        }
    }
    async getLastOrder(reference) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/last-order/${reference}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getOrder(orderId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/${orderId}`,
            });
        } catch (e) {
            throw e;
        }
    }
    async getOrderByOrderDate(reference, date) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/by-order-date/${reference}/${date}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async cancelOrder(orderId, version) {
        try {
            return await Backend.send({
                method: 'PUT',
                url: `${this.path}/cancel-order/${version}/${orderId}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export default OrderApi;
