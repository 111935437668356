import {
    ZONE_LOADING,
    ZONE_INFO_UPDATE,
    ZONE_LOADING_DONE,
    INIT_ZONE_INFO,
    ZONE_RETAILER_LIST_LOADING,
    ZONE_RETAILER_LIST,
} from '../actions/zones/readZones';

const INITIAL_STATE = {
    loading: false,
    taskIsEditable: true,
    zoneInfo: {},
};

const readZone = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ZONE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ZONE_RETAILER_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ZONE_INFO_UPDATE:
            return {
                ...state,
                zoneInfo: action.data,
                loading: false,
                taskIsEditable: false,
            };
        case ZONE_RETAILER_LIST:
            return {
                ...state,
                zoneInfo: action.zoneList,
                loading: false,
            };
        case ZONE_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };
        case INIT_ZONE_INFO:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
export default readZone;
