import { TextNotesApi } from '../../../services/text-notes-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { NOTIFY_SUCCESS, NOTIFY_ERROR } from '../../actions';

export const TEXT_NOTES_LIST = 'TEXT_NOTES_LIST';
export const CURR_EDIT_TEXT_NOTE = 'CURR_EDIT_TEXT_NOTE';
export const TEXT_NOTES_LOADING = 'TEXT_NOTES_LOADING';
export const TEXT_NOTES_LOADING_DONE = 'TEXT_NOTES_LOADING_DONE';
export const UPDATE_TEXT_NOTE = 'UPDATE_TEXT_NOTE';
export const TEXT_NOTES_RETAILER_ACCOUNTS_LOADING =
    'TEXT_NOTES_RETAILER_ACCOUNTS_LOADING';
export const TEXT_NOTES_RETAILER_ACCOUNTS_LIST =
    'TEXT_NOTES_RETAILER_ACCOUNTS_LIST';
export const TEXT_NOTES_RETAILER_ACCOUNT_LOADING_DONE =
    'TEXT_NOTES_RETAILER_ACCOUNT_LOADING_DONE';

const textNotesApi = new TextNotesApi();

const textNotesError = (e) => {
    return (dispatch) => {
        dispatch(error({ msg: getErrorMessage(e), target: 'ReadRetailer' }));
    };
};

export const searchTextNotes = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TEXT_NOTES_LOADING });

            let data = await textNotesApi.searchTextNotes(args);

            dispatch({
                type: TEXT_NOTES_LIST,
                data,
            });
        } catch (e) {
            console.error(e);
            dispatch(textNotesError(e));
        } finally {
            dispatch({ type: TEXT_NOTES_LOADING_DONE });
        }
    };
};

export const searchTextNotesForRetailerAccounts = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TEXT_NOTES_RETAILER_ACCOUNTS_LOADING });

            let retailerAccountText =
                await textNotesApi.searchRetailerTextNotesForAccounts(args);

            dispatch({
                type: TEXT_NOTES_RETAILER_ACCOUNTS_LIST,
                retailerAccountText,
            });
        } catch (e) {
            console.error(e);
            dispatch(textNotesError(e));
        } finally {
            dispatch({ type: TEXT_NOTES_RETAILER_ACCOUNT_LOADING_DONE });
        }
    };
};

export const updateTextNote = (textNoteId, textNoteText, target) => {
    return async (dispatch, getState) => {
        let success = true;
        try {
            dispatch({ type: TEXT_NOTES_LOADING });

            const { offline } = getState();

            //if online just do right away
            if (offline.online) {
                await textNotesApi.updateTextNote(textNoteId, textNoteText);
            } else {
                dispatch({
                    type: UPDATE_TEXT_NOTE,
                    payload: { textNoteText },
                    meta: {
                        offline: {
                            effect: {
                                method: 'put',
                                url: `/notes/${textNoteId}`,
                                body: textNoteText,
                            },
                            commit: {
                                type: NOTIFY_SUCCESS,
                                msg: 'Queued Text Note was updated successfully',
                                target: target,
                            }, //'ReadRetailer'},
                            rollback: {
                                type: NOTIFY_ERROR,
                                msg: 'Queued Text Note was not created',
                                target: target,
                            }, //'ReadRetailer'}
                        },
                    },
                });
            }
        } catch (e) {
            console.error(e);

            success = false;
            dispatch(textNotesError(e));
        } finally {
            dispatch({ type: TEXT_NOTES_LOADING_DONE });
        }

        return success;
    };
};

export const deleteTextNote = (textNoteId) => {
    return async (dispatch) => {
        let success = true;
        try {
            dispatch({ type: TEXT_NOTES_LOADING });

            await textNotesApi.deleteTextNote(textNoteId);
        } catch (e) {
            console.error(e);

            success = false;
            dispatch(textNotesError(e));
        } finally {
            dispatch({ type: TEXT_NOTES_LOADING_DONE });
        }

        return success;
    };
};

export const setCurrTextNote = (textNoteText) => ({
    type: CURR_EDIT_TEXT_NOTE,
    textNoteText,
});
