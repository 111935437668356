import { checkFilters } from './utils';
import { Backend } from './backend';

class TargetApi {
    constructor() {
        this.path = '/reports/sales-targets';
    }

    async searchSalesTargets({
        page,
        size,
        sortOrder,
        sortColumn,
        sortColumns,
        filters,
        query,
    }) {
        try {
            const sorting = {};
            if (sortColumn) {
                sorting.sortColumn =
                    sortColumn === 'fiscalYear'
                        ? 'fiscalYearStartDate'
                        : sortColumn;
                sorting.sortOrder = sortOrder
                    ? sortOrder.toUpperCase()
                    : 'DESC';
            } else if (sortColumns) {
                sorting.sortColumns = sortColumns;
            } else {
                sorting.sortColumns = [
                    { column: 'fiscalYearStartDate', order: 'DESC' },
                    { column: 'salesRouteName', order: 'ASC' },
                ];
            }

            return await Backend.send({
                method: 'post',
                url: `${this.path}/search`,
                body: {
                    page,
                    size,
                    ...sorting,
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async createSalesTarget(args) {
        try {
            const targetDto = this._mapCreateViewToDto(args);

            return await Backend.send({
                method: 'post',
                url: `${this.path}/`,
                body: targetDto,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesTargetsToUpdate(id) {
        try {
            const path = `${this.path}/${id}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async getSalesTargetYtd(id) {
        try {
            const path = `${this.path}/ytd/${id}`;
            return await Backend.send({
                method: 'get',
                url: path,
            });
        } catch (e) {
            throw e;
        }
    }

    async updateSalesTarget(id, targetToUpdate) {
        try {
            const targetDto = this._mapCreateViewToDto(targetToUpdate);
            delete targetDto.salesRoutes;

            return await Backend.send({
                method: 'put',
                url: `${this.path}`,
                body: targetDto,
            });
        } catch (e) {
            throw e;
        }
    }

    _mapCreateViewToDto(viewModel) {
        const dto = {
            id: '',
            fiscalYear: '',
            salesRouteId: '',
            salesRouteName: '',
            salesRoutes: '',
            drawBaseTarget: '',
            instantTarget: '',
            passiveTarget: '',
            vltTarget: '',
            pulltabTarget: '',
            salesManager: '',
            salesRep: '',
        };

        dto.drawBaseTarget = viewModel.drawBaseTarget;
        dto.instantTarget = viewModel.instantTarget;
        dto.passiveTarget = viewModel.passiveTarget;
        dto.vltTarget = viewModel.vltTarget;
        dto.pulltabTarget = viewModel.pulltabTarget;
        dto.fiscalYear = viewModel.fiscalYear;

        //If there is a rodId: Update ELSE: create
        if (viewModel.id) {
            dto.id = viewModel.id;
            dto.salesRouteId = viewModel.salesRouteId[0].id;
            dto.salesRouteName = viewModel.salesRouteId[0].name;
            dto.salesManager = viewModel.salesRouteId[0].salesManager;
            dto.salesRep = viewModel.salesRouteId[0].salesRep;
        } else {
            dto.salesRoutes = viewModel.salesRouteId;
        }
        return dto;
    }

    async getAllFiscalYearsWithSalesTargets() {
        try {
            const fiscalYears = await Backend.send({
                method: 'post',
                url: `${this.path}/fiscal-years`,
            });
            return fiscalYears;
        } catch (e) {
            throw e;
        }
    }
}

export { TargetApi };
