import { PlanogramApi } from '../../../services/planogram-group-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const PLANOGRAM_METADATA_PROCESSING = 'PLANOGRAM_METADATA_PROCESSING';
export const PLANOGRAM_METADATA_INIT = 'PLANOGRAM_METADATA_INIT';
export const PLANOGRAM_METADATA_GET_SUCCESS = 'PLANOGRAM_METADATA_GET_SUCCESS';
export const UPDATE_PLANOGRAM_METADATA_LOADING =
    'UPDATE_PLANOGRAM_METADATA_LOADING';
export const UPDATE_PLANOGRAM_METADATA_LOADING_DONE =
    'UPDATE_PLANOGRAM_METADATA_LOADING_DONE';

const planogramApi = new PlanogramApi();

const updatePlanogramError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'ReadPlanogramGroup',
            })
        );
    };
};

export const updatePlanogramMetadata = (updatePlanogramForm) => {
    return async (dispatch, getState) => {
        let success = true;
        dispatch({ type: PLANOGRAM_METADATA_PROCESSING });
        try {
            const planogram = await planogramApi.updatePlanogramMetadata(
                updatePlanogramForm
            );
            dispatch({
                type: PLANOGRAM_METADATA_GET_SUCCESS,
                planogram,
            });
        } catch (e) {
            success = false;
            dispatch(updatePlanogramError(e));
        } finally {
            dispatch({ type: UPDATE_PLANOGRAM_METADATA_LOADING_DONE });
        }

        return success;
    };
};

export const initPlanogramMetadata = () => {
    return async (dispatch) => {
        dispatch({ type: PLANOGRAM_METADATA_INIT });
    };
};
