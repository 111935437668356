import { PlanogramApi } from '../../../services/planogram-group-service';
import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';

export const PLANOGRAMS_UPLOAD_LIST = 'PLANOGRAMS_UPLOAD_LIST';
export const PLANOGRAMS_UPLOAD_LIST_LOADING = 'PLANOGRAMS_UPLOAD_LIST_LOADING';
export const PLANOGRAMS_UPLOAD_LIST_LOADING_DONE =
    'PLANOGRAMS_UPLOAD_LIST_LOADING_DONE';

const planogramApi = new PlanogramApi();

const updatePlanogramError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({
                msg: getErrorMessage(errors),
                target: 'PlanogramUploadList',
            })
        );
    };
};

export const getAllPlanogramGroups = (args, planogramGroupId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PLANOGRAMS_UPLOAD_LIST_LOADING });
            const data = await planogramApi.getAllPlanograms(
                args,
                planogramGroupId
            );
            dispatch({
                type: PLANOGRAMS_UPLOAD_LIST,
                data,
            });
        } catch (err) {
            console.error(err);
            dispatch(updatePlanogramError(err));
        } finally {
            dispatch({ type: PLANOGRAMS_UPLOAD_LIST_LOADING_DONE });
        }
    };
};
