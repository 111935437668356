import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
    updateDocument,
    uploadDocument,
    initDocumentAttachmentUpload,
    initDocumentUploadState,
    success,
    error,
    clearNotification,
} from '../../redux/actions';
import SfaForm from '../SfaForm';
import SfaTextArea from '../SfaTextArea';
import SfaSelect from '../SfaSelect';
import FileSelector from '../FileSelector';
import {
    required,
    maxLength,
    maxUploadFileSize,
    validFileExtension,
} from '../../constants/validate';
import OkCancelModal from '../../components/OkCancelModal';

class UploadDocument extends Component {
    state = {
        showConfirmation: false,
    };
    componentWillMount = () => {
        const { documentInfo } = this.props;

        if (documentInfo) {
            this.props.initDocumentUpload(documentInfo);
        } else {
            this.props.initDocumentUpload();
        }
    };

    componentWillUnmount = () => {
        this.props.initDocumentUploadState();
    };

    showConfirmationModal = () => this.setState({ showConfirmation: true });
    closeConfirmationModal = () => this.setState({ showConfirmation: false });

    uploadDocument = async (values) => {
        const {
            uploadDocument,
            returnToList,
            successMsg,
            errorMsg,
            clearNotification,
        } = this.props;

        if (values.file) {
            values.name = values.file[0].name;
            values.file = values.file[0];
        } else {
            errorMsg({ msg: 'File is required', target: 'UploadDocument' });
            return;
        }

        this.closeConfirmationModal();
        const success = await uploadDocument(values);

        returnToList();
        if (success) {
            clearNotification();
            successMsg({
                msg: 'Successfully uploaded document',
                target: 'DocumentListPage',
            });
        } else {
            clearNotification();
            errorMsg({
                msg: `File upload failed, contact administrator`,
                target: 'DocumentListPage',
            });
        }
    };

    editAttachment = async (values) => {
        const { updateDocument, documentInfo, returnToList, successMsg } =
            this.props;

        //add attachment id
        values.id = documentInfo.id;
        this.closeConfirmationModal();
        const success = await updateDocument(values);

        returnToList();

        if (success) {
            successMsg({
                msg: `Document was successfully updated.`,
                target: 'DocumentListPage',
            });
        }
    };

    render() {
        const {
            loading,
            description,
            category,
            categoryOptions,
            handleSubmit,
            returnToList,
            documentInfo,
            onDelete,
            pristine,
        } = this.props;

        const docType = [
            { name: 'Policy Document', id: 'POLICY' },
            { name: 'FAQ Document', id: 'FAQ' },
            { name: 'New Retailer Document', id: 'NEW_RETAILER' },
        ];

        return (
            <SfaForm
                name="UploadAttachmentForm"
                onSubmit={handleSubmit(this.showConfirmationModal)} //if have delete must be edit
                headerText={`${
                    !documentInfo ? 'Create File' : documentInfo.fileName
                }`}
                onCancel={returnToList}
                showSaveCancel={true}
                submitButtonText={documentInfo ? 'Save' : 'Create'}
                submitIsDisabled={pristine}
                loading={loading}
            >
                <OkCancelModal
                    header={`${
                        documentInfo
                            ? 'Update Document Details'
                            : 'Upload Document'
                    }`}
                    body={`Are you sure you want to save the ${
                        documentInfo ? 'changes' : 'file'
                    } ?`}
                    cancelFunction={this.closeConfirmationModal}
                    okFunction={
                        onDelete
                            ? handleSubmit(this.editAttachment)
                            : handleSubmit(this.uploadDocument)
                    }
                    isOpen={this.state.showConfirmation}
                    okText={'Save'}
                />
                {
                    //if no documentInfo passed in its a create else update
                    !documentInfo && (
                        <Field
                            component={FileSelector}
                            type="file"
                            name="file"
                            labelText="File *"
                            accept="application/pdf, image/jpeg, image/png, video/mp4, audio/3gpp, video/3gpp, video/quicktime, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            helpText="File types allowed: .pdf .jpeg .jpg .png .mp4 .3gp .mov .doc .docx. Maximum file size: 10MB"
                            validate={[
                                required,
                                maxUploadFileSize,
                                validFileExtension,
                            ]}
                        />
                    )
                }

                <SfaTextArea
                    labelText="Description *"
                    textAreaName="description"
                    value={description}
                    validations={[required, maxLength]}
                />
                <SfaSelect
                    labelText="Category *"
                    fieldName="category"
                    options={categoryOptions.map(
                        (item) => ({ label: item.name, value: `${item.id}` }) //value must be a string for redux forms to work properly
                    )}
                    isMulti={false}
                    value={`${category?.id}`} //value must be a string for redux forms to work properly
                    validations={required}
                />
                <SfaSelect
                    labelText="Document Type *"
                    fieldName="documentType"
                    options={docType.map(
                        (item) => ({ label: item.name, value: item.id }) //value must be a string for redux forms to work properly
                    )}
                    isMulti={false}
                    value={documentInfo && documentInfo.type}
                    validations={required}
                    isDisabled={documentInfo}
                />
            </SfaForm>
        );
    }
}

const mapStateToProps = ({ documentUpload: { document } }) => {
    return {
        ...document,
        initialValues: {
            file: document?.file,
            description: document?.description,
            category:
                document?.category?.id > 0 ? `${document.category.id}` : null,
            documentType: document?.type,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadDocument: (formData, documentType) =>
            dispatch(uploadDocument(formData, documentType)),
        updateDocument: (attachmentData) =>
            dispatch(updateDocument(attachmentData)),
        initDocumentUploadState: () => dispatch(initDocumentUploadState()),
        initDocumentUpload: (documentInfo) =>
            dispatch(initDocumentAttachmentUpload(documentInfo)),
        successMsg: ({ msg, target }) => dispatch(success({ msg, target })),
        errorMsg: ({ msg, target }) => dispatch(error({ msg, target })),
        clearNotification: () => dispatch(clearNotification()),
    };
};

UploadDocument.propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({ form: 'UploadAttachmentForm', enableReinitialize: true })(
            UploadDocument
        )
    )
);
