import {
    ORDER_QUANTITY_LOADING,
    ORDER_QUANTITY_LOADING_DONE,
    ORDER_QUANTITY_INIT,
    ORDER_QUANTITY_SAVE,
} from '../../actions';

const INITIAL_STATE = {
    loading: false,
    qty: 1,
};

const orderQuantity = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ORDER_QUANTITY_INIT:
            return { ...INITIAL_STATE };

        case ORDER_QUANTITY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ORDER_QUANTITY_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        case ORDER_QUANTITY_SAVE:
            return {
                ...state,
                qty: payload,
            };

        default:
            return state;
    }
};
export default orderQuantity;
