import { getErrorMessage } from '../../../constants/utils';
import { error } from '../notifications';
import { ReturnsApi } from '../../../services/returns-service';
import _ from 'lodash';

export const PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS =
    'PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS';
export const PENDING_RETURNS__VIEW_RETURN_UNEXPECTED_ITEMS =
    'PENDING_RETURNS__VIEW_RETURN_UNEXPECTED_ITEMS';
export const PENDING_RETURNS__VIEW_RETURN_SCANNED_ITEMS =
    'PENDING_RETURNS__VIEW_RETURN_SCANNED_ITEMS';
export const PENDING_RETURNS__VIEW_RETURN_LOADED =
    'PENDING_RETURNS__VIEW_RETURN_LOADED';
export const PENDING_RETURNS__VIEW_RETURN_LOADING =
    'PENDING_RETURNS__VIEW_RETURN_LOADING';
export const PENDING_RETURNS__VIEW_RETURN_RESET =
    'PENDING_RETURNS__VIEW_RETURN_RESET';
export const PENDING_RETURNS__VIEW_RETURN_RECEIVED =
    'PENDING_RETURNS__VIEW_RETURN_RECEIVED';

const returnsApi = new ReturnsApi();

export const viewReturnsLoading = () => ({
    type: PENDING_RETURNS__VIEW_RETURN_LOADING,
});

export const viewReturnsLoaded = () => ({
    type: PENDING_RETURNS__VIEW_RETURN_LOADED,
});

export const initViewReturn = (id) => async (dispatch) => {
    let response;
    dispatch(viewReturnsLoading());
    try {
        let pendingReturn = await returnsApi.getPendingReturnById(id);
        dispatch({
            type: PENDING_RETURNS__VIEW_RETURN_RECEIVED,
            payload: pendingReturn,
        });
        dispatch(prepareItemsList(pendingReturn));
        response = {
            result: 'SUCCESS',
        };
    } catch (e) {
        error({ msg: getErrorMessage(e) });
        response = {
            result: 'ERROR',
            errorMessage: getErrorMessage(e),
        };
    }
    dispatch(viewReturnsLoaded());
    return response;
};

const prepareItemsList = (pendingReturn) => (dispatch) => {
    let scannedItems = [];
    let expectedItems = [];
    let unexpectedItems = [];

    if (
        pendingReturn?.uiStatus?.code === 'U' ||
        pendingReturn?.uiStatus?.code === 'C'
    ) {
        expectedItems = dispatch(mapPendingReturnDetails(pendingReturn));

        dispatch({
            type: PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS,
            payload: expectedItems,
        });
    } else {
        let mappedPendingReturnDetails = dispatch(
            mapPendingReturnDetails(pendingReturn)
        );

        mappedPendingReturnDetails.forEach((pendingReturnDetail) => {
            if (pendingReturnDetail.itemType === 'ps') {
                if (pendingReturnDetail.isProcessed) {
                    if (pendingReturnDetail.isUnexpected) {
                        if (pendingReturnDetail.itemQuantity === 0) {
                            unexpectedItems.push({
                                bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                                itemCode: pendingReturnDetail.itemCode,
                                itemQuantity:
                                    pendingReturnDetail.processedQuantity -
                                    pendingReturnDetail.itemQuantity,
                                itemType: 'ps',
                            });
                        } else {
                            unexpectedItems.push({
                                bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                                itemCode: pendingReturnDetail.itemCode,
                                itemQuantity:
                                    pendingReturnDetail.processedQuantity -
                                    pendingReturnDetail.itemQuantity,
                                itemType: 'ps',
                            });

                            scannedItems.push({
                                bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                                itemCode: pendingReturnDetail.itemCode,
                                itemQuantity: pendingReturnDetail.itemQuantity,
                                itemType: 'ps',
                            });
                        }
                    } else {
                        scannedItems.push({
                            bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                            itemCode: pendingReturnDetail.itemCode,
                            itemQuantity: pendingReturnDetail.processedQuantity,
                            itemType: 'ps',
                        });

                        if (
                            pendingReturnDetail.itemQuantity !==
                            pendingReturnDetail.processedQuantity
                        ) {
                            expectedItems.push({
                                bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                                itemCode: pendingReturnDetail.itemCode,
                                itemQuantity:
                                    pendingReturnDetail.itemQuantity -
                                    pendingReturnDetail.processedQuantity,
                                itemType: 'ps',
                            });
                        }
                    }
                } else {
                    expectedItems.push({
                        bookSerial: `pos_${pendingReturnDetail.itemCode}`,
                        itemCode: pendingReturnDetail.itemCode,
                        itemQuantity: pendingReturnDetail.itemQuantity,
                        itemType: 'ps',
                    });
                }
            } else if (pendingReturnDetail.itemType === 'book') {
                if (!pendingReturnDetail.isUnexpected) {
                    if (pendingReturnDetail.isProcessed) {
                        scannedItems.push({
                            bookSerial: pendingReturnDetail.bookSerial,
                            itemType: 'book',
                        });
                    } else {
                        expectedItems.push({
                            bookSerial: pendingReturnDetail.bookSerial,
                            itemType: 'book',
                        });
                    }
                } else {
                    unexpectedItems.push({
                        bookSerial: pendingReturnDetail.bookSerial,
                        itemType: 'book',
                    });
                }
            } else {
                let unexpectedTickets = [];
                let expectedTickets = [];
                let scannedTickets = [];

                pendingReturnDetail?.tickets.forEach((ticketDetail) => {
                    if (!ticketDetail.isUnexpected) {
                        if (ticketDetail.isProcessed) {
                            scannedTickets.push({
                                ticketId: ticketDetail.ticketId,
                                ticketNumber: ticketDetail.ticketNumber,
                            });
                        } else {
                            expectedTickets.push({
                                ticketId: ticketDetail.ticketId,
                                ticketNumber: ticketDetail.ticketNumber,
                            });
                        }
                    } else {
                        unexpectedTickets.push({
                            ticketId: ticketDetail.ticketId,
                            ticketNumber: ticketDetail.ticketNumber,
                        });
                    }
                });

                if (_.size(expectedTickets) > 0) {
                    expectedItems.push({
                        bookSerial: pendingReturnDetail.bookSerial,
                        itemType: 'ticket',
                        tickets: expectedTickets,
                    });
                }

                if (_.size(scannedTickets) > 0) {
                    scannedItems.push({
                        bookSerial: pendingReturnDetail.bookSerial,
                        itemType: 'ticket',
                        tickets: scannedTickets,
                    });
                }

                if (_.size(unexpectedTickets) > 0) {
                    unexpectedItems.push({
                        bookSerial: pendingReturnDetail.bookSerial,
                        itemType: 'ticket',
                        tickets: unexpectedTickets,
                    });
                }
            }
        });

        dispatch({
            type: PENDING_RETURNS__VIEW_RETURN_EXPECTED_ITEMS,
            payload: expectedItems,
        });

        dispatch({
            type: PENDING_RETURNS__VIEW_RETURN_SCANNED_ITEMS,
            payload: scannedItems,
        });

        dispatch({
            type: PENDING_RETURNS__VIEW_RETURN_UNEXPECTED_ITEMS,
            payload: unexpectedItems,
        });
    }
};

export const resetViewReturnModal = () => ({
    type: PENDING_RETURNS__VIEW_RETURN_RESET,
});

export const mapPendingReturnDetails = (pendingReturn) => (dispatch) => {
    let pendingReturns = [];

    if (
        pendingReturn.returnPosItems &&
        pendingReturn.returnPosItems.length > 0
    ) {
        _.forEach(pendingReturn.returnPosItems, (posItem) => {
            pendingReturns.push({
                bookSerial: `pos_${posItem.itemCode}`,
                itemCode: posItem.itemCode,
                itemQuantity: ~~posItem.itemQuantity,
                isProcessed: posItem.isProcessed,
                processedQuantity: ~~posItem.processedQuantity,
                isUnexpected: posItem.isUnexpected,
                itemType: 'ps',
            });
        });
    }

    _.forEach(pendingReturn.returnTickets, (pendingReturnDetail) => {
        if (pendingReturnDetail.ticket == null) {
            pendingReturns.push({
                bookSerial: pendingReturnDetail.book,
                itemType: 'book',
                isUnexpected: pendingReturnDetail.isUnexpected,
                isProcessed: pendingReturnDetail.isProcessed,
            });
        } else {
            let existingReturn = _.find(pendingReturns, (aReturn) => {
                return aReturn.bookSerial === pendingReturnDetail.book;
            });
            if (existingReturn) {
                existingReturn.tickets.push({
                    ticketId: pendingReturnDetail.ticket,
                    ticketNumber: pendingReturnDetail.ticket,
                    isUnexpected: pendingReturnDetail.isUnexpected,
                    isProcessed: pendingReturnDetail.isProcessed,
                });
            } else {
                let pendingReturnTickets = [];
                pendingReturnTickets.push({
                    ticketId: pendingReturnDetail.ticket,
                    ticketNumber: pendingReturnDetail.ticket,
                    isUnexpected: pendingReturnDetail.isUnexpected,
                    isProcessed: pendingReturnDetail.isProcessed,
                });
                pendingReturns.push({
                    bookSerial: pendingReturnDetail.book,
                    itemType: 'ticket',
                    tickets: pendingReturnTickets,
                });
            }
        }
    });

    return pendingReturns;
};
