import {
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_LOADING_DONE,
    FORGOT_PASSWORD_INIT,
    FORGOT_PASSWORD_CONTACT_INFO,
    FORGOT_PASSWORD_SHOW_NOTIFY,
    FORGOT_PASSWORD_HIDE_NOTIFY,
} from '../../actions';

const INITIAL_NOTIFICATION_STATE = {
    message: null,
    isError: false,
    show: false,
};

const INITIAL_STATE = {
    loading: false,
    contactInfo: null,
    notification: { ...INITIAL_NOTIFICATION_STATE },
};

const forgotPassword = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FORGOT_PASSWORD_HIDE_NOTIFY:
            return {
                ...state,
                notification: { ...INITIAL_NOTIFICATION_STATE },
            };

        case FORGOT_PASSWORD_SHOW_NOTIFY:
            return {
                ...state,
                notification: { ...payload, show: true },
            };

        case FORGOT_PASSWORD_CONTACT_INFO:
            return {
                ...state,
                contactInfo: { ...payload },
            };

        case FORGOT_PASSWORD_INIT:
            return { ...INITIAL_STATE };

        case FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                loading: true,
            };

        case FORGOT_PASSWORD_LOADING_DONE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
export default forgotPassword;
