export const NOTIFY_WARN = 'NOTIFY_WARN';
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const NOTIFY_CLEAR = 'NOTIFY_CLEAR';
export const NOTIFY_QUEUE_INFO = 'NOTIFY_QUEUE_INFO';
export const NOTIFY_MULTI_NOTIFICATION = 'NOTIFY_MULTI_NOTIFICATION';

export const success = ({ msg, target }) => ({
    type: NOTIFY_SUCCESS,
    msg,
    target,
});

export const warning = ({ msg, target }) => ({
    type: NOTIFY_WARN,
    msg,
    target,
});

export const error = ({ msg, target }) => ({
    type: NOTIFY_ERROR,
    msg,
    target,
});

export const queueInfo = ({ msg, target }) => ({
    type: NOTIFY_QUEUE_INFO,
    msg,
    target,
});

export const clearNotification = () => ({
    type: NOTIFY_CLEAR,
});

export const multiNotification = ({ msg, target }) => ({
    type: NOTIFY_MULTI_NOTIFICATION,
    msg,
    target,
});
