import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import Select from 'react-select';
import RFReactSelect from './form/rfReactSelect';
import UneditableLabel from './UneditableLabel';

class SfaSelect extends Component {
    state = {
        active: null,
    };

    componentWillMount() {
        this.setState({ active: this.props.value });
    }

    render() {
        const {
            labelText,
            placeholderText,
            fieldName,
            options,
            isMulti,
            isDisabled,
            validations,
            onChange,
            style,
            searchable,
            inline,
            ...otherProps
        } = this.props;
        return (
            <Form.Field inline={inline}>
                <div className="readonly">
                    <UneditableLabel text={labelText} />
                </div>
                <Field
                    as={Select}
                    multi={isMulti}
                    name={fieldName}
                    options={options}
                    component={RFReactSelect}
                    placeholder={placeholderText}
                    value={this.state.active}
                    isDisabled={isDisabled}
                    validate={validations}
                    searchable={searchable || false}
                    onChange={onChange}
                    className="sfa-input-control"
                    style={style}
                    {...otherProps}
                />
            </Form.Field>
        );
    }
}

export default SfaSelect;
