import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import _ from 'lodash';
import { Form, FormField } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPlanogram,
    clearPlanogram,
    savePlanogram,
    createEmptyPlanogram,
    deletePlanogram,
} from '../../redux/actions/planogram/planogram-actions';
import { downloadDocument, success } from '../../redux/actions';
import type { Planogram } from '../../DTOs/planogram';
import { clearNotification } from '../../redux/actions';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import SfaForm from '../../components/SfaForm';
import SfaInput from '../../components/SfaInput';
import UneditablePill from '../../components/UneditablePill';
import UneditableInput from '../../components/UneditableInput';
import OkCancelModal from '../../components/OkCancelModal';
import SfaSelect from '../../components/SfaSelect';
import { required } from '../../constants/validate';
import { find } from 'lodash/collection';
import { getRetailerGroupList } from '../../redux/actions/retailer-group/retailer-group-actions';
import type { RetailerGroup } from '../../DTOs/retailer-group';
import FileSelector from '../../components/FileSelector';
import SfaReadView from '../../components/SfaReadView';
import { bindActionCreators } from 'redux';
import ReduxFormCalendarPicker from '../../components/ReduxFormCalendarPicker';
import permissions from '../../constants/permissions';
import SfaReduxToggle from '../../components/sfaToggle/sfaReduxToggle';
import {
    dateFromDisplayDateFormatToServerDate,
    dateFromServerDateToDisplayDateFormat,
    getDisplayDateFormat,
} from '../../constants/utils';

let EditPlanogramPage = (props) => {
    const {
        history,
        match: {
            params: { id },
        },
        handleSubmit,
    } = props;
    const dispatch = useDispatch();
    const [isEditView, setIsEditView] = useState(!id);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    useEffect(() => {
        if (id) {
            dispatch(getPlanogram(id));
        } else {
            dispatch(createEmptyPlanogram());
        }
        dispatch(getRetailerGroupList());
        return () => {
            dispatch(clearNotification());
            dispatch(clearPlanogram());
        };
    }, [id, dispatch]);

    const planogram: Planogram = useSelector(
        (state) => state.editPlanogram.planogram
    );

    const retailerGroups: RetailerGroup[] = useSelector(
        (state) => state.retailerGroup.retailerGroupList
    );

    const loading: boolean = useSelector(
        (state) =>
            state.editPlanogram.isLoading || state.retailerGroup.isLoading
    );
    const toggleEditView = () => {
        id ? setIsEditView(!isEditView) : goBack();
    };

    const deletePlanogramAsync = async () => {
        const response = await dispatch(deletePlanogram(id));
        if (response) {
            updateSuccess('The planogram was successfully deleted');
            goBack();
        }
    };

    const save = async (values: Planogram) => {
        values.retailerGroup = find(
            retailerGroups,
            (retailerGroup) => retailerGroup.id === values.retailerGroupId
        );
        try {
            const response = await dispatch(
                savePlanogram(id, {
                    ...values,
                    startDate: dateFromDisplayDateFormatToServerDate(
                        values.formattedStartDate
                    ),
                    endDate: dateFromDisplayDateFormatToServerDate(
                        values.formattedEndDate
                    ),
                })
            );
            if (response) {
                updateSuccess(
                    `The planogram was successfully ${
                        !id ? 'saved' : 'updated'
                    }.`
                );
                goBack();
            }
        } catch (err) {
            setIsShowConfirmation(false);
        }
    };

    const download = (urlPath) => {
        console.debug(`received urlPath ${urlPath}`);

        //need to strip out the beginning '/' if exists
        const formattedUrlPath =
            urlPath[0] === '/' ? urlPath.substr(1) : urlPath;

        console.debug(`downloading file for path ${formattedUrlPath}`);
        dispatch(downloadDocument(formattedUrlPath));
    };
    const goBack = () => {
        history.goBack();
    };
    const toggleConfirmationDialog = () => {
        setIsShowConfirmation(!isShowConfirmation);
    };

    const updateSuccess = (msg) => {
        // the timeout helps in delaying the propagation so that the
        // componentWillUnmount does not clear it out
        setTimeout(() => {
            dispatch(
                success({
                    msg: msg,
                    target: 'PlanogramListPage',
                })
            );
        }, 100);
    };

    return (
        <Page name="EditPlanogramPage" data-test="EditPlanogramPage">
            <SfaBreadCrumb
                onBreadCrumb={goBack}
                breadCrumbText="Planograms"
                animation="fade right"
            />
            <SfaForm
                name="UpdatePlanogram"
                loading={loading}
                showEdit={!isEditView}
                showSaveCancel={isEditView}
                onEdit={toggleEditView}
                onDelete={deletePlanogramAsync}
                showDelete={!isEditView}
                editButtonRoles={[permissions.ROLE_PLANOGRAM_UPDATE]}
                deleteButtonRoles={[permissions.ROLE_PLANOGRAM_DELETE]}
                onCancel={goBack}
                headerText={
                    id
                        ? (isEditView ? `Update` : '') + ` ${planogram?.name}`
                        : 'Create Planogram'
                }
                onSubmit={handleSubmit(toggleConfirmationDialog)}
                animation="fade left"
            >
                <Form.Field>
                    <OkCancelModal
                        header={id ? `Update Planogram` : `Create Planogram`}
                        body={`Are you sure you want to save the planogram?`}
                        cancelFunction={toggleConfirmationDialog}
                        okFunction={handleSubmit(save)}
                        isOpen={isShowConfirmation}
                        okText={'Save'}
                    />

                    {isEditView && (
                        <>
                            {!id && (
                                <Form.Group widths="equal">
                                    <FormField>
                                        <Field
                                            component={FileSelector}
                                            type="file"
                                            name="file"
                                            labelText="File *"
                                            accept="application/pdf"
                                            helpText="File types allowed: .pdf  Maximum file size: 10MB"
                                        />
                                    </FormField>
                                </Form.Group>
                            )}
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Planogram Name *'}
                                        inputName={'name'}
                                        validations={required}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaInput
                                        label={'Description *'}
                                        inputName={'description'}
                                        validations={required}
                                        helpText="Max 100 characters"
                                        maxLength={100}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="two">
                                <Form.Field>
                                    <ReduxFormCalendarPicker
                                        name="formattedStartDate"
                                        labelText="Start Date *"
                                        validate={required}
                                        change={props.change}
                                        format={getDisplayDateFormat()}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <ReduxFormCalendarPicker
                                        name="formattedEndDate"
                                        labelText="End Date *"
                                        change={props.change}
                                        validate={required}
                                        format={getDisplayDateFormat()}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <SfaSelect
                                        labelText={'Group Name *'}
                                        fieldName={'retailerGroupId'}
                                        options={_.map(
                                            retailerGroups,
                                            (retailerGroup) => ({
                                                label: retailerGroup.name,
                                                value: retailerGroup.id,
                                            })
                                        )}
                                        validation={required}
                                    ></SfaSelect>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="two">
                                <Form.Field>
                                    <SfaReduxToggle
                                        toggleName={'isActive'}
                                        labelText="Status *"
                                        activeLabel={'ACTIVE'}
                                        inactiveLabel={'INACTIVE'}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                    )}
                    {!isEditView && (
                        <>
                            <SfaReadView
                                elements={[
                                    {
                                        label: 'File',
                                        value: 'Download',
                                        className: 'button',
                                        callback: () =>
                                            download(planogram?.mediaKey),
                                    },
                                ]}
                            />

                            <UneditableInput
                                label="Planogram Name"
                                data={planogram?.name}
                            />
                            <UneditableInput
                                label="Description"
                                data={planogram?.description}
                            />
                            <Form.Group widths={2}>
                                <UneditableInput
                                    label="Start Date"
                                    data={planogram?.formattedStartDate}
                                />
                                <UneditableInput
                                    label="End Date"
                                    data={planogram?.formattedEndDate}
                                />
                            </Form.Group>
                            <UneditablePill
                                label="Group Name"
                                data={planogram?.retailerGroup?.name}
                            />
                            <UneditablePill
                                label="Status"
                                data={
                                    planogram?.isActive ? 'ACTIVE' : 'INACTIVE'
                                }
                            />
                        </>
                    )}
                </Form.Field>
            </SfaForm>
        </Page>
    );
};
const validate = (values: Planogram) => {
    const error = {};
    if (!values.file || values.file.length === 0) {
        error.file = 'Required';
    }

    if (
        values.file &&
        values.file.length === 1 &&
        values.file[0].type !== 'application/pdf'
    ) {
        error.file = 'File type not supported.';
    }
    if (
        values.file &&
        values.file.length === 1 &&
        values.file[0].size > 10 * 1024 * 1024
    ) {
        error.file = 'Maximum file size exceeded.';
    }
    if (!values.name) {
        error.name = 'Required';
    }
    if (!values.description) {
        error.description = 'Required';
    }
    if (values.description > 100) {
        error.description =
            'The max length should not be greater than 100 characters.';
    }
    if (!values.formattedStartDate) {
        error.formattedStartDate = 'Required';
    }
    if (!values.formattedEndDate) {
        error.formattedEndDate = 'Required';
    }

    if (values.formattedStartDate > values.formattedEndDate) {
        error.formattedEndDate = 'End date should not be less than Start date';
    }
    if (!values.retailerGroupId) {
        error.retailerGroupId = 'Required';
    }
    return error;
};
EditPlanogramPage = reduxForm({
    form: 'EditPlanogramPage',
    validate,
    enableReinitialize: true,
    pure: true,
})(EditPlanogramPage);
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
};
EditPlanogramPage = connect((state) => {
    const initialValues = state.editPlanogram.planogram;
    if (initialValues) {
        initialValues.retailerGroupId = initialValues?.retailerGroup?.isActive
            ? initialValues?.retailerGroup?.id
            : undefined;

        initialValues.formattedStartDate =
            dateFromServerDateToDisplayDateFormat(initialValues.startDate);
        initialValues.formattedEndDate = dateFromServerDateToDisplayDateFormat(
            initialValues.endDate
        );
    }
    return {
        initialValues: initialValues,
    };
}, mapDispatchToProps)(EditPlanogramPage);

export default EditPlanogramPage;
