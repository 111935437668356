import { UserRetailer } from '../../../DTOs/user-retailer';
import {
    UPDATE_USER_RETAILERS,
    CHANGE_ACTIVE_USER_RETAILERS,
    TOGGLE_SELECT_RETAILER_MODAL,
} from '../../type/user-retailers/user-reatlers';

import UserPreferenceApi from '../../../services/user-preference-service';
import { UserPreference } from '../../../DTOs/user-preference';
import { LAST_ACTIVE_RETAILER } from '../../../constants/user-preference-key';
import { UserApi } from '../../../services/user-service';

const userPreferenceApi = new UserPreferenceApi();
export const changeUserRetailer = (activeRetailer) => {
    return async (dispatch) => {
        await setUserRetailer(activeRetailer, dispatch);
    };
};
export const toggleSelectRetailerModal = () => {
    return async (dispatch, getState) => {
        let {
            userRetailer: { isShowSelectRetailerModal, userRetailer },
        } = getState();

        if (userRetailer && userRetailer.length > 1) {
            dispatch({
                type: TOGGLE_SELECT_RETAILER_MODAL,
                payload: !isShowSelectRetailerModal,
            });
        }
    };
};
const setUserRetailer = async (activeRetailer, dispatch) => {
    let userPreference = {
        userPreferenceId: {
            key: LAST_ACTIVE_RETAILER,
        },
        value: activeRetailer.userRetailerId.retailerReference,
    };
    await userPreferenceApi.saveUserPreferenceByKey(userPreference);
    dispatch({
        type: CHANGE_ACTIVE_USER_RETAILERS,
        payload: activeRetailer,
    });
};

export const setUserRetailers = (userId) => {
    return async (dispatch, getState) => {
        // this initialized here beacaouse this trigger on page load and userApi is not intilized if we used out of this method.
        const userApi = new UserApi();
        const userRetailers =
            await userApi.getUserRetailersByUsernameOrId(userId);
        dispatch({
            type: UPDATE_USER_RETAILERS,
            payload: userRetailers,
        });
        let {
            userRetailer: { activeRetailer },
        } = getState();

        if (userRetailers && userRetailers.length > 0) {
            let userPreference;
            try {
                userPreference =
                    await userPreferenceApi.getUserPreferenceByKey(
                        LAST_ACTIVE_RETAILER
                    );
                activeRetailer = userRetailers.find(
                    (ur) =>
                        ur.userRetailerId.retailerReference ===
                        userPreference.value,
                );
            } catch (e) {}
            if (!activeRetailer) {
                activeRetailer = userRetailers[0];
            }
            await setUserRetailer(activeRetailer, dispatch);
        }
    };
};
