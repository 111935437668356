import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PendingReturnItem from './PendingReturnItem';

class PendingReturnItemsList extends Component {
    handleRemoveItemClicked = (type, data, returnItem) => {
        if (type === 'book') {
            this.props.removeBookFromReturnItems(data.bookSerial, returnItem);
        } else if (type === 'ps') {
            this.props.removePosItemFromReturnItems(data.itemCode);
        } else {
            this.props.removeTicketFromReturnItems(
                data.bookSerial,
                data.ticketId,
                returnItem
            );
        }
    };

    render() {
        const { returnItems, readOnly } = this.props;
        let listItems = [];

        _.forEach(returnItems, (returnItem) => {
            listItems.push(
                <PendingReturnItem
                    bookSerial={returnItem.bookSerial}
                    posItem={returnItem.itemId}
                    key={returnItem.bookSerial}
                    returnItem={returnItem}
                    onRemoveItemClicked={this.handleRemoveItemClicked}
                    readOnly={readOnly}
                />
            );
        });

        return <div>{listItems}</div>;
    }
}

PendingReturnItemsList.propTypes = {
    returnItems: PropTypes.array.isRequired,
    removeBookFromReturnItems: PropTypes.func,
    removeTicketFromReturnItems: PropTypes.func,
    removePosItemFromReturnItems: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default PendingReturnItemsList;
