export const ONETIME_TOGGLE_POPUP = 'ONETIME_TOGGLE_POPUP';
export const ONETIME_DISABLE_POPUP = 'ONETIME_DISABLE_POPUP';

export const togglePopup = (id, val) => ({
    type: ONETIME_TOGGLE_POPUP,
    isPopupOpen: val,
    id,
});

export const disablePopup = (popupName) => ({
    type: ONETIME_DISABLE_POPUP,
    popupName,
});
