import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilterSection from '../../../components/filter/FilterSection';
import { generateReport, error } from '../../../redux/actions';
import { formatToServerDate } from '../../../constants/utils';

class DateRangeFilter extends Component {
    onGenerateReport = (selectedItems) => {
        const { userInfo, retailerInfo, reportId, notifyError, maxRangeDays } =
            this.props;

        const startDate = selectedItems.startDate[0];
        const endDate = selectedItems.endDate[0];

        if (moment(startDate).isAfter(moment(endDate))) {
            notifyError('Start Date cannot be after End Date');
            return;
        }

        if (
            maxRangeDays &&
            moment(startDate).add(maxRangeDays, 'd').isBefore(moment(endDate))
        ) {
            notifyError(
                `Start Date and End Date cannot be longer than ${maxRangeDays} days apart from each other`
            );
            return;
        }
        this.props.generateReport({
            id: reportId,
            userFullName: userInfo.full_name,
            userName: userInfo.user_name,
            retailerReference: retailerInfo.reference,
            retailerDisplayName: `${retailerInfo.reference} - ${retailerInfo.name}`,
            startDate: formatToServerDate(selectedItems.startDate[0]),
            endDate: formatToServerDate(selectedItems.endDate[0]),
        });
    };

    render() {
        const { startDateLabel, endDateLabel, selectedItems, maxRangeDays } =
            this.props;
        const todayMoment = moment();

        const selectedStartDate =
            selectedItems.startDate && moment(selectedItems.startDate[0]);
        const selectedEndDate =
            selectedItems.endDate && moment(selectedItems.endDate[0]);

        const endDateProps = {
            label: `${endDateLabel || 'End Date'}: `,
            name: 'endDate',
            isDate: true,
            minDate: moment.min(selectedStartDate, todayMoment),
            defaultSelected: todayMoment,
        };

        if (maxRangeDays && selectedStartDate) {
            const maxDateSelectedStartDate = selectedStartDate.clone();

            maxDateSelectedStartDate.add(maxRangeDays, 'd');

            endDateProps.maxDate = moment.min(
                maxDateSelectedStartDate,
                todayMoment
            );
        }

        return (
            <FilterSection
                filters={[
                    {
                        label: `${startDateLabel || 'Start Date'}: `,
                        name: 'startDate',
                        isDate: true,
                        maxDate: maxRangeDays
                            ? todayMoment
                            : moment.min(selectedEndDate, todayMoment),
                        defaultSelected: todayMoment,
                    },
                    endDateProps,
                ]}
                onApplyFilter={this.onGenerateReport}
                applyButtonText="Generate Report"
                resetButtonText="Reset Criteria"
                showClearFilter={false}
                showResetFilter={true}
                mustSelectAllFilters={true}
            />
        );
    }
}

const mapStateToProps = ({
    auth,
    tableFilter: { selectedItems },
    userInfo: { retailerInfo },
}) => ({
    userInfo: auth.info,
    selectedItems,
    retailerInfo,
});

const mapDispatchToProps = (dispatch) => {
    return {
        generateReport: (args) => dispatch(generateReport(args)),
        notifyError: (msg) => dispatch(error({ msg })),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DateRangeFilter)
);
