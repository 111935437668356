import {
    CORP_ACCOUNT_LOADING,
    CORP_ACCOUNT_LOADED,
    CORP_ACCOUNT_INIT_STATE,
    CORP_ACCOUNT_RETAILER_LOADING,
    CORP_ACCOUNT_RETAILER,
} from '../actions/readCorpAccount';

const INITIAL_STATE = {
    content: [],
    loading: false,
    phoneFaxMask: {},
    retailerInfo: [],
};

const readCorpAccount = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CORP_ACCOUNT_LOADING:
            return { ...state, loading: true };
        case CORP_ACCOUNT_LOADED:
            return {
                ...state,
                ...action,
                phoneFaxMask: action.phoneFaxMask,
                loading: false,
            };
        case CORP_ACCOUNT_RETAILER_LOADING:
            return { ...state, loading: true };
        case CORP_ACCOUNT_RETAILER:
            return {
                ...state,
                ...action,
                retailerInfo: action.retailerInfo,
                loading: false,
            };
        case CORP_ACCOUNT_INIT_STATE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
export default readCorpAccount;
