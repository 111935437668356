import { checkFilters, getMimeType } from './utils';
import { Backend } from './backend';
import { forEach } from 'lodash';

class MessagesApi {
    constructor() {
        this.path = '/media/engagement/messages/documents';
    }

    async downloadDocument(mediaKey, fileName) {
        try {
            //mediaKey should always have the file extension
            const fileType = getMimeType(mediaKey);

            return await Backend.download(
                {
                    method: 'GET',
                    url: `${this.path}/?key=${mediaKey}`,
                },
                fileName,
                fileType
            );
        } catch (e) {
            throw e;
        }
    }

    async getDownloadUrl(mediaKey, fileName) {
        try {
            //mediaKey should always have the file extension
            const fileType = getMimeType(mediaKey);

            return await Backend.getDownloadUrl(
                {
                    method: 'GET',
                    url: `${this.path}/?key=${mediaKey}`,
                },
                fileName,
                fileType
            );
        } catch (e) {
            throw e;
        }
    }

    async uploadPortalDocument(
        files,
        startDate,
        endDate,
        message,
        retailerTypes = []
    ) {
        try {
            let formData = new FormData();
            [].forEach.call(files, (file) => {
                formData.append('file', file);
            });

            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('description', message);
            formData.append('retailerTypes', retailerTypes);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}`,
                body: formData,
                contentType: 'multipart/form-data',
            });
        } catch (e) {
            throw e;
        }
    }

    async updateDocument(
        files,
        fileRemoveArray,
        selectedDocument,
        startDate,
        endDate,
        message,
        retailerTypes = []
    ) {
        try {
            let formData = new FormData();

            forEach(files, (file) => formData.append('file', file));

            formData.append('messageId', selectedDocument.id);
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('description', message);
            formData.append('fileRemoveArray', fileRemoveArray);
            formData.append('retailerTypes', retailerTypes);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/updateMessage`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async getMessageFiles(fileRemoveArray, messageId) {
        try {
            let formData = new FormData();

            formData.append('messageId', messageId);
            formData.append('fileRemoveArray', fileRemoveArray);

            return await Backend.send({
                method: 'POST',
                url: `${this.path}/getMessages`,
                body: formData,
            });
        } catch (e) {
            throw e;
        }
    }

    async deleteDocument(documentId) {
        try {
            return await Backend.send({
                method: 'DELETE',
                url: `${this.path}/${documentId}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllDocuments(args) {
        const { page, size, sortOrder, sortColumn, filters, query } = args;
        try {
            return await Backend.send({
                method: 'POST',
                url: `${this.path}/search`,
                body: {
                    page,
                    size: size < 1 ? 10 : size,
                    sortOrder: sortOrder ? sortOrder.toUpperCase() : 'DESC',
                    sortColumn: sortColumn || 'startDate',
                    filters: checkFilters(filters),
                    query,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllActiveMessageFiles() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/getAllActiveMessageFiles`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getActiveMessageFilesForRetailerType(retailerType) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/get-active-messages-for-retailer-types/${retailerType}`,
            });
        } catch (e) {
            throw e;
        }
    }

    async getAllMessageFile(messageId) {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/getMessageAllFiles/${messageId}`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { MessagesApi };
