import { Backend } from './backend';

class POSItemsApi {
    constructor() {
        this.path = '/retailers/posItem';
    }

    async getAllPOSItems() {
        try {
            return await Backend.send({
                method: 'GET',
                url: `${this.path}/getAllPOSItems`,
            });
        } catch (e) {
            throw e;
        }
    }
}

export { POSItemsApi };
