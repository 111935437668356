import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { HotKeys } from 'react-hotkeys';
import { getPrivacyPolicyHtml, initPrivacyPolicy } from '../../redux/actions';
import Page from '../../layout/Page';

class PrivacyPolicyPage extends Component {
    componentDidMount() {
        this.props.getData();
        this._container.focus();
    }

    componentWillUnmount() {
        this.props.init();
    }

    render() {
        const { loading, html } = this.props;
        return (
            <HotKeys innerRef={(c) => (this._container = c)}>
                <Page name="PrivacyPolicy" title="Privacy Policy">
                    <Dimmer page inverted active={loading}>
                        <Loader />
                    </Dimmer>
                    <div
                        className="data-section"
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                    />
                </Page>
            </HotKeys>
        );
    }
}

const mapStateToProps = ({ privacyPolicy }) => ({ ...privacyPolicy });

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => dispatch(getPrivacyPolicyHtml()),
        init: () => dispatch(initPrivacyPolicy()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPage)
);
