import React from 'react';
import { Icon } from 'semantic-ui-react';

const Status = ({ startDate, endDate, text, icon }) => {
    const currentDate = new Date().toISOString().slice(0, 10);

    if (currentDate >= startDate && currentDate <= endDate) {
        return (
            <>
                {' '}
                {icon && (
                    <Icon className={'icon light-green'} name={'circle'} />
                )}
                {text && 'Current'}
            </>
        );
    } else if (currentDate > endDate) {
        return (
            <>
                {' '}
                {icon && <Icon className={'icon red'} name={'circle'} />}
                {text && 'Previous'}
            </>
        );
    } else if (currentDate < startDate) {
        return (
            <>
                {' '}
                {icon && <Icon className={'icon yellow'} name={'circle'} />}
                {text && 'Upcoming'}
            </>
        );
    }
    return '';
};

export default Status;
