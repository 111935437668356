import React from 'react';
import Pill from '../pill/Pill';
import UneditableLabel from '../UneditableLabel';
import { Form } from 'semantic-ui-react';
import './styles.css';

const PillList = ({
    list,
    label,
    displayAttr,
    handleClick,
    displayFunction,
}) => (
    <Form.Field className="readonly">
        <UneditableLabel text={label} />
        {list.map((i) => (
            <div
                key={i.id}
                className={`pill-list-item-wrapper ${
                    handleClick ? 'pill-list-clickable' : ''
                }`}
            >
                {handleClick ? (
                    <Pill
                        text={
                            displayAttr
                                ? i[displayAttr]
                                : displayFunction
                                ? displayFunction(i)
                                : i.name
                        }
                        onClick={() => handleClick(i.id)}
                    />
                ) : (
                    <Pill
                        text={
                            displayAttr
                                ? i[displayAttr]
                                : displayFunction
                                ? displayFunction(i)
                                : i.name
                        }
                    />
                )}
            </div>
        ))}
    </Form.Field>
);

export default PillList;
