import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Header, Loader } from 'semantic-ui-react';

import Page from '../../layout/Page';
import SfaBreadCrumb from '../../components/SfaBreadCrumb';
import ReportDataSection from './components/ReportDataSection';
import config from '../../constants/config';
import { getReport, initReportDetails } from '../../redux/actions';
import ReportFilter from './components/ReportFilter';
import { userHasPermission } from '../../constants/utils';

const { REPORTS, UNAUTHORIZED } = config.ROUTE_URLS;
const PAGE_NAME = 'ReportPage';

export class ReportPage extends Component {
    async componentDidMount() {
        const id = this.props.match.params.id;
        await this.props.getReport(id);
    }

    componentWillUnmount() {
        this.props.init();
    }

    backToListPage = () => {
        this.props.history.push(REPORTS);
    };

    render() {
        const { match, generating, loading, currentReport } = this.props;
        if (currentReport.role && !userHasPermission(currentReport.role)) {
            this.props.history.push(UNAUTHORIZED);
        }
        const name = match.params.name;
        const id = match.params.id;
        const displayName =
            currentReport && currentReport.displayName
                ? currentReport.displayName
                : '';

        return (
            <Page name={PAGE_NAME} data-test="reportPageWrapper">
                <SfaBreadCrumb
                    onBreadCrumb={this.backToListPage}
                    breadCrumbText={'Reports'}
                    animation="fade right"
                />
                <div>
                    <Header className="subheader-text">{displayName}</Header>
                    <ReportFilter name={name} reportId={id} />
                    <ReportDataSection generating={generating} />
                </div>
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>
            </Page>
        );
    }
}

const mapStateToProps = ({ generateReport, auth }) => {
    const { generating, loading, currentReport } = generateReport;
    return { generating, loading, currentReport };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReport: (id) => dispatch(getReport(id)),
        init: () => dispatch(initReportDetails()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportPage)
);
