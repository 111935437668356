import React, { Component } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import _ from 'lodash';

const ITEMS_TO_SHOW = 5;

class Pagination extends Component {
    nextSet = () => {
        this.onEllipsesClick(1);
    };

    previousSet = () => {
        this.onEllipsesClick(-1);
    };

    onEllipsesClick = (dir) => {
        const { items, activePage, totalPages } = this.props;
        const itemsToShow = items || ITEMS_TO_SHOW;

        let newValue;
        let tracker;
        if (dir < 0) {
            tracker = _.floor((activePage - itemsToShow) / itemsToShow);
            // const temp = (tracker * itemsToShow) + 1;
            tracker = activePage % itemsToShow === 0 ? tracker : tracker + 1;
            newValue = tracker * itemsToShow;
        } else {
            tracker = _.floor((activePage + itemsToShow) / itemsToShow);
            const temp =
                activePage % itemsToShow === 0
                    ? tracker * itemsToShow
                    : tracker * itemsToShow + 1;
            newValue = temp >= totalPages ? totalPages : temp;
        }

        this.props.onPageChanged(newValue);
    };

    generatePageNumbers = (itemsToShow, activePage, totalPages) => {
        if (totalPages <= itemsToShow) {
            // show all page numbers
            return _.range(1, totalPages + 1);
        }

        const remainder = activePage % itemsToShow;
        let page = _.floor(activePage / itemsToShow);
        page = remainder > 0 ? page + 1 : page;

        let end = page * itemsToShow;
        const start = end - itemsToShow + 1;

        let numbers = _.range(start, _.min([end + 1, totalPages + 1]));

        if (start > 1) {
            numbers.unshift('ellipsis-');
        }

        if (!_.find(numbers, (n) => n === totalPages)) {
            numbers.push('ellipsis+');
            numbers.push(totalPages);
        }

        return numbers;
    };

    renderItems() {
        const { totalPages, activePage, items, onPageChanged } = this.props;

        const pageNumbers = this.generatePageNumbers(
            items || ITEMS_TO_SHOW,
            activePage,
            totalPages
        );

        return _.map(pageNumbers, (value, index) => {
            if (value === 'ellipsis+') {
                return (
                    <Button
                        className="paging-button numbering transparent-btn"
                        basic
                        key={`ellipsis${index}`}
                        name={`ellipsis${index}`}
                        size="mini"
                        disabled={value === activePage}
                        onClick={this.nextSet}
                    >
                        <span className="body-text bold">...</span>
                    </Button>
                );
            } else if (value === 'ellipsis-') {
                return (
                    <Button
                        className="paging-button numbering transparent-btn"
                        basic
                        key={`ellipsis${index}`}
                        name={`ellipsis${index}`}
                        size="mini"
                        disabled={value === activePage}
                        onClick={this.previousSet}
                    >
                        <span className="body-text bold">...</span>
                    </Button>
                );
            }

            return (
                <Button
                    className="paging-button numbering transparent-btn"
                    basic
                    size="mini"
                    key={value}
                    name={`${value}`}
                    disabled={value === activePage}
                    onClick={(e) => onPageChanged(value, e)}
                >
                    <span className="body-text bold">{value}</span>
                </Button>
            );
        });
    }

    render() {
        const {
            totalPages,
            activePage,
            prev,
            next,
            first,
            last,
            onPageChanged,
        } = this.props;
        const previousPage = activePage === 1 ? 1 : activePage - 1;
        const nextPage =
            activePage === totalPages ? totalPages : activePage + 1;
        let menuItems = [];

        if (first) {
            menuItems.push(
                <Button
                    key="first"
                    className="paging-button ending transparent-btn"
                    basic
                    size="mini"
                    disabled={activePage === 1}
                    onClick={(e) => onPageChanged(1, e)}
                >
                    <span className="body-text">&lt;&lt; First</span>
                </Button>
            );
        }

        if (prev) {
            menuItems.push(
                <Button
                    key="prev"
                    size="mini"
                    className="white"
                    circular
                    disabled={activePage === 1}
                    onClick={(e) => onPageChanged(previousPage, e)}
                >
                    <span className="icon-Arrow-Left" />
                </Button>
            );
        }

        const numberedItems = this.renderItems();

        menuItems = [...menuItems, ...numberedItems];

        if (next) {
            menuItems.push(
                <Button
                    key="next"
                    size="mini"
                    className="white"
                    circular
                    disabled={activePage === totalPages}
                    onClick={(e) => onPageChanged(nextPage, e)}
                >
                    <span className="icon-Arrow-Right" />
                </Button>
            );
        }

        if (last) {
            menuItems.push(
                <Button
                    key="last"
                    className="paging-button ending transparent-btn"
                    basic
                    size="mini"
                    disabled={activePage === totalPages}
                    onClick={(e) => onPageChanged(totalPages, e)}
                >
                    <span className="body-text">Last >></span>
                </Button>
            );
        }

        return (
            <div style={{ width: '100%' }}>
                <div className="max-mobile">
                    <div className="pagination">
                        <Button
                            size="small"
                            className="white"
                            circular
                            disabled={activePage === 1}
                            onClick={(e) => onPageChanged(previousPage, e)}
                        >
                            <span className="icon-Arrow-Left" />
                        </Button>
                        <span className="body-text bold">
                            {activePage} of {totalPages}
                        </span>
                        <Button
                            size="small"
                            className="white"
                            circular
                            disabled={activePage === totalPages}
                            onClick={(e) => onPageChanged(nextPage, e)}
                        >
                            <span className="icon-Arrow-Right" />
                        </Button>
                    </div>
                    <Button
                        className="paging-button transparent-btn"
                        basic
                        fluid
                        size="medium"
                        disabled={activePage === 1}
                        onClick={(e) => onPageChanged(1, e)}
                    >
                        <Icon name="angle double left" /> First
                    </Button>
                    <Button
                        className="paging-button transparent-btn"
                        basic
                        fluid
                        size="medium"
                        disabled={activePage === totalPages}
                        onClick={(e) => onPageChanged(totalPages, e)}
                    >
                        Last <Icon name="angle double right" />
                    </Button>
                </div>
                <div className="min-tablet">
                    <div className="pagination">{menuItems}</div>
                </div>
            </div>
        );
    }
}

export default Pagination;
