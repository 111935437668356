import moment from 'moment';
import _ from 'lodash';

import { getErrorMessage, getErrorStatus } from '../../constants/utils';
import { error } from './notifications';
import { errorHandler } from './errorHandler/errorHandler';
import { RetailersApi } from '../../services/retailers-service';
import { TaskApi } from '../../services/task-service';
import { SurveyApi } from '../../services/survey-service';
import { TaskComposerApi } from '../../services/task-composer-service';

export const WEEKLY_SCHEDULE_INIT = 'WEEKLY_SCHEDULE_INIT';
export const WEEKLY_SCHEDULE_RETAILERS_LIST = 'WEEKLY_SCHEDULE_RETAILERS_LIST';
export const WEEKLY_SCHEDULE_ADD_RETAILERS_LIST =
    'WEEKLY_SCHEDULE_ADD_RETAILERS_LIST';
export const WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST =
    'WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST';
export const WEEKLY_SCHEDULE_LOADING = 'WEEKLY_SCHEDULE_LOADING';
export const WEEKLY_SCHEDULE_DONE = 'WEEKLY_SCHEDULE_DONE';
export const WEEKLY_SCHEDULE_CURRENT_WEEK = 'WEEKLY_SCHEDULE_CURRENT_WEEK';
export const WEEKLY_SCHEDULE_CURRENT_WEEK_CLEAR =
    'WEEKLY_SCHEDULE_CURRENT_WEEK_CLEAR';

const retailersApi = new RetailersApi();
const taskApi = new TaskApi();
const surveyApi = new SurveyApi();
const taskComposerApi = new TaskComposerApi();

const weeklyScheduleError = (errors) => {
    console.error(errors);
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'WeeklyScheduler' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    console.error(errors);
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const clearWeeklySchedulerFirstDayOfWeek = () => ({
    type: WEEKLY_SCHEDULE_CURRENT_WEEK_CLEAR,
});

export const setWeeklySchedulerFirstDayOfWeek = (firstDayOfWeek) => ({
    type: WEEKLY_SCHEDULE_CURRENT_WEEK,
    firstDayOfWeek,
});

export const getRetailersForWeek = (week, otherUser) => {
    return async (dispatch, getState) => {
        try {
            const scheduled = [];
            const unscheduled = [];

            dispatch({ type: WEEKLY_SCHEDULE_LOADING });

            let myRetailerTasks = null;
            let allAdhocTasksForWeek = null;
            if (otherUser) {
                await taskComposerApi.generateUTRs(week, otherUser.id);
                const taskForWeekResult = await Promise.all([
                    taskApi.getAllRetailerTasksForWeek(week, otherUser.id),
                    taskApi.getAdHocTasksForWeek(week, otherUser.id),
                ]);
                myRetailerTasks = taskForWeekResult[0];
                allAdhocTasksForWeek = taskForWeekResult[1];
            } else {
                const { auth } = getState();
                await taskComposerApi.generateUTRs(week, auth.info.user_id);
                const taskForWeekResult = await Promise.all([
                    taskApi.getAllMyRetailerTasksForWeek(week),
                    taskApi.getMyAdHocTasksForWeek(week),
                ]);
                myRetailerTasks = taskForWeekResult[0];
                allAdhocTasksForWeek = taskForWeekResult[1];
            }

            //get above surveys for reducer use
            const getSurveyForTasksResult = await Promise.all([
                _getSurveyForTasks(myRetailerTasks),
                _getSurveyForTasks(allAdhocTasksForWeek),
            ]);
            myRetailerTasks = getSurveyForTasksResult[0];
            allAdhocTasksForWeek = getSurveyForTasksResult[1];

            // find retailers for tasks
            const scheduledRetailersForWeek = _.uniq(
                myRetailerTasks.map((task) => task.retailerId)
            );
            const adhocRetailersForWeek = _.uniq(
                allAdhocTasksForWeek.map((task) => task.retailerId)
            );
            const retailersForWeek = _.compact(
                _.uniq([...scheduledRetailersForWeek, ...adhocRetailersForWeek])
            );

            // const selectedBusinessWeek = await organizationApi.getBusinessWeekFromDate(week);
            //retailers include the tier and frequency already
            let retailerFound =
                (await retailersApi.getRetailersByIds(retailersForWeek)) || [];
            // retailerFound = _.filter(retailerFound, ({tierFrequency, cycleWeek}) =>((tierFrequency === 1 && cycleWeek === 1)
            //     || selectedBusinessWeek % tierFrequency === cycleWeek % tierFrequency));

            const retailers = _.map(retailerFound, (retailer) => ({
                ...retailer,
                frequency: retailer.tierFrequency,
            }));

            // group by retailer
            retailers.forEach((retailer) => {
                retailer.tasks = myRetailerTasks.filter(
                    (task) => task.retailerId === retailer.id
                );
            });

            // set retailer schedule date if all tasks for retailer are scheduled for the same date
            retailers.forEach((retailer) => {
                const retailerAdhocs = allAdhocTasksForWeek.filter(
                    (task) =>
                        task.scheduleType === 'ONE_TIME' &&
                        task.retailerId &&
                        task.retailerId === retailer.id
                );

                //if frequency is 0 then is in tier 0 and should not have scheduled tasks
                //TODO: should not generate scheduled UTRs for tier 0 retailers
                if (retailer.frequency === 0) {
                    retailer.tasks = [];
                }

                //adhoc tasks are caught in the else
                if (retailer.tasks && retailer.tasks.length > 0) {
                    const isScheduled = retailer.tasks.filter(
                        (task) => task.dueDate
                    );
                    const isNotScheduled = retailer.tasks.filter(
                        (task) => !task.dueDate
                    );

                    const retailerVisitByDate = {};

                    if (isScheduled.length > 0) {
                        const groupByDate = _.groupBy(isScheduled, 'dueDate');
                        _.each(_.keys(groupByDate), (date) => {
                            const tasks = groupByDate[date];

                            // determine retailer visit order (sortOrder)
                            const retailerTasksForTodayWithSortOrder =
                                retailer.tasks.filter(
                                    (task) =>
                                        task.sortOrder != null &&
                                        task.dueDate === date
                                );
                            const retailerAdhocsForTodayWithSortOrder =
                                retailerAdhocs.filter(
                                    (task) =>
                                        task.sortOrder != null &&
                                        task.dueDate === date
                                );
                            const tasksForTodayWithSortOrder =
                                retailerTasksForTodayWithSortOrder.concat(
                                    retailerAdhocsForTodayWithSortOrder
                                );
                            const visitOrder =
                                tasksForTodayWithSortOrder.length > 0
                                    ? tasksForTodayWithSortOrder[0].sortOrder
                                    : 1; //_.sortBy(tasksForTodayWithSortOrder, (task) => task.sortOrder)[Math.floor(tasksForTodayWithSortOrder.length / 2)].sortOrder : 1;

                            retailerVisitByDate[date] = {
                                ...retailer,
                                tasks,
                                highPriorityTasks: tasks.filter(
                                    (task) => task.priority === 'HIGH'
                                ).length,
                                adhocTasks: [],
                                schedule: date,
                                visitOrder,
                            };
                        });
                    }

                    if (retailerAdhocs.length > 0) {
                        const groupAdHocsByDate = _.groupBy(
                            retailerAdhocs,
                            'dueDate'
                        );
                        _.each(_.keys(groupAdHocsByDate), (date) => {
                            const adhocTasks = groupAdHocsByDate[date];
                            const existngTasks =
                                retailerVisitByDate[date] || {};

                            // determine retailer visit order (sortOrder)
                            const retailerTasksForTodayWithSortOrder =
                                retailer.tasks.filter(
                                    (task) =>
                                        task.sortOrder != null &&
                                        task.dueDate === date
                                );
                            const retailerAdhocsForTodayWithSortOrder =
                                retailerAdhocs.filter(
                                    (task) =>
                                        task.sortOrder != null &&
                                        task.dueDate === date
                                );
                            const tasksForTodayWithSortOrder =
                                retailerTasksForTodayWithSortOrder.concat(
                                    retailerAdhocsForTodayWithSortOrder
                                );
                            const visitOrder =
                                tasksForTodayWithSortOrder.length > 0
                                    ? _.sortBy(
                                          tasksForTodayWithSortOrder,
                                          (task) => task.sortOrder
                                      )[
                                          Math.floor(
                                              tasksForTodayWithSortOrder.length /
                                                  2
                                          )
                                      ].sortOrder
                                    : 1;

                            if (adhocTasks && adhocTasks.length > 0) {
                                retailerVisitByDate[date] = {
                                    ...retailer,
                                    ...existngTasks,
                                    tasks: existngTasks.tasks || [],
                                    highPriorityTasks:
                                        existngTasks.highPriorityTasks +
                                        adhocTasks.filter(
                                            (task) => task.priority === 'HIGH'
                                        ).length,
                                    adhocTasks,
                                    schedule: date,
                                    visitOrder,
                                };
                            }
                        });
                    }

                    _.each(_.keys(retailerVisitByDate), (date) => {
                        const visit = retailerVisitByDate[date];
                        scheduled.push(visit);
                    });

                    if (isNotScheduled.length > 0) {
                        const tasks = isNotScheduled;
                        unscheduled.push({
                            ...retailer,
                            schedule: null,
                            tasks,
                            highPriorityTasks: tasks.filter(
                                (task) => task.priority === 'HIGH'
                            ).length,
                            adhocTasks: [], //retailerAdhocs,
                        });
                    }
                } else {
                    const groupAdHocsByDate = _.groupBy(
                        retailerAdhocs,
                        'dueDate'
                    );
                    // even if there are no regular retailer visits check for ad-hoc visits and show on scheduler
                    _.each(_.keys(groupAdHocsByDate), (date) => {
                        const adhocTasks = groupAdHocsByDate[date];
                        scheduled.push({
                            ...retailer,
                            adhocTasks: adhocTasks,
                            schedule: date,
                        });
                    });
                }
            });
            // normalize retailer priorities so that they run from 1 to num_of_retailers_for_day
            const weeklySchedule = _.map(new Array(7).fill(0), (n, i) =>
                moment(week)
                    .startOf('isoWeek')
                    .clone()
                    .add(n + i, 'd')
            );
            weeklySchedule.forEach((day) =>
                _.sortBy(
                    scheduled.filter((retailer) =>
                        moment(retailer.schedule).isSame(day, 'day')
                    ),
                    [(retailer) => retailer.visitOrder]
                ).forEach((retailer, id) => (retailer.visitOrder = id + 1))
            );

            dispatch({
                type: WEEKLY_SCHEDULE_RETAILERS_LIST,
                scheduled,
                unscheduled,
            });
        } catch (err) {
            console.error(err);
            dispatch(weeklyScheduleError(err));
            dispatch(createErrorHandlerError(err));
        } finally {
            dispatch({ type: WEEKLY_SCHEDULE_DONE });
        }
    };
};

export const scheduleVisitDayForRetailer = (newDate, retailerId) => {
    return async (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();
        let success = true;

        try {
            //send to api if successful then do the update
            //send update to service

            const searchPredicate = { id: retailerId };

            let retailerToUpdate = _.find(unscheduled, searchPredicate);

            if (retailerToUpdate) {
                let updatedRetailer = { ...retailerToUpdate };

                // do not remove from unscheduled yet to show notification

                retailerToUpdate.name = `moved_${retailerToUpdate.name}`; // change name so that component gets updated
                retailerToUpdate.key = `moved_${retailerToUpdate.id}`;
                retailerToUpdate.id = -retailerToUpdate.id;
                retailerToUpdate.moved = true;

                const retailersToVisitOnDate = _.filter(scheduled, (retailer) =>
                    moment(retailer.schedule).isSame(newDate, 'day')
                );

                let scheduledRetailerIndex = _.findIndex(scheduled, {
                    ...searchPredicate,
                    schedule: newDate,
                });

                // show below all other visits unless retailer is already scheduled for that day
                const sortIndex =
                    scheduledRetailerIndex >= 0
                        ? scheduled[scheduledRetailerIndex].visitOrder
                        : retailersToVisitOnDate.length + 1;
                updatedRetailer.visitOrder = sortIndex;
                updatedRetailer.schedule = newDate;

                // update visit order of retailer tasks
                let tasks = [];
                updatedRetailer.tasks
                    .concat(updatedRetailer.adhocTasks)
                    .forEach((task) => {
                        task.sortOrder = sortIndex;
                        task.dueDate = newDate;
                        tasks.push(task);
                    });

                try {
                    await taskApi.bulkUpdateUTRs(tasks);
                } catch (err) {
                    dispatch(
                        weeklyScheduleError({
                            message: 'error updating task visit order',
                        })
                    );
                    success = false;
                }

                if (scheduledRetailerIndex >= 0) {
                    // retailer card already exists for that day
                    const scheduledRetailer = scheduled[scheduledRetailerIndex];
                    const tasks = [
                        ...scheduledRetailer.tasks,
                        ...updatedRetailer.tasks,
                    ];
                    const adhocTasks = [
                        ...scheduledRetailer.adhocTasks,
                        ...updatedRetailer.adhocTasks,
                    ];
                    scheduled[scheduledRetailerIndex] = {
                        ...scheduledRetailer,
                        ...updatedRetailer,
                        tasks: tasks,
                        highPriorityTasks: tasks
                            .concat(adhocTasks)
                            .filter((task) => task.priority === 'HIGH').length,
                        adhocTasks: adhocTasks,
                    };
                } else {
                    scheduled.push(updatedRetailer);
                }

                dispatch({
                    type: WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
                    scheduled,
                    unscheduled,
                });
            } else {
                //should never be false but just in case
                dispatch(
                    weeklyScheduleError({
                        message: 'retailer not found in list',
                    })
                );
                success = false;
            }
        } catch (err) {
            dispatch(weeklyScheduleError(err));
            success = false;
        } finally {
            dispatch({ type: WEEKLY_SCHEDULE_DONE });
        }

        return success;
    };
};

export const updateScheduleVisitDayForRetailer = (
    newDate,
    previousDate,
    retailerId
) => {
    return async (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();
        let success = true;

        if (newDate === previousDate) {
            return success;
        }

        try {
            //send to api if successful then do the update
            //send update to service

            const searchPredicate = { id: retailerId, schedule: previousDate };

            let retailerToUpdateIndex = _.findIndex(scheduled, searchPredicate);

            if (retailerToUpdateIndex >= 0) {
                let updatedRetailer = { ...scheduled[retailerToUpdateIndex] };

                const retailersToVisitOnDate = _.filter(scheduled, (retailer) =>
                    moment(retailer.schedule).isSame(newDate, 'day')
                );
                const scheduledRetailerIndex = _.findIndex(scheduled, {
                    id: retailerId,
                    schedule: newDate,
                });

                // show below all other visits unless retailer is already scheduled for that day
                const sortIndex =
                    scheduledRetailerIndex >= 0
                        ? scheduled[retailerToUpdateIndex].visitOrder
                        : retailersToVisitOnDate.length + 1;
                updatedRetailer.visitOrder = sortIndex;

                // update visit order of retailer tasks
                let tasks = [];
                updatedRetailer.tasks
                    .concat(updatedRetailer.adhocTasks)
                    .forEach((task) => {
                        task.sortOrder = sortIndex;
                        task.dueDate = newDate;
                        tasks.push(task);
                    });

                try {
                    await taskApi.bulkUpdateUTRs(tasks);
                } catch (err) {
                    dispatch(
                        weeklyScheduleError({
                            message: 'error updating task visit order',
                        })
                    );
                    success = false;
                }

                if (scheduledRetailerIndex >= 0) {
                    // retailer card already exists for that day
                    const scheduledRetailer = scheduled[scheduledRetailerIndex];
                    const tasks = [
                        ...scheduledRetailer.tasks,
                        ...updatedRetailer.tasks,
                    ];
                    const adhocTasks = [
                        ...scheduledRetailer.adhocTasks,
                        ...updatedRetailer.adhocTasks,
                    ];
                    scheduled[scheduledRetailerIndex] = {
                        ...scheduledRetailer,
                        visitOrder: sortIndex,
                        tasks: tasks,
                        highPriorityTasks: tasks
                            .concat(adhocTasks)
                            .filter((task) => task.priority === 'HIGH').length,
                        adhocTasks: adhocTasks,
                    };
                } else {
                    updatedRetailer.schedule = newDate;
                    updatedRetailer.visitOrder = sortIndex;
                    scheduled.push(updatedRetailer);
                }

                scheduled[
                    retailerToUpdateIndex
                ].name = `moved_${updatedRetailer.name}`; // change name so that component gets updated
                scheduled[retailerToUpdateIndex].key = `moved_${
                    updatedRetailer.id
                }_${new Date()}`;
                scheduled[retailerToUpdateIndex].id = -updatedRetailer.id;
                scheduled[retailerToUpdateIndex].movedToDate = newDate;
                scheduled[retailerToUpdateIndex].moved = true;

                dispatch({
                    type: WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
                    scheduled,
                    unscheduled,
                });
            } else {
                //should never be false but just in case
                dispatch(
                    weeklyScheduleError({
                        message: 'retailer not found in list',
                    })
                );
                success = false;
            }
        } catch (err) {
            dispatch(weeklyScheduleError(err));
            success = false;
        } finally {
            dispatch({ type: WEEKLY_SCHEDULE_DONE });
        }

        return success;
    };
};

export const removeRetailerFromList = (retailerId) => {
    return (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();
        const searchPredicate = { id: retailerId, moved: true };
        let foundInList = scheduled; //look in both lists to remove retailer since we need to show notificaiton for unscheduled to scheduled

        let retailerToUpdate = _.find(scheduled, searchPredicate);

        if (!retailerToUpdate) {
            retailerToUpdate = _.find(unscheduled, searchPredicate);
            foundInList = unscheduled;
        }

        if (retailerToUpdate) {
            _.remove(foundInList, searchPredicate);
            if (foundInList === scheduled) {
                // normalize retailer priorities so that they run from 1 to num_of_retailers_for_day
                _.sortBy(
                    scheduled.filter((retailer) =>
                        moment(retailer.schedule).isSame(
                            retailerToUpdate.schedule,
                            'day'
                        )
                    ),
                    [(retailer) => retailer.visitOrder]
                ).forEach((retailer, id) => (retailer.visitOrder = id + 1));
            }

            dispatch({
                type: WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
                scheduled,
                unscheduled,
            });
            return true;
        }
        return false;
    };
};

export const updateRetailerFromScheduledList = (utr) => {
    return (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();
        const searchPredicate = { id: utr.retailerId, tasks: [{ id: utr.id }] };

        let retailerToUpdate = _.find(scheduled, searchPredicate);
        if (retailerToUpdate) {
            //remove task to update the scheduled list
            let taskToRemove = _.find(retailerToUpdate.tasks, { id: utr.id });
            _.remove(retailerToUpdate.tasks, taskToRemove);
            retailerToUpdate.tasks.push(utr);

            // also update the adhoc task list
            taskToRemove = _.find(retailerToUpdate.adhocTasks, { id: utr.id });
            if (taskToRemove) {
                _.remove(retailerToUpdate.adhocTasks, taskToRemove);
                retailerToUpdate.adhocTasks.push(utr);
            }

            dispatch({
                type: WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
                scheduled: [...scheduled, retailerToUpdate],
                unscheduled,
            });
            return true;
        }
        return false;
    };
};

export const optimizeVisitSortOrder = (bulkUpdate) => {
    return async (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();
        if (bulkUpdate && bulkUpdate.length > 0) {
            const day = moment(bulkUpdate[0].schedule); //all visits happen on same day, just get first
            let retailersForDay = _.sortBy(
                _.filter(scheduled, (retailer) =>
                    moment(retailer.schedule).isSame(day, 'day')
                ),
                ['visitOrder']
            );
            const others = _.filter(
                scheduled,
                (retailer) => !moment(retailer.schedule).isSame(day, 'day')
            );

            for (let i = 0; i < bulkUpdate.length; i++) {
                const retailerPositionIndex = _.findIndex(retailersForDay, {
                    id: bulkUpdate[i].retailerId,
                });
                if (retailerPositionIndex >= 0) {
                    retailersForDay[retailerPositionIndex].visitOrder =
                        bulkUpdate[i].visitOrder;
                }
            }
            dispatch(
                await updateVisitSortOrder(retailersForDay, others, unscheduled)
            );
        }
    };
};

export const increaseVisitSortOrder = (retailerId, schedule) => {
    return async (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();

        const day = moment(schedule);
        let retailersForDay = _.sortBy(
            _.filter(scheduled, (retailer) =>
                moment(retailer.schedule).isSame(day, 'day')
            ),
            ['visitOrder']
        );
        const retailerPosition = _.findIndex(retailersForDay, {
            id: retailerId,
        });
        const others = _.filter(
            scheduled,
            (retailer) => !moment(retailer.schedule).isSame(day, 'day')
        );

        if (retailerPosition >= 0) {
            //swap the order of this retailer and the one in the preceding spot
            retailersForDay[retailerPosition].visitOrder -= 1;
            retailersForDay[retailerPosition - 1].visitOrder += 1;
            dispatch(
                await updateVisitSortOrder(retailersForDay, others, unscheduled)
            );
        }
    };
};

export const decreaseVisitSortOrder = (retailerId, schedule) => {
    return async (dispatch, getState) => {
        const {
            weeklyScheduler: { scheduled, unscheduled },
        } = getState();

        const day = moment(schedule);
        let retailersForDay = _.sortBy(
            _.filter(scheduled, (retailer) =>
                moment(retailer.schedule).isSame(day, 'day')
            ),
            ['visitOrder']
        );
        const retailerPosition = _.findIndex(retailersForDay, {
            id: retailerId,
        });
        const others = _.filter(
            scheduled,
            (retailer) => !moment(retailer.schedule).isSame(day, 'day')
        );

        if (retailerPosition <= retailersForDay.length - 1) {
            //swap the order of this retailer and the one in the following spot
            retailersForDay[retailerPosition].visitOrder += 1;
            retailersForDay[retailerPosition + 1].visitOrder -= 1;
            dispatch(
                await updateVisitSortOrder(retailersForDay, others, unscheduled)
            );
        }
    };
};

const updateVisitSortOrder = async (retailersForDay, others, unscheduled) => {
    // propagate new priorities to UTRs
    let tasks = [];
    _.each(retailersForDay, (retailer) => {
        _.each(retailer.tasks, async (task) => {
            task.sortOrder = retailer.visitOrder;
            tasks.push(task);
        });
        _.each(retailer.adhocTasks, async (task) => {
            task.sortOrder = retailer.visitOrder;
            tasks.push(task);
        });
    });
    try {
        await taskApi.bulkUpdateUTRs(tasks);
        return {
            type: WEEKLY_SCHEDULE_UPDATE_RETAILERS_LIST,
            scheduled: [...retailersForDay, ...others],
            unscheduled,
        };
    } catch (err) {
        return weeklyScheduleError({
            message: 'error updating task visit order',
        });
    }
};

export const _getSurveyForTasks = async (tasks) => {
    try {
        const complianceTasks = _.filter(
            tasks,
            (t) =>
                (t.category === 'COMPLIANCE' || t.category === 'MARKETING') &&
                typeof t.survey === 'undefined'
        );
        const nonSurveyTasks = _.filter(
            tasks,
            (t) => t.category !== 'COMPLIANCE' && t.category !== 'MARKETING'
        );

        if (complianceTasks.length) {
            const surveyIds = _.map(
                _.uniqBy(complianceTasks, 'surveyId'),
                'surveyId'
            );
            //remove nulls
            _.remove(surveyIds, (s) => !s);

            //if we have no ids then just quit
            if (!surveyIds.length) {
                return;
            }

            const surveyResultUuids = _.map(
                complianceTasks,
                'surveyResultUuid'
            );
            //remove nulls
            _.remove(surveyResultUuids, (s) => !s);

            const surveys = await surveyApi.getSurveysForIds(surveyIds);
            const surveyResults = surveyResultUuids.length
                ? await surveyApi.getSurveyAnswersForIds(surveyResultUuids)
                : [];

            const result = [];

            _.each(complianceTasks, (task) => {
                //need a copy of the object else will mutate the array
                task.survey = { ..._.find(surveys, { id: task.surveyId }) };

                if (task.surveyResultUuid) {
                    //need a copy of the object else will mutate the array
                    const answerResponse = {
                        ..._.find(surveyResults, { id: task.surveyResultUuid }),
                    };

                    if (answerResponse && answerResponse.answers) {
                        task.survey.answers = [...answerResponse.answers];

                        //save compliance answer for form
                        task.survey.answer = _.map(
                            task.survey.answers,
                            ({ text, question }) => ({
                                value: text,
                                questionId: question.id,
                            })
                        );

                        //used for updating answers
                        task.survey.surveyResultUuid = task.surveyResultUuid;
                    }
                } else {
                    task.survey.surveyResultUuid = null; //init surveyResultUuid for updating
                    task.survey.answers = [];
                    task.survey.answer = null;
                }

                result.push(task);
            });

            return [...result, ...nonSurveyTasks];
        }

        return tasks;
    } catch (e) {
        console.error(e);
        return tasks;
    }
};

export const initWeeklyScheduler = () => ({
    type: WEEKLY_SCHEDULE_INIT,
});
