import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Header, Dimmer, Loader } from 'semantic-ui-react';

import ErrorHandlerPage from '../components/errorHandlerPage/ErrorHandlerPage';
import { clearNotification } from '../redux/actions';
import Secured from '../components/security/Secured';

import './page.css';
import './list-page.css';

export class Page extends Component {
    componentWillUnmount() {
        if (!this.props.keepNotification) {
            this.props.clearNotification();
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderPageContent = ({
        name,
        children,
        title,
        onAddButtonClick,
        addButtonRoles,
        buttonComponent,
        breadCrumbComponent,
    }) => {
        let addButton;
        if (onAddButtonClick) {
            if (addButtonRoles) {
                addButton = (
                    <Secured hasAnyRole={addButtonRoles}>
                        {
                            <Button
                                size="small"
                                className="white"
                                circular
                                onClick={onAddButtonClick}
                            >
                                <span className="icon-Plus" />
                            </Button>
                        }
                    </Secured>
                );
            } else {
                addButton = (
                    <Button
                        size="small"
                        className="white"
                        circular
                        onClick={onAddButtonClick}
                    >
                        <span className="icon-Plus" />
                    </Button>
                );
            }
        }

        return (
            <div id="page-content" data-test="ErrorpageContent">
                <ErrorHandlerPage name={name}>
                    {breadCrumbComponent}
                    {(title || addButton || buttonComponent) && (
                        <div className="title-section">
                            {title && (
                                <Header className="subheader-text">
                                    {title}
                                </Header>
                            )}
                            {!!addButton && addButton}
                            {buttonComponent && (
                                <span className="move-right">
                                    {buttonComponent}
                                </span>
                            )}
                        </div>
                    )}
                    {children}
                </ErrorHandlerPage>
            </div>
        );
    };

    render() {
        const {
            children,
            name,
            title,
            onAddButtonClick,
            addButtonRoles,
            buttonComponent,
            breadCrumbComponent,
            clearNotification,
            loading,
            ...otherProps
        } = this.props;
        return (
            <div id="page-container" {...otherProps} data-test="pageContent">
                <Dimmer page active={loading} inverted>
                    <Loader />
                </Dimmer>
                <div className="sfa-page">
                    {this.renderPageContent({
                        name,
                        children,
                        title,
                        onAddButtonClick,
                        addButtonRoles,
                        buttonComponent,
                        breadCrumbComponent,
                    })}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearNotification: () => dispatch(clearNotification()),
    };
};

export default connect(null, mapDispatchToProps)(Page);
