import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import ExecutiveImageFile from '../../assets/executive.svg';

import './styles.css';

const ExecutiveImage = {
    src: ExecutiveImageFile,
    alt: 'executive img',
};

class SfaBranding extends Component {
    isSvgLogo = (appLogo, appLogoFile) => {
        if (appLogo && appLogoFile) {
            const ext = appLogo.substr(appLogo.lastIndexOf('.') + 1);
            if (ext.toLowerCase() === 'svg') {
                return `data:image/svg+xml;base64,${appLogoFile}`;
            } else if (appLogoFile != null) {
                return `data:image/png;base64,${appLogoFile}`;
            }
        }

        return null;
    };

    render() {
        const { taglineText, appLogoFile, loading, appLogo, disableLoader } =
            this.props;
        const showLoading = !appLogo || !appLogoFile || loading;

        let content = [];
        if (!disableLoader && showLoading) {
            content.push(
                <Loader
                    key="loader"
                    active={showLoading}
                    size="medium"
                    inline="centered"
                    inverted
                />
            );
        }

        const imageSource = this.isSvgLogo(appLogo, appLogoFile);

        if (!showLoading) {
            if (imageSource) {
                content.push(
                    <div className="logo-wrapper" key="logo">
                        <img
                            src={imageSource}
                            alt="appLogo Img"
                            className="logo"
                        />
                    </div>
                );
            }

            if (taglineText) {
                content.push(
                    <div className="tag-line-wrapper" key="tag-line">
                        <div>
                            <span className="subheading-text bold tag-line">
                                {taglineText}
                            </span>
                        </div>
                    </div>
                );
            }
        }

        if (!loading) {
            content.push(
                <div className="image-wrapper" key="image">
                    <img
                        src={ExecutiveImage.src}
                        alt={ExecutiveImage.alt}
                        className="image"
                    />
                </div>
            );
        }

        return <div id="branding">{content}</div>;
    }
}

const mapStateToProps = ({
    organization: { appLogo },
    appLogo: { appLogoFile, reportLogoFile, loading },
}) => ({
    appLogoFile,
    reportLogoFile,
    loading,
    appLogo,
});

export default connect(mapStateToProps, null)(SfaBranding);
