import OrderApi from '../../../services/order-service';
import { findTotalCostForEachOrder } from './orderDetail';
import _ from 'lodash';

export const PAST_ORDERS_LOADING = 'PAST_ORDERS_LOADING';
export const PAST_ORDERS_LOADING_DONE = 'PAST_ORDERS_LOADING_DONE';
export const PAST_ORDERS_INIT = 'PAST_ORDERS_INIT';
export const PAST_ORDERS_SEARCH = 'PAST_ORDERS_SEARCH';

const orderApi = new OrderApi();

export const searchPastOrders = (args) => {
    return async (dispatch) => {
        dispatch({ type: PAST_ORDERS_LOADING });

        try {
            let payload = await orderApi.search(args);
            payload = {
                ...payload,
                content: _.map(payload.content, (order) => {
                    let orderStatusByShipment = order?.status?.description;
                    const totalShipments = _.size(order.shipments);
                    if (totalShipments > 0) {
                        if (
                            totalShipments ===
                            _.size(
                                _.filter(
                                    order.shipments,
                                    (shipment) =>
                                        shipment.status?.code === 'CANCL'
                                )
                            )
                        ) {
                            orderStatusByShipment = 'Cancelled';
                        } else if (
                            totalShipments ===
                            _.size(
                                _.filter(
                                    order.shipments,
                                    (shipment) =>
                                        shipment.received ||
                                        shipment.inTransit ||
                                        shipment.status?.code === 'CANCL'
                                )
                            )
                        ) {
                            orderStatusByShipment = 'Processed';
                        } else {
                            orderStatusByShipment = 'In Shipment';
                        }
                    }
                    return {
                        ...order,
                        status: orderStatusByShipment,
                    };
                }),
            };

            await findTotalCostForEachOrder(payload);

            dispatch({ type: PAST_ORDERS_SEARCH, payload });
        } catch (err) {
            console.error(err);
        }

        dispatch({ type: PAST_ORDERS_LOADING_DONE });
    };
};

export const initPastOrders = () => ({
    type: PAST_ORDERS_INIT,
});
