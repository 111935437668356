import _ from 'lodash';

import { ReportsApi } from '../../../services/reports-service';
import { RetailersApi } from '../../../services/retailers-service';
import { errorHandler } from '../errorHandler/errorHandler';
import { getErrorStatus } from '../../../constants/utils';

export const RETAILER_INVOICES_INIT = 'RETAILER_INVOICES_INIT';
export const RETAILER_INVOICES = 'RETAILER_INVOICES';
export const RETAILER_INVOICES_LOADING = 'RETAILER_INVOICES_LOADING';

const reportsApi = new ReportsApi();
const retailersApi = new RetailersApi();

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const getLatestInvoiceForAccount = (args, accountId) => {
    return async (dispatch) => {
        dispatch({ type: RETAILER_INVOICES_INIT });
        dispatch({ type: RETAILER_INVOICES_LOADING });
        try {
            const retailers = await retailersApi.getRetailersByAccountId(
                accountId
            );
            const retailerReferences = _.map(
                retailers,
                (retailer) => retailer.reference
            );

            //ensure there is always a reference in the search criteria (ensures it never returns all/unfiltered results)
            retailerReferences.push('foo');
            //get the retailers into the filters list.
            args.filters.push({
                property: 'retailerReferences',
                data: retailerReferences,
            });
            const retailerInvoices =
                await reportsApi.getLatestInvoiceForRetailers(args);
            const totalCommission =
                await reportsApi.getLatestCommissionForRetailers(
                    retailerReferences
                );

            _.each(retailerInvoices.content, (invoice) => {
                const commissionsList = _.filter(invoice.lineItems, {
                    lineType: 'C',
                });
                const invoiceCommissions = _.reduce(
                    commissionsList,
                    function (result, commission) {
                        return (result += commission.amount);
                    },
                    0
                );
                invoice.commission = invoiceCommissions;

                //they will all be the same dates
                retailerInvoices.periodStart = invoice.startDate;
                retailerInvoices.periodEnd = invoice.endDate;

                const retailer = _.find(retailers, [
                    'reference',
                    invoice.retailerReference,
                ]);
                invoice.retailerName =
                    retailer && retailer.name ? retailer.name : '';
                invoice.salesRepName =
                    retailer && retailer.salesRepName
                        ? retailer.salesRepName
                        : '';
                invoice.salesRouteName =
                    retailer && retailer.salesRouteName
                        ? retailer.salesRouteName
                        : '';
            });

            retailerInvoices.totalCommission = totalCommission;

            dispatch({
                type: RETAILER_INVOICES,
                retailerInvoices,
            });
        } catch (e) {
            dispatch(createErrorHandlerError(e));
        }
    };
};
