import React, { Component } from 'react';
import moment from 'moment';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import _ from 'lodash';

import './styles.css';

class Calendar extends Component {
    state = {
        month: null,
        today: null,
        selectedDate: null,
    };

    componentWillMount() {
        const { format } = this.props;
        const today = new Date();

        const parsedValue =
            this.props.selectedDate &&
            moment(this.props.selectedDate, format ? format : 'YYYY-MM-DD');
        this.setState({
            month: moment(today).startOf('month').toDate(),
            today,
            selectedDate: parsedValue,
        });
    }

    prevMonth = () => {
        this.setState({
            month: subMonths(moment(this.state.month).toDate(), 1),
        });
    };

    nextMonth = () => {
        this.setState({
            month: addMonths(moment(this.state.month).toDate(), 1),
        });
    };

    _onDateSelected = (value) => {
        const { format } = this.props;
        this.setState({ selectedDate: value });

        const formattedValue = value.format(!format ? 'YYYY-MM-DD' : format);
        this.props.onValuesChange && this.props.onValuesChange(formattedValue);
    };

    render() {
        const { showYear } = this.props;
        const start = moment(this.state.month).startOf('month').week();
        const m = moment(this.state.month);
        let stop = moment(this.state.month).endOf('month').week();
        const month = [];

        if (start >= 48) {
            stop = 53;
        }

        for (let week = start; week <= stop; week++) {
            const days = _.map(new Array(7).fill(0), (n, i) =>
                moment(this.state.month)
                    .week(week)
                    .startOf('week')
                    .clone()
                    .add(n + i, 'd')
            );
            month.push(days);
        }

        const { minDate, maxDate } = this.props;
        const disabledClassName = 'disabled';

        const momentMinDate = minDate ? moment(minDate) : null;
        const momentMaxDate = maxDate ? moment(maxDate) : null;
        return (
            <div className="calendar">
                <div className="calendar-title">
                    <div className="title-arrow" onClick={this.prevMonth}>
                        <i className="icon-Arrow-Left"> </i>
                    </div>
                    <div className="calendar-title-text subheader-text bold">
                        {!showYear
                            ? m.format('MMMM YYYY').toUpperCase()
                            : m.format('MMMM').toUpperCase()}
                    </div>
                    <div className="title-arrow" onClick={this.nextMonth}>
                        <i className="icon-Arrow-Right"> </i>
                    </div>
                </div>
                <div className="calendar-content">
                    <table className="text-center" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="small-text secondary-color calendar-head">
                                    <small>Su</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>M</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>T</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>W</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>Th</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>F</small>
                                </th>
                                <th className="small-text secondary-color calendar-head">
                                    <small>Sa</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(month, (w) => {
                                return (
                                    <tr key={w[0].get('week')}>
                                        {_.map(w, (d) => {
                                            const sameMonth = d.isSame(
                                                this.state.month,
                                                'month'
                                            );

                                            let cls =
                                                sameMonth &&
                                                d.isSame(
                                                    this.state.today,
                                                    'day'
                                                )
                                                    ? 'today'
                                                    : '';

                                            cls += ' calendar-item';

                                            if (
                                                (momentMinDate &&
                                                    d <
                                                        momentMinDate.startOf(
                                                            'day'
                                                        )) ||
                                                !sameMonth
                                            ) {
                                                cls += ' disabled';
                                            }

                                            if (
                                                (momentMaxDate &&
                                                    d >
                                                        momentMaxDate.startOf(
                                                            'day'
                                                        )) ||
                                                !sameMonth
                                            ) {
                                                cls += ' disabled';
                                            }

                                            if (
                                                sameMonth &&
                                                this.state.selectedDate &&
                                                d.isSame(
                                                    this.state.selectedDate,
                                                    'day'
                                                )
                                            ) {
                                                cls += ' selected-date';
                                            }

                                            const date = d.get('date');

                                            return (
                                                <td
                                                    className={cls}
                                                    key={date}
                                                    onClick={() => {
                                                        if (
                                                            sameMonth &&
                                                            cls.indexOf(
                                                                disabledClassName
                                                            ) === -1
                                                        ) {
                                                            this._onDateSelected(
                                                                d
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {date}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Calendar;
