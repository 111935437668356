import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

import {
    tableMouseOut,
    tableMouseOver,
    updateSortDetails,
    selectAllTableRow,
} from './actions';

class Head extends Component {
    sortChanged = (sortColumnName) => {
        const ascending =
            this.props.sortColumnName === null ? true : !this.props.ascending;

        this.props.updateSortDetails({
            sortColumnName: sortColumnName,
            ascending,
        });

        const { sortData, size } = this.props;
        if (sortData) {
            sortData({
                size,
                page: 1,
                sortOrder: ascending ? 'asc' : 'desc',
                sortColumn: sortColumnName,
            });
        }
    };

    render() {
        const {
            columns,
            sortColumnName,
            ascending,
            hoverColumn,
            useCheckBox,
            content,
            contentKey,
            itemsSelected,
            showActionHead,
            headClassName,
        } = this.props;

        let rowDetails = [];

        if (useCheckBox) {
            const selectedItemIds =
                itemsSelected && Object.keys(itemsSelected).length > 0
                    ? Object.keys(itemsSelected).map(
                          (key) => itemsSelected[key].id
                      )
                    : [];
            const pageItemIds = content.map((item) => item.id);
            const selectedItemsOnPage = _.intersection(
                selectedItemIds,
                pageItemIds
            );
            const allChecked = selectedItemsOnPage.length === content.length;
            rowDetails.push(
                <Table.HeaderCell width={1} key={`head_selector`} collapsing>
                    <Checkbox
                        checked={allChecked}
                        onChange={() =>
                            this.props.selectAllTableRow(
                                contentKey,
                                content,
                                allChecked
                            )
                        }
                    />
                </Table.HeaderCell>
            );
        }

        rowDetails = [
            ...rowDetails,
            _.map(columns, (col, index) => {
                const {
                    name,
                    title,
                    sortable,
                    headerStyle,
                    headerTextStyle,
                    textAlign,
                    collapsing,
                    width,
                } = col;

                const upColor =
                    ascending && sortColumnName === name
                        ? '#0C0033'
                        : '#918da3';
                const downColor =
                    !ascending && sortColumnName === name
                        ? '#0C0033'
                        : '#918da3';

                let showIcons = false;
                let className = '';
                if (sortColumnName === name) {
                    className = 'sorted';
                    showIcons = true;
                } else if (hoverColumn === name && sortable) {
                    className = 'sortable';
                    showIcons = true;
                }

                return (
                    <Table.HeaderCell
                        key={index}
                        onClick={() => {
                            if (sortable) {
                                this.sortChanged(name);
                            }
                        }}
                        onMouseOver={() => this.props.tableMouseOver(name)}
                        onMouseOut={() => this.props.tableMouseOut()}
                        className={`text-nowrap ${className} ${
                            headClassName ? headClassName : ''
                        }`}
                        textAlign={textAlign}
                        collapsing={collapsing}
                        width={width}
                    >
                        <div
                            className="caption-text secondary-color bold"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                ...headerStyle,
                            }}
                        >
                            <span
                                style={{
                                    flex: 1,
                                    padding: sortable ? 0 : '4px 0',
                                    ...headerTextStyle,
                                }}
                            >
                                {title}
                            </span>
                            {sortable && showIcons && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        className="icon-Arrow-Up-2"
                                        style={{ color: upColor }}
                                    >
                                        {' '}
                                    </span>
                                    <span
                                        className="icon-Arrow-Down"
                                        style={{ color: downColor }}
                                    >
                                        {' '}
                                    </span>
                                </div>
                            )}
                        </div>
                    </Table.HeaderCell>
                );
            }),
        ];

        if (showActionHead) {
            rowDetails.push(<Table.HeaderCell key="actions" width={1} />);
        }

        return (
            <Table.Header>
                <Table.Row>{rowDetails}</Table.Row>
            </Table.Header>
        );
    }
}

export default connect(
    ({ tableSort, tableSelect }) => {
        const { sortColumnName, ascending, hoverColumn } = tableSort;
        const { itemsSelected } = tableSelect;
        return { sortColumnName, ascending, hoverColumn, itemsSelected };
    },
    (dispatch) => {
        return {
            updateSortDetails: (args) => dispatch(updateSortDetails(args)),
            tableMouseOver: (args) => dispatch(tableMouseOver(args)),
            tableMouseOut: () => dispatch(tableMouseOut()),
            selectAllTableRow: (contentKey, items, allChecked) =>
                dispatch(selectAllTableRow(contentKey, items, allChecked)),
        };
    }
)(Head);
