import React from 'react';
import { formatToDisplayDate } from '../../constants/utils';
import { Button, Dropdown, Header } from 'semantic-ui-react';
import { CheckoutItems } from '../../containers/checkout/CheckoutItems';
import { map, sortBy } from 'lodash';
import './style.css';
export const ShipmentHeader = ({
    shipment: {
        externalShipmentNumber,
        date,
        status,
        expectedDate,
        trackingNumber,
        details,
        courier,
        received,
        inTransit,
    },
    showDetail = false,
}) => {
    const gotoCourierUrl = (trackingNumber, trackingUrl) =>
        window.open(`${trackingUrl}${trackingNumber}`, '_blank');
    const getTrackingUrlButton = (trackingNumber, courier) => (
        <Button
            primary
            disabled={!trackingNumber || !courier || !courier.trackingUrl}
            onClick={() => gotoCourierUrl(trackingNumber, courier.trackingUrl)}
        >
            <span>
                Track Shipment <span className="icon-Open-in-new-tab" />
            </span>
        </Button>
    );

    let shipmentStatus = undefined;
    if (received) {
        shipmentStatus = 'Received';
    } else if (inTransit) {
        shipmentStatus = 'In Transit';
    } else {
        shipmentStatus = status.description;
    }

    return (
        <div className="shipment-header p-4 pl-1" key={externalShipmentNumber}>
            {showDetail && (
                <Header
                    as="h4"
                    className={' min-desktop'}
                >{`Shipment No. #${externalShipmentNumber}`}</Header>
            )}
            <div className={' max-tablet  pr-8'}>
                <div className={'d-flex  justify-content-space-between'}>
                    <span className={' d-flex '}>
                        <span className={'pr-1'}>Shipment No. </span>
                        <b className={'text-align-s'}>
                            #{externalShipmentNumber}
                        </b>
                    </span>
                    <span className={'  d-flex '}>
                        <span className={'pr-1'}>Status: </span>
                        <b className={'text-align-s'}>{status.description}</b>
                    </span>
                </div>
            </div>

            <div className="info pl-1">
                {!showDetail && (
                    <div className="info-cell min-desktop">
                        <div>Shipment No.</div>
                        <div className="bold">
                            {`#${externalShipmentNumber}`}
                        </div>
                    </div>
                )}
                <div className="info-cell">
                    <div>Shipment Date</div>
                    <div className="bold">
                        {!date ? '' : formatToDisplayDate(date)}
                    </div>
                </div>
                <div className="info-cell min-desktop">
                    <div>Status</div>
                    <div className="bold">{shipmentStatus}</div>
                </div>
                <div className="info-cell">
                    <div>Expected Date</div>
                    <div className="bold">
                        {!expectedDate ? '' : formatToDisplayDate(expectedDate)}
                    </div>
                </div>
                <div className="info-cell">
                    <div>Tracking No #</div>
                    <div className="bold">{trackingNumber || ''}</div>
                </div>
                <div className="info-cell min-desktop">
                    {getTrackingUrlButton(trackingNumber, courier)}
                </div>
                <div className={'max-tablet static-options'}>
                    <Dropdown item icon="ellipsis vertical" floating>
                        <Dropdown.Menu className={'left'}>
                            <Dropdown.Item>
                                {getTrackingUrlButton(trackingNumber, courier)}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {showDetail && details && details.length > 0 && (
                <CheckoutItems
                    orderedItems={map(
                        sortBy(details, 'lineNumber'),
                        ({
                            itemDescription,
                            itemNumber,
                            lineNumber,
                            quantity,
                            cost,
                        }) => ({
                            game: {
                                gameRefAndName: `#${itemNumber} - ${itemDescription}`,
                                cost: cost,
                            },
                            qty: quantity,
                        })
                    )}
                />
            )}
        </div>
    );
};
