import { MarketingSurveyApi } from '../../../services/marketing-survey-service';
import { getErrorMessage, getErrorStatus } from '../../../constants/utils';
import { error } from '../notifications';
import { errorHandler } from '../errorHandler/errorHandler';

export const MARKETING_LIST = 'MARKETING_LIST';
export const MARKETING_LIST_LOADING = 'MARKETING_LIST_LOADING';
export const MARKETING_LIST_LOADING_DONE = 'MARKETING_LIST_LOADING_DONE';

const marketingSurveyApi = new MarketingSurveyApi();

const marketingListError = (errors) => {
    return (dispatch) => {
        dispatch(
            error({ msg: getErrorMessage(errors), target: 'MarketingPage' })
        );
    };
};

const createErrorHandlerError = (errors) => {
    return (dispatch) => {
        dispatch(errorHandler({ msg: getErrorStatus(errors) }));
    };
};

export const fetchMarketingSurveys = (args) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MARKETING_LIST_LOADING });

            //fetch surveyTypes
            const marketingSurveyType = 2;

            //add surveyType to filters to only get marketing surveys

            const filtersWithMarketing = args.filters
                ? [
                      ...args.filters,
                      {
                          property: 'surveyType',
                          data: [marketingSurveyType],
                      },
                  ]
                : [
                      {
                          property: 'surveyType',
                          data: [marketingSurveyType],
                      },
                  ];

            const listArgs = { ...args, filters: filtersWithMarketing };

            const list = await marketingSurveyApi.searchMarketingSurveys(
                listArgs
            );

            dispatch({
                type: MARKETING_LIST,
                list,
            });
        } catch (e) {
            console.error(e);
            dispatch(marketingListError(e));
            dispatch(createErrorHandlerError(e));
        } finally {
            dispatch({ type: MARKETING_LIST_LOADING_DONE });
        }
    };
};
