export const UPDATE_SUGGESTED_QUANTITY = 'items/suggested-quantity';

export interface SuggestedQuantityStateType {
    isLoading: boolean;
    suggestedQuantityMap: Map;
}

interface UpdateSuggestedQuantityType {
    type: typeof UPDATE_SUGGESTED_QUANTITY;
    payload: Map;
}
export type SuggestedQuantityActionTypes = UpdateSuggestedQuantityType;
