import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/datatable/Table';
import {
    deleteMultipleDocument,
    getAllMessageFile,
    getAllMultipleDocuments,
    getMessageFiles,
    getRetailerTypeOptions,
    success,
    updateEditedDocument,
    uploadMultipleDocument,
} from '../../redux/actions';
import './styles.css';
import OkCancelModal from '../../components/OkCancelModal';
import { find, filter, includes, forEach } from 'lodash';
import SfaForm from '../../components/SfaForm';
import SfaReadView from '../../components/SfaReadView';
import permissions from '../../constants/permissions';
import UploadMultipleDocuments from '../../components/uploadMultipleDocuments/UploadMultipleDocuments';
import Page from '../../layout/Page';
import ImageCarousel from '../../containers/ImageCarousel/ImageCarousel';
import {
    formatToDisplayDate,
    selectItemsToFilter,
} from '../../constants/utils';
import FilterSection from '../../components/filter/FilterSection';
import { updateSortDetails } from '../../components/datatable/actions';
import PillList from '../../components/pillList/PillList';

const TOTAL_PER_PAGE = 10;
const MULTIPLE_DOCUMENT_LIST = 'MULTIPLE_DOCUMENT_LIST';
const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
const READ_DOCUMENT = 'READ_DOCUMENT';
const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
const IMAGE_CAROUSEL_PREVIEW = 'IMAGE_CAROUSEL_PREVIEW';
const SAVE_IMAGE_CONFIRMATION = 'SAVE_IMAGE_CONFIRMATION';
const IMAGE_CAROUSEL_EDIT_PREVIEW = 'IMAGE_CAROUSEL_EDIT_PREVIEW';
const MESSAGE_UPDATE_CONFIRMATION = 'MESSAGE_UPDATE_CONFIRMATION';
const PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT';
const MESSAGE_EDIT_IMAGE_CONFIRM = 'MESSAGE_EDIT_IMAGE_CONFIRM';

class MessageListPage extends Component {
    state = {
        action: MULTIPLE_DOCUMENT_LIST,
        prevAction: null,
        selectedDocument: null,
        fileValues: null,
        startDate: null,
        endDate: null,
        message: null,
        fileRemoveArray: null,
        undoCancel: false,
        page: 0,
        defaultFilterItems: {
            status: ['ACTIVE', 'INACTIVE', 'PENDING'],
            useDefault: true,
        },
    };

    componentDidMount() {
        this.props.updateSortDetails({
            sortColumnName: 'startDate',
            ascending: false,
        });
        this.initDocumentList();
    }

    initDocumentList = () => {
        this.props.getRetailerTypeOptions();
        this.props.fetchListOfDocuments({
            page: this.state.page,
            size: TOTAL_PER_PAGE,
            sortColumn: 'startDate',
            sortOrder: 'DESC',
            filters: [],
        });
    };

    getSelectedDocument = (id) => {
        const { content } = this.props;
        const ret = find(content, { id: id });

        if (!ret) {
            console.error(
                'something really wrong happened. should of found document in list'
            );
        }

        return ret;
    };

    showReadForm = (id) => {
        const selected = this.getSelectedDocument(id);

        this.setState({
            action: READ_DOCUMENT,
            selectedDocument: selected,
            fileValues: null,
            startDate: null,
            endDate: null,
            message: null,
            fileRemoveArray: null,
        });
    };

    showCreateForm = () => {
        this.setState({
            action: CREATE_DOCUMENT,
            fileValues: null,
            startDate: null,
            endDate: null,
            message: null,
            fileRemoveArray: null,
        });
    };

    showList = () => {
        this.setState({ action: MULTIPLE_DOCUMENT_LIST });
        this.initDocumentList();
    };

    ShowImageCarouselPreview = (values, startDate, endDate) => {
        this.setState({
            action: IMAGE_CAROUSEL_PREVIEW,
            fileValues: values,
            startDate: startDate,
            endDate: endDate,
            message: values.description,
        });
    };

    ShowImageCarouselEditPreview = (
        values,
        startDate,
        endDate,
        fileRemoveArray
    ) => {
        this.setState({
            action: IMAGE_CAROUSEL_EDIT_PREVIEW,
            fileValues: values,
            startDate: startDate,
            endDate: endDate,
            message: values.description,
            fileRemoveArray: fileRemoveArray,
        });
    };

    confirmSaveMessage = (values) => {
        this.setState({ action: SAVE_IMAGE_CONFIRMATION });
    };

    DocumentUpdateConfirmation = async (
        values,
        startDate,
        endDate,
        description,
        fileRemoveArray,
        undoCancel
    ) => {
        const { selectedDocument } = this.state;

        await this.props.getMessageFiles(fileRemoveArray, selectedDocument.id);
        this.setState({
            action: MESSAGE_EDIT_IMAGE_CONFIRM,
            fileValues: values,
            startDate: startDate,
            endDate: endDate,
            message: description,
            fileRemoveArray: fileRemoveArray,
        });
        if (undoCancel) {
            this.setState({
                undoCancel: undoCancel,
            });
        }
    };

    DocumentUpdateSaveConfirmation = (
        values,
        startDate,
        endDate,
        description,
        fileRemoveArray,
        undoCancel
    ) => {
        this.setState({
            action: MESSAGE_UPDATE_CONFIRMATION,
            fileValues: values,
            startDate: startDate,
            endDate: endDate,
            message: description,
            fileRemoveArray: fileRemoveArray,
        });
        if (undoCancel) {
            this.setState({
                undoCancel: undoCancel,
            });
        }
    };

    confirmEditMessage = (values) => {
        this.setState({ action: MESSAGE_UPDATE_CONFIRMATION });
    };
    saveMessage = async (values) => {
        const { startDate, endDate, fileValues, message } = this.state;
        const success = await this.props.uploadMultipleDocument(
            fileValues.file,
            startDate,
            endDate,
            message,
            values.retailerTypes
        );
        if (success) {
            this.props.updateSuccess({
                msg: `The message was successfully saved.`,
                target: 'MessageListPage',
            });
        }
        this.setState({ action: MULTIPLE_DOCUMENT_LIST });
        this.initDocumentList();
    };

    editMessage = async (selecteddoc) => {
        const {
            startDate,
            endDate,
            message,
            fileRemoveArray,
            selectedDocument,
            fileValues,
        } = this.state;

        const success = await this.props.updateEditedDocument(
            fileValues.file,
            fileRemoveArray,
            selectedDocument,
            startDate,
            endDate,
            message,
            fileValues.retailerTypes
        );
        if (success) {
            this.props.updateSuccess({
                msg: `The message was successfully updated.`,
                target: 'MessageListPage',
            });
        }
        this.setState({ action: MULTIPLE_DOCUMENT_LIST });
        this.initDocumentList();
    };

    editDocument = (id) => {
        const { selectedDocument, action } = this.state;
        let selected;
        if (action === MESSAGE_EDIT_IMAGE_CONFIRM) {
            selected = selectedDocument;
        } else {
            selected = this.getSelectedDocument(id);
            this.setState({
                startDate: null,
                endDate: null,
                message: selected.description,
                fileRemoveArray: null,
            });
        }

        this.setState({
            action: EDIT_DOCUMENT,
            selectedDocument: selected,
            fileValues: null,
        });
    };

    previewDocument = async (id) => {
        const selected = this.getSelectedDocument(id);
        await this.props.getAllMessageFile(id);

        this.setState({
            action: PREVIEW_DOCUMENT,
            selectedDocument: selected,
        });
    };

    showcancelDocumentModal = (id) => {
        const prevAction = this.state.action;
        this.setState({
            action: DELETE_DOCUMENT,
            prevAction,
        });
    };
    cancelDelete = () => {
        this.setState({ action: this.state.prevAction });
    };

    handleSearch = (query) => {
        this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            sortColumn: 'startDate',
            sortOrder: 'DESC',
            filters: [],
            query,
        });
    };

    handleSelectTablePage = ({ size, page, sortOrder, sortColumn, query }) => {
        let filters = selectItemsToFilter(this.props.selectedItems);
        page = page <= 0 ? 0 : page - 1;
        this.setState({ page: page });
        this.props.fetchListOfDocuments({
            page: page,
            size,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            filters: filters,
            query,
        });
    };

    handleFilterApply = (selectedItems, query) => {
        let filters = selectItemsToFilter(selectedItems);
        this.setState({ defaultFilterItems: { useDefault: false } });

        this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            filters,
            query,
        });
    };

    handleClearFilters = () => {
        this.props.fetchListOfDocuments({
            page: 0,
            size: TOTAL_PER_PAGE,
            sortColumn: 'startDate',
            sortOrder: 'DESC',
            filters: [],
        });

        this.setState({ defaultFilterItems: { useDefault: false } });
    };

    render() {
        const {
            action,
            selectedDocument,
            fileValues,
            fileRemoveArray,
            startDate,
            endDate,
            message,
        } = this.state;
        const {
            content,
            totalElements,
            totalPages,
            size,
            number,
            loading,
            categoryOptions,
            fileImages,
            retailerTypeOptions,
        } = this.props;

        const headerText = 'Messages';
        let actionsMenu = [];

        actionsMenu.push({
            iconClass: 'icon-Edit',
            text: 'Edit',
            handleClick: this.editDocument,
            handleClickParams: 'id',
        });

        actionsMenu.push({
            iconClass: 'icon-Search',
            text: 'Preview',
            handleClick: this.previewDocument,
            handleClickParams: 'id',
        });

        const backToListJSX = (
            <div className="nav-back">
                <span
                    onClick={this.showList}
                    className="caption-text clickable"
                >
                    {'<'} back to <b>Message List</b>
                </span>
            </div>
        );

        if (action === SAVE_IMAGE_CONFIRMATION) {
            return (
                <OkCancelModal
                    header={`Save the message`}
                    body={<div>Are you sure you want to save the message?</div>}
                    okText={'Save'}
                    loading={loading}
                    showSaveCancel
                    okFunction={() => this.saveMessage(fileValues)}
                    cancelFunction={() => this.showList()}
                    cssHeader={'center-header'}
                />
            );
        }

        if (action === MESSAGE_UPDATE_CONFIRMATION) {
            return (
                <OkCancelModal
                    header={`Save the message`}
                    body={<div>Are you sure you want to save the message?</div>}
                    okText={'Save'}
                    showSaveCancel
                    data-test="okCancelModal"
                    okFunction={() => this.editMessage(selectedDocument)}
                    cancelFunction={() => this.showList()}
                    cssHeader={'center-header'}
                />
            );
        }

        if (action === CREATE_DOCUMENT) {
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <UploadMultipleDocuments
                        returnToList={this.showList}
                        ShowImageCarouselPreview={this.ShowImageCarouselPreview}
                        data-test="uploadDocuments"
                        documentType={this.props.type}
                        categoryOptions={categoryOptions}
                    />
                </div>
            );
        }

        if (action === IMAGE_CAROUSEL_PREVIEW) {
            const files = [];
            [].forEach.call(fileValues.file, (file) => {
                files.push(URL.createObjectURL(file));
            });

            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <ImageCarousel
                        fileData={files}
                        confirmSaveMessage={this.confirmSaveMessage}
                        showThumbs={false}
                        showArrows={true}
                        returnToList={this.showList}
                        fileImage={files}
                        showSaveCancel={true}
                        ImageHeight="350px"
                    />
                </div>
            );
        }

        if (action === IMAGE_CAROUSEL_EDIT_PREVIEW) {
            const files = [];
            [].forEach.call(fileValues.file, (file) => {
                files.push(URL.createObjectURL(file));
            });
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <ImageCarousel
                        fileData={files}
                        confirmSaveUpdateMessage={this.confirmSaveUpdateMessage}
                        showThumbs={false}
                        showArrows={true}
                        returnToList={this.showList}
                        fileImage={files}
                        enableOK={true}
                        ImageHeight="300px"
                    />
                </div>
            );
        }

        if (action === PREVIEW_DOCUMENT) {
            const fileImage = [];
            for (var key in fileImages) {
                fileImage.push(fileImages[key].fileBase64);
            }

            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <ImageCarousel
                        fileImage={fileImage}
                        showThumbs={false}
                        showArrows={true}
                        ImageHeight="300px"
                        enableOK={true}
                        okayFunction={this.showList}
                    />
                </div>
            );
        }
        if (action === MESSAGE_EDIT_IMAGE_CONFIRM) {
            const fileImage = [];
            for (var Filekey in fileImages) {
                fileImage.push(fileImages[Filekey].fileBase64);
            }

            //merge file images between new and old
            forEach(fileValues.file, (file) => {
                const reader = new FileReader();
                fileImage.push(reader.readAsDataURL(file));
            });

            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <ImageCarousel
                        fileImage={fileImage}
                        showThumbs={false}
                        showArrows={true}
                        returnToList={this.showList}
                        confirmSaveMessage={this.confirmEditMessage}
                        enableOK={true}
                        okayFunction={this.editDocument}
                        ImageHeight="300px"
                    />
                </div>
            );
        }

        if (action === EDIT_DOCUMENT) {
            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <UploadMultipleDocuments
                        returnToList={this.showList}
                        documentInfo={selectedDocument}
                        DocumentUpdateConfirmation={
                            this.DocumentUpdateConfirmation
                        }
                        DocumentUpdateSaveConfirmation={
                            this.DocumentUpdateSaveConfirmation
                        }
                        headerText={headerText}
                        onDelete={() =>
                            this.showcancelDocumentModal(selectedDocument.id)
                        }
                        fileRemoveArray={fileRemoveArray}
                        startingDate={startDate}
                        endingDate={endDate}
                        docMessage={message}
                    />
                </div>
            );
        }

        const { DOCUMENT_CREATE } = permissions;

        if (action === READ_DOCUMENT) {
            const selectedRetailerOptions =
                selectedDocument && selectedDocument.retailerTypes.length
                    ? filter(retailerTypeOptions, (rto) =>
                          includes(selectedDocument.retailerTypes, rto.value)
                      )
                    : [];

            return (
                <div className="data-display-wrapper">
                    {backToListJSX}
                    <SfaForm
                        name="DocumentForm"
                        headerText={headerText}
                        showSaveCancel={false}
                        showEdit={true}
                        editButtonRoles={[DOCUMENT_CREATE]}
                        deleteButtonRoles={[DOCUMENT_CREATE]}
                        showDelete={true}
                        deleteButtonText="Cancel"
                        deleteButtonClass="transparent-btn"
                        onDelete={this.showList}
                        onEdit={() => this.editDocument(selectedDocument.id)}
                        deleteAction={this.showList}
                    >
                        <SfaReadView
                            elements={[
                                {
                                    label: 'Message Name',
                                    value: selectedDocument.description,
                                },
                                {
                                    label: 'Start Date',
                                    value: formatToDisplayDate(
                                        selectedDocument.startDate
                                    ),
                                },
                                {
                                    label: 'End Date',
                                    value: formatToDisplayDate(
                                        selectedDocument.endDate
                                    ),
                                },

                                {
                                    label: 'Last Modified',
                                    value: formatToDisplayDate(
                                        selectedDocument.modifiedDate
                                    ),
                                },
                            ]}
                        />
                        <PillList
                            label="Retailer Types"
                            list={selectedRetailerOptions}
                            displayAttr="label"
                        />
                    </SfaForm>
                </div>
            );
        }

        //default show list
        return (
            <Page
                name="MessageListPage"
                title="Add / Edit Messages"
                data-test="messageListPage"
                onAddButtonClick={this.showCreateForm}
                loading={loading}
                addButtonRoles={[DOCUMENT_CREATE]}
            >
                <div>
                    <FilterSection
                        filters={[
                            {
                                label: 'Message Status: ',
                                name: 'messageStatus',
                                multiple: true,
                                toggleAll: true,
                                client: true,
                                searchable: true,
                                items: [
                                    { text: 'Active', value: 'ACTIVE' },
                                    { text: 'Inactive', value: 'INACTIVE' },
                                    { text: 'Pending', value: 'PENDING' },
                                ],
                                defaultSelected: this.state.defaultFilterItems
                                    .useDefault
                                    ? this.state.defaultFilterItems.status
                                    : '',
                            },
                        ]}
                        onApplyFilter={this.handleFilterApply}
                        onClearFilters={this.handleClearFilters}
                    />
                    <div className="data-display-content">
                        <Table
                            actionsMenu={actionsMenu}
                            pagination
                            loading={loading}
                            content={content}
                            contentKey="id"
                            totalPages={totalPages}
                            totalElements={totalElements}
                            size={size}
                            page={number}
                            selector={{
                                type: 'callbackWithId',
                                callback: (id) => this.showReadForm(id),
                            }}
                            columns={[
                                {
                                    name: 'description',
                                    title: 'Message Name',
                                    sortable: true,
                                    searchable: true,
                                },
                                {
                                    name: 'messageCount',
                                    title: 'File Count',
                                },
                                {
                                    name: 'startDate',
                                    title: 'Start Date',
                                    sortable: true,
                                    formatter: (val) =>
                                        val ? formatToDisplayDate(val) : '-',
                                },
                                {
                                    name: 'endDate',
                                    title: 'End Date',
                                    sortable: true,
                                    formatter: (val) =>
                                        val ? formatToDisplayDate(val) : '-',
                                },
                                {
                                    name: 'messageStatus',
                                    title: 'Message Status',
                                    sortable: true,
                                },
                            ]}
                            fetchData={this.handleSelectTablePage}
                            onSearch={this.handleSearch}
                            noDataMessage="There are no messages"
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({
    documentUpload,
    organization,
    fileValues,
    selectedDocument,
    fileRemoveArray,
    tableFilter,
    retailerTypeOptions,
}) => {
    return {
        fileValues,
        selectedDocument,
        fileRemoveArray,
        retailerTypeOptions: retailerTypeOptions.options,
        ...organization,
        ...documentUpload,
        ...tableFilter,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRetailerTypeOptions: () => dispatch(getRetailerTypeOptions()),
        getAllMessageFile: (messageId) =>
            dispatch(getAllMessageFile(messageId)),
        getMessageFiles: (fileRemoveArray, messageId) =>
            dispatch(getMessageFiles(fileRemoveArray, messageId)),
        fetchListOfDocuments: (args) => dispatch(getAllMultipleDocuments(args)),
        deleteDocument: (documentId) =>
            dispatch(deleteMultipleDocument(documentId)),
        uploadMultipleDocument: (
            values,
            startDate,
            endDate,
            message,
            retailerTypes
        ) =>
            dispatch(
                uploadMultipleDocument(
                    values,
                    startDate,
                    endDate,
                    message,
                    retailerTypes
                )
            ),
        updateEditedDocument: (
            filesToAdd,
            fileRemoveArray,
            selectedDocument,
            startDate,
            endDate,
            message,
            retailerTypes
        ) =>
            dispatch(
                updateEditedDocument(
                    filesToAdd,
                    fileRemoveArray,
                    selectedDocument,
                    startDate,
                    endDate,
                    message,
                    retailerTypes
                )
            ),
        updateSuccess: ({ msg, target }) => dispatch(success({ msg, target })),
        updateSortDetails: (args) => dispatch(updateSortDetails(args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageListPage);
