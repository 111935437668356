import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Label } from 'semantic-ui-react';
import { find } from 'lodash';
import classnames from 'classnames';
import { formatMoney } from '../../constants/utils';
import GameCatalogDetailModal from '../gameCatalogDetailModal/GameCatalogDetailModal';
import {
    decrementCartQuantityForGame,
    decrementGameQuantityForOrder,
    incrementCartQuantityForGame,
    incrementGameQuantityForOrder,
} from '../../redux/actions';
import './styles.css';
import PosGameCatalogDetailModal from '../posGameCatalogDetailModal/PosGameCatalogDetailModal';

export class GameCatalogCard extends Component {
    state = {
        showModal: false,
    };

    openModal = () => this.setState({ showModal: true });
    closeModal = () => this.setState({ showModal: false });

    handleAddItem = (e) => {
        const { incrementCartQuantityForGame } = this.props;
        this.handleQuantityChange(e, incrementCartQuantityForGame);
    };
    handleRemoveItem = (e) => {
        const { decrementCartQuantityForGame } = this.props;
        this.handleQuantityChange(e, decrementCartQuantityForGame);
    };

    handleQuantityChange(e, changeQtyFunc) {
        changeQtyFunc();

        this.forceUpdate(); //force rerender since it will be stopped by the stopPropagation
        e.stopPropagation();
    }

    getOrderQtyColor(game, gameInCart) {
        if (
            (game.minimumOrderQuantity &&
                game.minimumOrderQuantity > gameInCart.qty) ||
            (game.maximumOrderQuantity &&
                game.maximumOrderQuantity < gameInCart.qty)
        ) {
            return 'red';
        }
        if (game.suggestedQuantity && game.suggestedQuantity > 0) {
            if (gameInCart.qty < game.suggestedQuantity) {
                return 'yellow';
            }
        }
        return 'green';
    }

    restrictModalOpen = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            game,
            orderedItems,
            localizeCard,
            localizeDetail,
            localizePosGameDetail,
            readOnly,
            isInstantTicket,
            isEditOrder,
            listView,
        } = this.props;
        if (!game) {
            return null;
        }
        let gameInCart = find(orderedItems, ['game.gameId', game.gameId]);
        const hasGameInCart = !!gameInCart;
        var gameInCartQty;
        if (hasGameInCart && gameInCart) {
            gameInCartQty = gameInCart.qty;
        }

        return (
            <div className="image-card" data-test="gameCatalogCard">
                {game.gameType === 'PS' ? (
                    <PosGameCatalogDetailModal
                        game={game}
                        onClose={this.closeModal}
                        isOpen={this.state.showModal}
                        localizePosGameDetail={localizePosGameDetail}
                        gameInCartQty={gameInCartQty}
                        readOnly={readOnly}
                    />
                ) : (
                    <GameCatalogDetailModal
                        game={game}
                        onClose={this.closeModal}
                        isOpen={this.state.showModal}
                        localizeDetail={localizeDetail}
                        gameInCartQty={gameInCartQty}
                        readOnly={readOnly}
                        isEditOrder={isEditOrder}
                    />
                )}
                <div onClick={this.openModal} data-test="gameDivWrapper">
                    <div>
                        <span>
                            <div
                                className={classnames({
                                    mobile: listView,
                                    'image-container p-3 pb-0 h-0':
                                        `${game.gameType}` !== 'PS',
                                    'pos-container p-3 pb-0':
                                        `${game.gameType}` === 'PS',
                                })}
                            >
                                {game.image?.props.src
                                    .toString()
                                    .includes('noImage') &&
                                game.gameType === 'PS' ? (
                                    <div className={'game-name'}>
                                        <div
                                            className={
                                                'text-container p-400 pb-0'
                                            }
                                        >
                                            Point of Sale : <br />
                                            <b>{game.name} </b>
                                        </div>
                                    </div>
                                ) : (
                                    game.image
                                )}
                                {!readOnly && (
                                    <div
                                        onClick={this.restrictModalOpen}
                                        className={classnames(
                                            'ticket-image-icon p-2',
                                            {
                                                'in-cart': hasGameInCart,
                                            }
                                        )}
                                    >
                                        <Icon
                                            className="icon-Plus plus-icon-size"
                                            onClick={this.handleAddItem}
                                        />
                                        <Icon
                                            className="icon-Minus plus-icon-size"
                                            onClick={this.handleRemoveItem}
                                            disabled={
                                                hasGameInCart ? false : true
                                            }
                                        />
                                        {hasGameInCart ? (
                                            <div className="item-in-cart-wrapper">
                                                <Label
                                                    circular
                                                    color={this.getOrderQtyColor(
                                                        game,
                                                        gameInCart
                                                    )}
                                                >
                                                    {gameInCart &&
                                                        gameInCart.qty}
                                                </Label>
                                            </div>
                                        ) : (
                                            <Icon
                                                className="icon-Cart icon-cart-size"
                                                onClick={this.handleAddItem}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={classnames('game-card-footer p-3', {
                                    mobile: listView,
                                })}
                            >
                                <div className="game-ref-name">
                                    <b>{game.gameRefAndName}</b>
                                </div>

                                {isInstantTicket && (
                                    <div className="game-info pt-0 d-flex justify-content-space-between">
                                        <p>
                                            {`${localizeCard.themeName}: `}
                                            <b>{game.themeName}</b>
                                        </p>
                                        <p>
                                            Min:{' '}
                                            {game.minimumOrderQuantity
                                                ? game.minimumOrderQuantity
                                                : '0'}{' '}
                                            {game.maximumOrderQuantity &&
                                            game.maximumOrderQuantity > 0
                                                ? `,Max: ${game.maximumOrderQuantity}`
                                                : ''}
                                        </p>
                                    </div>
                                )}
                                <div
                                    className={classnames(
                                        'game-info pt-1 mb-3'
                                    )}
                                >
                                    <p className="ticket-per-deal">
                                        {game.gameType === 'PS'
                                            ? 'Game type - POS'
                                            : `${
                                                  isInstantTicket
                                                      ? localizeCard.ticketsPerPackLocalized
                                                      : localizeCard.ticketsPerDealLocalized
                                              }: ${game.ticketPerDeal}`}
                                    </p>
                                    {game.gameType === 'PS' ? (
                                        <p className="ticket-commission">
                                            {`${
                                                localizeCard.pricePoint
                                            }: ${formatMoney(
                                                game.ticketPrice
                                            )}`}
                                        </p>
                                    ) : isInstantTicket ? (
                                        <p className="ticket-commission">
                                            {`${
                                                localizeCard.packValue
                                            }: ${formatMoney(game.packValue)}`}
                                        </p>
                                    ) : (
                                        <p className="ticket-commission">
                                            {`${
                                                localizeCard.commissionLocalized
                                            }: ${formatMoney(game.commission)}`}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    {
        cart: { orderedItems },
        orderDetail: { orderedItems: orderDetailItems },
        organization: { configs },
    },
    props
) => {
    const items = props.isEditOrder ? orderDetailItems : orderedItems;
    return {
        orderedItems: items,
        isInstantTicket:
            find(configs, { name: 'instant-ticket-view' })?.value === 'true',
    };
};

const mapDispatchToProps = (dispatch, { game, isEditOrder }) => {
    return {
        incrementCartQuantityForGame: () =>
            dispatch(
                isEditOrder
                    ? incrementGameQuantityForOrder(game, true)
                    : incrementCartQuantityForGame(game)
            ),
        decrementCartQuantityForGame: () =>
            dispatch(
                isEditOrder
                    ? decrementGameQuantityForOrder(game, true)
                    : decrementCartQuantityForGame(game)
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameCatalogCard);
